import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { getColumnConfig } from '../../components/Datatable/datatableUtils';
import {
  currencyValueFormatter,
  dateFromatter,
  getListOfMonths,
  replaceEmpty,
} from '../../utils/utils';

const initialSort = { field: 'projectName', sort: 'asc' };

const dynamicColumns = [{ fieldName: 'totalRevenue', columnHeader: 'month', rowCell: 'revenue' }];

const columns = [
  {
    field: 'customerName',
    type: 'string',
    headerName: 'Customer Name',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'managerName',
    type: 'string',
    headerName: 'Manager Name',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'accountManagers',
    type: 'string',
    headerName: 'Account Manager(s)',
    flex: 1.5,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return <Box>{`${params.row.accountManagers.join(', ')}`}</Box>;
    },
  },
  {
    field: 'sowEndDate',
    type: 'date',
    valueFormatter: (params: any) => dateFromatter(params.value),
    headerName: 'EndDate',
    flex: 1,
    sortable: true,
  },
  {
    field: 'totalResources',
    type: 'number',
    headerAlign: 'right',
    cellClassName: 'right-align-number-field',
    headerName: 'Allocated Resources',
    flex: 1,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
];

const getColumns = ({ currentDateRange, columnVisibilityModel }: any, rowActions?: any) => {
  const colRange = getListOfMonths(currentDateRange);
  const cols = [
    {
      field: 'projectName',
      type: 'string',
      headerName: 'Project',
      flex: 2,
      sortable: true,
      hideable: false,
      isVisible: true,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`/revenue/project/${params.row.id}?startDate=${currentDateRange.startDate}&endDate=${currentDateRange.endDate}`}>
          {params.row.projectName}
        </Link>
      ),
    },
    ...columns,
    ...colRange.map((month) =>
      getColumnConfig({
        field: month,
        currency: 'USD',
        ...(dayjs(month).isBefore(dayjs().startOf('month')) && {
          headerClassName: 'disable-past-month',
          cellClassName: 'disable-past-month right-align-number-field',
        }),
        ...(dayjs(month).isSame(dayjs().startOf('month')) && {
          headerClassName: 'highlight-current-month',
          cellClassName: 'highlight-current-month right-align-number-field',
        }),
      }),
    ),
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      flex: 0.5,
      hideable: false,
      getActions: (params: any) =>
        rowActions?.map((action: any) => {
          return (
            <Tooltip title={action.label}>
              <GridActionsCellItem
                key={action.label}
                icon={action.logo}
                label={action.label}
                onClick={() => action.callback(params)}
              />
            </Tooltip>
          );
        }),
    },
  ];
  return cols;
};

const getInitialVisibilityModel = ({ startDate, endDate }: any, showColumns: any) => {
  const colRange = getColumns({ currentDateRange: { startDate, endDate } });
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] =
      showColumns.includes(dayjs(col.field).month()) || showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const revenueProjectIdName = 'projectUid';
const initialShowCols = ['projectName', 'managerName', 'sowEndDate', 'actions'];

export {
  initialSort,
  dynamicColumns,
  revenueProjectIdName,
  initialShowCols,
  getColumns,
  getInitialVisibilityModel,
};
