import { Box, IconButton, Modal } from '@mui/material';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SidePanel from '../../components/SidePanel/SidePanel';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import AddAccountForm from '../Opportunity/AddAccountForm';
import { getFullName } from '../Opportunity/entitiesFormConfig';
import AccountList from './AccountList';
import { dynamicColumns, resourceIdName } from './accountListTableConfig';
import useAllAccountsStyles from './AllAccountsStyles';

const AllAccounts = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountList, setAccountList] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [fetch, setFetch] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);

  const styles = useAllAccountsStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const handleAddClick = () => setIsOpen(!isOpen);
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const formatData = (data: any) => {
    return data.map((item: any) => ({ ...item, customerInfo: item.customerInfo[0]?.name }));
  };

  const getAccountList = () => {
    setIsLoading(true);
    CustomerRelationClient.getAllAccounts()
      .then((result) => {
        const rows = generateRows(formatData(result.data), dynamicColumns, [], resourceIdName);
        setTableRows(rows);
        setAccountList(result.data);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAccountList(), [fetch]);

  useEffect(() => {
    if (id && accountList.length) {
      const {
        name,
        addressFirstLine,
        addressSecondLine,
        city,
        state,
        country,
        pincode,
        customerInfo,
        poc,
        pan,
        gstin,
      } = accountList.find((account: any) => account.uid === id);

      setSelectedAccount({
        id,
        name: name || '',
        addressFirstLine,
        addressSecondLine: addressSecondLine || '',
        city: city || '',
        state: state || '',
        country: country || '',
        pincode: pincode || '',
        customer: { label: customerInfo[0].name, value: customerInfo[0].customerUid },
        poc: poc.map((item: any) => ({
          label: `${getFullName(item)} (${item.email})`,
          value: item.uid,
        })),
        pan: pan || '',
        gstin: gstin || '',
      });
    }
  }, [id, accountList]);

  const handleClose = () => {
    setSelectedAccount(null);
    navigate('/account');
  };

  return (
    <Box className={styles.accountWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.ACCOUNT_TITLE,
          })}
        </span>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box
          className={selectedAccount ? `${styles.ListWrapper} ${styles.w75}` : styles.ListWrapper}>
          <AccountList
            tableRows={tableRows}
            updateFetch={updateFetch}
            handleCloseOnDelete={handleClose}
          />
        </Box>
        {selectedAccount && (
          <Box className={styles.detailsWrapper} key={selectedAccount.id}>
            <Box className={`${styles.topContainer} ${styles.card}`}>
              <strong>{selectedAccount.name}</strong>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={`${styles.bottomContainer} ${styles.card}`}>
              <Box className={styles.accountFormWrapper}>
                <AddAccountForm
                  handleModalClose={handleClose}
                  selectedAccount={selectedAccount}
                  updateFetch={updateFetch}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={styles.fabContainer}>
        <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleAddClick}>
          <AddIcon />
        </Box>
        {isOpen && (
          <Modal open={isOpen} onClose={handleModalClose}>
            <Box className={styles.formContainer}>
              <SidePanel
                header={intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_ACCOUNT,
                })}
                onClose={handleModalClose}>
                <AddAccountForm handleModalClose={handleModalClose} updateFetch={updateFetch} />
              </SidePanel>
            </Box>
          </Modal>
        )}
      </Box>
    </Box>
  );
};

export default AllAccounts;
