import dayjs from 'dayjs';
import { getColumnConfig } from '../../components/Datatable/datatableUtils';
import { getCurrencySymbol } from '../../utils/currency';
import { currencyValueFormatter, getListOfMonths } from '../../utils/utils';

const dynamicColumns = [
  { fieldName: 'forecastedRevenue', columnHeader: 'month', rowCell: 'revenue' },
];

const getTableRowTotalField = (currency: string) => {
  return [
    {
      field: 'total',
      headerName: `Total (${getCurrencySymbol(currency)})`,
      minWidth: 60,
      sortable: false,
      hideable: true,
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
    },
  ];
};

const gapFillForecastedRev = (
  forecastedRevenueArr: any,
  currFYStartDate: string,
  currFYEndDate: string,
) => {
  const financialYearMonths = getListOfMonths({
    startDate: currFYStartDate,
    endDate: currFYEndDate,
  });

  const filteredforecastedRevenueArr = forecastedRevenueArr.filter(
    (f: any) => dayjs(f.month) >= dayjs(currFYStartDate) && dayjs(f.month) <= dayjs(currFYEndDate),
  );
  const rows = financialYearMonths.map((month: any) => {
    const existingMonth = filteredforecastedRevenueArr.find((m: any) => m.month === month);
    return { month, revenue: existingMonth?.revenue || 0 };
  });

  return rows;
};

const getColumns = ({ currentFy, currency }: any) => {
  const colRange = getListOfMonths(currentFy);

  const cols = [
    {
      field: 'year',
      type: 'string',
      headerName: 'FY',
      sortable: true,
      hideable: false,
      isVisible: true,
    },
    ...colRange.map((month) =>
      getColumnConfig({
        field: month,
        editable: true,
        flex: 0,
        minWidth: 60,
        ...(dayjs(month).isBefore(dayjs().startOf('month')) && {
          headerClassName: 'disable-past-month',
          cellClassName: 'disable-past-month right-align-number-field',
          editable: false,
        }),
        ...(dayjs(month).isSame(dayjs().startOf('month')) && {
          headerClassName: 'highlight-current-month',
          cellClassName: 'highlight-current-month right-align-number-field',
        }),
        currency,
      }),
    ),
    ...getTableRowTotalField(currency),
  ];
  return cols;
};

const opportunityIdName = 'opportunityId';

export {
  dynamicColumns,
  getTableRowTotalField,
  getColumns,
  opportunityIdName,
  gapFillForecastedRev,
};
