export const getFullName = ({ firstName, middleName, lastName }: any) =>
  middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;

export const getProcessFields = (allFields: any, requiredFields: any) => {
  const processFields = { ...requiredFields };
  Object.keys(requiredFields).forEach((field: any) => {
    if (allFields[field] === '') delete processFields[field];
  });

  return processFields;
};
