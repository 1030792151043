import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';
import ResourceClient from '../../api/Resource/resourceAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import useAllResourcesStyles from './AllResourcesStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import getColumns from './ViewByProjectListConfig';

const ViewByProjectList = () => {
  const [projectListData, setProjectListData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState<any>([]);
  const [columnHeading, setColumnHeading] = useState<any>([]);
  const styles = useAllResourcesStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const getProjectList = () => {
    setIsLoading(true);
    ResourceClient.getViewByProjectList()
      .then((data) => {
        setProjectListData(data);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const projects = projectListData ? Object.keys(projectListData) : [];
    setColumnHeading(projects);
    setRow(
      Array.from({
        length: Math.max(...projects.map((project) => projectListData[project]?.length)),
      }).map((_, rowIndex) =>
        projects.reduce(
          (acc: any, project) => {
            acc[project] = projectListData[project][rowIndex] || '';
            return acc;
          },
          { id: rowIndex + 1 },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectListData]);

  return (
    <>
      <Box className={styles.resourceWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_PROJECT,
            })}
          </span>
          <Box className={styles.LinkWrapper}>
            <Link to="/resource">
              {intl.formatMessage({
                id: I18nKey.RESOURCE_TITLE,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_PROJECT,
            })}
          </Box>
        </Box>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        <Box className={styles.ListWrapper}>
          <Box className={styles.column}>
            <Datatable
              rows={row}
              columns={getColumns(columnHeading)}
              showExportButton
              exportedFileName={`Resource_List_By_Project_${dayjs().format('DD_MMMM')}`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewByProjectList;
