/* eslint-disable eqeqeq */
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import queryString from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Divider, Grid, IconButton, Modal, Typography } from '@mui/material';
import MisTabs from '../../components/MisTabs/MisTabs';
import I18nKey from '../../translations/I18nKey';
import useAllInterviewStyles from './AllInterviewStyles';
import InterviewList from './InterviewList';
import SidePanel from '../../components/SidePanel/SidePanel';
import AddInterviewerForm from './Forms/AddInterviewerForm';
import InterviewLevelTabs from './InterviewLevelTabs';
import InterviewTopSectionInfo from './InterviewTopSectionInfo';
import AllInterviewers from './AllInterviewers';
import InterviewClient from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { InterviewDetailsTypes } from '../../api/Interview/interviewTypes';
import { UserGroupRoles, hasSuperUserAccess } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import AddCandidateDetails from './AddCandidateDetails';
import { formatData } from './utils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import AllInterviewList from './AllInterviewList';
import AddCandidadateByCSVForm from './Forms/AddCandidadateByCSVForm';

interface AllTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

const AllInterviews: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showInterviewers, setShowInterviewers] = useState<boolean>(false);
  const [selectedCandidate, setSelectedCandidate] = useState<any>({ name: '', id: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<InterviewDetailsTypes>();
  const [fetch, setFetch] = useState<number>(0);
  const [fetchCandidateList, setFetchCandidateList] = useState<number>(0);
  const [fetchInterviewerList, setFetchInterviewerList] = useState<number>(0);

  const navigate = useNavigate();
  const styles = useAllInterviewStyles();
  const intl = useIntl();
  const { id } = useParams();
  const location = useLocation();
  const { showSnackbar } = useDisplaySnackbar();
  const { groupNames } = useContext(GlobalContext);
  const [isHRAccess, setIsHRAccess] = useState<boolean>(
    hasSuperUserAccess(groupNames) ||
      groupNames.includes(UserGroupRoles.HR) ||
      groupNames.includes(UserGroupRoles.RECRUITER),
  );

  const queryParams = queryString.parse(location?.search);
  const [selectedList, setSelectedList] = useState<any>(
    queryParams.selected_list || 'Interviewees',
  );
  const [selectedTab, setSelectedTab] = useState<any>(queryParams.selected_tab || 6);
  const [interviewersData, setInterviewersData] = useState<any>([]);

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const updateFetchCandidateList = () => setFetchCandidateList((fetchNum) => fetchNum + 1);
  const updateFetchInterviewerList = () => setFetchInterviewerList((fetchNum) => fetchNum + 1);

  const InterviewTabs: AllTabs[] = [
    {
      label: 'careers Applicants',
      component: (
        <InterviewList
          roundName="Applied"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'phone screening',
      component: (
        <InterviewList
          roundName="PHONESCREEN"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'L1',
      component: (
        <InterviewList
          roundName="L1"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'L2',
      component: (
        <InterviewList
          roundName="L2"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'Final',
      component: (
        <InterviewList
          roundName="FINAL"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'HR',
      component: (
        <InterviewList
          roundName="HR"
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
    {
      label: 'All',
      component: (
        <AllInterviewList
          selectedList={selectedList}
          isHRAccess={isHRAccess}
          selectedTab={selectedTab}
          fetchCandidateList={fetchCandidateList}
          updateFetchCandidateList={updateFetchCandidateList}
        />
      ),
    },
  ];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    setSelectedCandidate('');
    navigate(`/interview?selected_list=${selectedList}&selected_tab=${index}`);
  };

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(!open);
  };

  const handleModalCandidateClose = () => {
    setOpen(!open);
    updateFetchCandidateList();
  };

  const handleModalInterviewerClose = () => {
    setOpen(!open);
    updateFetchInterviewerList();
  };

  const handleViewClick = () => {
    setSelectedCandidate('');
    setSelectedTab(0);
    setShowInterviewers(!showInterviewers);
    if (!showInterviewers === true) {
      setSelectedList('Interviewers');
      navigate(`/interview/?selected_list=Interviewers&selected_tab=0`);
    } else {
      setSelectedList('Interviewees');
      navigate(`/interview/?selected_list=Interviewees&selected_tab=0`);
    }
  };

  const getCandidateDetails = () => {
    setIsLoading(true);
    setIsDetailsLoading(true);
    InterviewClient.getAllCandidateDetails(id)
      .then((response: InterviewDetailsTypes) => {
        setDetailsData({
          candidateDetails: response.candidateDetails,
          interviewFeedback: response.interviewFeedback,
        });
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsLoading(false);
        setIsDetailsLoading(false);
      });
  };

  const getInterviewerDetails = () => {
    setIsLoading(true);
    InterviewClient.getAllInterviewerDetails(id)
      .then((response: InterviewDetailsTypes) => {
        setDetailsData(response);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getInterviewersData = () => {
    setIsDetailsLoading(true);
    InterviewClient.getInterviewers()
      .then((response: any) => {
        setInterviewersData(formatData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  useEffect(() => {
    if (selectedList) {
      if (selectedList === 'Interviewers') {
        setShowInterviewers(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedCandidate({ ...selectedCandidate, id });
    if (id && selectedList === 'Interviewees') {
      getCandidateDetails();
      getInterviewersData();
    }
    // else if (id && selectedList === 'Interviewers') getInterviewerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetch]);

  const handleAddInterviewer = (interviewerData: any) => {
    setIsLoading(true);
    InterviewClient.handleInterviewerRole(interviewerData)
      .then((res) => {
        showSnackbar(res, 'success');
        handleModalInterviewerClose();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    const interviewerData = {
      employeeUid: values.name.value,
      isActive: true,
    };
    handleAddInterviewer(interviewerData);
  };

  const handleUploadCsvDocument = (values: any) => {
    setIsLoading(true);
    const file = values.documentFile;
    const formData = new FormData();
    formData.append('file', file);
    InterviewClient.postBulkCandidates(formData)
      .then((res: any) => {
        updateFetch();
        showSnackbar(res, 'success');
        handleModalCandidateClose();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const uploadCsvfile = (values: any) => {
    handleUploadCsvDocument(values);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.interviewWrapper}>
        <Box className={styles.headerWrapper}>
          <Box className={styles.Header}>
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
              })}
            </span>
          </Box>
          <Box>
            {isHRAccess && (
              <>
                <Button
                  className={classnames(styles.viewBtn, {
                    active: selectedList === 'Interviewers',
                  })}
                  variant="outlined"
                  onClick={handleViewClick}>
                  {intl.formatMessage({
                    id: I18nKey.INTERVIEW_VIEW_INTERVIEWERS,
                  })}
                </Button>
                <Button
                  className={styles.templateBtn}
                  variant="outlined"
                  onClick={() => navigate('/interview/offeredCandidate')}>
                  {intl.formatMessage({
                    id: I18nKey.OFFERED_CANDIDATE,
                  })}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box className={styles.contentWrapper}>
          {selectedList === 'Interviewers' ? (
            <AllInterviewers
              selectedList={selectedList}
              selectedTab={parseInt(selectedTab, 10)}
              setSelectedTab={setSelectedTab}
              fetchInterviewerList={fetchInterviewerList}
            />
          ) : (
            <>
              <Box
                className={
                  selectedCandidate.id
                    ? `${styles.allTabsWrapper} ${styles.w75}`
                    : styles.allTabsWrapper
                }>
                <MisTabs
                  tabs={InterviewTabs}
                  onTabChange={handleTabChange}
                  selected={parseInt(selectedTab, 10)}
                />
              </Box>
              <>
                {selectedCandidate.id && (
                  <Box className={styles.detailsWrapper} key={selectedCandidate}>
                    <InterviewTopSectionInfo
                      setSelectedCandidate={setSelectedCandidate}
                      candidateDetails={detailsData?.candidateDetails}
                      isLoading={isDetailsLoading}
                      selectedTab={selectedTab}
                      selectedList={selectedList}
                      selectedCandidateId={id}
                      isHRAccess={isHRAccess}
                      updateFetch={updateFetch}
                      updateFetchCandidateList={updateFetchCandidateList}
                    />
                    <Box className={`${styles.bottomContainer} ${styles.card}`}>
                      <Box className={styles.interviewFormWrapper}>
                        <Typography className={`${styles.TitleText} ${styles.bold}`}>
                          Application Status
                        </Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <InterviewLevelTabs
                              interviewFeedback={detailsData?.interviewFeedback}
                              isDetailsLoading={isDetailsLoading}
                              interviewersData={interviewersData}
                              selectedTab={selectedTab}
                              isHRAccess={isHRAccess}
                              updateFetchCandidateList={updateFetchCandidateList}
                              setIsDetailsLoading={setIsDetailsLoading}
                              updateFetch={updateFetch}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            </>
          )}
        </Box>

        {isHRAccess && (
          <>
            {selectedList === 'Interviewees' && (
              <Box className={styles.fabContainer}>
                <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleModalOpen}>
                  <Box>
                    <AddIcon />
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        <Modal open={open} onClose={handleModalClose}>
          <Box className={styles.formContainer}>
            {selectedList === 'Interviewees' ? (
              <SidePanel header="Add Candidate" onClose={handleModalCandidateClose}>
                <Box className={styles.sidePanelTabContent}>
                  {selectedTab == 0 ? (
                    <AddCandidadateByCSVForm handleSubmit={uploadCsvfile} />
                  ) : (
                    <AddCandidateDetails />
                  )}
                </Box>
              </SidePanel>
            ) : (
              <SidePanel header="Add Interviewer" onClose={handleModalInterviewerClose}>
                <Box className={styles.formContainer}>
                  <AddInterviewerForm handleSubmit={handleSubmit} />
                </Box>
              </SidePanel>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default AllInterviews;
