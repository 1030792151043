import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import useAllCandidateStyles from './AllCandidatesStyles';
import CandidateList from './CandidatesList';

const AllCandidates = () => {
  const styles = useAllCandidateStyles();
  const intl = useIntl();
  const params = useParams();
  return (
    <Box className={styles.candidateWrapper}>
      <Box className={styles.HeaderWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.CANDIDATE_TITLE,
            })}
          </span>
          <Box className={styles.LinkWrapper}>
            <Link to="/document">
              {intl.formatMessage({
                id: I18nKey.GENERATE_DOCUMENT_TITLE,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.OFFER_LETTER,
            })}
          </Box>
        </Box>
        <Box>
          <Link to="/document/offerLetter/newOfferLetter">
            <Button className={styles.templateBtn} variant="contained">
              {intl.formatMessage({
                id: I18nKey.NEW_OFFER_LETTER,
              })}
            </Button>
          </Link>
        </Box>
      </Box>

      <Box className={styles.contentWrapper}>
        <Box className={params.id ? `${styles.ListWrapper} ${styles.w70}` : styles.ListWrapper}>
          <CandidateList />
        </Box>
      </Box>
      {/* <Box className={styles.fabContainer}>
        <Box className={`${styles.iconbutton} ${styles.button}`}>
          <Box>
            <Link to="/onBoarding/addEmployee">
              <AddIcon />
            </Link>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};
export default AllCandidates;
