import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useAllProjectsStyles from './AllProjectsStyles';

const ProjectDeleteCloseForm: React.FC<any> = ({ setDeleteInfo, isProjectCloseForm }) => {
  const classes = useAllProjectsStyles();
  const currentDate = dayjs().format('YYYY-MM-DD');
  const deleteFormValidationSchema = Yup.object().shape({
    endDate: isProjectCloseForm ? Yup.date().required('Date is required') : Yup.date().nullable(),
    comment: Yup.string().nullable(),
  });
  const handleChange = (values: any): any => {
    setDeleteInfo(values);
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{ endDate: currentDate, comment: '' }}
        onSubmit={() => {}}
        validationSchema={deleteFormValidationSchema}
        enableReinitialize>
        {(formik) => {
          const { values } = formik;
          return (
            <Form onChange={handleChange(values)}>
              <Grid container spacing={2}>
                {isProjectCloseForm && (
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="endDate"
                      label=" End Date "
                      variant="standard"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <SimpleInputField
                    name="comment"
                    label="Comments"
                    variant="standard"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ProjectDeleteCloseForm;
