import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import { Box, Button, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import { FieldArray, Formik, Form, ErrorMessage } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useKraTemplateDetailsStyles from './KraTemplateDetailStyles';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { handleIntegerType } from '../../utils/utils';
import MisDialog from '../../components/MisDialog/MisDialog';
import I18nKey from '../../translations/I18nKey';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

const KraTemplateForm: React.FC<any> = ({
  csvData,
  isTemplateLoading,
  setIsTemplateLoading,
  appraisalDetails,
  updatedSubCategories,
  allCategories,
  allSubCategories,
  setUpdatedSubCategories,
  handleKraSubmit,
  handleLockGoals,
  isStartDisabled,
  isManager,
  isEmployee,
}: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({
    kraDetails: appraisalDetails,
  });

  const intl = useIntl();

  const styles = useKraTemplateDetailsStyles();
  const hasAccess = isManager || isEmployee;

  const validationSchema = Yup.object().shape({
    kraDetails: Yup.array()
      .of(
        Yup.object().shape({
          category: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .typeError('Category is required'),
          subCategory: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .typeError('Sub Category is required'),
          description: Yup.string().required('Details is required'),
          weightage: Yup.number()
            .required('Weightage is required')
            .typeError('Weightage is required')
            .min(1, 'Weightage should be greater than 0')
            .max(30, 'Weightage should be less than or equal to 30'),
        }),
      )
      .test('sum', 'Total weightage cannot be greater than 100', function (value) {
        if (!value) return true; // if the value is undefined or null, don't run the test
        const sum = value.reduce((acc, obj) => acc + (obj.weightage || 0), 0);
        return sum <= 100;
      }),
  });

  const handleDeleteRow = (value: any, index: number) => {
    const categoryRemoved = value.kraDetails[index].category || '';
  };

  useEffect(() => {
    if (appraisalDetails?.length) {
      setData({
        kraDetails: appraisalDetails,
      });
    }
    setIsTemplateLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalDetails]);

  // useEffect(() => {
  //   if (csvData) {
  //     const formData = csvData?.map((values: any) => {
  //       const weightage = 'Weightage %';
  //       const weightPercent = values[weightage];
  //       return {
  //         category: values.Category,
  //         description: values.Description,
  //         weightage:
  //           typeof weightPercent === 'string' ? parseInt(weightPercent, 10) : weightPercent,
  //       };
  //     });
  //     const kraData: any = { kraDetails: [...formData] };
  //     setData(kraData);
  //   }
  // }, [csvData]);

  return (
    <>
      {isTemplateLoading && <ProgressSpinner showSpinner={isTemplateLoading} />}
      <Formik
        initialValues={data}
        onSubmit={handleKraSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, errors } = formik;

          const disableLockGoals =
            dirty ||
            appraisalDetails.length === 0 ||
            values.kraDetails.reduce((acc: number, obj: any) => acc + (obj.weightage || 0), 0) !==
              100 ||
            !isValid ||
            values.kraDetails.length < 5;

          const disableAddButton =
            values.kraDetails.reduce((acc: number, obj: any) => acc + (obj.weightage || 0), 0) >=
            100;
          const addBtnDisability = !isStartDisabled || !hasAccess || disableAddButton;

          return (
            <Form>
              <table className={`${styles.table} ${styles.addTable}`}>
                <tr>
                  <th className={styles.column}>Category</th>
                  <th className={styles.column}>Sub Category</th>
                  <th className={styles.column}>Details</th>
                  <th className={styles.column}>Weightage %</th>
                  <th className={`${styles.column} ${styles.rightColumn}`}> </th>
                </tr>
                <tbody className={styles.tableContents}>
                  <FieldArray
                    name="kraDetails"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {values.kraDetails.map((item: any, index: number) => (
                            <>
                              {/* eslint-disable-next-line react/no-array-index-key */}
                              <tr key={index}>
                                <td className={`${styles.column} ${styles.leftColumn}`}>
                                  <SimpleAutocompleteField
                                    name={`kraDetails[${index}].category`}
                                    placeholder="Select Category"
                                    size="small"
                                    setFieldValue={setFieldValue}
                                    value={values.kraDetails[index].category}
                                    options={allCategories}
                                    values={values}
                                    disabled={!isStartDisabled || !hasAccess}
                                    fullWidth
                                    required
                                  />
                                </td>
                                <td className={`${styles.column} ${styles.leftColumn}`}>
                                  <SimpleAutocompleteField
                                    name={`kraDetails[${index}].subCategory`}
                                    placeholder="Select Sub Category"
                                    size="small"
                                    setFieldValue={setFieldValue}
                                    value={values.kraDetails[index].subCategory}
                                    options={updatedSubCategories}
                                    values={values}
                                    disabled={!isStartDisabled || !hasAccess}
                                    fullWidth
                                    required
                                  />
                                </td>
                                <td className={`${styles.column} ${styles.centerColumn}`}>
                                  <SimpleInputField
                                    name={`kraDetails[${index}].description`}
                                    type="string"
                                    placeholder="Details"
                                    size="small"
                                    disabled={!isStartDisabled || !hasAccess}
                                    minRows={5}
                                    multiline
                                    fullWidth
                                    required
                                  />
                                </td>
                                <td className={`${styles.column} ${styles.weightageColumn}`}>
                                  <SimpleInputField
                                    name={`kraDetails[${index}].weightage`}
                                    type="number"
                                    placeholder="Weightage"
                                    disabled={!isStartDisabled || !hasAccess}
                                    onKeyPress={handleIntegerType}
                                    size="small"
                                    required
                                    fullWidth
                                  />
                                </td>
                                <td
                                  className={`${styles.column} ${styles.rightColumn} ${styles.actionColumn}`}>
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      handleDeleteRow(values, index);
                                    }}
                                    className={styles.deleteBtn}
                                    disabled={!isStartDisabled || !hasAccess}>
                                    <DeleteIcon />
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}

                          <Button
                            className={styles.addBtn}
                            disabled={addBtnDisability}
                            onClick={() =>
                              arrayHelpers.push({
                                description: '',
                                category: null,
                                weightage: '',
                                subCategory: null,
                              })
                            }>
                            Add another
                          </Button>
                        </>
                      );
                    }}
                  />
                </tbody>
              </table>
              {typeof errors.kraDetails === 'string' && (
                <Box className={styles.errorMsg}>
                  <ErrorMessage name="kraDetails" />
                </Box>
              )}
              <>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid || !isStartDisabled || !hasAccess}
                  className={styles.saveBtn}>
                  Save
                </Button>
                {isManager && !isEmployee && (
                  <Button
                    variant="outlined"
                    disabled={disableLockGoals}
                    className={styles.actionBtn}
                    onClick={() => setOpenDialog(true)}>
                    Lock Goals
                  </Button>
                )}{' '}
                {isManager && !isEmployee && disableLockGoals && (
                  <Tooltip
                    title="Lock Goals will only be enabled if there are minimum 5 KRA's and the sum total of their weightage is 100"
                    placement="left">
                    <InfoOutlined color="info" className={styles.info} />
                  </Tooltip>
                )}
              </>
            </Form>
          );
        }}
      </Formik>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.APPRAISAL_SUBTITILE,
        })}
        message={intl.formatMessage({
          id: I18nKey.APPRAISAL_LOCK_GOALS,
        })}
        isOpen={openDialog}
        handleSuccess={handleLockGoals}
        handleClose={() => setOpenDialog(false)}
        actionBtnLabel="Lock Goals"
      />
    </>
  );
};

export default KraTemplateForm;
