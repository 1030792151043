import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllPolicyStoreStyles = makeStyles((theme: Theme) => {
  return {
    policyStoreWrapper: {
      padding: '1rem',
    },
    Header: {
      position: 'relative',
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        position: 'absolute',
        right: '190px',
      },
    },
    itemWrapper: {
      position: 'relative',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    routeItem: {
      backgroundColor: theme.palette.primary.main,
      padding: '10px 16px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderRadius: 12,
      minHeight: '90px',
      '&:hover': {
        cursor: 'pointer',
        '& $actionBtnWrapper': {
          visibility: 'visible',
        },
      },
    },
    ModuleIcon: {
      width: '32px',
      height: '32px',
      marginRight: '32px',
    },
    label: {
      fontSize: '20px !important',
      fontWeight: '600 !important',
    },
    routeItemWrapper: {
      display: 'flex',
    },
    footerItem: {
      display: 'flex',
      position: 'relative',
      marginTop: '20px',
    },
    contentWrapper: {
      textAlign: 'left',
      width: '100%',
    },
    footerRightItem: {
      position: 'absolute',
      left: '0px',
    },
    footerFont: {
      fontSize: '14px !important',
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        fontSize: '35px',
      },
    },
    actionBtnWrapper: {
      position: 'absolute',
      visibility: 'hidden',
      top: '20px',
      right: '5px',

      '& svg': {
        color: theme.palette.secondary?.main,
        cursor: 'pointer',
        margin: '2px',
        height: '18px',
        width: '18px',
      },
    },
    deleteBtn: {
      color: `${theme.palette.status?.danger} !important`,
    },
  };
});

export default useAllPolicyStoreStyles;
