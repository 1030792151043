import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useContractDetailStyles = makeStyles((theme: Theme) => {
  return {
    ContractDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    Header: {
      marginBottom: '2rem',
      fontSize: '14px',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    UpperCardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '1rem',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    UpperCardHeader: {
      padding: '1rem',
    },
    UpperCardFieldsKey: { fontSize: '12px' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1.5rem',
      position: 'relative',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      paddingBottom: '3.6rem',
    },
  };
});

export default useContractDetailStyles;
