import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { generateRows } from '../../components/Datatable/datatableUtils';
import {
  columns,
  sowInitialSort,
  msaInitialSort,
  contractUidName,
  isExpired,
  isExpiring,
} from './contractTableConfig';
import ContractClient from '../../api/Contract/contractAPIs';
import { ContractList } from '../../api/Contract/contractTypes';
import ContractButtons from './components/ContractButtons';
import theme from '../../theme';
import { F0 } from '../../utils/oppStages';
import { getConvertedCurrency } from '../../utils/exchangeRates';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useAllContractsStyles from './AllContractsStyles';

const AllContracts: React.FC = () => {
  const [tableRows, setTableRows] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  const intl = useIntl();
  const allContractsStyles = useAllContractsStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], contractUidName);
    return rows;
  };

  const getUpdatedContractRows = (rows: any) => {
    const updatedTableRows: any = {
      all: rows,
      no_sow_f0: [],
      sow_expired: [],
      sow_expiring: [],
      msa_expiring: [],
      msa_expired: [],
    };

    rows.forEach((row: any) => {
      if (row.projectStage === F0 && !row.sowStartDate && !row.sowEndDate) {
        updatedTableRows.no_sow_f0.push(row);
      }
      if (isExpiring(row?.sowEndDate)) {
        updatedTableRows.sow_expiring.push(row);
      }
      if (isExpired(row?.sowEndDate)) {
        updatedTableRows.sow_expired.push(row);
      }
      if (isExpiring(row?.msaEndDate)) {
        updatedTableRows.msa_expiring.push(row);
      }
      if (isExpired(row?.msaEndDate)) {
        updatedTableRows.msa_expired.push(row);
      }
    });
    return updatedTableRows;
  };

  useEffect(() => {
    setLoading(true);
    ContractClient.getContractList()
      .then((data) => {
        const result = data.map((row: any) => ({
          ...row,
          contractValue: getConvertedCurrency(row.contractValue, row.currency),
        }));

        const rows = getTableRows(result as ContractList[]);
        const contractupdatedRows = getUpdatedContractRows(rows);
        setTableRows(contractupdatedRows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const contractTabs = [
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_ALL,
      }),
      length: tableRows?.all.length,
      component: (
        <Datatable
          rows={tableRows?.all || []}
          columns={columns}
          initialSort={sowInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`All_Contract_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.secondary?.dark,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_NO_SOW_F0,
      }),
      length: tableRows?.no_sow_f0.length,
      component: (
        <Datatable
          rows={tableRows?.no_sow_f0 || []}
          columns={columns}
          initialSort={msaInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`F0_With_No_SOW_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.status?.danger,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_SOW_EXPIRED,
      }),
      length: tableRows?.sow_expired.length,
      component: (
        <Datatable
          rows={tableRows?.sow_expired || []}
          columns={columns}
          initialSort={sowInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`SOW_Expired_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.status?.danger,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_MSA_EXPIRED,
      }),
      length: tableRows?.msa_expired.length,
      component: (
        <Datatable
          rows={tableRows?.msa_expired || []}
          columns={columns}
          initialSort={msaInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`MSA_Expired_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.status?.danger,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_SOW_EXPIRING,
      }),
      length: tableRows?.sow_expiring.length,
      component: (
        <Datatable
          rows={tableRows?.sow_expiring || []}
          columns={columns}
          initialSort={sowInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`SOW_Expiring_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.status?.warning,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_ALL_BTN_MSA_EXPIRING,
      }),
      length: tableRows?.msa_expiring.length,
      component: (
        <Datatable
          rows={tableRows?.msa_expiring || []}
          columns={columns}
          initialSort={msaInitialSort as any}
          tableHeight="70vh"
          showExportButton
          exportedFileName={`MSA_Expiring_List_${dayjs().format('DD_MMMM')}`}
        />
      ),
      color: theme.palette.status?.warning,
    },
  ];

  return (
    <Box className={allContractsStyles.contractsWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={allContractsStyles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.CONTRACT_DETAIL_TITLE,
          })}
        </span>
      </Box>
      <div className={allContractsStyles.allContractsWrapper}>
        <ContractButtons tabs={contractTabs} />
      </div>
    </Box>
  );
};

export default AllContracts;
