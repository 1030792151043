import { Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import useAddEmployeeStyles from './AddEmployeeStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { ApiOperations, cleanFormData, EmployeeStatus } from '../../utils/utils';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import OnboardingClient, { PATHS } from '../../api/OnBoarding/onboardingAPIs';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { exitDetailsFormValidationSchema } from './utils';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';

interface EmployeeExitDetailsProps {
  formState: any;
  formRef: any;
  employeeUid: string;
  exitReason: any;
  updateFetch: () => void;
}

const EmployeeExitDetails: React.FC<EmployeeExitDetailsProps> = ({
  formState,
  formRef,
  employeeUid,
  exitReason,
  updateFetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { checkAccess } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();

  const navigate = useNavigate();
  const styles = useAddEmployeeStyles();
  const intl = useIntl();

  const getUpdateEmployeeParams = ({ values }: any) => ({
    dateOfResignation: values.dateOfResignation,
    proposedExitDate: values.proposedExitDate,
    exitReason: values.exitReason?.code,
    exitRemarks: { details: values.exitRemarks },
    status: EmployeeStatus.ON_NOTICE_PERIOD,
  });

  const handleSubmit = () => {
    setIsLoading(true);
    OnboardingClient.updateEmployeeById(employeeUid, getUpdateEmployeeParams(formRef?.current))
      .then((response: any) => {
        showSnackbar(response, 'success');
        navigate(`/onBoarding/addEmployee/${employeeUid}/${EmployeeStatus.ON_NOTICE_PERIOD}`);
        updateFetch();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}

      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={exitDetailsFormValidationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid } = formik;

          return (
            <Form className={styles.formWrapper}>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="dateOfResignation"
                    type="date"
                    label="Date Of Resignation"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="proposedExitDate"
                    type="date"
                    label="Proposed Exit Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="exitReason"
                    key="exitReason"
                    label="Exit Reason"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={exitReason}
                    values={values}
                    className={styles.formField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="exitRemarks"
                    type="string"
                    label="Exit Remarks"
                    size="small"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              {checkAccess(PATHS.GET_EMPLOYEE_BY_ID('id'), ApiOperations.PUT) && (
                <Button
                  disabled={!dirty || !isValid}
                  variant="contained"
                  type="submit"
                  className="upload-button">
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SAVE,
                  })}
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EmployeeExitDetails;
