import { useState, useEffect } from 'react';
import { Badge, Box, Button, Card, CardHeader, Grid, Modal } from '@mui/material';
import { useIntl } from 'react-intl';
import { DropResult, DragDropContext } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import TicketClient from '../../api/Ticket/TicketAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import StrictModeDroppable from './StrictModeDroppable';
import Tickets from './Tickets';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { Ticket, TicketStatus, categoryFormatData, formatEmployeeList } from './TicketTypes';
import useTicketBoardStyles from './TicketBoardStyles';
import SidePanel from '../../components/SidePanel/SidePanel';
import I18nKey from '../../translations/I18nKey';
import AddTicketForm from './AddTicketForm';
import SearchClient from '../../api/Search/searchAPIs';
import TicketsFilter from './TicketsFilter';
import { getProcessFields } from '../../utils/utils';

const TicketBoard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDropdownData, setIsLoadingDropdownData] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tickets, setTickets] = useState([]);
  const [localtickets, setLocalTickets] = useState<Ticket[]>([]);
  const [categoryData, setCategoryData] = useState([]);
  const [assigneeData, setAssigneeData] = useState([]);
  const [ticket, setTicket] = useState<Ticket>({
    title: '',
    description: '',
    category: null,
    priority: null,
    assignee: null,
    dueAt: null,
    files: null,
    comments: '',
  });
  const [fetch, setFetch] = useState<number>(0);
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useTicketBoardStyles();
  const intl = useIntl();
  const [localticketsCopy, setLocalTicketsCopy] = useState<Ticket[]>([]);
  const updateFetchTicketList = () => setFetch((fetchNum) => fetchNum + 1);
  const getTicketsLength = (status: String) => {
    return 1;
  };

  const [ticketLength, setTicketLength] = useState({
    todo: getTicketsLength(TicketStatus.OPEN),
    inprogress: getTicketsLength(TicketStatus.INPROGRESS),
    blocked: getTicketsLength(TicketStatus.BLOCKED),
    completed: getTicketsLength(TicketStatus.RESOLVED),
  });

  const getAllTickets = () => {
    setIsLoading(true);
    TicketClient.getAllTickets()
      .then((response: any) => {
        setTickets(response);
        setLocalTickets(response);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getCategoryData = () => {
    setIsLoadingDropdownData(true);
    TicketClient.getAllCategories()
      .then((response: any) => {
        setCategoryData(categoryFormatData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingDropdownData(false));
  };

  const getAssigneeData = () => {
    setIsLoadingDropdownData(true);
    SearchClient.getAllEmpList()
      .then((response) => {
        setAssigneeData(formatEmployeeList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingDropdownData(false));
  };

  useEffect(() => {
    getAssigneeData();
    getCategoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getAllTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const updateTicketDetails = (draggableId: string, destination: any) => {
    setLocalTicketsCopy(localtickets);
    const assignee = localtickets.find((list) => list.uid === draggableId)?.assignee?.uid;
    const updateTicket = localtickets.map((list) => {
      if (list.uid === draggableId) {
        return { ...list, status: destination?.droppableId! };
      }
      return list;
    });

    setLocalTickets(updateTicket);
    setIsLoading(true);
    const formData = new FormData();
    formData.append(
      'ticket_details',
      JSON.stringify({
        status: destination?.droppableId,
        assignee_uid: assignee,
      }),
    );

    TicketClient.updateTicketDetails(draggableId, formData)
      .then((res) => {
        showSnackbar(res, 'success');
        updateFetchTicketList();
        setLocalTicketsCopy(updateTicket);
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        setLocalTickets(localticketsCopy);
        setIsLoading(false);
      });

    setTicketLength({
      todo: getTicketsLength('todo'),
      inprogress: getTicketsLength('inprogress'),
      blocked: getTicketsLength('blocked'),
      completed: getTicketsLength('completed'),
    });
  };

  const handleCreateNewTicket = (ticketDetails: any, file: any) => {
    setIsLoading(true);
    const formData = new FormData();
    const valuesData = getProcessFields(ticketDetails);
    formData.append('ticket_details', JSON.stringify(valuesData));
    if (file) {
      formData.append('files', file);
    }
    TicketClient.createTicket(formData)
      .then((res: any) => {
        showSnackbar(res, 'success');
        handleModalClose();
        updateFetchTicketList();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    const ticketDetails = {
      title: values.title,
      description: values.description,
      categoryUid: values.category.value,
      priority: values.priority.value,
      assigneeUid: values?.assignee?.value || null,
      dueAt: values.dueAt,
    };
    const file = values.files;
    handleCreateNewTicket(ticketDetails, file);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    const sourceStatus = source.droppableId as string;
    const destStatus = destination?.droppableId as string;
    if (
      (sourceStatus === TicketStatus.OPEN && destStatus === TicketStatus.RESOLVED) ||
      (sourceStatus === TicketStatus.RESOLVED && destStatus === TicketStatus.OPEN)
    ) {
      showSnackbar(
        null,
        'error',
        intl.formatMessage({
          id: I18nKey.TICKET_MOVEMENT,
        }),
      );
      return;
    }

    if (source.droppableId && destination?.droppableId) {
      if (
        destination?.droppableId !== undefined &&
        source.droppableId !== destination?.droppableId!
      ) {
        updateTicketDetails(draggableId, destination);
      }
    }
  };

  const ticketStatus = [
    TicketStatus.OPEN,
    TicketStatus.INPROGRESS,
    TicketStatus.BLOCKED,
    TicketStatus.RESOLVED,
  ];

  return (
    <>
      {(isLoading || isLoadingDropdownData) && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.ticketBoardWrapper}>
        <TicketsFilter
          setLocalTickets={setLocalTickets}
          categoryData={categoryData}
          assigneeData={assigneeData}
        />

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container>
            {ticketStatus.map((status: any) => {
              let badgeTheme;
              let badgeContent;
              let title;
              switch (status) {
                case TicketStatus.RESOLVED:
                  title = intl.formatMessage({
                    id: I18nKey.TICKET_BOARD_TITLE_RESOLVED,
                  });
                  badgeTheme = 'success';
                  badgeContent = localtickets?.filter(
                    (item: any) => item.status === TicketStatus.RESOLVED,
                  ).length;
                  break;
                case TicketStatus.INPROGRESS:
                  title = intl.formatMessage({
                    id: I18nKey.TICKET_BOARD_TITLE_IN_PROGRESS,
                  });
                  badgeTheme = 'warning';
                  badgeContent = localtickets?.filter(
                    (item: any) => item.status === TicketStatus.INPROGRESS,
                  ).length;
                  break;
                case TicketStatus.BLOCKED:
                  title = intl.formatMessage({
                    id: I18nKey.TICKET_BOARD_TITLE_BLOCKED,
                  });
                  badgeTheme = 'error';
                  badgeContent = localtickets?.filter(
                    (item: any) => item.status === TicketStatus.BLOCKED,
                  ).length;
                  break;
                case TicketStatus.OPEN:
                  title = intl.formatMessage({
                    id: I18nKey.TICKET_BOARD_TITLE_OPEN,
                  });
                  badgeTheme = 'primary';
                  badgeContent = localtickets?.filter(
                    (item: any) => item.status === TicketStatus.OPEN,
                  ).length;
                  break;
                default:
                  break;
              }
              return (
                <Grid item xs={3}>
                  <Card className={styles.cardWrapper}>
                    <CardHeader
                      className={styles.cardHeader}
                      title={
                        <Box className={styles.cardTitle}>
                          {title}
                          <Badge
                            className={styles.badgeTitle}
                            badgeContent={badgeContent}
                            color="primary"
                          />
                        </Box>
                      }
                    />
                    <StrictModeDroppable droppableId={status.toLowerCase()}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={styles.droppableBox}>
                          <Tickets
                            setIsLoading={setIsLoading}
                            setLocaltickets={setLocalTickets}
                            getTickets={localtickets?.filter((item: any) => {
                              return item.status === status.toLowerCase();
                            })}
                            updateFetchTicketList={updateFetchTicketList}
                            categoryData={categoryData}
                            assigneeData={assigneeData}
                          />
                          {provided.placeholder}
                        </Box>
                      )}
                    </StrictModeDroppable>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </DragDropContext>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box className={styles.formContainer}>
            <SidePanel
              header={
                isEdit
                  ? intl.formatMessage({
                      id: I18nKey.EDIT_TICKET,
                    })
                  : intl.formatMessage({
                      id: I18nKey.CREATE_TICKET,
                    })
              }
              onClose={handleModalClose}>
              <AddTicketForm
                handleSubmit={handleSubmit}
                ticket={ticket}
                categoryData={categoryData}
                assigneeData={assigneeData}
              />
            </SidePanel>
          </Box>
        </Modal>
        <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleModalOpen}>
          <AddIcon />
        </Box>
      </Box>
    </>
  );
};

export default TicketBoard;
