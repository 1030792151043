import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { Link, useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import ProjectClient from '../../api/Project/projectAPIs';
import { GetProjectParams, ProjectDetail, ToCcMailInfo } from '../../api/Project/projectTypes';
import { AccountTypesLabel } from '../../utils/accountTypes';
import useProjectDetailStyles from './ProjectDetailStyles';
import ProjectResources from './ProjectResources';
import { dateFromatter, getFYDateRange } from '../../utils/utils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import ContractTable from '../../components/ContractTable/ContractTable';
import FYSelector from '../../components/FYSelector/FYSelector';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProjectInfoForm from './ProjectInfoForm';
import CustomerInfoForm from './CustomerInfoForm';
import { GetCustomerParams } from '../../api/Opportunity/opportunityTypes';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';

const currFYDateRange = getFYDateRange(dayjs());

const ProjectDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(location?.search);
  const [currentFy, setCurrentFy] = useState(
    queryParams.startDate
      ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
      : currFYDateRange,
  );
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [customerInfo, setCustomerInfo] = useState<any>({});
  const [totalRevenue, setTotalRevenue] = useState<string>('0');
  const [totalResources, setTotalResources] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState([false, false]);
  const [fetch, setFetch] = useState(0);
  const [toCcMailInfo, setToCcMailInfo] = useState<ToCcMailInfo>();

  const params = useParams();
  const projectDetailStyles = useProjectDetailStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  useEffect(() => {
    setIsLoading(true);
    const getProjectParams: GetProjectParams = {
      project_id: params.id || '',
    };

    ProjectClient.getProjectDetails(getProjectParams)
      .then((result) => {
        setProjectInfo(result as ProjectDetail);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  const getCustomerById = () => {
    const { customerUid } = projectInfo;
    if (customerUid) {
      const getCustomerParams: GetCustomerParams = {
        customer_id: customerUid || '',
      };
      setIsLoading(true);
      CustomerRelationClient.getCustomer(getCustomerParams)
        .then((customerResult: any) => {
          setCustomerInfo(customerResult);
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getCustomerById();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch, projectInfo]);

  const projectDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_PROJECT_NAME,
      }),
      value: projectInfo?.projectName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_CUSTOMER_NAME,
      }),
      value: projectInfo?.customerName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_RESOURCES,
      }),
      value: totalResources,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_TYPE,
      }),
      value: AccountTypesLabel[projectInfo?.accountType] || '-',
    },
    {
      label: `${intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_REVENUE,
      })} (${dayjs().format('MMMM')})`,
      value: totalRevenue,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_START_DATE,
      }),
      value: projectInfo?.sowStartDate ? dateFromatter(projectInfo?.sowStartDate) : '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_END_DATE,
      }),
      value: projectInfo?.sowEndDate ? dateFromatter(projectInfo?.sowEndDate) : '-',
    },
  ];

  const handleYearChange = (dateRange: any) => {
    setCurrentFy({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    navigate({
      search: createSearchParams({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }).toString(),
    });
  };

  const handleCurrentAccordionChange = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const updateFetch = () => setFetch((num) => num + 1);

  const handleToCcMailInfo = () => {
    const { projectUid } = projectInfo;
    if (projectUid) {
      setIsLoading(true);
      ProjectClient.getEmailRecipientsByProjectId(projectUid)
        .then(({ toAddr, ccAddr }) => {
          const toStr = toAddr.length ? `${toAddr.join(', ')} ` : '';
          const ccStr = ccAddr.length ? `${ccAddr.join(', ')}` : '';
          setToCcMailInfo({ toStr, ccStr });
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    handleToCcMailInfo();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [projectInfo]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <div className={projectDetailStyles.projectDetailsWrapper}>
        <Box className={projectDetailStyles.Header}>
          <Box>
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.PROJECT_DETAIL_TITLE,
              })}
            </span>
            <Box>
              <Link to={`/project?startDate=${currentFy.startDate}&endDate=${currentFy.endDate}`}>
                {intl.formatMessage({
                  id: I18nKey.PROJECT_DETAIL_SUBTITLE,
                })}{' '}
              </Link>
              / {projectInfo?.projectName}
            </Box>
          </Box>
          <Box className="headerContent">
            {projectInfo?.createdOn > currentFy.startDate ? (
              <FYSelector
                onChange={handleYearChange}
                minValue={getFYDateRange(dayjs(projectInfo?.createdOn))}
                maxValue={getFYDateRange(dayjs().add(1, 'year'))}
              />
            ) : (
              <FYSelector onChange={handleYearChange} defaultValue={currentFy} />
            )}
          </Box>
        </Box>
        <Box className={projectDetailStyles.projectInfoWrapper}>
          {projectDetails.map((project) => (
            <Box className={projectDetailStyles.projectInfo} key={project.label}>
              <span className={projectDetailStyles.projectInfoLabel}>{project.label}</span>
              <p className={projectDetailStyles.projectInfoValue}>{project.value}</p>
            </Box>
          ))}
        </Box>
        <Accordion
          expanded={expanded[0]}
          onChange={() => handleCurrentAccordionChange(0)}
          className={projectDetailStyles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <Typography>Project Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={projectDetailStyles.formContainer}>
              <ProjectInfoForm
                projectInfo={{
                  projectName: projectInfo.projectName,
                  alias: projectInfo.projectAlias,
                  poc: projectInfo?.projectPoc?.split(', '),
                  managerUid: projectInfo.managerUid,
                  accountManagerUids: projectInfo?.accountManagerUids,
                  isSez: projectInfo?.isSez,
                  currency: projectInfo?.currency,
                  isInternal: projectInfo?.isInternal,
                }}
                updateFetch={updateFetch}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded[2]}
          onChange={() => handleCurrentAccordionChange(2)}
          className={projectDetailStyles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <Typography>Customer Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={projectDetailStyles.formContainer}>
              <CustomerInfoForm
                customerInfo={customerInfo}
                updateFetch={updateFetch}
                toCcMailInfo={toCcMailInfo}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <div className={projectDetailStyles.tableWrapper}>
          <ProjectResources
            project_id={params.id || ''}
            /* eslint-disable react/jsx-boolean-value */
            isEditable={true}
            setTotalRevenue={setTotalRevenue}
            setTotalResources={setTotalResources}
            accountType={projectInfo?.accountType}
            currentFy={currentFy}
            currency={projectInfo?.currency}
          />
        </div>
        <Accordion
          expanded={expanded[1]}
          onChange={() => handleCurrentAccordionChange(1)}
          className={projectDetailStyles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Contract Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={projectDetailStyles.contractTableWrapper}>
              <ContractTable
                project_id={params.id || ''}
                currency={projectInfo?.currency}
                tableHeight="28vh"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default ProjectDetails;
