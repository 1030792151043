import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllVacanciesStyles = makeStyles((theme: Theme) => {
  return {
    allVacanciesWrapper: {
      padding: '1rem',
      '& .textWrapper': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    Header: {
      position: 'relative',
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',
      borderRadius: '8px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .resource-skill': {
        padding: '2px 8px',
        margin: '1px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
      },
      '& .resource-skill-cell': {
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'center',
      },
    },
    iconbutton: {
      position: 'absolute',
      bottom: '50px',
      right: '50px',
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',
      '& svg': {
        fontSize: '35px',
      },
    },
    submitButton: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
  };
});

export default useAllVacanciesStyles;
