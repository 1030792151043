import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useProjectDetailsStyles = makeStyles((theme: Theme) => {
  return {
    projectDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      '& .resource-skill': {
        padding: '2px 8px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',
      },
      '& .resource-skill-cell': {
        flexWrap: 'wrap',
      },
      '& .highlight-current-month': {
        color: theme.palette.text.midDark,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '1.1rem',
      },
      '& .disable-past-month': {
        color: theme.palette.text.disabled,
      },
    },
    Header: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: '600',
      display: 'flex',
      justifyContent: 'space-between',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    projectInfoWrapper: {
      backgroundColor: theme.palette.popUpBackground?.light,
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '10px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.tertiary?.main}`,
    },
    projectInfo: {
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        margin: '5px 0 0 0',
      },
    },
    projectInfoLabel: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      textAlign: 'center',
      color: '#173F74',
    },
    projectInfoValue: {
      fontSize: '16px',
      fontWeight: '700',
      textAlign: 'center',
      color: '#173F74',
    },
    tableWrapper: {
      backgroundColor: theme.palette.primary.main,
      marginTop: '1rem',
      borderRadius: '8px',
    },
    accordion: {
      backgroundColor: `${theme.palette.appBackground?.lightest} !important`,
      marginTop: '1rem',
      borderRadius: '8px !important',

      '& .MuiAccordionSummary-content': {
        color: theme.palette.text.midDark,
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
      },
      '& .makeStyles-header-18': {
        display: 'none',
      },
    },
    formField: {
      '& input': {
        height: '18px',
      },
    },
    bigFormField: {
      '& input': {
        height: '23px',
      },
    },
    formContainer: {
      '& .upload-button': {
        margin: '1rem 0',
        float: 'right',
      },
    },
    contractTableWrapper: {
      '& .contract-table-header': {
        display: 'none',
      },
    },
    infoWrapper: {
      fontSize: '14px',
      border: '1px solid #dddddd',
      borderRadius: '8px',
      padding: '10px',
    },
    infoshareWrapper: {
      display: 'flex',
      marginBottom: '10px',
    },
    infoText: {
      marginLeft: '10px',
    },
  };
});

export default useProjectDetailsStyles;
