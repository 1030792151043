import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { AddCircle } from '@mui/icons-material';
import CustomerRelationClient, {
  PATHS as opportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import { OpportunityAccountParams } from '../../api/Opportunity/opportunityTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getFullName, getProcessFields } from './entitiesFormConfig';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';
import useAddEntitiesStyles from './AddEntitiesStyles';
import I18nKey from '../../translations/I18nKey';
import AddContactForm from './AddContactForm';
import AddCustomerForm from './AddCustomerForm';
import SidePanel from '../../components/SidePanel/SidePanel';

interface AddAccountFormProps {
  readonly handleModalClose: Function;
  readonly selectedAccount?: any;
  readonly updateFetch?: () => void;
  readonly onCreateAccount?: Function;
  readonly selectedCustomer?: any;
}

const validationSchema = Yup.object().shape({
  customer: Yup.object().required('Customer name is required'),
  name: Yup.string().required('Account name is required'),
  poc: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .required('POC is required')
    .min(1),
  addressFirstLine: Yup.string().min(5, 'Min 5 Characters expected').nullable(),
  pan: Yup.string().length(10, 'PAN should be 10 Characters').nullable(),
  gstin: Yup.string().max(15, 'More than 15 Characters not allowed').nullable(),
});

const AddAccountForm: React.FC<AddAccountFormProps> = ({
  handleModalClose,
  selectedAccount,
  updateFetch,
  onCreateAccount,
  selectedCustomer,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const styles = useAddEntitiesStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);

  const getAllContacts = () => {
    setLoading(true);
    CustomerRelationClient.getCrmContactList()
      .then((data) => {
        setContacts(
          data.map((contact: any) => ({
            label: `${getFullName(contact)} (${contact.email})`,
            value: contact.uid,
          })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  const getAllCustomerList = () => {
    setLoading(true);
    CustomerRelationClient.getAllCustomerList()
      .then((data) => {
        setCustomers(
          data.map((customer: any) => ({ label: customer.name, value: customer.uid, ...customer })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllCustomerList();
    getAllContacts();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleSubmit = (values: any) => {
    const {
      name,
      addressFirstLine,
      addressSecondLine,
      city,
      country,
      state,
      pincode,
      poc,
      customer,
      pan,
      gstin,
    } = values;

    const addCRMAccountParams: OpportunityAccountParams = {
      name,
      addressFirstLine,
      addressSecondLine,
      city,
      country,
      state,
      pincode,
      pan,
      gstin,
      customerUid: customer.value,
      poc: poc.map((p: any) => p.value),
    };

    setLoading(true);

    if (selectedAccount.id) delete addCRMAccountParams.customerUid;

    const result = selectedAccount.id
      ? CustomerRelationClient.updateCRMAccount(
          getProcessFields(values, addCRMAccountParams),
          selectedAccount.id,
        )
      : CustomerRelationClient.addCRMAccount(getProcessFields(values, addCRMAccountParams));

    result
      .then((res) => {
        showSnackbar(res, 'success');
        if (updateFetch) updateFetch();
        onCreateAccount?.(res.data);
        handleModalClose();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  const defaultCustomer = selectedCustomer || selectedAccount.customer;

  const formik = useFormik({
    initialValues: {
      name: selectedAccount.name ? String(selectedAccount.name) : '',
      addressFirstLine: selectedAccount.addressFirstLine
        ? String(selectedAccount.addressFirstLine)
        : '',
      addressSecondLine: selectedAccount.addressSecondLine || '',
      city: selectedAccount.city || '',
      state: selectedAccount.state || '',
      country: selectedAccount.country || '',
      pincode: selectedAccount.pincode || '',
      customer: defaultCustomer
        ? { label: defaultCustomer?.label || '', value: defaultCustomer?.value || '' }
        : null,
      poc: selectedAccount.poc || [],
      pan: selectedAccount.pan ? String(selectedAccount.pan) : '',
      gstin: selectedAccount.gstin ? String(selectedAccount.gstin) : '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formik.values.customer && !(selectedCustomer?.value || selectedAccount?.id)) {
      const {
        addressFirstLine,
        addressSecondLine,
        city,
        country,
        pincode,
        state,
        pan,
        gstin,
      }: any = formik.values.customer;
      formik.setFieldValue('addressFirstLine', addressFirstLine || '');
      formik.setFieldValue('addressSecondLine', addressSecondLine || '');
      formik.setFieldValue('city', city || '');
      formik.setFieldValue('country', country || '');
      formik.setFieldValue('pincode', pincode || '');
      formik.setFieldValue('state', state || '');
      formik.setFieldValue('pan', pan || '');
      formik.setFieldValue('gstin', gstin || '');
    }
  }, [formik.values.customer]);

  const handAddEntity = (option: string) => {
    setSelectedOption(option);
  };
  const handleEntiyModalClose = (fetch: boolean = true) => {
    if (fetch) {
      if (selectedOption === 'customer') {
        getAllCustomerList();
      }
      if (selectedOption === 'contact') {
        getAllContacts();
      }
    }
    setSelectedOption(null);
  };

  const onCreateCustomer = (customer: any) => {
    formik.setFieldValue('customer', { label: customer.name, value: customer.uid });
    formik.setFieldValue('addressFirstLine', customer.address_first_line || '');
    formik.setFieldValue('addressSecondLine', customer.address_second_line || '');
    formik.setFieldValue('city', customer.city || '');
    formik.setFieldValue('country', customer.country || '');
    formik.setFieldValue('pincode', customer.pincode || '');
    formik.setFieldValue('state', customer.state || '');
    formik.setFieldValue('pan', customer.pan || '');
    formik.setFieldValue('gstin', customer.gstin || '');
  };

  const onCreateContact = ({ uid, first_name, middle_name, last_name }: any) => {
    const { poc } = formik.values;
    formik.setFieldValue('poc', [
      ...poc,
      ...[
        {
          label: getFullName({
            firstName: first_name,
            middleName: middle_name,
            lastName: last_name,
          }),
          value: uid,
        },
      ],
    ]);
  };

  const optionsObj: any = {
    customer: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
      }),
      icon: <PersonAddAltIcon />,
      component: (
        <AddCustomerForm
          handleModalClose={handleEntiyModalClose}
          onCreateCustomer={onCreateCustomer}
        />
      ),
    },
    contact: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CONTACT,
      }),
      icon: <ContactsOutlinedIcon />,
      component: (
        <AddContactForm
          handleModalClose={handleEntiyModalClose}
          onCreateContact={onCreateContact}
        />
      ),
    },
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="customer"
              key="customer"
              label="Customer Name"
              variant="standard"
              setFieldValue={formik.setFieldValue}
              options={customers}
              values={formik.values}
              disabled={!!selectedCustomer?.value || !!selectedAccount?.id}
              required
            />
            {checkAccess(opportunityPaths.CRM_CUSTOMER, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                disabled={!!selectedCustomer?.value || !!selectedAccount?.id}
                onClick={() => handAddEntity('customer')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
                })}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.gridItem}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Account Name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              onBlur={formik.handleBlur}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="gstin"
              name="gstin"
              label="GSTIN"
              variant="standard"
              value={formik.values.gstin}
              onChange={formik.handleChange}
              error={formik.touched.gstin && !!formik.errors.gstin}
              helperText={formik.touched.gstin && formik.errors.gstin ? formik.errors.gstin : null}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pan"
              name="pan"
              label="PAN"
              variant="standard"
              value={formik.values.pan}
              onChange={formik.handleChange}
              error={formik.touched.pan && !!formik.errors.pan}
              helperText={formik.touched.pan && formik.errors.pan ? formik.errors.pan : null}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="addressFirstLine"
              name="addressFirstLine"
              label="Address First Line"
              variant="standard"
              value={formik.values.addressFirstLine}
              onChange={formik.handleChange}
              error={formik.touched.addressFirstLine && !!formik.errors.addressFirstLine}
              helperText={
                formik.touched.addressFirstLine && formik.errors.addressFirstLine
                  ? formik.errors.addressFirstLine
                  : null
              }
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="addressSecondLine"
              name="addressSecondLine"
              label="Address Second Line"
              variant="standard"
              value={formik.values.addressSecondLine}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="city"
              name="city"
              label="City"
              variant="standard"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="state"
              name="state"
              label="State"
              variant="standard"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="country"
              name="country"
              label="Country"
              variant="standard"
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pincode"
              name="pincode"
              label="Pincode"
              variant="standard"
              value={formik.values.pincode}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="poc"
              key="poc"
              label="POC"
              variant="standard"
              size={'small'}
              setFieldValue={formik.setFieldValue}
              options={contacts}
              values={formik.values}
              required
              multiple
            />
            {checkAccess(opportunityPaths.CRM_CONTACT, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                onClick={() => handAddEntity('contact')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_CONTACT,
                })}
              </Button>
            )}
          </Grid>
        </Grid>
        {(checkAccess(opportunityPaths.CRM_ACCOUNT, ApiOperations.PUT) ||
          checkAccess(opportunityPaths.CRM_ACCOUNT, ApiOperations.POST)) && (
          <Button
            disabled={!formik.dirty || !formik.isValid}
            variant="contained"
            type="submit"
            className="save-btn">
            {intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SAVE,
            })}
          </Button>
        )}
      </form>
      <Modal open={selectedOption !== null} onClose={() => handleEntiyModalClose(false)}>
        <Box>
          <SidePanel
            header={optionsObj?.[selectedOption!]?.title}
            onClose={() => handleEntiyModalClose(false)}>
            <div className={styles.formContainer}>{optionsObj?.[selectedOption!]?.component}</div>
          </SidePanel>
        </Box>
      </Modal>
    </>
  );
};

AddAccountForm.defaultProps = {
  selectedAccount: {
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    customer: null,
    poc: [],
  },
  updateFetch: () => {},
  onCreateAccount: () => {},
  selectedCustomer: null,
};

export default AddAccountForm;
