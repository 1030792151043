import { Box, InputBase, InputBaseProps, Paper } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { useState } from 'react';
import useDatatableStyles from '../../../components/Datatable/DatatableStyles';

const RevenueRenderEditCell = (props: GridRenderEditCellParams<string>) => {
  const { id, field, value, row, updateProcessedRows } = props;

  const [noOfHrs, setNoOfHrs] = useState(row[field]?.noOfHours);
  const [hrsAnchorEl, setHrsAnchorEl] = React.useState<HTMLElement | null>();
  const [amount, setAmount] = useState<any>(value);
  const [amountAnchorEl, setAmountAnchorEl] = React.useState<HTMLElement | null>();
  const hrsApiRef = useGridApiContext();
  const amtApiRef = useGridApiContext();
  const DatatableStyles = useDatatableStyles();

  const handleHrsRef = React.useCallback((el: HTMLElement | null) => {
    setHrsAnchorEl(el);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleAmtRef = React.useCallback((el: HTMLElement | null) => {
    setAmountAnchorEl(el);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleHoursChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      const newHrs = Number(event.target.value);
      setNoOfHrs(newHrs);
      hrsApiRef.current.setEditCellValue({ id, field, value: newHrs }, event);

      const newAmount = newHrs * row.billingRate * (row.allocationPercentage / 100);
      setAmount(newAmount);
      amtApiRef.current.setEditCellValue({ id, field, value: newAmount }, event);

      const newRow = {
        ...row,
        [field]: {
          ...row[field],
          noOfHours: newHrs,
          revenue: newAmount,
        },
      };
      updateProcessedRows(newRow);
    },
    [hrsApiRef, field, id],
  );

  const handleAmountChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      const newValue = event.target.value;
      setAmount(newValue);
      amtApiRef.current.setEditCellValue({ id, field, value: newValue }, event);
    },
    [amtApiRef, field, id],
  );

  const handleHoursKeyDown = React.useCallback<NonNullable<InputBaseProps['onKeyDown']>>(
    (event) => {
      if (
        event.key === 'Escape' ||
        (event.key === 'Enter' && !event.shiftKey && (event.ctrlKey || event.metaKey))
      ) {
        const params = hrsApiRef.current.getCellParams(id, field);
        hrsApiRef.current.publishEvent('cellKeyDown', params, event);
      }
    },
    [hrsApiRef, id, field],
  );

  const handleAmtKeyDown = React.useCallback<NonNullable<InputBaseProps['onKeyDown']>>(
    (event) => {
      if (
        event.key === 'Escape' ||
        (event.key === 'Enter' && !event.shiftKey && (event.ctrlKey || event.metaKey))
      ) {
        const params = amtApiRef.current.getCellParams(id, field);
        amtApiRef.current.publishEvent('cellKeyDown', params, event);
      }
    },
    [amtApiRef, id, field],
  );

  return (
    <>
      <>
        <Box ref={handleHrsRef} />
        {hrsAnchorEl && (
          <Box className={DatatableStyles.customInputFieldWrapper} sx={{ width: '100%' }}>
            <InputBase
              sx={{ fontSize: 12 }}
              type="number"
              value={noOfHrs}
              inputProps={{ min: 0 }}
              onChange={handleHoursChange}
              autoFocus
              onKeyDown={handleHoursKeyDown}
              className={DatatableStyles.customInputField}
            />
          </Box>
        )}
      </>
      <>
        <Box ref={handleAmtRef} />
        {amountAnchorEl && (
          <Box className={DatatableStyles.customInputFieldWrapper}>
            <InputBase
              type="number"
              value={amount}
              inputProps={{ min: 0 }}
              onChange={handleAmountChange}
              autoFocus
              onKeyDown={handleAmtKeyDown}
              className={DatatableStyles.customInputField}
            />
          </Box>
        )}
      </>
    </>
  );
};

export default RevenueRenderEditCell;
