/* eslint-disable react/no-unused-prop-types */
import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import InvoiceClient, { PATHS } from '../../api/Invoice/invoiceAPIs';
import ProjectClient from '../../api/Project/projectAPIs';
import { GetProjectParams } from '../../api/Project/projectTypes';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { ApiOperations, handleIntegerType } from '../../utils/utils';
import { getFormattedProjects } from '../Resource/projectFormUtils';
import useAllInvoicesStyles from './AllInvoicesStyles';
import { InvoiceUploadType } from '../../api/Invoice/invoiceTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface UploadInvoiceFormProps {
  readonly invoiceData?: any;
  readonly onSuccess?: any;
  readonly editMode?: boolean;
}

const UploadInvoiceForm: React.FC<UploadInvoiceFormProps> = ({
  invoiceData,
  onSuccess,
  editMode,
}) => {
  const [projects, setProjects] = useState<any[]>([]);
  const [fields, setFields] = useState({
    invoiceNo: null,
    project_uid: null,
    invoiceDate: dayjs().format('YYYY-MM-DD'),
    file: null,
    invoiceTotal: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const { checkAccess } = useContext(GlobalContext);
  const allInvoicesStyles = useAllInvoicesStyles();
  const params = useParams();

  const getInitialValues = () => {
    const { invoiceDate, file, invoiceTotal } = invoiceData;

    const initVal = {
      invoiceNo: null,
      editMode,
      project_uid: projects.find((proj) => proj.value === params.id),
      invoiceDate,
      file,
      invoiceTotal,
    };

    setFields(initVal);
  };

  useEffect(() => {
    setIsLoading(true);
    const getProjectParams: GetProjectParams = {
      project_id: '',
      is_active: true,
      show_total_billing: false,
    };
    ProjectClient.getProjectList(getProjectParams)
      .then((result) => {
        setProjects(getFormattedProjects(result));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getInitialValues(), [invoiceData, projects]);

  const handleSubmit = (values: any) => {
    const { invoiceNo, documentFile, invoiceDate, invoiceTotal } = values;
    const file = documentFile;
    let data = {};

    const formData = new FormData();

    setIsLoading(true);
    if (editMode) {
      data = {
        invoiceTotal,
      };

      formData.append('updated_invoice_data', JSON.stringify(data));
      if (documentFile) formData.append('file', file);

      InvoiceClient.updateManualInvoice(formData, invoiceData.project_uid)
        .then((res: any) => {
          showSnackbar(res, 'success');
          onSuccess();
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    } else {
      data = {
        invoiceNo: Math.round(invoiceNo),
        invoiceDate,
        invoiceUploadType: InvoiceUploadType.manual,
        invoiceTotal,
      };

      formData.append('invoice_data', JSON.stringify(data));
      if (documentFile) formData.append('file', file);

      InvoiceClient.createManualInvoice(formData, values.project_uid.value)
        .then((res: any) => {
          showSnackbar(res, 'success');
          onSuccess();
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  const validationSchema = Yup.object().shape({
    invoiceNo: Yup.number().when('editMode', {
      is: true,
      then: Yup.number().nullable(),
      otherwise: Yup.number()
        .required('Invoice Number is required')
        .typeError('Invoice Number is required')
        .min(1, 'Invoice number should be greater than 0'),
    }),
    project_uid: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .required('Project is required')
      .nullable(),
    invoiceDate: Yup.date().required('Invoice date is required'),
    invoiceTotal: Yup.number()
      .min(1, 'Invoice Total should be greater than 0')
      .required('Invoice Total is required'),
    editMode: Yup.boolean(),
    documentFile: Yup.mixed().when('editMode', {
      is: true,
      then: Yup.mixed(),
      otherwise: Yup.mixed().required('Invoice document is required'),
    }),
  });

  return (
    <>
      <Formik
        initialValues={fields}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, errors } = formik;

          return (
            <Form>
              <Grid container spacing={2}>
                {!editMode && (
                  <Grid item xs={12}>
                    <SimpleInputField
                      className={allInvoicesStyles.formField}
                      name="invoiceNo"
                      type="number"
                      variant="standard"
                      label="Invoice Number"
                      onKeyPress={handleIntegerType}
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={editMode ? 6 : 12}>
                  <SimpleAutocompleteField
                    name="project_uid"
                    key="project_uid"
                    variant="standard"
                    label="Project"
                    setFieldValue={setFieldValue}
                    options={projects}
                    values={values}
                    className={allInvoicesStyles.formField}
                    disabled={editMode}
                    required
                  />
                </Grid>
                <Grid item xs={editMode ? 6 : 12}>
                  <SimpleInputField
                    className={allInvoicesStyles.formField}
                    name="invoiceDate"
                    type="date"
                    variant="standard"
                    label="Invoice Date"
                    disabled={editMode}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={editMode ? 6 : 12}>
                  <SimpleInputField
                    className={allInvoicesStyles.formField}
                    name="invoiceTotal"
                    type="number"
                    variant="standard"
                    label="Invoice Total"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={editMode ? 6 : 12}>
                  <SimpleInputField
                    name="file"
                    variant="standard"
                    label="Invoice"
                    type="file"
                    onChange={(evt: any) =>
                      setFieldValue(`documentFile`, evt.currentTarget.files[0])
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="custom-file-input"
                    inputProps={{ accept: 'application/pdf' }}
                    fullWidth
                    required={!editMode}
                  />
                </Grid>
                {(checkAccess(PATHS.UPLOAD_INVOICE('id'), ApiOperations.POST) ||
                  checkAccess(PATHS.UPLOAD_INVOICE('id'), ApiOperations.PUT)) && (
                  <Button
                    disabled={!dirty || !isValid}
                    variant="contained"
                    type="submit"
                    className="upload-button">
                    {intl.formatMessage({
                      id: I18nKey.BUTTON_LABEL_SAVE,
                    })}
                  </Button>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
    </>
  );
};

UploadInvoiceForm.defaultProps = {
  invoiceData: {
    project_uid: null,
    invoiceDate: dayjs().format('YYYY-MM-DD'),
    file: null,
    invoiceTotal: 0,
  },
  onSuccess: () => {},
  editMode: false,
};

export default UploadInvoiceForm;
