import { Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import I18nKey from '../../translations/I18nKey';
import useAddEmployeeStyles from './NewCandidateStyles';
import { basicDetailFormValidation } from './utils';

interface NewCandidateBasicDetailsProps {
  formState: any;
  gender: any;
  bloodGroup: any;
  formRef: any;
  onSave: any;
}

const NewCandidateBasicDetails: React.FC<NewCandidateBasicDetailsProps> = ({
  formState,
  gender,
  bloodGroup,
  formRef,
  onSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const styles = useAddEmployeeStyles();
  const intl = useIntl();

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}

      <Formik
        initialValues={formState}
        onSubmit={onSave}
        validationSchema={basicDetailFormValidation}
        innerRef={formRef}
        enableReinitialize
        validateOnMount>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, handleBlur, touched, errors } = formik;

          return (
            <Form className={styles.formWrapper}>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="firstName"
                    type="string"
                    label="First Name"
                    size="small"
                    onBlur={(event: any) => {
                      handleBlur(event);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="middleName"
                    type="string"
                    label="Middle Name"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="lastName"
                    type="string"
                    label="Last Name"
                    size="small"
                    onBlur={(event: any) => {
                      handleBlur(event);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="gender"
                    key="gender"
                    label="Gender"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={gender}
                    values={values}
                    className={styles.formField}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="bloodGroup"
                    key="bloodGroup"
                    label="Blood Group *"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={bloodGroup}
                    values={values}
                    className={styles.formField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="nationality"
                    type="string"
                    label="Nationality"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="personalEmail"
                    type="email"
                    label="Personal Email"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    className={styles.formField}
                    name="dob"
                    type="date"
                    label="Date of Birth"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IN"
                    id="phone"
                    name="phone"
                    label="Phone number"
                    size="small"
                    value={values.phone}
                    onChange={(newValue, info) => {
                      setFieldValue('phone', info.numberValue);
                    }}
                    forceCallingCode
                    splitCallingCode
                    focusOnSelectCountry
                    required
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone ? (errors.phone as any) : null}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="linkedinUrl"
                    type="string"
                    label="LinkedIn URL"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Button
                disabled={!dirty || !isValid}
                variant="contained"
                type="submit"
                className="upload-button">
                {intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_SAVE,
                })}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewCandidateBasicDetails;
