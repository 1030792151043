import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useSearchStyles from './SearchStyles';
import SearchClient from '../../api/Search/searchAPIs';
import { getFullName } from '../../utils/utils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { allSearchOptions } from './utils';
import { formatEmployeeList, formatProjectList } from '../Search/utils';

const Search = () => {
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);

  const navigate = useNavigate();
  const [selectedSearchOption, setSelectedSearchOption] = useState<any>(
    queryParams.searchBy ? queryParams.searchBy : 'name',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [allProjectList, setAllProjectList] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [initialFields, setInitialFields] = useState({
    searchBy: allSearchOptions.find((v) => v.label.toLowerCase() === selectedSearchOption),
    search: null,
  });
  const [key, setKey] = useState(0);

  const styles = useSearchStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const validationSchema = Yup.object().shape({
    searchBy: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .typeError('Search By field is required'),
    search: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .typeError('Search field is required'),
  });

  const handleSearchOptionChange = (values: any) => {
    setSelectedSearchOption(values.label?.toLowerCase());
    navigate({
      search: createSearchParams({
        searchBy: values.label?.toLowerCase(),
      }).toString(),
    });
    if (values.value === 0) {
      setAllOptions(allEmployeeList);
    } else {
      setAllOptions(allProjectList);
    }
    const newInitialFields = { searchBy: values, search: null };
    setInitialFields(newInitialFields);
    setKey(key + 1);
  };

  const handleSubmit = (values: any) => {
    navigate(`/search/${values.search.value}?searchBy=${selectedSearchOption}`);
  };

  useEffect(() => {
    setIsLoading(true);
    SearchClient.getAllEmpList()
      .then((response) => {
        setAllEmployeeList(formatEmployeeList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    SearchClient.getAllProjectList()
      .then((response) => {
        setAllProjectList(formatProjectList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allEmployeeList.length) {
      if (selectedSearchOption === 'name') setAllOptions(allEmployeeList);
      else setAllOptions(allProjectList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployeeList]);

  return (
    <>
      <Box className={styles.header}>Search</Box>
      <Box className={styles.searchWrapper}>
        <Formik
          initialValues={initialFields}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          key={key}
          enableReinitialize>
          {(formik) => {
            const { setFieldValue, dirty, isValid, values } = formik;
            return (
              <>
                <Form>
                  {isLoading ? (
                    <Box className={styles.spinnerWrapper}>
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Box className={styles.formWrapper}>
                          <SimpleAutocompleteField
                            name="searchBy"
                            key="searchBy"
                            placeholder="Search by"
                            size="small"
                            setFieldValue={setFieldValue}
                            handleChange={(e: any, v: any) => {
                              handleSearchOptionChange(v);
                            }}
                            className={styles.formField}
                            options={allSearchOptions}
                            values={values}
                            disableClearable
                            required
                            fullWidth
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box className={styles.formWrapper}>
                          <SimpleAutocompleteField
                            name="search"
                            key="search"
                            placeholder="Search"
                            size="small"
                            setFieldValue={setFieldValue}
                            className={styles.formField}
                            options={allOptions}
                            values={values}
                            freeSolo
                            required
                            fullWidth
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.searchBtn}
                          type="submit"
                          variant="contained"
                          disabled={!dirty || !isValid}>
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default Search;
