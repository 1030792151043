import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useResourceDetailStyles = makeStyles((theme: Theme) => {
  return {
    Header: {
      position: 'relative',
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
    },
    projectInfoWrapper: {
      backgroundColor: '#fff',
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '10px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.tertiary?.main}`,
    },
    projectInfo: {
      display: 'flex',

      '& p': {
        margin: '5px 0 0 0',
      },
    },
    projectInfoLabel: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      textAlign: 'center',
      color: '#173F74',
    },
    projectInfoValue: {
      fontSize: '16px',
      fontWeight: '700',
      textAlign: 'center',
      color: '#173F74',
    },
    form: {
      width: '100%',
    },
    resourceTabWrapper: {
      '& .MuiTabs-root': {
        minHeight: '32px',
        marginTop: '20px',
      },

      '& .MuiTabs-flexContainer > button ': {
        padding: '0 !important',
        minHeight: '32px',
        width: '100px',
      },
      marginBottom: '20px',
    },
  };
});

export default useResourceDetailStyles;
