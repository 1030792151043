/* eslint-disable no-param-reassign */
import axios from 'axios';
import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import {
  CandidateStausParams,
  GetCompensationParams,
  PutCompensationLetterParams,
  ShareCompensationLetterParams,
  ShareOfferLetterParams,
  GetGenerateInternshipParams,
  NewInternshipLetterData,
} from './DocumentTypes';
import { getSalaryRevisionDates } from '../../pages/Documents/utils';

export const PATHS = Object.freeze({
  GET_DOCUMENT: '/document/',
  GET_INTERNSHIP: '/document/internshipLetter',
  OFFER_LETTER: '/letters/candidate/fulltime/generate',
  INTERNSHIP_LETTER: '/letters/internship/generate',
  DELETE_OFFER_LETTER: '/letters/delete',
  CANDIDATE_LIST: '/letters/candidate',
  INTERN_LIST: '/letters/internship/candidate',
  GET_EMPLOYEE: '/employee/all_details',
  SHARE_OFFER_LETTER: (candidate_uid: string) => `/letters/candidate/${candidate_uid}/share`,
  SHARE_INTERNSHIP_LETTER: (candidate_uid: string) => `/letters/internship/${candidate_uid}/share`,
  GET_COMPENSATION_LIST: '/letters/salary_revision/employees',
  GET_EMPLOYEE_DETAIL: '/letters/salary_revision/employee',
  PUT_COMPENSATION_LETTER: '/letters/salary_revision/generate',
  SHARE_COMPENSATION_LETTER: (uid: string, startDate: any, endDate: any) =>
    `/letters/salary_revision/${uid}/share?start_date=${startDate}&end_date=${endDate}`,
});

const generateOfferLetter = async (params: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.OFFER_LETTER, params);
  return response;
};

const downloadDocument = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

const deleteDocument = async (params: any): Promise<any> => {
  const queryParams = queryString.stringify(params);

  const response = await axiosInstance.put(`${PATHS.DELETE_OFFER_LETTER}?${queryParams}`);
  return response;
};

const getCandidateList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.CANDIDATE_LIST}/`);
  return response;
};

const getCandidateOfferLetterDetails = async (candidateUid: any): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.CANDIDATE_LIST}/${candidateUid}`);
  return response;
};
const getEmployeeOfferLetterDetails = async (
  employeeUid: any,
  salaryRevisionYear: any,
): Promise<any> => {
  const { startDate, endDate } = getSalaryRevisionDates(salaryRevisionYear);
  const queryParams = queryString.stringify({
    employee_uid: employeeUid,
    start_date: startDate,
    end_date: endDate,
  });
  const response = await axiosInstance.get(`${PATHS.GET_EMPLOYEE_DETAIL}?${queryParams}`);
  return response;
};

const saveCandidateDetails = async (
  candidateUid: string,
  saveCandidateDetailsParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.CANDIDATE_LIST}/${candidateUid}`,
    saveCandidateDetailsParams,
  );
  return response;
};

const deleteCandidate = async (candidate_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CANDIDATE_LIST}/${candidate_uid}`);
  return response;
};

const updateCandidateStaus = async (
  UpdateCandidateStausParams: CandidateStausParams[],
): Promise<any> => {
  const response = await axiosInstance.patch(
    `${PATHS.CANDIDATE_LIST}/`,
    UpdateCandidateStausParams,
  );
  return response;
};

const shareOfferLetter = async (
  candidateId: string,
  shareOfferLetterParams: ShareOfferLetterParams,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.SHARE_OFFER_LETTER(candidateId),
    shareOfferLetterParams,
  );
  return response;
};

const getEmployeeList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_EMPLOYEE}?`, {
    params: { status: 'Active', page: 0, limit: 0 },
  });
  return response;
};

const getAllCompensationList = async (
  getCompensationParams: GetCompensationParams,
): Promise<any> => {
  const queryParams = queryString.stringify(getCompensationParams);

  const response = await axiosInstance.get(`${PATHS.GET_COMPENSATION_LIST}?${queryParams}`);
  return response.data;
};

const putCompensationLetter = async (
  compensationLetter: any,
  CompensationLetterParams: PutCompensationLetterParams,
  salaryRevisionYear: any,
): Promise<any> => {
  const { startDate, endDate } = getSalaryRevisionDates(salaryRevisionYear);
  CompensationLetterParams.start_date = startDate;
  CompensationLetterParams.end_date = endDate;
  const response = await axiosInstance.put(PATHS.PUT_COMPENSATION_LETTER, compensationLetter, {
    params: CompensationLetterParams,
  });
  return response;
};

const shareCompensationLetter = async (
  uid: string,
  shareCompensationLetterParams: any,
  salaryRevisionYear: any,
): Promise<any> => {
  const { startDate, endDate } = getSalaryRevisionDates(salaryRevisionYear);
  const response = await axiosInstance.put(
    PATHS.SHARE_COMPENSATION_LETTER(uid, startDate, endDate),
    shareCompensationLetterParams,
  );
  return response;
};

const downloadLetter = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

const getInternList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.INTERN_LIST}`);
  return response;
};

const getInternsOfferLetterDetails = async (candidate_uid: any): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.INTERN_LIST}/${candidate_uid}`);
  return response;
};

const generateInternshipLetter = async (
  getGenerateInternshipParam: GetGenerateInternshipParams,
  payload: any,
): Promise<any> => {
  const response = await axiosInstance.put(PATHS.INTERNSHIP_LETTER, payload, {
    params: getGenerateInternshipParam,
  });
  return response;
};

const shareInternshipLetter = async (
  candidateId: string,
  shareInternshipLetterParams: ShareOfferLetterParams,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.SHARE_INTERNSHIP_LETTER(candidateId),
    shareInternshipLetterParams,
  );
  return response;
};

const generateNewInternshipLetter = async (
  newInternshipLetterData: NewInternshipLetterData,
): Promise<any> => {
  const response = await axiosInstance.post(PATHS.INTERNSHIP_LETTER, newInternshipLetterData);
  return response;
};

export interface DocumentAPIClient {
  readonly generateOfferLetter: (params: any, salaryRevisionYear: any) => Promise<any>;
  readonly downloadDocument: (params: any) => Promise<any>;
  readonly deleteDocument: (params: any) => Promise<any>;
  readonly getCandidateOfferLetterDetails: (params: any) => Promise<any>;
  readonly saveCandidateDetails: (candidateUid: string, params: any) => Promise<any>;
  readonly getCandidateList: () => Promise<any>;
  readonly deleteCandidate: (candidate_uid: string) => Promise<any>;
  readonly updateCandidateStaus: (
    UpdateCandidateStausParams: CandidateStausParams[],
  ) => Promise<any>;
  readonly shareOfferLetter: (candidateId: string, params: ShareOfferLetterParams) => Promise<any>;
  readonly getEmployeeList: () => Promise<any>;
  readonly getAllCompensationList: (getCompensationParams: GetCompensationParams) => Promise<any>;
  readonly getEmployeeOfferLetterDetails: (
    employee_uid: any,
    salaryRevisionYear: any,
  ) => Promise<any>;
  readonly putCompensationLetter: (
    compensationLetter: any,
    CompensationLetterParams: PutCompensationLetterParams,
    salaryRevisionYear: any,
  ) => Promise<any>;
  readonly shareCompensationLetter: (
    uid: string,
    shareCompensationLetterParams: any,
    salaryRevisionYear: any,
  ) => Promise<any>;
  readonly downloadLetter: (params: any) => Promise<any>;
  readonly getInternList: () => Promise<any>;
  readonly getInternsOfferLetterDetails: (candidate_uid: any) => Promise<any>;
  readonly generateInternshipLetter: (
    getGenerateInternshipParam: GetGenerateInternshipParams,
    payload: any,
  ) => Promise<any>;
  readonly shareInternshipLetter: (
    candidateId: string,
    shareInternshipLetterParams: ShareOfferLetterParams,
  ) => Promise<any>;
  readonly generateNewInternshipLetter: (params: any) => Promise<any>;
}

const DocumentClient: DocumentAPIClient = Object.freeze({
  generateOfferLetter,
  downloadDocument,
  deleteDocument,
  getCandidateOfferLetterDetails,
  getCandidateList,
  saveCandidateDetails,
  deleteCandidate,
  updateCandidateStaus,
  shareOfferLetter,
  getEmployeeList,
  getAllCompensationList,
  getEmployeeOfferLetterDetails,
  putCompensationLetter,
  shareCompensationLetter,
  downloadLetter,
  getInternList,
  getInternsOfferLetterDetails,
  generateInternshipLetter,
  shareInternshipLetter,
  generateNewInternshipLetter,
});

export default DocumentClient;
