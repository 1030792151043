import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useCategoryStyles from './CategoryStyles';

interface AddCategoryFormProps {
  readonly handleSubmit?: any;
  readonly isEdit?: boolean;
  readonly category: any;
}
const AddCategoryForm: React.FC<AddCategoryFormProps> = ({
  category,
  handleSubmit,
  isEdit,
}: any) => {
  const styles = useCategoryStyles();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  });

  return (
    <>
      <Box className={styles.categoryWrapper}>
        <Formik
          initialValues={category}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize>
          {(formik) => {
            const { values, isValid, dirty } = formik;
            return (
              <>
                <Form>
                  <Grid container rowSpacing={2} spacing={1}>
                    <Grid item xs={12}>
                      <SimpleInputField
                        label="Name"
                        name="name"
                        type="string"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleInputField
                        label="Description"
                        name="description"
                        type="string"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                    {isEdit ? 'Edit' : 'Add'}
                  </Button>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AddCategoryForm;
