import axiosInstance from '../axiosInstance';
import { GetTimeSheetParams } from './timeSheetTypes';

export const PATHS = Object.freeze({
  ADD_UPDATE_TIMESHEET: '/employee/timesheet',
  GET_TIMESHEET: '/employee/timesheet',
});

const updateTimeSheetInfo = async (updateTimeSheetInfoParams: any, params?: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.ADD_UPDATE_TIMESHEET, updateTimeSheetInfoParams, {
    params,
  });
  return response;
};

const getTimeSheetInfo = async (getTimeSheetParams: GetTimeSheetParams): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_TIMESHEET, { params: getTimeSheetParams });
  return response;
};
export interface TimeSheetAPIClient {
  readonly updateTimeSheetInfo: (updateTimeSheetInfoParams: any, queryParams?: any) => Promise<any>;
  readonly getTimeSheetInfo: (getTimeSheetParams: GetTimeSheetParams) => Promise<any>;
}
const TimeSheetClient: TimeSheetAPIClient = Object.freeze({
  updateTimeSheetInfo,
  getTimeSheetInfo,
});

export default TimeSheetClient;
