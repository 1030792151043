import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useHomeStyles from '../../pages/Home/HomeStyles';

interface CustomSliderProps {
  images: any;
  interval: number;
}
const CustomSlider: React.FC<CustomSliderProps> = ({ images, interval }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const styles = useHomeStyles();

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => {
      clearInterval(slideInterval);
    };
  }, [images.length, interval]);

  const handleClick = (index: any) => {
    setActiveIndex(index);
  };

  return (
    <Box className={styles.homeBannerWrapper}>
      <Carousel
        selectedItem={activeIndex}
        onChange={handleClick}
        autoPlay
        infiniteLoop
        interval={interval}
        showArrows={false}
        showStatus={false}
        showThumbs={false}>
        {images.map((image: any, index: any) => (
          <Box>
            <img src={image} alt={`slide ${index + 1}`} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CustomSlider;
