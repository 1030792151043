import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleCheckboxField from '../../../components/SimpleCheckboxField/SimpleCheckboxField';
import MisDialog from '../../../components/MisDialog/MisDialog';
import AddTask from './AddTaskComponent';
import I18nKey from '../../../translations/I18nKey';
import useToDoStyles from './ToDoStyles';
import EmployeeClient from '../../../api/Employee/employeeAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../../contexts/GlobalContext';
import EditTask from './EditTaskComponent';

interface TodoListProps {
  readonly toDoList: any;
  readonly setToDoList: any;
  readonly getToDoList: any;
  readonly isProfile?: boolean;
}

const ToDoList: React.FC<TodoListProps> = ({ toDoList, setToDoList, getToDoList, isProfile }) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useToDoStyles();
  const intl = useIntl();
  const { employeeUid } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();
  const task = { task: '', completed: false };
  const [newTask, setNewTask] = useState<any>(task);
  const [selectedTask, setSelectedTask] = useState<any>({});
  const [editSelectedTask, setEditSelectedTask] = useState<any>();

  const addTask = (e: any) => {
    setNewTask({});
    setOpenAddDialog(true);
  };

  const editTask = (todo: any) => {
    setOpenEditDialog(true);
    setEditSelectedTask(todo);
  };

  const deleteTask = (todo: any) => {
    setOpenDeleteDialog(true);
    setSelectedTask(todo);
  };

  const updateEmployeeTodoList = (editTodoListParams: any) => {
    setIsLoading(true);
    if (employeeUid) {
      EmployeeClient.updateEmployeeTodos(editTodoListParams)
        .then((result) => {
          showSnackbar(result, 'success');
          getToDoList();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleAdd = () => {
    setIsLoading(true);
    const addTodolistParams = [
      {
        todo: newTask.task,
        status: 'not_started',
        isSystem: false,
      },
    ];
    if (employeeUid) {
      EmployeeClient.addEmployeeTodos(addTodolistParams)
        .then((result) => {
          showSnackbar(result, 'success');
          getToDoList();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
    setOpenAddDialog(false);
    setNewTask(task);
  };

  const handleEdit = () => {
    setOpenEditDialog(false);
    const editTodoListParams = [
      {
        uid: editSelectedTask.uid,
        todo: editSelectedTask.task,
        status: editSelectedTask.status,
        // isSystem: selectedTask.isSystem,
      },
    ];
    updateEmployeeTodoList(editTodoListParams);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    setIsLoading(true);
    const deleteTodoListParams = selectedTask.uid;
    if (employeeUid) {
      EmployeeClient.deleteEmployeeTodos(deleteTodoListParams)
        .then((result) => {
          showSnackbar(result, 'success');
          getToDoList();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    setOpenAddDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };

  const handleChange = (name: any, value: any) => {
    const taskIndex = toDoList.findIndex((t: any) => t.task === name);
    // eslint-disable-next-line no-param-reassign
    toDoList[taskIndex].completed = !toDoList[taskIndex].completed;
    if (toDoList[taskIndex].completed) {
      // eslint-disable-next-line no-param-reassign
      toDoList[taskIndex].status = 'completed';
    } else {
      // eslint-disable-next-line no-param-reassign
      toDoList[taskIndex].status = 'not_started';
    }
    setToDoList((prevList: any) => [...toDoList]);
    // TODO: API Call
    const editTodoListParams = [
      {
        uid: toDoList[taskIndex].uid,
        todo: toDoList[taskIndex].task,
        status: toDoList[taskIndex].status,
        // isSystem: selectedTask.isSystem,
      },
    ];
    updateEmployeeTodoList(editTodoListParams);
  };

  return (
    <>
      {!isProfile && (
        <Box className={classes.header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.PROFILE_TO_DO_LIST,
            })}
          </span>
        </Box>
      )}
      <Box className={toDoList.length ? classes.CardWrapper : classes.noContent}>
        <Box className={isProfile ? classes.CardHeader : classes.HomePageCardHeader}>
          {isProfile && (
            <Typography fontWeight={600}>
              {intl.formatMessage({
                id: I18nKey.PROFILE_TO_DO_LIST,
              })}
            </Typography>
          )}
          <IconButton onClick={addTask} className={classes.AddButton}>
            <AddIcon />
          </IconButton>
        </Box>

        <Box className={classes.CardContent}>
          {isLoading ? (
            <Box className={classes.spinnerWrapper}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={12} className={classes.ListItem}>
                {toDoList.length ? (
                  toDoList.map((i: any, index: number) => {
                    return (
                      <Box className={classes.lineWrapper}>
                        <Box className={classes.itemWrapper}>
                          <SimpleCheckboxField
                            name={i.task}
                            checked={i.completed}
                            // eslint-disable-next-line react/no-array-index-key
                            key={i.task + index}
                            handleChange={handleChange}
                          />
                          <Box className={classes.element} onClick={() => editTask(i)}>
                            {i.task}
                          </Box>
                        </Box>
                        <DeleteIcon
                          className={classes.deleteButton}
                          onClick={() => deleteTask(i)}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography className={isProfile ? classes.noDataProfile : classes.noData}>
                    No Task Added
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROFILE_TO_DO_LIST,
        })}
        isOpen={openAddDialog}
        handleSuccess={handleAdd}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_ADD,
        })}
        additionalInfoSection={<AddTask newTask={newTask} setNewTask={setNewTask} />}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROFILE_EDIT_TASK,
        })}
        isOpen={openEditDialog}
        handleSuccess={handleEdit}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_UPDATE,
        })}
        additionalInfoSection={
          <EditTask editSelectedTask={editSelectedTask} setEditSelectedTask={setEditSelectedTask} />
        }
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROFILE_DELETE_TASK,
        })}
        isOpen={openDeleteDialog}
        message={intl.formatMessage({
          id: I18nKey.PROFILE_REMOVE_TODO_TASK,
        })}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_REMOVE,
        })}
      />
    </>
  );
};

ToDoList.defaultProps = {
  isProfile: false,
};

export default ToDoList;
