/* eslint-disable prefer-template */
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useModalPopupStyles = makeStyles(() => {
  return {
    popupWrapper: {
      backgroundColor: theme.palette.primary?.light,
      width: '450px',
      borderRadius: '8px 8px 8px 8px',
      boxShadow: ' 0px 0px 4px ' + theme.palette.shadow?.main,
      position: 'fixed',
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.secondary?.dark,
      borderRadius: '8px 8px 0px 0px',
      color: theme.palette.primary?.light,
      '& span': {
        padding: '0 15px 0 15px',
      },
    },
    content: {
      overflow: 'auto',
      height: '18vh !important',
      padding: '1px !important',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeBtn: {
      margin: '7px !important',
      '& > span': {
        textTransform: 'none',
      },
    },
    successBtn: {
      margin: '7px !important',
      backgroundColor: `${theme.palette.text.midDark} !important`,
    },
    modalWrapper: {
      position: 'absolute' as 'absolute',
      top: '65%',
      left: '65%',
      transform: 'translate(-50%, -50%)',
    },
    closeIconBox: {
      textAlign: 'right',
    },
    closeIcon: {
      cursor: 'pointer',
      padding: '2px',
    },
    boxCenter: {
      textAlign: 'center',
    },
  };
});

export default useModalPopupStyles;
