import { MailOutline, PhoneEnabledOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import EmployeeClient from '../../api/Employee/employeeAPIs';
import { getFullName } from '../../pages/Opportunity/entitiesFormConfig';
import { getUserAttributes } from '../../utils/auth';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import useCardStyles from './ProfileSideCardStyles';

const ProfileSideCard = () => {
  const [collapsed, setCollapsed] = useState<number | null>();
  const [isLoading, setIsLoading] = useState(false);

  const [cardInfo, setCardInfo] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const fetchedInfo = getUserAttributes();
    EmployeeClient.getEmployeeProfile()
      .then((res) => {
        const newCardInfo = { ...res.data };
        newCardInfo.profileImage = fetchedInfo?.attributes?.picture;
        setCardInfo(newCardInfo);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const personalDetail = [
    {
      label: 'Street',
      value: cardInfo?.personalDetails?.currentAddress || '',
    },
    {
      label: 'City',
      value: cardInfo?.personalDetails?.currentCity || '',
    },
    {
      label: 'State',
      value: cardInfo?.personalDetails?.currentState || '',
    },
    {
      label: 'Phone Number',
      value: cardInfo?.personalDetails?.phone || '',
    },
    {
      label: 'Zip Code',
      value: cardInfo?.personalDetails?.currentPincode || '',
    },
    {
      label: 'Country',
      value: cardInfo?.personalDetails?.currentCountry || '',
    },
    {
      label: 'LinkedIn URL',
      value: cardInfo?.personalDetails?.linkedinUrl || '',
    },
  ];

  const organizationDetail = [
    {
      label: 'Reporting Manager',
      value:
        getFullName({
          firstName: cardInfo?.organisationDetails?.managerFirstName || '',
          middleName: cardInfo?.organisationDetails?.managerMiddleName || '',
          lastName: cardInfo?.organisationDetails?.managerLastName || '',
        }) || '',
    },
    {
      label: 'Department',
      value: cardInfo?.organisationDetails?.department || '',
    },
    {
      label: 'Date Of Joining',
      value: dayjs(cardInfo?.personalDetails?.dateOfJoining).format('DD MMM YYYY') || '',
    },
  ];

  const deviceDetail = [
    {
      label: 'Make',
      value: cardInfo?.registeredDevice?.make || '',
    },
    {
      label: 'Model',
      value: cardInfo?.registeredDevice?.model || '',
    },
    {
      label: 'OS',
      value: cardInfo?.registeredDevice?.operatingSystem || '',
    },
  ];

  const styles = useCardStyles();

  const handleCollapse = (index: number) => setCollapsed(index === collapsed ? null : index);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}

      <Box className={styles.CardWrapper}>
        <Box className={styles.CardImageWrapper}>
          <Avatar
            alt={cardInfo?.personalDetails?.firstName?.toUpperCase()}
            src={cardInfo.profileImage}
          />
        </Box>
        <Box className={styles.CardTitleWrapper}>
          <p className={`${styles.TitleText} ${styles.bold}`}>
            {cardInfo?.personalDetails && getFullName(cardInfo?.personalDetails)}
          </p>
          <p className={styles.TitleText}>{cardInfo?.personalDetails?.designation}</p>
          <p className={styles.TitleText}>ID: {cardInfo?.personalDetails?.employeeId}</p>
        </Box>
        <Box className={styles.MidSectionWrapper}>
          <Box className={styles.MidSectionItem}>
            <PhoneEnabledOutlined />
            <Tooltip title={cardInfo?.personalDetails?.phone}>
              <Typography>{cardInfo?.personalDetails?.phone}</Typography>
            </Tooltip>
          </Box>
          <Box className={styles.MidSectionItem}>
            <MailOutline />
            <Tooltip title={cardInfo?.personalDetails?.email}>
              <Typography>{cardInfo?.personalDetails?.email}</Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box className={styles.PersonalDetailWrapper}>
          {personalDetail.map((detail) => (
            <Box className={styles.info} key={detail.label}>
              <Box className={`${styles.labelText} ${styles.bold}`}>{detail.label} :</Box>
              <Box className={styles.text}>{detail.value}</Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.CardFooterWrapper}>
          <List>
            <ListItemButton onClick={() => handleCollapse(1)}>
              <img
                className={
                  collapsed === 1 ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`
                }
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText primary={'Organizational Information'} />
            </ListItemButton>
            <Collapse in={collapsed === 1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Box className={styles.innerSectionWrapper}>
                  {organizationDetail.map((detail) => (
                    <Box className={styles.info} key={detail.label}>
                      <Box className={`${styles.labelText} ${styles.bold}`}>{detail.label} :</Box>
                      <Box className={styles.text}>{detail.value}</Box>
                    </Box>
                  ))}
                </Box>
              </List>
            </Collapse>
            <ListItemButton onClick={() => handleCollapse(2)}>
              <img
                className={
                  collapsed === 2 ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`
                }
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText primary={'Access Settings'} />
            </ListItemButton>
            <Collapse in={collapsed === 2} timeout="auto" unmountOnExit>
              <Box className={styles.accessWrapper}>
                {cardInfo?.accessSettings?.groupNames.map((role: string, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.access} key={role + index}>
                    {role}
                  </div>
                ))}
              </Box>
            </Collapse>
            <ListItemButton onClick={() => handleCollapse(3)}>
              <img
                className={
                  collapsed === 3 ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`
                }
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText primary={'Device Registered'} />
            </ListItemButton>
            <Collapse in={collapsed === 3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Box className={styles.innerSectionWrapper}>
                  {deviceDetail.map((detail) => (
                    <Box className={styles.info} key={detail.label}>
                      <Box className={`${styles.labelText} ${styles.bold}`}>{detail.label} :</Box>
                      <Box className={styles.text}>{detail.value}</Box>
                    </Box>
                  ))}
                </Box>
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default ProfileSideCard;
