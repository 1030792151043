import { useEffect, useState } from 'react';
import { Button, Typography, Grid, CircularProgress, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker/CustomDateTimePicker';
import useInterviewLevelTabs from '../InterviewLevelTabsStyle';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import InterviewClient from '../../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { InterviewStatus } from '../utils';

interface ApplicationStatusLevelFormProps {
  readonly formRef: any;
  readonly formState: any;
  readonly interviewRoundName: string;
  readonly isHRAccess?: boolean;
  readonly setIsDetailsLoading: any;
  readonly interviewersData: any;
  readonly candidateId: any;
  readonly updateFetchCandidateList?: any;
  readonly updateFetch: any;
}
const ApplicationStatusLevelForm: React.FC<ApplicationStatusLevelFormProps> = ({
  formRef,
  formState,
  interviewRoundName,
  interviewersData,
  setIsDetailsLoading,
  candidateId,
  isHRAccess,
  updateFetchCandidateList,
  updateFetch,
}: any) => {
  const styles = useInterviewLevelTabs();
  const { showSnackbar } = useDisplaySnackbar();

  const getInterviewUid = (currentInterviewUid = '') => {
    if (currentInterviewUid) {
      return { interviewUid: currentInterviewUid };
    }
    return {};
  };

  const handleInterviewSchedule = (scheduleData: any) => {
    setIsDetailsLoading(true);
    InterviewClient.putInterviewSchedule(scheduleData)
      .then((res) => {
        updateFetch();
        updateFetchCandidateList();
        showSnackbar(res, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  const handleSubmit = (v: any) => {
    const selectedDateTime = dayjs(v.datetime);
    const formattedDate = selectedDateTime.format('YYYY-MM-DD');
    const formattedTime = selectedDateTime.format('h:mm A');
    const scheduleData = {
      ...getInterviewUid(v?.interviewUid),
      candidateUid: candidateId,
      interviewerUid: v.selectInterviewer.value,
      round: interviewRoundName,
      scheduledDate: formattedDate,
      scheduledTime: formattedTime,
      videoCallLink: v.meetingLink,
      status: InterviewStatus.SCHEDULED,
    };
    handleInterviewSchedule(scheduleData);
  };

  const validationSchema = Yup.object().shape({
    selectInterviewer: Yup.object()
      .shape({ label: Yup.string(), code: Yup.string() })
      .typeError('Select interviewer is required'),
    datetime: Yup.date().required('Date and time is required'),
    meetingLink: Yup.string().nullable(),
  });

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, errors } = formik;
          return (
            <>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <SimpleAutocompleteField
                      name="selectInterviewer"
                      key="selectInterviewer"
                      placeholder="Select interviewer"
                      label="Select interviewer"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={interviewersData}
                      values={values}
                      disabled={!isHRAccess}
                      required
                    />
                  </Grid>
                  <Grid item xs={10} className={styles.dateTimeWrapper}>
                    <CustomDateTimePicker
                      name="datetime"
                      label="Select Date and time"
                      value={values.datetime}
                      fullWidth
                      onChange={(v: any) => setFieldValue('datetime', v)}
                      disabled={!isHRAccess}
                    />
                  </Grid>
                  {/* <Grid item xs={10}>
                    <SimpleInputField
                      label="Meeting Link"
                      name="meetingLink"
                      type="string"
                      size="small"
                      multiline
                      fullWidth
                    />
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    {isSubmitted ? (
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Button variant="outlined">edit</Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={styles.editTitle}>
                            <CheckCircleIcon className={styles.editIcons} />
                            Invite has been Sent
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid> */}
                </Grid>
                {isHRAccess && (
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={!dirty || !isValid}
                    className={styles.submitButton}>
                    Send invite
                  </Button>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

ApplicationStatusLevelForm.defaultProps = {
  updateFetchCandidateList: () => {},
};

export default ApplicationStatusLevelForm;
