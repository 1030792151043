export const billingStatusOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'NA', value: null },
];

export const getFormattedProjects = (projects: any) => {
  return projects.map((el: any) => {
    return { label: el.projectName, value: el.projectUid, accountType: el.accountType };
  });
};
