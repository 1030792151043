/* eslint-disable react-hooks/rules-of-hooks */
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ExpandableLink from '../../components/ExpandableLink/ExpandableLink';
import { dateFromatter, sortAlphaNumeric } from '../../utils/utils';

const dynamicColumns: any[] = [];
const tableRowTotalField: any = [];
const initialSort = { field: 'employeeId', sort: 'desc' };

const getColumns = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setExpanded] = useState(true);
  const handleExpanded = (rowId: any) => {
    setExpanded(!isExpanded);
  };
  return [
    {
      field: 'employeeId',
      type: 'number',
      headerName: 'Id',
      headerAlign: 'left',
      width: 62,
      sortable: true,
      cellClassName: 'left-align-number-field',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/onboarding/addEmployee/${params.row.employeeUid}/${params.row.status}`}>
            {params.formattedValue}
          </Link>
        );
      },
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Name',
      flex: 2,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/onboarding/addEmployee/${params.row.employeeUid}/${params.row.status}`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    {
      field: 'managerName',
      type: 'string',
      headerName: 'Manager',
      flex: 2,
      hide: true,
      sortable: true,
    },
    {
      field: 'designationName',
      type: 'string',
      headerName: 'Designation',
      flex: 2,
      sortable: true,
    },
    {
      field: 'employeeType',
      type: 'string',
      headerName: 'Type',
      flex: 1,
      sortable: true,
    },
    {
      field: 'primarySkills',
      type: 'string',
      headerName: 'Primary Skill(s)',
      flex: 1.5,
      sortable: true,
      hideable: true,
      hide: true,
      cellClassName: isExpanded ? 'expanded-cell' : 'employee-skill-cell',
      renderCell: (params: GridRenderCellParams) => {
        const cellDisplay = params?.value?.map((skill: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="employee-skill" key={skill + index}>
            {skill}
          </div>
        ));
        const renderParams = isExpanded ? cellDisplay : cellDisplay?.slice(0, 2);
        return renderParams?.concat(
          <ExpandableLink {...params} handleExpanded={handleExpanded} isExpanded={isExpanded} />,
        );
      },
    },
    {
      field: 'otherSkills',
      type: 'string',
      headerName: 'Other Skill(s)',
      flex: 1.5,
      sortable: true,
      hide: true,
      cellClassName: 'employee-skill-cell',
      renderCell: (params: GridCellParams) => {
        return params?.value?.map((skill: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="employee-skill" key={skill + index}>
            {skill}
          </div>
        ));
      },
    },
    {
      field: 'doj',
      type: 'string',
      headerName: 'DOJ',
      flex: 1,
      filterable: false,
      valueFormatter: (params: any) => dateFromatter(params.value),
    },
  ];
};
const employeeUid = 'uid';

export const getFirstLastName = (params: any) => {
  const { firstName, lastName } = params;
  return firstName.concat(' ', lastName);
};

const getProcessFields = (allFields: any) => {
  const requiredFields = { ...allFields };
  const processFields = { ...requiredFields };
  Object.keys(requiredFields).forEach((field: any) => {
    if (allFields[field] === '' || allFields[field] === null) delete processFields[field];
  });
  return processFields;
};

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const colRange = getColumns();
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const initialShowCols = ['employeeId', 'employeeName', 'designationName', 'employeeType', 'doj'];

export {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  employeeUid,
  getProcessFields,
  getInitialVisibilityModel,
  initialShowCols,
};
