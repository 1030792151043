import axiosInstance from '../axiosInstance';
import { ContractList } from './contractTypes';

export const PATHS = Object.freeze({
  GET_CONTRACT: `/project/contract/`,
  GET_CONTRACT_PROJECT_DETAIL: (project_id: string) => `/project/${project_id}`,
});

const getContractList = async (): Promise<ContractList> => {
  const response = await axiosInstance.get(`${PATHS.GET_CONTRACT}`);
  return response.data;
};

export interface ContractAPIClient {
  readonly getContractList: () => Promise<any>;
}

const ContractClient: ContractAPIClient = Object.freeze({
  getContractList,
});

export default ContractClient;
