import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import indianFestival from '../../assets/indian-festival.jpg';

const useHomeStyles = makeStyles((theme: Theme) => {
  return {
    homeWrapper: {
      padding: '1rem',
    },
    header: {
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '2px',
      marginBottom: '8px',
      marginTop: '-20px',
    },
    holidayHeader: {
      fontSize: '25px',
      fontWeight: '800',
      textAlign: 'center',
      marginTop: '12px !important',
    },
    quickLinksWrapper: {
      height: '300px',
      background: theme.palette.primary.main,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '12px',
      padding: '30px 0px',
      '& .MuiBox-root css-0': {
        marginBottom: '-10px',
      },
    },
    itemWrapper: {
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    routeItem: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderRadius: 12,
      minHeight: 130,
      // border: '1px solid #EBEBEB',
    },
    MidSectionContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    ModuleIcon: {
      width: '40px',
      height: '40px',
    },
    label: {
      fontSize: '12px !important',
    },
    tableHeadWrapper: {
      fontSize: '20px !important',
      padding: '12px !important',
      fontWeight: '700 !important',
    },
    holidayWrapper: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'calc(100vh - 64px)',
      background: `url(${indianFestival})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: 0.8,
    },
    tableWrapper: {
      width: '80% !important',
      marginTop: '15px',
      boxShadow: '0px 6px 6px #111 !important',
    },
    emailTableWrapper: {
      width: '60% !important',
      marginTop: '40px',
      marginLeft: '350px',
    },
    rowData: {
      fontSize: '14px !important',
      padding: '12px !important',
      fontWeight: '600 !important',
    },
    emailRowData: {
      fontSize: '19px !important',
    },
    todoListWrapper: { height: '300px' },
    homeBannerWrapper: {
      '& .carousel': {
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25) !important',
        borderRadius: '16px !important',
      },
    },
  };
});

export default useHomeStyles;
