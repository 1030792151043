import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useSkillStyles = makeStyles((theme: Theme) => {
  return {
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      minHeight: '15vh',
    },
    UpperCardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      padding: '0 1rem',
      alignItems: 'center',
      overflowY: 'auto',
    },
    DocCardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
    },
    UpperCardHeader: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    AddButton: {
      padding: 'initial !important',
      color: `${theme.palette.text.light} !important`,
    },
    UpperCardFieldsKey: { fontSize: '12px' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1.5rem',
      position: 'relative',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      paddingBottom: '3.6rem',
    },
    ChipSpace: {
      marginRight: '5px',
      marginBottom: '5px',
      display: 'inline-flex',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
    },
    SkillsSubTitle: {
      fontSize: '14px !important',
      marginBottom: '5px !important',
    },
    EmptyCard: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      maxHeight: '45vh',
      minHeight: '18vh',
    },
    noData: {
      textAlign: 'center',
      margin: 'auto !important',
      padding: '2rem',
      fontSize: '13px !important',
    },
  };
});

export default useSkillStyles;
