import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';
import ResourceClient from '../../api/Resource/resourceAPIs';
import { GetResourceParams } from '../../api/Resource/resourceTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { dateFrom, dateTo } from './resourceListConfig';
import Datatable from '../../components/Datatable/Datatable';
import useAllResourcesStyles from './AllResourcesStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { getColumns, initialDeleteColumns } from './ViewBySkillsListConfig';

const ViewBySkillList = () => {
  const [resourceListData, setResourceListData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState<any>([]);
  const [columnHeading, setColumnHeading] = useState<any>([]);
  const styles = useAllResourcesStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const getResourceList = () => {
    setIsLoading(true);
    const getResourceParams: GetResourceParams = {
      // TODO: API integration, check to make single component with paramerized status
      start_date: dateFrom,
      end_date: dateTo,
    };
    ResourceClient.getResourceList(getResourceParams)
      .then((data) => {
        setResourceListData(data);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getResourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedSkillData: any = {};
    resourceListData?.forEach((person: any) => {
      const { primarySkills, employeeFirstName, employeeLastName } = person;
      const fullName = `${employeeFirstName} ${employeeLastName}`.trim();
      primarySkills?.forEach((skill: any) => {
        updatedSkillData[skill] = updatedSkillData[skill] || [];
        updatedSkillData[skill]?.push(fullName);
      });
    });

    const convertedSkillData: any = {};
    Object.keys(updatedSkillData).forEach((key) => {
      let convertedKey = key;
      if (key === 'Shell Scripting') {
        convertedKey = 'ShellScripting';
      } else if (key === 'Spring Boot') {
        convertedKey = 'SpringBoot';
      }
      convertedSkillData[convertedKey] = updatedSkillData[key];
    });

    const { ...updatedData } = convertedSkillData;
    const javascriptSet = new Set([
      ...(updatedData?.JavaScript || []),
      ...(updatedData?.Typescript || []),
    ]);
    const pythonSet = new Set([
      ...(updatedData?.Python || []),
      ...(updatedData?.Django || []),
      ...(updatedData?.FastAPI || []),
      ...(updatedData?.Flask || []),
    ]);
    const javaSet = new Set([
      ...(updatedData?.Java || []),
      ...(updatedData?.SpringBoot || []),
      ...(updatedData?.Hibernate || []),
    ]);
    const devOpsSet = new Set([
      ...(updatedData?.DevOps || []),
      ...(updatedData?.CICD || []),
      ...(updatedData?.Jenkins || []),
      ...(updatedData?.ShellScripting || []),
      ...(updatedData?.Terraform || []),
    ]);

    initialDeleteColumns.map((column: any) => delete updatedData[column]);

    const finalData = {
      ...updatedData,
      JavaScript: Array.from(javascriptSet),
      Python: Array.from(pythonSet),
      Java: Array.from(javaSet),
      DevOps: Array.from(devOpsSet),
    };
    const skills = Object.keys(finalData);
    const finalSkills = skills.sort();
    setColumnHeading(finalSkills);
    setRow(
      Array.from({
        length: Math.max(...finalSkills.map((skill) => finalData[skill]?.length)),
      }).map((_, rowIndex) =>
        finalSkills.reduce(
          (acc: any, skill) => {
            acc[skill] = finalData[skill][rowIndex] || '';
            return acc;
          },
          { id: rowIndex + 1 },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceListData]);

  return (
    <>
      <Box className={styles.resourceWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_SKILL,
            })}
          </span>
          <Box className={styles.LinkWrapper}>
            <Link to="/resource">
              {intl.formatMessage({
                id: I18nKey.RESOURCE_TITLE,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_SKILL,
            })}
          </Box>
        </Box>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        <Box className={styles.ListWrapper}>
          <Box className={styles.column}>
            <Datatable
              rows={row}
              columns={getColumns(columnHeading)}
              showExportButton
              exportedFileName={`Resource_List_By_Skill_${dayjs().format('DD_MMMM')}`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewBySkillList;
