import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Link as SimpleLink, Tooltip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  replaceEmpty,
  dateFromatter,
  getListOfMonths,
  UserGroupRoles,
  OfferedCandidatesStatus,
} from '../../utils/utils';
import { InterviewStatus } from './utils';

const initialSort = { field: 'dateOfJoining', sort: 'asc' };

const getInterviewStatusIcons = (status: string) => {
  switch (status) {
    case InterviewStatus.PASSED:
      return (
        <Tooltip title="Passed">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    case InterviewStatus.DID_NOT_APPEAR:
      return (
        <Tooltip title="Did Not Appear">
          <ErrorOutlineIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    case InterviewStatus.FAILED:
      return (
        <Tooltip title="Failed">
          <CancelOutlinedIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    // case InterviewStatus.TO_BE_SCHEDULED:
    //   return (
    //     <Tooltip title="To Be Scheduled">
    //       <ScheduleRoundedIcon sx={{ color: 'blue' }} />
    //     </Tooltip>
    //   );
    default:
      return null;
  }
};

const columns = [
  {
    field: 'currentState',
    type: 'string',
    headerName: 'Current State',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'yearsOfExperience',
    type: 'number',
    headerName: 'Experience',
    headerAlign: 'left',
    cellClassName: 'left-align-number-field',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'skills',
    type: 'string',
    headerName: 'Skill',
    flex: 1.5,
    sortable: true,
    cellClassName: 'employee-skill-cell',
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Status',
    flex: 1.5,
    sortable: true,
    renderCell: ({ value }: GridRenderCellParams) => getInterviewStatusIcons(value),
  },
  {
    field: 'noticePeriod',
    type: 'string',
    headerName: 'Category',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'dateOfJoining',
    type: 'date',
    valueFormatter: (params: any) => dateFromatter(params.value),
    headerName: 'DOJ',
    flex: 1,
    sortable: true,
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 1.5,
    sortable: true,
    hide: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
];

const getColumns = (isAccess: boolean, handleResumeDownload: any, rowActions: any[]) => {
  const cols = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    ...columns,
    {
      field: 'resumePresignedUrl',
      type: 'string',
      headerName: 'Resume Link',
      flex: 1.5,
      sortable: true,
      hide: true,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.resumePresignedUrl ? (
          <SimpleLink
            component="button"
            onClick={() => {
              handleResumeDownload(params.row.resumePresignedUrl, params.row.name, false);
            }}>
            Link
          </SimpleLink>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      hideable: false,
      getActions: (params: any) => {
        let isDisabled = false;
        return rowActions.map((action) => {
          if (
            params.row.currentState !== OfferedCandidatesStatus.OFFER_ACCEPTED &&
            action.label === 'Mail'
          )
            isDisabled = true;
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              disabled={isDisabled}
              onClick={() => action.callback(params)}
            />
          );
        });
      },
    },
  ];
  return cols;
};

const getInitialVisibilityModel = (
  { showColumns }: any,
  isHRAccess: any,
  handleResumeDownload: any,
  rowActions: any[],
) => {
  const colRange = getColumns(isHRAccess, handleResumeDownload, rowActions);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const candidateUid = 'uid';

const initialShowCols = [
  'name',
  'currentState',
  'yearsOfExperience',
  'skills',
  'status',
  'noticePeriod',
  'dateOfJoining',
  'actions',
];

export {
  columns,
  initialSort,
  candidateUid,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
};
