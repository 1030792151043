import axios from 'axios';
import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import { GetAllCandidateParams, GetAllInterviewerParams } from './interviewTypes';

export const PATHS = Object.freeze({
  GET_CANDIDATES: '/interviews/all',
  GET_INTERVIEWERS: '/interviews/interviewer/all',
  GET_INTERVIEWER_LISTING: '/interviews/interviewer/listing',
  GET_CANDIDATE_DETAILS: (candidate_uid: any) => `/interviews/${candidate_uid}`,
  POST_INTERVIEW_SCHEDULE: '/interviews/schedule',
  GET_INTERVIEWER_DETAILS: `/interviews/interviewer`,
  ADD_CANDIDATE: '/interviews/candidate',
  PUT_CANDIDATE_DETAIL: (candidate_uid: any) => `/interviews/candidate/${candidate_uid}`,
  GET_CANDIDATE_DETAIL: (candidate_uid: any) => `/interviews/candidate/${candidate_uid}`,
  EMPLOYEE: `/employee/active/all`,
  PUT_INTERVIEWER: '/interviews/interviewer',
  PATCH_SUBMIT_FEEDBACK: '/interviews/feedback',
  GET_ALL_INTERVIEWEES: '/interviews/candidates',
  POST_BULK_ADDING: '/interviews/candidate/bulk',
});

const getAllCandidates = async (getCandidateParams: any): Promise<GetAllCandidateParams[]> => {
  const queryParams = queryString.stringify(getCandidateParams);
  const response = await axiosInstance.get(`${PATHS.GET_CANDIDATES}?${queryParams}`);
  return response.data;
};

const getAllInterviewers = async (
  getInterviewerParams: any,
): Promise<GetAllInterviewerParams[]> => {
  const queryParams = queryString.stringify(getInterviewerParams);
  const response = await axiosInstance.get(`${PATHS.GET_INTERVIEWERS}/?${queryParams}`);
  return response.data;
};

const getAllCandidateDetails = async (candidate_uid: any): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_CANDIDATE_DETAILS(candidate_uid));
  return response.data;
};

const getInterviewers = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_INTERVIEWER_LISTING);
  return response.data;
};

const putInterviewSchedule = async (scheduleData: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.POST_INTERVIEW_SCHEDULE, scheduleData);
  return response;
};

const patchSubmitFeedback = async (feedbackData: any): Promise<any> => {
  const response = await axiosInstance.patch(PATHS.PATCH_SUBMIT_FEEDBACK, feedbackData);
  return response;
};

const getAllInterviewerDetails = async (getInterviewerDetailParams: any): Promise<any> => {
  const queryParams = queryString.stringify(getInterviewerDetailParams);
  const response = await axiosInstance.get(`PATHS.GET_INTERVIEWER_DETAILS/${queryParams}?`);
  return response.data;
};

const addCandidate = async (addCandidateParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_CANDIDATE, addCandidateParams);
  return response;
};

const updateCandidateDetails = async (
  candidate_uid: any,
  updateCandidateDetailsParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_CANDIDATE_DETAIL(candidate_uid),
    updateCandidateDetailsParams,
  );
  return response;
};

const downloadResume = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

const getCandidateDetailById = async (candidate_uid: any): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_CANDIDATE_DETAIL(candidate_uid));
  return response.data;
};

const getAllEmpList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.EMPLOYEE}`);
  return response;
};

const handleInterviewerRole = async (interviewerData: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_INTERVIEWER, interviewerData);
  return response;
};

const deleteCandidate = async (candidate_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.ADD_CANDIDATE}/${candidate_uid}`);
  return response;
};

const getAllIntervieweesList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_ALL_INTERVIEWEES}`);
  return response.data;
};

const getOfferedCandidateList = async (offeredCandidateParams: any): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_ALL_INTERVIEWEES, {
    params: offeredCandidateParams,
  });
  return response.data;
};

const postBulkCandidates = async (fileData: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.POST_BULK_ADDING, fileData);
  return response;
};

export interface InterviewAPIClient {
  readonly getAllCandidates: (getCandidateParams: any) => Promise<GetAllCandidateParams[]>;
  readonly getAllInterviewers: (getInterviewerParams: any) => Promise<GetAllInterviewerParams[]>;
  readonly getAllCandidateDetails: (candidate_uid: any) => Promise<any>;
  readonly getInterviewers: () => Promise<any>;
  readonly putInterviewSchedule: (scheduleData: any) => Promise<any>;
  readonly getAllInterviewerDetails: (interviewer_uid: any) => Promise<any>;
  readonly updateCandidateDetails: (
    candidate_uid: any,
    updateCandidateDetailsParams: any,
  ) => Promise<any>;
  readonly downloadResume: (params: any) => Promise<any>;
  readonly addCandidate: (addCandidateParams: any) => Promise<any>;
  readonly getCandidateDetailById: (candidate_uid: any) => Promise<any>;
  readonly getAllEmpList: () => Promise<any>;
  readonly handleInterviewerRole: (interviewerData: any) => Promise<any>;
  readonly patchSubmitFeedback: (feedbackData: any) => Promise<any>;
  readonly deleteCandidate: (candidate_uid: string) => Promise<any>;
  readonly getAllIntervieweesList: () => Promise<any>;
  readonly getOfferedCandidateList: (offeredCandidateParams: any) => Promise<any>;
  readonly postBulkCandidates: (fileData: any) => Promise<any>;
}

const InterviewClient: InterviewAPIClient = Object.freeze({
  getAllCandidates,
  getAllInterviewers,
  getAllCandidateDetails,
  getInterviewers,
  putInterviewSchedule,
  getAllInterviewerDetails,
  updateCandidateDetails,
  downloadResume,
  addCandidate,
  getCandidateDetailById,
  getAllEmpList,
  handleInterviewerRole,
  patchSubmitFeedback,
  deleteCandidate,
  getAllIntervieweesList,
  getOfferedCandidateList,
  postBulkCandidates,
});

export default InterviewClient;
