import React, { useContext, useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import MisTabs from '../MisTabs/MisTabs';
import { docUidName } from './EmpDocTableConfig';
import EmpDocTableForm from './EmpDocTableForm';
import { generateRows } from '../Datatable/datatableUtils';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import { CERTIFICATES, OTHERS, PERSONAL, PROFESSIONAL } from '../../utils/docTypes';
import useEmpDocTableStyles from './EmpDocTableStyle';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import OnboardingClient, { PATHS as onBoardingPaths } from '../../api/OnBoarding/onboardingAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations, UserGroupRoles, newJoineeDcouments } from '../../utils/utils';
import EmpDocContent from './EmpDocContent';
import NewCandidateClient, {
  PATHS as newCandidatePaths,
} from '../../api/NewCandidate/newCandidateAPIs';

export const PATHS = Object.freeze({
  PROFILE: `/profile`,
  ADD_CERTIFICATE: `/profile/document/certificates`,
  ADD_PERSONAL_DOC: `/profile/document/personal`,
  ADD_PROFESSIONAL_DOC: `/profile/document/professional`,
});

interface EmpDocTableTabs {
  id: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS;
  readonly label: any;
  readonly component: React.ReactNode;
  readonly module: string;
}

interface EmpDocTableProps {
  isUploadable?: boolean;
  employeeId?: string;
  onChange?: Function;
  onRemove?: Function;
  tableView?: boolean;
  uploadDocs?: any;
  usedBy?: 'others' | 'profile';
  handleIfPersonalDocsAreUploaded?: Function;
  handleIfProfessionalDocsAreUploaded?: Function;
}

const EmpDocTable: React.FC<EmpDocTableProps> = ({
  isUploadable,
  employeeId,
  onChange,
  onRemove,
  tableView,
  uploadDocs,
  usedBy,
  handleIfPersonalDocsAreUploaded,
  handleIfProfessionalDocsAreUploaded,
}) => {
  const [empCertificates, setEmpCertificates] = useState<any>([]);
  const [empPersonalDocs, setEmpPersonalDocs] = useState<any>([]);
  const [empProfessionalDocs, setEmpProfessionalDocs] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [fetch, setFetch] = useState<number>(0);

  const employeeUid = employeeId || '';

  const { checkAccess } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], docUidName);
    return rows;
  };

  const getEmpDocuments = () => {
    setLoading(true);

    Promise.allSettled([
      NewCandidateClient.getNewCandidateCertificatesById(employeeUid),
      NewCandidateClient.getNewCandidatePersonalDocsById(employeeUid),
      NewCandidateClient.getNewCandidateProfessionalDocsById(employeeUid),
    ])
      .then((res) => {
        res.forEach((item, index) => {
          const fulfilledStatus = 'fulfilled';
          switch (index) {
            case 0:
              if (item.status === fulfilledStatus)
                setEmpCertificates(getTableRows(item.value.data));
              break;
            case 1:
              if (item.status === fulfilledStatus) {
                setEmpPersonalDocs(getTableRows(item.value.data));
                if (item.value.data.length) {
                  handleIfPersonalDocsAreUploaded?.(true);
                } else {
                  handleIfPersonalDocsAreUploaded?.(false);
                }
              }
              break;
            case 2:
              if (item.status === fulfilledStatus) {
                setEmpProfessionalDocs(getTableRows(item.value.data));
                if (item.value.data.length) {
                  handleIfProfessionalDocsAreUploaded?.(true);
                } else {
                  handleIfProfessionalDocsAreUploaded?.(false);
                }
              }
              break;
            default:
          }
        });
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (employeeUid) getEmpDocuments();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch, employeeUid]);

  const empDocTableStyles = useEmpDocTableStyles();

  const onDocSave = (formData: any, row: any) => {
    onChange?.(formData);
  };

  const handleSuccessfulDelete = (res: any) => {
    showSnackbar(res, 'success');
    updateFetch();
  };

  const onDocRemove = (
    id: any,
    documentId: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS,
  ): void => {
    setLoading(true);
    onRemove?.(id);

    switch (documentId) {
      case PROFESSIONAL:
        NewCandidateClient.deleteEmployeeProfessionalDocsById(employeeUid, id)
          .then((res: any) => {
            handleSuccessfulDelete(res);
          })
          .catch((e: any) => showSnackbar(e, 'error'))
          .finally(() => setLoading(false));
        break;

      case PERSONAL:
        NewCandidateClient.deleteEmployeePersonalDocsById(employeeUid, id)
          .then((res: any) => {
            handleSuccessfulDelete(res);
          })
          .catch((e: any) => showSnackbar(e, 'error'))
          .finally(() => setLoading(false));
        break;

      case OTHERS:
        NewCandidateClient.deleteEmployeeCertificatesById(employeeUid, id)
          .then((res: any) => {
            handleSuccessfulDelete(res);
          })
          .catch((e: any) => showSnackbar(e, 'error'))
          .finally(() => setLoading(false));
        break;

      default:
    }
  };

  const empDocTableTabs: EmpDocTableTabs[] = [
    {
      id: PROFESSIONAL,
      label: PROFESSIONAL.toUpperCase(),
      component: (
        <EmpDocContent
          rows={empProfessionalDocs}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={PROFESSIONAL}
          usedBy={usedBy}
        />
      ),
      module: newCandidatePaths.GET_NEW_CANDIDATE_PERSONAL_DOC_BY_ID('id'),
    },
    {
      id: PERSONAL,
      label: (
        <Tooltip
          title={
            <>
              <span>
                Under Personal section, please upload the following documents (mandatory):
              </span>
              <ul>
                {newJoineeDcouments.map((doc) => (
                  <li>{doc}</li>
                ))}
              </ul>
            </>
          }>
          <span>{PERSONAL.toUpperCase()}</span>
        </Tooltip>
      ),
      component: (
        <EmpDocContent
          rows={empPersonalDocs}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={PERSONAL}
          usedBy={usedBy}
        />
      ),
      module: newCandidatePaths.GET_NEW_CANDIDATE_PERSONAL_DOC_BY_ID('id'),
    },
    {
      id: OTHERS,
      label: CERTIFICATES.toUpperCase(),
      component: (
        <EmpDocContent
          rows={empCertificates}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={OTHERS}
          usedBy={usedBy}
        />
      ),
      module: newCandidatePaths.GET_NEW_CANDIDATE_CERTIFICATE_BY_ID('id'),
    },
  ];

  const filteredTabList = [...empDocTableTabs];

  const onTabChange = (tabVal: number) => {
    setActiveTab(tabVal);
    setCurrentCategory(empDocTableTabs[tabVal]);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={empDocTableStyles.empDocTablesWrapper}>
        <MisTabs tabs={filteredTabList} onTabChange={onTabChange} selected={activeTab} />
      </Box>

      <EmpDocTableForm
        employeeUid={employeeUid}
        onFormUpload={getEmpDocuments}
        onSave={onDocSave}
        currentCategory={currentCategory || empDocTableTabs[0]}
        usedBy={usedBy || 'others'}
      />
    </>
  );
};

EmpDocTable.defaultProps = {
  isUploadable: false,
  onChange: () => {},
  onRemove: () => {},
  tableView: true,
  employeeId: '',
  uploadDocs: null,
  usedBy: 'others',
  handleIfPersonalDocsAreUploaded: () => {},
  handleIfProfessionalDocsAreUploaded: () => {},
};

export default EmpDocTable;
