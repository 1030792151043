import { useEffect, useState, useContext } from 'react';
import { Box, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import useAllPolicyStoreStyles from './PolicyStoreStyles';
import I18nKey from '../../translations/I18nKey';
import { formatPolicyData } from './utils';
import { PolicyList } from '../../api/Policy/policyTypes';
import PolicyClient, { PATHS as PolicyPaths } from '../../api/Policy/policyAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SidePanel from '../../components/SidePanel/SidePanel';
import AddPolicyForm from './AddPolicyForm';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';

const PolicyStore = () => {
  const intl = useIntl();
  const styles = useAllPolicyStoreStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [policyList, setPolicyList] = useState<PolicyList[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { checkAccess } = useContext(GlobalContext);
  const [fetch, setFetch] = useState<number>(0);

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const handleAddClick = () => {
    setIsOpen(!isOpen);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleDownloadPreviewDoc = (label: string, isPreview: boolean) => {
    setIsLoading(true);
    const selectedPolicyUrl = policyList.find((pol: any) => pol.label === label)?.url;
    const downloadPolicyParams = {
      preSignedUrl: selectedPolicyUrl,
    };
    PolicyClient.downloadPolicy(downloadPolicyParams)
      .then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        if (isPreview) {
          link.target = '_blank';
        } else {
          link.download = label;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleDeletePolicy = (policyLabel: string) => {
    setIsLoading(true);
    const selectedPolicyUid = policyList.find((pol: any) => pol.label === policyLabel)?.policyUid;
    PolicyClient.deletePolicy(selectedPolicyUid)
      .then((response) => {
        showSnackbar(response, 'success');
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handlePolicySubmit = (values: any) => {
    setIsLoading(true);
    const file = values.documentFile;
    const policyName = {
      name: values.policyTitle,
    };
    const formData = new FormData();
    formData.append('policy_details', JSON.stringify(policyName));
    formData.append('file', file);
    handleModalClose();
    PolicyClient.savePolicy(formData)
      .then((res: any) => {
        showSnackbar(res, 'success');
        updateFetch();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    PolicyClient.getAllPolicies()
      .then((response) => {
        const formattedData = response.data?.map((item: any) => formatPolicyData(item));
        setPolicyList(formattedData);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.policyStoreWrapper}>
        <Box className={styles.Header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.POLICY,
            })}
          </span>
        </Box>
        <Grid container spacing={2}>
          {policyList?.map(({ label, updatedOn }) => (
            <Grid key={label} item xs={3} className={styles.itemWrapper}>
              <Box className={styles.routeItem}>
                <Box
                  className={styles.routeItemWrapper}
                  onClick={() => handleDownloadPreviewDoc(label, true)}>
                  <Box className={styles.contentWrapper}>
                    <Typography className={styles.label}>{label}</Typography>
                    <Box className={styles.footerItem}>
                      <Box className={styles.footerRightItem}>
                        <Typography className={styles.footerFont}>
                          Updated on:- {updatedOn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.actionBtnWrapper}>
                  <Tooltip title="Download">
                    <DownloadIcon onClick={() => handleDownloadPreviewDoc(label, false)} />
                  </Tooltip>
                  {checkAccess(PolicyPaths.POLICY, ApiOperations.DELETE) && (
                    <Tooltip title="Delete">
                      <DeleteIcon
                        className={styles.deleteBtn}
                        onClick={() => handleDeletePolicy(label)}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        {(checkAccess(PolicyPaths.POLICY, ApiOperations.PUT) ||
          checkAccess(PolicyPaths.POLICY, ApiOperations.POST)) && (
          <Box className={styles.fabContainer}>
            <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleAddClick}>
              <AddIcon />
            </Box>
            {isOpen && (
              <Modal open={isOpen} onClose={handleModalClose}>
                <Box className={styles.formContainer}>
                  <SidePanel
                    header={intl.formatMessage({
                      id: I18nKey.ADD_POLICY,
                    })}
                    onClose={handleModalClose}>
                    <AddPolicyForm handleSubmit={handlePolicySubmit} />
                  </SidePanel>
                </Box>
              </Modal>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PolicyStore;
