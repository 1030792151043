import { Autocomplete, MenuItem, TextField } from '@mui/material';
import React from 'react';

const SimpleAutocompleteField: React.FC<any> = ({
  name,
  options,
  label,
  handleChange,
  setFieldValue,
  required,
  values,
  variant,
  placeholder,
  ...autocompleteProps
}) => {
  const handleAutoCompleteChange = (e: React.ChangeEvent<any>, option: any) => {
    setFieldValue?.(name, option);
    if (typeof handleChange === 'function') handleChange(name, option);
  };

  return (
    <Autocomplete
      options={options}
      value={values[name] || null}
      {...autocompleteProps}
      onChange={handleAutoCompleteChange}
      id={name}
      isOptionEqualToValue={(option: any, value) => option.value === value.value}
      renderOption={(props: any, option: any) => (
        <MenuItem {...props} key={option.value}>
          {option.label}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          variant={variant}
          placeholder={placeholder || ''}
          inputProps={{
            ...params.inputProps,
            ...autocompleteProps.inputProps,
            required: required && values[name]?.length === 0,
          }}
        />
      )}
    />
  );
};

SimpleAutocompleteField.defaultProps = {
  handleChange: () => {},
};

export default SimpleAutocompleteField;
