import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import useSidePanelStyles from './SidePanelStyle';

interface SidePanelProps {
  header: string;
  onClose: Function;
  children: JSX.Element;
}

const SidePanel: React.FC<SidePanelProps> = ({ header, children, onClose }) => {
  const SidePanelStyle = useSidePanelStyles();
  return (
    <div className={SidePanelStyle.sidePanelWrapper}>
      <div className={SidePanelStyle.header}>
        <span>{header}</span>
      </div>
      <div className={SidePanelStyle.content}>{children}</div>
      <Divider />
      <Box className={SidePanelStyle.btnWrapper}>
        <Button variant="text" className={SidePanelStyle.closeBtn} onClick={() => onClose()}>
          <span>Close</span>
        </Button>
      </Box>
    </div>
  );
};

export default SidePanel;
