import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useKraTemplateDetailsStyles = makeStyles((theme: Theme) => {
  return {
    formWrapper: {
      margin: '10px 0px',
      width: '100%',
      color: theme.palette.text.midDark,
      backgroundColor: '#fff',
      display: 'flex',
      padding: '15px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.tertiary?.main}`,
      position: 'relative',
      '& form > button': {
        position: 'absolute',
        top: 15,
        right: 25,
      },
    },
    elements: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '80%',
    },
    FieldWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',

      '&:hover': {
        '& $arrowBtnWrapper': {
          visibility: 'visible',
        },
      },
    },
    Field: {
      display: 'flex',
      flexDirection: 'column',

      '&:nth-child(2)': {
        width: '40%',
        marginRight: '10px',
      },
    },
    table: {
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #dddddd',
      minHeight: '50px',
      position: 'relative',
      marginBottom: '20px',
      borderBottom: 'none',
    },
    tableContents: {
      verticalAlign: 'top !important',
    },
    column: {
      borderBottom: '1px solid #dddddd',
      borderRight: '1px solid #f1f1f1',
      textAlign: 'left',
      padding: '5px',
      fontWeight: 'normal',
    },
    descriptionColumn: {
      width: '23%',
    },
    weightageColumn: {
      width: '8%',
    },
    actionColumn: {
      width: '6%',
    },
    tableHeader: {
      borderBottom: '1px solid #dddddd',
      borderRight: '1px solid #f1f1f1',
      textAlign: 'left',
      padding: '10px 5px',
    },
    leftColumn: {
      width: '16%',
    },
    centerColumn: {
      minWidth: '47%',
    },
    rightColumn: {
      borderRight: 'none',
    },
    addBtn: {
      left: 'calc(100vw - 160px) !important',
    },
    deleteBtn: {
      color: `${theme.palette.status?.danger} !important`,
    },
    saveBtn: {
      float: 'right',
      marginLeft: '20px !important',
    },
    actionBtn: {
      float: 'right',
    },
    info: {
      float: 'right',
      margin: ' 7px 5px 0 0 ',
    },
    addTable: {
      borderBottom: '1px solid #dddddd',
    },
    tableFooter: {
      display: 'flex',
      padding: '20px',
      background: '#ffffff',
      borderRadius: '8px',
      borderBottom: '1px solid #dddddd',
    },
    recommendText: {
      flex: 'none !important',
    },
    errorMsg: {
      color: 'red',
      width: '100%',
      textAlign: 'right',
      marginBottom: '20px',
    },
    accordion: {
      backgroundColor: `${theme.palette.appBackground?.lightest} !important`,
      marginTop: '1rem',
      borderRadius: '8px !important',

      '& .MuiAccordionSummary-content': {
        color: `${theme.palette.text.disabled} !important`,
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
      },
      '& .makeStyles-header-18': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        marginBottom: '-15px',
      },
    },
    heading: {
      fontSize: '14px !important',
    },
    detailsWrapper: {
      fontSize: '14px !important',
      '& .MuiInputBase-root-MuiInput-root:before': {
        borderBottom: 'none',
      },
      '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
        fontSize: '14px !important',
      },
      '& .MuiOutlinedInput-root': {
        fontSize: '14px',
        '& fieldset': {
          border: 'none',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
      },
    },
  };
});

export default useKraTemplateDetailsStyles;
