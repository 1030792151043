import { useEffect, useState } from 'react';
import { MailOutline, PhoneEnabledOutlined, LocationOnOutlined } from '@mui/icons-material';
import { Avatar, Box, Divider, Grid, Button, Tooltip, Typography, List } from '@mui/material';
import useAllSearchStyles from './AllSearchStyles';
import { getFirstLastName } from '../../utils/utils';

interface SearchCardProps {
  readonly candidateDetails: any;
  readonly handleOrganizationClick: any;
  readonly allCandidateProjects: any;
  readonly allPrimarySkills: any;
}

const SearchDetailsCard: React.FC<SearchCardProps> = ({
  candidateDetails,
  handleOrganizationClick,
  allCandidateProjects,
  allPrimarySkills,
}: any) => {
  const styles = useAllSearchStyles();
  const [candidateName, setCandidateName] = useState<string>(' ');

  useEffect(() => {
    if (candidateDetails?.firstName && candidateDetails?.lastName) {
      setCandidateName(
        getFirstLastName({
          firstName: candidateDetails?.firstName,
          lastName: candidateDetails?.lastName,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails]);

  return (
    <Box className={styles.CardWrapper}>
      <Box className={styles.CardImageWrapper}>
        <Avatar alt={candidateName} />
      </Box>
      <Box className={styles.CardTitleWrapper}>
        <p className={`${styles.TitleText} ${styles.bold}`}>{candidateName}</p>
        <p className={styles.TitleText}>{candidateDetails.designationName}</p>
        <p className={styles.TitleText}>ID:{candidateDetails.employeeId}</p>
      </Box>
      <Divider variant="middle" light />
      <Box className={styles.MidSectionWrappersecond}>
        Projects:
        <Typography fontSize={12}>{allCandidateProjects || '-'}</Typography>
      </Box>
      <Box className={styles.MidSectionWrappersecond} sx={{ marginBottom: '1rem' }}>
        Skills:<Typography sx={{ fontSize: '12px' }}>{allPrimarySkills || '-'}</Typography>
      </Box>
      <Divider variant="middle" light />
      <Box className={styles.MidSectionWrapper}>
        <Box className={styles.MidSectionItem}>
          <PhoneEnabledOutlined />
          <Tooltip title={candidateDetails?.phone}>
            <Typography>{candidateDetails?.phone}</Typography>
          </Tooltip>
        </Box>
        <Box className={styles.MidSectionItem}>
          <MailOutline />
          <Tooltip title={candidateDetails?.email}>
            <Typography>{candidateDetails?.email}</Typography>
          </Tooltip>
        </Box>
        <Box className={styles.MidSectionItem}>
          <LocationOnOutlined />
          <Tooltip title={candidateDetails?.currentCity}>
            <Typography>{candidateDetails?.currentCity || '-'}</Typography>
          </Tooltip>
        </Box>
      </Box>
      <Divider variant="middle" light />
      <Box>
        <Button
          className={styles.ButtonWrapper}
          variant="outlined"
          fullWidth
          onClick={handleOrganizationClick}>
          Organization Tree
        </Button>
      </Box>
    </Box>
  );
};

export default SearchDetailsCard;
