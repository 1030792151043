/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  AddCircleOutlined,
  ArrowCircleDown,
  ArrowCircleUp,
  CloseOutlined,
} from '@mui/icons-material';
import { Box, TextField, Button, Tooltip } from '@mui/material';
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { CreateOtherDetailsParams, ProjectResource } from '../../api/Invoice/invoiceTypes';
import InvoiceClient, { PATHS as invoicePaths } from '../../api/Invoice/invoiceAPIs';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import MisDialog from '../../components/MisDialog/MisDialog';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';
import OtherDetailsFormItem from './OtherDetailsFormItem';
import { InvoiceStatus } from './invoiceTableConfig';

interface OtherDetailsFormProp {
  readonly details: any;
  readonly currency: string;
  readonly showSubmitBtn: boolean;
  readonly invoiceResourceList: ProjectResource[];
  readonly updateFetch: () => void;
  readonly selectedMonth: any;
  readonly invoiceStatus: InvoiceStatus;
}

type InvoiceOtherDetailType = {
  heading: string;
  details: {
    comment: string;
    amount: number;
  };
  uid?: string;
};

const OtherDetailsForm: React.FC<OtherDetailsFormProp> = ({
  details,
  currency,
  showSubmitBtn,
  invoiceResourceList,
  updateFetch,
  selectedMonth,
  invoiceStatus,
}) => {
  const [dailog, setDailog] = useState({
    display: false,
    title: '',
    message: '',
    handleSuccess: () => {},
    handleClose: () => {},
  });
  const [flattenedDetails, setFlattenedDetails] = useState([]);

  const styles = useInvoiceDetailStyles();
  const params = useParams();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);

  useEffect(() => {
    const newFlattenedDetails = details.map(({ uid, heading, details: dtl, sortOrder }: any) => ({
      uid,
      heading,
      items: dtl,
      sortOrder,
    }));
    setFlattenedDetails(newFlattenedDetails);
  }, [details]);

  const formValidationSchema = Yup.object().shape({
    details: Yup.array().of(
      Yup.object().shape({
        heading: Yup.string().required('Heading is required'),
        items: Yup.array().of(
          Yup.object().shape({
            comment: Yup.string().required('Comment is required'),
            amount: Yup.number().required('Amount is required'),
          }),
        ),
      }),
    ),
  });

  const formatDetails = (input: any): InvoiceOtherDetailType[] => {
    return input.map(({ uid, heading, items }: any, index: number) => ({
      uid,
      heading,
      details: items,
      sortOrder: index,
    }));
  };

  const handleDialogClose = () =>
    setDailog({
      display: false,
      title: '',
      message: '',
      handleSuccess: () => {},
      handleClose: () => {},
    });

  const showErrorDialog = () =>
    setDailog({
      display: true,
      title: intl.formatMessage({
        id: I18nKey.OTHER_DETAILS_INVALID_OP,
      }),
      message: intl.formatMessage({
        id: I18nKey.OTHER_DETAILS_UPDATE_ERR_MSG,
      }),
      handleSuccess: () => handleDialogClose(),
      handleClose: () => {
        handleDialogClose();
        updateFetch();
      },
    });

  const isInvalid = (values: any) =>
    values.details.length === 0 && !invoiceResourceList.some((el) => el.includeInInvoice);

  const handleSubmit = (values: any) => {
    if (isInvalid(values)) {
      showErrorDialog();
    } else {
      // Format details
      const formatedDetails = formatDetails(values.details);

      // Check for newly created records
      const postPayload = formatedDetails.filter((detail) => !detail.uid);

      const existingRecords = formatedDetails.filter((detail) => detail.uid);

      // Check for deleted records
      const deletePayload = details
        .filter(
          ({ uid: init_uid }: { uid: string }) =>
            formatedDetails.find(({ uid: final_uid }) => final_uid === init_uid) === undefined,
        )
        .map(({ uid }: { uid: string }) => uid);

      // Check for edited records
      const putPayload: any[] = [];
      existingRecords.forEach(({ uid: id }) => {
        const before = flattenedDetails
          .map(({ uid, items, sortOrder, heading }) => ({
            uid,
            heading,
            sortOrder,
            details: items,
          }))
          .find(({ uid }: any) => uid === id);
        const after = formatDetails(values.details).find(({ uid }: any) => uid === id);

        if (!_.isEqual(before, after)) {
          putPayload.push(after);
        }
      });

      let postPromise = null;
      let putPromise = null;
      let deletePromise = null;

      if (postPayload.length) {
        const postOtherDetailsParams: CreateOtherDetailsParams = {
          project_uid: params.id || '',
          date: selectedMonth.endOf('month').format('YYYY-MM-DD'),
        };
        postPromise = InvoiceClient.createOtherDetail(postOtherDetailsParams, postPayload);
      }
      if (putPayload.length) {
        const newPayload = putPayload.map((data) => ({
          ...data,
        }));

        putPromise = InvoiceClient.updateOtherDetail(
          newPayload,
          selectedMonth.endOf('month').format('YYYY-MM-DD'),
        );
      }
      if (deletePayload.length) {
        deletePromise = InvoiceClient.deleteOtherDetail(
          deletePayload,
          selectedMonth.endOf('month').format('YYYY-MM-DD'),
        );
      }

      Promise.allSettled([postPromise, putPromise, deletePromise])
        .then(() => {
          showSnackbar(
            {
              status: 200,
              data: {
                detail: intl.formatMessage({
                  id: I18nKey.TOAST_MESSAGE_UPDATE,
                }),
              },
            },
            'success',
          );
          updateFetch();
        })
        .catch((e) => showSnackbar(e, 'error'));
    }
  };

  const handleMove = (
    arrayHelpers: FieldArrayRenderProps,
    index: number,
    direction: 'up' | 'down',
  ) => {
    if (direction === 'up') arrayHelpers.move(index, index - 1);
    else arrayHelpers.move(index, index + 1);
  };

  return (
    <>
      <Formik
        initialValues={{ details: flattenedDetails }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={formValidationSchema}
        render={({ values }) => {
          return (
            <Form>
              <FieldArray
                name="details"
                render={(arrayHelpers) => {
                  return (
                    <Box>
                      {values.details.map((item: any, outerIndex: number) => (
                        <Box className={styles.DetailFormContainer}>
                          <Box key={outerIndex} className={styles.DetailFormWrapper}>
                            <fieldset>
                              <legend>
                                <Box>
                                  <Field
                                    as={TextField}
                                    type="text"
                                    name={`details[${outerIndex}].heading`}
                                    placeholder="Heading"
                                  />
                                  <Box className={styles.ErrorMsg}>
                                    <ErrorMessage name={`details[${outerIndex}].heading`} />
                                  </Box>
                                </Box>
                              </legend>

                              <OtherDetailsFormItem
                                element={item}
                                name={`details[${outerIndex}].items`}
                                currency={currency}
                                invoiceStatus={invoiceStatus}
                              />
                            </fieldset>

                            {checkAccess(
                              invoicePaths.OTHER_DETAILS(dayjs().format('YYYY-MM-DD')),
                              ApiOperations.DELETE,
                            ) &&
                              invoiceStatus !== InvoiceStatus.SHARED && (
                                <Button
                                  size="small"
                                  className={styles.closeBtn}
                                  sx={{ position: 'absolute' }}
                                  onClick={() => arrayHelpers.remove(outerIndex)}>
                                  <CloseOutlined />
                                </Button>
                              )}
                          </Box>
                          <Box className={styles.DetailArrowBtnWrapper}>
                            {outerIndex > 0 && (
                              <Tooltip title="Move Up">
                                <ArrowCircleUp
                                  onClick={() => handleMove(arrayHelpers, outerIndex, 'up')}
                                />
                              </Tooltip>
                            )}
                            {outerIndex < values.details.length - 1 && (
                              <Tooltip title="Move Down">
                                <ArrowCircleDown
                                  onClick={() => handleMove(arrayHelpers, outerIndex, 'down')}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      ))}
                      {checkAccess(
                        invoicePaths.CREATE_OTHER_DETAILS(dayjs().format('YYYY-MM-DD')),
                        ApiOperations.POST,
                      ) &&
                        invoiceStatus !== InvoiceStatus.SHARED && (
                          <Button
                            className={styles.AddBtn}
                            onClick={() =>
                              arrayHelpers.push({
                                heading: '',
                                items: [{ amount: 0, comment: '' }],
                              })
                            }
                            sx={{ position: 'absolute' }}>
                            <AddCircleOutlined />
                          </Button>
                        )}
                      {(values.details.length || showSubmitBtn) &&
                        checkAccess(
                          invoicePaths.OTHER_DETAILS(dayjs().format('YYYY-MM-DD')),
                          ApiOperations.PUT,
                        ) &&
                        invoiceStatus !== InvoiceStatus.SHARED && (
                          <Box className={styles.SubmitBtn}>
                            <Button type="submit" variant="contained">
                              {intl.formatMessage({
                                id: I18nKey.OTHER_DETAILS_SUBMIT,
                              })}{' '}
                            </Button>
                          </Box>
                        )}
                    </Box>
                  );
                }}
              />
            </Form>
          );
        }}
      />
      {dailog.display && (
        <MisDialog
          isOpen={dailog.display}
          title={dailog.title}
          message={dailog.message}
          handleClose={dailog.handleClose}
          handleSuccess={dailog.handleSuccess}
          hideSubmitBtn
        />
      )}
    </>
  );
};

export default OtherDetailsForm;
