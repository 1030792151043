import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAllInterviewStyles from './AllInterviewStyles';
import SidePanel from '../../components/SidePanel/SidePanel';

interface InterviewersTopSectionInfoProps {
  selectedInterviewer: any;
  setSelectedInterviewer: any;
  selectedTab: string;
  selectedList: string;
}

const InterviewersTopSectionInfo: React.FC<InterviewersTopSectionInfoProps> = ({
  selectedInterviewer,
  setSelectedInterviewer,
  selectedTab,
  selectedList,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const styles = useAllInterviewStyles();
  const navigate = useNavigate();

  const handleModalToggle = () => setOpen(!open);

  const handleClose = () => {
    setSelectedInterviewer('');
    navigate(`/interview?selected_list=${selectedList}&selected_tab=${selectedTab}`);
  };

  return (
    <Box className={`${styles.topContainer} ${styles.card}`}>
      <Box className={styles.topContainerWrapper}>
        <Box>
          <strong>{selectedInterviewer.id}</strong>
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <IconButton className={styles.editIcon} onClick={handleModalToggle}>
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={styles.middleContainer}>
        <Typography className={styles.middleContainerTitle}>
          <StoreOutlinedIcon className={styles.middleContainerIcons} />
          Designation
        </Typography>
        <Typography className={styles.middleContainerTitle}>
          <LocationOnOutlinedIcon className={styles.middleContainerIcons} /> Location
        </Typography>
        <Typography className={styles.middleContainerTitle}>
          <DateRangeOutlinedIcon className={styles.middleContainerIcons} />
          #Interviews
        </Typography>
        <Typography className={styles.middleContainerInfoTwo}>
          Total: 245<Typography className={styles.middleContainerInfo}>Pending: 2</Typography>
          <Typography className={styles.middleContainerInfo}>Feedback: 244</Typography>
        </Typography>
      </Box>
      {/* <Modal open={open} onClose={handleModalToggle}>
        <Box className={styles.formContainer}>
          <SidePanel header="Edit Interviewer" onClose={handleModalToggle}>
            <EditApplicantForm handleSubmit={handleEditApplicantForm} />
          </SidePanel>
        </Box>
      </Modal> */}
    </Box>
  );
};

export default InterviewersTopSectionInfo;
