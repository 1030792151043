import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';

const EditTask: React.FC<any> = ({ editSelectedTask, setEditSelectedTask }) => {
  const handleChange = (values: any): any => {
    setEditSelectedTask(values);
  };

  return (
    <Formik initialValues={editSelectedTask} onSubmit={() => {}} enableReinitialize>
      {(formik) => {
        const { values } = formik;
        return (
          <Form onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleInputField
                  name="task"
                  label="Edit Task"
                  variant="standard"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditTask;
