import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllResourcesStyles = makeStyles((theme: Theme) => {
  return {
    resourceWrapper: {
      padding: '1rem',
    },
    Header: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      width: '100%',

      '& .resource-skill': {
        padding: '2px 8px',
        margin: '1px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',
      },
      '& .resource-skill-cell': {
        flexWrap: 'wrap',
      },
      '& .expanded-cell': {
        flexWrap: 'wrap',
        padding: '10px 0px 5px 0px !important',
      },
      '& .project-name': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .MuiDataGrid-cell--textLeft': {
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
        maxWidth: '100%',
      },
    },
    w70: {
      width: '70%',
    },
    contentWrapper: {
      display: 'flex',
      marginTop: '-23px ',
    },
    formContainer: {
      marginTop: '18px',

      '& form > button': {
        'margin-top': '25px',
        float: 'right',
      },
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    LinkWrapper: {
      fontSize: '14px',
      fontWeight: 'bold',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    templateBtn: {
      float: 'right',
      margin: '0px 10px !important',
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    column: {
      '& .MuiDataGrid-columnHeader': {
        color: '#000000 !important',
        fontSize: '16px !important',
        fontWeight: 'bolder !important',
      },
    },
  };
});

export default useAllResourcesStyles;
