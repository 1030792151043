import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllContactsStyles = makeStyles((theme: Theme) => {
  return {
    contactsWrapper: {
      padding: '1rem',
    },
    Header: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    w70: {
      width: '70%',
    },
    card: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    contentWrapper: {
      display: 'flex',
    },
    topContainer: {
      position: 'relative',
      height: '50px',
      backgroundColor: theme.palette.popUpBackground?.light,
      color: theme.palette.secondary.main,
      borderRadius: '12px',
      padding: '10px',

      '& strong': {
        fontSize: '18px',
        fontWeight: 'bold',
      },
      '& button': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    bottomContainer: {
      marginTop: '20px',
      position: 'relative',
      'flex-grow': 1,
    },
    detailsWrapper: {
      'flex-direction': 'column',
      width: '30%',
      minWidth: '450px',
      margin: '0 1rem',
      borderRadius: '12px',
      height: 'fit-content',
      padding: '10px',
      backgroundColor: theme.palette.appBackground?.lightest,
    },
    formWrapper: {
      marginTop: '10px',
      padding: '0 8px 8px 8px',

      '& .saveBtn': {
        marginTop: '10px',
        float: 'right',
      },
    },
    formContainer: {
      marginTop: '18px',
      padding: '0 8px 8px 8px',

      '& form > button': {
        'margin-top': '25px',
        float: 'right',
      },
    },
    closeBtn: {
      position: 'absolute',
      bottom: 20,
      left: 20,
      color: theme.palette.text.light,
      fontSize: '16px !important',
      backgroundColor: theme.palette.text?.lightest,
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(0deg)`,
        transform: `rotate(0deg)`,
        fontSize: '35px',
      },
    },
    contactFormContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
  };
});

export default useAllContactsStyles;
