/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridSortModel,
  GridSortItem,
  GridRowModel,
  GridCellParams,
  GridEventListener,
  GridRowParams,
  gridClasses,
  GridRenderEditCellParams,
  GridToolbarFilterButton,
  GridSelectionModel,
  useGridApiContext,
  gridFilteredSortedRowEntriesSelector,
  gridVisibleColumnFieldsSelector,
} from '@mui/x-data-grid';
import { Autocomplete, Button, MenuItem } from '@mui/material';
import { Download, Edit } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useIntl } from 'react-intl';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import classNames from 'classnames';
import { CellProps, DatatableProps } from './DatatableTypes';
import Comment from './components/Comment';
import TotalRowFooter from './components/TotalRowFooter';
import I18nKey from '../../translations/I18nKey';
import useDatatableStyles from './DatatableStyles';
import RenderEditCell from './components/RenderEditCell';
import MisDialog from '../MisDialog/MisDialog';
import { getFirstLastName } from '../../utils/utils';

interface CustomToolbarProps {
  readonly value?: string;
  readonly onChange?: () => void;
  readonly clearSearch: () => void;
  readonly columnVisibility?: boolean;
  readonly handleClick?: (action: 'save' | 'cancel' | 'finalize') => void;
  readonly dailog?: any;
  readonly updatedRows: [];
  readonly editable?: boolean;
  readonly showSearch?: boolean;
  readonly showFinalizeButtton?: boolean;
  readonly editMode: boolean;
  readonly showFilterButton?: boolean;
  readonly showExportButton?: boolean;
  readonly exportedFileName?: string;
  readonly setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  value,
  onChange,
  clearSearch,
  columnVisibility,
  handleClick = () => {},
  dailog,
  updatedRows,
  editable,
  showSearch,
  showFinalizeButtton,
  editMode,
  showFilterButton,
  showExportButton,
  setEditMode,
  exportedFileName,
}) => {
  const styles = useDatatableStyles();
  const apiRef = useGridApiContext();

  const handleDataExport = () => {
    const visibleColumns: string[] = gridVisibleColumnFieldsSelector(apiRef);
    const columnName: any = visibleColumns.map((v) => {
      return { [v]: apiRef.current.getColumn(v).headerName };
    });
    const keyMap: any = visibleColumns.map((item, index) => {
      return columnName[index];
    });
    const keyMapObject = keyMap.reduce(
      (result: { [x: string]: any }, current: { [x: string]: any }) => {
        const key = Object.keys(current)[0];
        // eslint-disable-next-line no-param-reassign
        result[key] = current[key];
        return result;
      },
      {},
    );
    const data = gridFilteredSortedRowEntriesSelector(apiRef).map(({ id, model }: any) => {
      const res: Record<string, any> = {};
      visibleColumns.forEach((item: any) => {
        if (Array.isArray(model[item])) {
          const cellElement: any = [];
          model[item].forEach((val: any, index: any) => {
            if (typeof val === 'object') {
              cellElement.push(getFirstLastName(val));
            } else {
              cellElement.push(val);
            }
          });
          res[item] = cellElement.join(',').replace(/,/g, ', ');
        } else res[item] = model[item];
      });

      return res;
    });
    const dataToExport = data.map((obj: any) =>
      Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [
          keyMapObject[k],
          typeof v === 'boolean' ? (v ? 'True' : 'False') : v,
        ]),
      ),
    );
    const tabName = exportedFileName?.split('_')[0];
    const workSheet = XLSX.utils.json_to_sheet(dataToExport as any);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `${tabName}`);
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workBook, `${exportedFileName}.xlsx`);
  };

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      {!columnVisibility && !editable && !showFilterButton && (
        <Box>
          <></>
        </Box>
      )}

      <Box sx={{ display: 'flex' }}>
        {columnVisibility && <GridToolbarColumnsButton sx={{ marginRight: '10px' }} />}
        {showFilterButton && <GridToolbarFilterButton />}
        {editable ? (
          editMode ? (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                p: 1,
                textAlign: 'right',
              }}>
              <IconButton onClick={() => handleClick('cancel')} color="error">
                <CloseIcon />
              </IconButton>
              <IconButton
                onClick={() => handleClick('save')}
                color="success"
                disabled={!updatedRows.length}>
                <DoneIcon />
              </IconButton>
            </Box>
          ) : (
            <Box>
              <IconButton onClick={() => setEditMode(true)} className={styles.editIcon}>
                <Edit />
              </IconButton>
            </Box>
          )
        ) : null}
      </Box>

      <Box>
        {/* {showFinalizeButtton && (
          <Button onClick={() => handleClick('finalize')} color="primary" variant="contained">
            {intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_FINALIZE,
            })}
          </Button>
        )} */}
        {showExportButton && (
          <Button
            startIcon={<Download style={{ marginBottom: '-3px' }} />}
            onClick={handleDataExport}>
            Export Data
          </Button>
        )}
        {showSearch && (
          <TextField
            sx={{ justifyContent: 'flex-end', marginLeft: '10px' }}
            label="Search"
            size="small"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={clearSearch}>
                    {value ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>

      {dailog.display && (
        <MisDialog
          isOpen={dailog.display}
          title={dailog.title}
          message={dailog.message}
          handleClose={dailog.handleClose}
          handleSuccess={dailog.handleSuccess}
        />
      )}
    </GridToolbarContainer>
  );
};

CustomToolbar.defaultProps = {
  value: '',
  onChange: () => {},
  columnVisibility: false,
  handleClick: () => {},
  dailog: {
    display: false,
    title: '',
    message: '',
    handleSuccess: () => {},
    handleClose: () => {},
  },
  editable: false,
  showSearch: true,
  showFinalizeButtton: false,
  showFilterButton: true,
  showExportButton: false,
  exportedFileName: 'MIS_Exported_Document',
};

const Datatable: React.FC<DatatableProps> = ({
  rows,
  paginationModel,
  setPaginationModel,
  rowCount,
  columns,
  loading,
  sx,
  checkboxSelection,
  pagination,
  hideFooter,
  hideFooterPagination,
  pageSize,
  rowsPerPageOptions,
  paginationMode,
  sortingMode,
  filterMode,
  onFilterModelChange,
  tableHeight,
  columnVisibility,
  initialSort,
  updateRows,
  editable,
  onRowClick,
  showTotalRowFooter,
  showSearch,
  showFinalizeButtton,
  showFilterButton,
  showExportButton,
  showPageNumber,
  exportedFileName,
  isCellEditable,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  onSelectionModelChange,
  isRowSelectable,
  skipConfirmation,
}) => {
  const { typography } = useTheme();
  const [tableRows, setTableRows] = useState<any[]>(rows);
  const [searchText, setSearchText] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(paginationModel?.limit || pageSize);
  const [sortModel, setSortModel] = useState<GridSortModel>([initialSort as GridSortItem]);
  const [contextMenuEl, setContextMenuEl] = useState<HTMLElement | null>(null);
  const [currentCell, setCurrentCell] = useState<CellProps | any>();
  const [updatedRows, setUpdatedRows] = useState<any>([]);
  const [submit, setSubmit] = useState<boolean>(false);
  const [dailog, setDailog] = useState({
    display: false,
    title: '',
    message: '',
    handleSuccess: () => {},
    handleClose: () => {},
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pageNumbers, setPageNumbers] = useState<{ label: string; value: number }[]>([
    {
      label: '0',
      value: 0,
    },
  ]);
  const [pageNumberField, setPageNumberField] = useState({
    label: '0',
    value: 0,
  });
  const [paginationInfo, setPaginationInfo] = useState<{
    page: number;
    limit: number;
  }>({
    page: paginationModel?.page || 0,
    limit: paginationModel?.limit || 0,
  });

  const [rowSelected, setRowSelected] = useState<boolean>(false);
  const DatatableStyles = useDatatableStyles();
  const intl = useIntl();

  const handleClose = () => {
    setDailog({
      display: false,
      title: '',
      message: '',
      handleSuccess: () => {},
      handleClose: () => {},
    });
    setEditMode(false);
  };

  const handleCancel = () => {
    setTableRows(rows);
    setUpdatedRows([]);
    handleClose();
  };

  useEffect(() => {
    if (submit && updateRows) {
      updateRows(updatedRows, tableRows);
      setUpdatedRows([]);
      handleClose();
      setSubmit(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [submit]);

  const handleSave = (finalize?: boolean) => {
    /* This state is set intentionlly as the last cell change in databale is not accessible i.e last updated state with edited values were not getting saved */
    /* Todo: handle finalize in better way once finalize feature is implemented */
    setSubmit(true);
  };

  const handleClick = (action: 'save' | 'cancel' | 'finalize') => {
    switch (action) {
      case 'save':
        if (skipConfirmation) {
          handleSave();
        } else {
          setDailog({
            display: true,
            title: intl.formatMessage({
              id: I18nKey.DAILOG_EDIT,
            }),
            message: intl.formatMessage({
              id: I18nKey.DAILOG_SAVE_MSG,
            }),
            handleSuccess: () => handleSave(),
            handleClose,
          });
        }
        break;
      case 'finalize':
        setDailog({
          display: true,
          title: intl.formatMessage({
            id: I18nKey.DAILOG_FINALIZE,
          }),
          message: intl.formatMessage({
            id: I18nKey.DAILOG_FINALIZE_MSG,
          }),
          handleSuccess: () => handleSave(true),
          handleClose,
        });
        break;

      case 'cancel':
        if (updatedRows.length) {
          setDailog({
            display: true,
            title: intl.formatMessage({
              id: I18nKey.DAILOG_CANCEL,
            }),
            message: intl.formatMessage({
              id: I18nKey.DAILOG_CANCEL_MSG,
            }),
            handleSuccess: handleCancel,
            handleClose,
          });
        } else {
          handleClose();
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    requestSearch('');
    setTableRows(rows);
    const pageNumberArray =
      rowCount && pageSize ? Array.from(Array(Math.floor(rowCount / pageSize) + 1).keys()) : [0];
    const transformedArray = pageNumberArray.map((number) => ({
      label: number.toString(),
      value: number,
    }));
    setPageNumbers(transformedArray);
    setRowSelected(false);
  }, [rows]);

  const escapeRegExp = (value: string): string => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setTableRows(filteredRows);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // TODO: check this confition, find a better way fo doing this
    if (
      newSortModel &&
      newSortModel.length > 0 &&
      JSON.stringify(sortModel) !== JSON.stringify(newSortModel)
    ) {
      setSortModel(newSortModel);
    }
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    const currentEl = event.currentTarget;
    const field = currentEl.dataset.field!;
    const id = currentEl.parentElement!.dataset.id!;
    const col = columns.find((c: any) => c.field === field);
    if (editMode && col?.editable && col?.commentable) {
      const row = tableRows.find((r: any) => r.id === id)!;
      event.stopPropagation();
      event.preventDefault();
      setCurrentCell({ row, field });
      setContextMenuEl(currentEl);
    }
  };

  const NoRowsOverlay: React.FC = () => {
    return (
      <Box className={DatatableStyles.noRowsWrapper}>
        {intl.formatMessage({
          id: I18nKey.NO_ROWS,
        })}
      </Box>
    );
  };

  const updateProcessedRows = (newRow: any) => {
    const newRowCopyArr = [...updatedRows];
    const index = newRowCopyArr.findIndex((c: any) => c.row.id === newRow.id);

    const updatedFields: any = [];
    const oldRow = tableRows.filter((tableRow) => tableRow.id === newRow.id)[0];

    if (index === -1) {
      Object.keys(newRow).forEach((n, i) => {
        if (JSON.stringify(newRow[n]) !== JSON.stringify(oldRow[n])) {
          updatedFields.push(n);
        }
      });
      newRowCopyArr.push({ row: newRow, updatedFields });
    } else {
      Object.keys(newRow).forEach((n, i) => {
        if (JSON.stringify(newRow[n]) !== JSON.stringify(oldRow[n])) {
          updatedFields.push(n);
        }
      });
      newRowCopyArr[index] = {
        row: newRow,
        updatedFields: [
          ...(new Set([...newRowCopyArr[index].updatedFields, ...updatedFields]) as any),
        ],
      };
    }

    setTableRows(tableRows.map((t) => (t.id === newRow.id ? newRow : t)));
    setUpdatedRows(newRowCopyArr);
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (!_.isEqual(newRow, oldRow)) updateProcessedRows(newRow);
    return newRow;
  };

  const tableColumns = columns.map((col) => ({
    ...col,
    editable: col.editable && editMode,
    ...(col.type === 'number' &&
      col.editable &&
      editable && {
        renderEditCell: col.renderEditCell
          ? (params: GridRenderEditCellParams) => col.renderEditCell?.(params, updateProcessedRows)
          : (params: GridRenderEditCellParams) => <RenderEditCell {...params} type="number" />,
      }),
    ...(col.type === 'boolean' &&
      col.editable &&
      editable && {
        renderEditCell: (params: GridRenderEditCellParams) => (
          <RenderEditCell {...params} type="boolean" />
        ),
      }),
  }));

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams) => {
    setRowSelected(true);
    onRowClick?.(params);
  };

  const handleColumnVisibilityModelChange = (newModel: any) => {
    // Extra Check, as 'show all' action gives object without any months
    const formatString = 'YYYY-MM-DD';
    const monthCols = tableColumns.filter((v: any) => dayjs(v.field, formatString).isValid());
    const checkModelObj = Object.keys(newModel).filter((v) => dayjs(v).isValid());
    if (monthCols.length && !checkModelObj.length) {
      const modifiedModel: Record<string, boolean> = {};
      tableColumns.forEach((col: any) => {
        modifiedModel[col.field] = true;
      });
      onColumnVisibilityModelChange?.(modifiedModel);
      return;
    }
    onColumnVisibilityModelChange?.(newModel);
  };

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    onSelectionModelChange?.(newSelectionModel);
  };

  const handlePageChange = (newPage: number) => {
    setPageNumberField({ label: newPage.toString(), value: newPage });
    setPaginationInfo({ ...paginationInfo, page: newPage });
    if (setPaginationModel) setPaginationModel({ ...paginationInfo, page: newPage });
  };

  const handleHighlightRow = (params: any) => {
    if (params?.row?.projectDetails?.length > 0) {
      const today = dayjs();
      const oneWeekFromNow = today.add(7, 'day');
      if (
        params?.row?.projectDetails?.some((detail: any) => {
          const endDate = dayjs(detail.endDate);
          return endDate.isAfter(today) && endDate.isBefore(oneWeekFromNow);
        })
      ) {
        return 'highlighted-row';
      }
    }
    return '';
  };

  return (
    <>
      <Box
        sx={{
          [`& .${gridClasses.row}:hover`]: {
            '.delete-action': {
              visibility: 'visible !important;',
            },
          },
          width: '100%',
        }}>
        <DataGrid
          className={DatatableStyles.dataTableWrapper}
          rows={tableRows}
          rowCount={rowCount || undefined}
          getRowHeight={() => 'auto'}
          columns={tableColumns}
          loading={loading}
          sx={{ ...sx, ...typography.body1, height: tableHeight }}
          checkboxSelection={checkboxSelection}
          pagination={pagination || undefined}
          hideFooter={hideFooter}
          hideFooterPagination={hideFooterPagination}
          page={paginationInfo.page}
          pageSize={entriesPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(newPageSize) => setEntriesPerPage(newPageSize)}
          paginationMode={paginationMode}
          onPageChange={handlePageChange}
          sortingMode={sortingMode}
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => handleSortModelChange(newSortModel)}
          filterMode={filterMode}
          onFilterModelChange={onFilterModelChange}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          isCellEditable={isCellEditable}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel: any) =>
            handleColumnVisibilityModelChange(newModel)
          }
          onSelectionModelChange={handleSelectionModelChange}
          isRowSelectable={isRowSelectable}
          getCellClassName={(params: GridCellParams) => {
            if (params?.row[params.field]?.comment) {
              return 'commented-cell';
            }
            return '';
          }}
          localeText={{
            toolbarColumns: 'Show/Hide Columns',
          }}
          components={{ Toolbar: CustomToolbar, NoRowsOverlay }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
              // TODO: add debounce for customToolBar explicitly
              // quickFilterProps: { debounceMs: 500 },
              columnVisibility,
              updatedRows,
              handleClick,
              dailog,
              editable,
              showSearch,
              showFinalizeButtton,
              showFilterButton,
              showExportButton,
              exportedFileName,
              editMode,
              setEditMode,
            },
            cell: {
              onContextMenu: handleContextMenu,
            },
            columnsPanel: {
              filterMode: {},
            },
          }}
          onRowClick={handleRowClick}
          getRowClassName={handleHighlightRow}
        />
        {showPageNumber && (
          <Box
            className={classNames(DatatableStyles.pageNumberWrapper, {
              pageRowSelected: rowSelected,
            })}>
            <Autocomplete
              id="pageNumber"
              options={pageNumbers}
              renderOption={(props: any, option: any) => (
                <MenuItem {...props} key={option.value}>
                  {option.label}
                </MenuItem>
              )}
              onChange={(e: any, option) => {
                return handlePageChange(option.value);
              }}
              value={pageNumberField}
              disableClearable
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  size="small"
                  name="pageNumber"
                  label="Page"
                  fullWidth
                  inputProps={{ ...inputProps, style: { fontSize: 13 } }}
                  className={DatatableStyles.pageNumberField}
                />
              )}
            />
          </Box>
        )}
      </Box>
      {showTotalRowFooter && (
        <TotalRowFooter
          rows={rows}
          columns={tableColumns}
          columnVisibilityModel={columnVisibilityModel}
        />
      )}
      <Comment
        {...{
          contextMenuEl,
          setContextMenuEl,
          currentCell,
          updateProcessedRows,
        }}
      />
    </>
  );
};

Datatable.defaultProps = {
  checkboxSelection: false,
  pagination: true,
  hideFooter: false,
  editable: false,
  pageSize: 100,
  tableHeight: '78vh',
  rowsPerPageOptions: [25, 50, 100],
  paginationMode: 'client',
  sortingMode: 'client',
  onSortModelChange: () => {},
  filterMode: 'client',
  onFilterModelChange: () => {},
  columnVisibility: false,
  hideFooterPagination: false,
  initialSort: {} as GridSortItem,
  updateRows: () => {},
  onRowClick: () => {},
  showTotalRowFooter: false,
  showSearch: true,
  showFinalizeButtton: false,
  showFilterButton: true,
  showExportButton: false,
  showPageNumber: false,
  exportedFileName: 'MIS_Exported_Document',
  onColumnVisibilityModelChange: () => {},
  onSelectionModelChange: () => {},
  skipConfirmation: false,
  isRowSelectable: () => true,
};

export default Datatable;
