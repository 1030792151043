import React from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { Button, Chip, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import ContactRelation from './ContactRelation';
import EmpergencyContact from './EmergencyContact';
import I18nKey from '../../translations/I18nKey';
import useAddEmployeeStyles from './NewCandidateStyles';
import { emergencyContactValidationSchema } from './utils';

interface NewCandidateContactsProps {
  formState: any;
  relations: any;
  formRef: any;
  onSave: any;
}

const NewCandidateContacts: React.FC<NewCandidateContactsProps> = ({
  formState,
  relations,
  formRef,
  onSave,
}) => {
  const intl = useIntl();
  const styles = useAddEmployeeStyles();
  return (
    <>
      <Formik
        initialValues={{
          relationData: formState.relationData,
          emergencyContacts: formState.emergencyContacts,
        }}
        innerRef={formRef}
        onSubmit={onSave}
        enableReinitialize
        validationSchema={emergencyContactValidationSchema}>
        {(formik) => {
          return (
            <>
              <Form className={styles.formWrapper}>
                <Divider>
                  <Chip label="Emergency Contact Details" className="divider-chip" />
                </Divider>
                <EmpergencyContact relations={relations} formik={formik} />
                <Divider>
                  <Chip label="Relations" className="divider-chip" />
                </Divider>
                <ContactRelation relations={relations} formik={formik} />
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  variant="contained"
                  type="submit"
                  className="upload-button">
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SAVE,
                  })}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default NewCandidateContacts;
