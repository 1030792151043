import dayjs from 'dayjs';
import { dateFromatter, getFullName } from '../../utils/utils';

export const steps = ['PS', 'L1', 'L2', 'Final', 'Hr'];

export enum Tabs {
  L1 = 0,
  L2 = 1,
  L3 = 2,
  L4 = 3,
  L5 = 4,
}
export const tabLabels: { [key in Tabs]: string } = {
  [Tabs.L1]: 'Phone Screening',
  [Tabs.L2]: 'L1 Interview',
  [Tabs.L3]: 'L2 Interview',
  [Tabs.L4]: 'Final',
  [Tabs.L5]: 'Hr',
};

export const formatData = (values: any) =>
  values.map((el: any) => ({
    label: el.interviewerName,
    value: el.uid,
  }));

export const formatEmployeeList = (values: any) =>
  values.map((el: any) => ({
    label: getFullName(el),
    value: el.uid,
  }));

export const noticePeriodList = [
  { value: 'IMMEDIATE_JOINER', label: 'IMMEDIATE JOINER' },
  { value: 'THIRTY_DAYS', label: 'THIRTY DAYS' },
  { value: 'FORTY_FIVE_DAYS', label: 'FORTY FIVE DAYS' },
  { value: 'SIXTY_DAYS', label: 'SIXTY DAYS' },
  { value: 'NINETY_DAYS', label: 'NINETY DAYS' },
];

export const getRoundDetails = (roundData: any, interviewersData: any) => {
  const interviewerName = interviewersData.find(
    ({ label }: any) => label === roundData?.interviewerName,
  );
  const convertedTime = dayjs(roundData?.scheduledTime, 'h:mm A').format('HH:mm');
  const scheduleDateTime = dayjs(`${roundData?.scheduledDate}T${convertedTime}`);
  return {
    selectInterviewer: interviewerName || null,
    datetime: scheduleDateTime.isValid() ? scheduleDateTime : dayjs(),
    meetingLink: roundData?.videoCallLink || '',
    interviewUid: roundData?.interviewUid,
  };
};

export const statusOptions = [
  { value: 'Passed', label: 'Passed' },
  { value: 'Failed', label: 'Failed' },
  { value: 'Did Not Appear', label: 'Did Not Appear' },
  { value: 'To Be Offered', label: 'To Be Offered' },
];

export enum InterviewStatus {
  PASSED = 'Passed',
  FAILED = 'Failed',
  DID_NOT_APPEAR = 'Did Not Appear',
  SCHEDULED = 'Scheduled',
}

export const currentStateOptions = [
  { value: 'SELECTED_BUT_NOT_OFFERED', label: 'Selected But Not Offered' },
  { value: 'TO_BE_OFFERED', label: 'To Be Offered' },
  { value: 'OFFER_ACCEPTED', label: 'Offer Accepted' },
  { value: 'OFFER_DELCINED', label: 'Offer Decline' },
  { value: ' NO_LONGER_INTERESTED', label: 'No Longer Interested' },
  { value: 'JOINED', label: 'Joined' },
  { value: 'NOT_JOINED', label: 'Not Joined' },
];

export const hasResignedList = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const validateDate = (params: any) =>
  params?.value && dayjs(params.value).isValid() ? dateFromatter(params.value) : '-';

export const getFileInfo = (documentName: any, label: string) => {
  const fileExtension = documentName.split('.').pop().toLowerCase();
  const mimeTypeMap: any = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };
  const mimeType = mimeTypeMap[fileExtension] || 'application/pdf';
  const fileName = `${label}.${fileExtension}`;

  return { mimeType, fileName, fileExtension };
};
