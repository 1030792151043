import { Box, Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CustomerRelationClient, {
  PATHS as opportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { ApiOperations } from '../../utils/utils';
import { getFullName, getProcessFields } from '../Opportunity/entitiesFormConfig';
import useProjectDetailsStyles from './ProjectDetailStyles';

interface CustomerInfoFormProps {
  // eslint-disable-next-line react/require-default-props
  readonly customerInfo: any;
  readonly updateFetch: () => void;
  readonly toCcMailInfo: any;
}

const CustomerInfoForm: React.FC<CustomerInfoFormProps> = ({
  customerInfo,
  updateFetch,
  toCcMailInfo,
}) => {
  const [fields, setFields] = useState({
    name: '',
    poc: [],
    accountPayablePoc: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);

  const projectDetailStyles = useProjectDetailsStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);
  const intl = useIntl();
  const params = useParams();

  const getAllContacts = () => {
    setIsLoading(true);
    CustomerRelationClient.getCrmContactList()
      .then((data) => {
        setContacts(
          data.map((contact: any) => ({
            label: `${getFullName(contact)} (${contact.email})`,
            value: contact.uid,
          })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllContacts();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getInitialValues = () => {
    const { name, poc, accountPayablePoc } = customerInfo;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const initVal: any = {
      name: name || '',
      poc:
        poc?.map((contact: any) => ({
          label: `${getFullName(contact)} (${contact.email})`,
          value: contact.uid,
        })) || [],
      accountPayablePoc:
        accountPayablePoc?.map((contact: any) => ({
          label: `${getFullName(contact)} (${contact.email})`,
          value: contact.uid,
        })) || [],
    };
    setFields(initVal);
  };

  useEffect(() => {
    getInitialValues();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [customerInfo, contacts]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Customer name is required'),
    poc: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .required('POC is required')
      .min(1),
    accountPayablePoc: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .required('POC is required')
      .min(1),
  });

  const handleSubmit = (values: any) => {
    const { name, accountPayablePoc, poc } = values;
    const { uid } = customerInfo;
    const addCRMCustomerParams: any = {
      name,
      accountPayablePoc: accountPayablePoc.map((p: any) => p.value),
      poc: poc.map((p: any) => p.value),
    };
    const processedParams = getProcessFields(values, addCRMCustomerParams);
    setIsLoading(true);
    CustomerRelationClient.updateCustomer(processedParams, uid)
      .then((res) => {
        showSnackbar(res, 'success');
        updateFetch();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={fields}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize>
          {(formik) => {
            const { values, setFieldValue, dirty, isValid } = formik;
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SimpleInputField
                          className={projectDetailStyles.formField}
                          name="name"
                          label="Customer Name"
                          type="string"
                          fullWidth
                          disabled
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAutocompleteField
                          name="poc"
                          key="poc"
                          label=" POC"
                          className={projectDetailStyles.formField}
                          setFieldValue={setFieldValue}
                          options={contacts}
                          values={values}
                          required
                          multiple
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAutocompleteField
                          name="accountPayablePoc"
                          key="accountPayablePoc"
                          label="Account Payable POC"
                          className={projectDetailStyles.formField}
                          setFieldValue={setFieldValue}
                          options={contacts}
                          values={values}
                          required
                          multiple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <span className={projectDetailStyles.Header}>
                      {intl.formatMessage({
                        id: I18nKey.INVOICE_MAIL_DETAIL,
                      })}
                    </span>
                    <Box className={projectDetailStyles.infoWrapper}>
                      <Box className={projectDetailStyles.infoshareWrapper}>
                        To:
                        <span className={projectDetailStyles.infoText}>{toCcMailInfo?.toStr}</span>
                      </Box>
                      <Box className={projectDetailStyles.infoshareWrapper}>
                        CC:
                        <span className={projectDetailStyles.infoText}>{toCcMailInfo?.ccStr}</span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  disabled={!dirty || !isValid}
                  variant="contained"
                  type="submit"
                  className="upload-button">
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SAVE,
                  })}
                </Button>
              </Form>
            );
          }}
        </Formik>
      ) : null}

      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
    </>
  );
};

export default CustomerInfoForm;
