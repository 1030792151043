import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';

interface MisDialogProps {
  readonly title: string;
  readonly subTitle?: string;
  readonly message?: string;
  readonly width?: any;
  readonly isOpen: boolean;
  readonly handleSuccess: () => void;
  readonly handleClose: () => void;
  readonly actionBtnLabel?: string | React.ReactNode;
  readonly hideSubmitBtn?: boolean;
  readonly disableSubmitBtn?: boolean;
  readonly additionalInfoSection?: ReactElement | null;
}
const MisDialog: React.FC<MisDialogProps> = ({
  title,
  subTitle,
  message,
  width,
  isOpen,
  handleSuccess,
  handleClose,
  actionBtnLabel,
  hideSubmitBtn,
  disableSubmitBtn,
  additionalInfoSection,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      fullWidth
      maxWidth={width}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      {message ? (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{subTitle}</DialogContentText>
          {message}
        </DialogContent>
      ) : null}
      {additionalInfoSection ? (
        <DialogContent>
          <>{additionalInfoSection}</>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {intl.formatMessage({
            id: I18nKey.BUTTON_LABEL_CANCEL,
          })}
        </Button>
        {!hideSubmitBtn && (
          <Button variant="contained" onClick={handleSuccess} disabled={disableSubmitBtn}>
            {actionBtnLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

MisDialog.defaultProps = {
  subTitle: '',
  message: '',
  width: 'sm',
  actionBtnLabel: 'Confirm',
  hideSubmitBtn: false,
  disableSubmitBtn: false,
  additionalInfoSection: null,
};

export default MisDialog;
