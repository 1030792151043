import * as React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Box, Button, LinearProgress } from '@mui/material';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import TicketClient from '../../api/Ticket/TicketAPIs';
import { Params, Props, priority } from './TicketTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useTicketFilterStyles from './TicketFilterStyles';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import MisPopover from '../../components/MisPopover/MisPopover';
import I18nKey from '../../translations/I18nKey';

const TicketFilter = ({ setLocalTickets, categoryData, assigneeData }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const styles = useTicketFilterStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const [params, setParams] = useState<Params>({
    query: '',
    priority: '',
    category_uid: '',
    start_date: '',
    end_date: '',
    assignee_uid: '',
    reporter_uid: '',
  });
  const [initialValues, setinitialValues] = useState<Params>({
    query: '',
    priority: '',
    category_uid: '',
    start_date: '',
    end_date: '',
    assignee_uid: '',
    reporter_uid: '',
  });

  useEffect(() => {
    setIsLoading(true);
    const timerId = setTimeout(() => {
      const ticketFilters = Object.entries(params)
        .filter(([key, value]) => value !== undefined && value !== '')
        .map(([key, value]) => ({ key, value }));
      TicketClient.filterTickets(ticketFilters)
        .then((res: any) => {
          setLocalTickets(res);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const validationSchema = Yup.object().shape({
    priority: Yup.string(),
    category_uid: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    query: Yup.string(),
    assignee_uid: Yup.string(),
    reporter_uid: Yup.string(),
  });

  const handleFieldChange = (fieldName: string, value: any) => {
    let updatedValue = value;
    if (
      fieldName === 'priority' ||
      fieldName === 'assignee_uid' ||
      fieldName === 'category_uid' ||
      fieldName === 'reporter_uid'
    ) {
      updatedValue = value?.value;
    }
    setParams((prevParams) => ({
      ...prevParams,
      [fieldName]: updatedValue,
    }));
  };

  const handleClear = () => {
    setParams({
      priority: '',
      category_uid: '',
      start_date: '',
      end_date: '',
      query: '',
      assignee_uid: '',
      reporter_uid: '',
    });
    setinitialValues({
      priority: '',
      category_uid: '',
      start_date: '',
      end_date: '',
      query: '',
      assignee_uid: '',
      reporter_uid: '',
    });
  };

  const handleSubmit = (values: any) => {};

  return (
    <Box>
      <Button onClick={handleClick} className={styles.buttonWrapper}>
        <FilterListIcon color="primary" />
        Filters
      </Button>
      <MisPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        {isLoading && <LinearProgress />}
        <CloseIcon onClick={handleClose} className={styles.closeIcon} />
        <Box className={styles.ticketfilterFormWrapper}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize>
            {(formik) => {
              const { values, setFieldValue } = formik;
              return (
                <>
                  <Form>
                    <Grid container rowSpacing={2} spacing={1}>
                      <Grid item xs={3}>
                        <SimpleInputField
                          label="Search Ticket"
                          name="query"
                          type="text"
                          size="small"
                          variant="standard"
                          onChange={(e: any, value: any) => {
                            setinitialValues({ ...initialValues, query: e.target.value });
                            handleFieldChange('query', e.target.value);
                          }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleAutocompleteField
                          name="priority"
                          key="priority"
                          placeholder="Priority"
                          label="Priority"
                          size="small"
                          variant="standard"
                          handleChange={(e: any, v: any) => {
                            handleFieldChange('priority', v);
                            setinitialValues({ ...initialValues, priority: v });
                          }}
                          setFieldValue={setFieldValue}
                          options={priority}
                          values={values}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleAutocompleteField
                          name="category_uid"
                          key="category_uid"
                          placeholder="Category"
                          label="Category"
                          size="small"
                          variant="standard"
                          handleChange={(e: any, v: any) => {
                            handleFieldChange('category_uid', v);
                            setinitialValues({ ...initialValues, category_uid: v });
                          }}
                          setFieldValue={setFieldValue}
                          options={categoryData}
                          values={values}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleAutocompleteField
                          name="assignee_uid"
                          key="assignee_uid"
                          placeholder="Assigned To"
                          label="Assignee To"
                          size="small"
                          variant="standard"
                          handleChange={(e: any, v: any) => {
                            handleFieldChange('assignee_uid', v);
                            setinitialValues({ ...initialValues, assignee_uid: v });
                          }}
                          options={assigneeData}
                          values={values}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleAutocompleteField
                          name="reporter_uid"
                          key="reporter_uid"
                          placeholder="Raised By"
                          label="Raised By"
                          size="small"
                          variant="standard"
                          setFieldValue={setFieldValue}
                          handleChange={(e: any, v: any) => {
                            handleFieldChange('reporter_uid', v);
                            setinitialValues({ ...initialValues, reporter_uid: v });
                          }}
                          options={assigneeData}
                          values={values}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleInputField
                          label="Start Date"
                          name="start_date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e: any, value: any) => {
                            setinitialValues({ ...initialValues, start_date: e.target.value });
                            handleFieldChange('start_date', e.target.value);
                          }}
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SimpleInputField
                          label="End Date"
                          name="end_date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e: any, value: any) => {
                            setinitialValues({ ...initialValues, end_date: e.target.value });
                            handleFieldChange('end_date', e.target.value);
                          }}
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <Button variant="outlined" onClick={handleClear} className={styles.clrIcon}>
                          {intl.formatMessage({
                            id: I18nKey.TICKETS_FILTER_CLEAR,
                          })}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </MisPopover>
    </Box>
  );
};

export default TicketFilter;
