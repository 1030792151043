import React, { useEffect, useState } from 'react';
import { TotalRowFooterProps } from '../DatatableTypes';
import { calculateFooterRow } from '../datatableUtils';
import useDatatableStyles from '../DatatableStyles';

const TotalRowFooter: React.FC<TotalRowFooterProps> = ({
  rows,
  columns,
  columnVisibilityModel,
}) => {
  const [footerRows, setFooterRows] = useState<any[]>([]);
  const DatatableStyles = useDatatableStyles();

  useEffect(() => {
    const updatedModel = Object.keys(columnVisibilityModel).filter(
      (val) => columnVisibilityModel[val],
    );
    const filteredColumns = columns.filter((v) => updatedModel.includes(v.field));
    setFooterRows(calculateFooterRow(rows, filteredColumns));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [rows, columns]);

  return (
    <div className={DatatableStyles.footerRowWrapper}>
      <div className={DatatableStyles.footerRow}>
        {footerRows.map((f) => (
          <div
            key={`${f.value}${f.field}`}
            className={`footerCell ${f.cellClassName}`}
            style={{ flex: f.flex }}>
            <span>{f.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalRowFooter;
