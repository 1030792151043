import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { Link, useParams, useLocation } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import useInterviewDetailsStyles from './InterviewDetailsStyles';
import InterviewSideCard from './InterviewSideCard';
import { tabLabels, Tabs } from './utils';
import { InterviewDetailsTypes } from '../../api/Interview/interviewTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import InterviewClient from '../../api/Interview/interviewAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import InterviewerFormDetails from './InterviewerFormDetails';
import InterviewFeedbackDetails from './InterviewFeedbackDetails';

const InterviewDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<InterviewDetailsTypes>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [level, setLevel] = useState(0);
  const [fetch, setFetch] = useState<number>(0);
  const intl = useIntl();
  const styles = useInterviewDetailsStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const tab: any = queryParams.selected_tab || '0';
  const selectedTab: Tabs = parseInt(tab, 10);
  const [currentRound, setCurrentRount] = useState<string>(tabLabels[selectedTab]);

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const getCandidateDetails = () => {
    setIsLoading(true);
    InterviewClient.getAllCandidateDetails(id)
      .then((response: InterviewDetailsTypes) => {
        setDetailsData(response);
        setIsFormDirty(false);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id) getCandidateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetch, selectedTab]);

  useEffect(() => {
    setLevel(selectedTab);
    setCurrentRount(tabLabels[selectedTab]);
  }, [selectedTab]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.detailWrapper}>
        <Box className={styles.Header}>
          <Box>
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
              })}
            </span>
            <Box>
              <Link to={`/interview?selected_tab=${selectedTab + 1}`}>{currentRound}</Link>
              {' / '}
              {detailsData?.candidateDetails?.candidateName}
            </Box>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={2.4}>
            <InterviewSideCard detailsData={detailsData} level={level} candidateUid={id} />
          </Grid>
          <Grid item xs={9.6}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <InterviewFeedbackDetails
                  updateFetch={updateFetch}
                  detailsData={detailsData}
                  selectedTab={selectedTab}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InterviewDetails;
