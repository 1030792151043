import { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, Link as SimpleLink, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useIntl } from 'react-intl';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { Assignee, CategoryTypes, priority } from './TicketTypes';
import useTicketsStyles from './TicketsStyles';
import I18nKey from '../../translations/I18nKey';
import MisDialog from '../../components/MisDialog/MisDialog';
import TicketClient from '../../api/Ticket/TicketAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';

interface AddTicketFormProps {
  readonly handleSubmit?: any;
  readonly isEdit?: boolean;
  readonly ticket: any;
  readonly categoryData: any;
  readonly assigneeData: any;
  readonly deleteTicket?: any;
  readonly updateTicketDetail?: any;
  readonly ticketDeleteId?: any;
  readonly fileInputRef?: any;
}

const AddTicketForm: React.FC<AddTicketFormProps> = ({
  ticket,
  handleSubmit,
  isEdit,
  categoryData,
  assigneeData,
  deleteTicket,
  updateTicketDetail,
  ticketDeleteId,
  fileInputRef,
}: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const styles = useTicketsStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const handlePreviewAttachment = (url: string, label: string, isPreview: boolean) => {
    const previewAttachmentParams = {
      preSignedUrl: url,
    };
    TicketClient.previewModeAttachment(previewAttachmentParams)
      .then((res) => {
        const contentType = res.headers['content-type'] || 'application/pdf';
        const blob = new Blob([res.data], { type: contentType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (isPreview) {
          link.target = '_blank';
        } else {
          link.download = label;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const deleteAttachment = (attachId: any) => {
    setOpenDialog(true);
    setDeleteAttachmentId(attachId);
  };
  const handleDeleteAttachment = () => {
    setIsLoading(true);
    TicketClient.deleteAttachment(deleteAttachmentId)
      .then((result) => {
        showSnackbar(result, 'success');
        handleClose();
        updateTicketDetail();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    category: Yup.object()
      .shape({ label: Yup.string(), code: Yup.string() })
      .typeError('Select Category is required'),
    priority: Yup.object()
      .shape({ label: Yup.string(), code: Yup.string() })
      .typeError('Select Priority is required'),
    assignee: Yup.object().shape({ label: Yup.string(), code: Yup.string() }).nullable(),
    dueAt: Yup.date().required('Due At is required'),
    comment: Yup.string().max(1000),
  });

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any,
    setFieldValue: any,
  ) => {
    let defaultAssignee = null;

    if (
      value.label === CategoryTypes.HARDWARE ||
      value.label === CategoryTypes.MEDICAL ||
      value.label === CategoryTypes.WELCOMEKIT ||
      value.label === CategoryTypes.LAPTOP ||
      value.label === CategoryTypes.IDCARD ||
      value.label === CategoryTypes.ASSET ||
      value.label === CategoryTypes.OATSTAX ||
      value.label === CategoryTypes.TRAVEL ||
      value.label === CategoryTypes.ACCOMMODATION
    ) {
      defaultAssignee = assigneeData.find((assignee: any) => assignee.label === Assignee.HELPDESK);
    } else if (
      value.label === CategoryTypes.OATSPASSWORD ||
      value.label === CategoryTypes.OATSMANAGER
    ) {
      defaultAssignee = assigneeData.find((assignee: any) => assignee.label === Assignee.HR);
    } else if (
      value.label === CategoryTypes.GOOGLEPASSWORD ||
      value.label === CategoryTypes.PAYROLL ||
      value.label === CategoryTypes.HR
    ) {
      defaultAssignee = assigneeData.find((assignee: any) => assignee.label === Assignee.Admin);
    }
    setFieldValue('assignee', defaultAssignee);
  };

  return (
    <>
      <Box className={styles.ticketFormWrapper}>
        <Formik
          initialValues={ticket}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize>
          {(formik) => {
            const { values, isValid, dirty, setFieldValue } = formik;
            return (
              <>
                <Form>
                  <Grid container rowSpacing={2} spacing={1}>
                    <Grid item xs={12}>
                      <SimpleInputField
                        label="Ticket Title"
                        name="title"
                        type="string"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleInputField
                        label="Description"
                        name="description"
                        type="string"
                        size="small"
                        fullWidth
                        rows={2}
                        multiline
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleAutocompleteField
                        name="category"
                        key="category"
                        placeholder="Select Category"
                        label="Select Category"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={categoryData}
                        values={values}
                        required
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: any) =>
                          handleCategoryChange(event, value, setFieldValue)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleAutocompleteField
                        name="priority"
                        key="priority"
                        placeholder="Select Priority"
                        label="Select Priority"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={priority}
                        values={values}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleAutocompleteField
                        name="assignee"
                        key="assignee"
                        placeholder="Select Assignee"
                        label="Select Assignee"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={assigneeData}
                        values={values}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleInputField
                        label="Due At"
                        name="dueAt"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SimpleInputField
                        name="attachments"
                        label="Add Attachments"
                        variant="standard"
                        type="file"
                        onChange={(evt: any) => {
                          setFieldValue(`files`, evt.currentTarget.files[0]);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={fileInputRef}
                        inputProps={{ accept: '.pdf, .jpg, .jpeg, .png' }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        {Array.isArray(ticket.files) && ticket.files.length > 0 && (
                          <>
                            <Typography className={styles.uploadText}>Uploaded files: </Typography>
                            {ticket.files.map((file: any) => (
                              <Box key={file.id} className={styles.fileDetailsWrapper}>
                                <Box className={styles.fileInfo}>
                                  <DescriptionOutlinedIcon
                                    color="primary"
                                    className={styles.pdfIcon}
                                  />
                                  <Tooltip title={file.file_name}>
                                    <SimpleLink
                                      component="button"
                                      className={styles.fileName}
                                      onClick={() => {
                                        handlePreviewAttachment(
                                          file.s3_file_path,
                                          file.file_name,
                                          false,
                                        );
                                      }}
                                      type="button">
                                      {file.file_name}
                                    </SimpleLink>
                                  </Tooltip>
                                </Box>
                                <Box className={styles.clearIconWrapper}>
                                  <ClearIcon
                                    color="primary"
                                    className={styles.clrIcon}
                                    onClick={() => deleteAttachment(file.attachment_uid)}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </>
                        )}
                      </Box>
                    </Grid>
                    {isEdit && (
                      <Grid item xs={12}>
                        <SimpleInputField
                          label="Comments"
                          name="comments"
                          type="string"
                          size="small"
                          fullWidth
                          multiline
                          rows={4}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {isEdit && (
                    <Button
                      className={styles.editCreateBtns}
                      onClick={() => deleteTicket(ticketDeleteId)}>
                      {intl.formatMessage({
                        id: I18nKey.DELETE,
                      })}
                    </Button>
                  )}
                  <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                    {isEdit
                      ? intl.formatMessage({
                          id: I18nKey.SAVE,
                        })
                      : intl.formatMessage({
                          id: I18nKey.CREATE,
                        })}
                  </Button>
                </Form>
                <MisDialog
                  title={intl.formatMessage({
                    id: I18nKey.TICKET_MANAGEMENT_TITLE,
                  })}
                  message={intl.formatMessage({
                    id: I18nKey.ATTACHMENT,
                  })}
                  isOpen={openDialog}
                  handleSuccess={handleDeleteAttachment}
                  handleClose={handleClose}
                  actionBtnLabel="Delete"
                />
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AddTicketForm;
