import { GridActionsCellItem, GridCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'name', sort: 'asc' };

const dynamicColumns: [] = [];

const getColumns = (rowActions: any) => {
  return [
    {
      field: 'name',
      type: 'string',
      headerName: 'Customer Name',
      flex: 2,
      sortable: true,
      renderCell: (params: GridCellParams) => (
        <Link to={`/customer/${params.row.id}`} key={params.row.id}>
          {params.row.name}
        </Link>
      ),
    },
    {
      field: 'state',
      type: 'string',
      headerName: 'State',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'country',
      type: 'string',
      headerName: 'Country',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'pincode',
      type: 'string',
      headerName: 'Pincode/Zipcode',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'poc',
      type: 'string',
      headerName: 'POC',
      flex: 1.5,
      sortable: true,
      cellClassName: 'poc-cell',
      renderCell: (params: GridCellParams) => {
        return params.value?.map(({ id, firstName, middleName, lastName }: any) => (
          <div className="poc" key={id}>
            {middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`}
          </div>
        ));
      },
    },
    {
      field: 'accountPayablePoc',
      type: 'string',
      headerName: 'Account Payable Poc',
      flex: 1.5,
      sortable: true,
      cellClassName: 'poc-cell',
      renderCell: (params: GridCellParams) => {
        return params.value?.map(({ id, firstName, middleName, lastName }: any) => (
          <div className="poc" key={id}>
            {middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`}
          </div>
        ));
      },
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      width: 10,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action: any) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
};

const getInitialVisibilityModel = ({ showColumns }: any, rowActions: any[]) => {
  const colRange = getColumns(rowActions);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const customerIdName = 'uid';

const initialShowCols = [
  'name',
  'state',
  'country',
  'pincode',
  'poc',
  'accountPayablePoc',
  'actions',
];

export {
  getColumns,
  initialSort,
  dynamicColumns,
  customerIdName,
  getInitialVisibilityModel,
  initialShowCols,
};
