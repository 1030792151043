import AssignmentIcon from '@mui/icons-material/Assignment';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CloseIcon from '@mui/icons-material/Close';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PhoneIcon from '@mui/icons-material/Phone';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { Box, Button, Modal, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NewCandidateClient from '../../api/NewCandidate/newCandidateAPIs';
import Footer from '../../components/Footer/Footer';
import MisDialog from '../../components/MisDialog/MisDialog';
import useModalPopupStyles from '../../components/ModalPopup/ModalPopupStyle';
import EmpDocument from '../../components/NewCandidateDocument/EmpDocument';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import { clearNewCandidateAttributes, getNewCandidateAttributes } from '../../utils/auth';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import NewCandidateBasicDetails from './NewCandidateBasicDetails';
import NewCandidateContacts from './NewCandidateContacts';
import NewCandidateHeader from './NewCandidateHeader';
import NewCandidatePersonalDetails from './NewCandidatePersonalDetails';
import useNewCandidateStyles from './NewCandidateStyles';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}
const NewCandidates = () => {
  const params = useParams();
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMissDialog, setOpenMisDialog] = useState(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [isAnyFieldMissing, setIsAnyFieldMissing] = useState(true);
  const [isPersonalDocUploaded, setIsPersonalDocUploaded] = useState(false);
  const [isProfessionalDocUploaded, setIsProfessionalDocUploaded] = useState(false);

  const [formState, setFormState] = useState<{
    0: any;
    1: any;
    2: {
      relationData: any[];
      emergencyContacts: any[];
    };
  }>({
    0: {
      personalEmail: '',
      firstName: '',
      lastName: '',
      middleName: '',
      dob: '',
      gender: '' || null,
      nationality: '',
      bloodGroup: '' || null,
      phone: '',
      linkedinUrl: '',
    },
    1: {
      pan: '',
      aadhaar: '',
      uan: '',
      bankAccountNo: '',
      bankIfscCode: '',
      bankName: '',
      isSameAddress: false,
      currentAddress: '',
      currentCity: '',
      currentPincode: '',
      currentState: '',
      currentCountry: '',
      permanentAddress: '',
      permanentCity: '',
      permanentState: '',
      permanentPincode: '',
      permanentCountry: '',
    },
    2: {
      relationData: [],
      emergencyContacts: [],
    },
  });

  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { name, picture } = location.state || {};
  const [isTokenValid, setIsTokenValid] = useState(false);
  const queryParams = queryString.parse(location?.search);
  const ModalPopupStyle = useModalPopupStyles();

  const [gender, setGender] = useState([
    {
      code: 'Male',
      label: 'Male',
    },
    {
      code: 'Female',
      label: 'Female',
    },
    {
      code: 'Other',
      label: 'Other',
    },
  ]);
  const [relations, setRelations] = useState([
    {
      code: 'Father',
      label: 'Father',
    },
    {
      code: 'Mother',
      label: 'Mother',
    },
    {
      code: 'Wife',
      label: 'Wife',
    },
    {
      code: 'Husband',
      label: 'Husband',
    },
    {
      code: 'Daughter',
      label: 'Daughter',
    },
    {
      code: 'Son',
      label: 'Son',
    },
    {
      code: 'Others',
      label: 'Others',
    },
  ]);
  const [bloodGroup, setBloodGroup] = useState([
    {
      code: 'A+',
      label: 'A+',
    },
    {
      code: 'A-',
      label: 'A-',
    },
    {
      code: 'B+',
      label: 'B+',
    },
    {
      code: 'B-',
      label: 'B-',
    },
    {
      code: 'O+',
      label: 'O+',
    },
    {
      code: 'O-',
      label: 'O-',
    },
    {
      code: 'AB+',
      label: 'AB+',
    },
    {
      code: 'AB-',
      label: 'AB-',
    },
  ]);
  const [tabValue, setTabValue] = useState(Number(queryParams?.selected_tab) || 0);
  const styles = useNewCandidateStyles();
  const basicFormRef = useRef<any>();
  const personalFormRef = useRef<any>();
  const contactFormRef = useRef<any>();
  const [newJoineeId, setNewJoineeId] = useState('');
  const [tokenValue, setTokenValue] = useState<any>('');

  const formRefDict: Record<number, any> = {
    0: basicFormRef,
    1: personalFormRef,
    2: contactFormRef,
  };
  const handleTabChange = (event: any, newValue: number) => {
    if (formRefDict[tabValue]?.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[tabValue] = formRefDict[tabValue]?.current?.values;
      setFormState(newFormState);
    }

    formRefDict[newValue]?.current?.validateForm();
    setTabValue(newValue);
  };

  const validateToken = () => {
    const token = getNewCandidateAttributes();
    setTokenValue(token);
    if (!token) {
      setIsTokenValid(false);
      setIsLoading(false);
      setOpenDialog(true);
    } else {
      NewCandidateClient.validateToken(token)
        .then((validateResponse) =>
          // eslint-disable-next-line
          getCandidateDetailsAndCheckIfAlreadySubmitted(validateResponse?.new_joinee_uid),
        )
        .catch(() => {
          setIsTokenValid(false);
          setOpenDialog(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    validateToken();
    // eslint-disable-next-line
  }, []);

  const getCandidateDetails = (joineeId: any) => {
    return NewCandidateClient.getNewCandidateDetails(joineeId);
  };

  const submittedCandidateDetails = (joineeId: any): any => {
    return NewCandidateClient.submittedCandidateDetails(joineeId);
  };

  const getCandidateDetailsAndCheckIfAlreadySubmitted = (joineeId: any) => {
    return Promise.all([getCandidateDetails(joineeId), submittedCandidateDetails(joineeId)]).then(
      (result) => {
        setIsTokenValid(true);
        setNewJoineeId(joineeId);
        const candidateDetailsResponse = result[0];
        const submittedCandidateDetailsResponse: any = result[1];
        if (submittedCandidateDetailsResponse && submittedCandidateDetailsResponse?.data?.length) {
          setIsAlreadySubmitted(true);
        } else {
          // eslint-disable-next-line
          setInitialState(candidateDetailsResponse);
        }
      },
    );
  };

  const setDocumentUploadedState = (document_details: any) => {
    const documentDetailsPresent = Boolean(document_details && document_details.length);
    if (documentDetailsPresent) {
      const documentTypesSubmitted = document_details.map(
        (document: any) => document.document_category,
      );
      if (documentTypesSubmitted.includes('personal')) {
        setIsPersonalDocUploaded(true);
      } else {
        setIsPersonalDocUploaded(false);
      }

      if (documentTypesSubmitted.includes('professional')) {
        setIsProfessionalDocUploaded(true);
      } else {
        setIsProfessionalDocUploaded(false);
      }
    }
  };
  const setInitialState = (candidateDetailsResponse: any) => {
    if (candidateDetailsResponse.data && candidateDetailsResponse.data.length) {
      const newFormState: any = { ...formState };
      const requiredCandidateDetails = candidateDetailsResponse.data[0].details.personal_details;
      const requiredCandidateFinanceDetails =
        candidateDetailsResponse.data[0].details.finance_details;
      const requiredCandidateEmergencyContactDetails =
        candidateDetailsResponse.data[0].details.emergency_contact_details;
      const requiredCandidateRelationDetails =
        candidateDetailsResponse.data[0].details.relation_details;

      setDocumentUploadedState(candidateDetailsResponse.data[0].details.document_details);

      const areAllKeysPresentPersonalDetailToSave =
        requiredCandidateDetails &&
        Object.entries(requiredCandidateDetails).every(
          ([key, value]) => key === 'middle_name' || (value !== null && value !== ''),
        );
      const areAllKeysPresentFinanceDetailToSave =
        requiredCandidateFinanceDetails &&
        Object.values(requiredCandidateFinanceDetails).every((x) => x !== null && x !== '');
      const areAllKeysPresentRelationDetailToSave =
        requiredCandidateRelationDetails &&
        Object.values(requiredCandidateRelationDetails).every((x) => x !== null && x !== '');
      const areAllKeysPresentEmergencyDetailToSave =
        requiredCandidateEmergencyContactDetails &&
        Object.values(requiredCandidateEmergencyContactDetails).every(
          (x) => x !== null && x !== '',
        );

      if (
        areAllKeysPresentPersonalDetailToSave &&
        areAllKeysPresentFinanceDetailToSave &&
        areAllKeysPresentRelationDetailToSave &&
        areAllKeysPresentEmergencyDetailToSave
      ) {
        setIsAnyFieldMissing(false);
      } else {
        setIsAnyFieldMissing(true);
      }

      newFormState[0] = {
        ...newFormState[0],
        personalEmail: requiredCandidateDetails.personal_email || '',
        firstName: requiredCandidateDetails.first_name || '',
        lastName: requiredCandidateDetails.last_name || '',
        middleName: requiredCandidateDetails.middle_name || '',
        bloodGroup:
          bloodGroup.find((v: any) => v.code === requiredCandidateDetails.blood_group) || '',
        dob: requiredCandidateDetails.dob,
        gender: gender.find((v: any) => v.code === requiredCandidateDetails.gender) || '',
        nationality: requiredCandidateDetails.nationality || '',
        phone: requiredCandidateDetails.phone.startsWith('+') ? requiredCandidateDetails.phone : '',
        linkedinUrl: requiredCandidateDetails.linkedin_url || '',
      };

      newFormState[1] = {
        ...newFormState[1],
        permanentAddress: requiredCandidateDetails.permanent_address || '',
        permanentCity: requiredCandidateDetails.permanent_city || '',
        permanentState: requiredCandidateDetails.permanent_state || '',
        permanentCountry: requiredCandidateDetails.permanent_country || '',
        permanentPincode: requiredCandidateDetails.permanent_pincode || '',
        currentAddress: requiredCandidateDetails.current_address,
        currentCity: requiredCandidateDetails.current_city,
        currentState: requiredCandidateDetails.current_state,
        currentCountry: requiredCandidateDetails.current_country,
        currentPincode: requiredCandidateDetails.current_pincode,
      };

      if (requiredCandidateFinanceDetails) {
        newFormState[1] = {
          ...newFormState[1],
          pan: requiredCandidateFinanceDetails.pan,
          aadhaar: requiredCandidateFinanceDetails.aadhaar,
          uan: requiredCandidateFinanceDetails.uan,
          bankAccountNo: requiredCandidateFinanceDetails.bank_account_no,
          bankIfscCode: requiredCandidateFinanceDetails.bank_ifsc_code,
          bankName: requiredCandidateFinanceDetails.bank_name,
        };
      }

      newFormState[2].emergencyContacts = requiredCandidateEmergencyContactDetails
        ? requiredCandidateEmergencyContactDetails.map((details: any) => {
            return {
              firstName: details.first_name,
              middleName: details.middle_name,
              lastName: details.last_name,
              relation: relations.find((r) => r.label === details.relation),
              phone: details.phone,
            };
          })
        : [];

      newFormState[2].relationData = requiredCandidateRelationDetails
        ? requiredCandidateRelationDetails.map((details: any) => {
            return {
              firstName: details.first_name,
              middleName: details.middle_name,
              lastName: details.last_name,
              relation: relations.find((r) => r.label === details.relation),
              dob: details.dob,
              isDependant: details.is_dependant,
              isNominee: details.is_nominee,
              nomineePercent: details.nominee_percent,
            };
          })
        : [];
      setFormState(newFormState);
    }
  };
  const persistCandidateDetails = (status: string) => {
    let personalDetailsToSave = {
      personalEmail: formState[0].personalEmail || null,
      firstName: formState[0].firstName || null,
      lastName: formState[0].lastName || null,
      middleName: formState[0].middleName || null,
      dob: formState[0].dob || null,
      gender: formState[0].gender?.code || null,
      nationality: formState[0].nationality || null,
      bloodGroup: formState[0].bloodGroup?.code || null,
      phone: formState[0].phone || null,
      linkedinUrl: formState[0].linkedinUrl || null,
      currentAddress: formState[1].currentAddress || null,
      currentCity: formState[1].currentCity || null,
      currentState: formState[1].currentState || null,
      currentCountry: formState[1].currentCountry || null,
      currentPincode: formState[1].currentPincode || null,
      permanentAddress: formState[1].permanentAddress || null,
      permanentCity: formState[1].permanentCity || null,
      permanentState: formState[1].permanentState || null,
      permanentCountry: formState[1].permanentCountry || null,
      permanentPincode: formState[1].permanentPincode || null,
    };

    let financeDetailsToSave = {
      pan: formState[1].pan || null,
      aadhaar: formState[1].aadhaar || null,
      uan: formState[1].uan || null,
      bankAccountNo: formState[1].bankAccountNo || null,
      bankIfscCode: formState[1].bankIfscCode || null,
      bankName: formState[1].bankName || null,
    };

    let relationDetailsToSave = formState[2].relationData?.map((val) => {
      return {
        ...val,
        relation: val.relation.code,
      };
    });

    let emergencyContactsToSave = formState[2].emergencyContacts?.map((val) => {
      return {
        ...val,
        relation: val.relation.code,
      };
    });

    if (basicFormRef.current) {
      personalDetailsToSave = {
        ...personalDetailsToSave,
        ...basicFormRef.current.values,
        gender: basicFormRef.current.values?.gender?.code,
        bloodGroup: basicFormRef.current.values?.bloodGroup?.code,
      };
    }

    if (personalFormRef.current) {
      personalDetailsToSave = {
        ...personalDetailsToSave,
        currentAddress: personalFormRef.current.values?.currentAddress || null,
        currentCity: personalFormRef.current.values?.currentCity || null,
        currentState: personalFormRef.current.values?.currentState || null,
        currentCountry: personalFormRef.current.values?.currentCountry || null,
        currentPincode: personalFormRef.current.values?.currentPincode || null,
        permanentAddress: personalFormRef.current.values?.permanentAddress || null,
        permanentCity: personalFormRef.current.values?.permanentCity || null,
        permanentState: personalFormRef.current.values?.permanentState || null,
        permanentCountry: personalFormRef.current.values?.permanentCountry || null,
        permanentPincode: personalFormRef.current.values?.permanentPincode || null,
      };

      financeDetailsToSave = {
        ...financeDetailsToSave,
        pan: personalFormRef.current.values.pan || null,
        aadhaar: personalFormRef.current.values.aadhaar || null,
        uan: personalFormRef.current.values.uan || null,
        bankAccountNo: personalFormRef.current.values.bankAccountNo || null,
        bankIfscCode: personalFormRef.current.values.bankIfscCode || null,
        bankName: personalFormRef.current.values.bankName || null,
      };
    }

    if (contactFormRef.current) {
      relationDetailsToSave = contactFormRef.current.values.relationData?.map((val: any) => {
        return {
          ...val,
          relation: val.relation.code,
        };
      });

      emergencyContactsToSave = contactFormRef.current.values.emergencyContacts?.map((val: any) => {
        return {
          ...val,
          relation: val.relation.code,
        };
      });
    }

    setIsLoading(true);
    if (basicFormRef.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[0] = basicFormRef?.current?.values;
      setFormState(newFormState);
    }
    if (personalFormRef.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[1] = personalFormRef?.current?.values;
      setFormState(newFormState);
    }

    if (contactFormRef.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[2] = contactFormRef?.current?.values;
      setFormState(newFormState);
    }

    const areAllKeysPresentPersonalDetailToSave = Object.values(personalDetailsToSave).every(
      (x) => x !== null && x !== '',
    );
    const areAllKeysPresentFinanceDetailToSave = Object.values(financeDetailsToSave).every(
      (x) => x !== null && x !== '',
    );
    const areAllKeysPresentRelationDetailToSave =
      relationDetailsToSave?.length &&
      Object.values(relationDetailsToSave).every((x) => x !== null && x !== '');
    const areAllKeysPresentEmergencyDetailToSave =
      emergencyContactsToSave?.length &&
      Object.values(emergencyContactsToSave).every((x) => x !== null && x !== '');

    return NewCandidateClient.saveCandidateDetails(
      newJoineeId,
      {
        personalDetailsToSave,
        financeDetailsToSave,
        relationDetailsToSave,
        emergencyContactsToSave,
      },
      status,
    )
      .then(() => {
        if (
          areAllKeysPresentPersonalDetailToSave &&
          areAllKeysPresentFinanceDetailToSave &&
          areAllKeysPresentRelationDetailToSave &&
          areAllKeysPresentEmergencyDetailToSave
        ) {
          setIsAnyFieldMissing(false);
        } else {
          setIsAnyFieldMissing(true);
        }
        showSnackbar('', 'success', 'Saved successfully');
      })
      .catch((e: any) => {
        let errorMessage = '';
        try {
          errorMessage = e?.response?.data?.detail
            .map((data: any) => `${data.loc[data.loc.length - 1]} : ${data.msg}`)
            .join(' | ');
        } catch (error: any) {
          errorMessage = e?.response?.data?.detail;
        }
        showSnackbar(
          e,
          'error',
          `Make sure that you have filled all the details correctly. ${errorMessage}.`,
        );
        throw new Error(e);
      })
      .finally(() => {
        setIsLoading(false);
        setOpenMisDialog(false);
      });
  };

  const handleSave = () => {
    persistCandidateDetails('draft');
  };

  const handleSubmit = () => {
    persistCandidateDetails('submitted').then((res) => {
      clearNewCandidateAttributes();
      navigate(`/onBoarding/candidate/${tokenValue}`);
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseMisDialog = () => {
    setOpenMisDialog(false);
  };

  const handleAlreadySubmittedClose = () => {
    const token = getNewCandidateAttributes();
    clearNewCandidateAttributes();
    navigate(`/onBoarding/candidate/${token}`);
  };

  const handleOpenMisDialog = () => {
    setOpenMisDialog(true);
  };

  const handleIfPersonalDocsAreUploaded = (val: boolean) => {
    setIsPersonalDocUploaded(val);
  };
  const handleIfProfessionalDocsAreUploaded = (val: boolean) => {
    setIsProfessionalDocUploaded(val);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      {isTokenValid && !isAlreadySubmitted ? (
        <>
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SUBMIT,
            })}
            message={intl.formatMessage({
              id: I18nKey.SUBMIT_MSG,
            })}
            isOpen={openMissDialog}
            handleSuccess={handleSubmit}
            handleClose={handleCloseMisDialog}
            actionBtnLabel={intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SUBMIT,
            })}
          />
          <NewCandidateHeader name={name} picture={picture} />
          <Toolbar />
          <Box className={styles.addEmployeeWrapper}>
            <Box className={styles.Header}>
              <Box>
                <span className="headerTitle">
                  {intl.formatMessage({
                    id: I18nKey.NEW_CANDIDATE,
                  })}
                </span>
              </Box>
            </Box>
            <Box className={styles.contentWrapper}>
              <Box
                className={`${styles.formContainer} ${
                  tabValue === 3 ? styles.DocformContainer : ''
                }`}>
                <Tabs
                  className={styles.tabs}
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example">
                  <Tab label="Basic details" icon={<PersonPinIcon />} iconPosition="start" />
                  <Tab
                    label="Personal details"
                    icon={<AssignmentIcon />}
                    iconPosition="start"
                    disabled={false}
                  />
                  <Tab
                    label="Emergency Contact"
                    icon={<PhoneIcon />}
                    iconPosition="start"
                    disabled={false}
                  />
                  <Tab
                    label="Documents"
                    icon={<NoteAddIcon />}
                    iconPosition="start"
                    disabled={false}
                  />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <NewCandidateBasicDetails
                    formState={formState[0]}
                    gender={gender}
                    bloodGroup={bloodGroup}
                    formRef={basicFormRef}
                    onSave={handleSave}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <NewCandidatePersonalDetails
                    formState={formState[1]}
                    formRef={personalFormRef}
                    onSave={handleSave}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <NewCandidateContacts
                    formState={formState[2]}
                    relations={relations}
                    formRef={contactFormRef}
                    onSave={handleSave}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <EmpDocument
                    isUploadable
                    employeeId={newJoineeId}
                    handleIfPersonalDocsAreUploaded={handleIfPersonalDocsAreUploaded}
                    handleIfProfessionalDocsAreUploaded={handleIfProfessionalDocsAreUploaded}
                  />
                </TabPanel>
                <Tooltip
                  title={
                    isAnyFieldMissing || !(isPersonalDocUploaded && isProfessionalDocUploaded)
                      ? intl.formatMessage({
                          id: I18nKey.SUBMIT_DISABLED_TOOLTIP,
                        })
                      : ''
                  }>
                  <span>
                    <Button
                      className={styles.submitBtn}
                      onClick={handleOpenMisDialog}
                      disabled={
                        isAnyFieldMissing || !(isPersonalDocUploaded && isProfessionalDocUploaded)
                      }>
                      {intl.formatMessage({
                        id: I18nKey.BUTTON_LABEL_SUBMIT,
                      })}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Footer />
        </>
      ) : (
        <div>
          {!isLoading && !isTokenValid ? (
            <Modal open={openDialog} onClose={handleClose}>
              <Box>
                <Box className={ModalPopupStyle.modalWrapper}>
                  <div className={ModalPopupStyle.popupWrapper}>
                    <Box className={ModalPopupStyle.closeIconBox}>
                      <CloseIcon
                        fontSize="medium"
                        color="info"
                        className={styles.closeIcon}
                        onClick={() => handleClose()}
                      />
                    </Box>
                    <div className={ModalPopupStyle.content}>
                      <Box className={ModalPopupStyle.boxCenter}>
                        <CancelSharpIcon color="error" fontSize="large" />
                      </Box>
                      <Box className={ModalPopupStyle.boxCenter}>
                        {intl.formatMessage({
                          id: I18nKey.LINK_INFO,
                        })}
                      </Box>
                    </div>
                  </div>
                </Box>
              </Box>
            </Modal>
          ) : (
            <Modal open={isAlreadySubmitted} onClose={handleAlreadySubmittedClose}>
              <Box>
                <Box className={ModalPopupStyle.modalWrapper}>
                  <div className={ModalPopupStyle.popupWrapper}>
                    <Box className={ModalPopupStyle.closeIconBox}>
                      <CloseIcon
                        fontSize="medium"
                        color="info"
                        className={ModalPopupStyle.closeIcon}
                        onClick={() => handleAlreadySubmittedClose()}
                      />
                    </Box>
                    <div className={ModalPopupStyle.content}>
                      <Box className={ModalPopupStyle.boxCenter}>
                        <WarningRoundedIcon color="warning" fontSize="large" />
                      </Box>
                      <Box className={ModalPopupStyle.boxCenter}>
                        {intl.formatMessage({
                          id: I18nKey.HR_MSG,
                        })}
                      </Box>
                    </div>
                  </div>
                </Box>
              </Box>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default NewCandidates;
