export const currencies = [
  {
    label: 'US Dollar ($ USD)',
    value: 'USD',
    symbol: '$',
  },
  {
    label: 'Euro (€ EUR)',
    value: 'EUR',
    symbol: '€',
  },
  {
    label: 'Indian Rupee (₹ INR)',
    value: 'INR',
    symbol: '₹',
  },
  {
    label: 'Afghan Afghani (؋ AFA)',
    value: 'AFA',
    symbol: '؋',
  },
  {
    label: 'Albanian Lek (Lek ALL)',
    value: 'ALL',
    symbol: 'Lek',
  },
  {
    label: 'Algerian Dinar (دج DZD)',
    value: 'DZD',
    symbol: 'دج',
  },
  {
    label: 'Angolan Kwanza (Kz AOA)',
    value: 'AOA',
    symbol: 'Kz',
  },
  {
    label: 'Argentine Peso ($ ARS)',
    value: 'ARS',
    symbol: '$',
  },
  {
    label: 'Armenian Dram (֏ AMD)',
    value: 'AMD',
    symbol: '֏',
  },
  {
    label: 'Aruban Florin (ƒ AWG)',
    value: 'AWG',
    symbol: 'ƒ',
  },
  {
    label: 'Australian Dollar ($ AUD)',
    value: 'AUD',
    symbol: '$',
  },
  {
    label: 'Azerbaijani Manat (m AZN)',
    value: 'AZN',
    symbol: 'm',
  },
  {
    label: 'Bahamian Dollar (B$ BSD)',
    value: 'BSD',
    symbol: 'B$',
  },
  {
    label: 'Bahraini Dinar (.د.ب BHD)',
    value: 'BHD',
    symbol: '.د.ب',
  },
  {
    label: 'Bangladeshi Taka (৳ BDT)',
    value: 'BDT',
    symbol: '৳',
  },
  {
    label: 'Barbadian Dollar (Bds$ BBD)',
    value: 'BBD',
    symbol: 'Bds$',
  },
  {
    label: 'Belarusian Ruble (Br BYR)',
    value: 'BYR',
    symbol: 'Br',
  },
  {
    label: 'Belgian Franc (fr BEF)',
    value: 'BEF',
    symbol: 'fr',
  },
  {
    label: 'Belize Dollar ($ BZD)',
    value: 'BZD',
    symbol: '$',
  },
  {
    label: 'Bermudan Dollar ($ BMD)',
    value: 'BMD',
    symbol: '$',
  },
  {
    label: 'Bhutanese Ngultrum (Nu. BTN)',
    value: 'BTN',
    symbol: 'Nu.',
  },
  {
    label: 'Bitcoin (฿ BTC)',
    value: 'BTC',
    symbol: '฿',
  },
  {
    label: 'Bolivian Boliviano (Bs. BOB)',
    value: 'BOB',
    symbol: 'Bs.',
  },
  {
    label: 'Bosnia Herzegovina Convertible Mark (KM BAM)',
    value: 'BAM',
    symbol: 'KM',
  },
  {
    label: 'Botswanan Pula (P BWP)',
    value: 'BWP',
    symbol: 'P',
  },
  {
    label: 'Brazilian Real (R$ BRL)',
    value: 'BRL',
    symbol: 'R$',
  },
  {
    label: 'British Pound Sterling (£ GBP)',
    value: 'GBP',
    symbol: '£',
  },
  {
    label: 'Brunei Dollar (B$ BND)',
    value: 'BND',
    symbol: 'B$',
  },
  {
    label: 'Bulgarian Lev (Лв. BGN)',
    value: 'BGN',
    symbol: 'Лв.',
  },
  {
    label: 'Burundian Franc (FBu BIF)',
    value: 'BIF',
    symbol: 'FBu',
  },
  {
    label: 'Cambodian Riel (KHR KHR)',
    value: 'KHR',
    symbol: 'KHR',
  },
  {
    label: 'Canadian Dollar ($ CAD)',
    value: 'CAD',
    symbol: '$',
  },
  {
    label: 'Cape Verdean Escudo ($ CVE)',
    value: 'CVE',
    symbol: '$',
  },
  {
    label: 'Cayman Islands Dollar ($ KYD)',
    value: 'KYD',
    symbol: '$',
  },
  {
    label: 'CFA Franc BCEAO (CFA XOF)',
    value: 'XOF',
    symbol: 'CFA',
  },
  {
    label: 'CFA Franc BEAC (FCFA XAF)',
    value: 'XAF',
    symbol: 'FCFA',
  },
  {
    label: 'CFP Franc (₣ XPF)',
    value: 'XPF',
    symbol: '₣',
  },
  {
    label: 'Chilean Peso ($ CLP)',
    value: 'CLP',
    symbol: '$',
  },
  {
    label: 'Chinese Yuan (¥ CNY)',
    value: 'CNY',
    symbol: '¥',
  },
  {
    label: 'Colombian Peso ($ COP)',
    value: 'COP',
    symbol: '$',
  },
  {
    label: 'Comorian Franc (CF KMF)',
    value: 'KMF',
    symbol: 'CF',
  },
  {
    label: 'Congolese Franc (FC CDF)',
    value: 'CDF',
    symbol: 'FC',
  },
  {
    label: 'Costa Rican ColÃ³n (₡ CRC)',
    value: 'CRC',
    symbol: '₡',
  },
  {
    label: 'Croatian Kuna (kn HRK)',
    value: 'HRK',
    symbol: 'kn',
  },
  {
    label: 'Cuban Convertible Peso ($, CUC CUC)',
    value: 'CUC',
    symbol: '$, CUC',
  },
  {
    label: 'Czech Republic Koruna (Kč CZK)',
    value: 'CZK',
    symbol: 'Kč',
  },
  {
    label: 'Danish Krone (Kr. DKK)',
    value: 'DKK',
    symbol: 'Kr.',
  },
  {
    label: 'Djiboutian Franc (Fdj DJF)',
    value: 'DJF',
    symbol: 'Fdj',
  },
  {
    label: 'Dominican Peso ($ DOP)',
    value: 'DOP',
    symbol: '$',
  },
  {
    label: 'East Caribbean Dollar ($ XCD)',
    value: 'XCD',
    symbol: '$',
  },
  {
    label: 'Egyptian Pound (ج.م EGP)',
    value: 'EGP',
    symbol: 'ج.م',
  },
  {
    label: 'Eritrean Nakfa (Nfk ERN)',
    value: 'ERN',
    symbol: 'Nfk',
  },
  {
    label: 'Estonian Kroon (kr EEK)',
    value: 'EEK',
    symbol: 'kr',
  },
  {
    label: 'Ethiopian Birr (Nkf ETB)',
    value: 'ETB',
    symbol: 'Nkf',
  },

  {
    label: 'Falkland Islands Pound (£ FKP)',
    value: 'FKP',
    symbol: '£',
  },
  {
    label: 'Fijian Dollar (FJ$ FJD)',
    value: 'FJD',
    symbol: 'FJ$',
  },
  {
    label: 'Gambian Dalasi (D GMD)',
    value: 'GMD',
    symbol: 'D',
  },
  {
    label: 'Georgian Lari (ლ GEL)',
    value: 'GEL',
    symbol: 'ლ',
  },
  {
    label: 'German Mark (DM DEM)',
    value: 'DEM',
    symbol: 'DM',
  },
  {
    label: 'Ghanaian Cedi (GH₵ GHS)',
    value: 'GHS',
    symbol: 'GH₵',
  },
  {
    label: 'Gibraltar Pound (£ GIP)',
    value: 'GIP',
    symbol: '£',
  },
  {
    label: 'Greek Drachma (₯, Δρχ, Δρ GRD)',
    value: 'GRD',
    symbol: '₯, Δρχ, Δρ',
  },
  {
    label: 'Guatemalan Quetzal (Q GTQ)',
    value: 'GTQ',
    symbol: 'Q',
  },
  {
    label: 'Guinean Franc (FG GNF)',
    value: 'GNF',
    symbol: 'FG',
  },
  {
    label: 'Guyanaese Dollar ($ GYD)',
    value: 'GYD',
    symbol: '$',
  },
  {
    label: 'Haitian Gourde (G HTG)',
    value: 'HTG',
    symbol: 'G',
  },
  {
    label: 'Honduran Lempira (L HNL)',
    value: 'HNL',
    symbol: 'L',
  },
  {
    label: 'Hong Kong Dollar ($ HKD)',
    value: 'HKD',
    symbol: '$',
  },
  {
    label: 'Hungarian Forint (Ft HUF)',
    value: 'HUF',
    symbol: 'Ft',
  },
  {
    label: 'Icelandic KrÃ³na (kr ISK)',
    value: 'ISK',
    symbol: 'kr',
  },

  {
    label: 'Indonesian Rupiah (Rp IDR)',
    value: 'IDR',
    symbol: 'Rp',
  },
  {
    label: 'Iranian Rial (﷼ IRR)',
    value: 'IRR',
    symbol: '﷼',
  },
  {
    label: 'Iraqi Dinar (د.ع IQD)',
    value: 'IQD',
    symbol: 'د.ع',
  },
  {
    label: 'Israeli New Sheqel (₪ ILS)',
    value: 'ILS',
    symbol: '₪',
  },
  {
    label: 'Italian Lira (L,£ ITL)',
    value: 'ITL',
    symbol: 'L,£',
  },
  {
    label: 'Jamaican Dollar (J$ JMD)',
    value: 'JMD',
    symbol: 'J$',
  },
  {
    label: 'Japanese Yen (¥ JPY)',
    value: 'JPY',
    symbol: '¥',
  },
  {
    label: 'Jordanian Dinar (ا.د JOD)',
    value: 'JOD',
    symbol: 'ا.د',
  },
  {
    label: 'Kazakhstani Tenge (лв KZT)',
    value: 'KZT',
    symbol: 'лв',
  },
  {
    label: 'Kenyan Shilling (KSh KES)',
    value: 'KES',
    symbol: 'KSh',
  },
  {
    label: 'Kuwaiti Dinar (ك.د KWD)',
    value: 'KWD',
    symbol: 'ك.د',
  },
  {
    label: 'Kyrgystani Som (лв KGS)',
    value: 'KGS',
    symbol: 'лв',
  },
  {
    label: 'Laotian Kip (₭ LAK)',
    value: 'LAK',
    symbol: '₭',
  },
  {
    label: 'Latvian Lats (Ls LVL)',
    value: 'LVL',
    symbol: 'Ls',
  },
  {
    label: 'Lebanese Pound (£ LBP)',
    value: 'LBP',
    symbol: '£',
  },
  {
    label: 'Lesotho Loti (L LSL)',
    value: 'LSL',
    symbol: 'L',
  },
  {
    label: 'Liberian Dollar ($ LRD)',
    value: 'LRD',
    symbol: '$',
  },
  {
    label: 'Libyan Dinar (د.ل LYD)',
    value: 'LYD',
    symbol: 'د.ل',
  },
  {
    label: 'Lithuanian Litas (Lt LTL)',
    value: 'LTL',
    symbol: 'Lt',
  },
  {
    label: 'Macanese Pataca ($ MOP)',
    value: 'MOP',
    symbol: '$',
  },
  {
    label: 'Macedonian Denar (ден MKD)',
    value: 'MKD',
    symbol: 'ден',
  },
  {
    label: 'Malagasy Ariary (Ar MGA)',
    value: 'MGA',
    symbol: 'Ar',
  },
  {
    label: 'Malawian Kwacha (MK MWK)',
    value: 'MWK',
    symbol: 'MK',
  },
  {
    label: 'Malaysian Ringgit (RM MYR)',
    value: 'MYR',
    symbol: 'RM',
  },
  {
    label: 'Maldivian Rufiyaa (Rf MVR)',
    value: 'MVR',
    symbol: 'Rf',
  },
  {
    label: 'Mauritanian Ouguiya (MRU MRO)',
    value: 'MRO',
    symbol: 'MRU',
  },
  {
    label: 'Mauritian Rupee (₨ MUR)',
    value: 'MUR',
    symbol: '₨',
  },
  {
    label: 'Mexican Peso ($ MXN)',
    value: 'MXN',
    symbol: '$',
  },
  {
    label: 'Moldovan Leu (L MDL)',
    value: 'MDL',
    symbol: 'L',
  },
  {
    label: 'Mongolian Tugrik (₮ MNT)',
    value: 'MNT',
    symbol: '₮',
  },
  {
    label: 'Moroccan Dirham (MAD MAD)',
    value: 'MAD',
    symbol: 'MAD',
  },
  {
    label: 'Mozambican Metical (MT MZM)',
    value: 'MZM',
    symbol: 'MT',
  },
  {
    label: 'Myanmar Kyat (K MMK)',
    value: 'MMK',
    symbol: 'K',
  },
  {
    label: 'Namibian Dollar ($ NAD)',
    value: 'NAD',
    symbol: '$',
  },
  {
    label: 'Nepalese Rupee (₨ NPR)',
    value: 'NPR',
    symbol: '₨',
  },
  {
    label: 'Netherlands Antillean Guilder (ƒ ANG)',
    value: 'ANG',
    symbol: 'ƒ',
  },
  {
    label: 'New Taiwan Dollar ($ TWD)',
    value: 'TWD',
    symbol: '$',
  },
  {
    label: 'New Zealand Dollar ($ NZD)',
    value: 'NZD',
    symbol: '$',
  },
  {
    label: 'Nicaraguan CÃ³rdoba (C$ NIO)',
    value: 'NIO',
    symbol: 'C$',
  },
  {
    label: 'Nigerian Naira (₦ NGN)',
    value: 'NGN',
    symbol: '₦',
  },
  {
    label: 'North Korean Won (₩ KPW)',
    value: 'KPW',
    symbol: '₩',
  },
  {
    label: 'Norwegian Krone (kr NOK)',
    value: 'NOK',
    symbol: 'kr',
  },
  {
    label: 'Omani Rial (.ع.ر OMR)',
    value: 'OMR',
    symbol: '.ع.ر',
  },
  {
    label: 'Pakistani Rupee (₨ PKR)',
    value: 'PKR',
    symbol: '₨',
  },
  {
    label: 'Panamanian Balboa (B/. PAB)',
    value: 'PAB',
    symbol: 'B/.',
  },
  {
    label: 'Papua New Guinean Kina (K PGK)',
    value: 'PGK',
    symbol: 'K',
  },
  {
    label: 'Paraguayan Guarani (₲ PYG)',
    value: 'PYG',
    symbol: '₲',
  },
  {
    label: 'Peruvian Nuevo Sol (S/. PEN)',
    value: 'PEN',
    symbol: 'S/.',
  },
  {
    label: 'Philippine Peso (₱ PHP)',
    value: 'PHP',
    symbol: '₱',
  },
  {
    label: 'Polish Zloty (zł PLN)',
    value: 'PLN',
    symbol: 'zł',
  },
  {
    label: 'Qatari Rial (ق.ر QAR)',
    value: 'QAR',
    symbol: 'ق.ر',
  },
  {
    label: 'Romanian Leu (lei RON)',
    value: 'RON',
    symbol: 'lei',
  },
  {
    label: 'Russian Ruble (₽ RUB)',
    value: 'RUB',
    symbol: '₽',
  },
  {
    label: 'Rwandan Franc (FRw RWF)',
    value: 'RWF',
    symbol: 'FRw',
  },
  {
    label: 'Salvadoran ColÃ³n (₡ SVC)',
    value: 'SVC',
    symbol: '₡',
  },
  {
    label: 'Samoan Tala (SAT WST)',
    value: 'WST',
    symbol: 'SAT',
  },
  {
    label: 'Saudi Riyal (﷼ SAR)',
    value: 'SAR',
    symbol: '﷼',
  },
  {
    label: 'Serbian Dinar (din RSD)',
    value: 'RSD',
    symbol: 'din',
  },
  {
    label: 'Seychellois Rupee (SRe SCR)',
    value: 'SCR',
    symbol: 'SRe',
  },
  {
    label: 'Sierra Leonean Leone (Le SLL)',
    value: 'SLL',
    symbol: 'Le',
  },
  {
    label: 'Singapore Dollar ($ SGD)',
    value: 'SGD',
    symbol: '$',
  },
  {
    label: 'Slovak Koruna (Sk SKK)',
    value: 'SKK',
    symbol: 'Sk',
  },
  {
    label: 'Solomon Islands Dollar (Si$ SBD)',
    value: 'SBD',
    symbol: 'Si$',
  },
  {
    label: 'Somali Shilling (Sh.so. SOS)',
    value: 'SOS',
    symbol: 'Sh.so.',
  },
  {
    label: 'South African Rand (R ZAR)',
    value: 'ZAR',
    symbol: 'R',
  },
  {
    label: 'South Korean Won (₩ KRW)',
    value: 'KRW',
    symbol: '₩',
  },
  {
    label: 'Special Drawing Rights (SDR XDR)',
    value: 'XDR',
    symbol: 'SDR',
  },
  {
    label: 'Sri Lankan Rupee (Rs LKR)',
    value: 'LKR',
    symbol: 'Rs',
  },
  {
    label: 'St. Helena Pound (£ SHP)',
    value: 'SHP',
    symbol: '£',
  },
  {
    label: 'Sudanese Pound (.س.ج SDG)',
    value: 'SDG',
    symbol: '.س.ج',
  },
  {
    label: 'Surinamese Dollar ($ SRD)',
    value: 'SRD',
    symbol: '$',
  },
  {
    label: 'Swazi Lilangeni (E SZL)',
    value: 'SZL',
    symbol: 'E',
  },
  {
    label: 'Swedish Krona (kr SEK)',
    value: 'SEK',
    symbol: 'kr',
  },
  {
    label: 'Swiss Franc (CHf CHF)',
    value: 'CHF',
    symbol: 'CHf',
  },
  {
    label: 'Syrian Pound (LS SYP)',
    value: 'SYP',
    symbol: 'LS',
  },
  {
    label: 'São Tomé and Príncipe Dobra (Db STD)',
    value: 'STD',
    symbol: 'Db',
  },
  {
    label: 'Tajikistani Somoni (SM TJS)',
    value: 'TJS',
    symbol: 'SM',
  },
  {
    label: 'Tanzanian Shilling (TSh TZS)',
    value: 'TZS',
    symbol: 'TSh',
  },
  {
    label: 'Thai Baht (฿ THB)',
    value: 'THB',
    symbol: '฿',
  },
  {
    label: "Tongan Pa'anga ($ TOP)",
    value: 'TOP',
    symbol: '$',
  },
  {
    label: 'Trinidad & Tobago Dollar ($ TTD)',
    value: 'TTD',
    symbol: '$',
  },
  {
    label: 'Tunisian Dinar (ت.د TND)',
    value: 'TND',
    symbol: 'ت.د',
  },
  {
    label: 'Turkish Lira (₺ TRY)',
    value: 'TRY',
    symbol: '₺',
  },
  {
    label: 'Turkmenistani Manat (T TMT)',
    value: 'TMT',
    symbol: 'T',
  },
  {
    label: 'Ugandan Shilling (USh UGX)',
    value: 'UGX',
    symbol: 'USh',
  },
  {
    label: 'Ukrainian Hryvnia (₴ UAH)',
    value: 'UAH',
    symbol: '₴',
  },
  {
    label: 'United Arab Emirates Dirham (إ.د AED)',
    value: 'AED',
    symbol: 'إ.د',
  },
  {
    label: 'Uruguayan Peso ($ UYU)',
    value: 'UYU',
    symbol: '$',
  },

  {
    label: 'Uzbekistan Som (лв UZS)',
    value: 'UZS',
    symbol: 'лв',
  },
  {
    label: 'Vanuatu Vatu (VT VUV)',
    value: 'VUV',
    symbol: 'VT',
  },
  {
    label: 'Venezuelan BolÃvar (Bs VEF)',
    value: 'VEF',
    symbol: 'Bs',
  },
  {
    label: 'Vietnamese Dong (₫ VND)',
    value: 'VND',
    symbol: '₫',
  },
  {
    label: 'Yemeni Rial (﷼ YER)',
    value: 'YER',
    symbol: '﷼',
  },
  {
    label: 'Zambian Kwacha (ZK ZMK)',
    value: 'ZMK',
    symbol: 'ZK',
  },
];

export const getCurrencySymbol = (currencyCode: string) =>
  currencies.find((c) => c.value === currencyCode)?.symbol;
