import { makeStyles } from '@mui/styles';

export default makeStyles(() => {
  return {
    spinner: {
      color: '#ffffff',
      zIndex: 2,
    },
  };
});
