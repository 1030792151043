import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import LetterForm from './components/LetterForm';
import useDocumentStyles from './DocumentStyles';
import DocumentClient from '../../api/Document/DocumentAPI';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  STATUTARYBONUSMONTHLY,
  STATUTARYBONUSYEARLY,
  TRANSPORTATIONMONTHLY,
  TRANSPORTATIONYEARLY,
  getSalaryRevisionDates,
  offerLetterInitialValues,
} from './utils';
import MasterClient from '../../api/Master/masterAPIs';

const OfferLetterPage = () => {
  const styles = useDocumentStyles();
  const intl = useIntl();
  const params = useParams();
  const empCandidateUid = params.id || '';

  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allTracks, setAllTracks] = useState([]);
  const [allDesignations, setAllDesignations] = useState([]);
  const [fetch, setFetch] = useState(0);
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const location = useLocation();

  const queryParams = queryString.parse(location?.search);
  const compensationLetterCondition = queryParams.letter === 'compensationLetter';

  const searchParams = new URLSearchParams(location.search);
  const salaryRevisionYear = searchParams.get('salary_revision_year');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const { startDate: calculatedStartAndCompensationStartDate } =
    getSalaryRevisionDates(salaryRevisionYear);

  const updatedOfferLetterInitialValues = {
    ...offerLetterInitialValues,
    compensationStartDate: calculatedStartAndCompensationStartDate,
  };

  const [candidateDetails, setCandidateDetails] = useState<any>(updatedOfferLetterInitialValues);

  const updateCandidateDetails = (candidateDetail: any) => {
    const { salaryStructure } = candidateDetail;
    const details = {
      addressFirstLine: candidateDetail.addressFirstLine || '',
      addressSecondLine: candidateDetail.addressSecondLine || '',
      basicMonthly: salaryStructure?.basicMonthly || 0,
      basicYearly: salaryStructure?.basicYearly || 0,
      dateOfJoining: candidateDetail.dateOfJoining || '',
      designation: candidateDetail.designation || '',
      email: candidateDetail.email || '',
      epfMonthly: salaryStructure?.epfMonthly || 0,
      epfYearly: salaryStructure?.epfYearly || 0,
      fixedAllowanceMonthly: salaryStructure?.fixedAllowanceMonthly || 0,
      fixedAllowanceYearly: salaryStructure?.fixedAllowanceYearly || 0,
      hraMonthly: salaryStructure?.hraMonthly || 0,
      hraYearly: salaryStructure?.hraYearly || 0,
      name: candidateDetail.name || '',
      phoneNumber: candidateDetail.phoneNumber || '',
      specialPerformancePayMonthly: salaryStructure?.specialPerformancePayMonthly || 0,
      specialPerformancePayYearly: salaryStructure?.specialPerformancePayYearly || 0,
      statutaryBonusMonthly: salaryStructure?.statutaryBonusMonthly || STATUTARYBONUSMONTHLY,
      statutaryBonusYearly: salaryStructure?.statutaryBonusYearly || STATUTARYBONUSYEARLY,
      totalMonthly: salaryStructure?.totalMonthly || 0,
      yearlySalary: candidateDetail.yearlySalary || 0,
      transportMonthly: salaryStructure?.transportMonthly || TRANSPORTATIONMONTHLY,
      transportYearly: salaryStructure?.transportYearly || TRANSPORTATIONYEARLY,
      gratuityMonthly: salaryStructure?.gratuityMonthly || 0,
      gratuityYearly: salaryStructure?.gratuityYearly || 0,
      joiningBonusMonthly: salaryStructure?.joiningBonusMonthly || 'NA',
      joiningBonusYearly: salaryStructure?.joiningBonusYearly || 0,
      anniversaryBonusMonthly: salaryStructure?.anniversaryBonusMonthly || 'NA',
      anniversaryBonusYearly: salaryStructure?.anniversaryBonusYearly || 0,
      targetAchievementBonusMonthly: salaryStructure?.targetAchievementBonusMonthly || 'NA',
      targetAchievementBonusYearly: salaryStructure?.targetAchievementBonusYearly || 0,
      individualPerformanceBonusMonthly: salaryStructure?.individualPerformanceBonusMonthly || 'NA',
      individualPerformanceBonusYearly: salaryStructure?.individualPerformanceBonusYearly || 0,
      isBonusGiven: candidateDetail?.isBonusGiven || false,
    };
    setCandidateDetails(details);
  };

  const getCandidateDetailsById = (uid: string) => {
    DocumentClient.getCandidateOfferLetterDetails(uid)
      .then((response: any) => {
        updateCandidateDetails(response.data);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  const updateEmployeeDetails = (employeeDetail: any) => {
    const details = {
      uid: employeeDetail?.uid,
      designation: employeeDetail?.designationName,
      addressFirstLine: employeeDetail?.currentAddress || '',
      addressSecondLine: employeeDetail.permanentAddress || '',
      phoneNumber: employeeDetail.phone || '',
      basicMonthly: 0,
      basicYearly: 0,
      epfMonthly: 0,
      epfYearly: 0,
      fixedAllowanceMonthly: 0,
      fixedAllowanceYearly: 0,
      hraMonthly: 0,
      hraYearly: 0,
      specialPerformancePayMonthly: 0,
      specialPerformancePayYearly: 0,
      statutaryBonusMonthly: STATUTARYBONUSMONTHLY,
      statutaryBonusYearly: STATUTARYBONUSYEARLY,
      totalMonthly: 0,
      yearlySalary: 0,
      transportMonthly: TRANSPORTATIONMONTHLY,
      transportYearly: TRANSPORTATIONYEARLY,
      gratuityMonthly: 0,
      gratuityYearly: 0,
      joiningBonusMonthly: 'NA',
      joiningBonusYearly: 0,
      anniversaryBonusMonthly: 'NA',
      anniversaryBonusYearly: 0,
      targetAchievementBonusMonthly: 'NA',
      targetAchievementBonusYearly: 0,
      individualPerformanceBonusMonthly: 'NA',
      individualPerformanceBonusYearly: 0,
      isBonusGiven: employeeDetail.isBonusGiven || false,
      isPromoted: employeeDetail.isPromoted || false,
      designationName:
        allDesignations.find((v: any) => v.name === employeeDetail?.designationName) || null,
      department:
        allDepartments.find((v: any) => v.name === employeeDetail?.departmentName) || null,
      track: allTracks.find((v: any) => v.name === employeeDetail?.trackName) || null,
      dateOfJoining: employeeDetail?.doj || '',
      email: employeeDetail.email || '',
      name: employeeDetail.employeeName || '',
      startDate: calculatedStartAndCompensationStartDate, // set default to 1st July, as per requirement
      empId: employeeDetail.employeeId,
      employeeUid: employeeDetail.employeeUid,
      compensationStartDate: employeeDetail.compensationStartDate,
    };
    setCandidateDetails(details);
  };

  const getLabel = (values: any) =>
    values.map((el: any) => ({
      ...el,
      label: el.name,
      value: el.uid,
    }));
  const getEmployeeDetailsById = (uid: string) => {
    DocumentClient.getEmployeeOfferLetterDetails(uid, salaryRevisionYear)
      .then((response: any) => {
        updateEmployeeDetails(response.data);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  const getAllEnums = () => {
    Promise.allSettled([
      MasterClient.getDepartment(),
      MasterClient.getTrack(),
      MasterClient.getDesignation(),
    ])
      .then((response) => {
        response.forEach((res, index) => {
          if (res.status === 'fulfilled') {
            if (index === 0) {
              setAllDepartments(getLabel(res.value.data));
            } else if (index === 1) {
              setAllTracks(getLabel(res.value.data));
            } else if (index === 2) {
              setAllDesignations(getLabel(res.value.data));
            }
          } else {
            setLoading(false);
            showSnackbar(res.status, 'error');
          }
        });
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (empCandidateUid) {
      setLoading(true);
      if (compensationLetterCondition) {
        if (allTracks && allDepartments && allDesignations) {
          getEmployeeDetailsById(empCandidateUid);
        }
      } else {
        getCandidateDetailsById(empCandidateUid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, allTracks, allDepartments, allDesignations]);

  useEffect(
    () => {
      setLoading(true);
      getAllEnums();
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [],
  );

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.detailPageWrapper}>
        <Box className={styles.DetailPageHeader}>
          <span>
            {intl.formatMessage({
              id: I18nKey.GENERATE_DOCUMENT_TITLE,
            })}
          </span>
          {empCandidateUid ? (
            <>
              {' '}
              {compensationLetterCondition ? (
                <Box>
                  <Link
                    to={`/document/compensationLetter?startDate=${startDate}&endDate=${endDate}&salary_revision_year=${salaryRevisionYear}`}>
                    {intl.formatMessage({
                      id: I18nKey.COMPENSATION_LETTER_TITLE,
                    })}
                  </Link>{' '}
                  / {candidateDetails?.name}
                </Box>
              ) : (
                <Box>
                  <Link to="/document/offerLetter">
                    {intl.formatMessage({
                      id: I18nKey.OFFER_LETTER,
                    })}
                  </Link>{' '}
                  / {candidateDetails?.name}
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Link to="/document/offerLetter">
                {intl.formatMessage({
                  id: I18nKey.OFFER_LETTER,
                })}
              </Link>{' '}
              /{' '}
              {intl.formatMessage({
                id: I18nKey.NEW_OFFER_LETTER,
              })}
            </Box>
          )}
        </Box>
        <Box className={styles.ContentWrapper}>
          <LetterForm
            candidateDetails={candidateDetails}
            candidateId={empCandidateUid}
            allDepartments={allDepartments}
            allTracks={allTracks}
            allDesignations={allDesignations}
            updateFetch={updateFetch}
            compensationLetterCondition={compensationLetterCondition}
          />
        </Box>
      </Box>
    </>
  );
};

export default OfferLetterPage;
