import React, { useEffect, useRef, useState } from 'react';
import { Formik, FieldArray, Form, Field } from 'formik';
import {
  Box,
  Rating,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import MisDialog from '../../../components/MisDialog/MisDialog';
import I18nKey from '../../../translations/I18nKey';
import InterviewStatusForm from './InterviewStatusForm';
import InterviewClient from '../../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import useFeedbackDetailsFormStyles from './FeedbackDetailsFormStyle';

interface FeedbackDetailsFormProps {
  readonly roundWiseData: any;
  readonly updateFetch: any;
}

const FeedbackDetailsForm: React.FC<FeedbackDetailsFormProps> = ({
  roundWiseData,
  updateFetch,
}) => {
  const styles = useFeedbackDetailsFormStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [openAttributeDialog, setOpenAttributeDialog] = React.useState(false);
  const [isStatusFormValid, setIsStatusFormValid] = useState(true);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [newAttribute, setNewAttribute] = React.useState('');
  const [initialField, setInitialField] = useState({
    status: null,
    finalComments: null,
  });
  const [submitDetails, setSubmitDetails] = useState<any>();
  const [initialFormState, setInitialFormState] = useState({
    skills: [{ skill: '', rating: 0, comments: '' }],
    project: '',
    projectComment: '',
    rating: 0,
    comments: '',
    attributes: [
      { name: 'Communication', rating: '' },
      { name: 'Problem Solving Ability', rating: '' },
      { name: 'Inquisitiveness', rating: '' },
      { name: 'Attitude', rating: '' },
    ],
  });
  const statusFormRef = useRef<any>();

  const handleSubmit = (values: any) => {
    setSubmitDetails(values);
    setOpenSubmitDialog(true);
  };

  const handleSubmitForm = () => {
    setIsLoading(true);
    setOpenSubmitDialog(false);
    const submitData = {
      uid: roundWiseData?.interviewUid,
      roundName: roundWiseData?.interviewRoundName,
      status: statusFormRef.current?.values?.status?.value,
      finalComments: statusFormRef.current?.values?.finalComments,
      feedback: submitDetails,
    };
    InterviewClient.patchSubmitFeedback(submitData)
      .then((res) => {
        updateFetch();
        showSnackbar(res, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const validationSchema = Yup.object().shape({
    project: Yup.string().nullable(),
    projectComment: Yup.string().required('Comments are required'),
    skills: Yup.array().of(
      Yup.object().shape({
        skill: Yup.string().nullable(),
        rating: Yup.number().nullable(),
        comments: Yup.string().required('Comments are required'),
      }),
    ),
    rating: Yup.string().nullable(),
    comments: Yup.string().required('Comments are required'),
  });

  const handleAddOption = () => {
    setOpenAttributeDialog(true);
  };

  const handleSaveOption = (values: any, setFieldValue: any) => {
    const updatedAttributes = [...values.attributes];
    updatedAttributes.push({ name: newAttribute, rating: '' });
    setFieldValue('attributes', updatedAttributes);
    setOpenAttributeDialog(false);
    setNewAttribute('');
  };

  const handleAttributeClose = () => {
    setOpenAttributeDialog(false);
    setNewAttribute('');
  };

  const handleSubmitClose = () => {
    setOpenSubmitDialog(false);
  };

  const handleInterviewSchedule = (submitData: any) => {
    InterviewClient.putInterviewSchedule(submitData)
      .then((res) => {
        updateFetch();
        showSnackbar(res, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSaveForm = (values: any) => {
    setIsLoading(true);
    const submitData = {
      interviewUid: roundWiseData?.interviewUid,
      candidateUid: roundWiseData?.candidateUid,
      interviewerUid: roundWiseData?.interviewerUid,
      round: roundWiseData?.interviewRoundName,
      feedback: values,
    };
    handleInterviewSchedule(submitData);
  };

  useEffect(() => {
    if (roundWiseData) {
      setInitialFormState({
        skills: roundWiseData?.interviewFeedback?.skills || [
          { skill: '', rating: 0, comments: '' },
        ],
        project: roundWiseData?.interviewFeedback?.project || '',
        projectComment: roundWiseData?.interviewFeedback?.projectComment || '',
        rating: roundWiseData?.interviewFeedback?.rating || 0,
        comments: roundWiseData?.interviewFeedback?.comments || '',
        attributes: roundWiseData?.interviewFeedback?.attributes || [
          { name: 'Communication', rating: '' },
          { name: 'Problem Solving Ability', rating: '' },
          { name: 'Inquisitiveness', rating: '' },
          { name: 'Attitude', rating: '' },
        ],
      });
    }
  }, [roundWiseData]);

  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ values, setFieldValue, dirty, isValid }) => (
        <Form>
          <Box className={styles.FeedbackDetailsWrapper}>
            <FieldArray name="skills">
              {({ push, remove }) => (
                <Box className={styles.skillFormWrapper}>
                  <Divider>
                    <Chip label="Skills" className="divider-chip" />
                  </Divider>
                  {values.skills.map((_: any, index: any) => (
                    <div>
                      <Box className={styles.inputBoxWrapper}>
                        <Grid container spacing={1}>
                          <Grid item xs={1}>
                            <Typography className={styles.label}>Skill</Typography>
                          </Grid>
                          <Grid item xs={5.5}>
                            <SimpleInputField
                              name={`skills.${index}.skill`}
                              placeholder="Enter Skill"
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography className={styles.label}>Rating</Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Stack spacing={2}>
                              <Rating
                                name={`skills.[${index}].rating`}
                                value={values.skills[index].rating}
                                precision={0.5}
                                onChange={(event, value) => {
                                  setFieldValue(`skills.[${index}].rating`, value);
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={2}>
                            {values.skills.length > 1 && (
                              <Button
                                type="button"
                                onClick={() => {
                                  remove(index);
                                }}
                                className={styles.deleteBtn}>
                                <DeleteIcon />
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className={styles.inputBoxWrapper}>
                        <Grid container spacing={1}>
                          <Grid item xs={1}>
                            <Typography className={styles.label}>Comments</Typography>
                          </Grid>
                          <Grid item xs={10.7}>
                            <SimpleInputField
                              name={`skills.${index}.comments`}
                              placeholder="Enter Feedback"
                              minRows={2}
                              multiline
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid item xs={12}>
                        <Divider variant="middle" className={styles.divider} />
                      </Grid>
                    </div>
                  ))}
                  <Box className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      type="button"
                      sx={{ textTransform: 'none' }}
                      onClick={() => push({ skill: '', rating: 0, comments: '' })}>
                      Add Another Skill
                    </Button>
                  </Box>
                </Box>
              )}
            </FieldArray>
            <Box className={styles.logicalFormWrapper}>
              <Divider>
                <Chip label="Logical Ability" className="divider-chip" />
              </Divider>
              <Box className={styles.inputBoxWrapperRating}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography className={styles.label}>Rating</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      <Rating
                        name="rating"
                        precision={0.5}
                        value={values.rating}
                        onChange={(event, value) => {
                          setFieldValue('rating', value);
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box className={styles.inputBoxWrapper}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography className={styles.label}> Comments</Typography>
                  </Grid>
                  <Grid item xs={10.7}>
                    <SimpleInputField
                      name="comments"
                      placeholder="Enter Feedback"
                      minRows={2}
                      multiline
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={styles.feedbackFormWrapper}>
              <Divider>
                <Chip label="Feedback" className="divider-chip" />
              </Divider>
              <Box className={styles.inputBoxWrapper}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography className={styles.label}>Projects</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleInputField name="project" placeholder="Enter Projects" size="small" />
                  </Grid>
                </Grid>
              </Box>
              <Box className={styles.inputBoxWrapper}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography className={styles.label}> Comments</Typography>
                  </Grid>
                  <Grid item xs={10.7}>
                    <SimpleInputField
                      name="projectComment"
                      placeholder="Enter Feedback"
                      minRows={2}
                      multiline
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={styles.attributeFormWrapper}>
              <Divider>
                <Chip label="Attributes" className="divider-chip" />
              </Divider>
              <TableContainer>
                <Table className={styles.tableWrapper}>
                  <TableBody>
                    <FieldArray name="attributes">
                      {({ push, remove }) =>
                        values.attributes.map((option: any, index: number) => (
                          <TableRow>
                            <TableCell className={styles.optionName}>{option.name}</TableCell>
                            <TableCell>
                              <Field name={`attributes.${index}.rating`}>
                                {({ field }: { field: any }) => (
                                  <RadioGroup
                                    {...field}
                                    row
                                    className={styles.radioButtons}
                                    aria-label={`rating-${index}`}>
                                    <FormControlLabel
                                      value="poor"
                                      control={<Radio />}
                                      label="Poor"
                                    />
                                    <FormControlLabel
                                      value="average"
                                      control={<Radio />}
                                      label="Average"
                                    />
                                    <FormControlLabel
                                      value="good"
                                      control={<Radio />}
                                      label="Good"
                                    />
                                    <FormControlLabel
                                      value="excellent"
                                      control={<Radio />}
                                      label="Excellent"
                                    />
                                  </RadioGroup>
                                )}
                              </Field>
                            </TableCell>
                            <TableCell>
                              {index >= 4 && (
                                <Button onClick={() => remove(index)} className={styles.deleteBtn}>
                                  <DeleteIcon />
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={styles.buttonContainerSecond}>
                <Button onClick={handleAddOption} sx={{ textTransform: 'none' }}>
                  Add Attribute
                </Button>
              </Box>
              <MisDialog
                title={intl.formatMessage({
                  id: I18nKey.ADD_ATTRIBUTE,
                })}
                message={intl.formatMessage({
                  id: I18nKey.ATTRIBUTE_MESSAGE,
                })}
                isOpen={openAttributeDialog}
                handleSuccess={() => handleSaveOption(values, setFieldValue)}
                handleClose={handleAttributeClose}
                actionBtnLabel="Save"
                additionalInfoSection={
                  <Grid container spacing={0} className={styles.dialogTextBox}>
                    <Grid item xs={12}>
                      <TextField
                        label="Attribute"
                        value={newAttribute}
                        size="small"
                        onChange={(e: any) => setNewAttribute(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                }
                disableSubmitBtn={newAttribute === ''}
              />
            </Box>
            <Box className={styles.btnWrapper}>
              <Button
                className={styles.saveButton}
                variant="contained"
                color="primary"
                onClick={() => handleSaveForm(values)}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={
                  !isValid ||
                  !values.projectComment.trim() ||
                  !values.comments.trim() ||
                  values.skills.some((item: any) => !item.comments)
                }>
                Submit
              </Button>
            </Box>
          </Box>
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
            })}
            message={intl.formatMessage({
              id: I18nKey.INTERVIEW_SUBMIT_CONFIRMATION,
            })}
            isOpen={openSubmitDialog}
            handleSuccess={handleSubmitForm}
            handleClose={handleSubmitClose}
            actionBtnLabel={intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SUBMIT,
            })}
            disableSubmitBtn={isStatusFormValid}
            additionalInfoSection={
              <InterviewStatusForm
                initialField={initialField}
                setInitialField={setInitialField}
                setIsStatusFormValid={setIsStatusFormValid}
                statusFormRef={statusFormRef}
              />
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackDetailsForm;
