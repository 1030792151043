import { GridCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AccountTypes } from '../../utils/accountTypes';
import { getColumnConfig } from '../../components/Datatable/datatableUtils';
import { currencyValueFormatter, getListOfMonths } from '../../utils/utils';
import { getCurrencySymbol } from '../../utils/currency';

const initialSort = { field: 'resourceName', sort: 'asc' };

const dynamicColumns = [{ fieldName: 'totalBilling', columnHeader: 'month', rowCell: 'revenue' }];

const getTableRowTotalField = (currency: string) => {
  return [
    {
      field: 'total',
      headerName: `FY Total (${getCurrencySymbol(currency)})`,
      flex: 1,
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      sortable: true,
      hideable: false,
      filterable: false,
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
    },
  ];
};

const getBillingHeader = (accountType: string, currency: string) => {
  let headerLabel = 'Billing Rate';
  switch (accountType) {
    case AccountTypes.TIME_MATERIAL_MONTHLY:
      headerLabel = `${headerLabel} (${getCurrencySymbol(currency)}/mo)`;
      break;
    case AccountTypes.TIME_MATERIAL_HOURLY:
      headerLabel = `${headerLabel} (${getCurrencySymbol(currency)}/hr)`;
      break;
    default:
  }
  return headerLabel;
};

const getColumns = ({ accountType, currentFy, currency }: any) => {
  const colRange =
    dayjs(currentFy.startDate).isSame(dayjs(), 'year') ||
    dayjs(currentFy.startDate).isAfter(dayjs(), 'year')
      ? getListOfMonths(currentFy)
      : getListOfMonths(currentFy).reverse();
  return [
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Resource',
      flex: 2,
      sortable: true,
      hideable: false,
    },
    {
      field: 'skills',
      type: 'string',
      headerName: 'Skills',
      flex: 2,
      sortable: true,
      cellClassName: 'resource-skill-cell',
      renderCell: (params: GridCellParams) => {
        return params.value?.map((skill: string) => (
          <div className="resource-skill" key={skill}>
            {skill}
          </div>
        ));
      },
    },
    {
      field: 'billingRate',
      type: 'number',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      headerName: getBillingHeader(accountType, currency),
      flex: 1,
      sortable: true,
      editable: true,
    },
    {
      field: 'allocationPercentage',
      type: 'number',
      headerName: 'Allocation (%)',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      flex: 1,
      sortable: true,
      editable: true,
    },
    ...colRange.map((month) =>
      getColumnConfig({
        field: month,
        ...(dayjs(month).isBefore(dayjs().startOf('month')) && {
          headerClassName: 'disable-past-month',
          cellClassName: 'disable-past-month right-align-number-field',
        }),
        ...(dayjs(month).isSame(dayjs().startOf('month')) && {
          headerClassName: 'highlight-current-month',
          cellClassName: 'highlight-current-month right-align-number-field',
        }),
        currency,
      }),
    ),
    ...getTableRowTotalField(currency),
  ];
};

const getInitialVisibilityModel = ({ startDate, endDate, showColumns }: any) => {
  const colRange = getColumns({ currentFy: { startDate, endDate } });
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] =
      showColumns.includes(dayjs(col.field).month()) || showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const resourceIdName = 'employeeUid';

const initialShowCols = ['employeeName', 'billingRate', 'allocationPercentage', 'total', 'actions'];

export {
  initialSort,
  dynamicColumns,
  getTableRowTotalField,
  resourceIdName,
  initialShowCols,
  getColumns,
  getInitialVisibilityModel,
};
