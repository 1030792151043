import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTicketFilterStyles = makeStyles((theme: Theme) => {
  return {
    mainDiv: {
      maxWidth: 700,
      padding: '10px',
      alignItems: 'center',
    },
    searchTicket: {
      margin: '1px !important',
      minWidth: '100px ',
      marginTop: '17px !important',
    },
    searchField: {
      maxWidth: '200px !important',
    },
    priorityForm: {
      margin: '1px !important',
      minWidth: '100px !important',
    },
    priorityField: {
      minWidth: '200px !important',
    },
    categoryForm: {
      margin: '1px !important',
      minWidth: '100px !important',
    },
    selectCategory: {
      minWidth: '200px !important',
    },
    selectReporter: {
      minWidth: '200px !important',
    },
    reporterForm: {
      margin: '1px !important',
      minWidth: '100px !important',
    },
    assigneeForm: {
      margin: '1px !important',
      minWidth: '100px !important',
    },
    selectAssignee: {
      minWidth: '200px !important',
    },
    startDateForm: {
      minWidth: '100px !important',
    },
    startDateInputLabel: {
      transform: 'translate(0, -5px) scale(1) !important',
      marginLeft: '5px',
    },
    startDateInput: {
      padding: '4px',
      marginTop: '9px !important',
    },
    endDateLabel: {
      transform: 'translate(0, -5px) scale(1) !important',
      marginLeft: '5px',
    },
    endDateInput: {
      padding: '4px',
      marginTop: '9px !important',
    },
    closeForm: {
      margin: '1px !important',
      top: '5px',
      position: 'relative',
    },
    closeButton: {
      marginTop: '10px',
    },
    loader: {
      marginLeft: '50px !important',
      width: '25px !important',
    },
    closeIcon: {
      color: '#777 !important',
      float: 'right',
      marginTop: '2px !important',
      marginRight: '2px !important',
      cursor: 'pointer',
    },
    ticketfilterFormWrapper: {
      padding: '1rem',
    },
    clrIcon: {
      marginTop: '7px !important',
    },
    buttonWrapper: {
      paddingLeft: '3px !important',
      marginTop: '-8px !important',
      '& svg': {
        marginRight: '5px',
      },
    },
  };
});

export default useTicketFilterStyles;
