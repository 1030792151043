import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useAllCustomerRelationsStyles = makeStyles((theme: Theme) => {
  return {
    opportunitiesWrapper: {
      padding: '1rem',
    },
    allCustomerRelationsWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',

      '& span': {
        fontSize: '24px',
      },
    },
    contentWrapper: {
      display: 'flex',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    w70: {
      width: '70%',
    },
    card: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    topContainer: {
      height: '50px',
      backgroundColor: theme.palette.popUpBackground?.light,
      color: theme.palette.secondary.main,
      borderRadius: '12px',
      padding: '10px',

      '& strong': {
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    bottomContainer: {
      marginTop: '20px',
      position: 'relative',
      'flex-grow': 1,
    },
    detailsWrapper: {
      padding: '0.5rem',
      height: '100%',
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
    },
    formWrapper: {
      marginTop: '10px',
      padding: '0 8px 60px 8px',

      '& form > button': {
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
      },
    },

    closeBtn: {
      position: 'absolute',
      bottom: 20,
      left: 10,
      color: theme.palette.text.light,
      fontSize: '16px !important',
      backgroundColor: theme.palette.text?.lightest,
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };
});

export default useAllCustomerRelationsStyles;
