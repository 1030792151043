export const columnLinked = [
  { value: 'name', label: 'Name' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
  { value: 'country', label: 'Country' },
  { value: 'companySpecialities', label: 'Company Specialities' },
  { value: 'designation', label: 'Designation' },
  { value: 'company_head_quarters', label: 'Company Headquarters' },
];

export const operatorOptions = [
  { value: 'contains', label: 'Contains' },
  { value: 'equal', label: 'Equal' },
  { value: 'startswith', label: 'Startswith' },
  { value: 'endswith', label: 'Endswith' },
  { value: 'not_equal', label: 'Not Equal' },
  { value: 'not_contains', label: 'Not Contains' },
];
