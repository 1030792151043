import { Box, Button, CircularProgress, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useInterviewLevelTabs from './InterviewLevelTabsStyle';
import ApplicationStatusLevelForm from './Forms/ApplicationStatusLevelForm';
import ApplicationStatus from './ApplicationStatus';
import { InterviewStatus, getRoundDetails } from './utils';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface InterviewLevelTabsProps {
  readonly interviewFeedback: any;
  readonly isDetailsLoading: boolean;
  readonly isHRAccess: boolean;
  readonly updateFetch: any;
  readonly selectedTab: string;
  readonly setIsDetailsLoading: any;
  readonly interviewersData: any;
  readonly updateFetchCandidateList: any;
}
const InterviewLevelTabs: React.FC<InterviewLevelTabsProps> = ({
  interviewFeedback,
  isDetailsLoading,
  interviewersData,
  isHRAccess,
  selectedTab,
  updateFetch,
  setIsDetailsLoading,
  updateFetchCandidateList,
}) => {
  const { id } = useParams();
  const selectedRoundTab = parseInt(selectedTab, 10);
  const selectRoundTabAll =
    selectedRoundTab === 6 || selectedRoundTab === 0 ? 0 : selectedRoundTab - 1;
  const [selectedOption, setSelectedOption] = useState(selectRoundTabAll);
  const [phoneScreenRoundData, setphoneScreenRoundData] = useState<any>();
  const [roundOneData, setRoundOneData] = useState<any>();
  const [roundTwoData, setRoundTwoData] = useState<any>();
  const [finalRoundData, setfinalRoundData] = useState<any>();
  const [hrRoundData, setHrRoundData] = useState<any>();

  const [formState, setFormState] = useState({
    0: {
      selectInterviewer: null,
      datetime: dayjs(),
      meetingLink: '',
    },
    1: { selectInterviewer: null, datetime: dayjs(), meetingLink: '' },
    2: { selectInterviewer: null, datetime: dayjs(), meetingLink: '' },
    3: { selectInterviewer: null, datetime: dayjs(), meetingLink: '' },
    4: { selectInterviewer: null, datetime: dayjs(), meetingLink: '' },
  });
  const phoneScreenRoundFormRef = useRef();
  const roundOneFormRef = useRef();
  const roundTwoFormRef = useRef();
  const finalRoundFormRef = useRef();
  const hrRoundFormRef = useRef();

  const formRefDict: Record<number, any> = {
    0: phoneScreenRoundFormRef,
    1: roundOneFormRef,
    2: roundTwoFormRef,
    3: finalRoundFormRef,
    4: hrRoundFormRef,
  };

  const styles = useInterviewLevelTabs();

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };
  const handleTabChange = (event: any, newValue: number) => {
    if (formRefDict[selectedOption]?.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[selectedOption] = formRefDict[selectedOption]?.current?.values;
      setFormState(newFormState);
    }

    formRefDict[newValue]?.current?.validateForm();
    // formRefDict[newValue]?.current?.isDirty = true;
    setSelectedOption(newValue);
  };

  useEffect(() => {
    const tabPhoneScreeningData = interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'PHONESCREEN',
    );
    const tabOneData = interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'L1',
    );
    const tabTwoData = interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'L2',
    );
    const tabThreeData = interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'FINAL',
    );
    const tabFourData = interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'HR',
    );
    setphoneScreenRoundData(tabPhoneScreeningData);
    setRoundOneData(tabOneData);
    setRoundTwoData(tabTwoData);
    setfinalRoundData(tabThreeData);
    setHrRoundData(tabFourData);
    const phoneScreeningState = getRoundDetails(tabPhoneScreeningData, interviewersData);
    const roundOneFormState = getRoundDetails(tabOneData, interviewersData);
    const roundTwoFormState = getRoundDetails(
      interviewFeedback?.find((feedback: any) => feedback?.interviewRoundName === 'L2'),
      interviewersData,
    );
    const finalRoundState = getRoundDetails(tabThreeData, interviewersData);
    const hrRoundState = getRoundDetails(tabFourData, interviewersData);
    setFormState({
      ...formState,
      0: phoneScreeningState,
      1: roundOneFormState,
      2: roundTwoFormState,
      3: finalRoundState,
      4: hrRoundState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewFeedback]);

  return isDetailsLoading ? (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Box className={styles.contentWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs value={selectedOption} onChange={handleTabChange} className={styles.tabWrapper}>
            <Tab
              label={
                phoneScreenRoundData?.interviewStatus === InterviewStatus.PASSED ? (
                  <>
                    <Typography className={styles.bottomContainerTitle}>
                      <CheckCircleIcon className={styles.bottomContainerIcons} /> PS
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.bold}>PS</Typography>
                )
              }
            />
            <Tab
              label={
                roundOneData?.interviewStatus === InterviewStatus.PASSED ? (
                  <>
                    <Typography className={styles.bottomContainerTitle}>
                      <CheckCircleIcon className={styles.bottomContainerIcons} /> L1
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.bold}>L1</Typography>
                )
              }
            />
            <Tab
              label={
                roundTwoData?.interviewStatus === InterviewStatus.PASSED ? (
                  <>
                    <Typography className={styles.bottomContainerTitle}>
                      <CheckCircleIcon className={styles.bottomContainerIcons} /> L2
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.bold}>L2</Typography>
                )
              }
            />
            <Tab
              label={
                finalRoundData?.interviewStatus === InterviewStatus.PASSED ? (
                  <>
                    <Typography className={styles.bottomContainerTitle}>
                      <CheckCircleIcon className={styles.bottomContainerIcons} /> Final
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.bold}>Final</Typography>
                )
              }
            />
            <Tab
              label={
                hrRoundData?.interviewStatus === InterviewStatus.PASSED ? (
                  <>
                    <Typography className={styles.bottomContainerTitle}>
                      <CheckCircleIcon className={styles.bottomContainerIcons} /> HR
                    </Typography>
                  </>
                ) : (
                  <Typography className={styles.bold}>HR</Typography>
                )
              }
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <TabPanel value={selectedOption} index={0}>
        <Box>
          {phoneScreenRoundData?.interviewStatus &&
          phoneScreenRoundData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
            <ApplicationStatus interviewFeedback={phoneScreenRoundData} />
          ) : (
            <ApplicationStatusLevelForm
              formRef={phoneScreenRoundFormRef}
              formState={formState[0]}
              interviewRoundName="PHONESCREEN"
              isHRAccess={isHRAccess}
              candidateId={id}
              interviewersData={interviewersData}
              updateFetch={updateFetch}
              setIsDetailsLoading={setIsDetailsLoading}
              updateFetchCandidateList={updateFetchCandidateList}
            />
          )}
        </Box>
        {formState[0].selectInterviewer && (
          <Link to={`/interview/details/${id}?selected_tab=${selectedOption}`}>View Details</Link>
        )}
      </TabPanel>
      <TabPanel value={selectedOption} index={1}>
        <Box>
          {roundOneData?.interviewStatus &&
          roundOneData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
            <ApplicationStatus interviewFeedback={roundOneData} />
          ) : (
            <ApplicationStatusLevelForm
              formRef={roundOneFormRef}
              formState={formState[1]}
              interviewRoundName="L1"
              isHRAccess={isHRAccess}
              candidateId={id}
              interviewersData={interviewersData}
              updateFetch={updateFetch}
              setIsDetailsLoading={setIsDetailsLoading}
              updateFetchCandidateList={updateFetchCandidateList}
            />
          )}
        </Box>
        {formState[1].selectInterviewer && (
          <Link to={`/interview/details/${id}?selected_tab=${selectedOption}`}>View Details</Link>
        )}
      </TabPanel>
      <TabPanel value={selectedOption} index={2}>
        <Box>
          {roundTwoData?.interviewStatus &&
          roundTwoData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
            <ApplicationStatus interviewFeedback={roundTwoData} />
          ) : (
            <ApplicationStatusLevelForm
              formRef={roundTwoFormRef}
              formState={formState[2]}
              interviewRoundName="L2"
              candidateId={id}
              isHRAccess={isHRAccess}
              updateFetch={updateFetch}
              interviewersData={interviewersData}
              setIsDetailsLoading={setIsDetailsLoading}
              updateFetchCandidateList={updateFetchCandidateList}
            />
          )}
        </Box>
        {formState[2].selectInterviewer && (
          <Link to={`/interview/details/${id}?selected_tab=${selectedOption}`}>View Details</Link>
        )}
      </TabPanel>
      <TabPanel value={selectedOption} index={3}>
        <Box>
          {finalRoundData?.interviewStatus &&
          finalRoundData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
            <ApplicationStatus interviewFeedback={finalRoundData} />
          ) : (
            <ApplicationStatusLevelForm
              formRef={finalRoundFormRef}
              formState={formState[3]}
              interviewRoundName="FINAL"
              candidateId={id}
              isHRAccess={isHRAccess}
              updateFetch={updateFetch}
              interviewersData={interviewersData}
              setIsDetailsLoading={setIsDetailsLoading}
              updateFetchCandidateList={updateFetchCandidateList}
            />
          )}
        </Box>
        {formState[3].selectInterviewer && (
          <Link to={`/interview/details/${id}?selected_tab=${selectedOption}`}>View Details</Link>
        )}
      </TabPanel>
      <TabPanel value={selectedOption} index={4}>
        {hrRoundData?.interviewStatus &&
        hrRoundData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
          <ApplicationStatus interviewFeedback={hrRoundData} />
        ) : (
          <ApplicationStatusLevelForm
            formRef={hrRoundFormRef}
            formState={formState[4]}
            interviewRoundName="HR"
            candidateId={id}
            isHRAccess={isHRAccess}
            interviewersData={interviewersData}
            updateFetch={updateFetch}
            setIsDetailsLoading={setIsDetailsLoading}
            updateFetchCandidateList={updateFetchCandidateList}
          />
        )}
        {formState[4].selectInterviewer && (
          <Link to={`/interview/details/${id}?selected_tab=${selectedOption}`}>View Details</Link>
        )}
      </TabPanel>
    </Box>
  );
};

export default InterviewLevelTabs;
