import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import OnboardingClient from '../../api/OnBoarding/onboardingAPIs';
import Datatable from '../../components/Datatable/Datatable';
import {
  getColumns,
  employeeUid,
  dynamicColumns,
  tableRowTotalField,
  initialSort,
  getFirstLastName,
  getInitialVisibilityModel,
  initialShowCols,
} from './OnBoardingListConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { AllEmployeeDetails } from '../../api/OnBoarding/onBoardingTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface OnBoardingListProps {
  status: string;
}

const OnBoardingList: React.FC<OnBoardingListProps> = ({ status }: any) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );

  const getSkillNames = (row: any, isPrimary: boolean) => {
    const skillName = row.employeeSkill
      .filter((skill: any) => skill.isPrimary === isPrimary)
      .map((skills: any, i: number) => {
        return skills.skillNames.map((skill: any) => {
          return skill.name;
        });
      });
    return skillName[0];
  };

  const generateEmployeeRows = (rows: any) => {
    const updatedTableRows: any = [];
    rows.forEach((row: any) => {
      const newRow = {
        ...row.employee,
        ...row.employeeDesignation,
        ...row.employeeSkill,
      };
      newRow.employeeId = row.employee.employeeId;
      newRow.employeeUid = row.employee.uid;
      newRow.employeeName = getFirstLastName(row.employee);
      newRow.designationName = row.employeeDesignation.designationName;
      newRow.primarySkills = getSkillNames(row, true);
      newRow.otherSkills = getSkillNames(row, false);
      newRow.managerName = row.employeeManager.managerName || '';
      updatedTableRows.push(newRow);
    });
    return updatedTableRows;
  };

  useEffect(() => {
    setLoading(true);
    OnboardingClient.getEmployeeList(status)
      .then((response: any) => {
        const rows: AllEmployeeDetails[] = generateEmployeeRows(response.data);
        const updatedRows = generateRows(rows, dynamicColumns, tableRowTotalField, employeeUid);
        setTableRows(updatedRows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Datatable
        columns={getColumns()}
        rows={tableRows}
        /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`OnBoarding_List_${dayjs().format('DD_MMMM')}`}
      />
    </>
  );
};

export default OnBoardingList;
