import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Box, Divider, Typography } from '@mui/material';
import CustomerRelationClient, { PATHS } from '../../../api/Opportunity/opportunityAPIs';
import Datatable from '../../../components/Datatable/Datatable';
import {
  dynamicColumns,
  gapFillForecastedRev,
  getColumns,
  opportunityIdName,
  getTableRowTotalField,
} from '../opportunityDetailTableConfig';
import I18nKey from '../../../translations/I18nKey';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';
import FYSelector from '../../../components/FYSelector/FYSelector';
import { ApiOperations, getFYDateRange } from '../../../utils/utils';
import { generateRows } from '../../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCRMProjectDetailsStyles from '../OpportunityDetailStyles';

interface RevenueForecastProps {
  readonly projectDetail: any;
}

const currFYDateRange = getFYDateRange(dayjs());
const RevenueForecast: React.FC<RevenueForecastProps> = ({ projectDetail }) => {
  const [projectDetailState, setprojectDetailState] = useState(projectDetail);
  const [currentFy, setCurrentFy] = useState({
    ...currFYDateRange,
    year: `${dayjs(currFYDateRange.startDate).format('YYYY')} - ${dayjs(
      currFYDateRange.endDate,
    ).format('YY')}`,
  });
  const params = useParams();
  const opportunityUid = params.id || '';

  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const classes = useCRMProjectDetailsStyles();
  const { checkAccess } = useContext(GlobalContext);

  const getUpdatedProjectState = (forecastedRevenues?: any) => {
    const updatedData = { ...projectDetail };
    updatedData.forecastedRevenue = gapFillForecastedRev(
      forecastedRevenues || projectDetail.forecastedRevenue,
      currentFy.startDate,
      currentFy.endDate,
    );
    updatedData.year = currentFy.year;
    return updatedData;
  };

  useEffect(() => {
    setprojectDetailState(getUpdatedProjectState());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [projectDetail, currentFy]);

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(
      data,
      dynamicColumns,
      getTableRowTotalField(projectDetail.currency),
      opportunityIdName,
    );
    return rows;
  };

  const handleYearChange = (values: any) => {
    setCurrentFy({
      startDate: values.startDate,
      endDate: values.endDate,
      year: values.label,
    });
  };

  const handleRowUpdate = (updatedRows: any) => {
    const forecastedRevenues = projectDetail?.forecastedRevenue;
    const { updatedFields, row } = updatedRows[0];
    updatedFields.forEach((month: string) => {
      const index = forecastedRevenues.findIndex((fo: any) => fo.month === month);
      if (index === -1) forecastedRevenues.push({ month, revenue: row[month] });
      else forecastedRevenues[index] = { month, revenue: row[month] };
    });

    let newForecastedRevenueObj = {};
    forecastedRevenues.forEach((r: any) => {
      newForecastedRevenueObj = { ...newForecastedRevenueObj, [r.month]: r.revenue };
    });
    setIsLoading(true);
    CustomerRelationClient.updateCRMOpportunity(opportunityUid, {
      forecastedRevenue: newForecastedRevenueObj,
    })
      .then((res) => {
        showSnackbar(res, 'success');
        setprojectDetailState(getUpdatedProjectState(forecastedRevenues));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  const rows = getTableRows([projectDetailState]);
  return (
    <>
      <Box className={classes.MiddleCardWrapper}>
        <Box className={classes.MiddleCardHeader}>
          <Typography>
            {intl.formatMessage({
              id: I18nKey.CUSTOMER_RELATION_REVENUE_FORECAST,
            })}
          </Typography>
          <FYSelector
            onChange={handleYearChange}
            minValue={getFYDateRange(dayjs(projectDetailState?.createdOn))}
            maxValue={getFYDateRange(dayjs().add(1, 'year'))}
          />
        </Box>
        <Divider />
        <Datatable
          rows={rows}
          columns={getColumns({ currentFy, currency: projectDetail.currency })}
          updateRows={handleRowUpdate}
          tableHeight="165px"
          /* eslint-disable react/jsx-boolean-value */
          hideFooterPagination={true}
          showSearch={false}
          editable={checkAccess(PATHS.PUT_CRM_OPPORTUNITY('id'), ApiOperations.PUT)}
          hideFooter={true}
          columnVisibility={true}
          showFilterButton={false}
        />
      </Box>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
    </>
  );
};

export default RevenueForecast;
