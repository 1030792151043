import * as yup from 'yup';
import { PropertyOptions, Validation } from './FormTypes';

const createYupSchema = (
  initialSchema: any,
  { id, validationType, validations = [] }: Validation,
) => {
  if (!(yup as any)[validationType]) {
    return initialSchema;
  }

  let validator = (yup as any)[validationType]();

  validations.forEach((validation: any) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  const schema = initialSchema;
  schema[id] = validator;

  return schema;
};

export const extractFieldOptions = (properties: PropertyOptions[]) => {
  let items: any[] = [];
  properties.forEach((property) => {
    if (Array.isArray(property.value)) {
      items = property.value;
    }
  });
  return items;
};

export const createFieldProperties = (properties: PropertyOptions[], fieldProperties: any) => {
  const updatedFieldPropertied = { ...fieldProperties };
  properties.forEach((property) => {
    updatedFieldPropertied[property.key] = property.value;
  });
  return updatedFieldPropertied;
};

export const createYupValidationSchema = (validationArray: Validation[]) => {
  const yupSchema = validationArray.reduce(createYupSchema, {});
  return yup.object().shape(yupSchema);
};
