import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useOverviewStyles = makeStyles((theme: Theme) => {
  return {
    overviewWrapper: {
      margin: '20px 0px',
    },
    itemWrapper: {
      backgroundColor: theme.palette.primary.main,
      height: 'fit-content',
      padding: '15px',
      borderRadius: '12px',
      minHeight: '287px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .js-plotly-plot': {
        height: '80%',
        width: '100%',
        // '& .plot-container plotly': {
        //   '& .user-select-none svg-container': {
        //     height: '300px',
        //     width: '90%',
        //   },
        // },
      },
    },
    headingLine: {
      paddingLeft: '25px',
    },
    header: {
      marginBottom: '20px !important',
      marginTop: '20px !important',
      fontSize: '20px !important',
    },
  };
});

export default useOverviewStyles;
