import { EmployeeStatus } from '../../utils/utils';
import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  GET_EMPLOYEE: '/employee/all_details',
  ADD_EMPLOYEE: '/employee/',
  EMPLOYEE_OTHER_DETAILS: '/employee/other_details',
  EMPLOYEE_RELATION: (employee_uid: string) => `/employee/${employee_uid}/relation`,
  ADD_SKILLS: (employee_uid: string) => `/employee/${employee_uid}/skill`,
  ADD_DESIGNATION: (employee_uid: string) => `/employee/${employee_uid}/designation`,
  ADD_MANAGER: (employee_uid: string) => `/employee/${employee_uid}/manager`,
  ADD_FINANCE_DETAILS: (employee_uid: string) => `/employee/${employee_uid}/finance`,
  EMERGENCYCONTACT_DETAILS: (employee_uid: string) => `/employee/${employee_uid}/emergency/contact`,
  GET_EMPLOYEE_BY_ID: (employee_uid: string) => `/employee/${employee_uid}`,
  PUT_EMPLOYEE_FINANCE: (employee_uid: string) => `/employee/${employee_uid}/finance`,
  PUT_EMPLOYEE_SKILLS: (employee_uid: string) => `/employee/${employee_uid}/skill`,
  PUT_EMPLOYEE_DESIGNATION: (employee_uid: string) => `/employee/${employee_uid}/designation`,
  PUT_EMPLOYEE_MANAGER: (employee_uid: string, emp_manager_uid: string) =>
    `/employee/${employee_uid}/manager/${emp_manager_uid}`,
  PUT_EMPLOYEE_GROUPS: '/rbac/groups',
  EMPLOYEE_CERTIFICATE: `/profile/document/certificates`,
  EMPLOYEE_PERSONAL: `/profile/document/personal`,
  EMPLOYEE_PROFESSIONAL: `/profile/document/professional`,
  DELETE_EMPLOYEE_CERTIFICATE: (document_uid: string) =>
    `/profile/document/${document_uid}/certificates`,
  DELETE_EMPLOYEE_PERSONAL_DOCUMENT: (document_uid: string) =>
    `/profile/document/${document_uid}/personal`,
  DELETE_EMPLOYEE_PROFESSIONAL_DOCUMENT: (document_uid: string) =>
    `/profile/document/${document_uid}/professional`,
  EMPLOYEE_CERTIFICATE_BY_ID: (document_uid: string) =>
    `/employee/${document_uid}/document/certificates`,
  EMPLOYEE_PERSONAL_DOCUMENT_BY_ID: (document_uid: string) =>
    `/employee/${document_uid}/document/personal`,
  EMPLOYEE_PROFESSIONAL_DOCUMENT_BY_ID: (document_uid: string) =>
    `/employee/${document_uid}/document/professional`,
  DELETE_EMPLOYEE_CERTIFICATE_BY_ID: (employee_uid: string, document_uid: string) =>
    `/employee/${employee_uid}/document/${document_uid}/certificates`,
  DELETE_EMPLOYEE_PERSONAL_BY_ID: (employee_uid: string, document_uid: string) =>
    `/employee/${employee_uid}/document/${document_uid}/personal`,
  DELETE_EMPLOYEE_PROFESSIONAL_BY_ID: (employee_uid: string, document_uid: string) =>
    `/employee/${employee_uid}/document/${document_uid}/professional`,
  GET_CANDIDATE_PRESIGNED_URL_BY_ID: (candidate_uid: string) =>
    `/interviews/candidate/onboarding/token/${candidate_uid}`,
  GET_NEW_JOINEE_LIST: '/employee/new_joinee/all',
  DELETE_NEW_JOINEE: (newjoinee_uid: string) => `/employee/new_joinee/${newjoinee_uid}`,
});

const getEmployeeList = async (status: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_EMPLOYEE}?`, {
    params: { status, page: 0, limit: 0 },
  });
  return response;
};

const getNewJoineeList = async (status: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_NEW_JOINEE_LIST}?`, {
    params: { status },
  });
  return response;
};
const getNewJoineeById = async (new_joinee_uid: string, status: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_NEW_JOINEE_LIST}?`, {
    params: { status, new_joinee_uid },
  });
  return response;
};

const addEmployee = async (addEmployeeParams: any, new_joinee_uid?: string): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_EMPLOYEE, addEmployeeParams, {
    params: { new_joinee_uid },
  });
  return response;
};

const addRelations = async (employee_uid: string, addRelationsParams: any): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.EMPLOYEE_RELATION(employee_uid),
    addRelationsParams,
  );
  return response;
};

const addSkills = async (employee_uid: string, addSkillsParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_SKILLS(employee_uid), addSkillsParams);
  return response;
};

const addDesignation = async (employee_uid: string, addDesignationParams: any): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.ADD_DESIGNATION(employee_uid),
    addDesignationParams,
  );
  return response;
};

const addManager = async (employee_uid: string, addManagerParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_MANAGER(employee_uid), addManagerParams);
  return response;
};

const addEmergencyContacts = async (
  employee_uid: string,
  addEmergencyContactsParams: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.EMERGENCYCONTACT_DETAILS(employee_uid),
    addEmergencyContactsParams,
  );
  return response;
};

const addFinanceDetails = async (
  employee_uid: string,
  addFinanceDetailsParams: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.ADD_FINANCE_DETAILS(employee_uid),
    addFinanceDetailsParams,
  );
  return response;
};

// For Edit

const getEmployeeById = async (employee_uid: string, employee_status: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_EMPLOYEE_BY_ID(employee_uid), {
    params: { status: employee_status },
  });
  return response;
};

const updateEmployeeById = async (
  employee_uid: string,
  updateEmployeeByIdParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.GET_EMPLOYEE_BY_ID(employee_uid),
    updateEmployeeByIdParams,
    {
      params: { uid: employee_uid },
    },
  );
  return response;
};

const updateEmployeeRelation = async (
  employee_uid: string,
  updateEmployeeRelationParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.EMPLOYEE_RELATION(employee_uid),
    updateEmployeeRelationParams,
  );
  return response;
};

const deleteEmployeeRelation = async (employee_uid: string, data: any): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.EMPLOYEE_RELATION(employee_uid), { data });
  return response;
};

const updateEmployeeContact = async (
  employee_uid: string,
  updateEmployeeContactParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.EMERGENCYCONTACT_DETAILS(employee_uid),
    updateEmployeeContactParams,
  );
  return response;
};

const deleteEmployeeContact = async (employee_uid: string, data: string[]): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.EMERGENCYCONTACT_DETAILS(employee_uid), {
    data,
  });
  return response;
};

const updateEmployeeFinance = async (
  employee_uid: string,
  updateEmployeeFinanceParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_EMPLOYEE_FINANCE(employee_uid),
    updateEmployeeFinanceParams,
  );
  return response;
};

const updateEmployeeSkills = async (
  employee_uid: string,
  updateEmployeeSkillParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_EMPLOYEE_SKILLS(employee_uid),
    updateEmployeeSkillParams,
  );
  return response;
};

const updateEmployeeDesignation = async (
  employee_uid: string,
  updateEmployeeDesignationParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_EMPLOYEE_DESIGNATION(employee_uid),
    updateEmployeeDesignationParams,
  );
  return response;
};

const updateEmployeeManager = async (
  employee_uid: string,
  emp_manager_uid: string,
  updateEmployeeManagerParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_EMPLOYEE_MANAGER(employee_uid, emp_manager_uid),
    updateEmployeeManagerParams,
  );
  return response;
};

const updateOtherDetails = async (updateOtherDetailsParams: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.EMPLOYEE_OTHER_DETAILS, updateOtherDetailsParams);
  return response;
};

const updateGroupNames = async (updateOtherDetailsParams: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_EMPLOYEE_GROUPS, updateOtherDetailsParams);
  return response;
};

const getEmployeeCertificates = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_CERTIFICATE);
  return response;
};

const getEmployeePersonalDocs = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_PERSONAL);
  return response;
};

const getEmployeeProfessionalDocs = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_PROFESSIONAL);
  return response;
};

const getEmployeeCertificatesById = async (employee_uid: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_CERTIFICATE_BY_ID(employee_uid));
  return response;
};

const getEmployeePersonalDocsById = async (employee_uid: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_PERSONAL_DOCUMENT_BY_ID(employee_uid));
  return response;
};

const getEmployeeProfessionalDocsById = async (employee_uid: string): Promise<any> => {
  const response = await axiosInstance.get(
    PATHS.EMPLOYEE_PROFESSIONAL_DOCUMENT_BY_ID(employee_uid),
  );
  return response;
};

const getPresignedURLById = async (candidate_uid: string): Promise<any> => {
  const response = await axiosInstance.post(PATHS.GET_CANDIDATE_PRESIGNED_URL_BY_ID(candidate_uid));
  return response;
};

const deleteEmployeeCertificates = async (document_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.DELETE_EMPLOYEE_CERTIFICATE(document_uid));
  return response;
};

const deleteEmployeePersonalDocs = async (document_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(
    PATHS.DELETE_EMPLOYEE_PERSONAL_DOCUMENT(document_uid),
  );
  return response;
};

const deleteEmployeeProfessionalDocs = async (document_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(
    PATHS.DELETE_EMPLOYEE_PROFESSIONAL_DOCUMENT(document_uid),
  );
  return response;
};

const deleteEmployeeCertificatesById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await axiosInstance.delete(
    PATHS.DELETE_EMPLOYEE_CERTIFICATE_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const deleteEmployeePersonalDocsById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await axiosInstance.delete(
    PATHS.DELETE_EMPLOYEE_PERSONAL_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const deleteEmployeeProfessionalDocsById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await axiosInstance.delete(
    PATHS.DELETE_EMPLOYEE_PROFESSIONAL_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const addEmployeeCertificates = async (
  employee_uid: string,
  employeeDocParams: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.EMPLOYEE_CERTIFICATE_BY_ID(employee_uid),
    employeeDocParams,
  );
  return response;
};

const addEmployeePersonalDocs = async (
  employee_uid: string,
  employeeDocParams: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.EMPLOYEE_PERSONAL_DOCUMENT_BY_ID(employee_uid),
    employeeDocParams,
  );
  return response;
};

const addEmployeeProfessionalDocs = async (
  employee_uid: string,
  employeeDocParams: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    PATHS.EMPLOYEE_PROFESSIONAL_DOCUMENT_BY_ID(employee_uid),
    employeeDocParams,
  );
  return response;
};

const deleteEmployee = async (employee_uid: string, deleteEmployeeParams: any): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.ADD_EMPLOYEE}${employee_uid}`, {
    data: deleteEmployeeParams,
  });
  return response;
};

const deleteNewJoinee = async (
  newjoinee_uid: string,
  deleteNewJoineePayload: any,
): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.DELETE_NEW_JOINEE(newjoinee_uid), {
    data: deleteNewJoineePayload,
  });
  return response;
};

const getInactiveEmployees = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.ADD_EMPLOYEE}`, {
    params: { status: EmployeeStatus.INACTIVE },
  });
  return response;
};

const OnboardingClient: any = Object.freeze({
  getEmployeeList,
  addEmployee,
  addRelations,
  addSkills,
  addDesignation,
  addManager,
  addEmergencyContacts,
  addFinanceDetails,
  getEmployeeById,
  updateEmployeeById,
  updateEmployeeRelation,
  deleteEmployeeRelation,
  updateEmployeeContact,
  deleteEmployeeContact,
  updateEmployeeFinance,
  updateEmployeeSkills,
  updateEmployeeDesignation,
  updateEmployeeManager,
  updateGroupNames,
  updateOtherDetails,
  getEmployeeCertificates,
  getEmployeePersonalDocs,
  getEmployeeProfessionalDocs,
  getEmployeeCertificatesById,
  getEmployeePersonalDocsById,
  getEmployeeProfessionalDocsById,
  getPresignedURLById,
  deleteEmployeeCertificates,
  deleteEmployeeCertificatesById,
  deleteEmployeePersonalDocs,
  deleteEmployeePersonalDocsById,
  deleteEmployeeProfessionalDocs,
  deleteEmployeeProfessionalDocsById,
  addEmployeeCertificates,
  addEmployeePersonalDocs,
  addEmployeeProfessionalDocs,
  deleteEmployee,
  getInactiveEmployees,
  getNewJoineeList,
  getNewJoineeById,
  deleteNewJoinee,
});

export default OnboardingClient;
