/* eslint-disable import/no-extraneous-dependencies */
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ErrorMessage, useField } from 'formik';
import dayjs from 'dayjs';

const CustomDateTimePicker: React.FC<any> = ({ name, value, defaultValue, ...fieldprops }: any) => {
  const [field, meta] = useField(name);
  const fieldProperties: any = {
    name,
    ...fieldprops,
  };

  if (meta && meta.touched && meta.error) {
    fieldProperties.error = true;
    fieldProperties.helperText = <ErrorMessage name={name} />;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          defaultValue={defaultValue}
          sx={{ width: '100%' }}
          value={value}
          {...fieldProperties}
          renderInput={(props: any) => <TextField {...props} {...field} />}
        />
      </LocalizationProvider>
    </>
  );
};

CustomDateTimePicker.defaultProps = {
  defaultValue: dayjs(),
};

export default CustomDateTimePicker;
