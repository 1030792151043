import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useInterviewDetailsStyles from './InterviewDetailsStyles';

interface SkillFeedbackprops {
  roundWiseData: any;
}

const SkillFeedback: React.FC<SkillFeedbackprops> = ({ roundWiseData }: any) => {
  const styles = useInterviewDetailsStyles();

  return (
    <>
      <Box>
        {roundWiseData?.interviewFeedback?.skills.map((element: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Accordion key={index} defaultExpanded className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <Typography className={styles.skillRating}>
                {element.skill} : {element.rating}/5 Rating
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={styles.skillFeedbackWrapperBox}>
                <Typography className={styles.title}>Feedback</Typography>
                <Typography className={styles.skillComment}>{element.comments}</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default SkillFeedback;
