import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { currencyValueFormatter } from '../../utils/utils';

const dynamicColumns = [
  { fieldName: 'forecastedRevenue', columnHeader: 'month', rowCell: 'revenue' },
];
const initialSort = { field: 'name', sort: 'asc' };

const tableRowTotalField = [
  {
    field: 'total',
    headerName: 'Revenue Total ($)',
    headerAlign: 'right',
    cellClassName: 'right-align-number-field',
    flex: 1,
    sortable: true,
    hideable: true,
    filterable: false,
    valueFormatter: (params: any) => currencyValueFormatter(params.value),
  },
];

const getColumns = ({ hideStage, rowActions }: any) => {
  return [
    {
      field: 'name',
      type: 'string',
      headerName: 'Opportunity',
      flex: 2,
      sortable: true,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={`/opportunity/${params.row.opportunityId}`}>{params.formattedValue}</Link>;
      },
    },
    {
      field: 'alias',
      type: 'string',
      headerName: 'Alias',
      flex: 1.5,
      sortable: true,
      hideable: false,
    },
    {
      field: 'customerName',
      type: 'string',
      headerName: 'Customer',
      flex: 2,
      sortable: true,
      hideable: false,
    },
    ...(hideStage
      ? []
      : [
          {
            field: 'stage',
            type: 'string',
            headerName: 'Stage',
            flex: 0.75,
            sortable: true,
            hideable: false,
            renderCell: (params: GridRenderCellParams) => params.value.toUpperCase(),
          },
        ]),
    {
      field: 'revenueQ1',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      type: 'string',
      headerName: 'Revenue Q1',
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
    },
    {
      field: 'revenueQ2',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      type: 'string',
      headerName: 'Revenue Q2',
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
    },
    {
      field: 'revenueQ3',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      type: 'string',
      headerName: 'Revenue Q3',
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
    },
    {
      field: 'revenueQ4',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      type: 'string',
      headerName: 'Revenue Q4',
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
    },
    ...tableRowTotalField,
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      flex: 0.5,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action: any) => {
          return (
            <Tooltip title={action.label}>
              <GridActionsCellItem
                key={action.label}
                icon={action.logo}
                label={action.label}
                onClick={() => action.callback(params)}
              />
            </Tooltip>
          );
        }),
    },
  ];
};

const opportunityIdName = 'opportunityId';

export { getColumns, dynamicColumns, tableRowTotalField, initialSort, opportunityIdName };
