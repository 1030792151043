/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import I18nKey from '../../translations/I18nKey';
import AddContactForm from '../Opportunity/AddContactForm';
import useAllContactsStyles from './AllContactsStyles';

interface ContactDetailProps {
  readonly selectedContact: any;
  readonly handleClose: () => void;
  readonly setIsRefetchContacts: any;
}

const ContactDetail: React.FC<ContactDetailProps> = ({
  selectedContact,
  handleClose,
  setIsRefetchContacts,
}) => {
  const styles = useAllContactsStyles();
  const intl = useIntl();

  return (
    <Box className={styles.detailsWrapper} key={selectedContact.id}>
      <Box className={`${styles.topContainer} ${styles.card}`}>
        <strong>{selectedContact.customerName}</strong>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={`${styles.bottomContainer} ${styles.card}`}>
        <Box className={styles.formWrapper}>
          <AddContactForm
            contactDetail={selectedContact}
            handleModalClose={handleClose}
            setIsRefetchContacts={setIsRefetchContacts}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactDetail;
