import axios from 'axios';
import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  POLICY: `/company/policy/`,
  GET_POLICY: `/company/policy/listing`,
  POLICY_TYPES: `/company/policy/types`,
  DELETE_POLICY: (policy_uid: string) => `/company/policy/${policy_uid}`,
});

const getAllPolicies = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.POLICY}`);
  return response;
};

const savePolicy = async (savePolicyParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.POLICY, savePolicyParams);
  return response;
};

const getAllPolicyTitles = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.POLICY_TYPES}`);
  return response;
};

const deletePolicy = async (policyUid: any): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.DELETE_POLICY(policyUid));
  return response;
};

const downloadPolicy = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

export interface PolicyAPIClient {
  readonly getAllPolicies: () => Promise<any>;
  readonly savePolicy: (savePolicyParams: any) => Promise<any>;
  readonly getAllPolicyTitles: () => Promise<any>;
  readonly deletePolicy: (policyUid: any) => Promise<any>;
  readonly downloadPolicy: (params: any) => Promise<any>;
}
const PolicyClient: PolicyAPIClient = Object.freeze({
  getAllPolicies,
  savePolicy,
  getAllPolicyTitles,
  deletePolicy,
  downloadPolicy,
});

export default PolicyClient;
