import { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import crm from '../../assets/icons/crm.svg';
import project from '../../assets/icons/project.svg';
import revenue from '../../assets/icons/revenue.svg';
import invoice from '../../assets/icons/invoice.svg';
import contract from '../../assets/icons/contract.svg';
import resource from '../../assets/icons/resource.svg';
import customer from '../../assets/icons/customer.svg';
import contact from '../../assets/icons/contact.svg';
import account from '../../assets/icons/account.svg';
import onBoarding from '../../assets/icons/onBoarding.svg';
import appraisal from '../../assets/icons/appraisal.svg';
import document from '../../assets/icons/document.svg';
import auditLog from '../../assets/icons/auditLog.svg';
import interview from '../../assets/icons/interview.svg';
import ticket from '../../assets/icons/ticket.png';
import claimDevice from '../../assets/icons/claimdevice.svg';
import { PATHS as ProjectPaths } from '../../api/Project/projectAPIs';
import { PATHS as RevenuePaths } from '../../api/Revenue/revenueAPIs';
import { PATHS as InvoicePaths } from '../../api/Invoice/invoiceAPIs';
import { PATHS as ContractPaths } from '../../api/Contract/contractAPIs';
import { PATHS as ResourcePaths } from '../../api/Resource/resourceAPIs';
import { PATHS as OnBoardingPaths } from '../../api/OnBoarding/onboardingAPIs';
import { PATHS as CustomerRelationPaths } from '../../api/Opportunity/opportunityAPIs';
import { PATHS as AppraisalPaths } from '../../api/Appraisal/appraisalAPIs';
import { PATHS as auditLogPaths } from '../../api/AuditLogs/AuditLogsAPIs';
import { PATHS as interviewPaths } from '../../api/Interview/interviewAPIs';
import { PATHS as ticketPaths } from '../../api/Ticket/TicketAPIs';
import { PATHS as linkedInPaths } from '../../api/LinkedInContacts/linkedInAPIs';
import { PATHS as vacanciesPaths } from '../../api/Vacancies/vacanciesAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import useWorkspaceStyles from './workspaceStyles';
import { PATHS as documentPaths } from '../../api/Document/DocumentAPI';
import Overview from './Overview';
import { hasSuperUserAccess } from '../../utils/utils';
import I18nKey from '../../translations/I18nKey';

const Workspace = () => {
  const intl = useIntl();
  const classes = useWorkspaceStyles();
  const { checkAccess, groupNames } = useContext(GlobalContext);

  const modules: any[] = [
    {
      icon: contact,
      label: intl.formatMessage({
        id: I18nKey.CONTACT_TITLE,
      }),
      link: '/contact',
      module: CustomerRelationPaths.CRM_CONTACT,
    },
    {
      icon: customer,
      label: intl.formatMessage({
        id: I18nKey.CUSTOMER_TITLE,
      }),
      link: '/customer',
      module: CustomerRelationPaths.CRM_CUSTOMER,
    },
    {
      icon: account,
      label: intl.formatMessage({
        id: I18nKey.ACCOUNT_TITLE,
      }),
      link: '/account',
      module: CustomerRelationPaths.CRM_ACCOUNT,
    },
    {
      icon: crm,
      label: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_TITLE,
      }),
      link: '/opportunity',
      module: CustomerRelationPaths.CRM_OPPORTUNITY,
    },
    {
      icon: onBoarding,
      label: intl.formatMessage({
        id: I18nKey.ONBOARDING_TITLE,
      }),
      module: OnBoardingPaths.GET_EMPLOYEE,
      link: '/onboarding',
    },
    {
      icon: project,
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_TITLE,
      }),
      module: ProjectPaths.GET_PROJECT,
      link: '/project',
    },
    {
      icon: revenue,
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_TITLE,
      }),
      module: RevenuePaths.GET_REVENUE_PROJECT,
      link: '/revenue/project',
    },
    {
      icon: invoice,
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_TITLE,
      }),
      module: InvoicePaths.GET_INVOICE,
      link: '/invoice',
    },
    {
      icon: contract,
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_DETAIL_TITLE,
      }),
      module: ContractPaths.GET_CONTRACT,
      link: '/contract',
    },
    {
      icon: resource,
      label: intl.formatMessage({
        id: I18nKey.RESOURCE_TITLE,
      }),
      module: ResourcePaths.GET_RESOURCES,
      link: '/resource',
    },
    {
      icon: appraisal,
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_TITLE,
      }),
      module: AppraisalPaths.GET_APPRAISAL,
      link: '/appraisal',
    },
    {
      icon: document,
      label: intl.formatMessage({
        id: I18nKey.GENERATE_DOCUMENT_TITLE,
      }),
      module: documentPaths.GET_DOCUMENT,
      link: '/document',
    },
    {
      icon: auditLog,
      label: intl.formatMessage({
        id: I18nKey.AUDITLOG_TITLE,
      }),
      module: auditLogPaths.GET_AUDITLOGS,
      link: '/auditLog',
    },
    {
      icon: interview,
      label: intl.formatMessage({
        id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
      }),
      module: interviewPaths.GET_CANDIDATES,
      link: '/interview',
    },
    {
      icon: ticket,
      label: intl.formatMessage({
        id: I18nKey.TICKET_MANAGEMENT_TITLE,
      }),
      module: ticketPaths.GET_TICKET,
      link: '/ticket',
    },
    {
      icon: contact,
      label: intl.formatMessage({
        id: I18nKey.LINKED_IN_TITLE,
      }),
      module: linkedInPaths.CONTACT,
      link: '/linkedInContacts',
    },
    {
      icon: claimDevice,
      label: intl.formatMessage({
        id: I18nKey.VACANCIES_TITLE,
      }),
      module: vacanciesPaths.VACANCIES,
      link: '/Vacancies',
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: '1rem' }}>
        <Typography className={classes.header}>
          {intl.formatMessage({ id: I18nKey.MODULE })}
        </Typography>
        <Grid container spacing={2}>
          {modules
            .filter(({ module }) => checkAccess(module))
            .map(({ icon, label, link }) => (
              <Grid key={link} item xs={6} sm={4} md={4} lg={2} className={classes.itemWrapper}>
                <Link to={link}>
                  <Box className={classes.routeItem}>
                    <Box component="img" src={icon} alt={label} className={classes.ModuleIcon} />
                    <Typography>{label}</Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
        </Grid>
        {hasSuperUserAccess(groupNames) && <Overview />}
      </Box>
    </>
  );
};

export default Workspace;
