import { Auth } from 'aws-amplify';
import _ from 'lodash';

export const checkUserLogin = () => {
  return Auth.currentAuthenticatedUser();
};

export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    signUpVerificationMethod: 'link',
    storage: localStorage,
    authenticationFlowType: 'ALLOW_REFRESH_TOKEN_AUTH',
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN_NAME,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN_URL,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT_URL,
      responseType: 'token',
    },
  },
};

export const setUserAttributes = (userName: string, attr: Record<string, any>, token: string) => {
  const userInfo = {
    userName,
    attributes: attr,
    token,
  };
  localStorage.setItem('UserInfo', JSON.stringify(userInfo));
};

export const clearUserAttributes = () => localStorage.removeItem('UserInfo');

export const getUserAttributes = () => {
  return JSON.parse(localStorage.getItem('UserInfo') || '{}');
};

export const setNewCandidateAttributes = (token: string) =>
  sessionStorage.setItem('candidateAccessToken', token);

export const getNewCandidateAttributes = () => sessionStorage.getItem('candidateAccessToken');

export const clearNewCandidateAttributes = () => sessionStorage.removeItem('candidateAccessToken');
