import { Avatar, Box, Grid, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useSearchCardStyles from './SearchProjectCardStyles';
import { getFirstLastName } from '../../utils/utils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';

interface SearchProjectCardProps {
  readonly searchBy: string;
  readonly empInProject: Array<{
    firstName: string;
    lastName: string;
    designationName: string;
    employeeUid: string;
  }>;
  readonly selectedEmpUid: any;
}

const SearchProjectCard: React.FC<SearchProjectCardProps> = ({
  searchBy,
  empInProject,
  selectedEmpUid,
}: SearchProjectCardProps) => {
  const styles = useSearchCardStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const handleEmployeeSelect = (empUid: string) => {
    navigate(`/search/${id}?searchBy=${searchBy}&selectedEmployee=${empUid}`);
  };
  return (
    <Grid container spacing={2}>
      {empInProject.map(({ firstName, lastName, designationName, employeeUid }) => {
        return (
          <Grid item xs={4} key={employeeUid}>
            <Box
              className={classNames(styles.cardWrapper, {
                [styles.highlighted]: selectedEmpUid === employeeUid,
              })}
              onClick={() => handleEmployeeSelect(employeeUid)}>
              <Avatar alt="name" />
              <Box className={styles.detailsWrapper}>
                <Typography fontWeight={600}>
                  {getFirstLastName({ firstName, lastName })}
                </Typography>
                <Typography className={styles.textDetails}>{designationName}</Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SearchProjectCard;
