import { Box, Checkbox, InputBase, InputBaseProps } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { useState } from 'react';
import useDatatableStyles from '../DatatableStyles';

interface RenderEditCellProps extends GridRenderEditCellParams<string> {
  type: string;
}

const RenderEditCell = (props: RenderEditCellProps) => {
  const { id, field, value, type } = props;
  const [valueState, setValueState] = useState<any>(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const apiRef = useGridApiContext();
  const DatatableStyles = useDatatableStyles();

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event: any) => {
      const newValue = type === 'number' ? event.target.value : event.target.checked;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
    },
    [apiRef, field, id, type],
  );

  const handleKeyDown = React.useCallback<NonNullable<InputBaseProps['onKeyDown']>>(
    (event) => {
      if (
        event.key === 'Escape' ||
        (event.key === 'Enter' && !event.shiftKey && (event.ctrlKey || event.metaKey))
      ) {
        const params = apiRef.current.getCellParams(id, field);
        apiRef.current.publishEvent('cellKeyDown', params, event);
      }
    },
    [apiRef, id, field],
  );

  return (
    <>
      <Box ref={handleRef} />
      {anchorEl && type === 'number' && (
        <Box className={DatatableStyles.customInputFieldWrapper}>
          <InputBase
            type="number"
            value={valueState}
            inputProps={{ min: 0 }}
            onChange={handleChange}
            autoFocus
            onKeyDown={handleKeyDown}
            className={DatatableStyles.customInputField}
          />
        </Box>
      )}
      {anchorEl && type === 'boolean' && (
        <Box className={DatatableStyles.customInputFieldWrapper}>
          <Checkbox onChange={handleChange} checked={valueState} />
        </Box>
      )}
    </>
  );
};

export default RenderEditCell;
