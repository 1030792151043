import { Box, Typography } from '@mui/material';
import useInterviewDetailsStyles from './InterviewDetailsStyles';

interface LogicalAbilityFeedbackprops {
  roundWiseData: any;
}

const LogicalAbilityFeedback: React.FC<LogicalAbilityFeedbackprops> = ({ roundWiseData }: any) => {
  const styles = useInterviewDetailsStyles();

  return (
    <>
      <Box className={styles.FeedbackWrapperBox}>
        <Typography className={styles.Rating}>
          {roundWiseData?.interviewFeedback?.rating}/5 Rating
        </Typography>
        <Typography className={styles.LogicalTitle}>Feedback</Typography>
        <Typography className={styles.Comment}>
          {roundWiseData?.interviewFeedback?.comments}
        </Typography>
      </Box>
    </>
  );
};

export default LogicalAbilityFeedback;
