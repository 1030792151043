import React from 'react';
import { Box, Chip, Divider, Grid } from '@mui/material';
import InterviewerFormDetails from './InterviewerFormDetails';
import useInterviewDetailsStyles from './InterviewDetailsStyles';
import SkillFeedback from './SkillFeedback';
import LogicalAbilityFeedback from './LogicalAbilityFeedback';
import ProjectFeedback from './ProjectFeedback';
import AttributeFeedback from './AttributeFeedback';

interface FeedbackDetailsProps {
  roundWiseData: any;
}
const FeedbackDetails: React.FC<FeedbackDetailsProps> = ({ roundWiseData }: any) => {
  const styles = useInterviewDetailsStyles();

  return (
    <>
      <Box className={styles.FeedbackDetailsWrapper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InterviewerFormDetails roundWiseData={roundWiseData} />
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Skills" className="divider-chip" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxWrapper}>
              <SkillFeedback roundWiseData={roundWiseData} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Logical Ability" className="divider-chip" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxWrapper}>
              <LogicalAbilityFeedback roundWiseData={roundWiseData} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Feedback" className="divider-chip" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxWrapper}>
              <ProjectFeedback roundWiseData={roundWiseData} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Chip label="Attributes" className="divider-chip" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.boxWrapper}>
              <AttributeFeedback roundWiseData={roundWiseData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FeedbackDetails;
