import { Popover } from '@mui/material';

interface MisPopoverProps {
  id?: string;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  anchorOrigin?: any;
  transformOrigin?: any;
  children: {};
}

const MisPopover: React.FC<MisPopoverProps> = ({
  id,
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  children,
}: any) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}>
      {children}
    </Popover>
  );
};

MisPopover.defaultProps = {
  id: 'popover',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
};

export default MisPopover;
