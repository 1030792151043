import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@mui/material';
import MisTabs from '../../components/MisTabs/MisTabs';
import InterviewerList from './InterviewerList';
import useAllInterviewStyles from './AllInterviewStyles';
import InterviewersTopSectionInfo from './InterviewersTopSectionInfo';
import AssignedIntervieweesList from './AssignedIntervieweesList';

interface AllTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

interface AllInterviewersProps {
  readonly selectedList: any;
  readonly selectedTab: any;
  readonly setSelectedTab: any;
  readonly fetchInterviewerList: any;
}

const AllInterviewers: React.FC<AllInterviewersProps> = ({
  selectedList,
  selectedTab,
  setSelectedTab,
  fetchInterviewerList,
}: any) => {
  const [selectedInterviewer, setSelectedInterviewer] = useState<any>({ name: '', id: '' });

  const { id } = useParams();
  const navigate = useNavigate();
  const styles = useAllInterviewStyles();

  const InterviewerTabs: AllTabs[] = [
    {
      label: 'Not Assigned',
      component: (
        <InterviewerList
          roundName="NOT_ASSIGNED"
          selectedList={selectedList}
          selectedTab={selectedTab}
          fetchInterviewerList={fetchInterviewerList}
        />
      ),
    },
    {
      label: 'L1',
      component: (
        <InterviewerList
          roundName="L1"
          selectedList={selectedList}
          selectedTab={selectedTab}
          fetchInterviewerList={fetchInterviewerList}
        />
      ),
    },
    {
      label: 'L2',
      component: (
        <InterviewerList
          roundName="L2"
          selectedList={selectedList}
          selectedTab={selectedTab}
          fetchInterviewerList={fetchInterviewerList}
        />
      ),
    },
    {
      label: 'Final',
      component: (
        <InterviewerList
          roundName="FINAL"
          selectedList={selectedList}
          selectedTab={selectedTab}
          fetchInterviewerList={fetchInterviewerList}
        />
      ),
    },
    {
      label: 'HR',
      component: (
        <InterviewerList
          roundName="HR"
          selectedList={selectedList}
          selectedTab={selectedTab}
          fetchInterviewerList={fetchInterviewerList}
        />
      ),
    },
  ];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    setSelectedInterviewer('');
    navigate(`/interview?selected_list=${selectedList}&selected_tab=${index}`);
  };

  useEffect(() => {
    setSelectedInterviewer({ ...selectedInterviewer, id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Box
        className={
          selectedInterviewer.id ? `${styles.allTabsWrapper} ${styles.w75}` : styles.allTabsWrapper
        }>
        <MisTabs tabs={InterviewerTabs} onTabChange={handleTabChange} selected={selectedTab} />
      </Box>
      <>
        {selectedInterviewer.id && (
          <Box className={styles.detailsWrapper} key={selectedInterviewer}>
            <InterviewersTopSectionInfo
              selectedInterviewer={selectedInterviewer}
              setSelectedInterviewer={setSelectedInterviewer}
              selectedTab={selectedTab}
              selectedList={selectedList}
            />
            <Box className={`${styles.bottomContainer} ${styles.card}`}>
              <Box className={styles.interviewFormWrapper}>
                <Typography className={`${styles.TitleText} ${styles.bold}`}>
                  Assigned Interviewees
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AssignedIntervieweesList />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </>
  );
};

export default AllInterviewers;
