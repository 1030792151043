import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllAppraisalsStyles = makeStyles((theme: Theme) => {
  return {
    appraisalWrapper: {
      padding: '1rem',
    },
    kraDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      marginBottom: '20px',
    },
    Header: {
      position: 'relative',
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        position: 'absolute',
        right: '190px',
      },
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',
      borderRadius: '8px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    templateBtn: {
      float: 'right',
      margin: '0px 10px !important',
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        fontSize: '35px',
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
  };
});
export default useAllAppraisalsStyles;
