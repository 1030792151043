import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';

export const ResourceDetailInfo: React.FC<any> = ({
  resourceDetailsInfo,
  resourceDetailsFormRef,
  setIsDetailsFormValid,
}: any) => {
  const validationSchema = Yup.object().shape({
    allocationPercentage: Yup.number()
      .required('Allocation percentage is required')
      .min(1, 'Allocation percentage should be greater than 0'),
    billingRate: Yup.number()
      .required('Billing rate is required')
      .min(1, 'Billing rate should be greater than 0'),
    startDate: Yup.date().required('Start date is required'),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End date can't be before Start date")
      .required('End date is required'),
  });
  const checkErrors = (errors: any): any => {
    if (Object.entries(errors).length !== 0) {
      setIsDetailsFormValid(false);
    }
  };
  const handleChange = (values: any): any => {
    setIsDetailsFormValid(
      values.allocationPercentage && values.billingRate && values.startDate && values.endDate,
    );
  };
  return (
    <>
      <Formik
        initialValues={resourceDetailsInfo}
        onSubmit={() => {}}
        innerRef={resourceDetailsFormRef}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, errors } = formik;
          return (
            <Form onChange={handleChange(values)} onBlur={checkErrors(errors)}>
              <Grid container spacing={1.5}>
                <Grid item xs={6}>
                  <SimpleInputField
                    inputProps={{ style: { fontSize: 13 } }}
                    name="allocationPercentage"
                    type="number"
                    label="Allocation %"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    inputProps={{ style: { fontSize: 13 } }}
                    name="billingRate"
                    type="number"
                    size="small"
                    label="Billing Rate"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    inputProps={{ style: { fontSize: 13 } }}
                    name="startDate"
                    type="date"
                    label="Start Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    inputProps={{ style: { fontSize: 13 } }}
                    name="endDate"
                    type="date"
                    label="End Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ResourceDetailInfo;
