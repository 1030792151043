/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const dynamicColumns: any[] = [];
const tableRowTotalField: any = [];
const initialSort = { field: 'employeeName', sort: 'desc' };

const getColumns = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setExpanded] = useState(true);
  const handleExpanded = (rowId: any) => {
    setExpanded(!isExpanded);
  };
  return [
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Name',
      flex: 1.25,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/onboarding/addEmployee/${params.row.uid}/newJoinee`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    {
      field: 'personalEmail',
      type: 'string',
      headerName: 'Email ID',
      flex: 2,
      sortable: true,
    },
    {
      field: 'currentCity',
      type: 'string',
      headerName: 'Current City',
      flex: 2,
      sortable: true,
    },
    {
      field: 'linkedinUrl',
      type: 'string',
      headerName: 'Linkedin',
      flex: 2,
      sortable: true,
    },
  ];
};
const uid = 'uid';

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const colRange = getColumns();
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const initialShowCols = ['employeeName', 'personalEmail', 'currentCity', 'linkedinUrl'];

export {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  uid,
  getInitialVisibilityModel,
  initialShowCols,
};
