import { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import useHomeStyles from './HomeStyles';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import EmployeeClient from '../../api/Employee/employeeAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import QuickLinks from './QuickLinks';
import Search from './Search';
import ToDoList from '../Profile/components/ToDoList';
import Timesheet from '../../components/TimeSheet/TimeSheet';
import TimeSheetClient from '../../api/TimeSheet/timeSheet';
import { getInitialTimesheetState } from '../../utils/utils';
import HomeBanner from './HomeBanner';

const Home = () => {
  const styles = useHomeStyles();
  const { employeeUid } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();
  const [toDoList, setToDoList] = useState<any>([]);
  const [weekStart, setWeekStart] = useState(dayjs().startOf('week').add(1, 'day'));
  const [currentWeekStatus, setCurrentWeekStatus] = useState<any>(
    getInitialTimesheetState(weekStart),
  );
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getTimeSheetData = () => {
    setIsDataLoading(true);
    const getTimeSheetParams = {
      start_date: weekStart.format('YYYY-MM-DD'),
      end_date: weekStart.add(4, 'day').format('YYYY-MM-DD'),
      employee_uid: employeeUid,
    };
    TimeSheetClient.getTimeSheetInfo(getTimeSheetParams)
      .then((response: any) => {
        if (Object.keys(response.data).length !== 0) {
          const { data } = response;
          const weekData = {
            uid: data.uid || null,
            details: data.details,
          };
          setCurrentWeekStatus(weekData);
        } else {
          setCurrentWeekStatus(getInitialTimesheetState(weekStart));
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsDataLoading(false));
  };
  useEffect(() => {
    setCurrentWeekStatus(getInitialTimesheetState(weekStart));
    getTimeSheetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStart]);

  const getEmployeeTodos = () => {
    if (employeeUid)
      EmployeeClient.getEmployeeTodos()
        .then((result) => {
          const { data } = result;
          const todoList = data.map((item: any) => {
            return {
              ...item,
              task: item.todo,
              completed: item.status === 'completed',
            };
          });
          setToDoList(todoList);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        });
  };

  useEffect(() => {
    getEmployeeTodos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={styles.homeWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HomeBanner />
              </Grid>
              <Grid item xs={12}>
                <Search />
              </Grid>
              <Grid item xs={7}>
                <Box className={styles.MidSectionContainer}>
                  <QuickLinks />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box className={styles.todoListWrapper}>
                  <ToDoList
                    toDoList={toDoList}
                    setToDoList={setToDoList}
                    getToDoList={getEmployeeTodos}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Timesheet
              currentWeekStatus={currentWeekStatus}
              weekStart={weekStart}
              getTimeSheetData={getTimeSheetData}
              setWeekStart={setWeekStart}
              isDataLoading={isDataLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
