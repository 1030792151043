/* eslint-disable react/prop-types */
import { Edit } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import {
  OpportunityCustomer,
  Opportunity,
  GetCustomerParams,
  GetOpportunityParams,
} from '../../api/Opportunity/opportunityTypes';
import { getFullName } from '../../pages/Opportunity/entitiesFormConfig';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getFullAddress } from '../../utils/utils';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import useCardStyles from './OrganizationSideCardStyles';

interface OrganizationSideCardProps {
  readonly projectId: string;
  readonly onDataLoad?: Function;
  readonly toggle?: Function;
  readonly usedBy?: 'opportunity' | 'others';
}

const OrganizationSideCard: React.FC<OrganizationSideCardProps> = ({
  projectId,
  onDataLoad,
  toggle,
  usedBy,
}) => {
  const [collapsed, setCollapsed] = useState<boolean[]>([false, false, false]);
  const [customerId, setCustomerId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState<any>({
    projectImgUrl: '',
    customerName: '',
    accountName: '',
    projectName: '',
    stage: '',
    opportunityPoc: [],
    customerInfo: {
      name: '',
      addressFirstLine: '',
      addressSecondLine: '',
      city: '',
      country: '',
      state: '',
      pincode: '',
    },
    customerPoc: [],
  });

  const styles = useCardStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();

  const handleGetOpprtunitySuccess = (opportunityResult: Opportunity) => {
    if (onDataLoad) onDataLoad(opportunityResult);
    setCustomerId(opportunityResult.customerId);
    setProjectInfo(
      Object.assign(projectInfo, {
        customerName: opportunityResult?.customerName,
        accountName: opportunityResult?.accountName,
        projectName: opportunityResult?.name,
        stage: opportunityResult?.stage,
        opportunityPoc: [...opportunityResult?.poc],
      }),
    );
  };

  useEffect(() => {
    const getOpportunityParams: GetOpportunityParams = {
      project_id: projectId,
    };
    setIsLoading(true);

    if (usedBy === 'others')
      CustomerRelationClient.getOpportunityListing(getOpportunityParams)
        .then((opportunityResult: Opportunity) => handleGetOpprtunitySuccess(opportunityResult))
        .catch((e) => {
          showSnackbar(e, 'error');
          setIsLoading(false);
        });
    else if (usedBy === 'opportunity')
      CustomerRelationClient.getOpportunity(getOpportunityParams)
        .then((opportunityResult: Opportunity) => handleGetOpprtunitySuccess(opportunityResult))
        .catch((e) => {
          showSnackbar(e, 'error');
          setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerId) {
      const getCustomerParams: GetCustomerParams = {
        customer_id: customerId || '',
      };

      if (usedBy === 'others')
        CustomerRelationClient.getCustomerListing(getCustomerParams)
          .then((customerResult: OpportunityCustomer) => {
            setProjectInfo(
              Object.assign(projectInfo, {
                customerInfo: customerResult,
                customerPoc: [...customerResult?.poc],
              }),
            );
          })
          .catch((e) => showSnackbar(e, 'error'))
          .finally(() => setIsLoading(false));
      else if (usedBy === 'opportunity')
        CustomerRelationClient.getCustomer(getCustomerParams)
          .then((customerResult: OpportunityCustomer) => {
            setProjectInfo(
              Object.assign(projectInfo, {
                customerInfo: customerResult,
                customerPoc: [...customerResult?.poc],
              }),
            );
          })
          .catch((e) => showSnackbar(e, 'error'))
          .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleCollapse = (index: number) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  return (
    <>
      <Box className={styles.CardWrapper}>
        {toggle && (
          <IconButton className="editBtn" onClick={() => toggle?.()} color="success">
            <Edit />
          </IconButton>
        )}
        <Box className={styles.CardImageWrapper}>
          <Avatar alt="project image" src={projectInfo.projectImgUrl} />
        </Box>
        <Box className={styles.CardTitleWrapper}>
          <p className={`${styles.TitleText} ${styles.bold}`}>{projectInfo.projectName} </p>
          <p className={styles.TitleText}>{projectInfo.accountName}</p>
          <p className={styles.TitleText}>{projectInfo.customerName}</p>
          <p className={styles.TitleText}>{`${intl.formatMessage({
            id: I18nKey.CONTRACT_DETAIL_STAGE,
          })} : ${projectInfo.stage?.toUpperCase()}`}</p>
        </Box>
        <Box className={styles.CardFooterWrapper}>
          <List>
            <ListItemButton onClick={() => handleCollapse(0)}>
              <img
                className={collapsed[0] ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`}
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText
                primary={intl.formatMessage({
                  id: I18nKey.CONTRACT_DETAIL_OPP_POC,
                })}
              />
            </ListItemButton>
            <Collapse in={collapsed[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={styles.pocInfoWrapper}>
                {projectInfo.opportunityPoc.map((item: Record<string, any>, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={`POCx${index}`}>
                    <Box className={styles.pocInfoWrapper}>
                      <Box className={styles.pocInfo}>
                        <Box className={styles.labelText}>{`${intl.formatMessage({
                          id: I18nKey.CONTRACT_DETAIL_POC,
                        })} ${index + 1} : `}</Box>
                        <Box className={styles.text}>{getFullName(item)}</Box>
                      </Box>
                      <Box className={styles.pocInfo}>
                        <Box className={styles.labelText}>{`${intl.formatMessage({
                          id: I18nKey.CONTRACT_DETAIL_EMAIL,
                        })} : `}</Box>
                        <Box className={styles.email}>{item.email}</Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={() => handleCollapse(1)}>
              <img
                className={collapsed[1] ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`}
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText
                primary={intl.formatMessage({
                  id: I18nKey.CONTRACT_DETAIL_CUSTOMER_INFO,
                })}
              />
            </ListItemButton>
            <Collapse in={collapsed[1]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <Box className={styles.pocInfoWrapper}>
                    <Box className={styles.pocInfo}>
                      <Box className={styles.labelText}>{`${intl.formatMessage({
                        id: I18nKey.CONTRACT_DETAIL_NAME,
                      })} : `}</Box>
                      <Box className={styles.text}>{projectInfo?.customerInfo?.name}</Box>
                    </Box>
                    <Box className={styles.pocInfo}>
                      <Box className={styles.labelText}>{`${intl.formatMessage({
                        id: I18nKey.CONTRACT_DETAIL_ADDRESS,
                      })} : `}</Box>
                      <Box className={styles.text}>{getFullAddress(projectInfo?.customerInfo)}</Box>
                    </Box>
                  </Box>
                </ListItem>
              </List>
            </Collapse>
            <ListItemButton onClick={() => handleCollapse(2)}>
              <img
                className={collapsed[2] ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`}
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText
                primary={intl.formatMessage({
                  id: I18nKey.CONTRACT_DETAIL_CUSTOMER_POC,
                })}
              />
            </ListItemButton>
            <Collapse in={collapsed[2]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={styles.pocInfoWrapper}>
                {projectInfo.customerPoc.map((item: Record<string, any>, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={`POC${index}`}>
                    <Box className={styles.pocInfoWrapper}>
                      <Box className={styles.pocInfo}>
                        <Box className={styles.labelText}>{`${intl.formatMessage({
                          id: I18nKey.CONTRACT_DETAIL_POC,
                        })} ${index + 1} : `}</Box>
                        <Box className={styles.text}>{getFullName(item)}</Box>
                      </Box>
                      <Box className={styles.pocInfo}>
                        <Box className={styles.labelText}>{`${intl.formatMessage({
                          id: I18nKey.CONTRACT_DETAIL_EMAIL,
                        })} : `}</Box>
                        <Box className={styles.email}>{item.email}</Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
    </>
  );
};

OrganizationSideCard.defaultProps = {
  usedBy: 'opportunity',
};

export default OrganizationSideCard;
