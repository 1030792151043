import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MuiTelInput } from 'mui-tel-input';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import useAllInterviewStyles from '../AllInterviewStyles';
import { currentStateOptions, hasResignedList, noticePeriodList } from '../utils';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';

interface AddCandidateFormProps {
  readonly handleSubmit: any;
  readonly formState: any;
  readonly formRef: any;
  readonly isEdit?: boolean;
}
const AddCandidateForm: React.FC<AddCandidateFormProps> = ({
  handleSubmit,
  formState,
  formRef,
  isEdit,
}: any) => {
  const styles = useAllInterviewStyles();
  const handleChange: any = (v: any) => {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required(),
    dateOfJoining: Yup.date().nullable(),
    yearsOfExperience: Yup.number().nullable().min(0, 'Experience cannot be negative'),
    currentCtc: Yup.string().nullable(),
    expectedCtc: Yup.string().nullable(),
    offeredCtc: Yup.string().nullable(),
    skills: Yup.string().required(),
    previousEmployer: Yup.string().nullable(),
    referedBy: Yup.string().nullable(),
    phoneNumber: Yup.string().required('Phone number is required'),
    addressFirstLine: Yup.string().nullable().min(5, 'Min 5 Characters expected'),
    designation: Yup.string().nullable(),
    remarks: Yup.string().nullable(),
    noticePeriod: Yup.object().shape({ label: Yup.string(), code: Yup.string() }).nullable(),
    linkedinUrl: Yup.string().url('Invalid URL').nullable(),
    relevantExperienceInYrs: Yup.number().nullable().min(0, 'Experience cannot be negative'),
    hasResigned: Yup.object().shape({ label: Yup.string(), code: Yup.string() }).nullable(),
    lwd: Yup.date().nullable(),
    class10thPassingYr: Yup.number().nullable(),
    class10thPassingPercentage: Yup.number().nullable().min(0, 'Percentage cannot be negative'),
    class12thPassingYr: Yup.number().nullable(),
    class12thPassingPercentag: Yup.number().nullable().min(0, 'Percentage cannot be negative'),
    diplomaPassingYr: Yup.number().nullable(),
    diplomaPassingPercentage: Yup.number().nullable().min(0, 'Percentage cannot be negative'),
    graduationPassingYr: Yup.number().nullable(),
    graduationPassingPercentage: Yup.number().nullable().min(0, 'Percentage cannot be negative'),
    postGraduationPassingYr: Yup.number().nullable(),
    postGraduationPassingPercentage: Yup.number()
      .nullable()
      .min(0, 'Percentage cannot be negative'),
  });

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, isValid, dirty, setFieldValue } = formik;
          return (
            <>
              <Form>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Name"
                      name="name"
                      type="string"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Email"
                      name="email"
                      type="string"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  {isEdit && (
                    <Grid item xs={12}>
                      <SimpleAutocompleteField
                        name="currentState"
                        key="currentState"
                        placeholder="Select Current State"
                        label="Select Current State"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={currentStateOptions}
                        values={values}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <MuiTelInput
                      fullWidth
                      defaultCountry="IN"
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Phone number"
                      size="small"
                      value={values.phoneNumber}
                      onChange={(newValue, info) => {
                        setFieldValue('phoneNumber', info.numberValue);
                      }}
                      forceCallingCode
                      splitCallingCode
                      focusOnSelectCountry
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="skills"
                      label="Skills"
                      type="string"
                      size="small"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Location"
                      name="addressFirstLine"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Total Experience"
                      name="yearsOfExperience"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Relevant experience"
                      name="relevantExperienceInYrs"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="linkedinUrl"
                      type="string"
                      label="LinkedIn URL"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleAutocompleteField
                      name="hasResigned"
                      key="hasResigned"
                      placeholder="Resigned yet ?"
                      label="Resigned yet ?"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={hasResignedList}
                      values={values}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Last working Date"
                      name="lwd"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="DOJ"
                      name="dateOfJoining"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleAutocompleteField
                      name="noticePeriod"
                      key="noticePeriod"
                      placeholder="Select Notice Period"
                      label="Select Notice Period"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={noticePeriodList}
                      values={values}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Previous Designation"
                      name="designation"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="previousEmployer"
                      label="Previous Employer"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SimpleInputField
                      name="currentCtc"
                      label="Current CTC"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="offeredCtc"
                      label="Offered CTC"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="expectedCtc"
                      label="Expected CTC"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="10th Passing Year"
                      name="class10thPassingYr"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="10th Passing Percentage"
                      name="class10thPassingPercentage"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="12th Passing Year"
                      name="class12thPassingYr"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="12th Passing Percentage"
                      name="class12thPassingPercentage"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Diploma Passing Year"
                      name="diplomaPassingYr"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Diploma Passing Percentage"
                      name="diplomaPassingPercentage"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Graduation Passing Year"
                      name="graduationPassingYr"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Graduation Passing Percentage"
                      name="graduationPassingPercentage"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Post Graduation Passing Year"
                      name="postGraduationPassingYr"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Post Graduation Passing Percentage"
                      name="postGraduationPassingPercentage"
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="referedBy"
                      label="Refered By"
                      type="string"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Remarks"
                      name="remarks"
                      type="string"
                      size="small"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                  Save
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddCandidateForm;
