import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllTimesheetStyles = makeStyles((theme: Theme) => {
  return {
    AllTimesheetWrapper: {
      padding: '1rem',
      //   backgroundColor: theme.palette.appBackground?.lightest,
      //   height: 'calc(100vh - 64px - 25px)',
    },
    searchOptions: {
      display: 'flex',
      padding: '0px 15px',
      alignItems: 'center',
    },
    searchWrapper: {
      margin: '20px 15%',
      width: '100%',
      marginBottom: '2px',
    },
    header: {
      position: 'relative',
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',
      borderRadius: '8px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    detailsWrapper: {
      'flex-direction': 'column',
      width: '30%',
      minWidth: '450px',
      margin: '0 1rem',
      borderRadius: '12px',
      height: 'fit-content',
      padding: '10px',
      backgroundColor: theme.palette.appBackground?.lightest,
    },
    contentWrapper: {
      display: 'flex',
    },
    topContainer: {
      position: 'relative',
      color: theme.palette.secondary.main,
      paddingBottom: '10px',

      '& strong': {
        fontSize: '18px',
        fontWeight: 'bold',
      },
      '& button': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 10,
      },
    },
    w65: {
      width: '65%',
    },
  };
});

export default useAllTimesheetStyles;
