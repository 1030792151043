import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../translations/I18nKey';
import useProfileStyles from '../ProfileStyles';

const TeamHierarchyFloatingCard = ({
  nodeDatum,
  onCollapseToggle,
}: {
  nodeDatum: any;
  onCollapseToggle: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const intl = useIntl();
  const styles = useProfileStyles();

  return (
    <Box className={styles.cardFloatingBox} onClick={onCollapseToggle} role="button" tabIndex={-1}>
      <span className={styles.cardFloatingBox_text}>{nodeDatum.directReporteesCount || 0}</span>{' '}
      {intl.formatMessage({
        id: I18nKey.ORG_TREE_DIRECT,
      })}{' '}
      <img
        className={
          nodeDatum.__rd3t.collapsed
            ? `${styles.cardFloatingBox_arrow}`
            : `${styles.cardFloatingBox_arrow} ${styles.cardFloatingBox_arrow_inverted}`
        }
        src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
        alt="arrow"
      />
    </Box>
  );
};

export default TeamHierarchyFloatingCard;
