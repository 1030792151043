import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import useAllTimesheetStyles from './AllTimesheetStyles';
import I18nKey from '../../translations/I18nKey';
import TimesheetList from './TimesheetList';
import TimeSheet from '../../components/TimeSheet/TimeSheet';
import TimeSheetClient from '../../api/TimeSheet/timeSheet';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getInitialTimesheetState } from '../../utils/utils';

const AllTimesheet: React.FC = () => {
  const [selectedEmployee, setSelectedEmployee] = useState<any>({});
  const [timesheetList, setTimesheetList] = useState<any>([]);
  const [weekStart, setWeekStart] = useState(dayjs().startOf('week').add(1, 'day'));
  const [currentWeekStatus, setCurrentWeekStatus] = useState(getInitialTimesheetState(weekStart));
  const [isDataLoading, setIsDataLoading] = useState(false);

  const styles = useAllTimesheetStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const getTimeSheetData = () => {
    setIsDataLoading(true);
    const getTimeSheetParams = {
      start_date: weekStart.format('YYYY-MM-DD'),
      end_date: weekStart.add(4, 'day').format('YYYY-MM-DD'),
      employee_uid: selectedEmployee.id,
    };
    TimeSheetClient.getTimeSheetInfo(getTimeSheetParams)
      .then((response: any) => {
        if (Object.keys(response.data).length !== 0) {
          const { data } = response;
          const weekData = {
            uid: data.uid || null,
            details: data.details,
          };
          setCurrentWeekStatus(weekData);
        } else {
          setCurrentWeekStatus(getInitialTimesheetState(weekStart));
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsDataLoading(false));
  };
  useEffect(() => {
    if (selectedEmployee?.id) {
      setCurrentWeekStatus(getInitialTimesheetState(weekStart));
      getTimeSheetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStart, selectedEmployee]);

  const handleClose = () => {
    setSelectedEmployee({});
    navigate('/timesheet');
  };

  useEffect(() => {
    if (id && timesheetList.length) {
      const { employeeName } = timesheetList.find((emp: any) => emp.employeeUid === id);
      setSelectedEmployee({
        id,
        name: employeeName || '',
      });
    }
  }, [id, timesheetList]);

  return (
    <Box className={styles.AllTimesheetWrapper}>
      <Box className={styles.header}>
        <span className="headerTitle">
          {intl.formatMessage({
            id: I18nKey.TIMESHEET_TITLE,
          })}
        </span>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box
          className={
            selectedEmployee.id ? `${styles.ListWrapper} ${styles.w65}` : styles.ListWrapper
          }>
          <TimesheetList setTimesheetList={setTimesheetList} />
        </Box>
        {selectedEmployee.id && (
          <Box className={styles.detailsWrapper} key={selectedEmployee.id}>
            <Box className={styles.topContainer}>
              <strong>{selectedEmployee.name}</strong>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TimeSheet
              currentWeekStatus={currentWeekStatus}
              weekStart={weekStart}
              getTimeSheetData={getTimeSheetData}
              showSubmitBtn={false}
              setWeekStart={setWeekStart}
              isDataLoading={isDataLoading}
              disableFields
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AllTimesheet;
