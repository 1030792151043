import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useFYSelectorStyles = makeStyles((theme: Theme) => {
  return {
    autoComplete: {
      width: 120,
      backgroundColor: theme.palette.primary.main,
    },
  };
});

export default useFYSelectorStyles;
