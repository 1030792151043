import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { sortAlphaNumeric } from '../../utils/utils';

const getColumns: any = (startDate?: string, endDate?: string) => {
  return [
    {
      field: 'employeeId',
      type: 'number',
      headerName: 'Id',
      headerAlign: 'left',
      width: 62,
      sortable: true,
      cellClassName: 'left-align-number-field',
      // renderCell: (params: GridRenderCellParams) => {
      //   return (
      //     <Link to={`/timesheet/${params.row.employeeUid}`}>
      //       {params.formattedValue}
      //     </Link>
      //   );
      // },
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Name',
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={`/timesheet/${params.row.employeeUid}`}>{params.formattedValue}</Link>;
      },
    },
    {
      field: 'designationName',
      type: 'string',
      headerName: 'Designation',
      flex: 1,
      sortable: true,
    },
    {
      field: 'departmentName',
      type: 'string',
      headerName: 'Department',
      flex: 1,
      sortable: true,
    },
  ];
};

const employeeId = 'employeeUid';

export { getColumns, employeeId };
