import { useContext, useState } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useProfileStyles from '../ProfileStyles';

const CardAvatar = ({ nodeDatum }: { nodeDatum: any }) => {
  const [showFallback, setShowFallback] = useState(!nodeDatum.profilePicture);
  const styles = useProfileStyles();
  const { employeeUid } = useContext(GlobalContext);

  const onImageError = () => setShowFallback(true);

  return showFallback ? (
    <div
      className={`${styles.upperCardBody_img_placeholder} `.concat(
        nodeDatum.employeeUid === employeeUid
          ? `${styles.upperCardBody_img_placeholder_current} `
          : ``,
      )}>
      {nodeDatum.firstName[0]?.toUpperCase() || ''}
      {nodeDatum.lastName[0]?.toUpperCase() || ''}
    </div>
  ) : (
    <img
      src={nodeDatum.profilePicture || ''}
      className={styles.upperCardBody_image}
      alt="Profile"
      onError={onImageError}
    />
  );
};

export default CardAvatar;
