/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { OtherDetail, ProjectResource } from '../../api/Invoice/invoiceTypes';
import I18nKey from '../../translations/I18nKey';
// eslint-disable-next-line import/no-cycle
import OtherDetailsForm from './OtherDetailsForm';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import { InvoiceStatus } from './invoiceTableConfig';

interface OtherDetailsProps {
  otherDetails: OtherDetail[];
  currency: string;
  invoiceResourceList: ProjectResource[];
  updateFetch: () => void;
  readonly selectedMonth: any;
  readonly invoiceStatus: InvoiceStatus;
}

const OtherDetails: React.FC<OtherDetailsProps> = ({
  otherDetails,
  currency,
  invoiceResourceList,
  updateFetch,
  selectedMonth,
  invoiceStatus,
}) => {
  // This state is used to refetch the invoice detail data.

  const styles = useInvoiceDetailStyles();
  const intl = useIntl();

  return (
    <Box className={styles.OtherDetailsWrapper}>
      <Box className={styles.OtherDetailsHeader}>
        <Typography className={styles.CardHeader}>
          {intl.formatMessage({
            id: I18nKey.INVOICE_DETAIL_OTHER_DETAIL,
          })}
        </Typography>
      </Box>

      <OtherDetailsForm
        details={otherDetails}
        currency={currency}
        showSubmitBtn={otherDetails.length > 0}
        invoiceResourceList={invoiceResourceList}
        updateFetch={updateFetch}
        selectedMonth={selectedMonth}
        invoiceStatus={invoiceStatus}
      />
    </Box>
  );
};

export default OtherDetails;
