import React from 'react';
import dayjs from 'dayjs';
import theme from '../theme';

export const getYearQuarter = (month: number) => {
  switch (month) {
    case 3:
    case 4:
    case 5: {
      return 1;
    }
    case 6:
    case 7:
    case 8: {
      return 2;
    }
    case 9:
    case 10:
    case 11: {
      return 3;
    }
    case 0:
    case 1:
    case 2: {
      return 4;
    }
    default:
      return null;
  }
};

export const months = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const getFYDateRange = (date: any) => {
  /* Returns start date of currrent Financial Year Eg for year 2022-23: 2022-04-01 */
  const startDate =
    date.month() < 3
      ? date.subtract(1, 'year').month(3).startOf('month').format('YYYY-MM-DD')
      : date.month(3).startOf('month').format('YYYY-MM-DD');

  /* Returns end date of currrent Financial Year Eg for year 2022-23: 2023-03-01 */
  const endDate = dayjs(startDate)
    .subtract(1, 'month')
    .add(1, 'year')
    .endOf('month')
    .format('YYYY-MM-DD');

  return { startDate, endDate };
};

export const getCurrentMonthRange = (date: any) => {
  const startDate = date.subtract(2, 'month').startOf('month').format('YYYY-MM-DD');
  const endDate = date.add(1, 'month').endOf('month').format('YYYY-MM-DD');
  return { startDate, endDate };
};

export const handleIntegerType = (event: { preventDefault?: any; key?: any }) => {
  const { key } = event;
  const isDot = key === '.';
  if (isDot) {
    event.preventDefault();
  }
};

export const getListOfMonths = ({ startDate, endDate }: any) => {
  const monthsArr: any[] = [];

  let start = dayjs(startDate);
  const end = dayjs(endDate);
  while (start <= end) {
    monthsArr.push(start.format('YYYY-MM-DD'));
    start = start.add(1, 'month');
  }
  return monthsArr;
};

export const dateFromatter = (date: string, format = 'DD MMM YYYY') => {
  return dayjs(date).isValid() ? dayjs(date).format(format) : '';
};

export const auditDateFormatter = (dateString: string) => {
  const date = dayjs(dateString);
  const now = dayjs();
  const diffInDays = now.diff(date, 'day');
  if (diffInDays === 0) {
    return `Today ${date.format('hh:mm A')}`;
  }
  if (diffInDays === 1) {
    return `Yesterday ${date.format('hh:mm A')}`;
  }
  return date.format('DD MMM YYYY  hh:mm A');
};

export const currencyFormatter = (amount: number | string, currency = 'USD', locales = 'en-US') => {
  const formatter = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
  });

  return formatter.format(Math.round(Number(amount)));
};

export const currencyValueFormatter = (amount: number, locales = 'en-US') => {
  const formatter = new Intl.NumberFormat(locales);

  return formatter.format(Math.round(Number(amount)));
};

export const replaceEmpty = (value: any, replaceWith = '-') => value || replaceWith;

export const calculateTotalRevenue = (resources: any[], field: string, currency?: string) => {
  let totalRevenueSum = 0;
  let totalPastRevenueSum = 0;
  resources.forEach((resource: any) => {
    resource[field].forEach((resItem: any) => {
      if (dayjs(resItem.month).startOf('month').isSame(dayjs().startOf('month'))) {
        totalRevenueSum += Math.round(resItem.revenue);
      } else if (
        dayjs(resItem.month).startOf('month').isSame(dayjs().subtract(1, 'month').startOf('month'))
      ) {
        totalPastRevenueSum += Math.round(resItem.revenue);
      }
    });
  });
  return {
    totalCurrRevenue: currencyFormatter(totalRevenueSum, currency),
    totalLastRevenue: currencyFormatter(totalPastRevenueSum, currency),
  };
};

export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const getFullAddress = (address: any) => {
  return `${address?.addressFirstLine ? `${address?.addressFirstLine} ` : ''}${
    address?.addressSecondLine ? `${address?.addressSecondLine} ` : ''
  }${address?.city ? `, ${address?.city}` : ''}${address?.state ? `, ${address?.state}` : ''}${
    address?.country ? `, ${address?.country}` : ''
  }${address?.pincode ? `, ${address?.pincode}.` : ''}`;
};

export const getFullName = (params: any) => {
  const { firstName, middleName, lastName } = params;
  const midName = middleName ? middleName.concat(' ') : '';
  return firstName.concat(' ', midName, lastName);
};

export const getFirstLastName = (params: any) => {
  const { firstName, lastName } = params;
  return firstName.concat(' ', lastName);
};

export const getProcessFields = (allFields: any) => {
  const requiredFields = { ...allFields };
  const processFields = { ...requiredFields };
  Object.keys(requiredFields).forEach((field: any) => {
    if (allFields[field] === '' || allFields[field] === null) delete processFields[field];
  });
  return processFields;
};

export const getAppraisalFY = (date: any) => {
  const appraisalDate = date?.month() >= 3 && date?.month() < 9 ? date?.subtract(1, 'year') : date;
  return getFYDateRange(appraisalDate);
};

export const getAppraisalYear = (dateRange?: any) => {
  const nowYear = new Date().getFullYear();
  const currentYearFromDateRange = dateRange?.endDate?.split('-')[0] || nowYear;
  const previousYearFromDateRange = dateRange?.startDate?.split('-')[0] || nowYear - 1;

  const previousYearISO = new Date(Date.UTC(previousYearFromDateRange, 6, 1)); // July 1 of the previous year
  const currentYearISO = new Date(Date.UTC(currentYearFromDateRange, 5, 30)); // June 30 of the current year

  const previousYear = previousYearISO.toISOString().split('T')[0];
  const currentYear = currentYearISO.toISOString().split('T')[0];
  return { previousYear, currentYear };
};

export const getPreviousFY = (date: any) => {
  const appraisalDate =
    date?.month() >= 3 && date?.month() < 9 ? date?.subtract(2, 'year') : date?.subtract(1, 'year');
  return getFYDateRange(appraisalDate);
};
export enum ApiOperations {
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  GET = 'GET',
  OPTIONS = 'OPTIONS',
}

export enum EmployeeStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ON_NOTICE_PERIOD = 'On_notice_period',
  SABBATICAL_LEAVE = 'Sabbatical_leave',
  MEDICAL_LEAVE = 'Medical_leave',
  EDUCATIONAL_LEAVE = 'Educational_leave',
  MATERNAL_LEAVE = 'Maternal_leave',
  PATERNAL_LEAVE = 'Paternal_leave',
}

export enum UserGroupRoles {
  EMPLOYEE = 'employee',
  HR = 'hr',
  INTERVIEWER = 'interviewer',
  LEGAL = 'legal',
  MANAGER = 'manager',
  OFFICE_ADMIN = 'officeadmin',
  RECRUITER = 'recruiter',
  RESOURCE_MANAGER = 'resourcemanager',
  SALES = 'sales',
  SUPERUSER = 'superuser',
  SYSTEM_ADMIN = 'sysadmin',
  SYSTEM_AUDITOR = 'systemauditor',
  TICKET_ADMIN = 'ticketadmin',
  LETTER_ADMIN = 'letteradmin',
}

export const sortAlphaNumeric = (v1: any, v2: any) => {
  // check if v1 or v2 are numeric or alphanumeric
  const isV1umeric = /^\d+$/.test(v1);
  const isV2Numeric = /^\d+$/.test(v2);

  if (isV1umeric && isV2Numeric) {
    // both v2 and v2 are numeric, so compare them as numbers
    return v1 - v2;
  }
  if (!isV1umeric && !isV2Numeric) {
    // both v2 and v2 are alphanumeric, so compare them as strings
    return v1.localeCompare(v2);
  }
  return isV1umeric ? -1 : 1;
};

export const cleanFormData = (allFields: any) => {
  const requiredFields = { ...allFields };
  const processFields = { ...requiredFields };
  Object.keys(requiredFields).forEach((field: any) => {
    if (allFields[field] === '' || allFields[field] === null) delete processFields[field];
  });
  return processFields;
};

export const hasSuperUserAccess = (groupNames: string[]) =>
  groupNames.includes(UserGroupRoles.SUPERUSER) || groupNames.includes(UserGroupRoles.SYSTEM_ADMIN);

export const getInitialTimesheetState = (weekStart: any) => {
  const initialData = [...Array(5)].map((_, i) => {
    const date = weekStart.add(i, 'day');
    const formattedDate = date.format('YYYY-MM-DD');
    return {
      [formattedDate]: [],
    };
  });
  const formattedObject = Object.assign({}, ...initialData);
  return { details: formattedObject };
};

export const SezAllowedCurrencies = ['INR'];

export const getAllocationStatus: any = (percentage: number) => {
  const displayValue = `${percentage}%`;
  let textColor: string = `${theme.palette.tertiary?.main}`;
  if (percentage === 0 || percentage < 50) {
    textColor = `${theme.palette.status?.danger}`;
  } else if (percentage >= 50 && percentage <= 99) {
    textColor = `${theme.palette.status?.warning}`;
  } else if (percentage >= 100) {
    textColor = `${theme.palette.status?.success}`;
  }
  return React.createElement('span', { style: { color: textColor } }, displayValue);
};

export enum Email {
  ADMIN = 'amit@techprescient.com',
  HRDEPARTMENT = 'hr@techprescient.com',
  HRADMIN = 'swati@techprescient.com',
}
export enum OfferedCandidatesStatus {
  SELECTED_BUT_NOT_OFFERED = 'Selected But Not Offered',
  TO_BE_OFFERED = 'To Be Offered',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_DELCINED = 'Offer Decline',
  NO_LONGER_INTRESTED = 'No Longer Intrested',
  JOINED = 'Joined',
  NOT_JOINED = 'Not Joined',
}

export const getMatchedUids = (arrOfNames: any, list: any) => {
  return arrOfNames
    .map((item: any) => {
      if (typeof item === 'string') {
        const found = list.find((skill: any) => skill.label === item);
        return found ? found.value : null;
      }
      if (typeof item === 'object' && item.value) {
        return item.value;
      }
      return null;
    })
    .filter((uid: any) => uid !== null);
};

export const newJoineeDcouments = [
  'Aadhar Card',
  'PAN Card',
  'Passport size photo',
  '10th Marksheet',
  '12th/Diploma Marksheet',
  'Graduation consolidated Marksheet',
  'Post Graduation consolidated Marksheet',
];
