import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import documentLogo from '../../assets/icons/document.png';
import { DocTypesLabel } from '../../utils/docTypes';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'documentName', sort: 'desc' };

const columns = [
  {
    field: 'documentName',
    type: 'string',
    headerName: 'Document Name',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <a href={params.row.presignedUrl} download>
          <Box className="emp-doc">
            <Box component="img" src={documentLogo} alt="document" />
            <Typography>{params.value}</Typography>
          </Box>
        </a>
      );
    },
  },
  {
    field: 'documentType',
    type: 'string',
    headerName: 'Category',
    renderCell: (params: GridRenderCellParams) => DocTypesLabel[params.value.toUpperCase()],
    flex: 1,
  },
  {
    field: 'uploadedBy',
    type: 'string',
    headerName: 'Uploaded By',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => replaceEmpty(params.value),
  },
  {
    field: 'uploadedOn',
    type: 'string',
    headerName: 'Uploaded On',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => dayjs(params.value).format('DD MMM YYYY'),
  },
];

const docUidName = 'uid';

export { columns, docUidName, initialSort };
