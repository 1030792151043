import { auditDateFormatter } from '../../utils/utils';
import theme from '../../theme';

const initialSort = { field: 'username', sort: 'asc' };

const dynamicColumns: [] = [];

const getActionColors = (action: string) => {
  let color = '';
  switch (action) {
    case 'Delete':
      color = `${theme.palette.status?.danger}`;
      break;
    case 'Create':
      color = `${theme.palette.status?.midDark}`;
      break;
    case 'Update':
      color = `${theme.palette.text.midDark}`;
      break;
    default:
      break;
  }
  return { color };
};

const columns = [
  {
    field: 'username',
    type: 'string',
    headerName: 'User',
    flex: 1.5,
    sortable: true,
  },
  {
    field: 'module',
    type: 'string',
    headerName: 'Module',
    flex: 1.5,
    sortable: true,
  },
  {
    field: 'apiVerb',
    type: 'string',
    headerName: 'Action',
    flex: 1,
    sortable: true,
    renderCell: (params: any) => <span style={getActionColors(params.value)}>{params.value}</span>,
  },
  {
    field: 'apiName',
    type: 'string',
    headerName: 'Api Name',
    flex: 1.5,
    sortable: true,
  },
  {
    field: 'createdOn',
    type: 'date',
    headerName: 'Time Stamp (UTC)',
    flex: 1,
    sortable: true,
    valueFormatter: (params: any) => auditDateFormatter(params.value),
  },
];
const auditlogIdName = 'key';
export { columns, initialSort, dynamicColumns, auditlogIdName };
