import React from 'react';
import dayjs from 'dayjs';
import { Autocomplete, TextField } from '@mui/material';
import useFYSelectorStyles from './FYSelectorStyles';
import { getFYDateRange } from '../../utils/utils';

interface FYSelectorProps {
  readonly onChange: Function;
  readonly defaultValue?: any;
  readonly minValue?: any;
  readonly maxValue?: any;
}

const currFYDateRange = getFYDateRange(dayjs());
const FYSelector: React.FC<FYSelectorProps> = ({ onChange, defaultValue, minValue, maxValue }) => {
  const classes = useFYSelectorStyles();

  const fyArr = [];
  let initFYStartDate = dayjs(minValue.startDate);
  let initFYEndDate = dayjs(minValue.endDate);

  while (initFYStartDate.year() <= dayjs(maxValue.startDate).year()) {
    fyArr.push({
      label: `${initFYStartDate.format('YYYY')}-${initFYEndDate.format('YY')}`,
      startDate: initFYStartDate.format('YYYY-MM-DD'),
      endDate: initFYEndDate.format('YYYY-MM-DD'),
    });
    initFYStartDate = dayjs(initFYStartDate).add(1, 'year');
    initFYEndDate = dayjs(initFYEndDate).add(1, 'year');
  }
  const handleChange = (evt: React.ChangeEvent<any>, option: any) => {
    onChange(option);
  };

  return (
    <>
      <Autocomplete
        disableClearable
        id="fy-selector"
        options={fyArr}
        size="small"
        className={classes.autoComplete}
        onChange={handleChange}
        defaultValue={fyArr.find(
          (fy: any) =>
            dayjs(defaultValue.startDate).isSame(dayjs(fy.startDate)) &&
            dayjs(defaultValue.endDate).isSame(dayjs(fy.endDate)),
        )}
        renderInput={({ inputProps, ...rest }) => (
          <TextField {...rest} label="Select FY" inputProps={{ ...inputProps, readOnly: true }} />
        )}
      />
    </>
  );
};

FYSelector.defaultProps = {
  defaultValue: {
    startDate: currFYDateRange.startDate,
    endDate: currFYDateRange.endDate,
  },
  minValue: {
    startDate: '2017-04-01',
    endDate: '2018-03-31',
  },
  maxValue: getFYDateRange(dayjs().add(1, 'year')),
};

export default FYSelector;
