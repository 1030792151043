import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllResourceListStyles = makeStyles((theme: Theme) => {
  return {
    buttonWrapper: {
      marginLeft: '17rem !important',
      marginTop: '-2rem !important',
      '& svg': {
        marginRight: '5px',
      },
    },
    linkedlnfilterFormWrapper: {
      padding: '0.7rem',
      width: '840px',
      fontSize: '14px',
    },
    closeIcon: {
      color: '#777 !important',
      float: 'right',
      marginTop: '2px !important',
      marginRight: '2px !important',
      width: '20px !important',
      cursor: 'pointer',
    },
    bottomBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '25px',
    },
    removeIconContainer: {
      paddingTop: '10px !important',
    },
    removeIcon: {
      color: 'red !important',
      width: '16px !important',
      cursor: 'pointer',
    },
    addIcon: {
      color: 'green !important',
      width: '16px !important',
      cursor: 'pointer',
    },
    OrIcon: { color: 'black !important', fontSize: '14px !important' },
    AndIcon: { color: 'black !important', width: '10px !important', fontSize: '14px !important' },
    iconsContainer: {
      minHeight: '38px !important',
    },
    datatableWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
    },
  };
});

export default useAllResourceListStyles;
