import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import _ from 'lodash';
import CustomerRelationClient, {
  PATHS as opportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import { OpportunityContactParams } from '../../api/Opportunity/opportunityTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { getProcessFields } from './entitiesFormConfig';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import TelInputField from '../../components/TelInputField/TelInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';

interface AddContactFormProps {
  readonly handleModalClose: Function;
  readonly contactDetail?: any;
  readonly onCreateContact?: Function;
  readonly setIsRefetchContacts?: any;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email format').required('Email id is required'),
});

const AddContactForm: React.FC<AddContactFormProps> = ({
  handleModalClose,
  contactDetail,
  setIsRefetchContacts,
  onCreateContact,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [fields, setFields] = useState(contactDetail);

  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);

  useEffect(() => {
    setFields(contactDetail);
  }, [contactDetail]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    if (contactDetail?.uid) {
      CustomerRelationClient.updateContact(
        contactDetail.uid,
        _.omit(values, ['customerName', 'id', 'uid']),
      )
        .then((res) => {
          handleModalClose();
          showSnackbar(res, 'success');
          setIsRefetchContacts(true);
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    } else {
      const addCRMContact: OpportunityContactParams = {
        ...values,
        otherDetails: {},
      };

      CustomerRelationClient.addCRMContact(getProcessFields(values, addCRMContact))
        .then((res) => {
          onCreateContact?.(res.data);
          showSnackbar(res, 'success');
          handleModalClose();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
          handleModalClose();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={fields}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid } = formik;
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="firstName"
                    label="First Name"
                    variant="standard"
                    type="text"
                    size="small"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="middleName"
                    label="Middle Name"
                    variant="standard"
                    type="text"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="lastName"
                    label="Last Name"
                    variant="standard"
                    type="text"
                    required
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="addressFirstLine"
                    label="Address First Line"
                    variant="standard"
                    type="text"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="addressSecondLine"
                    label="Address Second Line"
                    variant="standard"
                    type="text"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="city"
                    label="City"
                    variant="standard"
                    type="text"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="state"
                    label="State"
                    variant="standard"
                    type="text"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="country"
                    label="Country"
                    variant="standard"
                    type="text"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="pincode"
                    label="Pincode"
                    variant="standard"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="email"
                    type="email"
                    label="Email"
                    variant="standard"
                    size="small"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TelInputField
                    name="phone"
                    key="phone"
                    label="Phone Number"
                    setFieldValue={setFieldValue}
                    placeholder="000 000 0000"
                    properties={[
                      ...[
                        { key: 'defaultCountry', value: 'US' },
                        { key: 'preferredCountries', value: ['US', 'IN'] },
                        { key: 'size', value: 'small' },
                        { key: 'fullWidth', value: true },
                        { key: 'variant', value: 'standard' },
                      ],
                    ]}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="remarks"
                    label="Remarks"
                    variant="standard"
                    type="text"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              {(checkAccess(opportunityPaths.CRM_CONTACT, ApiOperations.PUT) ||
                checkAccess(opportunityPaths.CRM_CONTACT, ApiOperations.POST)) && (
                <Button
                  disabled={!dirty || !isValid}
                  variant="contained"
                  type="submit"
                  className={contactDetail?.uid ? 'saveBtn' : ''}>
                  Save
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
AddContactForm.defaultProps = {
  contactDetail: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  },
  setIsRefetchContacts: () => {},
  onCreateContact: () => {},
};
export default AddContactForm;
