import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import SimpleInputField from '../SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../SimpleAutocompleteField/SimpleAutocompleteField';
import useContractTableStyles from './ContractTableStyle';
import { SOW } from '../../utils/docTypes';
import accountTypes from './utils';

interface ContractTableFormProps {
  readonly handleSubmit: any;
  readonly isEdit?: boolean;
  docOptionsForm: any;
  sowApproverList: any;
  initialFields: any;
}
const ContractTableForm: React.FC<ContractTableFormProps> = ({
  handleSubmit,
  isEdit,
  docOptionsForm,
  sowApproverList,
  initialFields,
}: any) => {
  const styles = useContractTableStyles();

  return (
    <>
      <Formik initialValues={initialFields} onSubmit={handleSubmit} enableReinitialize>
        {(formik) => {
          const { values, isValid, dirty, setFieldValue } = formik;
          return (
            <>
              <Form>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <SimpleAutocompleteField
                      name="documentType"
                      key="documentType"
                      placeholder="Select Document Type"
                      label="Select Document Type"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={docOptionsForm}
                      values={values}
                      variant="standard"
                      disabled={isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="document"
                      label="Upload Document"
                      variant="standard"
                      type="file"
                      onChange={(evt: any) => {
                        setFieldValue(`documentFile`, evt.currentTarget.files[0]);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: '.pdf' }}
                      fullWidth
                      required
                      disabled={isEdit}
                    />
                  </Grid>
                  {isEdit && (
                    <Grid item>
                      <Box>
                        {values.documentName && (
                          <Box className={styles.fileDetailsWrapper}>
                            <Typography className={styles.uploadText}>Uploaded file: </Typography>
                            <PictureAsPdfIcon color="primary" className={styles.pdfIcon} />
                            <Typography className={styles.uploadText}>
                              {values.documentName}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="Start Date"
                      name="startDate"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      variant="standard"
                      disabled={isEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      label="End Date"
                      name="endDate"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      variant="standard"
                      disabled={isEdit}
                      required
                    />
                  </Grid>
                  {(values?.documentType?.label === SOW || initialFields?.documentType === SOW) && (
                    <>
                      <Grid item xs={12}>
                        <SimpleInputField
                          label="PO Number"
                          name="purchaseOrder"
                          size="small"
                          fullWidth
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAutocompleteField
                          name="sowApprover"
                          key="sowApprover"
                          placeholder="SOW Approver's Name"
                          label="SOW Approver's Name"
                          size="small"
                          setFieldValue={setFieldValue}
                          options={sowApproverList}
                          values={values}
                          variant="standard"
                          disabled={isEdit}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SimpleInputField
                          label="Invoice Due Date (In Months)"
                          name="invoiceDueMonthDuration"
                          type="number"
                          size="small"
                          fullWidth
                          inputProps={{
                            min: 0,
                            step: 1,
                            max: 12,
                          }}
                          variant="standard"
                          disabled={isEdit}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SimpleInputField
                          label="(In Days)"
                          name="invoiceDueDayDuration"
                          type="number"
                          size="small"
                          fullWidth
                          inputProps={{
                            min: 0,
                            step: 1,
                            max: 31,
                          }}
                          variant="standard"
                          disabled={isEdit}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAutocompleteField
                          name="accountType"
                          key="accountType"
                          placeholder="Select Account Type"
                          label="Select Account Type"
                          size="small"
                          setFieldValue={setFieldValue}
                          options={accountTypes}
                          values={values}
                          variant="standard"
                          disabled={isEdit}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleInputField
                          name="contractValue"
                          label="Contract Value"
                          type="number"
                          size="small"
                          fullWidth
                          variant="standard"
                          required
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                  Save
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ContractTableForm;
