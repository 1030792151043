import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useFooterStyles = makeStyles((theme: Theme) => {
  return {
    defaultStyle: {
      display: 'flex',
      position: 'fixed',
      height: '30px',
      paddingBottom: '0px',
      paddingRight: '24px',
      bottom: '0',
      backgroundColor: theme.palette.appBackground?.main,
    },
  };
});

export default useFooterStyles;
