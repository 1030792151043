import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getFullName } from '../../../utils/utils';
import useProfileStyles from '../ProfileStyles';
import CardAvatar from './CardAvatar';
import TeamHierarchyFloatingCard from './TeamHierarchyFloatingCard';

interface TeamHierarchyCardProps {
  nodeDatum: any;
  toggleNode: any;
}
const TeamHierarchyCard: React.FC<TeamHierarchyCardProps> = ({ nodeDatum, toggleNode }) => {
  const styles = useProfileStyles();
  const { employeeUid } = useContext(GlobalContext);

  const onCollapseToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (nodeDatum.children.length > 0) toggleNode(e);
  };

  return (
    <Box
      className={`${styles.cardWrapper} `.concat(
        nodeDatum.employeeUid === employeeUid ? `${styles.CurrentUser} ` : ``,
      )}
      role="button"
      tabIndex={-1}>
      <Box className={`${styles.upperCardBody} `}>
        <Box>
          <CardAvatar nodeDatum={nodeDatum} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box className={styles.upperCardBody_nameWrapper}>
            <p className={styles.upperCardBody_name}>
              {getFullName({
                firstName: nodeDatum.firstName,
                middleName: nodeDatum.middleName,
                lastName: nodeDatum.lastName,
              })}
            </p>
            <p className={styles.upperCardBody_desgnation}>{nodeDatum.designation}</p>
          </Box>
        </Box>
      </Box>

      <TeamHierarchyFloatingCard nodeDatum={nodeDatum} onCollapseToggle={onCollapseToggle} />
    </Box>
  );
};

export default TeamHierarchyCard;
