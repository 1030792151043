export enum AccountTypes {
  'FIXED_PRICE' = 'FIXED_PRICE',
  'TIME_MATERIAL_HOURLY' = 'TIME_MATERIAL_HOURLY',
  'TIME_MATERIAL_MONTHLY' = 'TIME_MATERIAL_MONTHLY',
}

export const AccountTypesLabel: Record<string, string> = {
  [AccountTypes.FIXED_PRICE]: 'Fixed Price',
  [AccountTypes.TIME_MATERIAL_HOURLY]: 'Time And Material Hourly',
  [AccountTypes.TIME_MATERIAL_MONTHLY]: 'Time And Material Monthly',
};
