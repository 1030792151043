import React from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import InvoiceResources from './InvoiceResources';
import OtherDetails from './OtherDetails';
import { getInvoiceStatusIcons } from './invoiceTableConfig';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import { OtherDetail, ProjectResource } from '../../api/Invoice/invoiceTypes';

interface SystemInvoiceDetailProps {
  readonly updateFetch: () => void;
  readonly cardInfo: any;
  readonly selectedMonth: dayjs.Dayjs;
  readonly selectedMonthStart: dayjs.Dayjs;
  readonly selectedMonthEnd: dayjs.Dayjs;
  readonly fetch: number;
  readonly invoiceResourceInfo: ProjectResource[];
  readonly otherDetails: OtherDetail[];
  readonly setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SystemInvoiceDetail: React.FC<SystemInvoiceDetailProps> = ({
  updateFetch,
  cardInfo,
  selectedMonth,
  selectedMonthEnd,
  selectedMonthStart,
  fetch,
  invoiceResourceInfo,
  otherDetails,
  setIsLoading,
}) => {
  const intl = useIntl();
  const styles = useInvoiceDetailStyles();
  const params = useParams();

  const invoiceDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_PROJECT,
      }),
      value: cardInfo?.projectName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_INVOICE_NUMBER,
      }),
      value: cardInfo?.invoiceNumber || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_CUSTOMER,
      }),
      value: cardInfo?.customerName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_RESOURCES,
      }),
      value: cardInfo?.noOfResources || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_START_DATE,
      }),
      value: selectedMonthStart.format('DD MMM YYYY'),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_END_DATE,
      }),
      value: selectedMonthEnd.format('DD MMM YYYY'),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_INVOICE_STATUS,
      }),
      value: getInvoiceStatusIcons(cardInfo?.invoiceStatus) || '-',
    },
  ];

  return (
    <>
      {/* Top card */}
      <Box className={styles.UpperCardWrapper}>
        {invoiceDetails.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box className={styles.UpperCardFields} key={item.label + index}>
            <span className={styles.UpperCardFieldsKey}>{item.label}</span>
            <p className={styles.UpperCardFieldsValue}>{item.value}</p>
          </Box>
        ))}
      </Box>

      {/* Lower card */}
      <Box className={styles.MiddleCardWrapper}>
        <InvoiceResources
          invoiceResourceList={invoiceResourceInfo}
          invoiceDetail={{
            projectUid: params.id || '',
            preSignedUrl: cardInfo.preSignedUrl,
            invoiceUid: cardInfo.invoiceUid,
            invoiceNumber: cardInfo.invoiceNumber,
            invoiceStatus: cardInfo.invoiceStatus,
            fileName: cardInfo.fileName,
            currency: cardInfo.projectCurrency,
            invoiceStartDate: cardInfo.invoiceStartDate,
            invoiceEndDate: cardInfo.invoiceEndDate,
          }}
          selectedMonth={selectedMonth}
          updateFetch={updateFetch}
          setIsLoading={setIsLoading}
          otherDetails={otherDetails}
        />
      </Box>
      <Box className={styles.MiddleCardWrapper}>
        <OtherDetails
          key={fetch}
          otherDetails={otherDetails}
          currency={cardInfo.projectCurrency}
          invoiceResourceList={invoiceResourceInfo}
          updateFetch={updateFetch}
          selectedMonth={selectedMonth}
          invoiceStatus={cardInfo.invoiceStatus}
        />
      </Box>
    </>
  );
};

export default SystemInvoiceDetail;
