import { useContext } from 'react';
import { Box } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useKraTemplateDetailsStyles from './KraTemplateDetailStyles';
import { AppraisalStatusList } from '../../api/Appraisal/appraisalTypes';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserGroupRoles } from '../../utils/utils';

const ResourceFeedbackForm: React.FC<any> = ({
  isManagerSection,
  isEmployeeSection,
  formState,
  formRef,
  appraisalStatus,
  handleFormChange,
  isManager,
  isEmployee,
  setOneOnOneValue,
  oneOnOne,
  isFormDirty,
  disableOneonOneSection,
}: any) => {
  const styles = useKraTemplateDetailsStyles();
  const { groupNames } = useContext(GlobalContext);

  const validationSchema = Yup.object().shape({
    employeeStrength: isManagerSection
      ? Yup.string().nullable()
      : Yup.string().required('Strength is required'),
    managerStrength: isManagerSection
      ? Yup.string().required('Strength is required')
      : Yup.string().nullable(),
    employeeAreaOfImp: isManagerSection
      ? Yup.string().nullable()
      : Yup.string().required('Area of Improvement is required'),
    managerAreaOfImp: isManagerSection
      ? Yup.string().required('Area of Improvement is required')
      : Yup.string().nullable(),
    employeeOverallFeedback: isManagerSection
      ? Yup.string().nullable()
      : Yup.string().required('Overall Feedback is required'),
    managerOverallFeedback: isManagerSection
      ? Yup.string().required('Overall Feedback is required')
      : Yup.string().nullable(),
    promotionRecommended: Yup.boolean().nullable(),
    oneOnOne: Yup.boolean().nullable(),
    promotionComments: Yup.string().nullable(),
  });

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue } = formik;
          return (
            <>
              <Form onChange={handleFormChange}>
                <table className={styles.table}>
                  <tr>
                    <th className={`${styles.tableHeader} ${styles.leftColumn}`}>Category </th>
                    <th className={styles.tableHeader}>Self</th>
                    <th className={`${styles.tableHeader} ${styles.rightColumn}`}>Manager</th>
                  </tr>
                  <tbody className={styles.tableContents}>
                    <>
                      <tr>
                        <td className={styles.column}>Strength</td>
                        <td className={styles.column}>
                          <SimpleInputField
                            name="employeeStrength"
                            type="string"
                            size="small"
                            minRows={5}
                            disabled={!isEmployeeSection}
                            multiline
                            fullWidth
                            required
                          />
                        </td>
                        <td className={`${styles.column} ${styles.rightColumn}`}>
                          <SimpleInputField
                            name="managerStrength"
                            type="string"
                            minRows={5}
                            multiline
                            disabled={!isManagerSection || oneOnOne}
                            size="small"
                            fullWidth
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.column}>Areas of improvement</td>
                        <td className={styles.column}>
                          <SimpleInputField
                            name="employeeAreaOfImp"
                            type="string"
                            size="small"
                            minRows={5}
                            disabled={!isEmployeeSection}
                            multiline
                            fullWidth
                            required
                          />
                        </td>
                        <td className={`${styles.column} ${styles.rightColumn}`}>
                          <SimpleInputField
                            name="managerAreaOfImp"
                            type="string"
                            size="small"
                            minRows={5}
                            disabled={!isManagerSection || oneOnOne}
                            multiline
                            fullWidth
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.column}`}>Overall Feedback</td>
                        <td className={`${styles.column}`}>
                          <SimpleInputField
                            name="employeeOverallFeedback"
                            type="string"
                            size="small"
                            minRows={5}
                            disabled={!isEmployeeSection}
                            multiline
                            fullWidth
                            required
                          />
                        </td>
                        <td className={`${styles.column} ${styles.rightColumn}`}>
                          <SimpleInputField
                            name="managerOverallFeedback"
                            type="string"
                            size="small"
                            minRows={5}
                            disabled={!isManagerSection || oneOnOne}
                            multiline
                            fullWidth
                            required
                          />
                        </td>
                      </tr>
                    </>
                  </tbody>
                  {/* {(isManagerSection ||
                    (appraisalStatus === AppraisalStatusList.MANAGER_SUBMITTED && isManager) ||
                    (groupNames.includes(UserGroupRoles.HR) && !isEmployee)) && (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <Box className={styles.tableFooter}>
                            <SimpleCheckboxField
                              className={styles.recommendText}
                              name="promotionRecommended"
                              disabled={!isManagerSection}
                              checked={values.promotionRecommended}
                              inputProps={{ style: { fontSize: 13 } }}
                              setFieldValue={setFieldValue}
                              label="Promotion Recommended"
                              size="small"
                            />
                            <SimpleInputField
                              name="promotionComments"
                              type="string"
                              disabled={!isManagerSection}
                              label="Comments"
                              size="small"
                              minRows={3}
                              multiline
                              fullWidth
                            />
                          </Box>
                        </td>
                      </tr>
                    </>
                  )} */}
                </table>
              </Form>
              {appraisalStatus === AppraisalStatusList.MANAGER_SUBMITTED &&
                (isManager || (groupNames.includes(UserGroupRoles.HR) && !isEmployee)) && (
                  <Box className={styles.tableFooter}>
                    <SimpleCheckboxField
                      className={styles.recommendText}
                      name="oneOnOne"
                      disabled={
                        !isManagerSection ||
                        appraisalStatus !== AppraisalStatusList.MANAGER_SUBMITTED ||
                        isFormDirty ||
                        disableOneonOneSection
                      }
                      checked={values.oneOnOne}
                      inputProps={{ style: { fontSize: 13 } }}
                      handleChange={(e: any, v: boolean) => {
                        setOneOnOneValue(v);
                      }}
                      label="One On One"
                      setFieldValue={setFieldValue}
                      size="small"
                    />
                  </Box>
                )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ResourceFeedbackForm;
