/* eslint-disable @typescript-eslint/no-unused-vars */
import axiosInstance from '../axiosInstance';
import { DocType } from './masterTypes';

export const PATHS = Object.freeze({
  GET_DOCUMENT_TYPES: `/master/document_type`,
  GET_SKILLS: '/master/skill/',
  GET_DESIGNATION: '/master/designation/',
  GET_DEPARTMENT: '/master/department/',
  GET_TRACK: '/master/track/',
  GET_ALL_ENUMS: '/master/employee_constants/all',
  GET_EXIT_REASON: '/master/employee_constants/exit_reason',
});

const getDocumentTypes = async (): Promise<DocType[]> => {
  const response = await axiosInstance.get(PATHS.GET_DOCUMENT_TYPES);
  return response.data;
};

const getSkills = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_SKILLS}`);
  return response;
};

const getDesignation = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_DESIGNATION}`);
  return response;
};

const getDepartment = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_DEPARTMENT}`);
  return response;
};

const getTrack = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_TRACK}`);
  return response;
};

const getAllEnums = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_ALL_ENUMS}`);
  return response;
};

const getExitReason = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_EXIT_REASON}`);
  return response;
};

export interface ContractTableAPIClient {
  readonly getDocumentTypes: () => Promise<DocType[]>;
  readonly getSkills: () => Promise<any>;
  readonly getDesignation: () => Promise<any>;
  readonly getDepartment: () => Promise<any>;
  readonly getTrack: () => Promise<any>;
  readonly getAllEnums: () => Promise<any>;
  readonly getExitReason: () => Promise<any>;
}

const MasterClient: ContractTableAPIClient = Object.freeze({
  getDocumentTypes,
  getSkills,
  getDesignation,
  getDepartment,
  getTrack,
  getAllEnums,
  getExitReason,
});

export default MasterClient;
