import { makeStyles } from '@mui/styles';

export default makeStyles(() => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      color: '#6f6f6f',

      '& h4': {
        color: '#979696',
      },

      '& svg': {
        fontSize: '350px',
      },
    },

    actionMsgWrapper: {
      display: 'flex',

      '& p': {
        marginTop: '6px',
        marginLeft: '-4px',
      },
    },
  };
});
