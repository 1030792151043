import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import { GetProjectParams, ProjectDetail } from '../Project/projectTypes';
import {
  EmployeeManagerApiParams,
  EmployeeOtherDetailsParams,
  GetResourceParams,
  ResourceAllocationHistory,
  ResourceDetail,
  ResourceProjectAllocationHistoryApiParams,
  ResourceProjectApiParams,
} from './resourceTypes';

export const PATHS = Object.freeze({
  GET_PROJECT: '/project/listing',
  GET_RESOURCES: '/employee/resources/',
  GET_RESOURCE_DETAILS: (id: string) => `/resource/${id}`,
  EMPLOYEE_PROJECT: (emp_uid: string, project_uid: string) =>
    `/employee/${emp_uid}/project/${project_uid}`,
  PUT_EMPLOYEE_OTHER_DETAILS: '/employee/other_details',
  EMPLOYEE_MANAGER: (emp_uid: string) => `/employee/${emp_uid}/manager`,
  PUT_EMPLOYEE_MANAGER: (emp_uid: string, emp_manager_uid: string) =>
    `/employee/${emp_uid}/manager/${emp_manager_uid}`,
  GET_VIEW_BY_PROJECTLIST: '/employee/details/view_by_project',
  GET_EMPLOYEE_PROJECT_ALLOCATION_HISTORY: (
    emp_uid: string,
    start_date: string,
    end_date: string,
  ) => `employee/${emp_uid}/project_history?start_date=${start_date}&end_date=${end_date}`,
});

const getProjectList = async (getProjectParams: GetProjectParams): Promise<ProjectDetail[]> => {
  const queryParams = queryString.stringify(getProjectParams);

  const response = await axiosInstance.get(`${PATHS.GET_PROJECT}?${queryParams}`);
  return response.data;
};

const getResourceList = async (getResourceParams: GetResourceParams): Promise<ResourceDetail[]> => {
  const queryParams = queryString.stringify(getResourceParams);

  const response = await axiosInstance.get(`${PATHS.GET_RESOURCES}?${queryParams}`);
  return response.data;
};

const updateResourceProject = async (
  emp_uid: string,
  project_uid: string,
  updateResourceProjectParams: ResourceProjectApiParams,
) => {
  const response = await axiosInstance.put(
    `${PATHS.EMPLOYEE_PROJECT(emp_uid, project_uid)}`,
    updateResourceProjectParams,
  );

  return response;
};

const createResourceProject = async (
  emp_uid: string,
  project_uid: string,
  createResourceProjectParams: ResourceProjectApiParams,
) => {
  const response = await axiosInstance.post(
    `${PATHS.EMPLOYEE_PROJECT(emp_uid, project_uid)}`,
    createResourceProjectParams,
  );

  return response;
};

const updateEmployeeOtherDetails = async (
  updateEmployeeOtherDetailsParams: EmployeeOtherDetailsParams[],
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.PUT_EMPLOYEE_OTHER_DETAILS}`,
    updateEmployeeOtherDetailsParams,
  );
  return response;
};

const addEmployeeManager = async (
  emp_uid: string,
  EmployeeManagerParams: EmployeeManagerApiParams,
) => {
  const response = await axiosInstance.post(
    `${PATHS.EMPLOYEE_MANAGER(emp_uid)}`,
    EmployeeManagerParams,
  );

  return response;
};

const getEmployeeManager = async (emp_uid: string) => {
  const response = await axiosInstance.get(`${PATHS.EMPLOYEE_MANAGER(emp_uid)}`);
  return response;
};

const updateEmployeeManager = async (
  emp_uid: string,
  emp_manager_uid: string,
  updateEmployeeManagerParams: EmployeeManagerApiParams,
) => {
  const response = await axiosInstance.put(
    `${PATHS.PUT_EMPLOYEE_MANAGER(emp_uid, emp_manager_uid)}`,
    updateEmployeeManagerParams,
  );

  return response;
};
const getResourceAllocationHistory = async (
  fetchAllocationHistoryParams: ResourceProjectAllocationHistoryApiParams,
): Promise<ResourceAllocationHistory[]> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_EMPLOYEE_PROJECT_ALLOCATION_HISTORY(
      fetchAllocationHistoryParams.employeeUid,
      fetchAllocationHistoryParams.startDate,
      fetchAllocationHistoryParams.endDate,
    )}`,
  );
  return response.data;
};

const getViewByProjectList = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_VIEW_BY_PROJECTLIST);
  return response.data;
};

export interface ResourceAPIClient {
  readonly getProjectList: (getProjectParams: GetProjectParams) => Promise<ProjectDetail[]>;
  readonly getResourceList: (getResourceParams: GetResourceParams) => Promise<any>;
  readonly updateResourceProject: (
    emp_uid: string,
    project_uid: any,
    updateResourceProjectParams: ResourceProjectApiParams,
  ) => Promise<any>;
  readonly createResourceProject: (
    emp_uid: string,
    project_uid: string,
    updateResourceProjectParams: ResourceProjectApiParams,
  ) => Promise<any>;
  readonly updateEmployeeOtherDetails: (
    updateEmployeeOtherDetailsParams: EmployeeOtherDetailsParams[],
  ) => Promise<any>;
  readonly getEmployeeManager: (emp_uid: string) => Promise<any>;
  readonly addEmployeeManager: (
    emp_uid: string,
    addEmployeeManagerParams: EmployeeManagerApiParams,
  ) => Promise<any>;
  readonly updateEmployeeManager: (
    emp_uid: string,
    project_uid: string,
    updateEmployeeManagerParams: EmployeeManagerApiParams,
  ) => Promise<any>;
  readonly getViewByProjectList: () => Promise<any>;
  readonly getResourceAllocationHistory: (
    fetchAllocationHistoryParams: ResourceProjectAllocationHistoryApiParams,
  ) => Promise<ResourceAllocationHistory[]>;
}

const ResourceClient: ResourceAPIClient = Object.freeze({
  getProjectList,
  getResourceList,
  updateResourceProject,
  createResourceProject,
  updateEmployeeOtherDetails,
  getEmployeeManager,
  addEmployeeManager,
  updateEmployeeManager,
  getViewByProjectList,
  getResourceAllocationHistory,
});

export default ResourceClient;
