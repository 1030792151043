import axios from 'axios';
import newCandidateAxiosInstance from '../newCandidateAxiosInstance';

export const PATHS = Object.freeze({
  GET_DOCUMENT_TYPES: `${process.env.REACT_APP_SERVICE_HOST}/candidate/document_types`,
  POST_TOKEN_VERIFY: `${process.env.REACT_APP_SERVICE_HOST}/candidate/validate`,
  GET_NEW_CANDIDATE_CERTIFICATE_BY_ID: (uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${uid}/document/others`,
  POST_NEW_CANDIDATE_CERTIFICATE_BY_ID: (uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${uid}/document/certificates`,
  GET_NEW_CANDIDATE_PERSONAL_DOC_BY_ID: (uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${uid}/document/personal`,
  GET_NEW_CANDIDATE_PROFESSIONAL_DOC_BY_ID: (uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${uid}/document/professional`,
  DELETE_NEW_CANDIDATE_CERTIFICATE_BY_ID: (employee_uid: string, document_uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${employee_uid}/document/${document_uid}`,
  DELETE_NEW_CANDIDATE_PERSONAL_BY_ID: (employee_uid: string, document_uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${employee_uid}/document/${document_uid}`,
  DELETE_NEW_CANDIDATE_PROFESSIONAL_BY_ID: (employee_uid: string, document_uid: string) =>
    `${process.env.REACT_APP_SERVICE_HOST}/candidate/${employee_uid}/document/${document_uid}`,
  VALIDATE_TOKEN: `${process.env.REACT_APP_SERVICE_HOST}/candidate/validate`,
  GET_CANDIDATE_DETAILS_BY_ID: `${process.env.REACT_APP_SERVICE_HOST}/candidate/new_joinee?status=draft&new_joinee_uid=`,
  GET_SUBMITTED_CANDIDATE_DETAILS_BY_ID: `${process.env.REACT_APP_SERVICE_HOST}/candidate/new_joinee?status=submitted&new_joinee_uid=`,
  SAVE_CANDIDATE_DETAILS: `${process.env.REACT_APP_SERVICE_HOST}/candidate/add`,
});

const validateToken = async (token: string) => {
  const response = await axios.post(PATHS.VALIDATE_TOKEN, { token });
  return response.data;
};

const getNewCandidateDetails = async (newJoineeId: string) => {
  const response = newCandidateAxiosInstance.get(
    `${PATHS.GET_CANDIDATE_DETAILS_BY_ID}${newJoineeId}`,
  );
  return response;
};

const submittedCandidateDetails = async (newJoineeId: string) => {
  const response = newCandidateAxiosInstance.get(
    `${PATHS.GET_SUBMITTED_CANDIDATE_DETAILS_BY_ID}${newJoineeId}`,
  );
  return response;
};

const saveCandidateDetails = async (newJoineeId: string, requestBody: any, status: string) => {
  const responseData = await newCandidateAxiosInstance.post(
    `${PATHS.SAVE_CANDIDATE_DETAILS}/${newJoineeId}`,
    {
      personal_details: requestBody.personalDetailsToSave,
      finance_details: requestBody.financeDetailsToSave,
      relation_details: requestBody.relationDetailsToSave,
      emergency_contact_details: requestBody.emergencyContactsToSave,
      status,
    },
  );
};

export interface NewCandidateAPIClient {
  readonly validateToken: (tokenValidateParams: any) => Promise<any>;
  readonly getNewCandidateDetails: (uid: any) => Promise<any>;
  readonly getNewCandidateCertificatesById: (uid: any) => Promise<any>;
  readonly getNewCandidatePersonalDocsById: (uid: any) => Promise<any>;
  readonly getNewCandidateProfessionalDocsById: (uid: any) => Promise<any>;
  readonly getDocumentTypes: () => Promise<any[]>;
  readonly addNewCandidateProfessionalDocs: (uid: any, newEmployeeDocParams: any) => Promise<any[]>;
  readonly addNewCandidatePersonalDocs: (uid: any, newEmployeeDocParams: any) => Promise<any[]>;
  readonly addNewCandidateOtherDocs: (uid: any, newEmployeeDocParams: any) => Promise<any[]>;
  readonly saveCandidateDetails: (
    newJoineeId: string,
    requestBody: any,
    status: string,
  ) => Promise<any>;
  readonly submittedCandidateDetails: (newJoineeId: string) => Promise<any>;
  readonly deleteEmployeeCertificatesById: (
    employee_uid: string,
    document_uid: string,
  ) => Promise<any>;
  readonly deleteEmployeeProfessionalDocsById: (
    employee_uid: string,
    document_uid: string,
  ) => Promise<any>;
  readonly deleteEmployeePersonalDocsById: (
    employee_uid: string,
    document_uid: string,
  ) => Promise<any>;
}

const getNewCandidateCertificatesById = async (candidateUid: string): Promise<any> => {
  const response = await newCandidateAxiosInstance.get(
    PATHS.GET_NEW_CANDIDATE_CERTIFICATE_BY_ID(candidateUid),
  );
  return response;
};

const getNewCandidatePersonalDocsById = async (candidateUid: string): Promise<any> => {
  const response = await newCandidateAxiosInstance.get(
    PATHS.GET_NEW_CANDIDATE_PERSONAL_DOC_BY_ID(candidateUid),
  );
  return response;
};

const getNewCandidateProfessionalDocsById = async (candidateUid: string): Promise<any> => {
  const response = await newCandidateAxiosInstance.get(
    PATHS.GET_NEW_CANDIDATE_PROFESSIONAL_DOC_BY_ID(candidateUid),
  );
  return response;
};

const getDocumentTypes = async (): Promise<any> => {
  const response = await newCandidateAxiosInstance.get(PATHS.GET_DOCUMENT_TYPES);
  return response.data;
};

const addNewCandidateProfessionalDocs = async (
  employee_uid: string,
  newEmployeeDocParams: any,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.post(
    PATHS.GET_NEW_CANDIDATE_PROFESSIONAL_DOC_BY_ID(employee_uid),
    newEmployeeDocParams,
  );
  return response;
};

const addNewCandidatePersonalDocs = async (
  employee_uid: string,
  newEmployeeDocParams: any,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.post(
    PATHS.GET_NEW_CANDIDATE_PERSONAL_DOC_BY_ID(employee_uid),
    newEmployeeDocParams,
  );
  return response;
};

const addNewCandidateOtherDocs = async (
  employee_uid: string,
  newEmployeeDocParams: any,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.post(
    PATHS.POST_NEW_CANDIDATE_CERTIFICATE_BY_ID(employee_uid),
    newEmployeeDocParams,
  );
  return response;
};

const deleteEmployeeCertificatesById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.delete(
    PATHS.DELETE_NEW_CANDIDATE_CERTIFICATE_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const deleteEmployeePersonalDocsById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.delete(
    PATHS.DELETE_NEW_CANDIDATE_PERSONAL_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const deleteEmployeeProfessionalDocsById = async (
  employee_uid: string,
  document_uid: string,
): Promise<any> => {
  const response = await newCandidateAxiosInstance.delete(
    PATHS.DELETE_NEW_CANDIDATE_PROFESSIONAL_BY_ID(employee_uid, document_uid),
  );
  return response;
};

const NewCandidateClient: NewCandidateAPIClient = Object.freeze({
  validateToken,
  getNewCandidateDetails,
  getNewCandidateCertificatesById,
  getNewCandidatePersonalDocsById,
  getNewCandidateProfessionalDocsById,
  getDocumentTypes,
  addNewCandidateProfessionalDocs,
  addNewCandidatePersonalDocs,
  addNewCandidateOtherDocs,
  deleteEmployeeCertificatesById,
  deleteEmployeeProfessionalDocsById,
  deleteEmployeePersonalDocsById,
  saveCandidateDetails,
  submittedCandidateDetails,
});

export default NewCandidateClient;
