import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import useDocumentStyles from './DocumentStyles';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserGroupRoles, hasSuperUserAccess } from '../../utils/utils';
import { DocTypes } from './utils';

const GenerateDocuments = () => {
  const styles = useDocumentStyles();
  const { groupNames } = useContext(GlobalContext);
  const intl = useIntl();
  const isSysAdmin = groupNames.includes(UserGroupRoles.SYSTEM_ADMIN);
  const isLettersAdminAccess = groupNames.includes(UserGroupRoles.LETTER_ADMIN);
  const [isHRAccess, setIsHRAccess] = useState<boolean>(groupNames.includes(UserGroupRoles.HR));

  const documentArray = [
    {
      label: intl.formatMessage({
        id: I18nKey.OFFER_LETTER,
      }),
      link: DocTypes.OFFER_LETTER,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.COMPENSATION_LETTER,
      }),
      link: DocTypes.COMPENSATION_LETTER,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INTERNSHIP_OFFER_LETTER,
      }),
      link: DocTypes.INTERNSHIP_LETTER,
    },
  ];

  const getDocuments = () => {
    if (isSysAdmin) {
      return documentArray;
    }
    if (isLettersAdminAccess) {
      return documentArray.filter((doc) => doc.link !== DocTypes.COMPENSATION_LETTER);
    }
    if (isHRAccess) {
      return documentArray.filter((doc) => doc.link === DocTypes.INTERNSHIP_LETTER);
    }
    return [];
  };

  const documents = getDocuments();

  return (
    <Box className={styles.generateDocWrapper}>
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.GENERATE_DOCUMENT_TITLE,
          })}
        </span>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {documents.map(({ label, link }) => (
            <Grid key={link} item xs={6} sm={4} md={4} lg={2} className={styles.itemWrapper}>
              <Link to={link}>
                <Box className={styles.routeItem}>
                  <Typography>{label}</Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default GenerateDocuments;
