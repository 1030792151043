import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useCRMProjectDetailsStyles from './OpportunityDetailStyles';

const OpportunityDeleteInfo: React.FC<any> = ({ setDeleteInfo }) => {
  const handleChange = (values: any): any => {
    setDeleteInfo(values);
  };

  const classes = useCRMProjectDetailsStyles();

  return (
    <Box className={classes.formContainer}>
      <Formik initialValues={{ comment: '' }} onSubmit={() => {}} enableReinitialize>
        {(formik) => {
          const { values } = formik;
          return (
            <Form onChange={handleChange(values)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="comment"
                    label="Comment"
                    variant="standard"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OpportunityDeleteInfo;
