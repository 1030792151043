import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import useDocumentStyles from './DocumentStyles';
import DocumentClient from '../../api/Document/DocumentAPI';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { internshipLetterInitialValues } from './utils';
import InternshipLetterForm from './components/InternshipLetterForm';

const InternshipLetterPage = () => {
  const styles = useDocumentStyles();
  const intl = useIntl();
  const params = useParams();
  const candidateUid = params.id;

  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState<any>(internshipLetterInitialValues);
  const [fetch, setFetch] = useState(0);
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const updateCandidateDetails = (candidateDetail: any) => {
    const details = {
      addressFirstLine: candidateDetail.addressFirstLine || '',
      addressSecondLine: candidateDetail.addressSecondLine || '',
      dateOfJoining: candidateDetail.dateOfJoining || dayjs().format('YYYY-MM-DD'),
      designation: candidateDetail.designation || '',
      email: candidateDetail.email || '',
      name: candidateDetail.name || '',
      phoneNumber: candidateDetail.phoneNumber || '',
      stipend: candidateDetail.stipend || 0,
      endDate: candidateDetail.endDate || dayjs().format('YYYY-MM-DD'),
    };
    setCandidateDetails(details);
  };

  const getInternDetailsById = (uid: string) => {
    DocumentClient.getInternsOfferLetterDetails(uid)
      .then((response: any) => {
        updateCandidateDetails(response.data);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (candidateUid) {
      setLoading(true);
      getInternDetailsById(candidateUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.detailPageWrapper}>
        <Box className={styles.DetailPageHeader}>
          <span>
            {intl.formatMessage({
              id: I18nKey.GENERATE_DOCUMENT_TITLE,
            })}
          </span>
          <>
            {candidateUid ? (
              <Box>
                <Link to="/document/internshipLetter">
                  {intl.formatMessage({
                    id: I18nKey.INTERNSHIP_OFFER_LETTER,
                  })}
                </Link>{' '}
                / {candidateDetails?.name}
              </Box>
            ) : (
              <Box>
                <Link to="/document/internshipLetter">
                  {intl.formatMessage({
                    id: I18nKey.INTERNSHIP_OFFER_LETTER,
                  })}
                </Link>{' '}
                /{' '}
                {intl.formatMessage({
                  id: I18nKey.NEW_INTERNSHIP_LETTER,
                })}
              </Box>
            )}
          </>
        </Box>
        <Box className={styles.ContentWrapper}>
          <InternshipLetterForm
            candidateDetails={candidateDetails}
            candidateId={candidateUid}
            updateFetch={updateFetch}
          />
        </Box>
      </Box>
    </>
  );
};

export default InternshipLetterPage;
