import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useLoginStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      zIndex: 1,
      width: '76.13px',
    },
    header: {
      display: 'flex',
      height: '45px',
      padding: '16px 0 0 16px',
    },
    contentWrapper: {
      display: 'flex',
    },
    leftWrapper: {
      flex: 1,
    },
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 45px)',
    },
    image: {
      width: '600px',
      height: '600px',
    },
    rightWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      backgroundColor: theme.palette.popUpBackground?.lighter,
    },
    headerText: {
      color: theme.palette.secondary.main,
    },
    subHeaderText: {
      color: theme.palette.text.light,
      fontSize: '32px',
      margin: '0',
    },
    authWrapper: {
      '& .amplify-divider': {
        display: 'none',
      },
      '& [data-amplify-authenticator] [data-amplify-router]': {
        backgroundColor: theme.palette.popUpBackground?.lighter,
        boxShadow: 'none',
        border: 'none',
      },
      '& .amplify-button': {
        backgroundColor: 'white',
      },
      '& .amplify-flex:first-child': {
        padding: '0 !important',
      },
      '& .amplify-flex:last-child': {
        display: 'none',
      },

      '& [data-amplify-footer]': {
        display: 'none',
      },
    },
  };
});

export default useLoginStyles;
