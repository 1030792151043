import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { replaceEmpty, dateFromatter, getListOfMonths, UserGroupRoles } from '../../utils/utils';
import { InterviewStatus, validateDate } from './utils';

const initialSort = { field: 'scheduledDate', sort: 'desc' };

const getInterviewStatusIcons = (status: string) => {
  switch (status) {
    case InterviewStatus.PASSED:
      return (
        <Tooltip title="Passed">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    case InterviewStatus.DID_NOT_APPEAR:
      return (
        <Tooltip title="Did Not Appear">
          <ErrorOutlineIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    case InterviewStatus.FAILED:
      return (
        <Tooltip title="Failed">
          <CancelOutlinedIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    default:
      return null;
  }
};

const columns = [
  {
    field: 'currentState',
    type: 'string',
    headerName: 'Current State',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'yearsOfExperience',
    type: 'number',
    headerName: 'Experience',
    headerAlign: 'left',
    cellClassName: 'left-align-number-field',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'skills',
    type: 'string',
    headerName: 'Skill',
    flex: 1.5,
    sortable: true,
    cellClassName: 'employee-skill-cell',
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Status',
    flex: 1.5,
    sortable: true,
    renderCell: ({ value }: GridRenderCellParams) => getInterviewStatusIcons(value),
  },
  {
    field: 'noticePeriod',
    type: 'string',
    headerName: 'Category',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'dateOfJoining',
    type: 'date',
    valueFormatter: (params: any) => dateFromatter(params.value),
    headerName: 'DOJ',
    flex: 1,
    sortable: true,
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 1.5,
    sortable: true,
    hide: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'scheduledDate',
    type: 'date',
    headerName: 'Last Updated on',
    flex: 1.5,
    sortable: true,
    valueFormatter: validateDate,
  },
];

const getColumns = (
  selectedList: string,
  selectedTab: string,
  isAccess: boolean,
  rowActions: any[],
) => {
  const cols = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={`/interview/${params.row.uid}?selected_list=${selectedList}&selected_tab=${selectedTab}`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      align: 'center',
      flex: 1,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
  return cols;
};

const getInitialVisibilityModel = (
  { showColumns }: any,
  selectedList: any,
  selectedTab: any,
  isHRAccess: any,
  // handleResumeDownload: any,
  rowActions: any[],
) => {
  const colRange = getColumns(
    selectedList,
    selectedTab,
    isHRAccess,
    // handleResumeDownload,
    rowActions,
  );
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const candidateUid = 'uid';

const initialShowCols = [
  'name',
  'currentState',
  'yearsOfExperience',
  'skills',
  'status',
  'noticePeriod',
  'dateOfJoining',
  'scheduledDate',
  'actions',
];

export {
  columns,
  initialSort,
  candidateUid,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
};
