import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { InvoiceUploadType } from '../../api/Invoice/invoiceTypes';
import { InvoiceStatus } from '../../pages/Invoice/invoiceTableConfig';
import { getCurrencySymbol } from '../../utils/currency';
import { currencyValueFormatter, dateFromatter } from '../../utils/utils';

export const getColumnConfig = (col: any) => {
  const column: any = {
    // TODO: Capitalize
    headerName: `${dayjs(col?.field).format('MMMM')} (${getCurrencySymbol(col?.currency) || ''})`,
    type: 'number',
    headerAlign: 'right',
    cellClassName: 'right-align-number-field',
    flex: 1,
    sortable: false,
    hideable: true,
    editable: false,
    commentable: false,
    computable: true,
    isVisible: true,
    filterable: false,
    valueFormatter: ({ value }: any) => currencyValueFormatter(value || 0),
    ...col,
  };
  return column;
};

export const generateActionColumn = (rowActions: any[]) => {
  const column: any = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      flex: 1,
      renderCell: (params: any) => {
        return rowActions.map((action) => {
          let isDisabled = true;

          if (params.row.invoiceUploadType === InvoiceUploadType.manual) {
            if (action.label === 'Download') isDisabled = false;
          } else {
            switch (params.row.invoiceStatus) {
              case InvoiceStatus.FINALISED:
                if (action.label === 'Generate') isDisabled = false;
                break;

              case InvoiceStatus.GENERATED:
                isDisabled = false;
                break;

              case InvoiceStatus.SHARED:
                if (action.label === 'Download') isDisabled = false;
                break;

              default:
                isDisabled = true;
            }
          }

          return isDisabled ? (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params.row)}
              disabled={isDisabled}
            />
          ) : (
            <Tooltip disableHoverListener={isDisabled} title={action.label} key={action.label}>
              <GridActionsCellItem
                key={action.label}
                icon={action.logo}
                label={action.label}
                onClick={() => action.callback(params.row)}
                disabled={isDisabled}
              />
            </Tooltip>
          );
        });
      },
    },
  ];

  return column;
};

export const generateDeleteColumn = (rowActions: any[]) => {
  const column: any = [
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      width: 10,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];

  return column;
};

export const generateRows = (
  rows: any[],
  dynamicColumns: any,
  tableRowTotalField: any[],
  primaryKeyColName?: string,
) => {
  return rows.map((row) => {
    // TODO: primaryKeyColName is not required once implemented for all the tables
    const newRow: any = { ...row, ...(primaryKeyColName && { id: row[primaryKeyColName] }) };

    if (dynamicColumns.length > 0) {
      dynamicColumns.forEach((dynamicColumn: any) => {
        if (Object.prototype.hasOwnProperty.call(newRow, dynamicColumn.fieldName)) {
          const arrDynamicCells = newRow[dynamicColumn.fieldName] as any[];
          arrDynamicCells.forEach((arr) => {
            const month = dateFromatter(arr[dynamicColumn.columnHeader], 'MMM');
            const obj = dynamicColumn.getCellValue
              ? dynamicColumn.getCellValue(arr)
              : {
                  // [month]: arr[dynamicColumn.rowCell],
                  [arr[dynamicColumn.columnHeader]]: arr[dynamicColumn.rowCell],
                };
            Object.assign(newRow, obj);
          });

          // TODO: make 'Total' of dynamicColumn configurable, right now its for 'revenue'
          if (tableRowTotalField.length > 0) {
            const result = arrDynamicCells.reduce(function (sum, item) {
              return Number(sum) + Number(item[dynamicColumn.rowCell]);
            }, 0);
            // TODO: [tableRowTotalField[0].field as string] make it generic
            const obj = {
              [tableRowTotalField[0].field as string]: result,
            };
            Object.assign(newRow, obj);
          }
          delete newRow[dynamicColumn.fieldName];
        }
      });
    }
    return newRow;
  });
};

export const calculateFooterRow = (rows: any[], columns: any[]) => {
  const totalArr: any = [];
  columns.forEach((col: any, id: number) => {
    if (rows.length > 0) {
      let value = null;
      if (col.computable) {
        const result = rows.reduce((sum: number, row: any) => {
          return Number(row[col.field]?.amount || Number(row[col.field]) || 0) + sum;
        }, 0);
        value = currencyValueFormatter(Math.round(result));
      }
      totalArr.push({ id, value, ...col });
    }
    if (totalArr.length) totalArr[0].value = 'Total';
  });
  return totalArr;
};

export const getShowMonthCols = (startDt: any, endDt: any) => {
  const startDate = dayjs(startDt);
  const endDate = dayjs(endDt);
  return Array.from(
    {
      length: (endDate.year() - startDate.year()) * 12 + endDate.month() - startDate.month() + 1,
    },
    (_, i) => {
      const newMonth = startDate.month() + i;
      return (newMonth + 12) % 12; // Adjust month to be in the range 0 to 11
    },
  );
};

export const getInitialShowMonthsCols = (noOfMonths: number) => {
  let currentMonth = dayjs();
  const monthsArr: any[] = [currentMonth.month()];
  while (monthsArr.length < noOfMonths) {
    currentMonth = currentMonth.add(1, 'month');
    if (currentMonth.month() === 3) break;
    monthsArr.push(currentMonth.month());
  }
  currentMonth = dayjs().subtract(1, 'month');
  while (monthsArr.length < noOfMonths) {
    monthsArr.unshift(currentMonth.month());
    currentMonth = currentMonth.subtract(1, 'month');
  }
  return monthsArr;
};

export const getLastMonths = (noOfMonths: number) => {
  let currentMonth = dayjs();
  const monthsArr: number[] = [];
  while (monthsArr.length < noOfMonths) {
    currentMonth = currentMonth.subtract(1, 'month');
    if (currentMonth.month() === 3) break;
    monthsArr.unshift(currentMonth.month());
  }
  currentMonth = dayjs().add(1, 'month');
  while (monthsArr.length < noOfMonths) {
    monthsArr.unshift(currentMonth.month());
    currentMonth = currentMonth.add(1, 'month');
  }
  return monthsArr;
};
