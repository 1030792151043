import React, { useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Datatable from '../Datatable/Datatable';
import { columns, initialSort } from './EmpDocTableConfig';
import { generateDeleteColumn } from '../Datatable/datatableUtils';
import { OTHERS, PERSONAL, PROFESSIONAL } from '../../utils/docTypes';
import MisDialog from '../MisDialog/MisDialog';
import { PATHS as onBoardingPaths } from '../../api/OnBoarding/onboardingAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';

interface EmpDocTableProps {
  rows: any;
  onDocRemove?: Function;
  employeeUid?: any;
  id: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS;
  usedBy?: 'others' | 'profile';
}

const EmpDocTable: React.FC<EmpDocTableProps> = ({
  rows,
  onDocRemove,
  employeeUid,
  id,
  usedBy,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState('');

  const { checkAccess } = useContext(GlobalContext);

  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteDocumentId(params.id);
      },
    },
  ];

  const getTableColumns = () => {
    return [...columns, ...generateDeleteColumn(rowActions)];
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteDocumentId) {
      onDocRemove?.(deleteDocumentId, id);
    }
    setDeleteDocumentId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteDocumentId('');
  };

  return (
    <>
      <Datatable
        columns={getTableColumns()}
        rows={rows}
        initialSort={initialSort as any}
        tableHeight="230px"
      />
      <MisDialog
        title="Document"
        message="Are you sure you want to delete this document?"
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

EmpDocTable.defaultProps = {
  onDocRemove: () => {},
  employeeUid: null,
  usedBy: 'others',
};

export default EmpDocTable;
