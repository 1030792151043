import dayjs from 'dayjs';
import * as Yup from 'yup';

export const TRANSPORTATIONYEARLY = 19200;
export const TRANSPORTATIONMONTHLY = 1600;
export const STATUTARYBONUSYEARLY = 18000;
export const STATUTARYBONUSMONTHLY = 1500;

export const offerLetterInitialValues = {
  name: '',
  phoneNumber: '',
  addressFirstLine: '',
  addressSecondLine: '',
  email: '',
  dateOfJoining: dayjs().format('YYYY-MM-DD'),
  designation: '',
  isBonusGiven: false,
  basicMonthly: 0,
  basicYearly: 0,
  hraMonthly: 0,
  hraYearly: 0,
  transportMonthly: TRANSPORTATIONMONTHLY,
  transportYearly: TRANSPORTATIONYEARLY,
  statutaryBonusMonthly: STATUTARYBONUSMONTHLY,
  statutaryBonusYearly: STATUTARYBONUSYEARLY,
  fixedAllowanceMonthly: 0,
  fixedAllowanceYearly: 0,
  specialPerformancePayMonthly: 0,
  specialPerformancePayYearly: 0,
  epfMonthly: 0,
  epfYearly: 0,
  totalMonthly: 0,
  yearlySalary: 0,
  gratuityMonthly: 0,
  gratuityYearly: 0,
  joiningBonusMonthly: 'NA',
  joiningBonusYearly: 0,
  anniversaryBonusMonthly: 'NA',
  anniversaryBonusYearly: 0,
  targetAchievementBonusMonthly: 'NA',
  targetAchievementBonusYearly: 0,
  individualPerformanceBonusMonthly: 'NA',
  individualPerformanceBonusYearly: 0,
  startDate: dayjs().format('YYYY-MM-DD'),
  designationName: '',
  isPromoted: false,
};

export const internshipLetterInitialValues = {
  name: '',
  phoneNumber: '',
  addressFirstLine: '',
  addressSecondLine: '',
  email: '',
  dateOfJoining: dayjs().format('YYYY-MM-DD'),
  designation: '',
  endDate: dayjs().format('YYYY-MM-DD'),
  stipend: 0,
};

export const OfferLetterFormValidation = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  phoneNumber: Yup.string().required('Phone Number is Required'),
  addressFirstLine: Yup.string()
    .min(4, 'Address Line 1 should have atleast 4 characters')
    .required('Address Line 1 is Required'),
  addressSecondLine: Yup.string().nullable(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  dateOfJoining: Yup.date().required('Date of Joining is required'),
  designation: Yup.string().required('Designation is Required'),
  basicMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  basicYearly: Yup.number()
    .min(0, 'Amount cannot be negative')
    .required('Basic Salary is Required'),
  hraMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  hraYearly: Yup.number().min(0, 'Amount cannot be negative').required('HRA is Required'),
  transportMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  transportYearly: Yup.number()
    .min(0, 'Amount cannot be negative')
    .required('Transportation is Required'),
  statutaryBonusMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  statutaryBonusYearly: Yup.number()
    .min(0, 'Amount cannot be negative')
    .required('Statutory Bonus is Required'),
  fixedAllowanceMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  fixedAllowanceYearly: Yup.number()
    .min(0, 'Amount cannot be negative')
    .required('Fixed Allowance is Required'),
  specialPerformancePayMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  specialPerformancePayYearly: Yup.number()
    .min(0, 'Amount cannot be negative')
    .required('Special Performance Pay is Required'),
  epfMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  epfYearly: Yup.number().min(0, 'Amount cannot be negative').required('Employer PF is Required'),
  totalMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  yearlySalary: Yup.number()
    .min(1, 'Yearly Salary should be greater than 0')
    .required('Yearly Salary is Required'),
  gratuityMonthly: Yup.number().min(0, 'Amount cannot be negative'),
  gratuityYearly: Yup.number().min(0, 'Amount cannot be negative'),
  isPromoted: Yup.boolean().nullable(),
  // department: Yup.object()
  //   .shape({ label: Yup.string(), code: Yup.string() })
  //   .when('isPromoted', {
  //     is: true,
  //     then: Yup.object().typeError('Department is required'),
  //     otherwise: Yup.object().nullable(),
  //   }),
  // track: Yup.object()
  //   .shape({ label: Yup.string(), code: Yup.string() })
  //   .when('isPromoted', {
  //     is: true,
  //     then: Yup.object().typeError('Track is required'),
  //     otherwise: Yup.object().nullable(),
  //   }),
  // designationName: Yup.object()
  //   .shape({ label: Yup.string(), code: Yup.string() })
  //   .when('isPromoted', {
  //     is: true,
  //     then: Yup.object().typeError('Designaiton is required'),
  //     otherwise: Yup.object().nullable(),
  //   }),
  // startDate: Yup.string().when('isPromoted', {
  //   is: true,
  //   then: Yup.string().required('Effective Date is required'),
  //   otherwise: Yup.number().nullable(),
  // }),
});

export const internshipLetterFormValidation = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  phoneNumber: Yup.string().required('Phone Number is Required'),
  addressFirstLine: Yup.string()
    .min(5, 'Address Line 1 should have atleast 5 characters')
    .required('Address Line 1 is Required'),
  addressSecondLine: Yup.string().nullable(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  dateOfJoining: Yup.date().required('Date of Joining is required'),
  designation: Yup.string().required('Designation is Required'),
  endDate: Yup.date().required('Last Date is required'),
  stipend: Yup.number().min(1, 'Stipend should be greater than 0').required('Stipend is Required'),
});

const roundOffValues = (value: number) => Math.round(value);

export const getMonthlySalary = (amount: number) => roundOffValues(amount / 12);

let yearSalary = 0;
export const getYearlySalary = (yearlySal: number) => {
  yearSalary = yearlySal;
};

export const handleCustomInputChange = (name: string, value: number, setFieldValue: any) => {
  let hra = 0;
  let pf = 0;
  let gratuity = 0;
  let basicYearly = 0;
  let specialPerformancePayYearly = 0;

  switch (name) {
    case 'yearlySalary':
      yearSalary = value;
      setFieldValue('totalMonthly', getMonthlySalary(value));
      basicYearly = roundOffValues((value - value / 3) / 2); // Calculate basicYearly as the half of substraction from yearly salary to special performance pay.
      setFieldValue('basicYearly', basicYearly);
      setFieldValue('basicMonthly', getMonthlySalary(basicYearly));

      specialPerformancePayYearly = roundOffValues(value / 3);
      setFieldValue('specialPerformancePayYearly', specialPerformancePayYearly);
      setFieldValue('specialPerformancePayMonthly', getMonthlySalary(specialPerformancePayYearly));

      hra = roundOffValues(basicYearly * 0.5); // HRA is 50% of Basic
      setFieldValue('hraYearly', hra);
      setFieldValue('hraMonthly', getMonthlySalary(hra));

      pf = roundOffValues(basicYearly * 0.12); // PF is 12% of Basic
      setFieldValue('epfYearly', pf);
      setFieldValue('epfMonthly', getMonthlySalary(pf));

      gratuity = roundOffValues(basicYearly * 0.0481); // gratuity is 4.81% of Basic
      setFieldValue('gratuityYearly', gratuity);
      setFieldValue('gratuityMonthly', getMonthlySalary(gratuity));
      break;

    case 'specialPerformancePayYearly':
      setFieldValue('specialPerformancePayMonthly', getMonthlySalary(value));
      basicYearly = roundOffValues((yearSalary - value) / 2); // Calculate basicYearly as the half of substraction from yearly salary to special performance pay.
      setFieldValue('basicYearly', basicYearly);
      hra = roundOffValues(basicYearly * 0.5); // HRA is 50% of Basic
      pf = roundOffValues(basicYearly * 0.12); // PF is 12% of Basic
      gratuity = roundOffValues(basicYearly * 0.0481); // gratuity is 4.81% of Basic
      setFieldValue('basicMonthly', getMonthlySalary(basicYearly));

      // Set HRA
      setFieldValue('hraYearly', hra);
      setFieldValue('hraMonthly', getMonthlySalary(hra));

      // Set PF
      setFieldValue('epfYearly', pf);
      setFieldValue('epfMonthly', getMonthlySalary(pf));

      // Set Gratuity
      setFieldValue('gratuityYearly', gratuity);
      setFieldValue('gratuityMonthly', getMonthlySalary(gratuity));
      // calculateFixedAllowance();
      break;

    case 'epfYearly':
      setFieldValue('epfMonthly', getMonthlySalary(value));
      // calculateFixedAllowance();
      break;

    default:
  }
};

export const formatOfferLetterData = (values: any) => {
  const {
    name,
    phoneNumber,
    addressFirstLine,
    addressSecondLine,
    email,
    dateOfJoining,
    designation,
    isBonusGiven,
    yearlySalary,
    basicMonthly,
    basicYearly,
    hraMonthly,
    hraYearly,
    transportMonthly,
    transportYearly,
    statutaryBonusMonthly,
    statutaryBonusYearly,
    fixedAllowanceMonthly,
    fixedAllowanceYearly,
    specialPerformancePayMonthly,
    specialPerformancePayYearly,
    epfMonthly,
    epfYearly,
    totalMonthly,
    totalYearly,
    gratuityMonthly,
    gratuityYearly,
    joiningBonusMonthly,
    joiningBonusYearly,
    anniversaryBonusMonthly,
    anniversaryBonusYearly,
    targetAchievementBonusMonthly,
    targetAchievementBonusYearly,
  } = values;

  return {
    offer_leter_details: {
      name,
      phoneNumber,
      addressFirstLine,
      addressSecondLine,
      email,
      dateOfJoining,
      designation,
      isBonusGiven,
      yearlySalary,
    },
    salary_details: {
      basicMonthly,
      basicYearly,
      hraMonthly,
      hraYearly,
      transportMonthly,
      transportYearly,
      statutaryBonusMonthly,
      statutaryBonusYearly,
      fixedAllowanceMonthly,
      fixedAllowanceYearly,
      specialPerformancePayMonthly,
      specialPerformancePayYearly,
      epfMonthly,
      epfYearly,
      totalMonthly,
      totalYearly,
      gratuityMonthly,
      gratuityYearly,
      joiningBonusMonthly,
      joiningBonusYearly,
      anniversaryBonusMonthly,
      anniversaryBonusYearly,
      targetAchievementBonusMonthly,
      targetAchievementBonusYearly,
    },
  };
};

export const formatIntershipLetterData = (values: any) => {
  const {
    addressFirstLine,
    addressSecondLine,
    dateOfJoining,
    designation,
    email,
    endDate,
    name,
    phoneNumber,
    stipend,
  } = values;
  return {
    internship_details: { stipend, endDate },
    candidate_details: {
      addressFirstLine,
      addressSecondLine,
      dateOfJoining,
      designation,
      email,
      name,
      phoneNumber,
    },
  };
};

export const DocTypes = {
  OFFER_LETTER: 'offerLetter',
  COMPENSATION_LETTER: 'compensationLetter',
  INTERNSHIP_LETTER: 'internshipLetter',
};

export const getAppraisalFY = (currentYear: any, nextYear: any) => {
  const financialYearStartISO = new Date(Date.UTC(currentYear, 6, 1)); // July 1 of the current year
  const financialYearStart = financialYearStartISO.toISOString().split('T')[0];

  const financialYearEndISO = new Date(Date.UTC(nextYear, 5, 30)); // June 30 of the next year
  const financialYearEnd = financialYearEndISO.toISOString().split('T')[0];

  return { financialYearStart, financialYearEnd };
};

export const getMinimumValueForFY = () => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const YearStart = new Date(Date.UTC(previousYear, 6, 1)).toISOString().split('T')[0]; // July 1 of the previous year
  const YearEnd = new Date(Date.UTC(currentYear, 5, 30)).toISOString().split('T')[0]; // June 30 of the current year

  return { startDate: YearStart, endDate: YearEnd };
};

export const getSalaryRevisionDates = (salaryRevisionYear: any) => {
  const startYear = salaryRevisionYear - 1;
  const startDate = new Date(startYear, 6, 1); // July 1st of the previous year
  const endDate = new Date(salaryRevisionYear, 5, 30); // June 30th of the given year

  // Adjust the dates to correct the month and day
  startDate.setDate(startDate.getDate() + 1);
  endDate.setDate(endDate.getDate() + 1);

  // Format dates as 'YYYY-MM-DD'
  const formatDate = (date: any) => date.toISOString().split('T')[0];

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
};
