import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import ModeCommentIcon from '@mui/icons-material/ModeComment';

interface CommentProps {
  updateProcessedRows: Function;
  contextMenuEl?: any;
  setContextMenuEl: Function;
  currentCell: any;
}

const Comment: React.FC<CommentProps> = ({
  contextMenuEl,
  setContextMenuEl,
  currentCell,
  updateProcessedRows,
}) => {
  const [currentComment, setCurrentComment] = React.useState('');
  const [commentPopupEl, setCommentPopupEl] = React.useState<HTMLElement | null>(null);
  const openContextMenu = Boolean(contextMenuEl);
  const openComment = Boolean(commentPopupEl);
  const comment = currentCell && currentCell.row[currentCell.field]?.comment;

  const handleCommentPopup = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setCommentPopupEl(event.currentTarget);
  };

  const handleComment = (action: string) => {
    const { field, row } = currentCell;
    const newRow = {
      ...row,
      [field]: { ...row[field], comment: action === 'add' ? currentComment : '' },
    };
    updateProcessedRows(newRow);
    setContextMenuEl(null);
    setCommentPopupEl(null);
  };

  return (
    <>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={contextMenuEl}
        open={openContextMenu}
        onClose={() => setContextMenuEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem onClick={handleCommentPopup}>{`${
          comment ? 'Edit Comment' : 'Add Comment'
        }`}</MenuItem>
      </Menu>
      <Popover
        open={openComment}
        anchorEl={commentPopupEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setCommentPopupEl(null)}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <ModeCommentIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label={`${comment ? 'Edit comment' : 'Add a comment'}`}
              variant="standard"
              multiline
              defaultValue={comment}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleComment('add');
                }
              }}
              onChange={(event) => setCurrentComment(event.target.value)}
            />
            {comment && (
              <DeleteIcon
                sx={{ color: 'action.active', my: 1, mr: 0.5, cursor: 'pointer' }}
                onClick={() => handleComment('delete')}
              />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

Comment.defaultProps = {
  contextMenuEl: null,
};

export default Comment;
