import { Button, Grid } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';

const AddPolicyForm: React.FC<any> = ({ handleSubmit }: any) => {
  const validationSchema = Yup.object().shape({
    policyTitle: Yup.string().required('Policy title is required'),
    documentFile: Yup.mixed()
      .test('fileType', 'Please upload a PDF file.', function (value) {
        if (!value) {
          return true;
        }
        const acceptedFiles = '.pdf';
        const fileExtension = value.name.split('.').pop().toLowerCase();
        return acceptedFiles.includes(`.${fileExtension}`);
      })
      .required('Please upload a file.'),
  });
  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { setFieldValue, dirty, isValid } = formik;
          return (
            <>
              <Form>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="policyTitle"
                      key="gender"
                      label="Title"
                      size="small"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name="policy"
                      label="Upload document"
                      variant="standard"
                      type="file"
                      onChange={(evt: any) => {
                        setFieldValue(`documentFile`, evt.currentTarget.files[0]);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: '.pdf' }}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                  Upload
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddPolicyForm;
