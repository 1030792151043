import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Modal } from '@mui/material';
import _ from 'lodash';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SidePanel from '../SidePanel/SidePanel';
import useempDocTableStyles from './EmpDocTableStyle';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import MasterClient from '../../api/Master/masterAPIs';
import { DocType } from '../../api/Master/masterTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import SimpleInputField from '../SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../SimpleAutocompleteField/SimpleAutocompleteField';
import OnboardingClient from '../../api/OnBoarding/onboardingAPIs';
import { DocTypesLabel, OTHERS, PERSONAL, PROFESSIONAL } from '../../utils/docTypes';
import EmployeeClient from '../../api/Employee/employeeAPIs';

interface EmpDocTableFormProps {
  employeeUid: string;
  onFormUpload: Function;
  onSave?: Function;
  currentCategory: any;
  usedBy: 'others' | 'profile';
}

const validationSchema = Yup.object().shape({
  docType: Yup.object().required('Select Document Type'),
});

const EmpDocTableForm: React.FC<EmpDocTableFormProps> = ({
  employeeUid,
  onFormUpload,
  currentCategory,
  onSave,
  usedBy,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [filteredDocTypes, setFilteredDocTypes] = useState<DocType[]>([]);
  const [isLoading, setLoading] = useState(false);

  const empDocTableStyles = useempDocTableStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const toggle = () => setOpen(!open);

  const getDocumentTypes = () => {
    setLoading(true);
    MasterClient.getDocumentTypes()
      .then((data) => {
        setDocTypes(
          data.map((doc: any) => ({ label: DocTypesLabel[doc.code], value: doc.uid, ...doc })),
        );
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getDocumentTypes();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setFilteredDocTypes(docTypes.filter((doc: any) => doc.category === currentCategory.id));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentCategory, docTypes]);

  const handleSubmitSuccess = (res: any) => {
    showSnackbar(res, 'success');
    onFormUpload();
  };

  const handleSubmit = (values: any) => {
    const documentType = docTypes.find((doc: any) => doc.code === values.docType.code);
    const file = values.documentFile;
    const empDoc = {
      documentTypeId: documentType?.uid,
    };

    const formData = new FormData();
    formData.append('emp_doc', JSON.stringify(empDoc));
    formData.append('file', file);
    if (employeeUid) {
      setLoading(true);

      if (usedBy === 'others') {
        switch (currentCategory.id) {
          case PROFESSIONAL:
            OnboardingClient.addEmployeeProfessionalDocs(employeeUid, formData)
              .then((res: any) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          case PERSONAL:
            OnboardingClient.addEmployeePersonalDocs(employeeUid, formData)
              .then((res: any) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          case OTHERS:
            OnboardingClient.addEmployeeCertificates(employeeUid, formData)
              .then((res: any) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          default:
        }
      } else {
        switch (currentCategory.id) {
          case PROFESSIONAL:
            EmployeeClient.addEmployeeProfessionalDocs(formData)
              .then((res) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          case PERSONAL:
            EmployeeClient.addEmployeePersonalDocs(formData)
              .then((res) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          case OTHERS:
            EmployeeClient.addEmployeeCertificates(formData)
              .then((res) => handleSubmitSuccess(res))
              .catch((e: any) => showSnackbar(e, 'error'))
              .finally(() => {
                setLoading(false);
                toggle();
              });
            break;

          default:
        }
      }
    } else {
      const rowData = {
        documentName: file.name,
        documentType: documentType?.code,
        category: currentCategory.id,
        uid: documentType?.uid,
        id: documentType?.uid,
        status: 'In Progress',
      };
      onSave?.({ documentType, file, id: documentType?.uid, category: currentCategory.id });
      toggle();
    }
  };

  return (
    <>
      <div className={empDocTableStyles.addButton}>
        <Box
          className={`${empDocTableStyles.iconbutton} ${empDocTableStyles.button}`}
          onClick={toggle}>
          <Box>
            <AddIcon />
          </Box>
        </Box>
      </div>
      <Modal open={open} onClose={toggle}>
        <Box>
          <SidePanel header="Add document" onClose={toggle}>
            <div className={empDocTableStyles.formContainer}>
              <Formik
                initialValues={{}}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize>
                {(formik) => {
                  const { values, setFieldValue, dirty, isValid } = formik;
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SimpleAutocompleteField
                            name="docType"
                            key="docType"
                            label="Document Type"
                            variant="standard"
                            disableClearable
                            setFieldValue={setFieldValue}
                            options={filteredDocTypes}
                            values={values}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleInputField
                            name="document"
                            label="Document"
                            variant="standard"
                            type="file"
                            onChange={(evt: any) =>
                              setFieldValue(`documentFile`, evt.currentTarget.files[0])
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ accept: 'application/pdf' }}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                      <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                        Save
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
              {isLoading && <ProgressSpinner showSpinner={isLoading} />}
            </div>
          </SidePanel>
        </Box>
      </Modal>
    </>
  );
};

EmpDocTableForm.defaultProps = {
  onSave: () => {},
};

export default EmpDocTableForm;
