import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Datatable from '../../components/Datatable/Datatable';
import { getColumns, initialSort, invoiceResourceIdName } from './invoiceResourcesTableConifig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { OtherDetail, ProjectResource, ShareInvoiceParams } from '../../api/Invoice/invoiceTypes';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { ApiOperations, currencyFormatter } from '../../utils/utils';
import InvoiceClient, { PATHS as invoicePaths } from '../../api/Invoice/invoiceAPIs';
import { InvoiceStatus } from './invoiceTableConfig';
import { GlobalContext } from '../../contexts/GlobalContext';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import useAllInvoicesStyles from './AllInvoicesStyles';
import MisDialog from '../../components/MisDialog/MisDialog';
import { UpdateProjectResourceRevenueParams } from '../../api/Revenue/revenueTypes';
import RevenueClient, { PATHS as revenuePaths } from '../../api/Revenue/revenueAPIs';
import CustomDateForm from './CustomDateForm';

interface ResourceListProps {
  readonly invoiceResourceList: ProjectResource[];
  readonly invoiceDetail: {
    projectUid: string;
    preSignedUrl: string;
    invoiceNumber: string;
    invoiceUid: string;
    invoiceStatus: string;
    fileName: string;
    currency: string;
    invoiceStartDate: string;
    invoiceEndDate: string;
  };
  readonly selectedMonth: any;
  readonly updateFetch: () => void;
  readonly setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly otherDetails: OtherDetail[];
}

const InvoiceResources: React.FC<ResourceListProps> = ({
  invoiceResourceList,
  invoiceDetail,
  updateFetch,
  selectedMonth,
  setIsLoading,
  otherDetails,
}) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [showCustomDateDialog, setShowCustomDateDialog] = useState(false);
  const [customDateState, setcustomDateState] = useState({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });
  const [confirmShareInfo, setConfirmShareInfo] = useState<any>();
  const allInvoicesStyles = useAllInvoicesStyles();

  // Indicates which button is disabled
  const [disabledActionState, setDisabledActionState] = useState<Record<string, boolean>>({
    generate: true,
    download: true,
    send: true,
  });
  const [dailog, setDailog] = useState({
    display: false,
    title: '',
    message: '',
    handleSuccess: () => {},
    handleClose: () => {},
    hideSubmitBtn: false,
  });

  const params = useParams();
  const location = useLocation();
  const styles = useInvoiceDetailStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);

  const queryParams = queryString.parse(location?.search);

  const calculateSelectedMonthRevenue = (data: any[]) => {
    const invoiceSum = data.reduce((sum, item) => {
      return sum + (item.includeInInvoice ? Number(item.billing) : 0);
    }, 0);

    const otherDetailSum = otherDetails.reduce((currrentSum, currentItem) => {
      const amount = currentItem.details.reduce((acc, detail) => acc + detail.amount, 0);
      return currrentSum + amount;
    }, 0);

    return invoiceSum + otherDetailSum;
  };

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], invoiceResourceIdName);
    return rows;
  };

  useEffect(() => {
    const rows: any = getTableRows(invoiceResourceList);
    const updateRows = rows.map((v: any) => {
      const resourceName = v?.resourceName;
      const comment = v?.comment || '';
      return {
        ...v,
        resourceName: { resourceName, comment },
      };
    });
    setTableRows(updateRows);
    setTotalRevenue(calculateSelectedMonthRevenue(rows));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [invoiceResourceList]);

  useEffect(() => {
    let updatedActionState = {};

    switch (invoiceDetail.invoiceStatus) {
      case InvoiceStatus.FINALISED:
        updatedActionState = {
          generate: false,
          download: true,
          send: true,
        };
        break;

      case InvoiceStatus.GENERATED:
        updatedActionState = {
          generate: false,
          download: false,
          send: false,
        };
        break;

      case InvoiceStatus.SHARED:
        updatedActionState = {
          generate: true,
          download: false,
          send: true,
        };
        break;

      default:
        updatedActionState = { ...disabledActionState };
    }

    setDisabledActionState(updatedActionState);
  }, [invoiceDetail]);

  const handleCheckboxChange = (e: any) => setHasCustomDate(e.target.checked);

  const downloadInvoice = (url: string, fileName: string) => {
    const downloadInvoiceParams = {
      preSignedUrl: url,
    };
    return InvoiceClient.downloadInvoice(downloadInvoiceParams)
      .then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        link.download = fileName;
        link.click();
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  const isInvalid = (updatedRows: any) =>
    otherDetails.length === 0 &&
    !updatedRows.some((item: any) => item.row.includeInInvoice) &&
    !invoiceResourceList
      .filter(
        (item) =>
          updatedRows.find((el: any) => el.row.employeeUid === item.employeeUid) === undefined,
      )
      .some((item) => item.includeInInvoice);

  const handleDialogClose = () =>
    setDailog({
      display: false,
      title: '',
      message: '',
      handleSuccess: () => {},
      handleClose: () => {},
      hideSubmitBtn: true,
    });

  const showErrorDialog = () =>
    setDailog({
      display: true,
      title: intl.formatMessage({
        id: I18nKey.OTHER_DETAILS_INVALID_OP,
      }),
      message: intl.formatMessage({
        id: I18nKey.OTHER_DETAILS_UPDATE_ERR_MSG,
      }),
      handleSuccess: () => handleDialogClose(),
      handleClose: () => handleDialogClose(),
      hideSubmitBtn: true,
    });

  const updateIncludedResouces = (updatedRows: any) => {
    setIsLoading(true);
    const updateresourceRevenues: UpdateProjectResourceRevenueParams[] = updatedRows.map(
      (updatedRow: any) => ({
        employeeUid: updatedRow.row?.employeeUid,
        billingDate: selectedMonth.startOf('month').format('YYYY-MM-DD'),
        includeInInvoice: updatedRow.row?.includeInInvoice,
        remarks: updatedRow.row?.resourceName?.comment,
      }),
    );

    RevenueClient.updateProjectResourceRevenue(params.id || '', updateresourceRevenues)
      .then((result) => {
        showSnackbar(result, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        updateFetch();
        setIsLoading(false);
      });
  };

  const handleInvoiceGenerate = () => {
    setIsLoading(true);
    InvoiceClient.generateInvoice(
      invoiceDetail.projectUid,
      selectedMonth.endOf('month').format('YYYY-MM-DD'),
      hasCustomDate ? customDateState : {},
    )
      .then((downloadResult) => {
        downloadInvoice(downloadResult.data.url, downloadResult.data.fileName);
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleInvoiceDownload = () => {
    if (!disabledActionState.download) {
      setIsLoading(true);
      downloadInvoice(invoiceDetail.preSignedUrl, invoiceDetail.fileName).finally(() =>
        setIsLoading(false),
      );
    }
  };

  const onSendInvoiceConfirm = () => {
    handleDialogClose();
    if (!disabledActionState.send) {
      setIsLoading(true);
      const shareInvoiceParams: ShareInvoiceParams = {
        invoiceUid: invoiceDetail.invoiceUid,
      };

      InvoiceClient.shareInvoice(shareInvoiceParams)
        .then(() => updateFetch())
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  const handleSendInvoice = () => {
    setIsLoading(true);
    InvoiceClient.getEmailRecipients(invoiceDetail.invoiceUid)
      .then(({ toAddr, ccAddr }) => {
        const toStr = toAddr.length ? `${toAddr.join(', ')} ` : '';
        const ccStr = ccAddr.length ? `${ccAddr.join(', ')}` : '';
        setConfirmShareInfo({ toStr, ccStr });
        setDailog({
          display: true,
          title: intl.formatMessage({
            id: I18nKey.INVOICE_DETAIL_TITLE,
          }),
          message: 'Are you sure you want to send the invoice ?',
          handleSuccess: () => onSendInvoiceConfirm(),
          handleClose: () => handleDialogClose(),
          hideSubmitBtn: false,
        });
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCustomDateFormChange = (e: any) => {
    const { name, value } = e.target;

    const newState: any = { ...customDateState };
    if (name) newState[name] = value;

    setcustomDateState(newState);
  };

  const handleCustomDateInvoiceGenerate = () => setShowCustomDateDialog(true);

  const handleRowUpdate = (updatedRows: any[]) => {
    // If there are no other details and if there are no resources selected, show dialog.
    if (isInvalid(updatedRows)) showErrorDialog();
    else updateIncludedResouces(updatedRows);
  };

  return (
    <Box>
      <Box className={styles.LowerCardInfo}>
        <Box className={styles.LowerCardInfoLeftSection}>
          {`${dayjs(queryParams.selected_month as string).format('MMMM')} ${intl.formatMessage({
            id: I18nKey.INVOICE_DETAIL_TOTAL_REVENUE,
          })} : ${currencyFormatter(totalRevenue, invoiceDetail.currency)}`}
        </Box>
        <Box className={styles.LowerCardInfoRightSection}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckboxChange}
                checked={hasCustomDate}
                className={styles.LowerCardInfoRightSectionCheckbox}
              />
            }
            label="Add Custom Dates"
          />
          {checkAccess(
            invoicePaths.GENERATE_INVOICE('id', dayjs().format('YYYY-MM-DD')),
            ApiOperations.POST,
          ) && (
            <Button
              disabled={disabledActionState.generate || tableRows.length === 0}
              variant="contained"
              onClick={() => {
                if (hasCustomDate) handleCustomDateInvoiceGenerate();
                else handleInvoiceGenerate();
              }}>
              {intl.formatMessage({
                id: I18nKey.INVOICE_DETAIL_GENERATE_INVOICE,
              })}
            </Button>
          )}
          <Tooltip disableHoverListener={disabledActionState.download} title="Download">
            <DownloadIcon
              className={disabledActionState.download ? 'disable-btn' : styles.Btn}
              onClick={disabledActionState.download ? () => {} : handleInvoiceDownload}
            />
          </Tooltip>
          {checkAccess(invoicePaths.SHARE_INVOICE('id'), ApiOperations.PUT) && (
            <Tooltip disableHoverListener={disabledActionState.send} title="Send Invoice">
              <ArrowOutwardIcon
                className={disabledActionState.send ? 'disable-btn' : styles.Btn}
                onClick={disabledActionState.send ? () => {} : handleSendInvoice}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Typography className={styles.CardHeader}>
        {intl.formatMessage({
          id: I18nKey.INVOICE_DETAIL_EMPLOYEE_DETAIL,
        })}
      </Typography>
      <Datatable
        rows={tableRows}
        columns={getColumns(invoiceDetail.currency)}
        initialSort={initialSort as any}
        tableHeight="54vh"
        editable={checkAccess(revenuePaths.PUT_PROJECT_RESOURCES_REVENUE('id'), ApiOperations.POST)}
        updateRows={handleRowUpdate}
      />
      {dailog.display && (
        <MisDialog
          isOpen={dailog.display}
          title={dailog.title}
          message={dailog.message}
          handleClose={dailog.handleClose}
          handleSuccess={dailog.handleSuccess}
          additionalInfoSection={
            <Box className={allInvoicesStyles.confirmShareInfoWrapper}>
              <Box className={allInvoicesStyles.infoshareWrapper}>
                To:
                <span className={allInvoicesStyles.infoShareText}> {confirmShareInfo?.toStr}</span>
              </Box>
              <Box className={allInvoicesStyles.infoshareWrapper}>
                CC:{' '}
                <span className={allInvoicesStyles.infoShareText}>{confirmShareInfo?.ccStr}</span>
              </Box>
            </Box>
          }
          hideSubmitBtn={dailog.hideSubmitBtn}
        />
      )}
      {showCustomDateDialog && (
        <MisDialog
          isOpen={showCustomDateDialog}
          title={'Add Custom Date'}
          message={''}
          handleClose={() => setShowCustomDateDialog(false)}
          handleSuccess={() => {
            handleInvoiceGenerate();
            setShowCustomDateDialog(false);
          }}
          disableSubmitBtn={false}
          additionalInfoSection={
            <CustomDateForm
              handleFormChange={handleCustomDateFormChange}
              formState={customDateState}
            />
          }
        />
      )}
    </Box>
  );
};

export default InvoiceResources;
