import { useState, useEffect, useRef } from 'react';
import { Button, Box, Modal, Grid } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import {
  Ticket,
  categoryFormatData,
  formatEmployeeList,
  priority,
  PriorityColor,
  TicketStatus,
} from './TicketTypes';
import I18nKey from '../../translations/I18nKey';
import AddTicketForm from './AddTicketForm';
import SidePanel from '../../components/SidePanel/SidePanel';
import TicketClient from '../../api/Ticket/TicketAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import SearchClient from '../../api/Search/searchAPIs';
import MisDialog from '../../components/MisDialog/MisDialog';
import useTicketsStyles from './TicketsStyles';

const Tickets = ({
  setIsLoading,
  getTickets,
  updateFetchTicketList,
  categoryData,
  assigneeData,
}: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [fetchTicket, setFetchTicket] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState(null);
  const [ticketDeleteId, setTicketDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>({
    title: '',
    description: '',
    category: null,
    priority: null,
    assignee: null,
    dueAt: null,
    files: null,
    comments: '',
  });

  const fileInputRef = useRef<any>(null);
  const styles = useTicketsStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const updateTicketDetail = () => setFetchTicket((fetchNum) => fetchNum + 1);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setIsEdit(false);
    setSelectedTicket({
      title: '',
      description: '',
      category: null,
      priority: null,
      assignee: null,
      dueAt: null,
      files: null,
      comments: '',
    });
    setTicketId(null);
    setTicketDeleteId(null);
  };

  const getTicketDetailsById = (ticketUid: any) => {
    setIsLoading(true);
    TicketClient.getTicketDetailById(ticketUid)
      .then((result: any) => {
        const initialAssignee = result.assignee
          ? assigneeData.find(
              (obj: any) => obj.name === result.assignee.label && obj.value === result.assignee.uid,
            )
          : null;
        const initialCategory = categoryData?.find(
          (obj: any) => obj.name === result.category.label && obj.value === result.category.uid,
        );
        const initialPriority = priority.find((obj: any) => obj.value === result.priority);
        setSelectedTicket({
          title: result.title,
          description: result.description,
          category: initialCategory,
          priority: initialPriority,
          assignee: initialAssignee,
          dueAt: result.due_at?.split('T')[0],
          files: result.attachments.length > 0 ? result.attachments : null,
          comments: result.comments,
        });
        handleModalOpen();
        setIsEdit(true);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectedTicketInitialState = (list: any) => {
    getTicketDetailsById(list.uid);
    setTicketId(list.uid);
    setTicketDeleteId(list.uid);
  };

  useEffect(() => {
    if (ticketId) {
      getTicketDetailsById(ticketId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTicket]);

  const handleUpdateTicket = (ticket_uid: any, ticketDetails: any, file: any) => {
    setIsLoading(true);
    const formData = new FormData();
    const valuesData = ticketDetails;
    formData.append('ticket_details', JSON.stringify(valuesData));
    if (file && !Array.isArray(file)) {
      formData.append('files', file);
    }
    TicketClient.updateTicketDetails(ticket_uid, formData)
      .then((res: any) => {
        showSnackbar(res, 'success');
        updateTicketDetail();
        updateFetchTicketList();
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    const ticketDetails = {
      title: values.title,
      description: values.description,
      categoryUid: values.category.value,
      priority: values.priority.value,
      assigneeUid: values?.assignee?.value || null,
      dueAt: values.dueAt,
      comments: values?.comments,
    };
    const file = values.files;
    handleUpdateTicket(ticketId, ticketDetails, file);
  };
  const splitTime = (hour: number) => {
    if (hour >= 24) {
      const totaldays = hour / 24;
      return `${Math.round(totaldays)} days`;
    }
    return `${hour} hrs`;
  };

  const getPriorityColor = (priorityClr: any): string => {
    let priorityColor = '';
    switch (priorityClr) {
      case PriorityColor.HIGH:
        priorityColor = 'red';
        break;
      case PriorityColor.NORMAL:
        priorityColor = '#FF5F15';
        break;
      case PriorityColor.LOW:
        priorityColor = '#e3cc00';
        break;
      case PriorityColor.URGENT:
        priorityColor = '#e3cc00';
        break;
      default:
        break;
    }
    return priorityColor;
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setTicketDeleteId(null);
  };

  const handleDelete = () => {
    if (ticketDeleteId) {
      TicketClient.deleteTicket(ticketDeleteId)
        .then((response: any) => {
          showSnackbar(response, 'success');
          handleClose();
          updateFetchTicketList();
          setModalOpen(false);
        })
        .catch((e: any) => showSnackbar(e, 'error'));
    }
  };

  const deleteTicket = (id: any) => {
    setOpenDeleteDialog(true);
    setTicketDeleteId(id);
  };

  const getStatusTime = (list: any) => {
    let statusTimeLabel = '';
    switch (list.status) {
      case TicketStatus.BLOCKED:
        statusTimeLabel = intl.formatMessage({
          id: I18nKey.TICKETS_STATUS_TIME_TIME_TAKEN,
        });
        break;
      case TicketStatus.RESOLVED:
        statusTimeLabel = intl.formatMessage({
          id: I18nKey.TICKETS_STATUS_TIME_RESOLVED,
        });
        break;
      default:
        statusTimeLabel = intl.formatMessage({
          id: I18nKey.TICKETS_STATUS_TIME_ACTIVE,
        });
        break;
    }
    return statusTimeLabel;
  };

  return (
    <Box className={styles.ticketWrapper}>
      {getTickets.length === 0 ? (
        <Box className={styles.noTickets}>No Tickets</Box>
      ) : (
        getTickets?.map((list: any, index: number) => {
          const priorityColor = getPriorityColor(list.priority);
          return (
            <Draggable key={list.uid} draggableId={list.uid} index={index}>
              {(provided: any) => (
                <Box
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}>
                  <Box className={styles.ticketLayout}>
                    <Box className={styles.specialTypography}>
                      {'TICKET NO'} : {list.ticket_id}
                      <Box className={styles.buttonHolder}>
                        <Button
                          size="small"
                          type="submit"
                          variant="text"
                          className={styles.editIcon}
                          onClick={() => handleSelectedTicketInitialState(list)}>
                          <EditIcon sx={{ fontSize: 17 }} />
                        </Button>
                        <Button
                          size="small"
                          type="submit"
                          variant="text"
                          className={styles.deleteIcon}
                          onClick={() => deleteTicket(list.uid)}>
                          <DeleteIcon sx={{ fontSize: 17 }} />
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <Box className={styles.ticketContent}>
                        <Box style={{ textAlign: 'end' }} />

                        <Grid container>
                          <Grid item xs={12}>
                            {intl.formatMessage({
                              id: I18nKey.TICKET_TITLE,
                            })}
                            : {list.title}
                          </Grid>
                          <Grid item xs={12}>
                            {intl.formatMessage({
                              id: I18nKey.PRIORITY,
                            })}
                            :{' '}
                            <span style={{ color: priorityColor }}>
                              {list.priority.toUpperCase()}
                            </span>
                          </Grid>
                          <Grid container lineHeight={1.3}>
                            <Grid item xs={8}>
                              {intl.formatMessage({
                                id: I18nKey.RAISEDBY,
                              })}
                              : {list.reporter?.name}
                            </Grid>
                            <Grid item xs={4} className={styles.statusTimeContainer}>
                              <span className={styles.statusTime}>
                                {getStatusTime(list)}: {splitTime(list.time_taken)}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Draggable>
          );
        })
      )}

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box className={styles.formContainer}>
          <Box className={styles.sidePanel}>
            <SidePanel
              header={
                isEdit
                  ? intl.formatMessage({
                      id: I18nKey.EDIT_TICKET,
                    })
                  : intl.formatMessage({
                      id: I18nKey.CREATE_TICKET,
                    })
              }
              onClose={handleModalClose}>
              <AddTicketForm
                handleSubmit={handleSubmit}
                ticket={selectedTicket}
                isEdit={isEdit}
                categoryData={categoryData}
                assigneeData={assigneeData}
                deleteTicket={deleteTicket}
                ticketDeleteId={ticketDeleteId}
                updateTicketDetail={updateTicketDetail}
                fileInputRef={fileInputRef}
              />
            </SidePanel>
          </Box>
        </Box>
      </Modal>
      {openDeleteDialog && (
        <MisDialog
          title={intl.formatMessage({
            id: I18nKey.TICKET_MANAGEMENT_TITLE,
          })}
          message={intl.formatMessage({
            id: I18nKey.TICKETS_MIS_DIALOG_MESSAGE,
          })}
          isOpen={openDeleteDialog}
          handleSuccess={() => handleDelete()}
          handleClose={handleClose}
          actionBtnLabel={intl.formatMessage({
            id: I18nKey.TICKETS_MIS_DIALOG_DELETE,
          })}
        />
      )}
    </Box>
  );
};
export default Tickets;
