import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useDefaultLayoutStyles = makeStyles((theme: Theme) => {
  return {
    InnnerContainer: {
      backgroundColor: theme.palette.appBackground?.main,
      minHeight: 'calc(100vh - 64px - 25px)',
      height: 'max-content',
    },
  };
});

export default useDefaultLayoutStyles;
