import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMisTabsStyles from './MisTabsStyles';

interface EntityTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

interface TabsComponentProps {
  readonly tabs: EntityTabs[];
  readonly selected?: number | null;
  readonly onTabChange?: Function;
}

const MisTabs: React.FC<TabsComponentProps> = ({ tabs, selected, onTabChange }) => {
  const [currentTab, setCurrentTab] = useState(selected || 0);
  const styles = useMisTabsStyles();

  useEffect(() => setCurrentTab(selected || 0), [selected]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    onTabChange?.(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className={styles.tabs}>
        {tabs.map((tab: any, index: number) => {
          return <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />;
        })}
      </Tabs>
      {tabs.map((tab: any, index: number) => {
        return (
          <Box
            key={tab.label}
            role="tabpanel"
            hidden={currentTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}>
            {currentTab === index && (
              <Box>
                <div>{tab.component}</div>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

MisTabs.defaultProps = {
  selected: 0,
  onTabChange: () => {},
};

export default MisTabs;
