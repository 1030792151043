import React from 'react';
import { Field } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { FieldDataProps, SelectionOption } from '../Form/FormTypes';
import { createFieldProperties, extractFieldOptions } from '../Form/FormUtils';

let items: SelectionOption[] = [];

const TelInputField: React.FC<FieldDataProps> = ({
  name,
  label,
  properties,
  required = false,
  setFieldValue,
  sx,
  values,
  placeholder,
}) => {
  items = extractFieldOptions(properties);
  const propertiesOnField = createFieldProperties(properties, {});

  const handleChange = (newValue: string, info: any) => {
    setFieldValue?.(name, info?.numberValue);
  };

  return (
    <Field name={name} id={name}>
      {() => {
        return (
          <MuiTelInput
            {...propertiesOnField}
            sx={sx}
            label={label}
            placeholder={placeholder}
            value={values[name]}
            onChange={handleChange}
            required={required}
            forceCallingCode
            splitCallingCode
            focusOnSelectCountry
          />
        );
      }}
    </Field>
  );
};

export default TelInputField;
