import React from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { FieldArray } from 'formik';
import { AddCircleOutlined, CloseOutlined } from '@mui/icons-material';
import useAddEmployeeStyles from './NewCandidateStyles';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';

interface ContactRelationProps {
  relations: any;
  formik: any;
}

const ContactRelation: React.FC<ContactRelationProps> = ({ relations, formik }) => {
  const { values, setFieldValue } = formik;
  const styles = useAddEmployeeStyles();
  return (
    <FieldArray
      name="relationData"
      render={(arrayHelpers) => {
        return (
          <>
            {values.relationData.map((item: any, index: number) => (
              <>
                <Box className={styles.dataBox}>
                  <Grid container rowSpacing={2} spacing={1}>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`relationData[${index}].firstName`}
                        type="string"
                        label="First Name"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`relationData[${index}].middleName`}
                        type="string"
                        label="Middle Name"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`relationData[${index}].lastName`}
                        type="string"
                        label="Last Name"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        fullWidth
                        id="relation"
                        size="small"
                        disableClearable
                        options={relations}
                        value={values.relationData[index].relation}
                        onChange={(event, value) => {
                          setFieldValue(`relationData[${index}].relation`, value);
                        }}
                        getOptionLabel={(option) => option.code}
                        renderInput={(params) => (
                          <TextField {...params} label="Relation" required />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SimpleInputField
                        name={`relationData[${index}].dob`}
                        type="date"
                        label="Date Of Birth"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={4}>
                          <SimpleCheckboxField
                            name={`relationData[${index}].isDependant`}
                            checked={item.isDependant}
                            inputProps={{ style: { fontSize: 13 } }}
                            setFieldValue={setFieldValue}
                            label="Is Dependent ?"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SimpleCheckboxField
                            name={`relationData[${index}].isNominee`}
                            checked={item.isNominee}
                            inputProps={{ style: { fontSize: 13 } }}
                            setFieldValue={setFieldValue}
                            label="Is Nominee ?"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SimpleInputField
                            name={`relationData[${index}].nomineePercent`}
                            disabled={!item.isNominee}
                            type="number"
                            label="Nominee %"
                            size="small"
                            fullWidth
                            required={item.isNominee}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    size="small"
                    className={styles.dataRemoveBtn}
                    sx={{ position: 'absolute' }}
                    onClick={() => arrayHelpers.remove(index)}>
                    <CloseOutlined />
                  </Button>
                </Box>
              </>
            ))}
            <Button
              className={styles.addBtn}
              onClick={() =>
                arrayHelpers.push({
                  firstName: '',
                  lastName: '',
                  middleName: '',
                  dob: '',
                  isNominee: false,
                  isDependant: false,
                  relation: null,
                })
              }>
              <AddCircleOutlined />
            </Button>
          </>
        );
      }}
    />
  );
};

export default ContactRelation;
