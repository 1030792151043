import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useIntl } from 'react-intl';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useHomeStyles from './HomeStyles';
import { tableData } from './utils';
import I18nKey from '../../translations/I18nKey';

const HolidayList: React.FC = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useHomeStyles();
  const intl = useIntl();

  useEffect(() => {
    setIsLoading(true);
    setTableRows(tableData);
    setIsLoading(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.holidayWrapper}>
        <Box className={styles.holidayHeader}>
          <span>{intl.formatMessage({ id: I18nKey.HOLIDAYLIST })}</span>
        </Box>
        <TableContainer className={styles.tableWrapper} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeadWrapper}>Sr. No.</TableCell>
                <TableCell className={styles.tableHeadWrapper}>Day</TableCell>
                <TableCell className={styles.tableHeadWrapper}>Event</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any) => (
                <TableRow key={row.SrNo}>
                  <TableCell className={styles.rowData}>{row.SrNo}</TableCell>
                  <TableCell className={styles.rowData}>{row.Day}</TableCell>
                  <TableCell className={styles.rowData}>{row.Event}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default HolidayList;
