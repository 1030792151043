import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import useProfileStyles from './ProfileStyles';
import EmployeeClient from '../../api/Employee/employeeAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../contexts/GlobalContext';
import ToDoList from './components/ToDoList';
import MySkills from './components/MySkills';
import RelationsList from './components/RelationsList';
import ProfileSideCard from '../../components/ProfileSideCard/ProfileSideCard';
import Document from './components/Document';
import MyTeam from './components/MyTeam';

const Profile: React.FC = () => {
  const classes = useProfileStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const { employeeUid } = useContext(GlobalContext);
  const [toDoList, setToDoList] = useState<any>([]);
  const [skills, setSkillsList] = useState<any>([]);
  const [insuranceeList, setInsuranceeList] = useState<any>([]);
  const [nomineeList, setNomineeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEmployeeRelations = () => {
    setIsLoading(true);
    if (employeeUid)
      EmployeeClient.getEmployeeRelation()
        .then((result) => {
          setInsuranceeList(result.data.filter((res: any) => res.isDependant));
          setNomineeList(result.data.filter((res: any) => res.isNominee));
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
  };

  const getEmployeeSkills = () => {
    if (employeeUid)
      EmployeeClient.getEmployeeSkills()
        .then((result) => {
          setSkillsList(result.data);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        });
  };

  const getEmployeeTodos = () => {
    if (employeeUid)
      EmployeeClient.getEmployeeTodos()
        .then((result) => {
          const { data } = result;
          const todoList = data.map((item: any) => {
            return {
              ...item,
              task: item.todo,
              completed: item.status === 'completed',
            };
          });
          setToDoList(todoList);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        });
  };

  useEffect(() => {
    getEmployeeRelations();
    getEmployeeSkills();
    getEmployeeTodos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={classes.PageWrapper}>
        <Grid container spacing={1}>
          <Grid item md={3} sm={12} xs={12}>
            <ProfileSideCard />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.MidSectionContainer}>
              <Box className={classes.MyTeamWrapper}>
                <MyTeam />
              </Box>
              <Document employeeUid={employeeUid} />
            </Box>
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <Box className={classes.todoListWrapper}>
                  <ToDoList
                    toDoList={toDoList}
                    setToDoList={setToDoList}
                    getToDoList={getEmployeeTodos}
                    isProfile
                  />
                </Box>
              </Grid>
              <Grid item>
                <MySkills
                  skills={skills}
                  setSkillsList={setSkillsList}
                  refetchList={getEmployeeSkills}
                />
              </Grid>
              <Grid item>
                <RelationsList
                  relationsList={insuranceeList}
                  refetchList={getEmployeeRelations}
                  category={'insurancee'}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <RelationsList
                  relationsList={nomineeList}
                  refetchList={getEmployeeRelations}
                  category={'nominee'}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
