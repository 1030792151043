import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'dateOfJoining', sort: 'asc' };

const columns = [
  {
    field: 'primarySkills',
    type: 'string',
    headerName: 'Primary Skill(s)',
    flex: 1.5,
    sortable: true,
    hide: true,
    renderCell: (params: GridCellParams) => {
      return params?.value?.map((skill: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="employee-skill" key={skill + index}>
          {skill}
        </div>
      ));
    },
  },
  {
    field: 'otherSkills',
    type: 'string',
    headerName: 'Other Skill(s)',
    flex: 1.5,
    sortable: true,
    hide: true,
    renderCell: (params: GridCellParams) => {
      return params?.value?.map((skill: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="employee-skill" key={skill + index}>
          {skill}
        </div>
      ));
    },
  },
  {
    field: 'assigned',
    type: 'string',
    headerName: 'Assigned',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'completed',
    type: 'string',
    headerName: 'Completed',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
];

const getColumns = (selectedList: any, selectedTab: string) => {
  const cols = [
    {
      field: 'interviewerName',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
      // renderCell: (params: GridRenderCellParams) => {
      //   return (
      //     <Link
      //       to={`/interview/${params.row.interviewerUid}?selected_list=${selectedList}&selected_tab=${selectedTab}`}>
      //       {params.formattedValue}
      //     </Link>
      //   );
      // },
    },
    ...columns,
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   cellClassName: 'delete-action',
    //   flex: 0.5,
    //   hideable: false,
    //   getActions: (params: any) =>
    //     rowActions.map((action: any) => {
    //       return (
    //         <Tooltip title={action.label}>
    //           <GridActionsCellItem
    //             key={action.label}
    //             icon={action.logo}
    //             label={action.label}
    //             onClick={() => action.callback(params)}
    //           />
    //         </Tooltip>
    //       );
    //     }),
    // },
  ];
  return cols;
};

const getInitialVisibilityModel = ({ showColumns }: any, selectedList: any, selectedTab: any) => {
  const colRange = getColumns(selectedList, selectedTab);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const interviewerUid = 'interviewerUid';

const initialShowCols = ['interviewerName', 'assigned', 'completed'];

export {
  columns,
  initialSort,
  interviewerUid,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
};
