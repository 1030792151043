import homeBanner1 from '../../assets/icons/homeBanner1.png';
import homeBanner2 from '../../assets/icons/homeBanner2.png';
import homeBanner3 from '../../assets/icons/homeBanner3.png';

export const tableData = [
  {
    id: 1,
    SrNo: 1,
    Day: 'Friday, January 26, 2024',
    Event: 'Republic Day',
    Type: 'Holiday',
  },
  {
    id: 2,
    SrNo: 2,
    Day: 'Monday, March 25, 2024',
    Event: 'Holi (Dhulivandan)',
    Type: 'Holiday',
  },
  { id: 3, SrNo: 3, Day: 'Tuesday, April 9, 2024', Event: 'Gudipadava', Type: 'Holiday' },
  { id: 4, SrNo: 4, Day: 'Wednesday, May 1, 2024', Event: 'Labor Day', Type: 'Holiday' },
  {
    id: 5,
    SrNo: 5,
    Day: 'Thursday, August 15, 2024',
    Event: 'Independence Day',
    Type: 'Holiday',
  },
  {
    id: 6,
    SrNo: 6,
    Day: 'Tuesday, September 17, 2024',
    Event: 'Anant Chaturdashi',
    Type: 'Holiday',
  },
  {
    id: 7,
    SrNo: 7,
    Day: 'Wednesday, October 2, 2024',
    Event: 'Gandhi Jayanti',
    Type: 'Holiday',
  },
  {
    id: 8,
    SrNo: 8,
    Day: 'Friday, November 1, 2024',
    Event: 'Diwali ',
    Type: 'Holiday',
  },
];

export const emailTableData = [
  {
    id: 1,
    department: 'HR',
    email: 'hr@techprescient.com',
  },
  {
    id: 2,
    department: 'IT Help-Desk',
    email: ' it-helpdesk@techprescient.com',
  },
  {
    id: 3,
    department: 'Payroll Help-Desk',
    email: ' payroll-helpdesk@techprescient.com',
  },
  {
    id: 4,
    department: 'Referral Program',
    email: 'referral@techprescient.com',
  },
  {
    id: 5,
    department: 'Interviews',
    email: 'interviews@techprescient.com',
  },
  {
    id: 6,
    department: 'Careers',
    email: 'careers@techprescient.com',
  },
];

export const allSearchOptions = [
  { value: 0, label: 'Name' },
  { value: 1, label: 'Project' },
];

export const images = [homeBanner1, homeBanner2, homeBanner3];
