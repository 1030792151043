import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import InterviewClient from '../../../api/Interview/interviewAPIs';
import { formatEmployeeList } from '../utils';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';

interface AddInterviewerFormProps {
  readonly handleSubmit: any;
}
const AddInterviewerForm: React.FC<AddInterviewerFormProps> = ({ handleSubmit }: any) => {
  const { showSnackbar } = useDisplaySnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allEmployeeList, setAllEmployeeList] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.object()
      .shape({ label: Yup.string(), code: Yup.string() })
      .typeError('Select interviewer is required'),
  });

  useEffect(() => {
    setIsLoading(true);
    InterviewClient.getAllEmpList()
      .then((response) => {
        setAllEmployeeList(formatEmployeeList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, isValid, dirty, setFieldValue } = formik;
          return (
            <>
              <Form>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <SimpleAutocompleteField
                      name="name"
                      key="name"
                      placeholder="name"
                      size="small"
                      label="Name"
                      setFieldValue={setFieldValue}
                      options={allEmployeeList}
                      values={values}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                  Add
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddInterviewerForm;
