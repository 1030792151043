import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tree from 'react-d3-tree';
import { Orientation, PathFunctionOption, Point } from 'react-d3-tree/lib/types/common';
import I18nKey from '../../translations/I18nKey';
import { formatEmployeeHierarchyData, useCenteredTree } from './utils';
import useProfileDocumentStyles from './ProfileDocumentStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../contexts/GlobalContext';
import EmployeeClient from '../../api/Employee/employeeAPIs';
import useProfileStyles from './ProfileStyles';
import TeamHierarchyCard from './components/TeamHierarchyCard';
import { cleanFormData } from '../../utils/utils';

export interface ProfileTeamPageProps {
  containerStyles?: Record<string, string>;
  orientation?: Orientation;
  pathFunc?: PathFunctionOption;
}

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}: {
  nodeDatum: any;
  toggleNode: any;
  foreignObjectProps: { width: number; height: number; x: number };
}) => {
  return (
    <g>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <TeamHierarchyCard nodeDatum={nodeDatum} toggleNode={toggleNode} />
      </foreignObject>
    </g>
  );
};

const ProfileTeamPage: React.FC<ProfileTeamPageProps> = ({
  containerStyles,
  orientation,
  pathFunc,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tree, setTree] = useState<any>(null);
  const { id } = useParams();
  const [translate, containerRef] = useCenteredTree(300);
  const { employeeUid } = useContext(GlobalContext);
  const nodeSize = { x: 220, y: 220 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -100,
  };
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);
  const [navigateId, setNavigateId] = useState<any>(
    queryParams.searchBy === 'project' ? queryParams.selectedProject : id,
  );
  const classes = useProfileDocumentStyles();
  const styles = useProfileStyles();
  const intl = useIntl();

  const getEmpHierarchy = (employeeid?: any) => {
    const employeeHierarchyParams = {
      upper_level: -1,
      lower_level: -1,
      employee_uid: employeeid,
      get_direct_reportee_count: true,
    };
    EmployeeClient.getEmployeeHierarchy(cleanFormData(employeeHierarchyParams))
      .then((res) => {
        setTree(formatEmployeeHierarchyData(res.data));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    getEmpHierarchy(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.Header}>
      <Box>
        <span className="headerTitle">Organization View</span>
        {id ? (
          <Box>
            <Link to={`/search/${navigateId}?searchBy=${queryParams.searchBy}`}>
              {intl.formatMessage({
                id: I18nKey.SEARCH_TITLE,
              })}{' '}
            </Link>
            / Team View
          </Box>
        ) : (
          <Box>
            <Link to="/profile">
              {intl.formatMessage({
                id: I18nKey.PROFILE_TITLE,
              })}{' '}
            </Link>
            / Team View
          </Box>
        )}
      </Box>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.EmpHierarchyWrapper}>
        {tree ? (
          <div style={containerStyles} ref={containerRef as React.LegacyRef<HTMLDivElement>}>
            <Tree
              data={tree as any}
              translate={translate as Point}
              nodeSize={nodeSize}
              renderCustomNodeElement={(rd3tProps) =>
                renderForeignObjectNode({
                  ...rd3tProps,
                  foreignObjectProps,
                })
              }
              orientation={orientation}
              pathFunc={pathFunc}
            />
          </div>
        ) : null}
      </Box>
    </Box>
  );
};

ProfileTeamPage.defaultProps = {
  containerStyles: {
    width: '100%',
    height: '100%',
  },
  orientation: 'vertical',
  pathFunc: 'step',
};

export default ProfileTeamPage;
