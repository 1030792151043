/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Grid, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useIntl } from 'react-intl';
import DocumentClient from '../../../api/Document/DocumentAPI';
import I18nKey from '../../../translations/I18nKey';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import {
  Email,
  capitalizeFirstLetter,
  cleanFormData,
  currencyFormatter,
} from '../../../utils/utils';
import useDocumentStyles from '../DocumentStyles';
import MisDialog from '../../../components/MisDialog/MisDialog';
import { ShareOfferLetterParams } from '../../../api/Document/DocumentTypes';
import {
  formatOfferLetterData,
  getMonthlySalary,
  getYearlySalary,
  handleCustomInputChange,
  OfferLetterFormValidation,
} from '../utils';
import CandidateShareForm from '../CandidateShareForm';
import SimpleCheckboxField from '../../../components/SimpleCheckboxField/SimpleCheckboxField';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';

const LetterForm: React.FC<any> = ({
  candidateDetails,
  candidateId,
  allDepartments,
  allTracks,
  allDesignations,
  updateFetch,
  compensationLetterCondition,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [initialField, setInitialField] = useState({
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
  });
  const [isShareFormValid, setIsShareFormValid] = useState(true);
  const [showPromotionFields, setShowPromotionFields] = useState<boolean>(false);
  const [showBonusFields, setShowBonusFields] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [tracks, setTracks] = useState([]);
  const [designations, setDesignations] = useState([]);
  const styles = useDocumentStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const navigate = useNavigate();
  const shareFormRef = useRef<any>();
  const formRef = useRef<any>();
  const location = useLocation();

  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  const salaryRevisionYear = searchParams.get('salary_revision_year');

  const currentYear = new Date().getFullYear();
  const isDisabled = compensationLetterCondition && Number(salaryRevisionYear) !== currentYear + 1;

  const handleCheckboxChangeBonus = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    setShowBonusFields(!showBonusFields);
  };

  const handleCheckboxChangePromotion = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any,
  ) => {
    setShowPromotionFields(value);
  };

  const getCompensationLetterParams = (values: any) => ({
    emp_details: {
      employeeUid: candidateId || '',
      // yearlySalary: values.yearlySalary,
      isBonusGiven: values.isBonusGiven,
      isPromoted: values.isPromoted,
    },
    salary_details: {
      basicMonthly: values.basicMonthly,
      basicYearly: values.basicYearly,
      hraMonthly: values.hraMonthly,
      hraYearly: values.hraYearly,
      transportMonthly: values.transportMonthly,
      transportYearly: values.transportYearly,
      statutaryBonusMonthly: values.statutaryBonusMonthly,
      statutaryBonusYearly: values.statutaryBonusYearly,
      fixedAllowanceMonthly: values.fixedAllowanceMonthly,
      fixedAllowanceYearly: values.fixedAllowanceYearly,
      specialPerformancePayMonthly: values.specialPerformancePayMonthly,
      specialPerformancePayYearly: values.specialPerformancePayYearly,
      epfMonthly: values.epfMonthly,
      epfYearly: values.epfYearly,
      totalMonthly: values.totalMonthly,
      gratuityMonthly: values.gratuityMonthly,
      gratuityYearly: values.gratuityYearly,
      joiningBonusMonthly: values.joiningBonusMonthly,
      joiningBonusYearly: values.isBonusGiven ? values.joiningBonusYearly : 0,
      anniversaryBonusMonthly: values.anniversaryBonusMonthly,
      anniversaryBonusYearly: values.isBonusGiven ? values.anniversaryBonusYearly : 0,
      targetAchievementBonusMonthly: values.targetAchievementBonusMonthly,
      targetAchievementBonusYearly: values.isBonusGiven ? values.targetAchievementBonusYearly : 0,
      individualPerformanceBonusMonthly: values.individualPerformanceBonusMonthly,
      individualPerformanceBonusYearly: values.isBonusGiven
        ? values.individualPerformanceBonusYearly
        : 0,
      totalYearly: values.isBonusGiven
        ? values.yearlySalary +
          values.joiningBonusYearly +
          values.anniversaryBonusYearly +
          values.targetAchievementBonusYearly +
          values.individualPerformanceBonusYearly
        : values.yearlySalary,
      compensationStartDate: values.compensationStartDate,
    },
    emp_designation: cleanFormData({
      startDate: values.startDate ? values.startDate : '',
      designationId: values.designationName?.value ? values.designationName.value : '',
      designationName: values.designationName?.label ? values.designationName.label : '',
    }),
  });

  const handleShareClick = (values: any) => {
    setFormValues(values);
    setShareDialogOpen(true);
  };
  const handleShare = () => {
    setIsLoading(true);
    const ccAddress = shareFormRef.current?.values.cc.split(';');
    const bccAddress = shareFormRef.current?.values.bcc.split(';');
    const modifiedCcAddress = ccAddress.map((address: string, index: number) => {
      if (index === 0) {
        return address; // Keep the first element unchanged
      }
      return address.trim(); // Remove initial space from other elements
    });
    const modifiedBccAddress = bccAddress.map((address: string, index: number) => {
      if (index === 0) {
        return address; // Keep the first element unchanged
      }
      return address.trim(); // Remove initial space from other elements
    });
    const shareParams: ShareOfferLetterParams = {
      toAddr: [shareFormRef.current?.values.to],
      ccAddr: modifiedCcAddress,
      emailSubject: shareFormRef.current?.values.subject,
      emailBody: shareFormRef.current?.values.message,
      ...(compensationLetterCondition && { bccAddr: modifiedBccAddress }),
    };

    const empSalaryDetails = getCompensationLetterParams(formValues);
    const shareCompensationLetterParams = {
      ...empSalaryDetails,
      email_details: { ...shareParams },
    };
    setShareDialogOpen(false);
    if (compensationLetterCondition) {
      DocumentClient.shareCompensationLetter(
        candidateDetails.employeeUid,
        shareCompensationLetterParams,
        salaryRevisionYear,
      )
        .then((result: any) => {
          showSnackbar(result, 'success');
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    } else {
      DocumentClient.shareOfferLetter(candidateId, shareParams)
        .then((result: any) => {
          updateFetch();
          showSnackbar(result, 'success');
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    setShareDialogOpen(false);
    setIsShareFormValid(true);
  };

  const calculateFixedAllowance = () => {
    const {
      basicYearly,
      hraYearly,
      transportYearly,
      statutaryBonusYearly,
      specialPerformancePayYearly,
      epfYearly,
      yearlySalary,
      gratuityYearly,
    } = formRef.current.values;

    const yearlyFixedAllowance =
      yearlySalary -
      (basicYearly +
        hraYearly +
        transportYearly +
        statutaryBonusYearly +
        specialPerformancePayYearly +
        epfYearly +
        gratuityYearly);
    formRef.current.setFieldValue('fixedAllowanceYearly', yearlyFixedAllowance);
    formRef.current.setFieldValue('fixedAllowanceMonthly', getMonthlySalary(yearlyFixedAllowance));
  };

  const handleCustomInputBlur = () => {
    calculateFixedAllowance();
  };

  const CustomInputField = ({
    name,
    handleChange,
    handleBlur,
    setFieldValue,
    externalHandleChange,
    externalhandleBlur,
    ...formFileds
  }: any) => {
    const fieldProperties: any = {
      name,
      ...formFileds,
    };

    const [field, mata] = useField(name);

    const handleInputChange = (e: any) => {
      handleChange(e);
      externalHandleChange(name, e.target.value, setFieldValue);
    };

    const handleInputBlur = (e: any) => {
      handleBlur(e);
      if (typeof externalhandleBlur === 'function') externalhandleBlur();
    };

    if (mata && mata.touched && mata.error) {
      fieldProperties.error = true;
      fieldProperties.helperText = <ErrorMessage name={name} />;
    }

    return (
      <Field
        as={TextField}
        {...field}
        {...fieldProperties}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    );
  };

  const getupdateCandidateParams = (values: any) => {
    return {
      candidate: {
        phoneNumber: values.phoneNumber,
        addressFirstLine: values.addressFirstLine,
        addressSecondLine: values.addressSecondLine,
        email: values.email,
        dateOfJoining: values.dateOfJoining,
        designation: values.designation,
        yearlySalary: values.yearlySalary,
        isBonusGiven: values.isBonusGiven,
      },
      salary_details: {
        basicMonthly: values.basicMonthly,
        basicYearly: values.basicYearly,
        hraMonthly: values.hraMonthly,
        hraYearly: values.hraYearly,
        transportMonthly: values.transportMonthly,
        transportYearly: values.transportYearly,
        statutaryBonusMonthly: values.statutaryBonusMonthly,
        statutaryBonusYearly: values.statutaryBonusYearly,
        fixedAllowanceMonthly: values.fixedAllowanceMonthly,
        fixedAllowanceYearly: values.fixedAllowanceYearly,
        specialPerformancePayMonthly: values.specialPerformancePayMonthly,
        specialPerformancePayYearly: values.specialPerformancePayYearly,
        epfMonthly: values.epfMonthly,
        epfYearly: values.epfYearly,
        totalMonthly: values.totalMonthly,
        gratuityMonthly: values.gratuityMonthly,
        gratuityYearly: values.gratuityYearly,
        joiningBonusMonthly: values.joiningBonusMonthly,
        joiningBonusYearly: values.isBonusGiven ? values.joiningBonusYearly : 0,
        anniversaryBonusMonthly: values.anniversaryBonusMonthly,
        anniversaryBonusYearly: values.isBonusGiven ? values.anniversaryBonusYearly : 0,
        targetAchievementBonusMonthly: values.targetAchievementBonusMonthly,
        targetAchievementBonusYearly: values.isBonusGiven ? values.targetAchievementBonusYearly : 0,
        individualPerformanceBonusMonthly: values.individualPerformanceBonusMonthly,
        individualPerformanceBonusYearly: values.isBonusGiven
          ? values.individualPerformanceBonusYearly
          : 0,
        totalYearly: values.isBonusGiven
          ? values.yearlySalary +
            values.joiningBonusYearly +
            values.anniversaryBonusYearly +
            values.targetAchievementBonusYearly +
            values.individualPerformanceBonusYearly
          : values.yearlySalary,
      },
    };
  };

  const downloadDocument = (
    GenerateResponse: { data: { fileName: string; filePath: any } },
    downloadInvoiceParams: any,
  ) => {
    return DocumentClient.downloadDocument(downloadInvoiceParams)
      .then((res) => {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        link.download = GenerateResponse.data.fileName;
        link.click();
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const totalYearly = values.isBonusGiven
      ? values.yearlySalary +
        values.joiningBonusYearly +
        values.anniversaryBonusYearly +
        values.targetAchievementBonusYearly +
        values.individualPerformanceBonusYearly
      : values.yearlySalary;
    const generateOfferLetterData = {
      ...values,
      totalYearly,
    };
    if (candidateId) {
      if (compensationLetterCondition) {
        const compensationLetterParams = getCompensationLetterParams(values);
        const uid = { uid: candidateDetails.uid };
        DocumentClient.putCompensationLetter(compensationLetterParams, uid, salaryRevisionYear)
          .then(async (GenerateResponse) => {
            const link = document.createElement('a');
            const pdfData = GenerateResponse.data;
            const linkSource = `data:application/pdf;base64,${pdfData}`;
            link.href = linkSource;
            link.download = `${candidateDetails.empId} - ${candidateDetails.name} Appraisal and Salary Increment FY 2024-25`;
            link.click();
            showSnackbar(GenerateResponse, 'success', 'Record successfully updated');
          })
          .catch((e) => {
            showSnackbar(e, 'error');
          })
          .finally(() => setIsLoading(false));
      } else {
        const candidateDetailsParams = getupdateCandidateParams(values);
        DocumentClient.saveCandidateDetails(candidateId, candidateDetailsParams)
          .then(async (GenerateResponse) => {
            const downloadInvoiceParams = {
              preSignedUrl: GenerateResponse.data.url,
            };
            downloadDocument(GenerateResponse, downloadInvoiceParams);
            showSnackbar(GenerateResponse, 'success', 'Record successfully updated');
            updateFetch();
          })
          .catch((e) => {
            showSnackbar(e, 'error');
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      DocumentClient.generateOfferLetter(
        formatOfferLetterData(generateOfferLetterData),
        salaryRevisionYear,
      )
        .then(async (GenerateResponse) => {
          const downloadInvoiceParams = {
            preSignedUrl: GenerateResponse.data.url,
          };
          downloadDocument(GenerateResponse, downloadInvoiceParams);
          showSnackbar(GenerateResponse, 'success');
          navigate(`/document/offerLetter`);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (candidateDetails) {
      getYearlySalary(candidateDetails.yearlySalary);
      setShowBonusFields(candidateDetails?.isBonusGiven);
      setShowPromotionFields(candidateDetails?.isPromoted);
      setTracks(allTracks.filter((t: any) => candidateDetails.department?.uid === t.departmentId));
      setDesignations(
        allDesignations.filter((t: any) => candidateDetails.track?.uid === t.trackId),
      );
      const capitalizedCandidateName = capitalizeFirstLetter(candidateDetails.name);
      if (compensationLetterCondition) {
        setInitialField({
          to: candidateDetails.email,
          cc: `${Email.ADMIN}`,
          bcc: `${Email.HRADMIN}`,
          subject: intl.formatMessage({
            id: I18nKey.COMPENSATION_LETTER_SUBJECT,
          }),
          message: `Dear ${capitalizedCandidateName}\n\n${intl.formatMessage({
            id: I18nKey.COMPENSATION_MSG_BODY,
          })}`,
        });
      } else {
        setInitialField({
          to: candidateDetails.email,
          cc: `${Email.ADMIN}; ${Email.HRDEPARTMENT}`,
          bcc: '',
          subject: intl.formatMessage({
            id: I18nKey.SUBJECT,
          }),
          message: `Dear ${capitalizedCandidateName}\n\n${intl.formatMessage({
            id: I18nKey.MESSAGEBODY,
          })}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={candidateDetails}
        onSubmit={handleSubmit}
        validationSchema={OfferLetterFormValidation}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const {
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
            errors,
            dirty,
            isValid,
          } = formik;
          return (
            <Form>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="name"
                    type="string"
                    label="Name"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    disabled={candidateId}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IN"
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                    inputProps={{ style: { fontSize: 15 } }}
                    size="small"
                    value={values.phoneNumber}
                    onChange={(newValue, info) => {
                      setFieldValue('phoneNumber', info.numberValue);
                    }}
                    forceCallingCode
                    splitCallingCode
                    focusOnSelectCountry
                    required
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={
                      touched.phoneNumber && errors.phoneNumber ? (errors.phoneNumber as any) : null
                    }
                    onBlur={handleBlur}
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="addressFirstLine"
                    type="string"
                    label="Address Line 1"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="addressSecondLine"
                    type="string"
                    label="Address Line 2"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="dateOfJoining"
                    type="date"
                    label="Date Of Joining"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="designation"
                    type="text"
                    label="Current Designation"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                    disabled={compensationLetterCondition}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="yearlySalary"
                    type="number"
                    label="Yearly Salary (in INR)"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    externalhandleBlur={handleCustomInputBlur}
                    fullWidth
                    required
                  />
                </Grid>
                {compensationLetterCondition && (
                  <Grid item xs={2}>
                    <SimpleInputField
                      className={styles.formField}
                      name="compensationStartDate"
                      type="date"
                      label="Start Date"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={2}>
                  <SimpleCheckboxField
                    name="isBonusGiven"
                    checked={values.isBonusGiven}
                    inputProps={{ style: { fontSize: 13 } }}
                    setFieldValue={setFieldValue}
                    label="Bonus"
                    size="small"
                    handleChange={handleCheckboxChangeBonus}
                  />
                </Grid>
                {compensationLetterCondition && (
                  <Grid item xs={2}>
                    <SimpleCheckboxField
                      name="isPromoted"
                      checked={values.isPromoted}
                      inputProps={{ style: { fontSize: 13 } }}
                      setFieldValue={setFieldValue}
                      label="Promotion"
                      size="small"
                      handleChange={(e: ChangeEvent<HTMLInputElement>, v: any) => {
                        handleCheckboxChangePromotion(e, v);
                      }}
                    />
                  </Grid>
                )}
                {showPromotionFields && (
                  <>
                    <Grid item xs={4}>
                      <SimpleAutocompleteField
                        name="department"
                        key="department"
                        label="Department"
                        size="small"
                        handleChange={(event: any, value: any) => {
                          setFieldValue('track', null);
                          setFieldValue('designationName', null);
                          setTracks(allTracks.filter((t: any) => value.uid === t.departmentId));
                          setDesignations(
                            allDesignations.filter((t: any) => value.uid === t.departmentId),
                          );
                        }}
                        setFieldValue={setFieldValue}
                        options={allDepartments}
                        values={values}
                        className={styles.formField}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleAutocompleteField
                        name="track"
                        key="track"
                        label="Track"
                        size="small"
                        handleChange={(event: any, value: any) => {
                          setFieldValue('designationName', null);
                          setDesignations(
                            allDesignations.filter((t: any) => value.uid === t.trackId),
                          );
                        }}
                        setFieldValue={setFieldValue}
                        options={values.department ? tracks : []}
                        values={values}
                        className={styles.formField}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleAutocompleteField
                        name="designationName"
                        key="designationName"
                        label="Designation"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={values.track ? designations : []}
                        values={values}
                        className={styles.formField}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleInputField
                        className={styles.formField}
                        name="startDate"
                        type="date"
                        label="Effective From"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </>
                )}
                <table className={styles.Table}>
                  <thead>
                    <tr>
                      <th className={`${styles.InfoColumn} ${styles.TableHeader}`}> </th>
                      <th className={`${styles.ValuesColumn} ${styles.TableHeader}`}>
                        Monthly Salary (in INR)
                      </th>
                      <th
                        className={`${styles.ValuesColumn} ${styles.TableHeader} ${styles.LastColumn}`}>
                        Yearly Salary (in INR)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>Basic Salary</td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="basicMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="basicYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          externalhandleBlur={handleCustomInputBlur}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>
                        House Rent Allowance
                      </td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="hraMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="hraYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>Transportation</td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="transportMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="transportYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>Statutory Bonus</td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="statutaryBonusMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="statutaryBonusYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>Fixed Allowance</td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="fixedAllowanceMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="fixedAllowanceYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>
                        Special Performance Pay
                      </td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="specialPerformancePayMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="specialPerformancePayYearly"
                          type="number"
                          size="small"
                          disabled={!values.yearlySalary}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          externalhandleBlur={handleCustomInputBlur}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    {showBonusFields && (
                      <>
                        <tr>
                          <td className={`${styles.InfoColumn} ${styles.Column}`}>Joining Bonus</td>
                          <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="joiningBonusMonthly"
                              type="string"
                              size="small"
                              disabled
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              fullWidth
                            />
                          </td>
                          <td
                            className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="joiningBonusYearly"
                              type="number"
                              size="small"
                              disabled={!values.yearlySalary}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              externalhandleBlur={handleCustomInputBlur}
                              fullWidth
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={`${styles.InfoColumn} ${styles.Column}`}>
                            Anniversary Bonus
                          </td>
                          <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="anniversaryBonusMonthly"
                              type="string"
                              size="small"
                              disabled
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              fullWidth
                            />
                          </td>
                          <td
                            className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="anniversaryBonusYearly"
                              type="number"
                              size="small"
                              disabled={!values.yearlySalary}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              externalhandleBlur={handleCustomInputBlur}
                              fullWidth
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={`${styles.InfoColumn} ${styles.Column}`}>
                            Individual Performance Bonus
                          </td>
                          <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="individualPerformanceBonusMonthly"
                              type="string"
                              size="small"
                              disabled
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              fullWidth
                            />
                          </td>
                          <td
                            className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="individualPerformanceBonusYearly"
                              type="number"
                              size="small"
                              disabled={!values.yearlySalary}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              externalhandleBlur={handleCustomInputBlur}
                              fullWidth
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={`${styles.InfoColumn} ${styles.Column}`}>
                            Target Achievement Bonus
                          </td>
                          <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="targetAchievementBonusMonthly"
                              type="string"
                              size="small"
                              disabled
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              fullWidth
                            />
                          </td>
                          <td
                            className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                            <CustomInputField
                              className={styles.formField}
                              inputProps={{ style: { fontSize: 15 } }}
                              name="targetAchievementBonusYearly"
                              type="number"
                              size="small"
                              disabled={!values.yearlySalary}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              externalHandleChange={handleCustomInputChange}
                              externalhandleBlur={handleCustomInputBlur}
                              fullWidth
                            />
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>
                        Employer Contribution to PF
                      </td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="epfMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="epfYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          externalhandleBlur={handleCustomInputBlur}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${styles.InfoColumn} ${styles.Column}`}>Gratuity</td>
                      <td className={`${styles.ValuesColumn} ${styles.Column}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="gratuityMonthly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          fullWidth
                          required
                        />
                      </td>
                      <td
                        className={`${styles.ValuesColumn} ${styles.Column} ${styles.LastColumn}`}>
                        <CustomInputField
                          className={styles.formField}
                          inputProps={{ style: { fontSize: 15 } }}
                          name="gratuityYearly"
                          type="number"
                          size="small"
                          disabled
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          externalHandleChange={handleCustomInputChange}
                          externalhandleBlur={handleCustomInputBlur}
                          fullWidth
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.InfoColumn}>Total Cost To Company</td>
                      <td className={`${styles.ValuesColumn} ${styles.TotalColumn}`}>
                        <Typography marginLeft={1} fontWeight={600}>
                          {currencyFormatter(values.totalMonthly, 'INR', 'en-IN').split('.')[0]}
                        </Typography>
                      </td>
                      <td className={`${styles.ValuesColumn} ${styles.TotalColumn}`}>
                        <Typography marginLeft={1} fontWeight={600}>
                          {
                            currencyFormatter(
                              values.isBonusGiven
                                ? values.yearlySalary +
                                    values.joiningBonusYearly +
                                    values.anniversaryBonusYearly +
                                    values.targetAchievementBonusYearly +
                                    values.individualPerformanceBonusYearly
                                : values.yearlySalary,
                              'INR',
                              'en-IN',
                            ).split('.')[0]
                          }
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Button
                className={styles.SubmitBtn}
                disabled={!dirty || !isValid || isDisabled}
                variant="contained"
                type="submit">
                Generate Document
              </Button>
              {candidateId && (
                <Button
                  className={styles.ShareBtn}
                  variant="contained"
                  disabled={!isValid || isDisabled}
                  onClick={() => handleShareClick(values)}
                  endIcon={<ArrowOutwardIcon />}>
                  <Typography variant="button" component="span">
                    Share
                  </Typography>
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
      <MisDialog
        title={
          compensationLetterCondition
            ? intl.formatMessage({
                id: I18nKey.COMPENSATION_LETTER_TITLE,
              })
            : intl.formatMessage({
                id: I18nKey.OFFER_LETTER,
              })
        }
        message={
          compensationLetterCondition
            ? intl.formatMessage({
                id: I18nKey.SHARE_COMPENSATION_LETTER,
              })
            : intl.formatMessage({
                id: I18nKey.SHARE_OFFERLETTER,
              })
        }
        width="lg"
        isOpen={shareDialogOpen}
        handleSuccess={handleShare}
        handleClose={handleClose}
        actionBtnLabel={'Share'}
        disableSubmitBtn={isShareFormValid}
        additionalInfoSection={
          <CandidateShareForm
            initialField={initialField}
            setInitialField={setInitialField}
            setIsShareFormValid={setIsShareFormValid}
            shareFormRef={shareFormRef}
            compensationLetterCondition={compensationLetterCondition}
          />
        }
      />
    </>
  );
};

export default LetterForm;
