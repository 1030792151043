import { useContext } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useIntl } from 'react-intl';
import { useLocation, Navigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import useLoginStyles from './LoginStyles';
import I18nKey from '../../translations/I18nKey';
import { GlobalContext } from '../../contexts/GlobalContext';

const Login = () => {
  const { isAuthenticated } = useContext(GlobalContext);

  const location = useLocation();
  const styles = useLoginStyles();
  const intl = useIntl();

  const locationState = location.state as any;
  const from = locationState?.from?.pathname || '/';

  if (isAuthenticated) return <Navigate replace to={from} />;

  return (
    <>
      <Box className={styles.contentWrapper}>
        <Box className={styles.leftWrapper}>
          <Box className={styles.header}>
            <Typography fontSize={20}>
              {intl.formatMessage({
                id: I18nKey.LOGIN_ME,
              })}
            </Typography>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/images/tech-prescient-logo.png`}
              alt="Tech Prescient"
              className={styles.logo}
            />
          </Box>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/images/login-hero.png`}
              alt="Tech Prescient"
              className={styles.image}
            />
          </Box>
        </Box>
        <Box className={styles.rightWrapper}>
          <Typography variant="h3" className={styles.headerText}>
            {intl.formatMessage({
              id: I18nKey.LOGIN_HEADER,
            })}
          </Typography>
          <p className={styles.subHeaderText}>
            {intl.formatMessage({
              id: I18nKey.LOGIN_SUB_HEADER,
            })}
          </p>
          <Box className={styles.authWrapper}>
            <Authenticator
              hideSignUp
              socialProviders={[process.env.REACT_APP_OAUTH_SOCIAL_PROVIDER as any]}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
