import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import useInterviewDetailsStyles from './InterviewDetailsStyles';
import FeedbackDetailsForm from './Forms/FeedbackDetailsForm';
import FeedbackDetails from './FeedbackDetails';
import { InterviewStatus } from './utils';

const InterviewFeedbackDetails: React.FC<any> = ({
  detailsData,
  updateFetch,
  selectedTab,
}: any) => {
  const [filteredData, setFilterData] = useState<any>([]);
  const styles = useInterviewDetailsStyles();

  useEffect(() => {
    if (detailsData) {
      const roundData = detailsData?.interviewFeedback.filter((item: any) => {
        if (selectedTab === 0) {
          return item.interviewRoundName === 'PHONESCREEN';
        }
        if (selectedTab === 1) {
          return item.interviewRoundName === 'L1';
        }
        if (selectedTab === 2) {
          return item.interviewRoundName === 'L2';
        }
        if (selectedTab === 3) {
          return item.interviewRoundName === 'FINAL';
        }
        if (selectedTab === 4) {
          return item.interviewRoundName === 'HR';
        }
        return false;
      });
      setFilterData({ ...roundData[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {filteredData?.interviewStatus !== InterviewStatus.SCHEDULED ? (
          <FeedbackDetails roundWiseData={filteredData} />
        ) : (
          <FeedbackDetailsForm roundWiseData={filteredData} updateFetch={updateFetch} />
        )}
      </Grid>
    </Grid>
  );
};

export default InterviewFeedbackDetails;
