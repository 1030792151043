import { useEffect, useState, useContext } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Papa from 'papaparse';
import * as xlsx from 'xlsx';
import * as Yup from 'yup';
import I18nKey from '../../translations/I18nKey';
import useAllAppraisalsStyles from './AllAppraisalStyles';
import SaveKraTemplate from './SaveKraTemplate';
import KraTemplateForm from './KraTemplateForm';
import SidePanel from '../../components/SidePanel/SidePanel';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import AppraisalClient from '../../api/Appraisal/appraisalAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import useResourceDetailStyles from './ResourceDetailStyles';
import {
  getAppraisalFY,
  getFYDateRange,
  hasSuperUserAccess,
  UserGroupRoles,
} from '../../utils/utils';
import { AppraisalStatus, AppraisalStatusList } from '../../api/Appraisal/appraisalTypes';

const AddKraTemplate: React.FC<any> = ({
  setIsManager,
  isManager,
  isEmployee,
  allCategories,
  allSubCategories,
  savedKraInfo,
  appraisalDetails,
  isTemplateLoading,
  setIsTemplateLoading,
  date,
  updateFetch,
}: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<any>();
  const [xlData, setXlData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingReportees, setIsLoadingReportees] = useState(false);
  const { showSnackbar } = useDisplaySnackbar();
  const [allResources, setAllResources] = useState([]);
  const [isStartDisabled, setStartDisabled] = useState<boolean>(false);
  const [appraisalId, setAppraisalId] = useState('');
  const [updatedSubCategories, setUpdatedSubCategories] = useState<any>(allSubCategories);

  const { employeeUid } = useContext(GlobalContext);
  const styles = useAllAppraisalsStyles();
  const headerStyles = useResourceDetailStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const commonConfig = { delimiter: ',' };

  const validationSchema = Yup.object().shape({
    documentFile: Yup.mixed()
      .test('fileType', 'Please upload a CSV or XLSX file.', function (value) {
        if (!value) {
          return true;
        }
        const acceptedFiles = ['.csv', '.xlsx'];
        const fileExtension = value.name.split('.').pop().toLowerCase();
        return acceptedFiles.includes(`.${fileExtension}`);
      })
      .required('Please upload a file.'),
  });

  const handleUploadModal = () => {
    setIsOpen(true);
  };

  const readFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const data = evt.target?.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setXlData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleSubmit = (values: any) => {
    const file = values.documentFile;
    const fileName = file.name.split('.');
    const format = fileName[1];
    if (format === 'csv') {
      setXlData('');
      Papa.parse(file, {
        ...commonConfig,
        header: true,
        complete: (result: any) => {
          setCSVData(result.data);
        },
      });
    } else {
      setCSVData(xlData);
    }
    setIsOpen(false);
  };

  const formatData = (values: any) =>
    values.map((el: any) => ({
      label: el.employeeName,
      value: el.employeeUid,
    }));

  const handleAppraisalStart = (values: any) => {
    setIsLoading(true);
    const startAppraisalParams = {
      employeeUid: values.resourceName?.value,
      //  dueDate: '2023-02-24',
      periodStartDate: values.startDate,
      periodEndDate: values.endDate,
    };
    AppraisalClient.postStartAppraisal(startAppraisalParams)
      .then((response: any) => {
        showSnackbar(response, 'success', 'Appraisal successfully started');
        setAppraisalId(response.uid);
        setStartDisabled(true);
        navigate(
          `/appraisal/kraDetails/${response.uid}?startDate=${date.startDate}&endDate=${date.endDate}`,
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const formatKraDetails = (details: any) =>
    details.map((item: any) => ({
      uid: item.uid,
      categoryUid: item.category.value,
      subCategoryUid: item.subCategory.value,
      details: item.description,
      weightage: item.weightage,
      name: 'dummy',
    }));

  const handleKraSubmit = (values: any) => {
    setIsLoading(true);
    const formatedKraDetails = formatKraDetails(values.kraDetails);

    // Check for deleted records
    const deletePayload = appraisalDetails
      .filter(
        ({ uid: init_uid }: { uid: string }) =>
          formatedKraDetails.find(({ uid: final_uid }: any) => final_uid === init_uid) ===
          undefined,
      )
      .map(({ uid }: { uid: string }) => uid);

    // PATCH payload
    const patchPaylod = formatedKraDetails.filter(
      ({ uid: init_uid }: { uid: string }) => !deletePayload.includes(init_uid),
    );

    let deletePromise = null;
    let patchPromise = null;

    if (deletePayload.length) {
      deletePromise = AppraisalClient.deleteAppraisalKra(deletePayload, appraisalId);
    }

    if (patchPaylod.length) {
      patchPromise = AppraisalClient.updateAppraisalKra(patchPaylod, appraisalId);
    }

    Promise.allSettled([deletePromise, patchPromise])
      .then((response: any) => {
        if (
          response.every((result: { status: string; values: any }) => result.status === 'fulfilled')
        ) {
          showSnackbar(
            {
              status: 200,
              data: {
                detail: intl.formatMessage({
                  id: I18nKey.TOAST_MESSAGE_UPDATE,
                }),
              },
            },
            'success',
          );
          updateFetch();
        } else {
          response.forEach((result: { status: string; reason: {} }, index: number) => {
            if (result.status === 'rejected') {
              showSnackbar(result.reason, 'error');
            }
          });
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleLockGoals = () => {
    setIsLoading(true);
    AppraisalClient.updateAppraisalStatus(appraisalId, AppraisalStatus.GOAL_LOCKED)
      .then((result: any) => {
        showSnackbar(result, 'success');
        navigate(`/appraisal?startDate=${date.startDate}&endDate=${date.endDate}`);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getReportees = () => {
    setIsLoadingReportees(true);
    AppraisalClient.getAllReportees(employeeUid)
      .then((response: any) => {
        setIsManager(response.length > 0);
        setAllResources(formatData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingReportees(false));
  };

  useEffect(() => {
    getReportees();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (id && allSubCategories.length) {
      setStartDisabled(true);
      setUpdatedSubCategories(allSubCategories);
      setAppraisalId(id);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [allSubCategories, id]);

  return (
    <>
      {(isLoading || isLoadingReportees) && (
        <ProgressSpinner showSpinner={isLoading || isLoadingReportees} />
      )}
      <Box className={headerStyles.Header}>
        <Box>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.APPRAISAL_FYTITLE,
            })}
          </span>
        </Box>
        <Box>
          <Link to={`/appraisal?startDate=${date.startDate}&endDate=${date.endDate}`}>
            {intl.formatMessage({
              id: I18nKey.APPRAISAL_TITLE,
            })}{' '}
          </Link>
          /{' '}
          {intl.formatMessage({
            id: I18nKey.APPRAISAL_START_APPRAISAL,
          })}
        </Box>
      </Box>
      <SaveKraTemplate
        allResources={allResources}
        handleAppraisalStart={handleAppraisalStart}
        isStartDisabled={isStartDisabled}
        id={id}
        savedKraInfo={savedKraInfo}
        isManager={isManager}
        date={date}
      />
      <KraTemplateForm
        csvData={csvData}
        appraisalDetails={appraisalDetails}
        updatedSubCategories={updatedSubCategories}
        allCategories={allCategories}
        allSubCategories={allSubCategories}
        setUpdatedSubCategories={setUpdatedSubCategories}
        handleKraSubmit={handleKraSubmit}
        handleLockGoals={handleLockGoals}
        isStartDisabled={isStartDisabled}
        isTemplateLoading={isTemplateLoading}
        setIsTemplateLoading={setIsTemplateLoading}
        isManager={isManager}
        isEmployee={isEmployee}
      />
      {/* <Box className={styles.fabContainer}>
        <Box className={`${styles.iconbutton} ${styles.button}`}>
          <Box onClick={handleUploadModal}>
            <Link to="/appraisal/startAppraisal">
              <FileUploadOutlinedIcon />
            </Link>
          </Box>
        </Box>
      </Box> */}
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box>
          <SidePanel header="Upload Template" onClose={() => setIsOpen(false)}>
            <div className={styles.formContainer}>
              <Formik
                initialValues={{}}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize>
                {(formik) => {
                  const { setFieldValue, dirty, isValid } = formik;
                  return (
                    <>
                      <Form>
                        <SimpleInputField
                          name="template"
                          label="Template Name"
                          variant="standard"
                          type="file"
                          onChange={(evt: any) => {
                            setFieldValue(`documentFile`, evt.currentTarget.files[0]);
                            readFile(evt);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ accept: '.csv, .xlsx' }}
                          fullWidth
                          required
                        />
                        <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                          Upload
                        </Button>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </SidePanel>
        </Box>
      </Modal>
    </>
  );
};

export default AddKraTemplate;
