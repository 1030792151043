import { Box, Button, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import useAddCandidateDetailStyles from '../AddCandidateDetailStyles';

interface AddCandidateByCSVFormProps {
  readonly handleSubmit: any;
}

const AddCandidateByCSVForm: React.FC<AddCandidateByCSVFormProps> = ({ handleSubmit }: any) => {
  const styles = useAddCandidateDetailStyles();

  const initialValue: any = {};

  const validationSchema = Yup.object().shape({
    documentFile: Yup.mixed()
      .test('fileType', 'Please upload a CSV file.', function (value) {
        if (!value) {
          return true;
        }
        const acceptedFiles = '.csv';
        const fileExtension = value.name.split('.').pop().toLowerCase();
        return acceptedFiles.includes(`.${fileExtension}`);
      })
      .required('Please upload a file.'),
  });

  return (
    <Box className={styles.csvFormWrapper}>
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { setFieldValue, dirty, isValid, values } = formik;
          return (
            <Form>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="document"
                    label="Upload Careers Applicants"
                    variant="standard"
                    type="file"
                    onChange={(evt: any) => {
                      setFieldValue(`documentFile`, evt.currentTarget.files[0]);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ accept: '.csv' }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  {values.documentFile && (
                    <Box className={styles.fileDetailsWrapper}>
                      <Typography className={styles.uploadText}>Uploaded file: </Typography>
                      <Typography className={styles.uploadText}>
                        {values.documentFile?.name}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                Upload
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddCandidateByCSVForm;
