import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import CustomDateTimePicker from '../../components/CustomDateTimePicker/CustomDateTimePicker';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useInterviewDetailsStyles from './InterviewDetailsStyles';

interface InterviewerFormDetailsProps {
  roundWiseData: any;
}

const InterviewerFormDetails: React.FC<InterviewerFormDetailsProps> = ({ roundWiseData }: any) => {
  const handleSubmit = (v: any) => {};
  const styles = useInterviewDetailsStyles();
  const [initialField, setInitialField] = useState({ interviewer: '', datetime: dayjs() });

  const convertedTime = dayjs(roundWiseData?.scheduledTime, 'h:mm A').format('HH:mm');

  const scheduleDateTime = dayjs(`${roundWiseData?.scheduledDate}T${convertedTime}`);

  useEffect(() => {
    setInitialField({ interviewer: roundWiseData?.interviewerName, datetime: scheduleDateTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundWiseData]);

  return (
    <>
      <Formik initialValues={initialField} onSubmit={handleSubmit} enableReinitialize>
        {(formik) => {
          const { values } = formik;
          return (
            <>
              <Form>
                <Box className={styles.disableDetails}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <SimpleInputField
                        name="interviewer"
                        label="Interviewer"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3} className={styles.dateTimeWrapper}>
                      <CustomDateTimePicker
                        name="datetime"
                        label="Date and Time"
                        value={values.datetime}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InterviewerFormDetails;
