import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useContractTableStyles = makeStyles(() => {
  return {
    contractTablesWrapper: {
      '& .contract-doc': {
        display: 'flex',
        alignSelf: 'center',
      },
    },
    header: {
      color: theme.palette.text.midDark,
      padding: '1rem',
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    addButton: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    categorySelect: {
      paddingBottom: '10px !important',
    },
    fileDetailsWrapper: {
      display: 'flex',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      color: 'gray',
    },
    uploadText: {
      fontSize: '14px !important',
    },
    pdfIcon: {
      margin: '0 1px 0 8px',
      color: 'gray !important',
    },
  };
});

export default useContractTableStyles;
