/* eslint-disable @typescript-eslint/no-unused-vars */
import axiosInstance from '../axiosInstance';
import { ContractDocumentList, GetProjectParams } from './contractTableTypes';

export const PATHS = Object.freeze({
  CONTRACT_BY_ID: (project_id: string) => `/project/contract-listing/${project_id}`,
  CONTRACT: (project_id: string) => `/project/${project_id}/contract`,
  PROJECT: (project_id: string) => `/crm/opportunity/${project_id}`,
  OPPORTUNITY_LISTING: (project_id: string) => `/crm/opportunity/listing/${project_id}`,
  EDIT_CONTRACT: (project_uid: string, contract_id: string) =>
    `/project/${project_uid}/contract/${contract_id}`,
});

const getContractDocs = async (
  getProjectParams: GetProjectParams,
): Promise<ContractDocumentList> => {
  const response = await axiosInstance.get(`${PATHS.CONTRACT_BY_ID(getProjectParams.project_id)}`);
  return response.data;
};

const addContractDocs = async (project_id: string, contractparams: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.CONTRACT(project_id)}`, contractparams);
  return response;
};

const getProject = async (getProjectParams: GetProjectParams): Promise<ContractDocumentList> => {
  const response = await axiosInstance.get(`${PATHS.PROJECT(getProjectParams.project_id)}`);
  return response.data[0];
};

const getOpportunityProject = async (
  getProjectParams: GetProjectParams,
): Promise<ContractDocumentList> => {
  const response = await axiosInstance.get(
    `${PATHS.OPPORTUNITY_LISTING(getProjectParams.project_id)}`,
  );
  return response.data[0];
};

const updateContractDocs = async (
  project_uid: string,
  contract_id: string,
  contractparams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.EDIT_CONTRACT(project_uid, contract_id)}`,
    contractparams,
  );
  return response;
};

export interface ContractTableAPIClient {
  readonly getContractDocs: (getProjectParams: GetProjectParams) => Promise<ContractDocumentList>;
  readonly addContractDocs: (project_id: string, contractparams: any) => Promise<any>;
  readonly getProject: (getProjectParams: any) => Promise<any>;
  readonly getOpportunityProject: (
    getProjectParams: GetProjectParams,
  ) => Promise<ContractDocumentList>;
  readonly updateContractDocs: (
    project_uid: string,
    contract_id: string,
    contractparams: any,
  ) => Promise<any>;
}

const ContractTableClient: ContractTableAPIClient = Object.freeze({
  getContractDocs,
  addContractDocs,
  getProject,
  getOpportunityProject,
  updateContractDocs,
});

export default ContractTableClient;
