/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { DeleteProjectResourceParams, ProjectDetail } from '../../api/Project/projectTypes';
import { ResourceProjectApiParams } from '../../api/Resource/resourceTypes';
import ResourceClient, { PATHS } from '../../api/Resource/resourceAPIs';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../contexts/GlobalContext';
import { AccountTypes } from '../../utils/accountTypes';
import { ApiOperations, UserGroupRoles } from '../../utils/utils';
import { billingStatusOptions, getFormattedProjects } from './projectFormUtils';
import useResourceDetailStyles from './ResourceDetailStyles';
import I18nKey from '../../translations/I18nKey';
import MisDialog from '../../components/MisDialog/MisDialog';
import DeleteInfo from '../Project/DeleteInfo';
import ProjectClient from '../../api/Project/projectAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface ProjectFormProps {
  readonly projectDetail: any;
  readonly availableProjects: any;
  readonly projects: ProjectDetail[];
  readonly index: number;
  readonly primaryProject: number | null;
  readonly handleSuccessfulSubmit: () => void;
  readonly setDisableAddBtn: React.Dispatch<React.SetStateAction<boolean>>;
  readonly employeeUid: string;
  readonly currManager?: any;
  readonly newManager?: any;
}

const BillingRateLabel: Record<string, string> = {
  [AccountTypes.FIXED_PRICE]: 'Billing Rate',
  [AccountTypes.TIME_MATERIAL_HOURLY]: 'Billing Rate ($/hr)',
  [AccountTypes.TIME_MATERIAL_MONTHLY]: 'Billing Rate ($/mo)',
};

const validationSchema = Yup.object().shape({
  project_uid: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .required('Project is required')
    .nullable(),
  isPrimary: Yup.boolean(),
  isBilled: Yup.object().required('Billing status is required'),
  allocationPercentage: Yup.number()
    .max(100, "Allocation percentage can't be greater than 100")
    .required('Allocation percentage is required')
    .min(1, 'Allocation percentage should be greater than 0'),
  billingRate: Yup.number().when(['isBilled', 'project_uid'], {
    is: (isBilled: any, project_uid: any) =>
      project_uid.accountType !== AccountTypes.FIXED_PRICE && Boolean(isBilled?.value),
    then: Yup.number()
      .required('Billing rate is required')
      .min(1, 'Billing rate should be greater than 0'),
    otherwise: Yup.number(),
  }),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), "End date can't be before Start date")
    .required('End date is required'),
});

const ProjectForm: React.FC<ProjectFormProps> = ({
  projectDetail,
  availableProjects,
  projects,
  index,
  primaryProject,
  handleSuccessfulSubmit,
  setDisableAddBtn,
  employeeUid: resourceId,
}) => {
  const [fields, setFields] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const styles = useResourceDetailStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const { checkAccess, employeeUid, groupNames } = useContext(GlobalContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState<DeleteProjectResourceParams>({
    endDate: dayjs().format('YYYY-MM-DD'),
    comments: '',
  });

  const getInitialValues = (details: any) => {
    const initVal = {
      project_uid: details.projectUid
        ? getFormattedProjects(projects).find(
            (proj: { label: string; value: string }) => proj.value === details.projectUid,
          )
        : null,
      isBilled: billingStatusOptions.find((el) => el.value === details.isBilled),
      allocationPercentage: details.allocationPercentage,
      billingRate: details.billingRate,
      startDate: details.startDate,
      endDate: details.endDate,
      isPrimary: Boolean(details.isPrimary),
    };

    setFields(initVal);
  };

  useEffect(() => {
    getInitialValues(projectDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, projects]);

  const handleSubmit = (values: any) => {
    const updatedFormValues: ResourceProjectApiParams = {
      isPrimary: Number(values.isPrimary),
      isBilled: values.isBilled?.value,
      allocationPercentage: values.allocationPercentage || 0,
      billingRate: values.isBilled?.value ? values.billingRate : 0,
      startDate: values.startDate,
      endDate: values.endDate,
    };

    if (projectDetail.projectUid) {
      // Edit
      ResourceClient.updateResourceProject(resourceId, projectDetail.projectUid, updatedFormValues)
        .then((res) => {
          showSnackbar(res, 'success');
          handleSuccessfulSubmit();
        })
        .catch((e) => showSnackbar(e, 'error'));
    } else {
      // New
      ResourceClient.createResourceProject(resourceId, values.project_uid.value, updatedFormValues)
        .then((res) => {
          showSnackbar(res, 'success');
          handleSuccessfulSubmit();
          setDisableAddBtn(false);
        })
        .catch((e) => showSnackbar(e, 'error'));
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDelete = () => {
    setOpenDialog(false);
    if (resourceId && projectDetail.projectUid) {
      setIsLoading(true);
      ProjectClient.deleteProjectResource(projectDetail.projectUid, resourceId, deleteInfo)
        .then((result) => {
          if (result?.detail) {
            handleSuccessfulSubmit();
            showSnackbar(result, 'success', 'Employee Project mapping deleted successfully!');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  return projectDetail && fields ? (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={fields}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid } = formik;
          return (
            <Form className={styles.formContainer}>
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  {projectDetail.projectUid ? (
                    <Box className={styles.projectWrapper}>
                      <Typography fontWeight={500} fontSize={14}>
                        Project:{' '}
                      </Typography>
                      {projectDetail.projectName}
                    </Box>
                  ) : (
                    <SimpleAutocompleteField
                      name="project_uid"
                      key="project_uid"
                      label="Project"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={getFormattedProjects(
                        projectDetail.projectUid ? projects : availableProjects,
                      )}
                      inputProps={{ style: { fontSize: 13 } }}
                      values={values}
                      className={styles.formField}
                      disabled={Boolean(projectDetail.projectUid)}
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={3.5}>
                  <SimpleAutocompleteField
                    name="isBilled"
                    key="isBilled"
                    label="Billing Status"
                    size="small"
                    inputProps={{ style: { fontSize: 13 } }}
                    setFieldValue={setFieldValue}
                    options={billingStatusOptions}
                    values={values}
                    className={styles.formField}
                    required
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <SimpleInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 13 } }}
                    name="allocationPercentage"
                    type="number"
                    label="Allocation %"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={5}>
                  <SimpleInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 13 } }}
                    name="billingRate"
                    type="number"
                    size="small"
                    label={
                      BillingRateLabel[values.project_uid?.accountType || AccountTypes.FIXED_PRICE]
                    }
                    required={
                      values.isBilled?.value &&
                      values.project_uid?.accountType !== AccountTypes.FIXED_PRICE
                    }
                    fullWidth
                    disabled={values.project_uid?.accountType === AccountTypes.FIXED_PRICE}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 13 } }}
                    name="startDate"
                    type="date"
                    label="Start Date"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 13 } }}
                    name="endDate"
                    type="date"
                    label="End Date"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6} className={styles.primaryCheck}>
                  <SimpleCheckboxField
                    name="isPrimary"
                    checked={values.isPrimary}
                    inputProps={{ style: { fontSize: 13 } }}
                    setFieldValue={setFieldValue}
                    disabled={primaryProject !== null && primaryProject !== index}
                    label="Set as primary"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} className={styles.btnWrapper}>
                  {(resourceId !== employeeUid ||
                    groupNames.includes(UserGroupRoles.SUPERUSER) ||
                    groupNames.includes(UserGroupRoles.SYSTEM_ADMIN)) &&
                    (checkAccess(PATHS.EMPLOYEE_PROJECT('id', 'id'), ApiOperations.POST) ||
                      checkAccess(PATHS.EMPLOYEE_PROJECT('id', 'id'), ApiOperations.PUT)) && (
                      <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                        Save
                      </Button>
                    )}
                </Grid>
                <Grid item xs={6}>
                  {(resourceId !== employeeUid || groupNames.includes(UserGroupRoles.SUPERUSER)) &&
                    Boolean(projectDetail.projectUid) && (
                      <Button
                        sx={{
                          textTransform: 'none',
                        }}
                        className={styles.deleteBtn}
                        onClick={() => setOpenDialog(true)}>
                        Deallocate
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.RESOURCE_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.RESOURCE_DETAILS_UNASSIGN,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_UNASSIGN,
        })}
        additionalInfoSection={<DeleteInfo setDeleteInfo={setDeleteInfo} />}
      />
    </>
  ) : null;
};

export default ProjectForm;
