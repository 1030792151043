import React from 'react';
import { OTHERS, PERSONAL, PROFESSIONAL } from '../../utils/docTypes';
import EmpDocGrid from './EmpDocGrid';
import EmpDocTable from './EmpDocTable';

interface EmpDocContentProps {
  rows: any;
  tableView?: boolean;
  onDocRemove?: Function;
  employeeUid?: any;
  id: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS;
  usedBy?: 'others' | 'profile';
}

const EmpDocContent: React.FC<EmpDocContentProps> = ({
  rows,
  tableView,
  onDocRemove,
  employeeUid,
  id,
  usedBy,
}) => {
  return tableView ? (
    <EmpDocTable
      rows={rows}
      onDocRemove={onDocRemove}
      id={id}
      employeeUid={employeeUid}
      usedBy={usedBy}
    />
  ) : (
    <EmpDocGrid rows={rows} />
  );
};

EmpDocContent.defaultProps = {
  tableView: true,
  onDocRemove: () => {},
  employeeUid: null,
  usedBy: 'others',
};

export default EmpDocContent;
