import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';

const DeleteInfo: React.FC<any> = ({ setDeleteInfo }) => {
  const handleChange = (values: any): any => {
    setDeleteInfo(values);
  };

  return (
    <Formik
      initialValues={{ endDate: dayjs().format('YYYY-MM-DD'), comments: '' }}
      onSubmit={() => {}}
      enableReinitialize>
      {(formik) => {
        const { values } = formik;
        return (
          <Form onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleInputField
                  name="endDate"
                  label="End Date of Resource"
                  variant="standard"
                  type="date"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleInputField
                  name="comments"
                  label="Comments"
                  variant="standard"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeleteInfo;
