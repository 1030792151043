import { Theme } from '@aws-amplify/ui-react';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => {
  return {
    wrapper: {
      fontSize: '12px !important',
      textDecoration: 'none !important',
    },
  };
});
