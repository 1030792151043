import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllContractsStyles = makeStyles((theme: Theme) => {
  return {
    contractsWrapper: {
      padding: '1rem',
    },
    allContractsWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .past-date': {
        color: theme.palette.status?.danger,
      },
      '& .due-date': {
        color: theme.palette.status?.warning,
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',

      '& span': {
        fontSize: '24px',
      },
    },
    tabs: {
      backgroundColor: theme.palette.appBackground?.main,
      paddingBottom: 10,

      '& .tabBtn': {
        marginRight: 16,
        backgroundColor: theme.palette.background.default,
      },
      '& .MuiTab-root': {
        textTransform: 'capitalize',
      },
      '& .MuiTab-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.lighter,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  };
});

export default useAllContractsStyles;
