import { makeStyles } from '@mui/styles';

const useHeaderStyles = makeStyles(() => {
  return {
    logoArea: {
      display: 'flex',
      paddingTop: '19px',
      marginLeft: '12px',
    },
    text: {
      paddingLeft: '2px',
      paddingTop: '4px',
      fontWeight: 400,
      color: 'black',
    },
    image: {
      width: 90,
      height: 33,
      marginLeft: '7px',
      marginTop: '-5px',
    },
    notificationIcon: { color: 'black' },
    link: {
      textDecoration: 'none',
      color: 'black',
    },
  };
});

export default useHeaderStyles;
