import axiosInstance from '../axiosInstance';
import {
  GetCustomerAccountsParams,
  OpportunityContactParams,
  OpportunityCustomerList,
  CustomerAccoutList,
  OpportunityCustomerParams,
  OpportunityCustomer,
  OpportunityAccountParams,
  OpportunityParams,
  AllOpportunities,
  GetOpportunityParams,
  GetCustomerParams,
  Opportunity,
  OpportunityDetail,
  Employee,
  UpdateCRMAccountParams,
  DeleteOpportunityParams,
  CRMContactRemarksParams,
} from './opportunityTypes';

export const PATHS = Object.freeze({
  CRM_CUSTOMER: '/crm/customer',
  CRM_CUSTOMER_LISTING: (id: string) => `/crm/customer/${id}/listing`,
  CRM_CONTACT: '/crm/contact',
  CRM_OPPORTUNITY: '/crm/opportunity',
  CRM_ACCOUNT: '/crm/account',
  GET_CRM_OPPORTUNITY_DETAIL: (opportunity_id: string) => `/crm/opportunity/${opportunity_id}`,
  GET_CRM_OPPORTUNITY_LISTING_DETAIL: (opportunity_id: string) =>
    `/crm/opportunity/listing/${opportunity_id}`,
  PUT_CRM_OPPORTUNITY: (opportunity_id: string) => `/crm/opportunity/${opportunity_id}`,
  GET_EMPLOYEE: '/employee/',
  GET_EMPLOYEE_LIST: '/employee/listing',
  CONTACT_LIST: '/crm/contact/listing',
  PUT_CRM_CONTACT_REMARKS: '/crm/contact/',
});

const getCustomerRelationList = async (): Promise<AllOpportunities[]> => {
  const response = await axiosInstance.get(`${PATHS.CRM_OPPORTUNITY}`);
  return response.data;
};

const getAllCustomerList = async (): Promise<OpportunityCustomerList[]> => {
  const response = await axiosInstance.get(PATHS.CRM_CUSTOMER);
  return response.data;
};

const getAllAccounts = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.CRM_ACCOUNT);
  return response;
};

const getCustomerAccounts = async (
  getCustomerAccountsParams: GetCustomerAccountsParams,
): Promise<[CustomerAccoutList[]]> => {
  const response = await axiosInstance.get(
    `${PATHS.CRM_ACCOUNT}?customer_uid=${getCustomerAccountsParams.customer_uid}`,
  );
  return response.data;
};

const getCrmContactList = async (): Promise<OpportunityContactParams[]> => {
  const response = await axiosInstance.get(PATHS.CONTACT_LIST);
  return response.data;
};

const addCRMContact = async (addCRMContactParams: OpportunityContactParams): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CRM_CONTACT, addCRMContactParams);
  return response;
};

const addCRMCustomer = async (addCRMCustomerParams: OpportunityCustomerParams): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CRM_CUSTOMER, addCRMCustomerParams);
  return response;
};

const updateCustomer = async (
  updateCustomerParams: OpportunityCustomerParams,
  customerId: string,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.CRM_CUSTOMER}/${customerId}`,
    updateCustomerParams,
  );
  return response;
};

const deleteCustomer = async (customerId: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CRM_CUSTOMER}/${customerId}`);
  return response;
};

const addCRMAccount = async (addCRMAccountParams: OpportunityAccountParams): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CRM_ACCOUNT, addCRMAccountParams);
  return response;
};

const updateCRMAccount = async (
  updateCRMAccountParams: UpdateCRMAccountParams,
  accoundId: string,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.CRM_ACCOUNT}/${accoundId}`,
    updateCRMAccountParams,
  );
  return response;
};

const deleteCRMAccount = async (accoundId: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CRM_ACCOUNT}/${accoundId}`);
  return response;
};

const addCRMOpportunity = async (addCRMOpportunityParams: OpportunityParams): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CRM_OPPORTUNITY, addCRMOpportunityParams);
  return response;
};

const updateCRMOpportunity = async (
  opportunityId: string,
  updateCRMOpportunityParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_CRM_OPPORTUNITY(opportunityId),
    updateCRMOpportunityParams,
  );
  return response;
};

const getCRMOpportunityDetail = async (
  getOpportunityParams: GetOpportunityParams,
): Promise<OpportunityDetail[]> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_CRM_OPPORTUNITY_DETAIL(getOpportunityParams.project_id)}`,
  );
  return response.data;
};

const getOpportunity = async (params: GetOpportunityParams): Promise<Opportunity> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_CRM_OPPORTUNITY_DETAIL(params.project_id)}`,
  );
  return response.data[0];
};

const getOpportunityListing = async (params: GetOpportunityParams): Promise<Opportunity> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_CRM_OPPORTUNITY_LISTING_DETAIL(params.project_id)}`,
  );
  return response.data[0];
};

const getCustomer = async (params: GetCustomerParams): Promise<any> => {
  const response = await axiosInstance.get(
    `${PATHS.CRM_CUSTOMER}?customer_id=${params.customer_id}`,
  );
  return response.data[0];
};

const getCustomerListing = async (params: GetCustomerParams): Promise<any> => {
  const response = await axiosInstance.get(PATHS.CRM_CUSTOMER_LISTING(params.customer_id));
  return response.data[0];
};

const getEmployeeList = async (): Promise<Employee[]> => {
  const response = await axiosInstance.get(PATHS.GET_EMPLOYEE_LIST);
  return response.data;
};

const updateContact = async (contactId: string, updateContactParams: any): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.CRM_CONTACT}/${contactId}`,
    updateContactParams,
  );
  return response;
};

const deleteContact = async (contactId: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CRM_CONTACT}/${contactId}`);
  return response;
};

const deleteOpportunity = async (
  opportunityId: string,
  deleteOpportunityParams: DeleteOpportunityParams,
): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CRM_OPPORTUNITY}/${opportunityId}`, {
    data: deleteOpportunityParams,
  });
  return response;
};
const updateCrmContactRemarks = async (
  UpdateCRMContactRemarksParams: CRMContactRemarksParams[],
): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.PUT_CRM_CONTACT_REMARKS,
    UpdateCRMContactRemarksParams,
  );
  return response;
};

export interface CustomerRelationAPIClient {
  // TODO: change return type from any to GetCustomerRelationsParams while API integration
  readonly getCustomerRelationList: () => Promise<any>;
  readonly getAllCustomerList: () => Promise<any>;
  readonly getAllAccounts: () => Promise<any>;
  readonly getCustomerAccounts: (
    getCustomerAccountsParams: GetCustomerAccountsParams,
  ) => Promise<any>;
  readonly getCrmContactList: () => Promise<any>;
  readonly addCRMContact: (addCRMContactParams: OpportunityContactParams) => Promise<any>;
  readonly addCRMCustomer: (addCRMCustomerParams: OpportunityCustomerParams) => Promise<any>;
  readonly updateCustomer: (
    updateCustomerParams: OpportunityCustomerParams,
    customerId: string,
  ) => Promise<any>;
  readonly deleteCustomer: (customerId: string) => Promise<any>;
  readonly addCRMAccount: (addCRMAccountParams: OpportunityAccountParams) => Promise<any>;
  readonly updateCRMAccount: (
    updateCRMAccountParams: UpdateCRMAccountParams,
    accoundId: string,
  ) => Promise<any>;
  readonly deleteCRMAccount: (accoundId: string) => Promise<any>;
  readonly addCRMOpportunity: (addCRMOpportunityParams: OpportunityParams) => Promise<any>;
  readonly updateCRMOpportunity: (
    opportunityId: string,
    updateCRMOpportunityParams: any,
  ) => Promise<any>;
  readonly getOpportunity: (params: GetOpportunityParams) => Promise<Opportunity>;
  readonly getOpportunityListing: (params: GetOpportunityParams) => Promise<Opportunity>;
  readonly getCRMOpportunityDetail: (getOpportunityParams: GetOpportunityParams) => Promise<any>;
  readonly getCustomer: (params: GetCustomerParams) => Promise<OpportunityCustomer>;
  readonly getCustomerListing: (params: GetCustomerParams) => Promise<any>;
  readonly getEmployeeList: () => Promise<Employee[]>;
  readonly updateContact: (contactId: string, updateContactParams: any) => Promise<any>;
  readonly deleteContact: (contactId: string) => Promise<any>;
  readonly deleteOpportunity: (
    opportunityId: string,
    deleteOpportunityParams: DeleteOpportunityParams,
  ) => Promise<any>;
  readonly updateCrmContactRemarks: (
    UpdateCRMContactRemarksParams: CRMContactRemarksParams[],
  ) => Promise<any>;
}

const CustomerRelationClient: CustomerRelationAPIClient = Object.freeze({
  getCustomerRelationList,
  getAllCustomerList,
  getAllAccounts,
  getCustomerAccounts,
  getCrmContactList,
  addCRMContact,
  addCRMCustomer,
  updateCustomer,
  deleteCustomer,
  addCRMAccount,
  updateCRMAccount,
  deleteCRMAccount,
  addCRMOpportunity,
  updateCRMOpportunity,
  getOpportunity,
  getOpportunityListing,
  getCRMOpportunityDetail,
  getCustomer,
  getCustomerListing,
  getEmployeeList,
  updateContact,
  deleteContact,
  deleteOpportunity,
  updateCrmContactRemarks,
});

export default CustomerRelationClient;
