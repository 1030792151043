import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => {
  return {
    addEmployeeWrapper: {
      padding: '1rem',
    },
    btnLabel: {
      padding: '2px 5px',
      marginRight: '10px',
      alignSelf: 'center',
      borderRadius: '3px',
    },
    inverted: {
      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(135deg)`,
        transform: `rotate(135deg)`,
      },
    },
    Header: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: '600',
      display: 'flex',
      justifyContent: 'space-between',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    contentWrapper: {
      display: 'flex',
      backgroundColor: theme.palette.primary?.light,
      borderRadius: '8px',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
    },
    closeBtn: {
      margin: '7px !important',
      '& > span': {
        textTransform: 'none',
      },
    },
    formContainer: {
      minHeight: '450px',
      minWidth: '100%',
      position: 'relative',

      '& .divider-chip': {
        backgroundColor: `rgb(2, 118, 213, 0.1)`,
        color: theme.palette.secondary.light,
      },
    },
    DocformContainer: {
      position: 'unset',
    },
    formWrapper: {
      paddingBottom: '3rem',

      '& .upload-button': {
        position: 'absolute',
        bottom: 23,
        right: 23,
      },
    },
    formField: {
      '& input': {
        height: '22px',
      },
    },
    options: {
      listStyleType: 'none',
      position: 'absolute',
      bottom: '55px',
      right: 0,
      width: '225px',
      WebkitTransition: '-webkit-transform 300ms',
      transition: 'transform 300ms',
      paddingLeft: '0 !important',

      '& li': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '5px',
        margin: '5px',
        borderRadius: 8,
        background: theme.palette.primary.main,
        boxShadow: `0px 0px 8px ${theme.palette.shadow?.main}`,
      },
    },
    checkbox: {
      display: 'flex',
      'align-items': 'center',
    },
    tabs: {
      '& .MuiTab-root': {
        textTransform: 'capitalize',
      },
      '& .MuiTabs-flexContainer': {
        height: '60px !important',
      },

      '& button': {
        paddingBottom: '20px',
        height: '60px !important',
      },
      '& .MuiTab-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.lighter,
      },
    },
    root: {
      backgroundColor: '#00000014 !important',
      '& .MuiAccordionSummary-content': {
        color: '#333',
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
      },
    },
    dataBox: {
      position: 'relative',
      border: '1px solid #0000001f',
      padding: '25px',
      borderRadius: '5px',
      marginTop: '25px',
      boxShadow: '0px 1px 1px grey',
      display: 'flex',
    },
    addBtn: {
      '& svg': {
        fontSize: '40px',
      },
    },
    dataRemoveBtn: {
      backgroundColor: `${theme.palette.tertiary?.main} !important`,
      borderRadius: '50% !important',
      padding: 0,
      minWidth: '17px !important',
      top: '-16px',
      right: '-16px',
      fontWeight: 700,

      '& svg': {
        fontSize: '22px',
        color: 'black !important',
      },
    },
    deleteBtn: {
      margin: '16px !important',
      top: '0px',
      right: '0px',
      backgroundColor: `${theme.palette.status?.success} !important`,
      color: '#fff !important',
    },
    submitBtn: {
      position: 'absolute',
      margin: '16px !important',
      top: '0px',
      right: '0px',
      backgroundColor: `${theme.palette.status?.success} !important`,
      color: '#fff !important',
      '&:disabled': {
        backgroundColor: '#E0E0E0 !important',
      },
    },
    closeIcon: {
      cursor: 'pointer',
      padding: '2px',
    },
  };
});
