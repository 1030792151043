import axios from 'axios';
import dayjs from 'dayjs';

export const fetchExchangeRates = async () => {
  return axios
    .get('https://v6.exchangerate-api.com/v6/610015cd4de3321e471e50c5/latest/USD')
    .then((res: any) => {
      localStorage.setItem('exchangeRates', JSON.stringify(res.data));
    })
    .catch((e) => {
      // eslint-disable-next-line no-alert
      alert(e);
    });
};

export const getExchangeRates = () => {
  const exchangeRates: any = localStorage.getItem('exchangeRates');
  return exchangeRates ? JSON.parse(exchangeRates) : null;
};

export const setExchangeRates = () => {
  const exchangeRates = getExchangeRates();
  if (exchangeRates) {
    if (
      !dayjs(dayjs().format('YYYY-MM-DD')).isSame(
        dayjs(exchangeRates.time_last_update_utc, { format: 'ddd, DD MMM YYYY HH:mm:ss Z' }).format(
          'YYYY-MM-DD',
        ),
      )
    )
      fetchExchangeRates();
  } else {
    fetchExchangeRates();
  }
};

export const getConvertedCurrency = (amount: number, currency: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { conversion_rates } = getExchangeRates();
  return Math.round(amount / conversion_rates[currency]);
};

export const getConvertedResult = (rows: any, field: string) => {
  return rows.map((row: any) => {
    const result = row[field].map((revObj: any) => {
      return { ...revObj, revenue: getConvertedCurrency(revObj.revenue, row.currency) };
    });
    return { ...row, [field]: result };
  });
};
