import { Button, Chip, Divider, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { MuiTelInput } from 'mui-tel-input';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useAddEmployeeStyles from './AddEmployeeStyles';
import OnboardingClient, { PATHS } from '../../api/OnBoarding/onboardingAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import {
  ApiOperations,
  cleanFormData,
  hasSuperUserAccess,
  UserGroupRoles,
} from '../../utils/utils';
import I18nKey from '../../translations/I18nKey';
import { basicDetailFormValidation, removeSpecialChars } from './utils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';

interface AddEmployeeBasicDetailsProps {
  formState: any;
  gender: any;
  bloodGroup: any;
  employeeUid: any;
  employees: any;
  allSkills: any;
  allDepartments: any;
  allTracks: any;
  allDesignations: any;
  employeeStatus: any;
  employmentType: any;
  employeeType: any;
  inactiveEmployees: any;
  allGroupRoles: any;
  primarySkillsUid: any;
  otherSkillsUid: any;
  otherDetailsUid: any;
  uidEmployeeManager: any;
  formRef: any;
  updateFetch: () => void;
}

const AddEmployeeBasicDetails: React.FC<AddEmployeeBasicDetailsProps> = ({
  formState,
  gender,
  bloodGroup,
  allDepartments,
  allDesignations,
  allSkills,
  allTracks,
  employeeStatus,
  employeeType,
  employees,
  employmentType,
  employeeUid,
  inactiveEmployees,
  allGroupRoles,
  primarySkillsUid,
  otherSkillsUid,
  otherDetailsUid,
  uidEmployeeManager,
  formRef,
  updateFetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tracks, setTracks] = useState(allTracks);
  const [designations, setDesignations] = useState(allDesignations);
  const [empUid, setEmpUid] = useState<string | null>(employeeUid);
  const [failedAPIs, setFailedAPIs] = useState([]);
  const [skills, setSkills] = useState(
    allSkills.filter((s: any) => !formRef?.current?.values.otherSkills.includes(s)),
  );
  const [otherSkills, setOtherSkills] = useState(
    allSkills.filter((s: any) => !formRef?.current?.values.skills.includes(s)),
  );

  const styles = useAddEmployeeStyles();
  const { checkAccess } = useContext(GlobalContext);
  const { groupNames } = useContext(GlobalContext);

  const { showSnackbar } = useDisplaySnackbar();
  const navigate = useNavigate();
  const intl = useIntl();
  const [showInactiveEmployees, setShowInactiveEmployees] = useState<boolean>(false);
  const [isSysAdmin, setIsSysAdmin] = useState<boolean>(hasSuperUserAccess(groupNames));

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    setShowInactiveEmployees(value);
  };
  const params = useParams();
  const employeeStatusById = params.status || '';
  const updateworkEmail = () => {
    const { email, firstName, lastName } = formRef?.current.values;
    if (!employeeUid) {
      if (!email) {
        if (firstName && lastName) {
          const formattedFirstName = removeSpecialChars(firstName);
          const formattedLastName = removeSpecialChars(lastName);
          const emailStr = `${formattedFirstName}.${formattedLastName}@techprescient.com`;
          formRef?.current?.setFieldValue('email', emailStr);
        }
      }
    }
  };

  const addSkills = (uid: any) => {
    const primarySkillIds: any[] = [];
    const otherSkillIds: any[] = [];
    formRef?.current?.values.skills.forEach((item: any) => {
      primarySkillIds.push(item.value);
    });
    formRef?.current?.values.otherSkills.forEach((item: any) => {
      otherSkillIds.push(item.value);
    });
    const addSkillsParams = [
      {
        skillIds: primarySkillIds,
        isPrimary: true,
      },
      {
        skillIds: otherSkillIds,
        isPrimary: false,
      },
    ];
    return OnboardingClient.addSkills(uid, addSkillsParams);
  };

  const getAddDesignationParams = (values: any) => ({
    startDate: values.designationEffectiveDate,
    designationId: values.designation?.uid,
  });

  const addDesignation = (uid: any, values: any) => {
    return OnboardingClient.addDesignation(uid, getAddDesignationParams(values));
  };

  const getAddManagerParams = (values: any) => ({
    managerUid: values.manager?.value || null,
    startDate: values.managerStartDate,
  });

  const getGroupRolesParams = (uid: any, values: any) => {
    const groupRoles = values.groupNames.map((role: any) => {
      return role.code;
    });
    return { uid: otherDetailsUid, employeeUid: uid, groupNames: groupRoles };
  };

  const getOtherDetailsParams = (uid: any, values: any) => {
    return [
      {
        uid: otherDetailsUid || undefined,
        employeeUid: uid,
        remarks: values.remarks,
      },
    ];
  };

  const addManager = (uid: any, values: any) => {
    return OnboardingClient.addManager(uid, getAddManagerParams(values));
  };

  const saveGroupRoles = (uid: any, values: any) => {
    return OnboardingClient.updateGroupNames(cleanFormData(getGroupRolesParams(uid, values)));
  };

  const saveOtherDetails = (uid: any, values: any) => {
    return OnboardingClient.updateOtherDetails(getOtherDetailsParams(uid, values));
  };

  const apiList: Record<number, any> = {
    0: addDesignation,
    1: addSkills,
    2: addManager,
    3: saveOtherDetails,
    4: saveGroupRoles,
  };

  const updateEmployeeSkills = (uid: any, values: any) => {
    const primarySkillIds: any[] = [];
    const otherSkillIds: any[] = [];
    values.skills.forEach((item: any) => {
      primarySkillIds.push(item.value);
    });
    values.otherSkills.forEach((item: any) => {
      otherSkillIds.push(item.value);
    });
    if (!otherSkillsUid) {
      const addEmployeeSkillsParams = [
        {
          skillIds: otherSkillIds,
          isPrimary: false,
        },
      ];
      const updateEmployeeSkillsParams = [
        {
          skillIds: primarySkillIds,
          uid: primarySkillsUid,
        },
      ];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateApiList[4] = OnboardingClient.addSkills(uid, addEmployeeSkillsParams);
      return OnboardingClient.updateEmployeeSkills(uid, updateEmployeeSkillsParams);
    }
    const updateEmployeeSkillsParams = [
      {
        skillIds: primarySkillIds,
        uid: primarySkillsUid,
      },
      {
        skillIds: otherSkillIds,
        uid: otherSkillsUid,
      },
    ];
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (updateApiList[4]) delete updateApiList[4];
    return OnboardingClient.updateEmployeeSkills(uid, updateEmployeeSkillsParams);
  };

  const updateEmployeeManagerParams = (values: any) => ({
    managerUid: values.manager?.value,
    startDate: values.managerStartDate,
  });

  const updateEmployeeManager = (uid: string, values: any) => {
    return OnboardingClient.updateEmployeeManager(
      uid,
      uidEmployeeManager,
      updateEmployeeManagerParams(values),
    );
  };

  const updateEmployeeDesignationParams = (values: any) => ({
    startDate: values.designationEffectiveDate,
    designationId: values.designation?.uid,
  });

  const updateEmployeeDesignation = (uid: string, values: any) => {
    return OnboardingClient.updateEmployeeDesignation(uid, updateEmployeeDesignationParams(values));
  };

  const updateEmployeeParams = (values: any) => ({
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    personalEmail: values.personalEmail,
    dob: values.dob,
    doj: values.doj,
    gender: values.gender?.code,
    nationality: values.nationality,
    phone: values.phone,
    bloodGroup: values.bloodGroup?.code,
    permanentAddress: values.permanentAddress,
    permanentCity: values.permanentCity,
    permanentState: values.permanentState,
    permanentCountry: values.permanentCountry,
    permanentPincode: values.permanentPincode,
    currentAddress: values.currentAddress,
    currentCity: values.currentCity,
    currentState: values.currentState,
    currentCountry: values.currentCountry,
    currentPincode: values.currentPincode,
    linkedinUrl: values.linkedinUrl,
    status: values.employeeStatus?.code,
    employeeType: values.employeeType?.code,
  });

  const updateEmployee = (uid: string, values: any) => {
    return OnboardingClient.updateEmployeeById(uid, updateEmployeeParams(values));
  };

  const updateApiList: Record<number, any> = {
    0: updateEmployeeSkills,
    1: !uidEmployeeManager ? addManager : updateEmployeeManager,
    2: updateEmployeeDesignation,
    3: updateEmployee,
    4: saveOtherDetails,
    5: saveGroupRoles,
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    if (employeeUid && employeeStatusById !== 'newJoinee') {
      if (!values.manager?.value) {
        delete updateApiList[1];
      }
      if (!isSysAdmin) {
        delete updateApiList[5];
      }

      Promise.allSettled(Object.values(updateApiList).map((api) => api(employeeUid, values)))
        .then((response: any) => {
          if (
            response.every(
              (result: { status: string; values: any }) => result.status === 'fulfilled',
            )
          ) {
            showSnackbar(response, 'success', 'Record Updated Successfully');
            navigate(`/onBoarding/addEmployee/${employeeUid}/${values?.employeeStatus?.code}`);
            updateFetch();
          } else {
            const newFailedAPIs: any = [];
            response.forEach((result: { status: string; values: {}; reason: {} }, index: any) => {
              if (result.status === 'rejected') {
                newFailedAPIs.push(failedAPIs[index]);
                showSnackbar(result.reason, 'error');
              }
            });
          }
        })
        .catch((e) => {
          showSnackbar('error in edit save', 'error');
        })
        .finally(() => setIsLoading(false));
    } else if (empUid && failedAPIs.length !== 0 && employeeStatusById !== 'newJoinee') {
      // Calling only failed api's when creating
      Promise.allSettled(failedAPIs.map((num) => apiList[num](empUid, values)))
        .then((response: any) => {
          if (
            response.every(
              (result: { status: string; values: any }) => result.status === 'fulfilled',
            )
          ) {
            navigate(`/onBoarding/addEmployee/${empUid}/${values?.employeeStatus?.code}`);
            updateFetch();
          } else {
            const newFailedAPIs: any = [];
            response.forEach((result: { status: string; values: {}; reason: {} }, index: any) => {
              if (result.status === 'rejected') {
                newFailedAPIs.push(failedAPIs[index]);
                showSnackbar(result.reason, 'error');
              }
            });
            setFailedAPIs(newFailedAPIs);
          }
        })
        .catch((e: any) => {
          // no routing stay on same page
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    } else {
      // Create
      const addEmployeeParams = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        dob: values.dob,
        gender: values.gender?.code,
        nationality: values.nationality,
        phone: values.phone,
        bloodGroup: values.bloodGroup?.code,
        doj: values.doj,
        employeeType: values.employeeType?.code,
        employmentType: values.employmentType?.code,
        status: values.employeeStatus?.code,
        email: values.email,
        personalEmail: values.personalEmail,
        linkedinUrl: values.linkedinUrl,
        oldEmployeeUid: values.inactiveEmployee?.value,
      };

      // Don't call manager API if manager is not set
      if (!values.manager?.value) {
        delete apiList[2];
      }
      if (!isSysAdmin) {
        delete apiList[4];
      }
      const addEmployee =
        employeeStatusById === 'newJoinee'
          ? OnboardingClient.addEmployee(cleanFormData(addEmployeeParams), employeeUid)
          : OnboardingClient.addEmployee(cleanFormData(addEmployeeParams));

      addEmployee
        .then((res: any) => {
          const { uid } = res.data;
          setEmpUid(uid);
          Promise.allSettled(Object.values(apiList).map((api) => api(uid, values)))
            .then((response: any) => {
              if (
                response.every(
                  (result: { status: string; values: any }) => result.status === 'fulfilled',
                )
              ) {
                showSnackbar(res, 'success');
                navigate(
                  `/onBoarding/addEmployee/${res?.data?.uid}/${values?.employeeStatus?.code}`,
                );
                updateFetch();
              } else {
                const newFailedAPIs: any = [];
                response.forEach(
                  (result: { status: string; values: {}; reason: {} }, index: number) => {
                    if (result.status === 'rejected') {
                      newFailedAPIs.push(index);
                      showSnackbar(result.reason, 'error');
                    }
                  },
                );
                setFailedAPIs(newFailedAPIs);
              }
            })
            .catch((e: any) => {
              // no routing stay on same page
              showSnackbar(e, 'error');
            })
            .finally(() => setIsLoading(false));
        })
        .catch((e: any) => {
          showSnackbar(e, 'error');
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}

      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={basicDetailFormValidation}
        innerRef={formRef}
        enableReinitialize
        validateOnMount>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, handleBlur, touched, errors } = formik;

          return (
            <Form className={styles.formWrapper}>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="firstName"
                    type="string"
                    label="First Name"
                    size="small"
                    onBlur={(event: any) => {
                      handleBlur(event);
                      updateworkEmail();
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="middleName"
                    type="string"
                    label="Middle Name"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="lastName"
                    type="string"
                    label="Last Name"
                    size="small"
                    onBlur={(event: any) => {
                      handleBlur(event);
                      updateworkEmail();
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="gender"
                    key="gender"
                    label="Gender"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={gender}
                    values={values}
                    className={styles.formField}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="bloodGroup"
                    key="bloodGroup"
                    label="Blood Group"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={bloodGroup}
                    values={values}
                    className={styles.formField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="nationality"
                    type="string"
                    label="Nationality"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="email"
                    type="email"
                    label="Work Email"
                    size="small"
                    fullWidth
                    required
                    disabled={Boolean(employeeUid) && employeeStatusById !== 'newJoinee'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="personalEmail"
                    type="email"
                    label="Personal Email"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    className={styles.formField}
                    name="dob"
                    type="date"
                    label="Date of Birth"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IN"
                    id="phone"
                    name="phone"
                    label="Phone number"
                    size="small"
                    value={values.phone}
                    onChange={(newValue, info) => {
                      setFieldValue('phone', info.numberValue);
                    }}
                    forceCallingCode
                    splitCallingCode
                    focusOnSelectCountry
                    required
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone ? (errors.phone as any) : null}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="linkedinUrl"
                    type="string"
                    label="LinkedIn URL"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Joining Details" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    className={styles.formField}
                    name="doj"
                    type="date"
                    label="Date of Joining"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="employmentType"
                    key="employmentType"
                    label="Employment Type"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={employmentType}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="employeeType"
                    key="employeeType"
                    label="Employee Type"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={employeeType}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="skills"
                    key="skills"
                    label="Primary Skills"
                    size="small"
                    handleChange={(event: any, value: any) => {
                      setOtherSkills(() => allSkills.filter((s: any) => !value.includes(s)));
                    }}
                    setFieldValue={setFieldValue}
                    options={skills}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                    multiple
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="otherSkills"
                    key="otherSkills"
                    label="Other Skills"
                    size="small"
                    handleChange={(event: any, value: any) => {
                      setSkills(() => allSkills.filter((s: any) => !value.includes(s)));
                    }}
                    setFieldValue={setFieldValue}
                    options={otherSkills}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    multiple
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="employeeStatus"
                    key="employeeStatus"
                    label="Employee Status"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={employeeStatus}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                    disabled={Boolean(employeeUid) && employeeStatusById !== 'newJoinee'}
                  />
                </Grid>
                {!empUid && (
                  <>
                    <Grid item xs={4}>
                      <SimpleCheckboxField
                        name="isOldEmployee"
                        checked={values.isOldEmployee}
                        inputProps={{ style: { fontSize: 13 } }}
                        setFieldValue={setFieldValue}
                        label="Existing employee"
                        handleChange={handleCheckboxChange}
                        size="small"
                      />
                    </Grid>
                    {showInactiveEmployees && (
                      <Grid item xs={4}>
                        <SimpleAutocompleteField
                          name="inactiveEmployee"
                          key="inactiveEmployee"
                          label="Inactive Employee"
                          size="small"
                          setFieldValue={setFieldValue}
                          options={inactiveEmployees}
                          values={values}
                          className={styles.formField}
                          fullWidth
                          required
                        />
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Designation Details" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="department"
                    key="department"
                    label="Department"
                    size="small"
                    handleChange={(event: any, value: any) => {
                      setFieldValue('track', null);
                      setFieldValue('designation', null);
                      setTracks(allTracks.filter((t: any) => value.uid === t.departmentId));
                      setDesignations(
                        allDesignations.filter((t: any) => value.uid === t.departmentId),
                      );
                    }}
                    setFieldValue={setFieldValue}
                    options={allDepartments}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="track"
                    key="track"
                    label="Track"
                    size="small"
                    handleChange={(event: any, value: any) => {
                      setFieldValue('designation', null);
                      setDesignations(allDesignations.filter((t: any) => value.uid === t.trackId));
                    }}
                    setFieldValue={setFieldValue}
                    options={values.department ? tracks : []}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="designation"
                    key="designation"
                    label="Designation"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={values.track ? designations : []}
                    values={values}
                    className={styles.formField}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    className={styles.formField}
                    name="designationEffectiveDate"
                    type="date"
                    label="Effective From"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Manager Details" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="manager"
                    key="manager"
                    label="Manager"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={employees}
                    values={values}
                    className={styles.formField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    className={styles.formField}
                    name="managerStartDate"
                    type="date"
                    label="Effective From"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required={Boolean(values?.manager)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Other Details" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={4}>
                  <SimpleAutocompleteField
                    name="groupNames"
                    key="groupNames"
                    label="Group Names"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={allGroupRoles}
                    values={values}
                    className={styles.formField}
                    multiple
                    disabled={!isSysAdmin}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="remarks"
                    type="string"
                    label="Remarks"
                    size="small"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              {(checkAccess(PATHS.ADD_EMPLOYEE, ApiOperations.POST) ||
                checkAccess(PATHS.GET_EMPLOYEE_BY_ID('id'), ApiOperations.PUT)) && (
                <Button
                  disabled={!dirty || !isValid}
                  variant="contained"
                  type="submit"
                  className="upload-button">
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SAVE,
                  })}
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEmployeeBasicDetails;
