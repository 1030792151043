import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { getFullName } from '../../Opportunity/entitiesFormConfig';
import useProfileStyles from '../ProfileStyles';

const MyTeamCard = ({ nodeDatum }: any) => {
  const { firstName, lastName, profilePic, designationName } = nodeDatum;

  const classes = useProfileStyles();

  return (
    <>
      <Box className={classes.AvatarWrapper}>
        <Avatar alt={firstName} src={profilePic} />
      </Box>
      <Box className={classes.TeamInfoWrapper}>
        <Typography fontWeight={600} fontSize={12}>
          {getFullName({ firstName, lastName })}
        </Typography>
        <i>{designationName}</i>
      </Box>
    </>
  );
};

export default MyTeamCard;
