import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import useDefaultLayoutStyles from './DefaultLayoutStyles';

const DefaultLayout = ({ children }: { children: ReactNode }) => {
  const styles = useDefaultLayoutStyles();

  return (
    <>
      <Header />
      <Box component="main">
        <Toolbar />
        <div className={styles.InnnerContainer}>{children}</div>
      </Box>
      <Footer />
    </>
  );
};

export default DefaultLayout;
