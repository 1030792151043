import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTicketsStyles = makeStyles((theme: Theme) => {
  return {
    ticketWrapper: {
      height: 'calc(100vh - 240px)',
      border: '1px solid #dddddd',
      overflowY: 'auto',
      backgroundColor: 'rgb(246, 248, 250)',
    },
    noTickets: {
      position: 'relative',
      top: '40%',
      textAlign: 'center',
      color: 'grey',
      fontWeight: 500,
      alignItems: 'center',
      verticalAlign: 'middle',
      fontSize: '14px',
    },
    mainBoard: {
      height: '400px',
      border: '8px solid #dddddd',
      overflowY: 'scroll',
      borderEndEndRadius: '6px',
      backgroundColor: 'rgb(246, 248, 250)',
      borderBottomLeftRadius: '6px',

      '&::-webkit-scrollbar': {
        width: '3px',
      },
      '&::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(223, 223, 223, 0.3)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(218, 218, 218)',
        outline: '1px solid rgb(245, 250, 255)',
      },
    },
    ticketLayout: {
      margin: '8px',
      WebkitTransform: 'translateZ(0)',
      transform: 'translateZ(0)',
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      MozOsxFontSmoothing: 'grayscale',
      WebkitTransitionDuration: '0.3s',
      transitionDuration: '0.3s',
      WebkitTransitionProperty: 'transform',
      transitionProperty: 'transform',
      WebkitTransitionTimingFunction: 'ease-out',
      transitionTimingFunction: 'ease-out',
      backgroundColor: 'white',
      color: 'darkslategray',
      borderRadius: '6px',
      cursor: 'grab',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',

      '&:hover, &:focus, &:active': {
        WebkitTransform: 'translateY(-8px)',
        transform: 'translateY(-8px)',
        transition: '5s',
        boxShadow:
          'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
      },
    },
    ticketContent: {
      fontSize: '12px',
      fontWeight: 400,
      padding: '6px',
    },
    statusTimeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    ticketDetails: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1px',
    },
    time: {
      color: 'rgb(0, 0, 0)',
      fontSize: '9px',
      fontWeight: 'bold',
      padding: '1px 3px 2px 0p',
    },
    specialTypography: {
      fontSize: '14px',
      fontWeight: 500,
      padding: '3px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px dashed grey',
      fontFamily: 'Roboto, Arial, sans-serif',
      color: 'black',
      alignItems: 'center',
    },
    ticketFormWrapper: {
      padding: '1rem',
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    buttonHolder: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    statusTime: {
      fontSize: '8px',
      fontWeight: 'bolder',
      color: 'black',
      marginTop: '5px',
    },
    sidePanel: {
      display: 'flex',
    },
    editCreateBtns: {
      marginRight: '100px !important',
      color: 'red !important',
    },
    uploadText: {
      fontSize: '14px !important',
    },
    fileDetailsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '13px !important',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '1px 5px 1px 5px',
    },
    fileInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    pdfIcon: {
      marginRight: '5px',
      width: '15px !important',
    },
    clrIcon: {
      color: 'red !important',
      width: '15px !important',
      cursor: 'pointer',
    },
    fileName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '270px',
    },
    clearIconWrapper: {
      marginLeft: 'auto',
    },
    editIcon: {
      justifyContent: 'flex-end',
      minWidth: '10px !important',
    },
    deleteIcon: {
      justifyContent: 'flex-end',
      minWidth: '10px !important',
      color: '#bf2a34 !important',
    },
  };
});

export default useTicketsStyles;
