import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import I18nKey from '../../../translations/I18nKey';
import EmpDocument from '../../../components/EmployeeDocument/EmpDocument';
import useProfileDocumentStyles from '../ProfileDocumentStyles';

const Document: React.FC<any> = ({ employeeUid }) => {
  const classes = useProfileDocumentStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  const goToAllDocs = () => {
    navigate(`/profile/document/${employeeUid}`);
  };

  return (
    <>
      <Box className={classes.UpperCardWrapper}>
        <Box className={classes.UpperCardHeader}>
          <Typography fontWeight={600}>
            {intl.formatMessage({
              id: I18nKey.PROFILE_MY_DOCUMENTS,
            })}
          </Typography>
          <Button onClick={goToAllDocs} className={classes.AddButton}>
            {intl.formatMessage({
              id: I18nKey.PROFILE_VIEW_ALL,
            })}
          </Button>
        </Box>

        <Box className={classes.DocCardContent}>
          <EmpDocument employeeId={employeeUid} tableView={false} usedBy="profile" />
        </Box>
      </Box>
    </>
  );
};

export default Document;
