import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Datatable from '../../components/Datatable/Datatable';
import {
  columns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
} from './contactListConfig';
import MisDialog from '../../components/MisDialog/MisDialog';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import CustomerRelationClient, {
  PATHS as OpportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import I18nKey from '../../translations/I18nKey';
import { ApiOperations } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';

interface ContactListProps {
  readonly tableRows: any[];
  readonly setSelectedContact: Dispatch<SetStateAction<any>>;
  readonly setIsRefetchContacts: any;
  readonly handleCloseOnDelete: () => void;
}

const ContactList: React.FC<ContactListProps> = ({
  tableRows,
  setSelectedContact,
  setIsRefetchContacts,
  handleCloseOnDelete,
}) => {
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const { id } = useParams();
  const { checkAccess } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [deleteContactId, setDeleteContactId] = useState('');

  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteContactId(params.id);
      },
      path: OpportunityPaths.CRM_CONTACT,
      operation: ApiOperations.DELETE,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel(
      { showColumns },
      rowActions.filter(({ path, operation }) => checkAccess(path, operation)),
      setSelectedContact,
    ),
  );

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedRemarks = updatedRows.map((r) => {
      return { uid: r.row.uid, remarks: r.row.remarks };
    });
    CustomerRelationClient.updateCrmContactRemarks(updatedRemarks)
      .then((result) => {
        setIsRefetchContacts(true);
        showSnackbar(result, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteContactId) {
      setIsLoading(true);
      CustomerRelationClient.deleteContact(deleteContactId)
        .then((result) => {
          if (id && deleteContactId === id) handleCloseOnDelete();
          setIsRefetchContacts(true);
          showSnackbar(result, 'success');
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteContactId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteContactId('');
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Datatable
        rows={tableRows}
        columns={columns(
          rowActions.filter(({ path, operation }) => checkAccess(path, operation)),
          setSelectedContact,
        )} /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`Contact_List_${dayjs().format('DD_MMMM')}`}
        editable={true}
        updateRows={handleRowDataChange}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.CONTACT_TITLE,
        })}
        message="Are you sure you want to delete this contact?"
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

export default ContactList;
