import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useParams, useLocation } from 'react-router-dom';
import InvoiceClient from '../../api/Invoice/invoiceAPIs';
import {
  GetInvoiceParams,
  InvoiceUploadType,
  OtherDetail,
  ProjectResource,
} from '../../api/Invoice/invoiceTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import SystemInvoiceDetail from './SystemInvoiceDetail';
import ManualInvoiceDetail from './ManualInvoiceDetail';

const InvoiceDetail = () => {
  const [cardInfo, setCardInfo] = useState<any>({});
  const [invoiceResourceInfo, setInvoiceResourceInfo] = useState<ProjectResource[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // This state is used to refetch the invoice detail data.
  const [fetch, setFetch] = useState(0);
  const [otherDetails, setOtherDetails] = useState<OtherDetail[]>([]);
  const [manualInvoiceDetails, setManualInvoiceDetails] = useState<any>(null);

  const params = useParams();
  const styles = useInvoiceDetailStyles();
  const intl = useIntl();
  const location = useLocation();
  const { showSnackbar } = useDisplaySnackbar();

  const queryParams = queryString.parse(location?.search);
  const selectedMonth = dayjs(queryParams.selected_month as string);
  const selectedMonthStart = selectedMonth.startOf('month');
  const selectedMonthEnd = selectedMonth.endOf('month');

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  useEffect(() => {
    setIsLoading(true);
    const getInvoiceParams: GetInvoiceParams = {
      project_uid: params.id,
      start_date: selectedMonthStart.format('YYYY-MM-DD'),
      end_date: selectedMonthEnd.format('YYYY-MM-DD'),
      invoice_no: String(queryParams.invoice_number) || null,
      invoice_upload_type: String(queryParams.upload_type) || InvoiceUploadType.system,
    };
    InvoiceClient.getInvoiceDetail(getInvoiceParams)
      .then((data) => {
        setCardInfo(data?.projectCustomerInfo);
        setInvoiceResourceInfo(data?.resourceDetails);
        setOtherDetails(data?.otherDetails);
        setManualInvoiceDetails(data?.manualInvoiceDetails || null);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, queryParams.selected_month, fetch]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.InvoiceDetailsWrapper}>
        {/* Header */}
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.INVOICE_DETAIL_TITLE,
            })}
          </span>
          <Box>
            <Link to={`/invoice?selected_month=${queryParams.selected_month}`}>
              {intl.formatMessage({
                id: I18nKey.INVOICE_DETAIL_SUBTITLE,
              })}{' '}
            </Link>{' '}
            / {cardInfo?.projectName}
          </Box>
        </Box>

        {manualInvoiceDetails ? (
          <ManualInvoiceDetail
            cardInfo={cardInfo}
            invoiceDetail={manualInvoiceDetails}
            selectedMonthEnd={selectedMonthEnd}
            selectedMonthStart={selectedMonthStart}
          />
        ) : (
          <SystemInvoiceDetail
            updateFetch={updateFetch}
            cardInfo={cardInfo}
            fetch={fetch}
            invoiceResourceInfo={invoiceResourceInfo}
            otherDetails={otherDetails}
            selectedMonth={selectedMonth}
            selectedMonthEnd={selectedMonthEnd}
            selectedMonthStart={selectedMonthStart}
            setIsLoading={setIsLoading}
          />
        )}
      </Box>
    </>
  );
};

export default InvoiceDetail;
