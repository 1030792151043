import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as Yup from 'yup';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleCheckboxField from '../../../components/SimpleCheckboxField/SimpleCheckboxField';
import EmployeeClient from '../../../api/Employee/employeeAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { cleanFormData } from '../../../utils/utils';

const AddRelation: React.FC<any> = ({
  relationConstants,
  relationDetails,
  refetchList,
  handleModalClose,
  category,
}) => {
  const { showSnackbar } = useDisplaySnackbar();
  const { employeeUid } = useContext(GlobalContext);

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      dob: Yup.string().required('Date of Birth is required'),
      isNominee: Yup.boolean().when('isDependant', {
        is: (isDependant: boolean) => !isDependant,
        then: Yup.boolean().oneOf([true], 'Relation should either be a Nominee or a Dependant'),
      }),
      relation: Yup.object()
        .shape({ label: Yup.string(), code: Yup.string() })
        .typeError('Relation is required'),
      isDependant: Yup.boolean().when('isNominee', {
        is: (isNominee: boolean) => !isNominee,
        then: Yup.boolean().oneOf([true], 'Relation should either be a Nominee or a Dependant'),
      }),
      nomineePercent: Yup.number().when('isNominee', {
        is: true,
        then: Yup.number().min(1, 'Mininmum 1% is required').typeError('Mininmum 1% is required'),
        otherwise: Yup.number().nullable(),
      }),
    },
    [
      ['isNominee', 'isDependant'],
      ['isDependant', 'isNominee'],
    ],
  );

  const relationObj = {
    firstName: '',
    middleName: '',
    lastName: '',
    relation: null,
    dob: dayjs().format('YYYY-MM-DD'),
    isDependant: category === 'insurancee',
    isNominee: category === 'nominee',
    nomineePercent: 0.0,
  };
  const [newRelation, setNewRelation] = useState<any>(relationObj);

  useEffect(() => {
    if (!_.isEmpty(relationDetails)) setNewRelation(relationDetails);
  }, [relationDetails]);

  const handleAddUpdateRelation = (relationValue: any) => {
    const cleanValues = cleanFormData(relationValue);
    if (cleanValues.uid) {
      let updateObj: any = _.omit(cleanValues, ['employeeUid']);
      if (typeof cleanValues.relation !== 'string')
        updateObj = { ...updateObj, ...{ relation: cleanValues.relation.label } };
      if (employeeUid)
        EmployeeClient.updateEmployeeRelation([updateObj])
          .then((res) => {
            showSnackbar(res, 'success');
            refetchList();
            handleModalClose();
            setNewRelation(relationObj);
          })
          .catch((e) => {
            showSnackbar(e, 'error');
          });
    } else {
      const addObj = [{ ...cleanValues, ...{ relation: cleanValues.relation.label } }];
      if (employeeUid)
        EmployeeClient.addEmployeeRelation(addObj)
          .then((res) => {
            showSnackbar(res, 'success');
            refetchList();
            handleModalClose();
            setNewRelation(relationObj);
          })
          .catch((e) => {
            showSnackbar(e, 'error');
          });
    }
  };

  return (
    relationConstants.length && (
      <Formik
        initialValues={newRelation}
        onSubmit={handleAddUpdateRelation}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid } = formik;

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="firstName"
                    label="First Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="middleName"
                    label="Middle Name"
                    variant="standard"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="lastName"
                    label="Last Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleAutocompleteField
                    name="relation"
                    key="relation"
                    label="Employee Relation"
                    variant="standard"
                    setFieldValue={setFieldValue}
                    options={relationConstants}
                    values={values}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleInputField
                    name="dob"
                    label="Date of Birth"
                    variant="standard"
                    type="date"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleCheckboxField
                    name="isDependant"
                    label="Is Dependent?"
                    checked={values.isDependant}
                    key="isDependant"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleCheckboxField
                    name="isNominee"
                    label="Is Nominee?"
                    checked={values.isNominee}
                    key="isNominee"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="nomineePercent"
                    label="Nominee %"
                    variant="standard"
                    type="number"
                    fullWidth
                    required={values.isNominee}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={!dirty || !isValid}
                variant="contained"
                type="submit"
                style={{ position: 'absolute', right: '1.3rem', bottom: '0.5rem' }}>
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
    )
  );
};

export default AddRelation;
