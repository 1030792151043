import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FooterProps } from './FooterTypes';
import useFooterStyles from './FooterStyles';

const Footer: React.FC<FooterProps> = ({ footerText }) => {
  const today = new Date();
  const defaultFooterText = `${today.getFullYear()} Copyright © | Tech Prescient | All rights reserved`;

  const classes = useFooterStyles();

  return (
    <Grid className={classes.defaultStyle} container direction="row" justifyContent="flex-end">
      <Typography
        sx={{
          fontSize: '10px',
          margin: 'auto',
          marginRight: '0px',
          display: 'block',
          verticalAlign: 'middle',
        }}>
        {footerText || defaultFooterText}
      </Typography>
    </Grid>
  );
};

export default Footer;
