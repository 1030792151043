import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useChartStyles = makeStyles((theme: Theme) => {
  return {
    parentWrapper: {
      display: 'grid',
      gap: '3px',
      marginTop: '10px',
    },
    childWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#FFEDED',
      width: 'calc(100% - 3em)',
      margin: 'auto',
      padding: '5px !important',
      borderRadius: '8px',
      overflow: 'hidden',
      '&.title': {
        backgroundColor: 'transparent',
        padding: '8px 0 0 0',
      },
      '&.grey': {
        backgroundColor: '#F3F3F3',
      },
      '&.grey  p:nth-child(2)': {
        color: 'red',
      },
    },
    viewBtn: {
      textAlign: 'end',
      width: 'calc(100% - 2em)',
      margin: '7px  auto',
      color: '#04A3D6',
      fontSize: '12px !important',
    },
    textWrapper: {
      fontSize: '14px !important',
    },
    titleText: {
      fontSize: '12px !important',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
      marginTop: '60px !important',
    },
  };
});

export default useChartStyles;
