import { Box, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import useAllContactsStyles from './AllContactsStyles';
import ContactList from './ContactList';
import ContactDetail from './ContactDetail';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { contactIdName } from './contactListConfig';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SidePanel from '../../components/SidePanel/SidePanel';
import AddContactForm from '../Opportunity/AddContactForm';

const AllContacts = () => {
  const [selectedContact, setSelectedContact] = useState<any>();
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetchContacts, setIsRefetchContacts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fetch, setFetch] = useState(0);

  const styles = useAllContactsStyles();
  const intl = useIntl();
  const params = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useDisplaySnackbar();

  const handleAddClick = () => setIsOpen(true);

  const handleModalClose = () => setIsOpen(false);

  const onCreateContact = () => setFetch(fetch + 1);

  const generateContactRows = (rows: any) => {
    const updatedTableRows: any = [];

    rows.forEach((row: any) => {
      const newRow = { ...row };
      newRow.customerName = `${newRow.firstName} ${newRow.middleName ? newRow.middleName : ''} ${
        newRow.lastName
      }`;
      updatedTableRows.push(newRow);
    });
    return updatedTableRows;
  };

  const getContactList = () => {
    setIsLoading(true);
    CustomerRelationClient.getCrmContactList()
      .then((data) => {
        const rows = generateContactRows(generateRows(data, [], [], contactIdName));
        if (params.id && !isRefetchContacts) {
          setSelectedContact(rows.find((c: any) => c.id === params.id));
        }
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getContactList();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  useEffect(() => {
    if (isRefetchContacts) getContactList();
    setIsRefetchContacts(false);
  }, [isRefetchContacts]);

  const handleClose = () => {
    navigate('/contact');
    setSelectedContact(null);
  };

  return (
    <Box className={styles.contactsWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.CONTACT_TITLE,
          })}
        </span>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={params.id ? `${styles.ListWrapper} ${styles.w70}` : styles.ListWrapper}>
          <ContactList
            tableRows={tableRows}
            setSelectedContact={setSelectedContact}
            setIsRefetchContacts={setIsRefetchContacts}
            handleCloseOnDelete={handleClose}
          />
        </Box>

        {selectedContact && (
          <ContactDetail
            selectedContact={selectedContact}
            handleClose={handleClose}
            setIsRefetchContacts={setIsRefetchContacts}
          />
        )}
        <Box className={styles.fabContainer}>
          <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleAddClick}>
            <Box>
              <AddIcon />
            </Box>
          </Box>

          <Modal open={isOpen} onClose={handleModalClose}>
            <SidePanel header={'Add Contact'} onClose={handleModalClose}>
              <div className={styles.contactFormContainer}>
                <AddContactForm
                  handleModalClose={handleModalClose}
                  onCreateContact={onCreateContact}
                />
              </div>
            </SidePanel>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default AllContacts;
