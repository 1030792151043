import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Box, Button, CircularProgress, Grid, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { getEmployee, stages } from './opportunityStageFormConfig';
import useCRMProjectDetailsStyles from '../OpportunityDetailStyles';
import CustomerRelationClient from '../../../api/Opportunity/opportunityAPIs';
import { Employee } from '../../../api/Opportunity/opportunityTypes';
import { F0 } from '../../../utils/oppStages';
import MisDialog from '../../../components/MisDialog/MisDialog';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../../translations/I18nKey';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';

interface OpportunityStageFormProps {
  readonly projectInfo: any;
  readonly onOpportunityUpdate: Function;
}

const OpportunityStage: React.FC<OpportunityStageFormProps> = ({
  projectInfo,
  onOpportunityUpdate,
}) => {
  const [projectDetail, setProjectDetail] = useState(projectInfo);
  const [isLoading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [opportunityStage, setOpportunityStage] = useState(projectInfo.stage);
  const [fields, setFields] = useState({
    stage: null,
    comment: '',
    projectManager: null,
    accountManagers: [],
  });
  const intl = useIntl();

  const classes = useCRMProjectDetailsStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const validationSchema = Yup.object().shape({
    stage: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .required('Stage is required')
      .typeError('Stage is required'),
    comment: Yup.string().nullable(),
    projectManager:
      opportunityStage === F0
        ? Yup.object()
            .shape({ label: Yup.string(), value: Yup.string() })
            .required('Project Manager is required')
            .typeError('Project Manager is required')
        : Yup.object().shape({ label: Yup.string(), value: Yup.string() }).nullable(),
    accountManagers:
      opportunityStage === F0
        ? Yup.array()
            .of(
              Yup.object()
                .shape({ label: Yup.string(), value: Yup.string() })
                .typeError('Account Manager is required'),
            )
            .min(1, 'Select atleast one Account Manager')
        : Yup.array().of(
            Yup.object().shape({ label: Yup.string(), value: Yup.string() }).nullable(),
          ),
  });

  const initialValuesForm = (allEmployee: any) => {
    const comment = projectInfo.comments || '';
    const projectManagerId = projectInfo.managerId;
    const accManager = projectInfo.accountManagerUids;
    const defaultaccManager = accManager.map((itemId: any) => {
      return allEmployee.find((manager: any) => manager.value === itemId);
    });
    const initialValue: any = {
      stage: stages.find((stage: any) => stage.value === opportunityStage),
      comment,
      projectManager: allEmployee.find((employee: any) => employee.value === projectManagerId),
      accountManagers: defaultaccManager,
    };
    setFields(initialValue);
  };

  const getInitialValues = () => {
    const initialStage = projectInfo.stage;
    const comment = projectInfo.comments || '';
    const initialValue: any = {
      stage: stages.find((stage: any) => stage.value === initialStage),
      comment,
      projectManager: null,
      accountManagers: [],
    };
    setFields(initialValue);
  };

  const getAllEmployee = () => {
    setLoading(true);
    CustomerRelationClient.getEmployeeList()
      .then((data) => {
        const allEmployee = getEmployee(data);
        setEmployeeData(allEmployee);
        initialValuesForm(allEmployee);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  const handleStageChange = (option: any) => {
    setOpportunityStage(option.value);
  };

  useEffect(() => {
    if (opportunityStage === F0) {
      if (!employeeData.length) {
        getAllEmployee();
      }
    } else if (opportunityStage && opportunityStage !== F0) getInitialValues();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [opportunityStage]);

  useEffect(() => {
    if (projectInfo.opportunityId) {
      setOpportunityStage(projectInfo.stage);
      setProjectDetail(projectInfo);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [projectInfo]);

  const handleSubmit = (values: any) => {
    const { comment, projectManager, accountManagers } = values;
    const payload = {
      stage: opportunityStage,
      comment,
      managerUid: projectManager?.value,
      accountManagerUids: accountManagers?.map((m: any) => m.value),
    };
    setLoading(true);
    CustomerRelationClient.updateCRMOpportunity(projectInfo.opportunityId, payload)
      .then((res) => {
        showSnackbar(res, 'success');
        onOpportunityUpdate(payload);
        if (opportunityStage === F0) setOpenDialog(true);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  return projectDetail?.opportunityId && opportunityStage ? (
    <Box className={classes.FormWrapper}>
      <Formik
        initialValues={fields}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid } = formik;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <SimpleAutocompleteField
                    name="stage"
                    key="stage"
                    label="Stage"
                    disableClearable
                    size="small"
                    setFieldValue={setFieldValue}
                    handleChange={(event: any, value: any) => {
                      handleStageChange(value);
                    }}
                    options={stages}
                    values={values}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="comment"
                    label="Comment"
                    type="text"
                    size="small"
                    fullWidth
                    multiline
                  />
                </Grid>
                {opportunityStage === F0 && (
                  <>
                    <Grid item xs={3}>
                      <SimpleAutocompleteField
                        name="projectManager"
                        key="projectManager"
                        label="Project Manager"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={employeeData}
                        values={values}
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SimpleAutocompleteField
                        name="accountManagers"
                        key="accountManagers"
                        label="Account Manager(s)"
                        size="small"
                        multiple
                        setFieldValue={setFieldValue}
                        options={employeeData}
                        values={values}
                        required
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Button variant="contained" type="submit" disabled={!dirty || !isValid}>
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <MisDialog
        title="Warning"
        message={intl.formatMessage({
          id: I18nKey.CUSTOMER_RELATION_STAGE_WARNING,
        })}
        isOpen={openDialog}
        handleSuccess={() => setOpenDialog(false)}
        handleClose={() => setOpenDialog(false)}
        actionBtnLabel="OK"
      />
    </Box>
  ) : (
    <Box className={classes.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default OpportunityStage;
