import axios from 'axios';
import { getUserAttributes } from '../utils/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_HOST,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config: any) {
    const newConfig = { ...config };
    const userInfo = getUserAttributes();

    newConfig.headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    return newConfig;
  },
  function (error: any) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  // TODO: revise this while API implementation
  function (error: any) {
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
