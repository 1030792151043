import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useProfileDocumentStyles = makeStyles((theme: Theme) => {
  return {
    Header: {
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '1rem',
      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    DocWrapper: {
      height: '100%',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .highlight-current-month': {
        color: theme.palette.text.midDark,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '1.1rem',
      },
      '& .disable-past-month': {
        color: theme.palette.text.disabled,
      },
    },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1rem',
    },
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    DocCardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
    },
    UpperCardHeader: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    AddButton: {
      padding: 'initial !important',
      color: `${theme.palette.text.light} !important`,
    },
  };
});

export default useProfileDocumentStyles;
