import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useSearchCardStyles = makeStyles((theme: Theme) => {
  return {
    cardWrapper: {
      display: 'flex',
      height: 'fit-content',
      padding: '16px 20px',
      border: '1px solid #dddddd',
      borderRadius: '4px',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    textDetails: {
      fontSize: '12px !important',
    },
    detailsWrapper: {
      paddingLeft: '10px',
    },
    highlighted: {
      backgroundColor: '#C2DFF4',
    },
  };
});

export default useSearchCardStyles;
