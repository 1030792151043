import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Divider from '@mui/material/Divider';
import I18nKey from '../../translations/I18nKey';
import useCRMProjectDetailsStyles from './OpportunityDetailStyles';
import ContractTable from '../../components/ContractTable/ContractTable';
import OrganizationSideCard from '../../components/OrganizationSideCard/OrganizationSideCard';
import RevenueForecast from './components/RevenueForecast';
import OpportunityStage from './components/OpportunityStage';
import { SOW } from '../../utils/docTypes';
import { F0 } from '../../utils/oppStages';
import OpportunityEdit from './components/OpportunityEdit';

const CRMProjectDetail = () => {
  const [projectDetail, setProjectDetail] = useState<any>({
    forecastedRevenue: [],
    opportunityId: '',
  });
  const [remountKey, setRemountKey] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const params = useParams();
  const projectUid = params.id || '';
  const classes = useCRMProjectDetailsStyles();
  const intl = useIntl();

  const onProjectDataLoad = (project: any) => setProjectDetail(project);

  const onOpportunityUpdate = () => {
    setRemountKey(remountKey + 1);
  };

  const toggle = () => setShowEdit(!showEdit);

  const handleClose = () => {
    toggle();
  };

  const hideDocCategories = projectDetail.stage !== F0 ? [SOW] : [];

  return (
    <Box className={classes.projectDetailsWrapper}>
      <Box className={classes.Header}>
        <span>
          {projectDetail?.name} - {projectDetail?.accountName}
        </span>
        <Box>
          <Link to="/opportunity">
            {intl.formatMessage({
              id: I18nKey.CUSTOMER_RELATION_DETAIL_SUBTITLE,
            })}
          </Link>{' '}
          / {projectDetail?.name}
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item md={3} sm={12} xs={12}>
          {showEdit ? (
            <OpportunityEdit
              projectInfo={projectDetail}
              handleClose={handleClose}
              projectId={projectUid}
            />
          ) : (
            <OrganizationSideCard
              key={remountKey}
              projectId={projectUid}
              onDataLoad={onProjectDataLoad}
              toggle={toggle}
            />
          )}
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Box className={classes.UpperCardWrapper}>
            <Typography className={classes.UpperCardHeader}>
              {intl.formatMessage({
                id: I18nKey.CUSTOMER_RELATION_OPPORTUNITY_STAGE,
              })}
              : {projectDetail?.stage?.toUpperCase()}
            </Typography>
            <Divider />
            <Box className={classes.UpperCardContent}>
              <OpportunityStage
                projectInfo={projectDetail}
                onOpportunityUpdate={onOpportunityUpdate}
              />
            </Box>
          </Box>
          <RevenueForecast projectDetail={projectDetail} />
          <Box className={classes.LowerCardWrapper}>
            <ContractTable
              project_id={projectUid}
              isUploadable
              tableHeight="263px"
              hideDocCategories={hideDocCategories}
              currency={projectDetail?.currency}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CRMProjectDetail;
