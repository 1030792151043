import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllSearchStyles = makeStyles((theme: Theme) => {
  return {
    AllsearchWrapper: {
      padding: '1rem',
      backgroundColor: theme.palette.appBackground?.lightest,
      height: 'calc(100vh - 64px - 25px)',
    },
    searchOptions: {
      display: 'flex',
      padding: '0px 15px',
      alignItems: 'center',
    },
    searchWrapper: {
      margin: '20px 13%',
      width: '100%',
      marginBottom: '2px',
    },
    header: {
      // fontSize: '30px',
      // fontWeight: '600',
      width: '320px',
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    DividerLine: { backgroundColor: '#BDBDBD', marginBottom: '40px !important' },
    CardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      borderRadius: '12px',
      color: theme.palette.secondary.main,
      background: theme.palette.background.default,
      marginBottom: '20px',
      height: 'fit-content',
      maxWidth: '550px',
      width: 'inherit',
    },
    GridWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '55%',
    },
    CardImageWrapper: {
      backgroundColor: theme.palette.popUpBackground?.light,
      borderRadius: '12px 12px 0px 0px',
      minHeight: '90px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '55px',

      '& .MuiAvatar-root': {
        width: '110px',
        height: '110px',
        marginBottom: '-100px',
      },
    },
    CardTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      padding: '0 1rem',
    },
    MidSectionWrapper: {
      margin: '1rem 0 2rem 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',

      '& svg': {
        color: theme.palette.tertiary?.light,
        alignSelf: 'center',
      },
    },
    MidSectionWrappersecond: {
      margin: '1rem 0 0 0',
      display: 'flex',
      padding: '0 1rem',
      alignItems: 'baseline',
      '& svg': {
        color: theme.palette.tertiary?.light,
        alignSelf: 'center',
      },
      '& p': {
        padding: '10px',
      },
    },
    PersonalDetailWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '17px',
      padding: '0 1rem',
    },
    MidSectionItem: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 1rem',
      width: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& p': {
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
      },
      '& img': {
        alignSelf: 'center',
      },
    },
    TitleText: {
      marginBottom: '0',
      marginTop: '4px',
      textTransform: 'capitalize',
    },
    bold: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    ButtonWrapper: {
      margin: '10px !important',
      marginLeft: '50px !important',
      fontSize: '15px !important',
      fontWeight: 'bold',
      width: '80% !important',
    },
    RoundedBtn: {
      borderRadius: '40px !important',
      width: '52px',
      height: '45px',
      marginLeft: '20px !important',
    },
    Searchbox: {
      height: '52px !important',
      borderRadius: '15px',
      marginLeft: '22px !important',
    },
    labelText: {
      color: theme.palette.text.midDark,
      fontSize: '12px',
      marginLeft: '3px',
      whiteSpace: 'nowrap',
    },
    projectIcon: {
      height: '1em !important',
      width: '1em !important',
    },
  };
});

export default useAllSearchStyles;
