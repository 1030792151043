/* eslint-disable no-plusplus */
export const operatorOptions = [
  { value: 'contains', label: 'Contains' },
  { value: 'equal', label: 'Equal' },
  { value: 'startswith', label: 'Startswith' },
  { value: 'endswith', label: 'Endswith' },
  { value: 'notEqual', label: 'Not Equal' },
  { value: 'notContains', label: 'Not Contains' },
];

export const getColumnDataType = (columnName: string, columnDataTypes: any): string => {
  let returnType;
  for (let index = 0; index < columnDataTypes.length; index++) {
    const element = columnDataTypes[index];
    if (element.value === columnName) {
      returnType = element.type;
    }
  }
  return returnType;
};

export const onColumnChange = (columnName: any, columnDataTypes: any) => {
  const columnType = getColumnDataType(columnName.value, columnDataTypes);
  if (columnType === 'array') {
    return [
      { value: 'contains', label: 'Contains' },
      { value: 'startswith', label: 'Startswith' },
      { value: 'endswith', label: 'Endswith' },
      { value: 'notContains', label: 'Not Contains' },
    ];
  }
  if (columnType === 'boolean') {
    return [
      { value: 'isTrue', label: 'Is True' },
      { value: 'isFalse', label: 'Is False' },
    ];
  }
  if (columnType === 'number') {
    return [
      { value: 'equal', label: '=' },
      { value: 'notEqual', label: '!=' },
      { value: 'lessThan', label: '<' },
      { value: 'greaterThan', label: '>' },
      { value: 'lessThanOrEqual', label: '<=' },
      { value: 'greaterThanOrEqual', label: '>=' },
    ];
  }
  if (columnType === 'date') {
    return [
      { value: 'between', label: 'Between' },
      { value: 'before', label: 'Before' },
      { value: 'after', label: 'After' },
    ];
  }
  return operatorOptions;
};

export const isBooleanColumn = (columnName: any, columnDataTypes: any): any => {
  const columnType = getColumnDataType(columnName.value, columnDataTypes);
  return columnType === 'boolean';
};

export const filterRows = (rows: any[], filters: any, columnDataTypes: any) => {
  if (!filters) {
    return rows;
  }

  return rows.filter((row) => {
    let includeRow = true;
    Object.keys(filters).forEach((filterKey: string) => {
      const filterConditions = filters[filterKey];
      const columnType = getColumnDataType(filterKey, columnDataTypes);
      includeRow =
        includeRow &&
        filterConditions.some((filterCondition: any) => {
          const { data, operation } = filterCondition;

          switch (operation) {
            case 'equal':
              if (columnType === 'number') {
                return data.some((value: any) => row[filterKey] === Number(value));
              }
              return data.some((value: any) => row[filterKey] === value);
            case 'contains':
              return data.some((value: any) =>
                row[filterKey]?.toString().toLowerCase().includes(value.toLowerCase()),
              );
            case 'notEqual':
              if (columnType === 'number') {
                return !data.some((value: any) => row[filterKey] === Number(value));
              }
              return !data.some(
                (value: any) => row[filterKey]?.toLowerCase() === value.toLowerCase(),
              );
            case 'notContains':
              return Array.isArray(row[filterKey])
                ? !data.some((value: any) =>
                    row[filterKey]?.some((item: any) =>
                      item.toLowerCase().includes(value.toLowerCase()),
                    ),
                  )
                : !data.some((value: any) =>
                    row[filterKey]?.toLowerCase().includes(value.toLowerCase()),
                  );

            case 'startswith':
              return Array.isArray(row[filterKey])
                ? data.some(
                    (value: any) =>
                      typeof value === 'string' &&
                      row[filterKey][0].toLowerCase().startsWith(value.toLowerCase()),
                  )
                : data.some((value: any) =>
                    row[filterKey]?.toLowerCase().startsWith(value.toLowerCase()),
                  );

            case 'endswith':
              return Array.isArray(row[filterKey])
                ? data.some(
                    (value: any) =>
                      typeof value === 'string' &&
                      row[filterKey][row[filterKey].length - 1]
                        .toLowerCase()
                        .endsWith(value.toLowerCase()),
                  )
                : data.some((value: any) =>
                    row[filterKey]?.toLowerCase().endsWith(value.toLowerCase()),
                  );

            case 'isTrue':
              return row[filterKey] === true;

            case 'isFalse':
              return row[filterKey] === false;

            case 'lessThan':
              return data.some((value: any) => row[filterKey] < Number(value));

            case 'greaterThan':
              return data.some((value: any) => row[filterKey] > Number(value));

            case 'lessThanOrEqual':
              return data.some((value: any) => row[filterKey] <= Number(value));

            case 'greaterThanOrEqual':
              return data.some((value: any) => row[filterKey] >= Number(value));

            case 'between':
              if (columnType === 'number') {
                const [min, max] = data;
                return row[filterKey] >= Number(min) && row[filterKey] <= Number(max);
              }
              if (columnType === 'date') {
                const [startDate, endDate] = data;
                const rowDate = new Date(row[filterKey]);
                return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
              }
              return false;
            case 'before':
              if (columnType === 'date') {
                const referenceDateBefore = new Date(data[0]);
                const rowDateBefore = new Date(row[filterKey]);
                return rowDateBefore < referenceDateBefore;
              }
              return false;

            case 'after':
              if (columnType === 'date') {
                const referenceDateAfter = new Date(data[0]);
                const rowDateAfter = new Date(row[filterKey]);
                return rowDateAfter > referenceDateAfter;
              }
              return false;
            default:
              return true;
          }
        });
    });
    return includeRow;
  });
};
