import { F0, F1, F2, F3, F4 } from '../../../utils/oppStages';

export const stages = [
  { label: F4.toUpperCase(), value: F4 },
  { label: F3.toUpperCase(), value: F3 },
  { label: F2.toUpperCase(), value: F2 },
  { label: F1.toUpperCase(), value: F1 },
  { label: F0.toUpperCase(), value: F0 },
];

export const getEmployee = (data: any[]) => {
  return data?.map((d: any) => ({ label: `${d.firstName} ${d.lastName}`, value: d.uid })) || [];
};
