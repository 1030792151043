import { GridActionsCellItem } from '@mui/x-data-grid';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'name', sort: 'asc' };

const getColumns = (rowActions: any[]) => {
  const cols = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Description',
      flex: 1.5,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      align: 'center',
      flex: 1,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
  return cols;
};

const getInitialVisibilityModel = ({ showColumns }: any, rowActions: any[]) => {
  const colRange = getColumns(rowActions);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const categoryUid = 'uid';

const initialShowCols = ['name', 'description', 'actions'];

export { initialSort, categoryUid, getColumns, initialShowCols, getInitialVisibilityModel };
