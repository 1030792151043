import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

const SimpleCheckboxField: React.FC<any> = ({
  name,
  handleChange,
  checked,
  setFieldValue,
  label,
  disabled,
  ...fieldProperties
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<any>, value: any) => {
    setFieldValue?.(name, value);
    handleChange(name, value);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          onChange={handleCheckboxChange}
          checked={checked}
          disabled={disabled}
          {...fieldProperties}
        />
      }
      label={label}
    />
  );
};

SimpleCheckboxField.defaultProps = {
  handleChange: () => {},
  label: '',
  disabled: false,
};

export default SimpleCheckboxField;
