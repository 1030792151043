import axios from 'axios';
import { getNewCandidateAttributes } from '../utils/auth';

const newCandidateAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_HOST,
});

// Add a request interceptor
newCandidateAxiosInstance.interceptors.request.use(
  function (config: any) {
    const newConfig = { ...config };
    const token = getNewCandidateAttributes();

    newConfig.headers = {
      Authorization: `Bearer ${token}`,
    };

    return newConfig;
  },
  function (error: any) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
newCandidateAxiosInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error?.response?.status === 401) {
      sessionStorage.clear();
    }
    return Promise.reject(error);
  },
);

export default newCandidateAxiosInstance;
