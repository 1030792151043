import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'customerName', sort: 'asc' };
const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={value}>
      <div>{newVal}</div>
    </Tooltip>
  ) : (
    <div>{newVal}</div>
  );
};

const columns = (rowActions: any[], setSelectedContact: any): any[] => {
  return [
    {
      field: 'customerName',
      type: 'string',
      headerName: 'Customer Name',
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/contact/${params.row.id}`} onClick={() => setSelectedContact(params.row)}>
            {params.row.customerName}
          </Link>
        );
      },
    },
    {
      field: 'email',
      type: 'string',
      headerName: 'Email',
      flex: 1.5,
      sortable: true,
      filterable: false,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'phone',
      type: 'string',
      headerName: 'Phone Number',
      flex: 1.1,
      sortable: true,
      filterable: false,
      hideable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
      hide: true,
    },
    {
      field: 'state',
      type: 'string',
      headerName: 'State',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'country',
      type: 'string',
      headerName: 'Country',
      flex: 1,
      sortable: true,
      headerAlign: 'left',
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'pincode',
      type: 'string',
      headerName: 'Pin Code',
      flex: 1,
      sortable: true,
      headerAlign: 'left',
      hideable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
      hide: true,
    },
    {
      field: 'remarks',
      type: 'string',
      headerName: 'Remarks',
      flex: 2,
      hideable: true,
      filterable: false,
      editable: true,
      renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.remarks, 32),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      width: 10,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
};

const getInitialVisibilityModel = (
  { showColumns }: any,
  rowActions: any[],
  setSelectedContact: any,
) => {
  const colRange = columns(rowActions, setSelectedContact);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const contactIdName = 'uid';

const initialShowCols = ['customerName', 'email', 'state', 'country', 'actions'];

export { columns, initialSort, contactIdName, initialShowCols, getInitialVisibilityModel };
