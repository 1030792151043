import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MisTabs from '../MisTabs/MisTabs';
import { docUidName } from './EmpDocTableConfig';
import EmpDocTableForm from './EmpDocTableForm';
import { generateRows } from '../Datatable/datatableUtils';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import { CERTIFICATES, OTHERS, PERSONAL, PROFESSIONAL } from '../../utils/docTypes';
import useEmpDocTableStyles from './EmpDocTableStyle';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import OnboardingClient, { PATHS as onBoardingPaths } from '../../api/OnBoarding/onboardingAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations, UserGroupRoles } from '../../utils/utils';
import EmpDocContent from './EmpDocContent';
import { PATHS as employeePaths } from '../../api/Employee/employeeAPIs';

interface EmpDocTableTabs {
  id: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS;
  readonly label: string;
  readonly component: React.ReactNode;
  readonly module: string;
}

interface EmpDocTableProps {
  isUploadable?: boolean;
  employeeId?: string;
  onChange?: Function;
  onRemove?: Function;
  tableView?: boolean;
  uploadDocs?: any;
  usedBy?: 'others' | 'profile';
}

const EmpDocTable: React.FC<EmpDocTableProps> = ({
  isUploadable,
  employeeId,
  onChange,
  onRemove,
  tableView,
  uploadDocs,
  usedBy,
}) => {
  const [empCertificates, setEmpCertificates] = useState<any>([]);
  const [empPersonalDocs, setEmpPersonalDocs] = useState<any>([]);
  const [empProfessionalDocs, setEmpProfessionalDocs] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [fetch, setFetch] = useState<number>(0);

  const employeeUid = employeeId || '';

  const { checkAccess } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], docUidName);
    return rows;
  };

  const getEmpDocuments = () => {
    setLoading(true);
    if (usedBy === 'others')
      Promise.allSettled([
        OnboardingClient.getEmployeeCertificatesById(employeeUid),
        OnboardingClient.getEmployeePersonalDocsById(employeeUid),
        OnboardingClient.getEmployeeProfessionalDocsById(employeeUid),
      ])
        .then((res) => {
          res.forEach((item, index) => {
            switch (index) {
              case 0:
                if (item.status === 'fulfilled') setEmpCertificates(getTableRows(item.value.data));
                break;
              case 1:
                if (item.status === 'fulfilled') setEmpPersonalDocs(getTableRows(item.value.data));
                break;
              case 2:
                if (item.status === 'fulfilled')
                  setEmpProfessionalDocs(getTableRows(item.value.data));
                break;
              default:
            }
          });
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    else {
      Promise.allSettled([
        OnboardingClient.getEmployeeCertificates(),
        OnboardingClient.getEmployeePersonalDocs(),
        OnboardingClient.getEmployeeProfessionalDocs(),
      ])
        .then((res) => {
          res.forEach((item, index) => {
            switch (index) {
              case 0:
                if (item.status === 'fulfilled') setEmpCertificates(getTableRows(item.value.data));
                break;
              case 1:
                if (item.status === 'fulfilled') setEmpPersonalDocs(getTableRows(item.value.data));
                break;
              case 2:
                if (item.status === 'fulfilled')
                  setEmpProfessionalDocs(getTableRows(item.value.data));
                break;
              default:
            }
          });
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (employeeUid) getEmpDocuments();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch, employeeUid]);

  const empDocTableStyles = useEmpDocTableStyles();

  const onDocSave = (formData: any, row: any) => {
    onChange?.(formData);
  };

  const handleSuccessfulDelete = (res: any) => {
    showSnackbar(res, 'success');
    updateFetch();
  };

  const onDocRemove = (
    id: any,
    documentId: typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS,
  ): void => {
    setLoading(true);
    onRemove?.(id);

    switch (documentId) {
      case PROFESSIONAL:
        if (usedBy === 'others')
          OnboardingClient.deleteEmployeeProfessionalDocsById(employeeUid, id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        else
          OnboardingClient.deleteEmployeeProfessionalDocs(id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        break;

      case PERSONAL:
        if (usedBy === 'others')
          OnboardingClient.deleteEmployeePersonalDocsById(employeeUid, id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        else
          OnboardingClient.deleteEmployeePersonalDocs(id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        break;

      case OTHERS:
        if (usedBy === 'others')
          OnboardingClient.deleteEmployeeCertificatesById(employeeUid, id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        else
          OnboardingClient.deleteEmployeeCertificates(id)
            .then((res: any) => {
              handleSuccessfulDelete(res);
            })
            .catch((e: any) => showSnackbar(e, 'error'))
            .finally(() => setLoading(false));
        break;

      default:
    }
  };

  const empDocTableTabs: EmpDocTableTabs[] = [
    {
      id: PROFESSIONAL,
      label: PROFESSIONAL.toUpperCase(),
      component: (
        <EmpDocContent
          rows={empProfessionalDocs}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={PROFESSIONAL}
          usedBy={usedBy}
        />
      ),
      module:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_PROFESSIONAL_DOCUMENT_BY_ID('id')
          : onBoardingPaths.EMPLOYEE_PROFESSIONAL,
    },
    {
      id: PERSONAL,
      label: PERSONAL.toUpperCase(),
      component: (
        <EmpDocContent
          rows={empPersonalDocs}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={PERSONAL}
          usedBy={usedBy}
        />
      ),
      module:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_PERSONAL_DOCUMENT_BY_ID('id')
          : onBoardingPaths.EMPLOYEE_PERSONAL,
    },
    {
      id: OTHERS,
      label: CERTIFICATES.toUpperCase(),
      component: (
        <EmpDocContent
          rows={empCertificates}
          tableView={tableView}
          onDocRemove={onDocRemove}
          employeeUid={employeeUid}
          id={OTHERS}
          usedBy={usedBy}
        />
      ),
      module:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_CERTIFICATE_BY_ID('id')
          : onBoardingPaths.EMPLOYEE_CERTIFICATE,
    },
  ];

  const filteredTabList = empDocTableTabs.filter((el) => checkAccess(el.module, ApiOperations.GET));

  const ShowAddButton = () => {
    if (!isUploadable) return false;

    const apiCalls: Record<typeof PROFESSIONAL | typeof PERSONAL | typeof OTHERS, string> = {
      professional:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_PROFESSIONAL_DOCUMENT_BY_ID('id')
          : employeePaths.ADD_PROFESSIONAL_DOC,
      personal:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_PERSONAL_DOCUMENT_BY_ID('id')
          : employeePaths.ADD_PERSONAL_DOC,
      others:
        usedBy === 'others'
          ? onBoardingPaths.EMPLOYEE_CERTIFICATE_BY_ID('id')
          : employeePaths.ADD_CERTIFICATE,
    };

    return checkAccess(apiCalls[filteredTabList[activeTab].id], ApiOperations.POST);
  };

  const onTabChange = (tabVal: number) => {
    setActiveTab(tabVal);
    setCurrentCategory(empDocTableTabs[tabVal]);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={empDocTableStyles.empDocTablesWrapper}>
        <MisTabs tabs={filteredTabList} onTabChange={onTabChange} selected={activeTab} />
      </Box>
      {ShowAddButton() && (
        <EmpDocTableForm
          employeeUid={employeeUid}
          onFormUpload={getEmpDocuments}
          onSave={onDocSave}
          currentCategory={currentCategory || empDocTableTabs[0]}
          usedBy={usedBy || 'others'}
        />
      )}
    </>
  );
};

EmpDocTable.defaultProps = {
  isUploadable: false,
  onChange: () => {},
  onRemove: () => {},
  tableView: true,
  employeeId: '',
  uploadDocs: null,
  usedBy: 'others',
};

export default EmpDocTable;
