import { IconButton } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import I18nKey from '../translations/I18nKey';

const useDisplaySnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();

  const showSnackbar = (apiResonse: any, variant: VariantType = 'error', userMessage?: string) => {
    let message = '';
    const response = { ...apiResonse };
    if (userMessage) {
      return enqueueSnackbar(userMessage, {
        variant,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (!response.status) {
      response.status = response.response?.status;
    }

    switch (response.status) {
      case 200:
        message = response.data.detail;
        break;
      case 409:
      case 400:
        message = response.response.data.detail;
        break;

      case 201:
        message = intl.formatMessage({
          id: I18nKey.TOAST_MESSAGE_ADD,
        });
        break;

      case 404:
        message = intl.formatMessage({
          id: I18nKey.TOAST_MESSAGE_NOT_FOUND,
        });
        break;

      case 422:
        response.response.data.detail.forEach((item: any) => {
          message = message.concat(`${item.loc[1]}: `);
          message = message.concat(`${item.msg}\n`);
        });
        break;

      case 500:
        message = response.response.data.detail;
        break;

      default:
        message = intl.formatMessage({
          id: I18nKey.TOAST_MESSAGE_ERROR,
        });
    }

    return enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      ),
    });
  };

  return {
    showSnackbar,
  };
};

export default useDisplaySnackbar;
