/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import I18nKey from '../../../translations/I18nKey';
import useAllCustomerRelationsStyles from '../AllOpportunitiesStyles';
import Addopportunity from '../AddOpportunityForm';

interface OpportunityEditProps {
  readonly projectInfo: any;
  readonly handleClose: () => void;
  readonly projectId: string;
}

const OpportunityEdit: React.FC<OpportunityEditProps> = ({
  handleClose,
  projectInfo,
  projectId,
}) => {
  const styles = useAllCustomerRelationsStyles();
  const intl = useIntl();

  return (
    <Box className={styles.detailsWrapper}>
      <Box className={`${styles.topContainer} ${styles.card}`}>
        <strong>{projectInfo.name}</strong>
      </Box>
      <Box className={`${styles.bottomContainer} ${styles.card}`}>
        <Box className={styles.formWrapper}>
          <Addopportunity
            opportunityDetail={projectInfo}
            handleModalClose={handleClose}
            projectId={projectId}
          />
        </Box>
        <button type="submit" className={styles.closeBtn} onClick={handleClose}>
          {intl.formatMessage({
            id: I18nKey.BUTTON_LABEL_CLOSE,
          })}
        </button>
      </Box>
    </Box>
  );
};

export default OpportunityEdit;
