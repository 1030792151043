import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { deleteFormValidationSchema } from './utils';

const DeleteEmployee: React.FC<any> = ({ formRef, setIsDeleteFormValid, currentDate }) => {
  const handleFormChange = (e?: any) => {
    setIsDeleteFormValid(e?.target?.value);
  };

  return (
    <Box>
      <Formik
        initialValues={{ dos: currentDate, exitRemarks: '' }}
        onSubmit={() => {}}
        innerRef={formRef}
        validationSchema={deleteFormValidationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, handleChange, handleBlur } = formik;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="dos"
                    type="date"
                    label="Date of Separation"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e: any) => {
                      handleFormChange(e);
                      handleChange(e);
                    }}
                    required
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    name="exitRemarks"
                    label="Exit Remarks"
                    type="text"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default DeleteEmployee;
