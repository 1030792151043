import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), "End Date can't be before Start Date")
    .required('End date is required'),
});

const CustomDateForm = ({ handleFormChange, formState: initialState }: any) => {
  const [formState, setFormState] = useState(initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFormState(initialState), []);

  return (
    <Formik
      initialValues={formState}
      onSubmit={() => {}}
      enableReinitialize
      validationSchema={validationSchema}>
      {(formik) => {
        const { values } = formik;

        return (
          <Form onChange={handleFormChange}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SimpleInputField
                  name="startDate"
                  type="date"
                  label="Start Date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <SimpleInputField
                  name="endDate"
                  type="date"
                  label="End Date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CustomDateForm;
