/* eslint-disable prefer-template */
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useSidePanelStyles = makeStyles(() => {
  return {
    sidePanelWrapper: {
      backgroundColor: theme.palette.primary?.light,
      width: '400px',
      borderRadius: '8px 8px 0px 0px',
      boxShadow: ' 0px 0px 4px ' + theme.palette.shadow?.main,
      position: 'fixed',
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.secondary?.dark,
      borderRadius: '8px 8px 0px 0px',
      color: theme.palette.primary?.light,
      '& span': {
        padding: '0 15px 0 15px',
      },
    },
    content: {
      height: '71vh',
      overflow: 'auto',
      padding: '1rem',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeBtn: {
      margin: '7px !important',
      '& > span': {
        textTransform: 'none',
      },
    },
    successBtn: {
      margin: '7px !important',
      backgroundColor: `${theme.palette.text.midDark} !important`,
    },
  };
});

export default useSidePanelStyles;
