import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button, Chip, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import ContactRelation from './ContactRelation';
import EmpergencyContact from './EmergencyContact';
import I18nKey from '../../translations/I18nKey';
import OnboardingClient, { PATHS } from '../../api/OnBoarding/onboardingAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getProcessFields } from './OnBoardingListConfig';
import { ApiOperations } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useAddEmployeeStyles from './AddEmployeeStyles';
import { emergencyContactValidationSchema } from './utils';

interface EmployeeContactProps {
  formState: any;
  relations: any;
  employeeUid: String;
  formRef: any;
  status: string;
  updateFetch: () => void;
}

const EmployeeContacts: React.FC<EmployeeContactProps> = ({
  employeeUid,
  formState,
  relations,
  formRef,
  status,
  updateFetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);
  const styles = useAddEmployeeStyles();
  const navigate = useNavigate();

  const formatPayload = (data: any) => {
    return data.map((item: any) => ({ ...getProcessFields(item), relation: item.relation.code }));
  };

  const submitRelations = (relationData: any) => {
    const formattedData = formatPayload(relationData);

    // Check for newly created records
    const postPayload = formattedData.filter((detail: any) => !detail.uid);

    // Check for edited records
    const existingRecords = formattedData.filter((detail: any) => detail.uid);
    const putPayload: any[] = [];
    existingRecords.forEach(({ uid: id }: any) => {
      const before = formState.relationData.find(({ uid }: any) => uid === id);
      const after = relationData.find(({ uid }: any) => uid === id);

      if (!_.isEqual(before, after)) {
        putPayload.push(formatPayload([after])[0]);
      }
    });

    // Check for deleted records
    const deletePayload = formState.relationData
      .filter(
        ({ uid: init_uid }: { uid: string }) =>
          relationData.find(({ uid: final_uid }: any) => final_uid === init_uid) === undefined,
      )
      .map(({ uid }: { uid: string }) => uid);

    let postPromise = null;
    let putPromise = null;
    let deletePromise = null;
    if (postPayload.length) {
      postPromise = OnboardingClient.addRelations(employeeUid, postPayload);
    }
    if (putPayload.length) {
      putPromise = OnboardingClient.updateEmployeeRelation(employeeUid, putPayload);
    }
    if (deletePayload.length) {
      // updated delete functionality once fixed from backend to support multiple relation delete
      deletePromise = OnboardingClient.deleteEmployeeRelation(employeeUid, deletePayload);
    }
    setIsLoading(true);
    Promise.allSettled([postPromise, putPromise, deletePromise])
      .then(() => {
        showSnackbar(
          {
            status: 200,
            data: {
              detail: intl.formatMessage({
                id: I18nKey.TOAST_MESSAGE_UPDATE,
              }),
            },
          },
          'success',
        );
        navigate(`/onBoarding/addEmployee/${employeeUid}/${status}?selected_tab=2`);
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const submitEmergencyContacts = (emergencyContacts: any) => {
    const formattedData = formatPayload(emergencyContacts);

    // Check for newly created records
    const postPayload = formattedData.filter((detail: any) => !detail.uid);

    // Check for edited records
    const existingRecords = formattedData.filter((detail: any) => detail.uid);
    const putPayload: any[] = [];
    existingRecords.forEach(({ uid: id }: any) => {
      const before = formState.emergencyContacts.find(({ uid }: any) => uid === id);
      const after = emergencyContacts.find(({ uid }: any) => uid === id);

      if (!_.isEqual(before, after)) {
        putPayload.push(formatPayload([after])[0]);
      }
    });

    // Check for deleted records
    const deletePayload = formState.emergencyContacts
      .filter(
        ({ uid: init_uid }: { uid: string }) =>
          emergencyContacts.find(({ uid: final_uid }: any) => final_uid === init_uid) === undefined,
      )
      .map(({ uid }: { uid: string }) => uid);

    let postPromise = null;
    let putPromise = null;
    let deletePromise = null;
    if (postPayload.length) {
      postPromise = OnboardingClient.addEmergencyContacts(employeeUid, postPayload);
    }
    if (putPayload.length) {
      putPromise = OnboardingClient.updateEmployeeContact(employeeUid, putPayload);
    }
    if (deletePayload.length) {
      // updated delete functionality once fixed from backend to support multiple relation delete
      deletePromise = OnboardingClient.deleteEmployeeContact(employeeUid, deletePayload);
    }
    setIsLoading(true);
    Promise.allSettled([postPromise, putPromise, deletePromise])
      .then(() => {
        showSnackbar(
          {
            status: 200,
            data: {
              detail: intl.formatMessage({
                id: I18nKey.TOAST_MESSAGE_UPDATE,
              }),
            },
          },
          'success',
        );
        navigate(`/onBoarding/addEmployee/${employeeUid}/${status}?selected_tab=2`);
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    submitEmergencyContacts(values.emergencyContacts);
    submitRelations(values.relationData);
  };
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={{
          relationData: formState.relationData,
          emergencyContacts: formState.emergencyContacts,
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={emergencyContactValidationSchema}>
        {(formik) => {
          return (
            <>
              <Form className={styles.formWrapper}>
                <Divider>
                  <Chip label="Emergency Contact Details" className="divider-chip" />
                </Divider>
                <EmpergencyContact relations={relations} formik={formik} />
                <Divider>
                  <Chip label="Relations" className="divider-chip" />
                </Divider>
                <ContactRelation relations={relations} formik={formik} />
                {(checkAccess(PATHS.EMPLOYEE_RELATION('id'), ApiOperations.POST) ||
                  checkAccess(PATHS.EMERGENCYCONTACT_DETAILS('id'), ApiOperations.POST) ||
                  checkAccess(PATHS.EMPLOYEE_RELATION('id'), ApiOperations.PUT) ||
                  checkAccess(PATHS.EMERGENCYCONTACT_DETAILS('id'), ApiOperations.PUT)) && (
                  <Button
                    disabled={!formik.dirty || !formik.isValid}
                    variant="contained"
                    type="submit"
                    className="upload-button">
                    {intl.formatMessage({
                      id: I18nKey.BUTTON_LABEL_SAVE,
                    })}
                  </Button>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default EmployeeContacts;
