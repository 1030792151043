/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import queryString from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import useAllResourcesStyles from './AllOnBoardingStyles';
import OnBoardingList from './OnBoardingList';
import NewJoineeList from './NewJoineeList';
import MisTabs from '../../components/MisTabs/MisTabs';

interface AllTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

const AllOnBoardings = () => {
  const styles = useAllResourcesStyles();
  const intl = useIntl();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const initialEmployeeStatus =
    queryParams.employee === 'Inactive' ? 1 : queryParams.employee === 'newJoinee' ? 2 : 0;
  const [selectedTab, setSelectedTab] = useState<number>(initialEmployeeStatus);

  const tabUrlMap: any = {
    0: '/onboarding?employee=Active',
    1: '/onboarding?employee=Inactive',
    2: '/onboarding?employee=newJoinee',
  };

  useEffect(() => {
    const activeTabURL = tabUrlMap[selectedTab];
    navigate(activeTabURL, { replace: true });
  }, [selectedTab, navigate]);

  const OnboardingTabs: AllTabs[] = [
    {
      label: 'Active',
      component: <OnBoardingList status={'Active'} />,
    },
    {
      label: 'Inactive',
      component: <OnBoardingList status={'Inactive'} />,
    },
    {
      label: 'New Joiners',
      component: <NewJoineeList />,
    },
  ];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box className={styles.onBoardingWrapper}>
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.ONBOARDING_TITLE,
          })}
        </span>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={params.id ? `${styles.ListWrapper} ${styles.w70}` : styles.ListWrapper}>
          <MisTabs tabs={OnboardingTabs} onTabChange={handleTabChange} selected={selectedTab} />
        </Box>
      </Box>
      <Box className={styles.fabContainer}>
        <Box className={`${styles.iconbutton} ${styles.button}`}>
          <Box>
            <Link to="/onBoarding/addEmployee">
              <AddIcon />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllOnBoardings;
