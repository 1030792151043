import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import {
  GetRevenueParams,
  RevenueProject,
  RevenueProjectResource,
  RevenueEmployee,
  UpdateProjectResourceRevenueParams,
  DeleteProjectParams,
  DeleteProjectResourceParams,
  GetProjectParams,
  ProjectDetail,
} from './revenueTypes';

export const PATHS = Object.freeze({
  GET_REVENUE_PROJECT: `/revenue/project`,
  GET_REVENUE_EMPLOYEE: `/revenue/employee`,
  GET_REVENUE_EMPLOYEE_DETAIL: (id: string) => `/revenue/employee/${id}`,
  GET_REVENUE_PROJECT_DETAIL: (id: string) => `/revenue/project/${id}/resources`,
  PUT_PROJECT_RESOURCES_REVENUE: (id: string) => `/revenue/project/${id}`,
  GET_PROJECT_DETAIL: (project_id: string) => `/project/${project_id}`,
  DELETE_PROJECT_RESOURCE: (project_id: string, employee_id: string) =>
    `/project/${project_id}/employee/${employee_id}`,
});

const getProjectDetails = async (getProjectParams: GetProjectParams): Promise<ProjectDetail> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_PROJECT_DETAIL(getProjectParams.project_id)}`,
  );
  return response.data[0];
};

const getRevenueByProjectList = async (
  getRevenueProjectParams: GetRevenueParams,
): Promise<RevenueProject[]> => {
  const queryParams = queryString.stringify(getRevenueProjectParams);

  const response = await axiosInstance.get(`${PATHS.GET_REVENUE_PROJECT}?${queryParams}`);
  return response.data;
};

const getRevenueByEmployeeList = async (
  getRevenueEmployeeParams: GetRevenueParams,
): Promise<RevenueEmployee[]> => {
  const queryParams = queryString.stringify(getRevenueEmployeeParams);

  const response = await axiosInstance.get(`${PATHS.GET_REVENUE_EMPLOYEE}?${queryParams}`);
  return response.data;
};

const getRevenueProjectDetail = async (
  getRevenueProjectParams: GetRevenueParams,
): Promise<RevenueProjectResource> => {
  const queryParams = queryString.stringify(getRevenueProjectParams);

  const response = await axiosInstance.get(
    `${PATHS.GET_REVENUE_PROJECT_DETAIL(getRevenueProjectParams.project_uid || '')}?${queryParams}`,
  );
  return response.data[0];
};

const getRevenueEmployeeDetail = async (
  getRevenueEmployeeParams: GetRevenueParams,
): Promise<any> => {
  const queryParams = queryString.stringify(getRevenueEmployeeParams);

  const response = await axiosInstance.get(
    `${PATHS.GET_REVENUE_EMPLOYEE_DETAIL(
      getRevenueEmployeeParams.employee_uid || '',
    )}?${queryParams}`,
  );
  return response.data;
};

const updateProjectResourceRevenue = async (
  project_id: string,
  updateProjectResourceRevenueParams: UpdateProjectResourceRevenueParams[],
): Promise<any> => {
  const response = await axiosInstance.post(
    `${PATHS.PUT_PROJECT_RESOURCES_REVENUE(project_id)}`,
    updateProjectResourceRevenueParams,
  );
  return response;
};

const updateProjectInfo = async (
  project_id: string,
  updateProjectInfoParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.GET_PROJECT_DETAIL(project_id)}`,
    updateProjectInfoParams,
  );
  return response;
};

const deleteProjectInfo = async (
  projectId: string,
  deleteOpportunityParams: DeleteProjectParams,
): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.GET_PROJECT_DETAIL(projectId)}`, {
    data: deleteOpportunityParams,
  });
  return response;
};

const deleteProjectResource = async (
  project_id: string,
  employee_id: string,
  deleteProjectResourceParams: DeleteProjectResourceParams,
): Promise<any> => {
  const response = await axiosInstance.delete(
    `${PATHS.DELETE_PROJECT_RESOURCE(project_id, employee_id)}`,
    { data: deleteProjectResourceParams },
  );
  return response.data;
};

export interface RevenueAPIClient {
  readonly getProjectDetails: (getProjectParams: GetProjectParams) => Promise<ProjectDetail>;
  readonly getRevenueByProjectList: (
    getRevenueProjectParams: GetRevenueParams,
  ) => Promise<RevenueProject[]>;
  readonly getRevenueByEmployeeList: (
    getRevenueEmployeeParams: GetRevenueParams,
  ) => Promise<RevenueEmployee[]>;
  readonly getRevenueProjectDetail: (
    getRevenueProjectParams: GetRevenueParams,
  ) => Promise<RevenueProjectResource>;
  readonly getRevenueEmployeeDetail: (getRevenueEmployeeParams: GetRevenueParams) => Promise<any>;
  readonly updateProjectResourceRevenue: (
    project_id: string,
    updateProjectResourceRevenueParams: UpdateProjectResourceRevenueParams[],
  ) => Promise<any>;
  readonly updateProjectInfo: (project_id: string, updateProjectInfoParams: any) => Promise<any>;
  readonly deleteProjectInfo: (
    projectId: string,
    deleteOpportunityParams: DeleteProjectParams,
  ) => Promise<any>;
  readonly deleteProjectResource: (
    project_id: string,
    employee_id: string,
    deleteProjectResourceParams: DeleteProjectResourceParams,
  ) => Promise<any>;
}

const RevenueClient: RevenueAPIClient = Object.freeze({
  getProjectDetails,
  getRevenueByProjectList,
  getRevenueByEmployeeList,
  getRevenueProjectDetail,
  getRevenueEmployeeDetail,
  updateProjectResourceRevenue,
  updateProjectInfo,
  deleteProjectInfo,
  deleteProjectResource,
});

export default RevenueClient;
