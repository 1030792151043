import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import I18nKey from '../../translations/I18nKey';
import useResourceDetailStyles from './ResourceDetailStyles';
import AppraisalClient from '../../api/Appraisal/appraisalAPIs';

interface TableDetailsProps {
  readonly startDate: string;
  readonly endDate: string;
  readonly managerName: string;
  readonly designationName: string;
  readonly employeeAvgRating: any;
  readonly managerAvgRating: any;
}

const ResourceDetailsTable: React.FC<TableDetailsProps> = ({
  startDate,
  endDate,
  managerName,
  designationName,
  employeeAvgRating,
  managerAvgRating,
}: any) => {
  const styles = useResourceDetailStyles();
  const intl = useIntl();

  const resourceDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_START_DATE,
      }),
      value: startDate,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_END_DATE,
      }),
      value: endDate,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_RESOURCE_MANAGER,
      }),
      value: managerName,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_RESOURCE_DESIGNATION,
      }),
      value: designationName,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_EMPLOYEE_AVGRATING,
      }),
      value: employeeAvgRating ? `${employeeAvgRating} / 5` : '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_MANAGER_AVGRATING,
      }),
      value: managerAvgRating ? `${managerAvgRating} / 5` : '-',
    },
  ];
  return (
    <>
      <Box className={styles.projectInfoWrapper}>
        <Grid container spacing={2}>
          {resourceDetails.map((resource) => (
            <Grid item xs={2} className={styles.projectInfo} sx={{ flexDirection: 'column' }}>
              <span className={styles.projectInfoLabel}>{resource.label}</span>
              <p className={styles.projectInfoValue}>{resource.value}</p>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ResourceDetailsTable;
