import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useSearchStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '2px',
      marginBottom: '-14px',
    },
    searchWrapper: {
      margin: '20px 0px',
      height: '85px',
      borderRadius: '12px',
      padding: '20px',
      background: `linear-gradient(to right, rgba(63, 50, 217, 0.6),60%, rgba(235, 0, 255, 0.3))`,
    },
    formWrapper: {
      height: 'fit-content',
      width: '100%',
      borderRadius: '8px',
      background: theme.palette.primary.main,
    },
    searchBtn: {
      float: 'right',
    },
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px !important',
        outline: 'none',
      },
    },
    formField: {
      '& input': {
        height: '18px',
      },
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export default useSearchStyles;
