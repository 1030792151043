import { Box, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { getFYDateRange } from '../../../utils/utils';
import ChartsClient from '../../../api/Charts/chartsAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { getConvertedCurrency } from '../../../utils/exchangeRates';
import useChartStyles from './ChartStyles';

const RevenueChart = () => {
  const [isLoading, setLoading] = useState(false);
  const [revenues, setRevenues] = useState<any[]>([]);
  const [months, setMonths] = useState<any>([]);
  const [parents, setParents] = useState<any>([]);
  const [totalRevenues, setTotalRevenues] = useState([]);
  const currFYDateRange = getFYDateRange(dayjs());
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useChartStyles();

  useEffect(() => {
    setLoading(true);
    const getRevenueProjectParams: any = {
      start_date: currFYDateRange.startDate,
      end_date: currFYDateRange.endDate,
    };
    ChartsClient.getRevenueList(getRevenueProjectParams)
      .then((result: any) => {
        setRevenues(result.slice(0, 5));
      })
      .catch((e) => {
        showSnackbar('error', e);
      })
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (revenues) {
      const updatedRevenue = revenues.map((v) => ({
        ...v,
        revenue: v.revenue.map((item: any) => ({
          currency: 'USD',
          totalRevenue: getConvertedCurrency(item.totalRevenue, item.currency),
        })),
      }));
      const a: Record<string, number> = {};
      updatedRevenue.forEach((r: any) => {
        r.revenue.forEach((t: any) => {
          a[r.month] = (a[r.month] || 0) + t.totalRevenue;
        });
      });
      const month: any = [];
      const parent: any = [];
      const revs: any = [];
      Object.keys(a).forEach((c: any) => {
        month.push(`${dayjs(c).format('MMMM')} ($)`);
        revs.push(a[c]);
        parent.push('');
      });
      setMonths(month);
      setParents(parent);
      setTotalRevenues(revs);
    }
  }, [revenues]);

  return isLoading ? (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Plot
          config={{ displayModeBar: false }}
          data={[
            {
              type: 'treemap',
              labels: months,
              values: totalRevenues,
              parents,
              textinfo: 'label+text+value',
              textposition: 'middle center',
            },
          ]}
          layout={{
            autosize: true,
            width: 390,
            height: 223,
            margin: {
              l: 20,
              r: 0,
              b: 0,
              t: 0,
            },
            colorway: ['#84B0FF', '#3780FF', '#41587F', '#2C66CC'],
          }}
        />
      </Box>
    </>
  );
};

export default RevenueChart;
