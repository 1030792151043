import React from 'react';
import { Formik, Form } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useAllCandidateStyles from './AllCandidatesStyles';

const CandidateShareForm: React.FC<any> = ({
  initialField,
  setInitialField,
  setIsShareFormValid,
  shareFormRef,
  compensationLetterCondition,
}) => {
  const styles = useAllCandidateStyles();
  const handleChange = (values: any): any => {
    setIsShareFormValid(!(values.to && values.cc && values.subject && values.message));
  };
  const shareFormValidationSchema = Yup.object().shape({
    to: Yup.string().required('Email is required'),
    cc: Yup.string().required('Email is required'),
    bcc: Yup.string().nullable(),
    message: Yup.string().required('Message is required'),
    subject: Yup.string().required('Subject is required'),
  });
  return (
    <Formik
      initialValues={initialField}
      onSubmit={() => {}}
      validationSchema={shareFormValidationSchema}
      innerRef={shareFormRef}
      enableReinitialize>
      {(formik) => {
        const { values } = formik;
        return (
          <Form className={styles.candidateShareFormWrapper} onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleInputField label="To" name="to" type="string" fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <SimpleInputField label="CC" name="cc" type="string" fullWidth required />
              </Grid>
              {compensationLetterCondition && (
                <Grid item xs={12}>
                  <SimpleInputField label="BCC" name="bcc" type="string" fullWidth />
                </Grid>
              )}
              <Grid item xs={12}>
                <SimpleInputField
                  label="Subject"
                  name="subject"
                  type="string"
                  placeholder="Enter Your subject"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleInputField
                  name="message"
                  type="string"
                  placeholder="Enter Your Message"
                  minRows={11}
                  multiline
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CandidateShareForm;
