import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import useChartStyles from './ChartStyles';
import ChartsClient from '../../../api/Charts/chartsAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { getFYDateRange } from '../../../utils/utils';

const currentFy = getFYDateRange(dayjs());

const ResourceChart: React.FC = () => {
  const styles = useChartStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [billedData, setBilledData] = useState<any>([]);
  const [notBilledData, setNotBilledData] = useState<any>([]);
  const [month, setMonth] = useState<any>([]);

  const currentDate = dayjs();
  const firstDateOfPrevMonth = currentDate
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');
  useEffect(() => {
    setLoading(true);
    const resourceParams = {
      start_date: firstDateOfPrevMonth,
      end_date: currentFy.endDate,
    };
    ChartsClient.getResourceList(resourceParams)
      .then((result) => {
        setList(result.slice(0, 5));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (list.length) {
      const lastFiveMonthData = Object.values(list);
      const billed = lastFiveMonthData.map((item: any) => item.billed);
      const notBilled = lastFiveMonthData.map((item: any) => item.notBilled);
      const monthFormat = lastFiveMonthData.map((item: any) => dayjs(item.month).format('MMMM'));
      setBilledData(billed);
      setNotBilledData(notBilled);
      setMonth(monthFormat);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [list]);

  return isLoading ? (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Box>
        <Box className={styles.parentWrapper}>
          <Plot
            data={[
              {
                x: month,
                y: billedData,
                name: 'Billed',
                type: 'scatter',
                marker: {
                  color: '#094A9E',
                },
              },

              {
                x: month,
                y: notBilledData,
                type: 'scatter',
                name: 'Non-billed',
                marker: {
                  color: '#39A5F5',
                },
              },
            ]}
            layout={{
              autosize: true,
              width: 390,
              height: 223,
              margin: {
                l: 30,
                r: 0,
                b: 0,
                t: 0,
              },
              xaxis: {
                showline: true,
              },
              yaxis: {
                showline: true,
                tickmode: 'linear',
                dtick: 100,
                range: [0, 500],
              },
              legend: {
                orientation: 'h',
                x: 0.2,
                y: -0.1,
              },
              showlegend: true,
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
export default ResourceChart;
