import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useInvoiceDetailStyles = makeStyles((theme: Theme) => {
  return {
    InvoiceDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
    },
    Header: {
      marginBottom: '2rem',
      fontSize: '14px',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '1rem',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    UpperCardFields: {
      flexDirection: 'column',
      flex: 1,
      textAlign: 'center',
    },
    UpperCardFieldsKey: { fontSize: '12px' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    MiddleCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1rem',
    },
    LowerCardInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem',
    },
    ManualMiddleCardInfo: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1rem',
      padding: '2rem 10rem',
      '& .upload-button': {
        margin: '1rem 0 0 auto',
      },
      '& .custom-file-input input': {
        color: 'transparent',
      },
    },
    CardHeader: {
      padding: '1rem',
      color: theme.palette.text.midDark,
      fontSize: '18px !important',
    },
    LowerCardInfoLeftSection: {
      color: theme.palette.text.midDark,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      paddingTop: '5px',
    },
    LowerCardInfoRightSection: {
      '& svg': {
        marginBottom: '-5px',
        marginLeft: '20px',
        color: theme.palette.tertiary?.light,
      },

      '& .disable-btn': {
        color: `${theme.palette.tertiary?.light}73`,
      },

      '& button': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    LowerCardInfoRightSectionCheckbox: {
      '& svg': {
        marginBottom: '0px',
        marginLeft: '0px',
        color: theme.palette.secondary?.main,
      },
    },
    OtherDetailsWrapper: {
      marginTop: '1rem',
      position: 'relative',
    },
    DetailFormContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:hover': {
        '& > $DetailArrowBtnWrapper': {
          visibility: 'visible',
        },
      },
    },
    DetailFormWrapper: {
      padding: '1rem 0 1rem 1.8rem',
      position: 'relative',
      flex: 1,
    },
    DetailArrowBtnWrapper: {
      margin: '0 1rem',
      justifyContent: 'center',
      visibility: 'hidden',
      display: 'flex',
      flexDirection: 'column',

      '& svg': {
        color: theme.palette.secondary?.main,
        cursor: 'pointer',
        fontSize: '35px',
      },
    },

    FieldWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',

      '&:hover': {
        '& $arrowBtnWrapper': {
          visibility: 'visible',
        },
      },
    },
    Field: {
      display: 'flex',
      flexDirection: 'column',

      '&:nth-child(2)': {
        width: '40%',
        marginRight: '10px',
      },
    },
    ErrorMsg: {
      color: 'red',
    },
    AddBtn: {
      top: 3,
      right: 0,

      '& svg': {
        fontSize: '40px',
      },
    },
    OtherDetailsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formLabel: {
      fontSize: '16px',
      margin: '0 10px',
      fontWeight: '600',
      color: theme.palette.text.midDark,
    },
    closeBtn: {
      backgroundColor: `${theme.palette.tertiary?.main} !important`,
      borderRadius: '50% !important',
      padding: 0,
      minWidth: '17px !important',
      top: '30px',
      right: '-10px',
      fontWeight: 700,

      '& svg': {
        fontSize: '22px',
        color: 'black !important',
      },
    },
    SubmitBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0 15px 15px 0',
    },
    Btn: {
      cursor: 'pointer',
    },
    innerAddBtn: {
      float: 'right',
    },
    innerRemoveBtn: {
      color: 'red !important',
    },
    arrowBtnWrapper: {
      marginLeft: '1rem',
      visibility: 'hidden',

      '& svg': {
        color: theme.palette.secondary?.main,
        cursor: 'pointer',
      },
    },
  };
});

export default useInvoiceDetailStyles;
