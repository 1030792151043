import { Button, Chip, Divider, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import I18nKey from '../../translations/I18nKey';
import useNewCandidateStyles from './NewCandidateStyles';
import { personalDetailFormValidation } from './utils';

interface NewCandidatePersonalDetailsProps {
  formState: any;
  formRef: any;
  onSave: any;
}

const NewCandidatePersonalDetails: React.FC<NewCandidatePersonalDetailsProps> = ({
  formState,
  formRef,
  onSave,
}) => {
  const [isPermanentAddressDisable, setIsPermanentAddressDisable] = useState(
    formState.isSameAddress,
  );

  const styles = useNewCandidateStyles();
  const intl = useIntl();

  const handleAddressChange = (from: string, to: string, e: any) => {
    formRef?.current?.setFieldValue(from, e?.target.value);

    if (isPermanentAddressDisable) formRef?.current?.setFieldValue(to, e?.target?.value);
  };

  const handleSimilarAddressValues = () => {
    formRef?.current?.setFieldValue('permanentAddress', formRef?.current?.values.currentAddress);
    formRef?.current?.setFieldValue('permanentCity', formRef?.current?.values.currentCity);
    formRef?.current?.setFieldValue('permanentPincode', formRef?.current?.values.currentPincode);
    formRef?.current?.setFieldValue('permanentState', formRef?.current?.values.currentState);
    formRef?.current?.setFieldValue('permanentCountry', formRef?.current?.values.currentCountry);

    setTimeout(() => {
      formRef?.current?.setTouched({
        ...formRef?.current?.touched,
        permanentAddress: true,
        permanentCity: true,
        permanentPincode: true,
        permanentState: true,
        permanentCountry: true,
      });
      formRef?.current?.validateForm(formRef?.current?.values);
    });
  };

  const handlePanChange = (e: any) =>
    formRef?.current?.setFieldValue('pan', e?.target?.value.toUpperCase());

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    if (value) {
      formRef?.current?.setFieldValue('isSameAddress', true);
      setIsPermanentAddressDisable(true);
      handleSimilarAddressValues();
    } else {
      formRef?.current?.setFieldValue('isSameAddress', false);
      formRef?.current?.setFieldValue('permanentAddress', '');
      formRef?.current?.setFieldValue('permanentCity', '');
      formRef?.current?.setFieldValue('permanentPincode', '');
      formRef?.current?.setFieldValue('permanentState', '');
      formRef?.current?.setFieldValue('permanentCountry', '');
      setIsPermanentAddressDisable(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={onSave}
        validationSchema={personalDetailFormValidation}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, errors, touched } = formik;

          return (
            <Form className={styles.formWrapper}>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Finance Details" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="pan"
                    type="string"
                    label="PAN"
                    size="small"
                    onChange={handlePanChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="aadhaar"
                    type="string"
                    label="Aadhaar No."
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField name="uan" type="string" label="UAN *" size="small" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="bankName"
                    type="string"
                    label="Bank Name *"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="bankIfscCode"
                    type="string"
                    label="Bank IFSC Code *"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="bankAccountNo"
                    type="string"
                    label="Bank Account Number *"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Current Address" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={8}>
                  <SimpleCheckboxField
                    name="isSameAddress"
                    checked={values.isSameAddress}
                    inputProps={{ style: { fontSize: 13 } }}
                    setFieldValue={setFieldValue}
                    label="Use this address for permanent Address"
                    handleChange={handleCheckboxChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={8}>
                  <SimpleInputField
                    name="currentAddress"
                    type="string"
                    label="Current Address Line"
                    size="small"
                    onChange={(e: any) => {
                      handleAddressChange('currentAddress', 'permanentAddress', e);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="currentCity"
                    type="string"
                    label="Current City"
                    size="small"
                    onChange={(e: any) => {
                      handleAddressChange('currentCity', 'permanentCity', e);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="currentState"
                    type="string"
                    label="Current State"
                    size="small"
                    onChange={(e: any) => {
                      handleAddressChange('currentState', 'permanentState', e);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="currentCountry"
                    type="string"
                    label="Current Country"
                    size="small"
                    onChange={(e: any) => {
                      handleAddressChange('currentCountry', 'permanentCountry', e);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <SimpleInputField
                    name="currentPincode"
                    type="string"
                    label="Current Pincode"
                    size="small"
                    onChange={(e: any) => {
                      handleAddressChange('currentPincode', 'permanentPincode', e);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Permanent Address" className="divider-chip" />
                  </Divider>
                </Grid>
                <Grid item xs={8}>
                  <SimpleInputField
                    name="permanentAddress"
                    disabled={isPermanentAddressDisable}
                    type="string"
                    label="Permanent Address Line"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="permanentCity"
                    disabled={isPermanentAddressDisable}
                    type="string"
                    label="Permanent City"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="permanentState"
                    disabled={isPermanentAddressDisable}
                    type="string"
                    label="Permanent State"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleInputField
                    name="permanentCountry"
                    disabled={isPermanentAddressDisable}
                    type="string"
                    label="Permanent Country"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <SimpleInputField
                    name="permanentPincode"
                    disabled={isPermanentAddressDisable}
                    type="string"
                    label="Permanent Pincode"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Button
                disabled={!dirty || !isValid}
                variant="contained"
                type="submit"
                className="upload-button">
                {intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_SAVE,
                })}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewCandidatePersonalDetails;
