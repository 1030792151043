import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import queryString from 'query-string';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MasterClient from '../../api/Master/masterAPIs';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useAddEmployeeStyles from './AddEmployeeStyles';
import AddEmployeeBasicDetails from './AddEmployeeBasicDetails';
import OnboardingClient from '../../api/OnBoarding/onboardingAPIs';
import EmpDocument from '../../components/EmployeeDocument/EmpDocument';
import EmployeeContact from './EmployeeContacts';
import AddEmployeePersonalDetails from './AddEmployeePersonalDetails';
import MisDialog from '../../components/MisDialog/MisDialog';
import DeleteEmployee from './DeleteEmployee';
import EmployeeExitDetails from './EmployeeExitDetails';
import { getFullName } from '../../utils/utils';
import { removeSpecialChars } from './utils';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}
const currentDate = dayjs().format('YYYY-MM-DD');
const AddEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [uidEmployeeManager, setUidEmployeeManager] = useState('');
  const [primarySkillsUid, setPrimarySkillsUid] = useState('');
  const [otherSkillsUid, setOtherSkillsUid] = useState('');
  const [otherDetailsUid, setOtherDetailsUid] = useState('');
  const [financeUid, setFinanceUid] = useState<string | null>(null);
  const [isDeleteFormValid, setIsDeleteFormValid] = useState(true);
  const [fetch, setFetch] = useState(0);
  const [newJoineeExitRemarks, setNewJoineeExitRemarks] = React.useState(null);
  const [formState, setFormState] = useState({
    0: {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: null,
      nationality: 'Indian',
      bloodGroup: null,
      dob: currentDate,
      email: '',
      personalEmail: '',
      phone: '',
      linkedinUrl: '',
      doj: currentDate,
      employeeStatus: null,
      employeeType: null,
      employmentType: null,
      department: null,
      track: null,
      designation: null,
      designationEffectiveDate: currentDate,
      skills: [],
      otherSkills: [],
      isOldEmployee: false,
      inactiveEmployee: null,
      manager: null,
      managerStartDate: currentDate,
      groupNames: [],
      remarks: '',
    },
    1: {
      pan: '',
      aadhaar: '',
      uan: '',
      bankAccountNo: '',
      bankIfscCode: '',
      bankName: '',
      isSameAddress: false,
      currentAddress: '',
      currentCity: '',
      currentPincode: '',
      currentState: '',
      currentCountry: '',
      permanentAddress: '',
      permanentCity: '',
      permanentState: '',
      permanentPincode: '',
      permanentCountry: '',
    },
    2: {
      relationData: [],
      emergencyContacts: [],
    },
    4: {
      dateOfResignation: currentDate,
      proposedExitDate: currentDate,
      exitReason: '',
      exitRemarks: '',
    },
  });

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = queryString.parse(location?.search);

  //   Prerequisites
  const [allSkills, setAllSkills] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allTracks, setAllTracks] = useState([]);
  const [allDesignations, setAllDesignations] = useState([]);
  const [employees, setEmployees] = useState<any>([]);
  const [gender, setGender] = useState([]);
  const [employmentType, setEmploymentType] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [relations, setRelations] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [exitReason, setExitReason] = useState([]);
  const [inactiveEmployees, setInActiveEmployees] = useState([]);
  const [allGroupRoles, setallGroupRoles] = useState([]);
  const [allEnumApiSuccess, setAllEnumApiSuccess] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [tabValue, setTabValue] = useState(Number(queryParams?.selected_tab) || 0);

  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const params = useParams();
  const styles = useAddEmployeeStyles();
  const basicFormRef = useRef();
  const personalFormRef = useRef();
  const contactFormRef = useRef();
  const exitDetailsFormRef = useRef();
  const deleteFormRef = useRef<any>();

  const employeeUid = params.id || '';
  const employeeStatusById = params.status || '';

  const formRefDict: Record<number, any> = {
    0: basicFormRef,
    1: personalFormRef,
    2: contactFormRef,
    4: exitDetailsFormRef,
  };

  const getLabel = (values: any) =>
    values.map((el: any) => ({
      ...el,
      label: el.name,
      value: el.uid,
    }));

  const formatCodeData = (values: any) =>
    values.map((el: any) => ({
      ...el,
      label: el.label,
      value: el.code,
    }));

  const getLabelInactiveEmployees = (values: any) => {
    return values.map((el: any) => ({
      label: `${el.employeeId} - ${el.firstName} ${el.lastName}`,
      value: el.uid,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      CustomerRelationClient.getEmployeeList(),
      MasterClient.getSkills(),
      MasterClient.getDepartment(),
      MasterClient.getTrack(),
      MasterClient.getDesignation(),
      MasterClient.getAllEnums(),
      MasterClient.getExitReason(),
      OnboardingClient.getInactiveEmployees(),
    ])
      .then((response) => {
        response.forEach((res, index) => {
          if (res.status === 'fulfilled') {
            if (index === 0) {
              setEmployees(
                res.value.map((contact: any) => ({
                  label: getFullName(contact),
                  value: contact.uid,
                })),
              );
            } else if (index === 1) {
              setAllSkills(
                res.value.data.map((skill: any) => {
                  return {
                    label: skill.name,
                    value: skill.uid,
                  };
                }),
              );
            } else if (index === 2) {
              setAllDepartments(getLabel(res.value.data));
            } else if (index === 3) {
              setAllTracks(getLabel(res.value.data));
            } else if (index === 4) {
              setAllDesignations(getLabel(res.value.data));
            } else if (index === 5) {
              setGender(formatCodeData(res.value.data.gender));
              setEmployeeType(formatCodeData(res.value.data.employee_type));
              setEmploymentType(formatCodeData(res.value.data.employment_type));
              setBloodGroup(formatCodeData(res.value.data.blood_group));
              setEmployeeStatus(formatCodeData(res.value.data.employee_status));
              setRelations(formatCodeData(res.value.data.relation));
              setallGroupRoles(formatCodeData(res.value.data.access_group));
              setAllEnumApiSuccess(true);
            } else if (index === 6) {
              setExitReason(formatCodeData(res.value.data));
            } else if (index === 7) {
              setInActiveEmployees(getLabelInactiveEmployees(res.value.data));
            }
          } else {
            setIsLoading(false);
            showSnackbar(res.status, 'error');
          }
        });
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // For Edit mode
  const getUpdatedSkillValues = (skillData: any, isPrimary: boolean) => {
    if (skillData.length) {
      skillData.forEach((v: any) => {
        if (v.isPrimary) {
          setPrimarySkillsUid(v.uid);
        } else {
          setOtherSkillsUid(v.uid);
        }
      });
      const skills = skillData.filter((v: any) => v.isPrimary === isPrimary);
      if (skills.length) {
        const updatedSkills = skills.map((item: any) => {
          return item.skillNames.map((skill: any) => {
            return {
              name: skill.name,
              uid: skill.id,
            };
          });
        });
        return updatedSkills[0];
      }
    }
    return [];
  };

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const getDefaultBasicFieldValues = () => {
    const newBasicFormState = { ...formState[0] };
    newBasicFormState.employeeStatus =
      employeeStatus.find((v: any) => v.label === 'Active') || null;
    newBasicFormState.employeeType = employeeType.find((v: any) => v.label === 'Employee') || null;
    newBasicFormState.employmentType =
      employmentType.find((v: any) => v.label === 'Full Time') || null;
    newBasicFormState.groupNames = allGroupRoles.filter((v: any) => v.label === 'Employee') || [];
    setFormState({
      ...formState,
      0: newBasicFormState,
    });
  };

  const updateEmployeeBasicDetails = (
    employeeData: any,
    professionalData: any,
    otherDetailsData: any,
  ) => {
    if (employeeData && professionalData) {
      const { firstName, lastName } = employeeData;
      let emailStr = '';
      if (employeeStatusById === 'newJoinee') {
        if (firstName && lastName) {
          const formattedFirstName = removeSpecialChars(firstName);
          const formattedLastName = removeSpecialChars(lastName);
          emailStr = `${formattedFirstName}.${formattedLastName}@techprescient.com`;
        }
      }
      const updatedBasicDetails = {
        ...formState[0],
        firstName: employeeData.firstName,
        middleName: employeeData.middleName || '',
        lastName: employeeData.lastName,
        gender: gender.filter((v: any) => v.code === employeeData.gender)[0],
        nationality: employeeData.nationality,
        bloodGroup: bloodGroup.filter((v: any) => v.code === employeeData.bloodGroup)[0] || null,
        dob: employeeData.dob,
        personalEmail: employeeData.personalEmail,
        email:
          employeeData.email === '' || employeeData.email === null ? emailStr : employeeData.email,
        phone: employeeData.phone,
        linkedinUrl: employeeData.linkedinUrl,
        doj: employeeData.doj || currentDate,
        employeeStatus:
          employeeStatus.filter((v: any) => v.code === employeeData.status)[0] ||
          employeeStatus.find((v: any) => v.label === 'Active'),
        employeeType:
          employeeType.filter((v: any) => v.code === employeeData.employeeType)[0] ||
          employeeType.find((v: any) => v.label === 'Employee'),
        employmentType:
          employmentType.filter((v: any) => v.code === employeeData.employmentType)[0] ||
          employmentType.find((v: any) => v.label === 'Full Time'),
        groupNames: allGroupRoles.filter((v: any) => v.label === 'Employee') || [],
        remarks: otherDetailsData?.remarks || '',
      };

      const designationData = professionalData.employeeDesignation;
      if (designationData) {
        const designation: any = allDesignations.find(
          (v: any) => v.uid === designationData.designationId,
        );

        updatedBasicDetails.designation = designation;
        updatedBasicDetails.department =
          allDepartments.find((v: any) => v.uid === designation?.departmentId) || null;
        updatedBasicDetails.track =
          allTracks.find((v: any) => v.uid === designation?.trackId) || null;
        updatedBasicDetails.designationEffectiveDate = designationData.startDate;
      }

      const managerData = { ...professionalData.employeeManager };
      if (managerData) {
        const manager = employees.find((v: any) => v.value === managerData.managerUid);
        setUidEmployeeManager(managerData.uid);
        updatedBasicDetails.manager = manager;
        updatedBasicDetails.managerStartDate = managerData.startDate;
      }

      const skillData = professionalData.employeeSkill;
      if (skillData) {
        const primarySkills = getUpdatedSkillValues(skillData, true);
        const otherSkills = getUpdatedSkillValues(skillData, false);

        if (primarySkills.length) {
          updatedBasicDetails.skills = allSkills?.filter(
            (s: any) =>
              primarySkills.find((sk: { name: string; uid: string }) => s.value === sk.uid) !==
              undefined,
          );
        }
        if (otherSkills.length) {
          updatedBasicDetails.otherSkills = allSkills?.filter(
            (s: any) =>
              otherSkills.find((sk: { name: string; uid: string }) => s.value === sk.uid) !==
              undefined,
          );
        }
      }

      if (otherDetailsData?.uid) {
        setOtherDetailsUid(otherDetailsData.uid);
      }
      if (otherDetailsData?.groupNames?.length) {
        const groupNameData = otherDetailsData?.groupNames;
        updatedBasicDetails.groupNames = allGroupRoles?.filter(
          (groupRole: any) =>
            groupNameData.find((name: string) => groupRole.code === name.toLowerCase()) !==
            undefined,
        );
      }
      return updatedBasicDetails;
    }

    return null;
  };

  const updatedEmployeeContactDetails = (emergencyContacts: any, contactRelations: any) => {
    const updatedEmergencyContacts = emergencyContacts.map((contact: any) => ({
      ...contact,
      relation: relations.find((rel: any) => rel.code === contact.relation),
    }));
    const updatedContactRelations = contactRelations.map((contact: any) => ({
      ...contact,
      relation: relations.find((rel: any) => rel.code === contact.relation),
    }));
    return { emergencyContacts: updatedEmergencyContacts, relationData: updatedContactRelations };
  };

  const getUpdatedEmployeePersonalDetails = (employeeData: any, financeData: any) => {
    if (employeeData && financeData) {
      setFinanceUid(financeData.uid);

      const updatedPersonalData = {
        ...formState[1],
      };

      updatedPersonalData.pan = financeData.pan || '';
      updatedPersonalData.aadhaar = financeData.aadhaar || '';
      updatedPersonalData.uan = financeData.uan || '';
      updatedPersonalData.bankAccountNo = financeData.bankAccountNo || '';
      updatedPersonalData.bankIfscCode = financeData.bankIfscCode || '';
      updatedPersonalData.bankName = financeData.bankName || '';
      updatedPersonalData.currentAddress = employeeData.currentAddress || '';
      updatedPersonalData.currentCity = employeeData.currentCity || '';
      updatedPersonalData.currentPincode = employeeData.currentPincode || '';
      updatedPersonalData.currentState = employeeData.currentState || '';
      updatedPersonalData.currentCountry = employeeData.currentCountry || '';
      updatedPersonalData.permanentAddress = employeeData.permanentAddress || '';
      updatedPersonalData.permanentCity = employeeData.permanentCity || '';
      updatedPersonalData.permanentCountry = employeeData.permanentCountry || '';
      updatedPersonalData.permanentPincode = employeeData.permanentPincode || '';
      updatedPersonalData.permanentState = employeeData.permanentState || '';

      return updatedPersonalData;
    }

    return null;
  };

  const updateDefaultExitDetails = (employeeData: any) => {
    if (employeeData) {
      return {
        dateOfResignation: employeeData.dateOfResignation || currentDate,
        proposedExitDate: employeeData.proposedExitDate || currentDate,
        exitReason: exitReason.find((reason: any) => reason.code === employeeData.exitReason),
        exitRemarks: employeeData.exitRemarks?.details,
      };
    }
    return null;
  };

  const getEmployeeById = (uid: string, status: string) => {
    setIsLoading(true);
    OnboardingClient.getEmployeeById(uid, status)
      .then(async (response: any) => {
        const { data } = response;
        const formData = data[0];
        const employeeData = formData.employee;
        const financeData = formData.employeeFinance;
        const otherDetailsData = formData.employeeOtherDetails;
        const contactData = formData.employeeEmergencyContact[0];
        const professionalData = {
          employeeManager: formData.employeeManager,
          employeeDesignation: formData.employeeDesignation,
          employeeSkill: formData.employeeSkill,
        };
        const updatedBasicDetails = updateEmployeeBasicDetails(
          employeeData,
          professionalData,
          otherDetailsData,
        );
        const updatedPersonalDetails = getUpdatedEmployeePersonalDetails(employeeData, financeData);
        const newFormState: any = { ...formState };

        newFormState[0] = updatedBasicDetails;
        newFormState[1] = updatedPersonalDetails;
        newFormState[2] = updatedEmployeeContactDetails(
          formData.employeeEmergencyContact,
          formData.employeeRelation,
        );
        newFormState[4] = updateDefaultExitDetails(employeeData);
        setFormState(newFormState);
        setEmployeeName(getFullName(employeeData));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  const getNewJoineeById = (uid: string) => {
    setIsLoading(true);
    OnboardingClient.getNewJoineeById(uid)
      .then(async (response: any) => {
        const { data } = response;
        const formData = data;
        const employeeData = formData.employee;
        const financeData = formData.employeeFinance;
        const otherDetailsData = formData.employeeOtherDetails;
        const contactData = formData.employeeEmergencyContact[0];
        const professionalData = {
          employeeManager: formData.employeeManager,
          employeeDesignation: formData.employeeDesignation,
          employeeSkill: formData.employeeSkill,
        };
        const updatedBasicDetails = updateEmployeeBasicDetails(
          employeeData,
          professionalData,
          otherDetailsData,
        );
        const updatedPersonalDetails = getUpdatedEmployeePersonalDetails(employeeData, financeData);
        const newFormState: any = { ...formState };

        newFormState[0] = updatedBasicDetails;
        newFormState[1] = updatedPersonalDetails;
        newFormState[2] = updatedEmployeeContactDetails(
          formData.employeeEmergencyContact,
          formData.employeeRelation,
        );
        newFormState[4] = updateDefaultExitDetails(employeeData);
        setFormState(newFormState);
        setEmployeeName(getFullName(employeeData));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (employees && allSkills && allDesignations && allEnumApiSuccess) {
      if (employeeUid && employeeStatusById !== 'newJoinee') {
        getEmployeeById(employeeUid, employeeStatusById);
      } else if (employeeStatusById === 'newJoinee') {
        getNewJoineeById(employeeUid);
      }
      getDefaultBasicFieldValues();
    }
  }, [employees, allSkills, allDesignations, allGroupRoles, allEnumApiSuccess, employeeUid, fetch]);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const handleTabChange = (event: any, newValue: number) => {
    // Preserving the current state of the form in the global state
    if (formRefDict[tabValue]?.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[tabValue] = formRefDict[tabValue]?.current?.values;
      setFormState(newFormState);
    }

    formRefDict[newValue]?.current?.validateForm();
    // formRefDict[newValue]?.current?.isDirty = true;
    setTabValue(newValue);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setNewJoineeExitRemarks(null);
  };

  const handleDelete = () => {
    setOpenDialog(false);
    const deleteEmployeeParams = {
      dos: deleteFormRef?.current?.values.dos,
      exitRemarks: { details: deleteFormRef?.current?.values.exitRemarks },
    };
    const deleteNewJoineeParams = {
      comments: newJoineeExitRemarks,
    };
    if (employeeUid && employeeStatusById === 'newJoinee') {
      setIsLoading(true);
      OnboardingClient.deleteNewJoinee(employeeUid, deleteNewJoineeParams)
        .then((result: any) => {
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
            setNewJoineeExitRemarks(null);
            navigate(`/onboarding?employee=${params.status}`);
          }
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    if (employeeUid && employeeStatusById !== 'newJoinee') {
      setIsLoading(true);
      OnboardingClient.deleteEmployee(employeeUid, deleteEmployeeParams)
        .then((result: any) => {
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
            navigate('/onBoarding');
          }
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Box className={styles.addEmployeeWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.Header}>
        <Box>
          {employeeStatusById === 'newJoinee' ? (
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.ONBOARDING_NEWJOINEE_DETAILS,
              })}
            </span>
          ) : (
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.ONBOARDING_EMPLOYEE_DETAILS,
              })}
            </span>
          )}
          {employeeUid ? (
            <Box>
              <Link to={`/onboarding?employee=${params.status}`}>
                {intl.formatMessage({
                  id: I18nKey.ONBOARDING_EMPLOYEE,
                })}{' '}
              </Link>
              / {employeeName}
            </Box>
          ) : (
            <Box>
              <Link to="/onBoarding">
                {intl.formatMessage({
                  id: I18nKey.ONBOARDING_EMPLOYEE,
                })}{' '}
              </Link>
              /{' '}
              {intl.formatMessage({
                id: I18nKey.ONBOARDING_ADD_EMPLOYEE,
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={`${styles.formContainer} ${tabValue === 3 ? styles.DocformContainer : ''}`}>
          <Tabs
            className={styles.tabs}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example">
            <Tab label="Basic details" icon={<PersonPinIcon />} iconPosition="start" />
            <Tab
              label="Personal details"
              icon={<AssignmentIcon />}
              iconPosition="start"
              disabled={!employeeUid || employeeStatusById === 'newJoinee'}
            />
            <Tab
              label="Emergency Contact"
              icon={<PhoneIcon />}
              iconPosition="start"
              disabled={!employeeUid || employeeStatusById === 'newJoinee'}
            />
            <Tab
              label="Documents"
              icon={<NoteAddIcon />}
              iconPosition="start"
              disabled={!employeeUid || employeeStatusById === 'newJoinee'}
            />
            {employeeUid && (
              <Tab
                label="Exit Details"
                icon={<ExitToAppIcon />}
                iconPosition="start"
                disabled={!employeeUid || employeeStatusById === 'newJoinee'}
              />
            )}
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <AddEmployeeBasicDetails
              formState={formState[0]}
              gender={gender}
              bloodGroup={bloodGroup}
              employees={employees}
              allSkills={allSkills}
              allDepartments={allDepartments}
              allTracks={allTracks}
              allDesignations={allDesignations}
              employeeStatus={employeeStatus}
              employmentType={employmentType}
              employeeType={employeeType}
              employeeUid={employeeUid}
              primarySkillsUid={primarySkillsUid}
              otherSkillsUid={otherSkillsUid}
              uidEmployeeManager={uidEmployeeManager}
              inactiveEmployees={inactiveEmployees}
              otherDetailsUid={otherDetailsUid}
              allGroupRoles={allGroupRoles}
              formRef={basicFormRef}
              updateFetch={updateFetch}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AddEmployeePersonalDetails
              formState={formState[1]}
              formRef={personalFormRef}
              employeeUid={employeeUid}
              financeUid={financeUid}
              status={employeeStatusById}
              updateFetch={updateFetch}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <EmployeeContact
              formState={formState[2]}
              relations={relations}
              employeeUid={employeeUid}
              formRef={contactFormRef}
              status={employeeStatusById}
              updateFetch={updateFetch}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <EmpDocument isUploadable employeeId={employeeUid} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <EmployeeExitDetails
              formState={formState[4]}
              formRef={exitDetailsFormRef}
              employeeUid={employeeUid}
              exitReason={exitReason}
              updateFetch={updateFetch}
            />
          </TabPanel>
          {employeeUid && employeeStatusById !== 'Inactive' && (
            <Button sx={{ position: 'absolute' }} className={styles.deleteBtn} onClick={handleOpen}>
              Delete
            </Button>
          )}
        </Box>
      </Box>
      {employeeStatusById === 'newJoinee' ? (
        <MisDialog
          title={intl.formatMessage({ id: I18nKey.ONBOARDING_DELETE_NEWJOINEE })}
          message={intl.formatMessage({ id: I18nKey.ONBOARDING_DELETE_NEW_JOINEE_MSG })}
          isOpen={openDialog}
          handleSuccess={handleDelete}
          handleClose={handleClose}
          actionBtnLabel={intl.formatMessage({ id: I18nKey.BUTTON_LABEL_REMOVE })}
          additionalInfoSection={
            <Grid container spacing={0} className={styles.dialogTextBox}>
              <Grid item xs={12}>
                <TextField
                  label="Exit Remarks"
                  value={newJoineeExitRemarks}
                  size="small"
                  onChange={(e: any) => setNewJoineeExitRemarks(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          }
        />
      ) : (
        <MisDialog
          title={intl.formatMessage({ id: I18nKey.ONBOARDING_DELETE_EMPLOYEE })}
          message={intl.formatMessage({ id: I18nKey.ONBOARDING_DELETE_EMPLOYEE_DETAILS })}
          isOpen={openDialog}
          handleSuccess={handleDelete}
          handleClose={handleClose}
          actionBtnLabel={intl.formatMessage({ id: I18nKey.BUTTON_LABEL_REMOVE })}
          disableSubmitBtn={!isDeleteFormValid}
          additionalInfoSection={
            <DeleteEmployee
              formRef={deleteFormRef}
              setIsDeleteFormValid={setIsDeleteFormValid}
              currentDate={currentDate}
            />
          }
        />
      )}
    </Box>
  );
};

export default AddEmployee;
