import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import React, { useEffect } from 'react';
import useExpandableLinkStyles from './ExpandableLinkStyles';

const ExpandableLink: React.FC<any> = ({ value, handleExpanded, isExpanded }) => {
  const [items, setItems] = React.useState<any>(['']);
  const [isItems, setIsItems] = React.useState(false);

  const styles = useExpandableLinkStyles();

  const getValuesLength = (val: any[]) => {
    setItems(val);
    setIsItems(true);
  };

  useEffect(() => {
    getValuesLength(value);
  }, [value]);

  return (
    <>
      {isItems && (
        <Box>
          {items?.length > 3 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              type="button"
              component="button"
              className={styles.wrapper}
              onClick={handleExpanded}>
              {isExpanded ? 'view less' : 'view more'}
            </Link>
          )}
        </Box>
      )}
    </>
  );
};

export default ExpandableLink;
