export interface GetAppraisalParams {
  start_date: string;
  end_date: string;
}

export interface GetAppraisalDetailsParams {
  readonly appraisal_uid: string;
}

export interface AppraisalDetails {
  readonly appraisalDetails: AppraisalKRADetails[];
  readonly appraisalStatus: string;
  readonly designationName: string;
  readonly appraisalEndDate: string;
  readonly appraisalStartDate: string;
  readonly employeeFeedback: any;
  readonly employeeName: string;
  readonly employeeUid: string;
  readonly managerFeedback: any;
  readonly managerName: string;
  readonly managerUid: string;
  readonly promotionComments: string;
  readonly promotionRecommended: boolean;
  readonly appraisalOneOnOneDone: boolean;
  readonly isFormCompleted: boolean;
}

export interface AppraisalKRADetails {
  readonly appraisal_uid: string;
  readonly category_uid: string;
  readonly category?: string;
  readonly created_by?: string;
  readonly created_on?: string;
  readonly details: string;
  readonly employee_comment: string;
  readonly employee_rating: number;
  readonly is_deleted?: boolean;
  readonly manager_comment: string;
  readonly manager_rating: number;
  readonly name?: string;
  readonly uid: string;
  readonly updated_by?: string;
  readonly updated_on?: string;
  readonly weightage: number;
}

export enum AppraisalStatusList {
  DRAFT = 'Draft',
  GOAL_SET = 'Goal Set',
  GOAL_LOCKED = 'Goal Locked',
  EMPLOYEE_SUBMITTED = 'Employee Submitted',
  MANAGER_SUBMITTED = 'Manager Submitted',
  COMPLETE = 'Complete',
}

export enum AppraisalStatus {
  DRAFT = 'draft',
  GOAL_SET = 'goal_set',
  GOAL_LOCKED = 'goal_locked',
  EMPLOYEE_SUBMITTED = 'employee_submitted',
  MANAGER_SUBMITTED = 'manager_submitted',
  COMPLETE = 'closed',
}
