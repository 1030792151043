import MisTabs from '../../components/MisTabs/MisTabs';
import useResourceDetailStyles from './ResourceDetailStyles';
import ResourceFeedbackForm from './ResourceFeedbackForm';
import ResourceKRAForm from './ResourceKRAForm';

interface ProjectTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

const ResourceKRAFeedback: React.FC<any> = ({
  isManagerSection,
  isEmployeeSection,
  formState,
  feedbackFormRef,
  kraFormRef,
  handleTabChange,
  appraisalStatus,
  handleFormChange,
  setOneOnOneValue,
  isManager,
  isEmployee,
  isFormDirty,
  disableOneonOneSection,
  oneOnOne,
  setEmployeeAvgRating,
  setManagerAvgRating,
}: any) => {
  const styles = useResourceDetailStyles();

  const ResourceDetailTabs: ProjectTabs[] = [
    {
      label: 'KRA',
      /* eslint-disable react/jsx-boolean-value */
      component: (
        <ResourceKRAForm
          formState={formState[0]}
          formRef={kraFormRef}
          isManagerSection={isManagerSection}
          isEmployeeSection={isEmployeeSection}
          handleFormChange={handleFormChange}
          oneOnOne={oneOnOne}
          setEmployeeAvgRating={setEmployeeAvgRating}
          setManagerAvgRating={setManagerAvgRating}
        />
      ),
    },
    {
      label: 'Feedback',
      /* eslint-disable react/jsx-boolean-value */
      component: (
        <ResourceFeedbackForm
          formState={formState[1]}
          formRef={feedbackFormRef}
          isManagerSection={isManagerSection}
          isEmployeeSection={isEmployeeSection}
          appraisalStatus={appraisalStatus}
          handleFormChange={handleFormChange}
          setOneOnOneValue={setOneOnOneValue}
          isManager={isManager}
          isEmployee={isEmployee}
          isFormDirty={isFormDirty}
          disableOneonOneSection={disableOneonOneSection}
          oneOnOne={oneOnOne}
        />
      ),
    },
  ];

  return (
    <div className={styles.resourceTabWrapper}>
      <MisTabs tabs={ResourceDetailTabs} onTabChange={handleTabChange} />
    </div>
  );
};

export default ResourceKRAFeedback;
