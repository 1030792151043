import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useDashBoardStyles from './DashBoardStyles';
import I18nKey from '../../translations/I18nKey';
import TicketBoard from './TicketBoard';
import { UserGroupRoles } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';

const DashBoard = () => {
  const { groupNames } = useContext(GlobalContext);
  const isSysTicketAdmin =
    groupNames.includes(UserGroupRoles.SYSTEM_ADMIN) ||
    groupNames.includes(UserGroupRoles.TICKET_ADMIN);
  const styles = useDashBoardStyles();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <Box className={styles.dashBoardWrap}>
        <Box className={styles.headerWrapper}>
          <Box className={styles.header}>
            {intl.formatMessage({
              id: I18nKey.DASHBOARD,
            })}
          </Box>
          {isSysTicketAdmin && (
            <Button
              className={styles.templateBtn}
              variant="outlined"
              onClick={() => navigate('/ticket/category')}>
              {intl.formatMessage({
                id: I18nKey.VIEW_CATEGORY,
              })}
            </Button>
          )}
        </Box>
        <TicketBoard />
      </Box>
    </>
  );
};

export default DashBoard;
