import { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { Link, useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import _ from 'lodash';
import ProjectResourceRevenue from './ProjectResourceRevenue';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import RevenueClient, { PATHS as revenuePaths } from '../../api/Revenue/revenueAPIs';
import {
  GetProjectParams,
  GetRevenueParams,
  ProjectDetail,
  RevenueEmployee,
} from '../../api/Revenue/revenueTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import {
  dateFromatter,
  calculateTotalRevenue,
  getFYDateRange,
  ApiOperations,
  getCurrentMonthRange,
} from '../../utils/utils';
import { GetInvoiceStatusParams } from '../../api/Invoice/invoiceTypes';
import InvoiceClient from '../../api/Invoice/invoiceAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import useRevenueDetailStyles from './RevenueDetailStyles';
import ContractTable from '../../components/ContractTable/ContractTable';
import ProjectInfoForm from '../Project/ProjectInfoForm';
import { AccountTypesLabel } from '../../utils/accountTypes';

const currFYDateRange = getFYDateRange(dayjs());
const currDateRange = getCurrentMonthRange(dayjs());

const RevenueDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(location?.search);
  const [currentDateRange, setCurrentDateRange] = useState(
    queryParams.startDate
      ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
      : currDateRange,
  );
  const [cardInfo, setCardInfo] = useState<any>();
  const [invoiceStatus, setInvoiceStatus] = useState<any>(false);
  const [totalRevenue, setTotalRevenue] = useState<string>('0');
  const [totalPastRevenue, setTotalPastRevenue] = useState<string>('0');
  const [projectResourceRevenues, setProjectResourceRevenues] = useState<RevenueEmployee[]>([]);
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState([false, false]);
  const [fetch, setFetch] = useState(0);

  const params = useParams();
  const classes = useRevenueDetailStyles();
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const projectUid = params.id || '';
  const { checkAccess } = useContext(GlobalContext);

  const projectDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_PROJECT,
      }),
      value: cardInfo?.projectName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_CUSTOMER,
      }),
      value: cardInfo?.customerName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_RESOURCES,
      }),
      value: String(cardInfo?.resources.length || 0),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.PROJECT_DETAIL_TYPE,
      }),
      value: AccountTypesLabel[projectInfo?.accountType] || '-',
    },
    {
      label: `${intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_PAST_TOTAL_REVENUE,
      })} (${dayjs().subtract(1, 'month').format('MMMM')})`,
      value: totalPastRevenue,
    },
    {
      label: `${intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_TOTAL_REVENUE,
      })} (${dayjs().format('MMMM')})`,
      value: totalRevenue,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_START_DATE,
      }),
      value: dateFromatter(cardInfo?.startDate || ''),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_END_DATE,
      }),
      value: dateFromatter(cardInfo?.endDate || '-'),
    },
  ];
  const updateFetch = () => setFetch((num) => num + 1);

  const handleYearChange = (dateRange: any) => {
    setCurrentDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    navigate({
      search: createSearchParams({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }).toString(),
    });
  };

  const handleCurrentAccordionChange = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const callApiRevenueProjectDetail = () => {
    setIsLoading(true);
    const getRevenueProjectParams: GetRevenueParams = {
      project_uid: projectUid,
      start_date: currentDateRange.startDate,
      end_date: currentDateRange.endDate,
    };
    RevenueClient.getRevenueProjectDetail(getRevenueProjectParams)
      .then((result) => {
        const projectDetail: any = {
          projectUid: result?.projectUid,
          projectName: result?.projectName,
          customerName: result?.customerName,
          startDate: result?.startDate,
          endDate: result?.endDate,
          resources: result?.resources,
          createdOn: result?.projectCreatedOn,
          currency: result?.projectCurrency,
          accountType: result?.accountType,
        };
        setCardInfo(projectDetail);
        setProjectResourceRevenues(result?.resources as RevenueEmployee[]);
        // if (_.isEqual(currentDateRange, currFYDateRange))
        const { totalCurrRevenue, totalLastRevenue } = calculateTotalRevenue(
          result?.resources || [],
          'totalRevenue',
          projectDetail?.currency,
        );
        setTotalRevenue(totalCurrRevenue);
        setTotalPastRevenue(totalLastRevenue);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getInvoiceStatus = () => {
    setIsLoading(true);
    // Todo: Assumption is current & prev month is editable
    const getCurrInvoiceParams: GetInvoiceStatusParams = {
      date: dayjs().startOf('month').format('YYYY-MM-DD'),
    };
    const getPrevInvoiceParams: GetInvoiceStatusParams = {
      date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    };
    const getCurrMonthPromise = InvoiceClient.getInvoiceStatus(projectUid, getCurrInvoiceParams);
    const getPrevMonthPromise = InvoiceClient.getInvoiceStatus(projectUid, getPrevInvoiceParams);
    Promise.all([getCurrMonthPromise, getPrevMonthPromise])
      .then((res) => {
        const invoiceStatusObj = {
          [getCurrInvoiceParams.date]: res[0]?.status,
          [getPrevInvoiceParams.date]: res[1]?.status,
        };
        setInvoiceStatus(invoiceStatusObj);
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  useEffect(() => {
    callApiRevenueProjectDetail();
    getInvoiceStatus();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentDateRange]);

  useEffect(() => {
    setIsLoading(true);
    const getProjectParams: GetProjectParams = {
      project_id: params.id || '',
    };

    RevenueClient.getProjectDetails(getProjectParams)
      .then((result) => {
        setProjectInfo(result as ProjectDetail);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={classes.RevenueDetailsWrapper}>
        {/* Header */}
        <Box className={classes.Header}>
          <Box>
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.REVENUE_DETAIL_TITLE,
              })}
            </span>
            <Box>
              <Link
                to={`/revenue/project?startDate=${currentDateRange.startDate}&endDate=${currentDateRange.endDate}`}>
                {intl.formatMessage({
                  id: I18nKey.REVENUE_DETAIL_SUBTITLE,
                })}
                {' Project'}
              </Link>{' '}
              / {cardInfo?.projectName}
            </Box>
          </Box>
          {/* <Box className="headerContent">
            {cardInfo?.createdOn > currentDateRange.startDate ? (
              <FYSelector
                onChange={handleYearChange}
                minValue={getFYDateRange(dayjs(cardInfo?.createdOn))}
                maxValue={getFYDateRange(dayjs().add(1, 'year'))}
              />
            ) : (
              <FYSelector onChange={handleYearChange} defaultValue={currentDateRange} />
            )}
          </Box> */}
        </Box>
        <Box className={classes.UpperCardWrapper}>
          {projectDetails?.map((item) => (
            <Box className={classes.UpperCardFields} key={item.label}>
              <span className={classes.UpperCardFieldsKey}>{item.label}</span>
              <p className={classes.UpperCardFieldsValue}>{item.value}</p>
            </Box>
          ))}
        </Box>
        <Accordion
          expanded={expanded[0]}
          onChange={() => handleCurrentAccordionChange(0)}
          className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <Typography>Project Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.formContainer}>
              <ProjectInfoForm
                projectInfo={{
                  projectName: projectInfo.projectName,
                  alias: projectInfo.projectAlias,
                  poc: projectInfo?.projectPoc?.split(', '),
                  managerUid: projectInfo.managerUid,
                  accountManagerUids: projectInfo?.accountManagerUids,
                  isSez: projectInfo?.isSez,
                  currency: projectInfo?.currency,
                }}
                updateFetch={updateFetch}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box className={classes.LowerCardWrapper}>
          <ProjectResourceRevenue
            project_id={params.id || ''}
            projectResourceList={projectResourceRevenues}
            callApiRevenueProjectDetail={callApiRevenueProjectDetail}
            invoiceStatus={invoiceStatus}
            currentDateRange={currentDateRange}
            /* eslint-disable react/jsx-boolean-value */
            currency={cardInfo?.currency}
            accountType={cardInfo?.accountType}
            isEditable={checkAccess(
              revenuePaths.PUT_PROJECT_RESOURCES_REVENUE('id'),
              ApiOperations.PUT,
            )}
          />
        </Box>
        <Accordion
          expanded={expanded[1]}
          onChange={() => handleCurrentAccordionChange(1)}
          className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Contract Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.contractTableWrapper}>
              <ContractTable
                project_id={params.id || ''}
                currency={projectInfo?.currency}
                tableHeight="28vh"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <div className={classes.filler} />
      </Box>
    </>
  );
};

export default RevenueDetail;
