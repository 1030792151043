import { Backdrop, CircularProgress } from '@mui/material';
import useProgressSpinnerStyles from './progressSpinnerStyles';

const ProgressSpinner: React.FC<any> = (showSpinner: boolean) => {
  const progressSpinnerStyles = useProgressSpinnerStyles();

  return (
    <Backdrop className={progressSpinnerStyles.spinner} open={Boolean(showSpinner)}>
      <CircularProgress color="inherit" thickness={5} size={100} />
    </Backdrop>
  );
};

export default ProgressSpinner;
