export const SOW = 'SOW';
export const MSA = 'MSA';
export const NDA = 'NDA';

export const PROFESSIONAL = 'professional';
export const PROJECT = 'project';
export const PERSONAL = 'personal';
export const CERTIFICATES = 'certificates';
export const OTHERS = 'others';

export enum DOCTYPES {
  'SOW' = 'SOW',
  'MSA' = 'MSA',
  'NDA' = 'NDA',
  'COMPENSATION' = 'COMPENSATION',
  'PROMOTION' = 'PROMOTION',
  'EXPERIENCE_LETTER' = 'EXPERIENCE_LETTER',
  'ID_PROOF' = 'ID_PROOF',
  'ADDRESS_PROOF' = 'ADDRESS_PROOF',
  'MARRIAGE_CERTIFICATE' = 'MARRIAGE_CERTIFICATE',
  'EDUCATIONAL' = 'EDUCATIONAL',
  'CERTIFICATES' = 'CERTIFICATES',
  'DEFAULTS' = 'DEFAULTS',
}

export const DocTypesLabel: Record<string, string> = {
  [DOCTYPES.SOW]: 'SOW',
  [DOCTYPES.MSA]: 'MSA',
  [DOCTYPES.NDA]: 'NDA',
  [DOCTYPES.COMPENSATION]: 'Compensation',
  [DOCTYPES.PROMOTION]: 'Promotion',
  [DOCTYPES.EXPERIENCE_LETTER]: 'Experience Letter',
  [DOCTYPES.ID_PROOF]: 'ID Proof',
  [DOCTYPES.ADDRESS_PROOF]: 'Address Proof',
  [DOCTYPES.MARRIAGE_CERTIFICATE]: 'Marriage Certificate',
  [DOCTYPES.EDUCATIONAL]: 'Educational',
  [DOCTYPES.CERTIFICATES]: 'Certificates',
  [DOCTYPES.DEFAULTS]: 'Defaults',
};
