import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Box,
  CircularProgress,
  IconButton,
  Link as SimpleLink,
  Modal,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAllInterviewStyles from './AllInterviewStyles';
import SidePanel from '../../components/SidePanel/SidePanel';
import AddCandidateDetails from './AddCandidateDetails';
import InterviewClient from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getFileInfo } from './utils';

interface InterviewTopSectionInfoProps {
  setSelectedCandidate: any;
  candidateDetails: any;
  isLoading: boolean;
  isHRAccess: boolean;
  selectedTab: string;
  selectedList: string;
  selectedCandidateId: any;
  updateFetch: any;
  updateFetchCandidateList: any;
}

const InterviewTopSectionInfo: React.FC<InterviewTopSectionInfoProps> = ({
  setSelectedCandidate,
  candidateDetails,
  isLoading,
  selectedTab,
  selectedList,
  selectedCandidateId,
  isHRAccess,
  updateFetch,
  updateFetchCandidateList,
}: any) => {
  const [isUrlLoading, setUrlIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const styles = useAllInterviewStyles();
  const navigate = useNavigate();
  const handleModalOpen = () => setOpen(!open);
  const { showSnackbar } = useDisplaySnackbar();

  const handleClose = () => {
    setSelectedCandidate('');
    navigate(`/interview?selected_list=${selectedList}&selected_tab=${selectedTab}`);
  };

  const handleModalClose = () => {
    setOpen(!open);
    updateFetch();
    updateFetchCandidateList();
  };

  const handleResumeDownload = (url: string, label: string, documentName: any) => {
    const resumeDownloadParams = {
      preSignedUrl: url,
    };
    InterviewClient.downloadResume(resumeDownloadParams)
      .then((res: any) => {
        const { mimeType, fileName, fileExtension } = getFileInfo(documentName, label);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));

        if (fileExtension === 'pdf') {
          link.target = '_blank';
        } else {
          link.download = fileName;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setUrlIsLoading(false));
  };

  return isLoading ? (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Box className={`${styles.topContainer} ${styles.card}`}>
      <Box className={styles.topContainerWrapper}>
        <Box>
          <strong>{candidateDetails?.candidateName}</strong>
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          {isHRAccess && (
            <IconButton className={styles.editIcon} onClick={handleModalOpen}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box className={styles.middleContainer}>
        <Typography className={styles.middleContainerTitle}>
          <StoreOutlinedIcon className={styles.middleContainerIcons} />
          {candidateDetails?.candidateDesignation}
        </Typography>
        <Typography className={styles.middleContainerTitle}>
          <LocationOnOutlinedIcon className={styles.middleContainerIcons} />{' '}
          {candidateDetails?.addressFirstLine} {candidateDetails?.addressSecondLine}
        </Typography>
        <Typography className={styles.middleContainerTitle}>
          <DateRangeOutlinedIcon className={styles.middleContainerIcons} />
          Experience:
          <Typography className={styles.boldExp}>{candidateDetails?.yearsOfExperience}</Typography>
          <Typography className={styles.middleContainerInfo}>
            DOJ: {candidateDetails?.dateOfJoining}
          </Typography>
        </Typography>
        {isHRAccess && (
          <Typography className={styles.middleContainerTitle}>
            <PaymentOutlinedIcon className={styles.middleContainerIcons} />
            Current: {candidateDetails?.currentCtc}
            <Typography className={styles.middleContainerInfo}>
              Offered: {candidateDetails?.offeredCtc}
            </Typography>
            <Typography className={styles.middleContainerInfo}>
              Expected: {candidateDetails?.expectedCtc}
            </Typography>
          </Typography>
        )}
        <Typography className={styles.middleContainerTitle}>
          Current State : {candidateDetails?.currentState}
        </Typography>
        <Typography className={styles.middleContainerTitle}>
          {candidateDetails?.resumePresignedUrl && (
            <SimpleLink
              onClick={() => {
                handleResumeDownload(
                  candidateDetails?.resumePresignedUrl,
                  candidateDetails?.candidateName,
                  candidateDetails?.documentName,
                );
              }}>
              VIEW RESUME
            </SimpleLink>
          )}
        </Typography>
      </Box>
      <Modal open={open} onClose={handleModalClose}>
        <Box className={styles.formContainer}>
          <SidePanel header="Edit Candidate" onClose={handleModalClose}>
            <Box className={styles.sidePanelTabContent}>
              <AddCandidateDetails selectedCandidateId={selectedCandidateId} isEditMode />
            </Box>
          </SidePanel>
        </Box>
      </Modal>
    </Box>
  );
};

export default InterviewTopSectionInfo;
