import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useInterviewLevelTabs from './InterviewLevelTabsStyle';
import { InterviewStatus } from './utils';

interface ApplicationStatusProps {
  interviewFeedback: any;
}

const ApplicationStatus: React.FC<ApplicationStatusProps> = ({ interviewFeedback }) => {
  const styles = useInterviewLevelTabs();

  const formattedAttributes = interviewFeedback?.interviewFeedback?.attributes
    .map((attribute: any) => {
      const value = attribute.rating || '-';
      return `${attribute.name}: ${value}`;
    })
    .join('\n');

  const getInterviewStatusIcons = (status: string) => {
    if (status.includes('Passed')) {
      return (
        <Tooltip title="Passed">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    }
    if (status.includes('Failed')) {
      return (
        <Tooltip title="Failed">
          <CancelOutlinedIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    }
    if (status.includes('Did Not Appear')) {
      return (
        <Tooltip title="Did Not Appear">
          <ErrorOutlineIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    }
    return status;
  };

  const feedbackIcon = getInterviewStatusIcons(interviewFeedback?.interviewStatus);

  return (
    <>
      <Grid container rowSpacing={2} spacing={1} className={styles.gridWrapper}>
        <Grid item xs={3}>
          <Typography className={styles.title}>Status</Typography>
          <Typography> {feedbackIcon}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={styles.title}>Interviewer</Typography>
          <Typography>{interviewFeedback?.interviewerName}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={styles.title}>Date and Time</Typography>
          <Typography>
            {interviewFeedback?.scheduledDate} {interviewFeedback?.scheduledTime}
          </Typography>
        </Grid>
        {interviewFeedback?.finalComments && (
          <Grid item xs={12}>
            <Box className={styles.comments}>
              <Typography className={styles.title}>Comments</Typography>
              <Typography className={styles.comments}>
                {interviewFeedback?.finalComments}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className={styles.feedback}>
            <Typography className={styles.title}>Feedback</Typography>
            <Typography className={styles.feedback}>
              {interviewFeedback?.interviewFeedback?.projectComment}
            </Typography>
            {formattedAttributes &&
              formattedAttributes
                .split('\n')
                .map((attribute: any) => <Typography>{attribute}</Typography>)}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ApplicationStatus;
