import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllInterviewStyles = makeStyles((theme: Theme) => {
  return {
    interviewWrapper: {
      padding: '1rem',
    },
    allTabsWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '8px',
      width: '100%',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .MuiTab-root': {
        width: '120px !important',
      },
      '& .employee-skill': {
        padding: '2px 8px',
        margin: '1px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',
      },
      '& .employee-skill-cell': {
        flexWrap: 'wrap',
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',

      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    viewBtn: {
      float: 'right',
      margin: '0px 10px !important',
      '&.active': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: '#FFF',
      },
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        fontSize: '35px',
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    w75: {
      width: '70%',
    },
    contentWrapper: {
      display: 'flex',
      '& .MuiTab-root': {
        width: 'auto !important',
      },
    },
    card: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    detailsWrapper: {
      'flex-direction': 'column',
      width: '30%',
      margin: '0 1rem',
      borderRadius: '12px',
      height: 'fit-content',
      padding: '0.5rem',
      backgroundColor: theme.palette.appBackground?.lightest,
    },
    topContainer: {
      position: 'relative',
      height: '230px',
      backgroundColor: theme.palette.popUpBackground?.light,
      color: theme.palette.secondary.main,
      borderRadius: '12px',
      padding: '10px',

      '& strong': {
        fontSize: '18px',
        fontWeight: 'bold',
      },
      '& button': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    bottomContainer: {
      marginTop: '10px',
      position: 'relative',
      'flex-grow': 1,
    },
    interviewFormWrapper: {
      padding: '8px',

      '& .save-btn': {
        float: 'right',
      },
    },
    topContainerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editIcon: {
      marginRight: '40px !important',
      fontSize: '16px !important',
    },
    middleContainer: {
      marginTop: '10px',
    },
    middleContainerTitle: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px !important',
      fontSize: '14px !important',
      cursor: 'pointer',
    },
    middleContainerIcons: {
      marginRight: '5px !important',
      fontSize: '14px !important',
    },
    middleContainerInfo: {
      marginLeft: '30px !important',
      fontSize: '14px !important',
    },
    middleContainerInfoTwo: {
      justifyContent: 'space-around',
      display: 'flex',
      marginTop: '10px !important',
      fontSize: '14px !important',
    },
    TitleText: {
      textAlign: 'left',
      paddingTop: '5px',
      paddingLeft: '10px',
      color: theme.palette.secondary.main,
      textTransform: 'capitalize',
      paddingBottom: '10px',
    },
    bold: {
      fontSize: '14px !important',
      fontWeight: 'bold !important',
    },
    boldExp: {
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      marginLeft: '4px !important',
    },
    tableWrapper: {
      marginTop: '4px',
      '& .MuiDataGrid-toolbarContainer': {
        display: 'none !important',
      },
      '& .MuiDataGrid-root': {
        border: 'none !important',
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#EFEFEF !important',
        fontSize: '14px !important',
      },
      '& .MuiDataGrid-root .MuiDataGrid-row': {
        fontSize: '14px !important',
      },
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
    },
    content: {
      padding: '0px !important',
    },
    tabs: {
      minHeight: '40px !important',
      '& .MuiTab-root': {
        textTransform: 'capitalize',
        minHeight: '40px !important',
        padding: '0 2px !important',
        margin: '0 4px !important',
      },
      '& .MuiTab-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.lighter,
      },
    },
    sidePanelTabContent: {
      margin: '-15px !important',
    },
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    messageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    message: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '-40px',
      marginBottom: '10px',
    },
    templateBtn: {
      float: 'right',
      margin: '0px 10px !important',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    LinkWrapper: {
      fontSize: '14px',
      fontWeight: 'bold',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    AllHeader: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    currentStateWrapper: {
      marginTop: '-15px !important',
    },
    tableContainer: {
      marginTop: theme.spacing(2),
    },
    filterButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '-4px !important',
      '& svg': {
        marginRight: '5px',
      },
    },
  };
});

export default useAllInterviewStyles;
