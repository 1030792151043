import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { currentStateOptions } from '../utils';
import useAllInterviewStyles from '../AllInterviewStyles';

const CurrentStateInfo: React.FC<any> = ({ statusInfo, setCurrentState }) => {
  const styles = useAllInterviewStyles();

  const handleChange = (values: any): any => {
    setCurrentState(values);
  };

  return (
    <>
      <Box className={styles.currentStateWrapper}>
        <Formik initialValues={statusInfo} onSubmit={() => {}} enableReinitialize>
          {(formik) => {
            const { values, setFieldValue } = formik;
            return (
              <Form onChange={handleChange(values)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SimpleAutocompleteField
                      label="Current State"
                      name="currentState"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={currentStateOptions}
                      values={values}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default CurrentStateInfo;
