import React, { createContext, useEffect, useState } from 'react';
import RbacClient from '../api/RBAC/rbacApi';
import { clearUserAttributes, getUserAttributes } from '../utils/auth';
import { ApiOperations } from '../utils/utils';
import useDisplaySnackbar from '../utils/useDisplaySnackbar';

interface Global {
  readonly userPermissions: string[];
  readonly checkAccess: (module: string, operation?: ApiOperations | '') => boolean;
  readonly isFetchingPermissions: boolean;
  readonly isAuthenticated: boolean;
  readonly setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isAuthenticating: boolean;
  readonly setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>;
  readonly employeeUid: string | undefined;
  readonly groupNames: any[];
}

interface GlobalContextProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const GlobalContext = createContext<Global>({
  userPermissions: [],
  checkAccess: () => false,
  isFetchingPermissions: false,
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isAuthenticating: true,
  setIsAuthenticating: () => {},
  employeeUid: '',
  groupNames: [],
});

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isFetchingPermissions, setIsFetchingPermissions] = useState<boolean>(true);
  const [userPermissions, setUserPermissions] = useState<any>([]);
  const [employeeUid, setEmployeeUid] = useState<string>();
  const [groupNames, setGroupNames] = useState<any>([]);

  const { userName } = getUserAttributes();

  const { showSnackbar } = useDisplaySnackbar();

  const fetchUserPermissions = () => {
    setIsFetchingPermissions(true);
    RbacClient.getOperationsList()
      .then((res) => {
        setUserPermissions(res.permissions);
        setEmployeeUid(res.employee_uid);
        setGroupNames(res.groups);
      })
      .catch((err) => showSnackbar(err, 'error'))
      .finally(() => setIsFetchingPermissions(false));
  };

  useEffect(() => {
    if (isAuthenticated) fetchUserPermissions();
    else if (!isAuthenticating && !isAuthenticated) {
      setIsFetchingPermissions(false);
      setUserPermissions([]);
      clearUserAttributes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, isAuthenticated, isAuthenticating]);

  const checkAccess = (
    module: string,
    operation: ApiOperations | '' = ApiOperations.GET,
  ): boolean => {
    if (module === '') return true;
    if (userPermissions.length === 0) return false;

    const entry = userPermissions.find((item: any) => {
      const moduleRegex = new RegExp(`^${item.module.replace(/\/\*|\*/g, '.*?')}`);
      return (
        moduleRegex.test(module) &&
        (item.operations.includes('*') || item.operations.includes(operation))
      );
    });
    return Boolean(entry);
  };

  return (
    <GlobalContext.Provider
      value={{
        userPermissions,
        checkAccess,
        isFetchingPermissions,
        isAuthenticated,
        setIsAuthenticated,
        isAuthenticating,
        setIsAuthenticating,
        employeeUid,
        groupNames,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
