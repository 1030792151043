import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useInterviewSideCArdStyles = makeStyles(() => {
  return {
    CardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      borderRadius: '12px',
      color: theme.palette.secondary.main,
      background: theme.palette.background.default,
      height: '600px',
    },
    CardImageWrapper: {
      backgroundColor: theme.palette.popUpBackground?.light,
      borderRadius: '12px 12px 0px 0px',
      minHeight: '130px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',

      '& .MuiAvatar-root': {
        width: '110px',
        height: '110px',
        marginBottom: '-77px',
      },
    },
    CardTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90px',
      padding: '0 1rem',
      marginTop: '20px',
      fontSize: '14px',
    },
    MidSectionWrapper: {
      margin: '1rem 0 2rem 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',

      '& svg': {
        color: theme.palette.tertiary?.light,
        alignSelf: 'center',
      },
    },
    PersonalDetailWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '17px',
      padding: '0 1rem',
    },
    MidSectionItem: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 1rem',
      width: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& p': {
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
      },
    },
    TitleText: {
      margin: '0',
      textTransform: 'capitalize',
    },
    bold: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    CardFooterWrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'flex-end',
      overflow: 'auto',

      '& ul': {
        width: '100%',
      },
    },
    Card_arrow: {
      marginRight: '10px',
      transform: 'rotate(90deg)',
    },
    Card_arrow_inverted: {
      marginRight: '10px',
      transform: 'rotate(180deg)',
    },
    text: {
      color: theme.palette.text.midDark,
      fontSize: '12px',
      marginLeft: '3px',
    },
    labelText: {
      color: theme.palette.text.midDark,
      fontSize: '12px',
      marginLeft: '3px',
      whiteSpace: 'nowrap',
    },
    email: {
      color: theme.palette.text.light,
      fontSize: '12px',
      marginLeft: '3px',
    },
    sectionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '18px',
    },
    innerSectionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '17px',
      padding: '0 1rem',
      marginBottom: '5px',
      marginTop: '-5px',
    },
    info: {
      display: 'flex',
    },
    accessWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '33px',
    },
    access: {
      padding: '2px 8px',
      marginRight: 5,
      fontSize: 13,
      background: theme.palette.secondary.midDark,
      borderRadius: 12,
      with: '100%',
    },
    TitleLink: {
      marginTop: '4px !important',
      fontSize: '13px !important',
      textTransform: 'capitalize',
    },
    stepLevel: {
      paddingTop: '20px',
      marginBottom: '5px !important',
      '& .MuiStepIcon-root': {
        fontSize: '14px !important',
      },
      '& .MuiStepIcon-root.Mui-completed': {
        color: `${theme.palette.status?.midDark} !important`,
      },
      '& .completedStep': {
        color: `${theme.palette.status?.midDark} !important`,
      },
    },
    stepBold: {
      '& .MuiStepLabel-alternativeLabel': {
        fontSize: '12px !important',
        fontWeight: 'bold !important',
        marginTop: '2px !important',
      },
    },
  };
});
export default useInterviewSideCArdStyles;
