import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import { FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useKraTemplateDetailsStyles from './KraTemplateDetailStyles';

const ResourceKRAForm: React.FC<any> = ({
  isManagerSection,
  isEmployeeSection,
  formState,
  formRef,
  handleFormChange,
  oneOnOne,
  setEmployeeAvgRating,
  setManagerAvgRating,
}: any) => {
  const styles = useKraTemplateDetailsStyles();
  const [empRatings, setEmpRatings] = useState<any>([]);
  const [managerRatings, setManagerRatings] = useState<any>([]);

  const validationSchema = Yup.object().shape({
    kraDetails: Yup.array().of(
      Yup.object().shape({
        manager_rating: isManagerSection
          ? Yup.number().required('Rating is required').typeError('Rating is required')
          : Yup.number().nullable(),
        employee_rating: isManagerSection
          ? Yup.number().nullable()
          : Yup.number().required('Rating is required').typeError('Rating is required'),
        manager_comment: isManagerSection
          ? Yup.string().required('Comments is required').typeError('Comments is required')
          : Yup.string().nullable(),
        employee_comment: isManagerSection
          ? Yup.string().nullable()
          : Yup.string().required('Comments is required').typeError('Comments is required'),
      }),
    ),
  });

  const handleTotalRating = (arr: number[]) => {
    return arr.reduce((acc, curr) => acc + curr, 0).toFixed(1);
  };

  const handleAvgEmployeeRating = (index: number, kraDetails: any, rating: any) => {
    const employeeRating = [...empRatings];
    const { weightage } = kraDetails[index];
    const updatedRating = rating * (weightage / 100);
    employeeRating[index] = updatedRating;
    setEmpRatings(employeeRating);
    setEmployeeAvgRating(handleTotalRating(employeeRating));
  };

  const handleAvgManagerRating = (index: number, kraDetails: any, rating: any) => {
    const managerRating = [...managerRatings];
    const { weightage } = kraDetails[index];
    const updatedRating = rating * (weightage / 100);
    managerRating[index] = updatedRating;
    setManagerRatings(managerRating);
    setManagerAvgRating(handleTotalRating(managerRating));
  };

  useEffect(() => {
    if (formState.kraDetails.length) {
      const employeeRating = formState.kraDetails.map(
        (v: any) => (v.employee_rating || 0) * (v.weightage / 100),
      );
      const managerRating = formState.kraDetails.map(
        (v: any) => (v.manager_rating || 0) * (v.weightage / 100),
      );
      setEmpRatings(employeeRating);
      setManagerRatings(managerRating);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue } = formik;
          return (
            <Form onChange={handleFormChange}>
              <FieldArray
                name="kraDetails"
                render={() => {
                  return (
                    <>
                      {values?.kraDetails?.map((item: any, index: number) => (
                        <Accordion
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          defaultExpanded
                          className={styles.accordion}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                {values.kraDetails[index].category_name} {' - '}
                                {values.kraDetails[index].sub_category_name}
                                <Typography fontWeight={700}>
                                  {values.kraDetails[index].weightage}
                                  {'%'}
                                </Typography>
                              </Grid>
                              <Grid item xs={10}>
                                <SimpleInputField
                                  name={`kraDetails[${index}].details`}
                                  className={styles.detailsWrapper}
                                  type="string"
                                  size="small"
                                  disabled
                                  multiline
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box>
                              <Grid container spacing={1}>
                                <Grid item xs={2}>
                                  <Typography className={styles.heading}>
                                    Employee Section
                                  </Typography>
                                  <Stack spacing={2}>
                                    <Rating
                                      name={`kraDetails[${index}].employee_rating`}
                                      precision={0.5}
                                      value={values.kraDetails[index].employee_rating}
                                      onChange={(event, value) => {
                                        setFieldValue(
                                          `kraDetails[${index}].employee_rating`,
                                          value,
                                        );
                                        handleAvgEmployeeRating(index, values.kraDetails, value);
                                      }}
                                      disabled={!isEmployeeSection}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={10}>
                                  <SimpleInputField
                                    name={`kraDetails[${index}].employee_comment`}
                                    type="string"
                                    size="small"
                                    minRows={2}
                                    disabled={!isEmployeeSection}
                                    multiline
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography className={styles.heading}>
                                    Manager Section
                                  </Typography>
                                  <Stack spacing={2}>
                                    <Rating
                                      name={`kraDetails[${index}].manager_rating`}
                                      precision={0.5}
                                      value={values.kraDetails[index].manager_rating}
                                      onChange={(event, value) => {
                                        setFieldValue(`kraDetails[${index}].manager_rating`, value);
                                        handleAvgManagerRating(index, values.kraDetails, value);
                                      }}
                                      disabled={!isManagerSection || oneOnOne}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={10}>
                                  <SimpleInputField
                                    name={`kraDetails[${index}].manager_comment`}
                                    type="string"
                                    size="small"
                                    values={values}
                                    minRows={2}
                                    disabled={!isManagerSection || oneOnOne}
                                    multiline
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ResourceKRAForm;
