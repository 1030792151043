import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { AddCircle } from '@mui/icons-material';
import _ from 'lodash';
import {
  OpportunityParams,
  GetCustomerAccountsParams,
} from '../../api/Opportunity/opportunityTypes';
import CustomerRelationClient, {
  PATHS as opportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { getFullName } from './entitiesFormConfig';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { currencies } from '../../utils/currency';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import I18nKey from '../../translations/I18nKey';
import AddAccountForm from './AddAccountForm';
import AddContactForm from './AddContactForm';
import AddCustomerForm from './AddCustomerForm';
import SidePanel from '../../components/SidePanel/SidePanel';
import useAddEntitiesStyles from './AddEntitiesStyles';
import { ApiOperations, SezAllowedCurrencies } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';

interface AddOpportunityFormProps {
  readonly handleModalClose: Function;
  readonly onCreateOpportunity?: Function;
  readonly opportunityDetail?: any;
  readonly projectId?: any;
}

const validationSchema = Yup.object().shape({
  customer: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .required('Customer name is required'),
  account: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .required('Account name is required'),
  currency: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .required('Currency is required'),
  name: Yup.string().required('Opportunity name is required'),
  poc: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .required('POC is required')
    .min(1),
  isSez: Yup.boolean().nullable(),
});

const Addopportunity: React.FC<AddOpportunityFormProps> = ({
  opportunityDetail,
  handleModalClose,
  onCreateOpportunity,
  projectId,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [showSez, setShowSez] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAddEntitiesStyles();
  const intl = useIntl();
  const { checkAccess } = useContext(GlobalContext);

  const handleSubmit = (params: any) => {
    const { account, name, alias, currency, poc, customer, isSez } = params;
    let addCRMOpportunityParams: OpportunityParams = {
      customerUid: customer.value,
      accountUid: account.value,
      name,
      currency: currency.value,
      poc: poc.map((p: any) => p.value),
      isSez,
    };
    if (!_.isEmpty(alias)) addCRMOpportunityParams = { ...addCRMOpportunityParams, alias };
    setLoading(true);

    const result = projectId
      ? CustomerRelationClient.updateCRMOpportunity(projectId, addCRMOpportunityParams)
      : CustomerRelationClient.addCRMOpportunity(addCRMOpportunityParams);

    result
      .then((res) => {
        showSnackbar(res, 'success');
        onCreateOpportunity?.();
        handleModalClose();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };
  const formik = useFormik({
    initialValues: {
      customer: opportunityDetail
        ? { label: opportunityDetail.customerName, value: opportunityDetail.customerId }
        : null,
      account: opportunityDetail
        ? { label: opportunityDetail.accountName, value: opportunityDetail.accountId }
        : null,
      name: opportunityDetail ? opportunityDetail.name : '',
      alias: opportunityDetail ? opportunityDetail.alias : '',
      currency: opportunityDetail
        ? currencies.find((c) => c.value === opportunityDetail?.currency)
        : currencies.find((c) => c.value === 'USD'),
      poc: opportunityDetail
        ? opportunityDetail.poc.map((option: any) => ({
            label: getFullName(option),
            value: option.uid,
          }))
        : [],
      isSez: opportunityDetail ? opportunityDetail.isSez : false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  const selectedCustomer: any = formik.values.customer;

  const getAllContacts = () => {
    setLoading(true);
    CustomerRelationClient.getCrmContactList()
      .then((data) => {
        setContacts(
          data.map((contact: any) => ({
            label: `${getFullName(contact)} (${contact.email})`,
            value: contact.uid,
          })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  const getAllCustomerList = () => {
    setLoading(true);

    CustomerRelationClient.getAllCustomerList()
      .then((data) => {
        setCustomers(
          data.map((customer: any) => ({ label: customer.name, value: customer.uid, ...customer })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  const getCustomerAccounts = () => {
    if (selectedCustomer?.value) {
      setLoading(true);
      const getCustomerAccountsParams: GetCustomerAccountsParams = {
        customer_uid: selectedCustomer.value,
      };
      CustomerRelationClient.getCustomerAccounts(getCustomerAccountsParams)
        .then((data) => {
          setAccounts(
            data.map((acc: any) => ({
              label: acc.name,
              value: acc.uid,
              ...acc,
            })),
          );
        })
        .catch((e) => {
          showSnackbar(e, 'error');
          handleModalClose();
        })
        .finally(() => setLoading(false));
    } else {
      setAccounts([]);
    }
  };

  const handleEntiyModalClose = (fetch: boolean = true) => {
    if (fetch) {
      if (selectedOption === 'customer') {
        getAllCustomerList();
      }
      if (selectedOption === 'contact') {
        getAllContacts();
      }
      if (selectedOption === 'account') {
        getCustomerAccounts();
      }
    }
    setSelectedOption(null);
  };

  const handleCurrencyChange = (event: any, values: any) => {
    if (SezAllowedCurrencies.includes(values?.value)) {
      setShowSez(true);
    } else {
      setShowSez(false);
    }
  };

  useEffect(() => {
    getAllCustomerList();
    getAllContacts();
    if (SezAllowedCurrencies.includes(opportunityDetail?.currency)) {
      setShowSez(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    getCustomerAccounts();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectedCustomer]);

  const onCreateCustomer = (customer: any) => {
    formik.setFieldValue('customer', { label: customer.name, value: customer.uid });
  };

  const onCreateAccount = (account: any) => {
    formik.setFieldValue('account', { label: account.name, value: account.uid });
  };

  const onCreateContact = ({ uid, first_name, middle_name, last_name }: any) => {
    const { poc } = formik.values;
    formik.setFieldValue('poc', [
      ...poc,
      ...[
        {
          label: getFullName({
            firstName: first_name,
            middleName: middle_name,
            lastName: last_name,
          }),
          value: uid,
        },
      ],
    ]);
  };

  const optionsObj: any = {
    account: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_ACCOUNT,
      }),
      icon: <AccountCircleOutlinedIcon />,
      component: (
        <AddAccountForm
          handleModalClose={handleEntiyModalClose}
          onCreateAccount={onCreateAccount}
          selectedCustomer={selectedCustomer}
        />
      ),
    },
    customer: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
      }),
      icon: <PersonAddAltIcon />,
      component: (
        <AddCustomerForm
          handleModalClose={handleEntiyModalClose}
          onCreateCustomer={onCreateCustomer}
        />
      ),
    },
    contact: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CONTACT,
      }),
      icon: <ContactsOutlinedIcon />,
      component: (
        <AddContactForm
          handleModalClose={handleEntiyModalClose}
          onCreateContact={onCreateContact}
        />
      ),
    },
  };

  const handAddEntity = (option: string) => {
    setSelectedOption(option);
  };

  const handleCustomerChange = (name: string, option: any) => {
    formik.setFieldValue('account', null);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="customer"
              key="customer"
              label="Customer Name"
              variant="standard"
              size="small"
              handleChange={handleCustomerChange}
              setFieldValue={formik.setFieldValue}
              options={customers}
              values={formik.values}
              required
            />
            {checkAccess(opportunityPaths.CRM_CUSTOMER, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                onClick={() => handAddEntity('customer')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
                })}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.gridItem}>
            <SimpleAutocompleteField
              name="account"
              key="account"
              label="Account Name"
              variant="standard"
              size="small"
              setFieldValue={formik.setFieldValue}
              options={accounts}
              values={formik.values}
              disabled={!selectedCustomer?.value}
              required
            />
            {checkAccess(opportunityPaths.CRM_ACCOUNT, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                disabled={!selectedCustomer?.value}
                onClick={() => handAddEntity('account')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_ACCOUNT,
                })}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.gridItem}>
            <TextField
              fullWidth
              id="firstName"
              name="name"
              label="Opportunity Name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="alias"
              name="alias"
              label="Alias Name"
              variant="standard"
              value={formik.values.alias}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="currency"
              key="currency"
              label="Currency"
              variant="standard"
              size="small"
              setFieldValue={formik.setFieldValue}
              options={currencies}
              values={formik.values}
              handleChange={handleCurrencyChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="poc"
              key="poc"
              label="POC"
              variant="standard"
              size="small"
              setFieldValue={formik.setFieldValue}
              options={contacts}
              values={formik.values}
              required
              multiple
            />
            {checkAccess(opportunityPaths.CRM_CONTACT, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                onClick={() => handAddEntity('contact')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_CONTACT,
                })}
              </Button>
            )}
          </Grid>
          {showSez && (
            <Grid item xs={12}>
              <SimpleCheckboxField
                name="isSez"
                checked={formik.values.isSez}
                inputProps={{ style: { fontSize: 13 } }}
                setFieldValue={formik.setFieldValue}
                label="Is SEZ?"
                size="small"
              />
            </Grid>
          )}
        </Grid>
        {(checkAccess(opportunityPaths.CRM_OPPORTUNITY, ApiOperations.PUT) ||
          checkAccess(opportunityPaths.CRM_OPPORTUNITY, ApiOperations.POST)) && (
          <Button disabled={!formik.dirty || !formik.isValid} variant="contained" type="submit">
            {intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SAVE,
            })}
          </Button>
        )}
      </form>
      <Modal open={selectedOption !== null} onClose={() => handleEntiyModalClose(false)}>
        <Box>
          <SidePanel
            header={optionsObj?.[selectedOption!]?.title}
            onClose={() => handleEntiyModalClose(false)}>
            <div className={styles.formContainer}>{optionsObj?.[selectedOption!]?.component}</div>
          </SidePanel>
        </Box>
      </Modal>
    </>
  );
};

Addopportunity.defaultProps = {
  onCreateOpportunity: () => {},
  opportunityDetail: null,
  projectId: null,
};

export default Addopportunity;
