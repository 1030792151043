import { useIntl } from 'react-intl';
import { Box, Grid, Typography } from '@mui/material';
import Plot from 'react-plotly.js';
import useOverviewStyles from './OverviewStyles';
import I18nKey from '../../translations/I18nKey';
import useWorkspaceStyles from './workspaceStyles';
import ProjectChart from './components/ProjectChart';
import ContractChart from './components/ContractChart';
import RevenueChart from './components/RevenueChart';
import AppraisalChart from './components/AppraisalChart';
import ResourceChart from './components/ResourceChart';

const Overview = () => {
  const styles = useOverviewStyles();

  const intl = useIntl();
  const classes = useWorkspaceStyles();
  const charts = [
    // {
    //   label: intl.formatMessage({
    //     id: I18nKey.PROJECT_DETAIL_TITLE,
    //   }),
    //   component: <ProjectChart />,
    // },
    {
      label: intl.formatMessage({
        id: I18nKey.REVENUE_DETAIL_TITLE,
      }),
      component: <RevenueChart />,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_TITLE,
      }),
      component: <AppraisalChart />,
    },
    // {
    //   label: intl.formatMessage({
    //     id: I18nKey.CONTRACT_DETAIL_TITLE,
    //   }),
    //   component: <ContractChart />,
    // },
    {
      label: intl.formatMessage({
        id: I18nKey.RESOURCE_TITLE,
      }),
      component: <ResourceChart />,
    },
  ];

  return (
    <>
      <Typography className={styles.header}>
        {intl.formatMessage({ id: I18nKey.OVERVIEW })}
      </Typography>
      <Box className={styles.overviewWrapper}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            {charts.map(({ label, component }) => (
              <Grid key={label} item lg={4} md={6} sm={12} xs={12}>
                <Box className={styles.itemWrapper}>
                  <Typography className={styles.headingLine}>{label}</Typography>
                  {component}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
