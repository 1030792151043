import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Datatable from '../../components/Datatable/Datatable';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { employeeId, getColumns } from './TimesheetListConfig';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import AppraisalClient from '../../api/Appraisal/appraisalAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface TimeSheetListProps {
  readonly setTimesheetList: any;
}

const TimesheetList: React.FC<TimeSheetListProps> = ({ setTimesheetList }: any) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { employeeUid } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();
  const params = useParams();

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, [], [], employeeId);
  };

  const getReportees = () => {
    setIsLoading(true);
    AppraisalClient.getAllReportees(employeeUid)
      .then((response: any) => {
        const rows = getTableRows(response);
        setTableRows(rows);
        setTimesheetList(response);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getReportees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      {tableRows.length ? (
        <Datatable
          columns={getColumns()}
          rows={tableRows}
          /* eslint-disable react/jsx-boolean-value */
          columnVisibility={true}
          showExportButton
        />
      ) : (
        <Typography textAlign="center" padding={2} fontWeight="bold" color={'#173F74'}>
          No reportees found
        </Typography>
      )}
    </>
  );
};

export default TimesheetList;
