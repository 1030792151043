import { useContext, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

import { generateRows } from '../../components/Datatable/datatableUtils';
import InterviewClient, { PATHS as CandidatePaths } from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useAllInterviewStyles from './AllInterviewStyles';
import MisDialog from '../../components/MisDialog/MisDialog';
import {
  ApiOperations,
  UserGroupRoles,
  hasSuperUserAccess,
  OfferedCandidatesStatus,
} from '../../utils/utils';
import {
  candidateUid,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
} from './OfferedCandidateListTableConfig';
import { GlobalContext } from '../../contexts/GlobalContext';
import CurrentStateInfo from './Forms/CurrentStateInfo';
import OnboardingClient from '../../api/OnBoarding/onboardingAPIs';

const OfferedCandidateList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendingMail, setIsSendingMail] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [candidateId, setCandidateId] = useState('');
  const [fetchCandidateList, setFetchCandidateList] = useState<number>(0);
  const { groupNames } = useContext(GlobalContext);
  const [statusInfo, setStatusInfo] = useState<any>({
    currentState: null,
  });
  const [currentState, setCurrentState] = useState<any>(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});

  const [isHRAccess, setIsHRAccess] = useState<boolean>(
    hasSuperUserAccess(groupNames) || groupNames.includes(UserGroupRoles.HR),
  );

  const updateFetchCandidateList = () => setFetchCandidateList((fetchNum) => fetchNum + 1);

  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAllInterviewStyles();

  const handleResumeDownload = (url: string, label: string, isPreview: boolean) => {
    const resumeDownloadParams = {
      preSignedUrl: url,
    };
    InterviewClient.downloadResume(resumeDownloadParams)
      .then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        if (isPreview) {
          link.target = '_blank';
        } else {
          link.download = label;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const rowActions = [
    {
      label: 'Edit',
      logo: <EditIcon />,
      callback: (params: any) => {
        setOpenDialogStatus(true);
        setCandidateId(params.id);
      },
      path: CandidatePaths.PUT_CANDIDATE_DETAIL,
      operation: ApiOperations.PUT,
    },
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteCandidateId(params.id);
      },
      path: CandidatePaths.ADD_CANDIDATE,
      operation: ApiOperations.DELETE,
    },
    {
      label: 'Mail',
      logo: <MailIcon />,
      callback: (params: any) => {
        setFormValues(params);
        setOpenMailDialog(true);
      },
    },
  ];

  const filteredRowActions = isHRAccess ? rowActions : [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel(
      { showColumns },
      isHRAccess,
      handleResumeDownload,
      filteredRowActions,
    ),
  );

  const [tableColumns, setTableColumns] = useState<any[]>(
    getColumns(isHRAccess, handleResumeDownload, filteredRowActions),
  );

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], candidateUid);
    return rows;
  };

  const generateDatatableInputs = () => {
    setIsLoading(true);
    const offeredCandidateParams = {
      interview_phase_completed: true,
    };
    InterviewClient.getOfferedCandidateList(offeredCandidateParams)
      .then((response: any) => {
        const rows = getTableRows(response);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    generateDatatableInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCandidateList]);

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteCandidateId) {
      setIsLoading(true);
      InterviewClient.deleteCandidate(deleteCandidateId)
        .then((result) => {
          updateFetchCandidateList();
          showSnackbar(result, 'success');
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteCandidateId('');
  };

  const handleSubmit = () => {
    setOpenDialogStatus(false);
    const values = {
      currentState: currentState?.currentState?.label,
    };
    if (candidateId) {
      setIsDetailsLoading(true);
      const candidateData = values;
      const formData = new FormData();
      formData.append('candidate', JSON.stringify(candidateData));
      InterviewClient.updateCandidateDetails(candidateId, formData)
        .then((res) => {
          updateFetchCandidateList();
          showSnackbar(res, 'success');
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsDetailsLoading(false));
    }

    setCurrentState(null);
  };

  const handlePreJoiningSubmit = () => {
    if (formValues.id) {
      setOpenMailDialog(false);
      setIsLoading(true);
      OnboardingClient.getPresignedURLById(formValues.id)
        .then((res: any) => {
          showSnackbar(res, 'success');
        })
        .catch((e: any) => {
          showSnackbar(e, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogStatus(false);
    setOpenMailDialog(false);
    setDeleteCandidateId('');
    setCandidateId('');
    setCurrentState(null);
  };

  return (
    <>
      <Box className={styles.interviewWrapper}>
        <Box className={styles.AllHeader}>
          <span>
            {intl.formatMessage({
              id: I18nKey.OFFERED_CANDIDATE,
            })}
          </span>
          <Box className={styles.LinkWrapper}>
            <Link to="/interview">
              {intl.formatMessage({
                id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.OFFERED_CANDIDATE,
            })}
          </Box>
        </Box>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        <>
          <Datatable
            rows={tableRows}
            columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
            columnVisibility={true}
            tableHeight="70vh"
            initialSort={initialSort as any}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            showExportButton
            exportedFileName={`Candidate_List_${dayjs().format('DD_MMMM')}`}
          />
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
            })}
            message="Are you sure you want to delete this Candidate?"
            isOpen={openDialog}
            handleSuccess={handleDelete}
            handleClose={handleClose}
            actionBtnLabel="Delete"
          />
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
            })}
            message="Are you sure you want to update the status? If yes, please select the state..."
            isOpen={openDialogStatus}
            handleSuccess={handleSubmit}
            handleClose={handleClose}
            actionBtnLabel="Submit"
            additionalInfoSection={
              <CurrentStateInfo statusInfo={statusInfo} setCurrentState={setCurrentState} />
            }
          />
          <MisDialog
            title="Pre-Joining Formalities"
            message={`Are you sure you want to send the mail to ${formValues.row?.name}?`}
            isOpen={openMailDialog}
            handleSuccess={handlePreJoiningSubmit}
            handleClose={handleClose}
            actionBtnLabel={'Send'}
          />
        </>
      </Box>
    </>
  );
};

export default OfferedCandidateList;
