import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useRevenueDetailStyles = makeStyles((theme: Theme) => {
  return {
    RevenueDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .highlight-current-month': {
        color: theme.palette.text.midDark,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '1.1rem',
      },
      '& .disable-past-month': {
        color: theme.palette.text.disabled,
      },
    },
    Header: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',

      '& .headerTitle': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    accordion: {
      backgroundColor: `${theme.palette.appBackground?.lightest} !important`,
      marginTop: '1rem',
      borderRadius: '8px !important',

      '& .MuiAccordionSummary-content': {
        color: theme.palette.text.midDark,
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
      },
      '& .makeStyles-header-18': {
        display: 'none',
      },
    },
    UpperCardWrapper: {
      background: theme.palette.popUpBackground?.light,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '10px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    UpperCardFields: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: '5px 0 0 0',
      },
    },
    UpperCardFieldsKey: { fontSize: '12px', textAlign: 'center' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1rem',
    },
    filler: {
      height: 60,
    },
    formContainer: {
      '& .upload-button': {
        margin: '1rem 0',
        float: 'right',
      },
    },
    contractTableWrapper: {
      '& .contract-table-header': {
        display: 'none',
      },
    },
  };
});

export default useRevenueDetailStyles;
