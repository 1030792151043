import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import logo from '../../assets/icons/logo.png';
import { getUserAttributes } from '../../utils/auth';
import useHeaderStyles from './HeaderStyles';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';

const Header: React.FC = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    pages: ['Home', 'Workspace'],
    profileImage: 'logo.png',
  });
  const [isLogoutInProgress, setIsLogoutInProgress] = useState(false);
  const classes = useHeaderStyles();
  const { setIsAuthenticated } = useContext(GlobalContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchedInfo = getUserAttributes();
    const newUserInfo = { ...userInfo };
    newUserInfo.firstName = fetchedInfo?.attributes?.given_name;
    newUserInfo.profileImage = fetchedInfo?.attributes?.picture;
    setUserInfo(newUserInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLogoutInProgress(true);
    Auth.signOut().finally(() => {
      setIsLogoutInProgress(false);
      setIsAuthenticated(false);
    });
  };

  return (
    <AppBar sx={{ bgcolor: 'white', minHeight: '70px' }} component="nav">
      <Toolbar disableGutters>
        <Grid container spacing={4}>
          <Grid item md={6} lg={6} xs={6}>
            <Box className={classes.logoArea}>
              <Typography className={classes.text} fontSize={20}>
                Me@
              </Typography>
              <Box component="img" className={classes.image} src={logo} alt="Tech Prescient" />
            </Box>
          </Grid>
          <Grid display="flex" item md={6} lg={6} xs={6}>
            <Grid container justifyContent="flex-end">
              <Box sx={{ display: { xs: 'none', md: 'flex' }, align: 'right' }}>
                {userInfo.pages.map((page: string) => (
                  <Link key={page} to={`/${page.toLowerCase()}`} className={classes.link}>
                    <Button
                      sx={{
                        textTransform: 'none',
                        margin: 'auto',
                        marginRight: '40px',
                        color: 'black',
                        display: 'block',
                        fontSize: '16px',
                        marginTop: '10px',
                      }}>
                      {page}
                    </Button>
                  </Link>
                ))}
                <IconButton
                  size="large"
                  sx={{
                    marginRight: '19px',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#D9D9D9',
                    marginTop: '11px',
                  }}>
                  <NotificationsOutlinedIcon className={classes.notificationIcon} />
                </IconButton>
                <Button
                  size="large"
                  sx={{ marginRight: '24px' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
                  <Avatar alt={userInfo?.firstName.toUpperCase()} src={userInfo.profileImage} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem>
                    <Link to="/profile" className={classes.link} onClick={handleClose}>
                      Profile
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {isLogoutInProgress && <ProgressSpinner showSpinner={isLogoutInProgress} />}
    </AppBar>
  );
};

export default Header;
