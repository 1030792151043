import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useWorkspaceStyles = makeStyles((theme: Theme) => {
  return {
    itemWrapper: {
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    routeItem: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderRadius: 12,
      minHeight: 130,
      border: '1px solid #EBEBEB',
    },
    ModuleIcon: {
      width: '60px',
      height: '60px',
    },
    header: {
      marginBottom: '20px !important',
      fontSize: '20px !important',
    },
  };
});

export default useWorkspaceStyles;
