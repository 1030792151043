import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCRMProjectDetailsStyles = makeStyles((theme: Theme) => {
  return {
    projectDetailsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      '& .resource-skill': {
        padding: '2px 8px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',
      },
      '& .resource-skill-cell': {
        flexWrap: 'wrap',
      },
      '& .totalRow': {
        fontWeight: '600',
      },
      '& .highlight-current-month': {
        color: theme.palette.text.midDark,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& .disable-past-month': {
        color: theme.palette.text.disabled,
      },
    },
    Header: {
      textTransform: 'capitalize',
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: '600',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    UpperCardContent: {
      color: theme.palette.text.midDark,
      display: 'flex',
      padding: '1rem',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    UpperCardHeader: {
      padding: '1rem',
    },
    UpperCardFieldsKey: { fontSize: '12px' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '0.5rem',
      position: 'relative',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      paddingBottom: '3.6rem',
    },
    MiddleCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      marginTop: '0.5rem',
      color: theme.palette.text.midDark,
      borderRadius: '8px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    MiddleCardHeader: {
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    FormWrapper: {
      width: '100%',
      position: 'relative',
      '& form > button': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    formContainer: {
      marginTop: '-35px !important',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '86px',
    },
  };
});

export default useCRMProjectDetailsStyles;
