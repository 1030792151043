import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useIntl } from 'react-intl';
import useAllLinkedInStyles from './AllLinkedInStyles';
import MisPopover from '../../components/MisPopover/MisPopover';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { operatorOptions, columnLinked } from './utils';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import I18nKey from '../../translations/I18nKey';

const LinkedInDataFilter = ({
  handleSubmit,
  initialValues,
  setInitialValues,
  updateFetchLinkedInList,
  setFilteredData,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const intl = useIntl();

  const styles = useAllLinkedInStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClearAllFilter = () => {
    setInitialValues({
      filters: [{ columnName: '', operatorName: '', values: [''] }],
    });
    setFilteredData(null);
    updateFetchLinkedInList();
  };

  return (
    <Box>
      <Button onClick={handleClick} className={styles.buttonWrapper}>
        <FilterListIcon color="primary" />
        {intl.formatMessage({
          id: I18nKey.LINKED_IN_FILTER,
        })}
      </Button>
      <MisPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Tooltip title={'Close'}>
          <CloseIcon onClick={handleClose} className={styles.closeIcon} />
        </Tooltip>
        <Box className={styles.linkedlnfilterFormWrapper}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {(formik) => {
              const { values, setFieldValue } = formik;
              return (
                <Form>
                  <FieldArray
                    name="filters"
                    render={({ push, remove }) => (
                      <>
                        <Box>
                          {formik.values.filters.map((filter: any, index: any) => (
                            <Grid container rowSpacing={2} spacing={1.5}>
                              <Grid item xs={0.5} className={styles.removeIconContainer}>
                                {index > 0 && (
                                  <>
                                    <Tooltip
                                      title={
                                        values.filters[index - 1].columnName === filter.columnName
                                          ? 'OR'
                                          : 'And'
                                      }>
                                      <IconButton className={styles.AndIcon}>
                                        {values.filters[index - 1].columnName === filter.columnName
                                          ? '||'
                                          : '&&'}
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={0.9} className={styles.removeIconContainer}>
                                {index > 0 && (
                                  <>
                                    <Tooltip title="Remove Filter">
                                      <IconButton onClick={() => remove(index)}>
                                        <ClearIcon className={styles.removeIcon} />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={3.3}>
                                <SimpleAutocompleteField
                                  name={`filters[${index}].columnName`}
                                  placeholder="Select column"
                                  size="small"
                                  variant="standard"
                                  setFieldValue={setFieldValue}
                                  options={columnLinked}
                                  value={values.filters[index].columnName}
                                  values={values}
                                  disableClearable
                                />
                              </Grid>
                              <Grid item xs={2.2}>
                                <SimpleAutocompleteField
                                  name={`filters[${index}].operatorName`}
                                  placeholder="Select operator"
                                  size="small"
                                  variant="standard"
                                  setFieldValue={setFieldValue}
                                  options={operatorOptions}
                                  value={values.filters[index].operatorName}
                                  values={values}
                                  disableClearable
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <FieldArray
                                  name={`filters[${index}].values`}
                                  render={({ push: pushValue, remove: removeValue }) => (
                                    <>
                                      {filter.values.map((value: any, valueIndex: any) => (
                                        <Grid container>
                                          <Grid item xs={8}>
                                            <SimpleInputField
                                              name={`filters[${index}].values[${valueIndex}]`}
                                              size="small"
                                              placeholder={`Enter value ${valueIndex + 1}`}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </Grid>
                                          <Grid item xs={4} className={styles.iconsContainer}>
                                            <Grid container>
                                              <Grid item xs={4}>
                                                {valueIndex > 0 && (
                                                  <Tooltip title="Remove Value">
                                                    <IconButton
                                                      onClick={() => removeValue(valueIndex)}>
                                                      <RemoveIcon className={styles.removeIcon} />
                                                    </IconButton>
                                                  </Tooltip>
                                                )}
                                              </Grid>
                                              <Grid item xs={4}>
                                                {valueIndex === filter.values.length - 1 && (
                                                  <Tooltip title="Add Value">
                                                    <IconButton onClick={() => pushValue('')}>
                                                      <AddIcon className={styles.addIcon} />
                                                    </IconButton>
                                                  </Tooltip>
                                                )}
                                              </Grid>
                                              <Grid item xs={4}>
                                                {valueIndex < filter.values.length - 1 && (
                                                  <IconButton className={styles.OrIcon}>
                                                    OR
                                                  </IconButton>
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                        <Box className={styles.bottomBox}>
                          <Button
                            onClick={() =>
                              push({ columnName: '', operatorName: '', values: [''] })
                            }>
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_ADDFILTER,
                            })}
                          </Button>
                          <Button onClick={handleClearAllFilter}>
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_CLEAR,
                            })}
                          </Button>
                          <Button type="submit" variant="contained">
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_SEARCH,
                            })}
                          </Button>
                        </Box>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </MisPopover>
    </Box>
  );
};

export default LinkedInDataFilter;
