import React, { useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import { columns, initialSort } from './AuditLogsListTableConfig';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface AuditLogsListProps {
  readonly tableRows: any[];
}

const AuditLogsList: React.FC<AuditLogsListProps> = ({ tableRows }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Datatable rows={tableRows} columns={columns} />
    </>
  );
};
export default AuditLogsList;
