import { Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import ProjectClient, { PATHS as projectPaths } from '../../api/Project/projectAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { ApiOperations, SezAllowedCurrencies } from '../../utils/utils';
import { getFullName } from '../Opportunity/entitiesFormConfig';
import useProjectDetailsStyles from './ProjectDetailStyles';

interface ProjectInfoFormProps {
  // eslint-disable-next-line react/require-default-props
  readonly projectInfo: any;
  readonly updateFetch: () => void;
}

const ProjectInfoForm: React.FC<ProjectInfoFormProps> = ({ projectInfo, updateFetch }) => {
  const [fields, setFields] = useState({
    projectName: '',
    alias: '',
    poc: [],
    managerUid: '',
    accountManagerUids: [],
    isSez: false,
    isInternal: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [contacts, setContacts] = useState<any[]>([]);

  const projectDetailStyles = useProjectDetailsStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);
  const intl = useIntl();
  const params = useParams();

  const getInitialValues = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { projectName, alias, poc, managerUid, accountManagerUids, isSez, isInternal } =
      projectInfo;
    const initVal: any = {
      projectName: projectName || '',
      alias: alias || '',
      poc: contacts.filter((con) => poc?.find((el: string) => el === con.value) !== undefined),
      manager: employees?.find((emp: { label: string; value: string }) => emp.value === managerUid),
      accountManagerUids: employees?.filter(
        (ac: any) =>
          accountManagerUids?.find((emp: { label: string; value: string }) => ac.value === emp) !==
          undefined,
      ),
      isSez: isSez || false,
      isInternal: isInternal || false,
    };
    setFields(initVal);
  };

  const getAllEmployee = () => {
    setIsLoading(true);
    Promise.all([
      CustomerRelationClient.getEmployeeList(),
      CustomerRelationClient.getCrmContactList(),
    ])
      .then((res) => {
        setEmployees(
          res[0].map((emp: any) => ({
            label: `${getFullName(emp)} (${emp.email})`,
            value: emp.uid,
          })),
        );
        setContacts(
          res[1].map((contact: any) => ({
            label: `${getFullName(contact)} (${contact.email})`,
            value: contact.uid,
          })),
        );
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getAllEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getInitialValues(), [projectInfo, employees, contacts]);

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required'),
    alias: Yup.string().required('Alias is required'),
    poc: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .required('POC is required')
      .min(1),
    manager: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .required('Project Manager is required'),
    accountManagerUids: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .required('Account manager is required')
      .min(1),
    isSez: Yup.boolean().nullable(),
    isInternal: Yup.boolean().nullable(),
  });

  const handleSubmit = (values: any) => {
    const { alias, manager, poc, projectName, accountManagerUids, isSez, isInternal } = values;

    const updateProjectParams = {
      project: {
        alias,
        managerUid: manager.value,
        name: projectName,
        poc: poc.map((el: any) => el.value),
        accountManagerUids: accountManagerUids.map((mg: any) => mg.value),
        isSez,
        isInternal,
      },
      project_finance: {},
    };

    setIsLoading(true);
    ProjectClient.updateProjectInfo(String(params.id), updateProjectParams)
      .then((res) => {
        showSnackbar(res, 'success');
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={fields}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize>
          {(formik) => {
            const { values, setFieldValue, dirty, isValid } = formik;

            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SimpleInputField
                      className={projectDetailStyles.formField}
                      name="projectName"
                      type="string"
                      label="Project Name"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleInputField
                      className={projectDetailStyles.formField}
                      name="alias"
                      type="string"
                      label="Alias"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleAutocompleteField
                      name="poc"
                      key="poc"
                      label="Project POC"
                      className={projectDetailStyles.formField}
                      setFieldValue={formik.setFieldValue}
                      options={contacts}
                      values={values}
                      required
                      multiple
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleAutocompleteField
                      name="manager"
                      label="Project Manager"
                      setFieldValue={setFieldValue}
                      options={employees}
                      values={values}
                      className={`${projectDetailStyles.formField} ${projectDetailStyles.bigFormField}`}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleAutocompleteField
                      name="accountManagerUids"
                      label="Account Manager(s)"
                      className={`${projectDetailStyles.formField} ${projectDetailStyles.bigFormField}`}
                      setFieldValue={setFieldValue}
                      options={employees}
                      values={values}
                      required
                      multiple
                    />
                  </Grid>
                  {SezAllowedCurrencies.includes(projectInfo?.currency) && (
                    <Grid item xs={12}>
                      <SimpleCheckboxField
                        name="isSez"
                        checked={values.isSez}
                        inputProps={{ style: { fontSize: 13 } }}
                        setFieldValue={setFieldValue}
                        label="Is SEZ?"
                        size="small"
                      />
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <SimpleCheckboxField
                      name="isInternal"
                      checked={values.isInternal}
                      inputProps={{ style: { fontSize: 13 } }}
                      setFieldValue={setFieldValue}
                      label="Mark as Internal?"
                      size="small"
                    />
                  </Grid>
                </Grid>
                {checkAccess(projectPaths.GET_PROJECT_DETAIL('id'), ApiOperations.PUT) && (
                  <Button
                    disabled={!dirty || !isValid}
                    variant="contained"
                    type="submit"
                    className="upload-button">
                    {intl.formatMessage({
                      id: I18nKey.BUTTON_LABEL_SAVE,
                    })}
                  </Button>
                )}
              </Form>
            );
          }}
        </Formik>
      ) : null}
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
    </>
  );
};

ProjectInfoForm.defaultProps = {
  projectInfo: {
    projectName: '',
    alias: '',
    poc: [],
    managerUid: '',
  },
};

export default ProjectInfoForm;
