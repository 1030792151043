import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useDashBoardStyles = makeStyles((theme: Theme) => {
  return {
    dashBoardWrap: {
      padding: '1rem',
    },
    header: {
      fontSize: '24px',
      fontWeight: '600',
      marginLeft: '5px',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    templateBtn: {
      float: 'right',
      margin: '0px 10px !important',
    },
  };
});

export default useDashBoardStyles;
