import { getFullName } from '../../utils/utils';

export const formatEmployeeList = (values: any) =>
  values.map((el: any) => ({
    label: getFullName(el),
    value: el.uid,
  }));

export const formatProjectList = (values: any) =>
  values.map((el: any) => ({
    label: el.projectName,
    value: el.uid,
  }));
