import axios from 'axios';
import axiosInstance from '../axiosInstance';

interface TicketParams {
  key: string;
  value: string | boolean | number;
}
export const PATHS = Object.freeze({
  GET_TICKET: '/tms/ticket/',
  CREATE_TICKET: '/tms/ticket/',
  PUT_TICKET: (ticket_uid: any) => `/tms/ticket/${ticket_uid}`,
  GET_TICKET_DETAIL: (ticket_uid: any) => `/tms/ticket/${ticket_uid}`,
  GET_CATEGORY: '/tms/category/',
  CREATE_CATEGORY: '/tms/category/',
  PUT_CATEGORY: (categoty_uid: any) => `tms/category/${categoty_uid}`,
});

const getAllTickets = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_TICKET}`);
  return response.data;
};
const filterTickets = async (params: TicketParams[]): Promise<any> => {
  const query = `?${params
    .filter((item: TicketParams) => item.value !== '')
    .map((item: TicketParams, index: number) => {
      const separator = index === 0 ? '' : '&';
      const param = `${separator}${item.key}=${item.value}`;
      return param;
    })
    .join('')}`;
  const response = await axiosInstance.get(`${PATHS.GET_TICKET}${query}`);
  return response.data;
};

const createTicket = async (createTicketData: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CREATE_TICKET, createTicketData);
  return response;
};

const updateTicketDetails = async (ticket_uid: any, updateTicketData: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_TICKET(ticket_uid), updateTicketData);
  return response;
};

const deleteTicket = async (ticket_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CREATE_TICKET}${ticket_uid}`);
  return response;
};

const deleteAttachment = async (attachment_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.CREATE_TICKET}attachment/${attachment_uid}`);
  return response;
};

const getAllCategories = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_CATEGORY}`);
  return response.data;
};

const createCategory = async (createCategoryData: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.CREATE_CATEGORY, createCategoryData);
  return response;
};

const updateCategoryDetails = async (category_uid: any, updateCategoryData: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_CATEGORY(category_uid), updateCategoryData);
  return response;
};

const getTicketDetailById = async (ticket_uid: any): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_TICKET_DETAIL(ticket_uid));
  return response.data;
};

const previewModeAttachment = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

export interface TicketAPIClient {
  readonly getAllTickets: () => Promise<any>;
  readonly filterTickets: (params: TicketParams[]) => Promise<any>;
  readonly createTicket: (createTicketData: any) => Promise<any>;
  readonly updateTicketDetails: (ticket_uid: any, updateTicketData: any) => Promise<any>;
  readonly getAllCategories: () => Promise<any>;
  readonly createCategory: (createCategoryData: any) => Promise<any>;
  readonly deleteTicket: (ticket_uid: string) => Promise<any>;
  readonly deleteAttachment: (attachment_uid: string) => Promise<any>;
  readonly updateCategoryDetails: (category_uid: any, updateCategoryData: any) => Promise<any>;
  readonly previewModeAttachment: (params: any) => Promise<any>;
  readonly getTicketDetailById: (ticket_uid: any) => Promise<any>;
}
const TicketClient: TicketAPIClient = Object.freeze({
  getAllTickets,
  getAllCategories,
  createTicket,
  createCategory,
  updateTicketDetails,
  deleteTicket,
  deleteAttachment,
  updateCategoryDetails,
  previewModeAttachment,
  getTicketDetailById,
  filterTickets,
});

export default TicketClient;
