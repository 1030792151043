import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useAllProjectsStyles = makeStyles((theme: Theme) => {
  return {
    projectWrapper: {
      padding: '1rem',
    },
    allProjectsWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .highlight-current-month': {
        color: theme.palette.text.midDark,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '1.1rem',
      },
      '& .disable-past-month': {
        color: theme.palette.text.disabled,
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',

      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    formContainer: {
      marginTop: '-35px !important',
    },
  };
});

export default useAllProjectsStyles;
