import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import { currencyValueFormatter, dateFromatter } from '../../utils/utils';

const sowInitialSort = { field: 'sowEndDate', sort: 'asc' };
const msaInitialSort = { field: 'msaEndDate', sort: 'asc' };

const isExpired = (date: any) => {
  if (date && dayjs().isAfter(dayjs(date))) {
    return true;
  }
  return false;
};

const isExpiring = (date: any) => {
  if (date && !isExpired(date) && dayjs(date).diff(dayjs(), 'd') <= 30) {
    return true;
  }
  return false;
};

const cellClassName = (params: GridCellParams) => {
  if (isExpired(params.value)) {
    return 'past-date';
  }
  if (isExpiring(params.value)) {
    return 'due-date';
  }
  return '';
};

const validateDate = (params: any) =>
  params?.value && dayjs(params.value).isValid() ? dateFromatter(params.value) : '-';

const columns = [
  {
    field: 'projectName',
    type: 'string',
    headerName: 'Project/Opportunity',
    flex: 2,
    sortable: true,
    hideable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/contract/project/${params.row.id}`}>{params.row.projectName}</Link>
    ),
  },
  {
    field: 'projectStage',
    type: 'string',
    headerName: 'Stage',
    flex: 0.75,
    sortable: true,
    hideable: false,
    renderCell: (params: GridRenderCellParams) => params.value.toUpperCase(),
  },
  {
    field: 'msaEndDate',
    type: 'date',
    headerName: 'MSA End Date',
    valueFormatter: validateDate,
    flex: 1,
    sortable: true,
    hideable: false,
    cellClassName,
  },
  {
    field: 'sowStartDate',
    type: 'date',
    headerName: 'SOW Start Date',
    valueFormatter: validateDate,
    flex: 1,
    sortable: true,
    hideable: false,
  },
  {
    field: 'sowEndDate',
    type: 'date',
    headerName: 'SOW End Date',
    valueFormatter: validateDate,
    flex: 1,
    sortable: true,
    hideable: false,
    cellClassName,
  },
  {
    field: 'contractValue',
    type: 'number',
    headerName: 'SOW/Contract Value ($)',
    flex: 1,
    sortable: true,
    hideable: false,
    headerAlign: 'right',
    cellClassName: 'right-align-number-field',
    filterable: false,
    valueFormatter: (params: any) => currencyValueFormatter(params.value),
  },
];

const contractUidName = 'projectUid';

export { columns, sowInitialSort, msaInitialSort, contractUidName, isExpired, isExpiring };
