import '@aws-amplify/ui-react/styles.css';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import NewCandidateClient from '../../api/NewCandidate/newCandidateAPIs';
import useModalPopupStyles from '../../components/ModalPopup/ModalPopupStyle';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import { setNewCandidateAttributes } from '../../utils/auth';
import useLoginStyles from './LoginStyles';

const NewCandidateLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [newJoineeId, setNewJoineeId] = useState('');
  const navigate = useNavigate();

  const [openUnauthorisedDialog, setOpenUnauthorisedDialog] = useState(false);
  const location = useLocation();
  const urlParts = location?.pathname.split('/');
  const tokenPartIndex = urlParts.indexOf('candidate') + 1;
  const token = urlParts[tokenPartIndex];
  const styles = useLoginStyles();
  const intl = useIntl();
  const [candidateEmail, setCandidateEmail] = useState('');

  const locationState = location.state as any;
  const from = locationState?.from?.pathname || '/';
  const ModalPopupStyle = useModalPopupStyles();

  const navigateToCandidateInfo = async (res: any) => {
    setIsLoading(true);
    const claims = JSON.parse(window.atob(res.credential.split('.')[1]));
    if (claims.email) {
      NewCandidateClient.validateToken(token)
        .then((response: any) => {
          setNewJoineeId(response.new_joinee_uid);
          setCandidateEmail(claims.email);
          setIsTokenValid(true);
          setNewCandidateAttributes(token);
          const companyEmail = JSON.parse(window.atob(token.split('.')[1])).email;
          if (companyEmail !== claims.email) {
            setOpenUnauthorisedDialog(true);
          } else {
            navigate(`/onboarding/addCandidate/${response.new_joinee_uid}`, {
              state: {
                newJoineeId,
                candidateEmail,
                name: claims.name,
                picture: claims.picture,
              },
            });
          }
        })
        .catch(() => {
          setIsTokenValid(false);
          setOpenUnauthorisedDialog(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setOpenUnauthorisedDialog(true);
      setIsLoading(false);
    }
  };

  const navigateToError = () => {
    setOpenUnauthorisedDialog(true);
  };

  const handleClose = () => {
    setOpenUnauthorisedDialog(false);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.contentWrapper}>
        <Box className={styles.leftWrapper}>
          <Box className={styles.header}>
            <Typography fontSize={20}>
              {intl.formatMessage({
                id: I18nKey.LOGIN_ME,
              })}
            </Typography>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/images/tech-prescient-logo.png`}
              alt={intl.formatMessage({
                id: I18nKey.COMPANY_NAME,
              })}
              className={styles.logo}
            />
          </Box>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/images/login-hero.png`}
              alt={intl.formatMessage({
                id: I18nKey.COMPANY_NAME,
              })}
              className={styles.image}
            />
          </Box>
        </Box>
        <Box className={styles.rightWrapper}>
          <Typography variant="h3" className={styles.headerText}>
            {intl.formatMessage({
              id: I18nKey.LOGIN_HEADER,
            })}
          </Typography>
          <GoogleLogin size="large" onSuccess={navigateToCandidateInfo} onError={navigateToError} />
        </Box>

        <Modal open={openUnauthorisedDialog} onClose={handleClose}>
          <Box className={ModalPopupStyle.modalWrapper}>
            <div className={ModalPopupStyle.popupWrapper}>
              <Box className={ModalPopupStyle.closeIconBox}>
                <CloseIcon
                  fontSize="medium"
                  color="info"
                  className={ModalPopupStyle.closeIcon}
                  onClick={() => handleClose()}
                />
              </Box>
              <div className={ModalPopupStyle.content}>
                <Box className={ModalPopupStyle.boxCenter}>
                  <CancelSharpIcon color="error" fontSize="large" />
                </Box>
                <Box className={ModalPopupStyle.boxCenter}>
                  {intl.formatMessage({
                    id: I18nKey.EMAIL_AUTHORISED,
                  })}
                </Box>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
      <div>
        {!isLoading && !isTokenValid && (
          <Modal open={openUnauthorisedDialog} onClose={handleClose}>
            <Box className={ModalPopupStyle.modalWrapper}>
              <div className={ModalPopupStyle.popupWrapper}>
                <Box className={ModalPopupStyle.closeIconBox}>
                  <CloseIcon
                    fontSize="medium"
                    color="info"
                    className={ModalPopupStyle.closeIcon}
                    onClick={() => handleClose()}
                  />
                </Box>
                <div className={ModalPopupStyle.content}>
                  <Box className={ModalPopupStyle.boxCenter}>
                    <CancelSharpIcon color="error" fontSize="large" />
                  </Box>
                  <Box className={ModalPopupStyle.boxCenter}>
                    {intl.formatMessage({
                      id: I18nKey.TOKEN_VALID,
                    })}
                  </Box>
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

export default NewCandidateLogin;
