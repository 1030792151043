import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import { InfoOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { Formik, FieldArray, Field, Form } from 'formik';
import { Box, Button, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import useTimeSheetStyles from './TimeSheetStyles';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import TimeSheetClient from '../../api/TimeSheet/timeSheet';
import MisDialog from '../MisDialog/MisDialog';

interface TimeSheetProps {
  readonly currentWeekStatus: any;
  readonly weekStart: any;
  readonly getTimeSheetData: any;
  readonly showSubmitBtn?: boolean;
  readonly setWeekStart: any;
  readonly disableFields?: boolean;
  readonly isDataLoading?: boolean;
}
const TimeSheet: React.FC<TimeSheetProps> = ({
  currentWeekStatus,
  weekStart,
  getTimeSheetData,
  showSubmitBtn,
  setWeekStart,
  disableFields,
  isDataLoading,
}: TimeSheetProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableNextPage, setDisableNextPage] = useState(true);
  const [initialValues, setInitialValues] = useState<any>({ uid: null, timeSheetDetails: [] });
  const [timesheetParams, setTimesheetParams] = useState<any>();

  const styles = useTimeSheetStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const latestDate = weekStart.add(6, 'day');
  const currentDate = dayjs();
  const lastdayOfCurrentWeek = currentDate.add(7, 'day');

  const validationSchema = Yup.object().shape({
    timeSheetDetails: Yup.array().of(
      Yup.object().shape({
        description: Yup.array().of(
          Yup.object().shape({
            hour: Yup.number()
              .positive('Hour must be a positive number')
              .typeError('Hour must be a number')
              .min(0, 'Hour cannot be negative')
              .test('decimal', 'Hour must be a float number up to two decimal place', (value) => {
                if (value !== undefined && value !== null) {
                  const decimalRegex = /^\d+(\.\d{1,2})?$/;
                  return decimalRegex.test(value.toString());
                }
                return true;
              }),
          }),
        ),
      }),
    ),
  });

  const onPageChange = (event: any) => {
    const currentId = event.currentTarget.id;
    setWeekStart(weekStart[currentId](7, 'day'));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getuid = (currentUId = '') => {
    if (currentUId) {
      return { uid: currentUId };
    }
    return {};
  };

  const updateTimesheetDetails = (updateTimeSheetInfoParams: any, hasSubmit: boolean) => {
    const queryParams = {
      is_submitted: hasSubmit,
    };
    TimeSheetClient.updateTimeSheetInfo(updateTimeSheetInfoParams, queryParams)
      .then((res) => {
        showSnackbar(res, 'success');
        getTimeSheetData();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getTimesheetParams = (values: any) => {
    setOpenDialog(true);
    const dateDetails = values.timeSheetDetails.map((item: any) => ({
      [item.day]: item.description,
    }));
    const detail = Object.assign({}, ...dateDetails);
    setTimesheetParams({
      ...getuid(values.uid),
      startDate: weekStart.format('YYYY-MM-DD'),
      endDate: weekStart.add(4, 'day').format('YYYY-MM-DD'),
      details: detail,
    });
  };

  const handleSaveTimesheet = (values: any) => {
    setIsLoading(true);
    const dateDetails = values.timeSheetDetails.map((item: any) => ({
      [item.day]: item.description,
    }));
    const detail = Object.assign({}, ...dateDetails);
    const updateTimeSheetInfoParams = {
      ...getuid(values.uid),
      startDate: weekStart.format('YYYY-MM-DD'),
      endDate: weekStart.add(4, 'day').format('YYYY-MM-DD'),
      details: detail,
    };
    setTimesheetParams(updateTimeSheetInfoParams);
    updateTimesheetDetails(updateTimeSheetInfoParams, false);
  };

  const handleSubmitTimesheet = () => {
    setOpenDialog(false);
    setIsLoading(true);
    updateTimesheetDetails(timesheetParams, true);
  };

  useEffect(() => {
    const startOfWeek = currentDate.startOf('week').add(1, 'day').format('YYYY-MM-DD');
    if (weekStart.isSame(startOfWeek, 'day')) {
      setDisableNextPage(true);
    } else {
      setDisableNextPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStart]);

  useEffect(() => {
    if (
      Object.keys(currentWeekStatus).length !== 0 &&
      !Object.values(currentWeekStatus).includes(undefined)
    ) {
      const formatDetailsObject = Object.entries(currentWeekStatus.details).map(([key, value]) => ({
        [key]: value,
      }));
      const initialVal = {
        uid: currentWeekStatus.uid,
        timeSheetDetails: Object.values(formatDetailsObject).map((obj: any, i) => {
          return {
            day: Object.keys(obj)[0],
            description: Object.values(obj).flatMap((item: any) => {
              if (item.length) {
                return item;
              }
              return { hour: 0, detail: '' };
            }),
          };
        }),
      };
      setInitialValues(initialVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeekStatus]);

  return (
    <>
      {' '}
      <Box>
        {showSubmitBtn && (
          <Box className={styles.header}>
            {' '}
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.TIMESHEET_TITLE,
              })}
            </span>
          </Box>
        )}
        <Box key={weekStart.format()} className={styles.timeSheetBoxWrapper}>
          {isLoading || isDataLoading ? (
            <Box className={styles.spinnerWrapper}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleSaveTimesheet}>
              {(formik) => {
                const { values, dirty, isValid } = formik;
                const submitBtnDisable = values.timeSheetDetails?.some((timeSheetDetail: any) =>
                  timeSheetDetail.description?.some((item: any) => !item.hour || !item.desc),
                );
                return (
                  <Form>
                    <table className={`${styles.table} `}>
                      <thead>
                        <tr>
                          <th className={styles.column}>Day</th>
                          <th className={styles.columnSecond}>
                            Hours{' '}
                            <Typography className={styles.rightHeading}>Description</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.timeSheetDetails?.map((timeSheetDetail: any, index: any) => (
                          <tr>
                            <td className={styles.column}>
                              {dayjs(timeSheetDetail.day).format('DD/MM/YYYY ddd')}
                            </td>

                            <td className={styles.column}>
                              <FieldArray
                                name={`timeSheetDetails.${index}.description`}
                                render={(arrayHelpers) => (
                                  <>
                                    {timeSheetDetail?.description.map(
                                      (desc: any, descIndex: any) => (
                                        <Box sx={{ display: 'flex' }}>
                                          <Box className={styles.hourWrapper}>
                                            <Field
                                              as={TextField}
                                              type="number"
                                              name={`timeSheetDetails.${index}.description.${descIndex}.hour`}
                                              placeholder="Hours"
                                              className={styles.formField}
                                              size="small"
                                              value={desc.hour}
                                              disabled={
                                                disableFields ||
                                                latestDate.isAfter(lastdayOfCurrentWeek)
                                              }
                                              inputProps={{
                                                min: 0,
                                                max: 24,
                                                step: 0.01,
                                                style: { fontSize: 13 },
                                              }}
                                            />
                                          </Box>
                                          <Box className={styles.desWrapper}>
                                            <Field
                                              as={TextField}
                                              type="text"
                                              name={`timeSheetDetails.${index}.description.${descIndex}.desc`}
                                              placeholder="Description"
                                              size="small"
                                              multiline
                                              value={desc.desc}
                                              inputProps={{ style: { fontSize: 13 } }}
                                              fullWidth
                                              disabled={
                                                disableFields ||
                                                latestDate.isAfter(lastdayOfCurrentWeek)
                                              }
                                            />
                                            {!disableFields && (
                                              <Button
                                                type="button"
                                                className={styles.helpersBtn}
                                                onClick={() => {
                                                  arrayHelpers.push({ hour: 0, detail: '' });
                                                }}>
                                                <AddIcon className={styles.addIcon} />
                                              </Button>
                                            )}
                                            {timeSheetDetail.description.length > 1 &&
                                              !disableFields && (
                                                <Button
                                                  type="button"
                                                  className={styles.helpersBtn}
                                                  onClick={() => {
                                                    arrayHelpers.remove(descIndex);
                                                  }}>
                                                  <DeleteIcon className={styles.deleteIcon} />
                                                </Button>
                                              )}
                                          </Box>
                                        </Box>
                                      ),
                                    )}
                                  </>
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Box className={styles.lowerBoxWrapper}>
                      <Box>
                        <Button id="subtract" onClick={onPageChange} className={styles.arrowBtn}>
                          <ArrowLeftOutlinedIcon />
                        </Button>
                        <Button
                          id="add"
                          onClick={onPageChange}
                          className={styles.arrowBtn}
                          disabled={disableNextPage}>
                          <ArrowRightOutlinedIcon />
                        </Button>
                      </Box>
                      <Box>
                        {showSubmitBtn && (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              className={styles.saveBtn}
                              disabled={
                                latestDate.isAfter(lastdayOfCurrentWeek) || !isValid || !dirty
                              }>
                              Save
                            </Button>
                            <Button
                              variant="outlined"
                              disabled={
                                latestDate.isAfter(lastdayOfCurrentWeek) ||
                                !isValid ||
                                dirty ||
                                submitBtnDisable
                              }
                              onClick={() => getTimesheetParams(values)}>
                              Submit
                            </Button>
                            <Tooltip
                              title="For leave, please enter the leave/vacation in the description and the number of hours, respectively."
                              placement="left">
                              <InfoOutlined color="info" className={styles.info} />
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.TIMESHEET_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.TIMESHEET_SUBMIT_CONFIRMATION,
        })}
        isOpen={openDialog}
        handleSuccess={handleSubmitTimesheet}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_SUBMIT,
        })}
      />
    </>
  );
};
TimeSheet.defaultProps = {
  showSubmitBtn: true,
  disableFields: false,
  isDataLoading: false,
};
export default TimeSheet;
