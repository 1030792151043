import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTicketBoardStyles = makeStyles((theme: Theme) => {
  return {
    ticketBoardWrapper: {
      flexGrow: 1,
      marginTop: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },

    iconbutton: {
      position: 'absolute',
      bottom: '50px',
      right: '50px',
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',
      '& svg': {
        fontSize: '35px',
      },
    },
    createTicketButton: {
      marginRight: '10px',
    },
    cardWrapper: {
      border: '1px solid #cecece',
      margin: '5px',
    },
    cardHeader: {
      background: 'linear-gradient(180deg, #1976d2 0%, #094A9E 100%)',
      padding: '8px !important',
    },
    cardTitle: {
      fontSize: '15px',
      textAlign: 'center',
      fontWeight: '600',
      color: '#fff',
    },
    droppableBox: {
      height: 'calc(100vh - 240px)',
    },
    badgeTitle: {
      marginLeft: '13px',
      marginBottom: '3px',
      '& span': {
        backgroundColor: '#fff',
        color: '#1976d2',
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
  };
});

export default useTicketBoardStyles;
