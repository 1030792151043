import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  GET_OPERATIONS: 'rbac/operations',
});

const getOperationsList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_OPERATIONS}`);
  return response.data;
};

export interface RbacAPIClient {
  readonly getOperationsList: () => Promise<any>;
}

const RbacClient: RbacAPIClient = Object.freeze({
  getOperationsList,
});

export default RbacClient;
