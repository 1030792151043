import { useEffect } from 'react';
import { useContext } from 'use-context-selector';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/styles';
import flat from 'flat';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import enMessages from './translations/en.json';
import AppRouter from './pages/Router/AppRouter';
import { awsConfig } from './utils/auth';
import { LanguageContext } from './contexts/LanguageContext';
import { GlobalContextProvider } from './contexts/GlobalContext';
import theme from './theme';
import './App.css';

import { setExchangeRates } from './utils/exchangeRates';

Amplify.configure(awsConfig);

const localeConfig: Record<string, any> = {
  // [langCode: langFile] entries to be added when translations are available.
  en: enMessages,
};

function App() {
  useEffect(() => {
    /* Get and set currency exchange rates in local storage */
    setExchangeRates();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const locale = useContext(LanguageContext).language;
  return (
    <IntlProvider
      locale={localeConfig[locale] ? locale : 'en'}
      messages={flat(localeConfig[locale] ? localeConfig[locale] : localeConfig.en)}>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <Authenticator.Provider>
            <Router>
              <AppRouter />
            </Router>
          </Authenticator.Provider>
        </ThemeProvider>
      </GlobalContextProvider>
    </IntlProvider>
  );
}

export default App;
