import { Box, Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import useKraTemplateDetailsStyles from './KraTemplateDetailStyles';
import { getAppraisalFY, getFYDateRange } from '../../utils/utils';

const SaveKraTemplate: React.FC<any> = ({
  allResources,
  handleAppraisalStart,
  savedKraInfo,
  isStartDisabled,
  id,
  isManager,
  date,
}: any) => {
  const [fields, setFields] = useState({
    resourceName: id ? '' : null,
    startDate: date?.startDate,
    endDate: date?.endDate,
  });
  const styles = useKraTemplateDetailsStyles();

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required('Start date is required'),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End Date can't be before Start Date")
      .required('End date is required'),
    resourceName: id
      ? Yup.string().required('Resource Name is required')
      : Yup.object()
          .shape({ label: Yup.string(), value: Yup.string() })
          .typeError('Resource Name is required'),
  });

  useEffect(() => {
    if (id) {
      setFields({
        resourceName: savedKraInfo.empName,
        startDate: savedKraInfo.startDate,
        endDate: savedKraInfo.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedKraInfo.empName]);

  return (
    <Box className={styles.formWrapper}>
      <Formik
        initialValues={fields}
        onSubmit={handleAppraisalStart}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, setFieldValue, dirty, isValid, errors } = formik;
          return (
            <Form className={styles.elements}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  {id ? (
                    <SimpleInputField
                      name="resourceName"
                      label="Select Resource"
                      size="small"
                      disabled={isStartDisabled}
                      fullWidth
                      required
                    />
                  ) : (
                    <SimpleAutocompleteField
                      name="resourceName"
                      key="resourceName"
                      label="Select Resource"
                      size="small"
                      setFieldValue={setFieldValue}
                      options={allResources}
                      values={values}
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <SimpleInputField
                    name="startDate"
                    type="date"
                    label="Start Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStartDisabled}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <SimpleInputField
                    name="endDate"
                    type="date"
                    label="End Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStartDisabled}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                disabled={!dirty || !isValid || isStartDisabled || !isManager}>
                Start
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SaveKraTemplate;
