import { useIntl } from 'react-intl';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useDocumentStyles from './DocumentStyles';
import I18nKey from '../../translations/I18nKey';
import InternsList from './InternList';

const AllInterns: React.FC<any> = () => {
  const styles = useDocumentStyles();
  const intl = useIntl();

  return (
    <Box className={styles.generateDocWrapper}>
      <Box className={styles.HeaderWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.CANDIDATE_TITLE,
            })}
          </span>
          <Box className={styles.LinkWrapper}>
            <Link to="/document">
              {intl.formatMessage({
                id: I18nKey.GENERATE_DOCUMENT_TITLE,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.INTERNSHIP_OFFER_LETTER,
            })}
          </Box>
        </Box>
        <Box>
          <Link to="/document/internshipLetter/newInternshipLetter">
            <Button className={styles.templateBtn} variant="contained">
              {intl.formatMessage({
                id: I18nKey.NEW_INTERNSHIP_LETTER,
              })}
            </Button>
          </Link>
        </Box>
      </Box>
      <Box className={styles.ContentWrapper}>
        <InternsList />
      </Box>
    </Box>
  );
};

export default AllInterns;
