import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'applicantName', sort: 'asc' };

const columns = (rowActions: any[]): any[] => {
  return [
    {
      field: 'applicantName',
      type: 'string',
      headerName: 'Applicant Name',
      flex: 2,
      sortable: true,
      filterable: false,

      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'experience',
      type: 'number',
      headerName: '#Experience',
      headerAlign: 'left',
      cellClassName: 'left-align-number-field',
      flex: 1.5,
      sortable: true,
      filterable: false,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'skill',
      type: 'string',
      headerName: 'Skill',
      flex: 1.3,
      sortable: true,
      filterable: false,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      width: 10,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
};
const assignedIntervieweesId = 'uid';

export { columns, initialSort, assignedIntervieweesId };
