import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useTimeSheetStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      fontSize: '14px !important',
      fontWeight: '600',
      marginLeft: '2px',
      marginBottom: '8px',
    },
    column: {
      borderBottom: '1px solid #DDDDDD',
      borderRight: '1px solid #F1F1F1',
      textAlign: 'left',
      padding: '2px',
      fontWeight: 'normal',
      fontSize: '13px',
    },
    columnSecond: {
      borderBottom: '1px solid #DDDDDD',
      borderRight: '1px solid #F1F1F1',
      textAlign: 'left',
      padding: '2px',
      fontWeight: 'normal',
      fontSize: '13px',
      display: 'flex',
    },
    table: {
      width: '100%',
      backgroundColor: '#fff',
      borderTop: '1px solid #DDDDDD',
      minHeight: '50px',
      position: 'relative',
      marginBottom: '10px',
      '& th': {
        fontSize: '14px !important',
        fontWeight: '600',
      },
    },
    tableContents: {
      verticalAlign: 'top !important',
    },
    addTable: {
      borderBottom: '1px solid #DDDDDD',
    },
    hourWrapper: {
      margin: '2px',
      width: '70px',
    },
    desWrapper: {
      display: 'flex',
      margin: '2px',
      flexBasis: '100%',
    },
    addIcon: {
      color: theme.palette.status?.midDark,
      fontSize: '16px !important',
    },
    deleteIcon: {
      color: theme.palette.status?.danger,
      fontSize: '16px !important',
    },
    helpersBtn: {
      minWidth: '10px !important',
    },
    lowerBoxWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '15px !important',
      paddingRight: '15px !important',
    },
    timeSheetBoxWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      overflowY: 'auto',
      height: 'inherit',
      minHeight: '340px',
      maxHeight: '550px !important',
    },
    arrowBtn: {
      minWidth: 'fit-content !important',
    },
    rightHeading: {
      textAlign: 'center',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      marginLeft: '23px !important',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '30%',
      height: '10vh',
    },
    formField: {
      '& input': {
        height: '23px',
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    saveBtn: {
      marginRight: '10px !important',
    },
    info: {
      float: 'left',
      margin: ' 7px 10px 0 0',
      fontSize: '20px !important',
    },
  };
});

export default useTimeSheetStyles;
