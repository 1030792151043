import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAddCandidateDetailStyles = makeStyles((theme: Theme) => {
  return {
    fileDetailsWrapper: {
      display: 'flex',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
    },
    uploadText: {
      fontSize: '14px !important',
    },
    pdfIcon: {
      margin: '0 1px 0 8px',
    },
    docIcon: {
      margin: '2px 1px 0 8px',
      fontSize: '1rem !important',
    },
    csvFormWrapper: {
      padding: '1rem',
    },
  };
});

export default useAddCandidateDetailStyles;
