import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
  GridRenderEditCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { getColumnConfig } from '../../components/Datatable/datatableUtils';
import { dateFromatter, getListOfMonths } from '../../utils/utils';
import { InvoiceStatus } from '../Invoice/invoiceTableConfig';
import { getCurrencySymbol } from '../../utils/currency';
import RevenueRenderEditCell from './components/RevenueRenderEditCell';
import { AccountTypes } from '../../utils/accountTypes';

const initialSort = { field: 'employeeName', sort: 'asc' };

const dynamicColumns = [
  {
    fieldName: 'totalRevenue',
    columnHeader: 'month',
    rowCell: 'revenue',
    getCellValue(row: any) {
      return {
        [row[this.columnHeader]]: {
          amount: row[this.rowCell],
          comment: row.comment,
          noOfHours: row.noOfHours,
        },
      };
    },
  },
];

export const valueGetter = (params: GridValueGetterParams) => {
  return params.row[params.field]?.amount;
};

const valueSetter = function (this: GridColDef, params: GridValueSetterParams): object {
  return {
    ...params.row,
    [this?.field]: { ...params.row[this.field as string], amount: params.value },
  };
};

const getBillingHeader = (accountType: string, currency: string) => {
  let headerLabel = 'Billing Rate';
  switch (accountType) {
    case AccountTypes.TIME_MATERIAL_MONTHLY:
      headerLabel = `${headerLabel} (${getCurrencySymbol(currency)}/mo)`;
      break;
    case AccountTypes.TIME_MATERIAL_HOURLY:
      headerLabel = `${headerLabel} (${getCurrencySymbol(currency)}/hr)`;
      break;
    default:
  }
  return headerLabel;
};

export const renderCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.row[params.field]?.comment || ''} placement="right-start">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          justifyContent: 'flex-end',
        }}>
        {params?.colDef?.valueFormatter &&
          params?.colDef?.valueFormatter({
            value: params?.row[params.field]?.amount,
          } as GridValueFormatterParams)}
      </div>
    </Tooltip>
  );
};

const getColumns = ({
  currentDateRange,
  columnVisibilityModel,
  invoiceStatus,
  currency,
  accountType,
  rowActions,
}: any) => {
  const colRange = getListOfMonths(currentDateRange);
  const editableCols = [dayjs().subtract(1, 'month').month(), dayjs().month()];
  const cols = [
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Employee',
      flex: 1.5,
      sortable: true,
      hideable: false,
      isVisible: true,
      // renderCell: (params: GridRenderCellParams) => (
      //   <Link to={`/revenue/resource/${params.row.id}`}>{params.row.employeeName}</Link>
      // ),
    },
    {
      field: 'startDate',
      type: 'date',
      valueFormatter: (params: any) => dateFromatter(params.value),
      headerName: 'Start Date',
      flex: 1.25,
      sortable: true,
      editable: false,
    },
    {
      field: 'endDate',
      type: 'date',
      valueFormatter: (params: any) => dateFromatter(params.value),
      headerName: 'End Date',
      flex: 1,
      sortable: true,
      editable: false,
    },
    {
      field: 'billingRate',
      type: 'number',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      headerName: getBillingHeader(accountType, currency),
      flex: 1.25,
      sortable: true,
      editable: true,
    },
    {
      field: 'allocationPercentage',
      type: 'number',
      headerName: 'Allocation (%)',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      flex: 1.25,
      sortable: true,
      editable: false,
    },
    ...colRange.map((month) =>
      getColumnConfig({
        valueGetter,
        valueSetter,
        renderCell,
        ...(accountType === AccountTypes.TIME_MATERIAL_HOURLY && {
          renderEditCell: (params: GridRenderEditCellParams, updateProcessedRows: Function) => (
            <RevenueRenderEditCell {...params} updateProcessedRows={updateProcessedRows} />
          ),
        }),

        field: month,
        // Todo: Remove 1 month subtract condition and keep only for current month after invoice generated in december
        ...(dayjs(month).isBefore(dayjs().subtract(1, 'month').startOf('month')) && {
          headerClassName: 'disable-past-month',
          cellClassName: 'disable-past-month right-align-number-field',
        }),
        ...(dayjs(month).isSame(dayjs().startOf('month')) && {
          headerClassName: 'highlight-current-month',
          cellClassName: 'highlight-current-month right-align-number-field',
        }),
        // Todo: Remove 1 month subtract condition and keep only for current month after invoice generated in december
        ...(invoiceStatus &&
          invoiceStatus !== false &&
          editableCols.includes(dayjs(month).month()) &&
          invoiceStatus[month] !== InvoiceStatus.SHARED && {
            editable: true,
            commentable: true,
          }),
        currency,
      }),
    ),
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      flex: 0.5,
      hideable: false,
      getActions: (params: any) =>
        rowActions?.map((action: any) => {
          return (
            <Tooltip title={action.label}>
              <GridActionsCellItem
                key={action.label}
                icon={action.logo}
                label={action.label}
                onClick={() => action.callback(params)}
              />
            </Tooltip>
          );
        }),
    },
  ];
  return cols;
};

const getInitialVisibilityModel = ({ startDate, endDate }: any, showColumns: any) => {
  const colRange = getColumns({ currentDateRange: { startDate, endDate } });
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] =
      showColumns.includes(dayjs(col.field).month()) || showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const revenueEmployeeIdName = 'employeeUid';
const initialShowCols = [
  'employeeName',
  'billingRate',
  'allocationPercentage',
  'startDate',
  'endDate',
  'actions',
];

export {
  initialSort,
  dynamicColumns,
  revenueEmployeeIdName,
  initialShowCols,
  getColumns,
  getInitialVisibilityModel,
};
