import React, { useState } from 'react';
import { Box } from '@mui/material';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import MisTabs from '../../components/MisTabs/MisTabs';
import RevenueList from './RevenueList';
import RevenuesByEmployee from './RevenuesByEmployee';
import useAllRevenuesStyles from './AllRevenuesStyles';
import FYSelector from '../../components/FYSelector/FYSelector';
import { getCurrentMonthRange } from '../../utils/utils';

interface RevenueTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}
const currDateRange = getCurrentMonthRange(dayjs());

const AllRevenues: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(location?.search);
  const [currentDateRange, setCurrentDateRange] = useState(
    queryParams.startDate
      ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
      : currDateRange,
  );

  const allRevenuesStyles = useAllRevenuesStyles();
  const intl = useIntl();
  const { type } = useParams();

  const revenueTabs: RevenueTabs[] = [
    {
      label: 'Active',
      // eslint-disable-next-line react/jsx-boolean-value
      component: <RevenueList is_active={true} currentDateRange={currentDateRange} />,
    },
    {
      label: 'Completed',
      // eslint-disable-next-line react/jsx-boolean-value
      component: <RevenueList is_active={false} currentDateRange={currentDateRange} />,
    },
  ];
  // const handleYearChange = (dateRange: any) => {
  //   setCurrentFy({
  //     startDate: dateRange.startDate,
  //     endDate: dateRange.endDate,
  //   });
  //   navigate({
  //     search: createSearchParams({
  //       startDate: dateRange.startDate,
  //       endDate: dateRange.endDate,
  //     }).toString(),
  //   });
  // };

  return (
    <Box className={allRevenuesStyles.revenueWrapper}>
      <Box className={allRevenuesStyles.Header}>
        <span className="headerTitle">
          {intl.formatMessage({
            id: I18nKey.REVENUE_DETAIL_TITLE,
          })}
        </span>
        {/* <Box className="headerContent">
          <FYSelector onChange={handleYearChange} defaultValue={currentDateRange} />
        </Box> */}
      </Box>
      <div className={allRevenuesStyles.allRevenuesWrapper}>
        {/* Revenue by employee not required due inconsistent currency */}
        <MisTabs tabs={revenueTabs} />
      </div>
    </Box>
  );
};

export default AllRevenues;
