import { isArray } from 'lodash';
import { useCallback, useState } from 'react';
import { Point } from 'react-d3-tree/lib/types/common';

export const useCenteredTree = (heightOffset: number = 200) => {
  const [translate, setTranslate] = useState<Point>({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem: any) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 2 - heightOffset });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [translate, containerRef];
};

export const formatMyTeamData = (data: {
  employeeDetails: any;
  managerDetails: any;
  reporteeDetails: any[];
}) => {
  return {
    ...(data.managerDetails.employeeUid
      ? {
          ...data.managerDetails,
          children: [
            {
              ...data.employeeDetails,
              children: [...data.reporteeDetails],
            },
          ],
        }
      : {
          ...data.employeeDetails,
          children: [...data.reporteeDetails],
        }),
  };
};

const formatManagerTree = (data: any, tree: any): any => {
  const {
    manager,
    designation,
    employeeId,
    employeeUid,
    firstName,
    lastName,
    middleName,
    directReporteesCount,
  } = data;

  if (!manager) {
    return {
      designation,
      employeeId,
      employeeUid,
      firstName,
      lastName,
      middleName,
      directReporteesCount,
      children: tree,
    };
  }

  return formatManagerTree(manager, [
    {
      designation,
      employeeId,
      employeeUid,
      firstName,
      lastName,
      middleName,
      directReporteesCount,
      children: tree,
    },
  ]);
};

const formatReporteesTree = (data: any) => {
  const {
    designation,
    employeeId,
    employeeUid,
    firstName,
    lastName,
    middleName,
    reportees,
    directReporteesCount,
  } = data;

  if (!data.reportees && !isArray(data)) {
    return {
      designation,
      employeeId,
      employeeUid,
      firstName,
      lastName,
      middleName,
      directReporteesCount,
      children: [],
    };
  }
  return {
    designation,
    employeeId,
    employeeUid,
    firstName,
    lastName,
    middleName,
    directReporteesCount,
    children: [...reportees.map((item: any) => formatReporteesTree(item))],
  };
};

export const formatEmployeeHierarchyData = (data: {
  current: any;
  manager: any;
  reportees: any[];
}) => {
  const { manager, current, reportees } = data;

  if (Object.keys(manager).length)
    return formatManagerTree(manager, [
      {
        ...current,
        children: reportees.length
          ? reportees.map((reportee) => formatReporteesTree(reportee))
          : [],
      },
    ]);

  return {
    ...current,
    children: reportees.length ? reportees.map((reportee) => formatReporteesTree(reportee)) : [],
  };
};
