const getColumns = (skills: any) =>
  skills.map((skill: any) => ({ field: skill, headerName: skill, width: 150, sortable: false }));

const initialDeleteColumns = [
  'Typescript',
  'Django',
  'FastAPI',
  'Flask',
  'Hibernate',
  'SpringBoot',
  'CSS',
  'HTML',
  'CICD',
  'Jenkins',
  'ShellScripting',
  'Terraform',
];

export { getColumns, initialDeleteColumns };
