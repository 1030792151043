import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip, CircularProgress, IconButton, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import MisDialog from '../../../components/MisDialog/MisDialog';
import AddSkill from './AddSkillComponent';
import I18nKey from '../../../translations/I18nKey';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import MasterClient from '../../../api/Master/masterAPIs';
import EmployeeClient from '../../../api/Employee/employeeAPIs';
import useSkillStyles from './MySkillsStyles';
import { GlobalContext } from '../../../contexts/GlobalContext';

const MySkills: React.FC<any> = ({ skills, setSkillsList, refetchList }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useSkillStyles();
  const intl = useIntl();
  const { employeeUid } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();
  const [newSkill, setNewSkill] = useState<any>({
    skill: { value: '', label: '' },
    isPrimary: false,
  });
  const [allSkills, setAllSkills] = useState<any>();

  const getSkills = () => {
    setIsLoading(true);
    MasterClient.getSkills()
      .then((s: any) => {
        setAllSkills(s.data.map((skill: any) => ({ value: skill.uid, label: skill.name })));
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSkill = () => {
    setOpenDialog(true);
  };
  const handleAdd = () => {
    const skillSet = skills.find((s: any) => s.isPrimary === newSkill.isPrimary);
    if (skillSet) {
      const skillIds = skillSet.skillNames.map((s: any) => s.uid);
      if (!skillIds.includes(newSkill.skill.value)) {
        skillIds.push(newSkill.skill.value);
        if (employeeUid) {
          setIsLoading(true);
          EmployeeClient.updateEmployeeSkill([
            {
              skillIds,
              uid: skillSet.uid,
            },
          ])
            .then((res) => {
              showSnackbar(res, 'success');
              refetchList();
            })
            .catch((e) => {
              showSnackbar(e, 'error');
            })
            .finally(() => setIsLoading(false));
        }
      }
    } else if (employeeUid) {
      setIsLoading(true);
      EmployeeClient.addEmployeeSkill([
        {
          skillIds: [newSkill.skill.value],
          isPrimary: newSkill.isPrimary,
        },
      ])
        .then((res) => {
          showSnackbar(res, 'success');
          refetchList();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
    setOpenDialog(false);
    setNewSkill({});
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (skillToDelete: any, skill: any) => () => {
    const skillSet: any = skills.find((s: any) => s.isPrimary === skillToDelete.isPrimary);
    const skillIds = skillSet.skillNames
      .filter((s: any) => s.uid !== skill.uid)
      .map((i: any) => i.uid);
    if (employeeUid) {
      setIsLoading(true);
      EmployeeClient.updateEmployeeSkill([
        {
          uid: skillSet.uid,
          skillIds,
        },
      ])
        .then((res) => {
          showSnackbar(res, 'success');
          refetchList();
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Box className={skills.length === 0 ? classes.EmptyCard : classes.UpperCardWrapper}>
        <Box className={classes.UpperCardHeader}>
          <Typography fontWeight={600}>
            {intl.formatMessage({
              id: I18nKey.PROFILE_MY_SKILLS,
            })}
          </Typography>
          <IconButton onClick={addSkill} className={classes.AddButton}>
            <AddIcon />
          </IconButton>
        </Box>

        {isLoading ? (
          <Box className={classes.spinnerWrapper}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box className={classes.UpperCardContent}>
            {skills.length ? (
              skills
                .sort((a: any, b: any) => b.isPrimary - a.isPrimary)
                .map((i: any) => {
                  return (
                    <>
                      <Typography className={classes.SkillsSubTitle}>
                        {i.isPrimary ? 'Primary Skills' : 'Secondary Skills'}
                      </Typography>

                      {i.skillNames.length &&
                        i.skillNames.map((s: any) => {
                          return (
                            <div className={classes.ChipSpace}>
                              <Chip
                                label={s.name}
                                variant="outlined"
                                onDelete={handleDelete(i, s)}
                              />
                            </div>
                          );
                        })}
                    </>
                  );
                })
            ) : (
              <Typography className={classes.noData}>No Skill Added</Typography>
            )}
          </Box>
        )}
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROFILE_MY_SKILLS,
        })}
        isOpen={openDialog}
        handleSuccess={handleAdd}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_ADD,
        })}
        additionalInfoSection={
          <AddSkill
            newSkill={{ skill: { value: '', label: '' }, isPrimary: false }}
            setNewSkill={setNewSkill}
            skillList={allSkills}
          />
        }
      />
    </>
  );
};

export default MySkills;
