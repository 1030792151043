import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useContext } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Employee } from '../../api/Opportunity/opportunityTypes';
import ResourceClient, { PATHS } from '../../api/Resource/resourceAPIs';
import { EmployeeManagerApiParams } from '../../api/Resource/resourceTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';

interface ManagerFieldsProps {
  readonly handleManagerChange?: Function;
  readonly currManager: any;
  readonly newManager: any;
  readonly employees: Employee[];
  readonly selectedResource?: any;
  readonly handleSuccessfulSubmit: Function;
  readonly fetchManager: boolean;
}
const ManagerFields: React.FC<ManagerFieldsProps> = ({
  handleManagerChange,
  employees,
  selectedResource,
  currManager,
  newManager,
  handleSuccessfulSubmit,
  fetchManager,
}) => {
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);
  const handleChange = (name: string, value: any) => {
    handleManagerChange?.(name, value);
  };
  const filteredEmployees = employees.filter(
    (employee: any) => employee.value !== selectedResource?.id,
  );

  const isNewManagerChanged = currManager.manager?.uid !== newManager.manager?.uid;
  const isNewManagerDateChanged = currManager?.startDate !== newManager?.startDate;

  const submitEmployeeManager = () => {
    const empManagerParams: EmployeeManagerApiParams = {
      managerUid: newManager?.manager.value,
      startDate: newManager?.startDate,
    };

    if (isNewManagerChanged || (isNewManagerChanged && isNewManagerDateChanged)) {
      ResourceClient.addEmployeeManager(selectedResource.employeeUid, empManagerParams)
        .then((res) => {
          showSnackbar(res, 'success');
          handleSuccessfulSubmit();
        })
        .catch((e) => showSnackbar(e, 'error'));
    } else if (isNewManagerDateChanged) {
      ResourceClient.updateEmployeeManager(
        selectedResource.employeeUid,
        newManager.manager?.uid,
        empManagerParams,
      )
        .then((res) => {
          showSnackbar(res, 'success');
          handleSuccessfulSubmit();
        })
        .catch((e) => showSnackbar(e, 'error'));
    }
  };

  return (
    <>
      {fetchManager ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Grid container spacing={1.5}>
          {(checkAccess(PATHS.PUT_EMPLOYEE_MANAGER('id', 'id'), ApiOperations.PUT) ||
            checkAccess(PATHS.EMPLOYEE_MANAGER('id'), ApiOperations.POST)) && (
            <IconButton
              sx={{ position: 'absolute', top: -5, right: 0 }}
              color="success"
              disabled={
                (!isNewManagerChanged || (isNewManagerChanged && !newManager?.startDate)) &&
                (!isNewManagerDateChanged || (isNewManagerDateChanged && !newManager?.manager?.uid))
              }
              onClick={submitEmployeeManager}>
              <DoneIcon />
            </IconButton>
          )}
          <Grid item xs={7}>
            <Autocomplete
              id="manager"
              options={filteredEmployees}
              renderOption={(props: any, option: any) => (
                <MenuItem {...props} key={option.value}>
                  {option.label}
                </MenuItem>
              )}
              onChange={(e: any, option) => handleChange('manager', option)}
              value={newManager?.manager}
              disableClearable
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  size="small"
                  name="manager"
                  label="Manager"
                  fullWidth
                  inputProps={{ ...inputProps, style: { fontSize: 13 } }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              id="manager-start-date"
              label="Effective From"
              size="small"
              value={newManager?.startDate}
              inputProps={{ style: { fontSize: 13 } }}
              type="date"
              fullWidth
              onChange={(e: any) => handleChange('startDate', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

ManagerFields.defaultProps = {
  handleManagerChange: () => {},
  selectedResource: {},
};

export default ManagerFields;
