import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllResourcesStyles = makeStyles((theme: Theme) => {
  return {
    onBoardingWrapper: {
      padding: '1rem',
    },
    Header: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',

      '& .employee-skill': {
        padding: '2px 8px',
        margin: '1px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',
      },
      '& .employee-skill-cell': {
        flexWrap: 'wrap',
      },
      '& .expanded-cell': {
        flexWrap: 'wrap',
        padding: '8px 0px !important',
      },
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(0deg)`,
        transform: `rotate(0deg)`,
        fontSize: '35px',
      },
    },
    w70: {
      width: '70%',
    },
    contentWrapper: {
      display: 'flex',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
  };
});
export default useAllResourcesStyles;
