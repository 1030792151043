import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useFeedbackDetailsFormStyles = makeStyles((theme: Theme) => {
  return {
    inputBoxWrapper: {
      marginTop: '10px',
      '& .MuiInputBase-root': {
        fontSize: '14px !important',
      },
    },
    inputBoxWrapperRating: {
      marginBottom: '20px',
    },
    label: {
      fontSize: '14px !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '25px',
    },
    buttonContainerSecond: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      display: 'flex !important',
      justifyContent: 'flex-end !important',
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '130px !important',
      textTransform: 'none',
    },
    tableWrapper: {
      border: '1px solid #D9D9D9',
      borderRadius: '12px !important',
      marginBottom: '5px',
      marginTop: '5px',
      '& .MuiFormGroup-root': {
        marginTop: '-14px',
        marginBottom: '-14px',
      },
      '& .MuiTableCell-root': {
        fontSize: '14px !important',
        padding: '10px !important',
      },
      '& .MuiTypography-root': {
        fontSize: '14px !important',
      },
    },
    radioButtons: {
      justifyContent: 'space-between ',
      '& svg': {
        fontSize: '18px',
      },
    },
    optionName: {
      fontWeight: 'bold !important',
    },
    dialogTextBox: {
      marginTop: '-15px !important',
    },
    divider: {
      marginTop: '10px !important',
      marginBottom: '10px !important',
    },
    deleteBtn: {
      float: 'right',
      '& svg': { color: theme.palette.status?.danger, fontSize: '18px' },
    },
    skillFormWrapper: {
      marginTop: '3px',
      marginRight: '15px',
      marginLeft: '20px',
    },
    logicalFormWrapper: {
      marginRight: '10px',
      marginLeft: '20px',
      marginTop: '-20px',
    },
    feedbackFormWrapper: {
      marginRight: '10px',
      marginLeft: '20px',
    },
    attributeFormWrapper: {
      marginRight: '40px',
      marginLeft: '20px',
    },
    saveButton: {
      marginRight: '10px !important',
      float: 'right',
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginBottom: '20px',
      marginRight: '35px',
    },
    FeedbackDetailsWrapper: {
      borderRadius: '8px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      border: '1px solid #ddd;',
      background: theme.palette.background.default,
      '& .MuiDivider-root': {
        paddingTop: '5px',
      },
      '& .MuiButton-root': {
        fontSize: '12px !important',
      },
    },
  };
});

export default useFeedbackDetailsFormStyles;
