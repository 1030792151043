import axiosInstance from '../axiosInstance';
import { UpdatedLinkedInDetailParams } from './linkedInTypes';

export const PATHS = Object.freeze({
  CONTACT: `/contacts/profile/`,
  FILTERPROFILES: '/contacts/profile/listing',
});

const getAllLinkedInList = async (
  paginationModel: {
    page: number;
    limit: number;
  },
  filterdata?: any,
): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.FILTERPROFILES}`, filterdata, {
    params: { page: paginationModel.page + 1, limit: paginationModel.limit },
  });
  return response.data;
};

const updateLinkedInDetails = async (
  updatedDetails: UpdatedLinkedInDetailParams[],
): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.CONTACT}`, updatedDetails);
  return response;
};

export interface LinkedInAPIClient {
  readonly updateLinkedInDetails: (updatedDetails: UpdatedLinkedInDetailParams[]) => Promise<any>;
  readonly getAllLinkedInList: (
    paginationModel: {
      page: number;
      limit: number;
    },
    filterdata?: any,
  ) => Promise<any>;
}

const LinkedInClient: LinkedInAPIClient = Object.freeze({
  getAllLinkedInList,
  updateLinkedInDetails,
});

export default LinkedInClient;
