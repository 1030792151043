import React from 'react';
import { TextField } from '@mui/material';
import { ErrorMessage, Field, useField } from 'formik';

const SimpleInputField: React.FC<any> = ({ name, ...formFileds }) => {
  const [field, mata] = useField(name);

  const fieldProperties: any = {
    name,
    ...formFileds,
  };

  if (mata && mata.touched && mata.error) {
    fieldProperties.error = true;
    fieldProperties.helperText = <ErrorMessage name={name} />;
  }

  return <Field as={TextField} {...field} {...fieldProperties} />;
};

export default SimpleInputField;
