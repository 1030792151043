import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useHomeStyles from './HomeStyles';
import { emailTableData } from './utils';

const EmailAiasesList: React.FC = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useHomeStyles();

  useEffect(() => {
    setIsLoading(true);
    setTableRows(emailTableData);
    setIsLoading(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.holidayWrapper}>
        <Box className={styles.holidayHeader}>
          <span>Email Aliases</span>
        </Box>
        <TableContainer className={styles.emailTableWrapper} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeadWrapper}>Department</TableCell>
                <TableCell className={styles.tableHeadWrapper}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailTableData.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell className={styles.emailRowData}>{row.department}</TableCell>
                  <TableCell className={styles.emailRowData}>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default EmailAiasesList;
