import { Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import I18nKey from '../../translations/I18nKey';
import EmpDocument from '../../components/EmployeeDocument/EmpDocument';
import useProfileDocumentStyles from './ProfileDocumentStyles';

const ProfileDocumentPage = () => {
  const params = useParams();
  const classes = useProfileDocumentStyles();
  const intl = useIntl();
  const employeeUid = params.id || '';

  return (
    <>
      <Box className={classes.DocWrapper}>
        <Box className={classes.Header}>
          <Box>
            <span className="headerTitle">
              {intl.formatMessage({
                id: I18nKey.PROFILE_MY_DOCUMENTS,
              })}
            </span>
            <Box>
              <Link to="/profile">
                {intl.formatMessage({
                  id: I18nKey.PROFILE_TITLE,
                })}{' '}
              </Link>
              /{' '}
              {intl.formatMessage({
                id: I18nKey.PROFILE_MY_DOCUMENTS,
              })}
            </Box>
          </Box>
        </Box>
        <Box className={classes.LowerCardWrapper}>
          <EmpDocument employeeId={employeeUid} isUploadable usedBy="profile" />
        </Box>
      </Box>
    </>
  );
};

export default ProfileDocumentPage;
