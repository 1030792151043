import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid';
import { Checkbox, Tooltip } from '@mui/material';
import { getCurrencySymbol } from '../../utils/currency';
import { currencyValueFormatter } from '../../utils/utils';

const initialSort = { field: 'resourceName', sort: 'asc' };

export const valueGetter = (params: GridValueGetterParams) => {
  return params?.row?.resourceName?.resourceName;
};

const valueSetter = function (this: GridColDef, params: GridValueSetterParams): object {
  return {
    ...params.row,
    resourceName: {
      ...params?.row?.resourceName,
      value: params?.row?.resourceName?.resourceName,
    },
  };
};

const renderCell = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.row?.resourceName?.comment || ''} placement="right-start">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
        {params?.row?.resourceName?.resourceName}
      </div>
    </Tooltip>
  );
};

const getColumns = (currency: string) => {
  return [
    {
      field: 'includeInInvoice',
      headerName: 'Include In Invoice?',
      type: 'boolean',
      flex: 0.5,
      editable: true,
      renderCell: (params: any) => {
        return <Checkbox checked={params.value} disabled />;
      },
    },
    {
      field: 'resourceName',
      type: 'string',
      headerName: 'Resource Name',
      flex: 1,
      sortable: true,
      hide: false,
      editable: true,
      commentable: true,
      renderCell,
      valueGetter,
      valueSetter,
    },
    {
      field: 'resourceType',
      type: 'string',
      headerName: 'Resource Type',
      flex: 1,
      sortable: true,
      hide: false,
    },
    {
      field: 'billing',
      type: 'number',
      headerName: `Billing (${getCurrencySymbol(currency)})`,
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      valueFormatter: (params: GridRenderCellParams) => currencyValueFormatter(params.value),
      flex: 1,
      sortable: true,
      hide: false,
    },
    {
      field: 'allocation',
      type: 'number',
      headerName: 'Allocation (%)',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      valueFormatter: (params: GridRenderCellParams) => `${params.value}`,
      flex: 1,
      sortable: true,
      hide: false,
    },
  ];
};

const invoiceResourceIdName = 'employeeUid';

export { getColumns, initialSort, invoiceResourceIdName };
