import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { Orientation, PathFunctionOption, Point } from 'react-d3-tree/lib/types/common';
import { Link } from 'react-router-dom';
import EmployeeClient from '../../../api/Employee/employeeAPIs';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useProfileStyles from '../ProfileStyles';
import { formatMyTeamData, useCenteredTree } from '../utils';
import MyTeamCard from './MyTeamCard';

export interface MyTeamProps {
  containerStyles?: Record<string, string>;
  orientation?: Orientation;
  pathFunc?: PathFunctionOption;
}
const renderForeignObjectNode = ({
  nodeDatum,
  foreignObjectProps,
}: {
  nodeDatum: any;
  toggleNode: any;
  foreignObjectProps: { width: number; height: number; x: number };
}) => {
  return (
    <g>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <MyTeamCard nodeDatum={nodeDatum} />
      </foreignObject>
    </g>
  );
};

const MyTeam: React.FC<MyTeamProps> = ({ containerStyles, orientation, pathFunc }) => {
  const [tree, setTree] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useProfileStyles();
  const { employeeUid } = useContext(GlobalContext);
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 170, y: 150 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -20,
  };

  useEffect(() => {
    if (employeeUid) {
      setIsLoading(false);
      EmployeeClient.getEmployeeTeam()
        .then((res) => {
          setTree(formatMyTeamData(res.data));
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.MyTeamWrapper} ref={containerRef as React.LegacyRef<HTMLDivElement>}>
      <div className={classes.tree_root} style={containerStyles}>
        <Box className={classes.TeamHeader}>
          <Typography fontWeight={600}>My Team</Typography>
          <Link to={'team'}>
            <Typography fontSize={13}>View Full Team</Typography>
          </Link>
        </Box>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        {tree ? (
          <Tree
            data={tree as any}
            translate={translate as Point}
            nodeSize={nodeSize}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({
                ...rd3tProps,
                foreignObjectProps,
              })
            }
            orientation={orientation}
            pathFunc={pathFunc}
            zoomable={false}
          />
        ) : null}
      </div>
    </div>
  );
};

MyTeam.defaultProps = {
  containerStyles: {
    width: '100%',
    height: '100%',
  },
  orientation: 'vertical',
  pathFunc: 'step',
};

export default MyTeam;
