import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { Box } from '@mui/material';
import AppraisalClient from '../../api/Appraisal/appraisalAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { AppraisalStatusList } from '../../api/Appraisal/appraisalTypes';
import { getAppraisalFY, hasSuperUserAccess } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import ResourceDetails from './ResourceDetails';
import AddKraTemplate from './AddKraTemplate';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useAllAppraisalsStyles from './AllAppraisalStyles';

const KraTemplateDetails: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);
  const [isGoalLocked, setIsGoalLocked] = useState<boolean>(false);
  const [savedKraInfo, setSavedKraInfo] = useState({});
  const [appraisalDetails, setAppraisalDetails] = useState([]);
  const [appraisalStatus, setAppraisalStatus] = useState('');
  const [resourceDetailsTableInfo, setResourceDetailsTableInfo] = useState({});
  const [empName, setEmpName] = useState('');
  const [empUid, setEmpUid] = useState('');
  const [managerUid, setManagerUid] = useState('');
  const [managerName, setManagerName] = useState('');
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState({});
  const [isManager, setIsManager] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [fetch, setFetch] = useState(0);

  const { employeeUid, groupNames } = useContext(GlobalContext);

  const { showSnackbar } = useDisplaySnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const currentDate = getAppraisalFY(dayjs());
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);
  const date = queryParams.startDate
    ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
    : currentDate;

  const styles = useAllAppraisalsStyles();
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const handleFormChange = () => setIsFormDirty(true);

  const formatCategoryData = (values: any) =>
    values.map((el: any) => ({
      label: el.name,
      value: el.uid,
    }));

  const getCategories = () => {
    setIsLoading(true);
    AppraisalClient.getallCategories()
      .then((response: any) => {
        setAllCategories(formatCategoryData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getSubCategories = () => {
    setIsLoading(true);
    AppraisalClient.getallSubCategories()
      .then((result: any) => {
        const response = result?.data;
        setAllSubCategories(formatCategoryData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getKraTemplateInfo = (response: any) => {
    setSavedKraInfo({
      empName: response.employeeName,
      startDate: response.appraisalStartDate,
      endDate: response.appraisalEndDate,
    });
    setAppraisalDetails(
      response.appraisalDetails?.map(
        ({ uid, category_uid, sub_category_uid, details, weightage }: any) => ({
          uid,
          category:
            allCategories.find(
              (el: { label: string; value: string }) => el.value === category_uid,
            ) || null,
          subCategory:
            allSubCategories.find(
              (el: { label: string; value: string }) => el.value === sub_category_uid,
            ) || null,
          description: details,
          weightage,
        }),
      ),
    );
  };

  const getResourceDetailsInfo = (response: any) => {
    setEmpName(response.employeeName);
    setEmpUid(response.employeeUid);
    setManagerUid(response.managerUid);
    setManagerName(response.managerName);
    setAppraisalStatus(response.appraisalStatus);
    setAppraisalDetails(response.appraisalDetails);
    setResourceDetailsTableInfo({
      startDate: dayjs(response.appraisalStartDate).format('DD MMM YYYY'),
      endDate: dayjs(response.appraisalEndDate).format('DD MMM YYYY'),
      managerName: response.managerName,
      designationName: response.designationName,
      employeeAvgRating: response.employeeAverageRating || 0,
      managerAvgRating: response.managerAverageRating || 0,
    });
    setIsFormCompleted(response.isFormCompleted);
    setFeedbackDetails({
      employeeStrength: response.employeeFeedback?.strength || '',
      managerStrength: response.managerFeedback?.strength || '',
      employeeAreaOfImp: response.employeeFeedback?.areasOfImprovement || '',
      managerAreaOfImp: response.managerFeedback?.areasOfImprovement || '',
      employeeOverallFeedback: response.employeeFeedback?.overallFeedback || '',
      managerOverallFeedback: response.managerFeedback?.overallFeedback || '',
      promotionComments: response.promotionComments || '',
      promotionRecommended: response.promotionRecommended,
      oneOnOne: response.appraisalOneOnOneDone,
    });
  };

  useEffect(() => {
    if (id && allCategories && allSubCategories) {
      setIsTemplateLoading(true);
      AppraisalClient.getAppraisalDetails(id)
        .then((response: any) => {
          setIsManager(hasSuperUserAccess(groupNames) || response.managerUid === employeeUid);
          setIsEmployee(response.employeeUid === employeeUid);
          if (response.appraisalStatus === AppraisalStatusList.DRAFT) {
            setIsGoalLocked(false);
            getKraTemplateInfo(response);
          } else {
            setIsGoalLocked(true);
            getResourceDetailsInfo(response);
          }
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [allCategories, allSubCategories, fetch, id]);

  useEffect(() => {
    setIsFormDirty(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  useEffect(() => {
    getCategories();
    getSubCategories();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box className={styles.kraDetailsWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      {isGoalLocked ? (
        <ResourceDetails
          empName={empName}
          empUid={empUid}
          managerUid={managerUid}
          managerName={managerName}
          appraisalStatus={appraisalStatus}
          appraisalDetails={appraisalDetails}
          isFormCompleted={isFormCompleted}
          isFormDirty={isFormDirty}
          resourceDetailsTableInfo={resourceDetailsTableInfo}
          feedbackDetails={feedbackDetails}
          allCategories={allCategories}
          isManager={isManager}
          isEmployee={isEmployee}
          date={date}
          handleFormChange={handleFormChange}
          updateFetch={updateFetch}
        />
      ) : (
        <AddKraTemplate
          setIsManager={setIsManager}
          isManager={isManager}
          isEmployee={isEmployee}
          allCategories={allCategories}
          allSubCategories={allSubCategories}
          savedKraInfo={savedKraInfo}
          appraisalDetails={appraisalDetails}
          isTemplateLoading={isTemplateLoading}
          setIsTemplateLoading={setIsTemplateLoading}
          date={date}
          updateFetch={updateFetch}
        />
      )}
    </Box>
  );
};

export default KraTemplateDetails;
