import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Box, Link as SimpleLink, Tooltip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { replaceEmpty, dateFromatter, getListOfMonths, UserGroupRoles } from '../../utils/utils';
import { InterviewStatus, validateDate } from './utils';

const initialSort = { field: 'scheduledDate', sort: 'desc' };
const getInterviewStatusIcons = (status: string) => {
  if (status.includes('Passed')) {
    return (
      <Tooltip title="Passed">
        <TaskAltIcon sx={{ color: 'green' }} />
      </Tooltip>
    );
  }
  if (status.includes('Failed')) {
    return (
      <Tooltip title="Failed">
        <CancelOutlinedIcon sx={{ color: 'red' }} />
      </Tooltip>
    );
  }
  if (status.includes('Did Not Appear')) {
    return (
      <Tooltip title="Did Not Appear">
        <ErrorOutlineIcon sx={{ color: 'orange' }} />
      </Tooltip>
    );
  }
  return status;
};

const columns = [
  {
    field: 'currentState',
    type: 'string',
    headerName: 'Current State',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'yearsOfExperience',
    type: 'number',
    headerName: 'Experience',
    headerAlign: 'left',
    cellClassName: 'left-align-number-field',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'skills',
    type: 'string',
    headerName: 'Skill',
    flex: 1.5,
    sortable: true,
    cellClassName: 'employee-skill-cell',
    valueFormatter: (params: any) => replaceEmpty(params.value),
    // renderCell: (params: GridRenderCellParams) => {
    //   const skillArray = params?.value?.split(',');
    //   return skillArray?.map((skill: string, index: number) => (
    //     // eslint-disable-next-line react/no-array-index-key
    //     <div className="employee-skill" key={skill + index}>
    //       {skill}
    //     </div>
    //   ));
    // },
  },
  {
    field: 'interviewStatus',
    type: 'string',
    headerName: 'Status',
    flex: 1.5,
    sortable: true,
    renderCell: ({ value }: GridRenderCellParams) => getInterviewStatusIcons(value),
  },
  {
    field: 'noticePeriod',
    type: 'string',
    headerName: 'Category',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'dateOfJoining',
    type: 'date',
    valueFormatter: validateDate,
    headerName: 'DOJ',
    flex: 1,
    sortable: true,
  },
  {
    field: 'interviewerName',
    type: 'string',
    headerName: 'Interviewer Name',
    flex: 1.5,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'scheduledDate',
    type: 'date',
    headerName: 'Scheduled Date',
    flex: 1.5,
    sortable: true,
    valueFormatter: validateDate,
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 1.5,
    sortable: true,
    hide: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
];

const getColumns = (
  selectedList: string,
  selectedTab: string,
  isAccess: boolean,
  rowActions: any[],
) => {
  const cols = [
    {
      field: 'candidateName',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={`/interview/${params.row.candidateUid}?selected_list=${selectedList}&selected_tab=${selectedTab}`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      hideable: false,
      align: 'center',
      flex: 1,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
  return cols;
};

const getInitialVisibilityModel = (
  { showColumns }: any,
  selectedList: any,
  selectedTab: any,
  isHRAccess: any,
  rowActions: any[],
) => {
  const colRange = getColumns(selectedList, selectedTab, isHRAccess, rowActions);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const candidateUid = 'candidateUid';

const initialShowCols = [
  'candidateName',
  'yearsOfExperience',
  'skills',
  'interviewStatus',
  'noticePeriod',
  'dateOfJoining',
  'interviewerName',
  'scheduledDate',
  'actions',
];

export {
  columns,
  initialSort,
  candidateUid,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
};
