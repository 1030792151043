import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { AddCircle } from '@mui/icons-material';
import CustomerRelationClient, {
  PATHS as opportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import { OpportunityCustomerParams } from '../../api/Opportunity/opportunityTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getFullName, getProcessFields } from './entitiesFormConfig';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';
import useAddEntitiesStyles from './AddEntitiesStyles';
import I18nKey from '../../translations/I18nKey';
import AddContactForm from './AddContactForm';
import SidePanel from '../../components/SidePanel/SidePanel';

interface AddCustomerFormProps {
  readonly handleModalClose: Function;
  readonly customerInfo?: any;
  readonly updateFetch?: () => void;
  readonly onCreateCustomer?: Function;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Customer name is required'),
  addressFirstLine: Yup.string().min(5, 'Min 5 Characters expected').nullable(),
  poc: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .required('POC is required')
    .min(1),
  accountPayablePoc: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .required('POC is required')
    .min(1),
});

const AddCustomerForm: React.FC<AddCustomerFormProps> = ({
  handleModalClose,
  customerInfo,
  updateFetch,
  onCreateCustomer,
}) => {
  const [isLoading, setLoading] = useState(false);
  const { showSnackbar } = useDisplaySnackbar();
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [fields, setFields] = useState({
    name: '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    poc: [],
    accountPayablePoc: [],
  });

  const { checkAccess } = useContext(GlobalContext);

  const styles = useAddEntitiesStyles();
  const intl = useIntl();

  const getAllContacts = () => {
    setLoading(true);
    CustomerRelationClient.getCrmContactList()
      .then((data) => {
        setContacts(
          data.map((contact: any) => ({
            label: `${getFullName(contact)} (${contact.email})`,
            value: contact.uid,
          })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllContacts();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setFields(customerInfo);
  }, [customerInfo]);

  const handleSubmit = (values: any) => {
    const {
      name,
      addressFirstLine,
      addressSecondLine,
      city,
      country,
      state,
      pincode,
      accountPayablePoc,
      poc,
    } = values;
    const addCRMCustomerParams: OpportunityCustomerParams = {
      name,
      addressFirstLine,
      addressSecondLine,
      city,
      country,
      state,
      pincode,
      accountPayablePoc: accountPayablePoc.map((p: any) => p.value),
      poc: poc.map((p: any) => p.value),
    };

    const processedParams = getProcessFields(values, addCRMCustomerParams);

    setLoading(true);

    const result = customerInfo.id
      ? CustomerRelationClient.updateCustomer(processedParams, customerInfo.id)
      : CustomerRelationClient.addCRMCustomer(processedParams);
    result
      .then((res) => {
        if (updateFetch) updateFetch();
        onCreateCustomer?.(res.data);
        showSnackbar(res, 'success');
        handleModalClose();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        handleModalClose();
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      name: customerInfo.name ? String(customerInfo.name) : '',
      addressFirstLine: customerInfo.addressFirstLine ? String(customerInfo.addressFirstLine) : '',
      addressSecondLine: customerInfo.addressSecondLine || '',
      city: customerInfo.city || '',
      state: customerInfo.state || '',
      country: customerInfo.country || '',
      pincode: customerInfo.pincode || '',
      poc: customerInfo.poc || [],
      accountPayablePoc: customerInfo.accountPayablePoc || [],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handAddEntity = (option: string) => {
    setSelectedOption(option);
  };
  const handleEntiyModalClose = (fetch: boolean = true) => {
    if (fetch) {
      if (selectedOption === 'contact') {
        getAllContacts();
      }
    }
    setSelectedOption(null);
  };

  const onCreateContact = ({ uid, first_name, middle_name, last_name }: any) => {
    const label = getFullName({
      firstName: first_name,
      middleName: middle_name,
      lastName: last_name,
    });
    if (selectedOption === 'accountPayablePoc') {
      formik.setFieldValue('accountPayablePoc', [
        ...formik.values.accountPayablePoc,
        ...[{ label, value: uid }],
      ]);
    } else {
      formik.setFieldValue('poc', [...formik.values.poc, ...[{ label, value: uid }]]);
    }
  };

  const optionsObj: any = {
    contact: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CONTACT,
      }),
      icon: <ContactsOutlinedIcon />,
      component: (
        <AddContactForm
          handleModalClose={handleEntiyModalClose}
          onCreateContact={onCreateContact}
        />
      ),
    },
    accountPayablePoc: {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CONTACT,
      }),
      icon: <ContactsOutlinedIcon />,
      component: (
        <AddContactForm
          handleModalClose={handleEntiyModalClose}
          onCreateContact={onCreateContact}
        />
      ),
    },
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Customer Name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              onBlur={formik.handleBlur}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="addressFirstLine"
              name="addressFirstLine"
              label="Address First Line"
              variant="standard"
              value={formik.values.addressFirstLine}
              onChange={formik.handleChange}
              error={formik.touched.addressFirstLine && !!formik.errors.addressFirstLine}
              helperText={
                formik.touched.addressFirstLine && formik.errors.addressFirstLine
                  ? formik.errors.addressFirstLine
                  : null
              }
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="addressSecondLine"
              name="addressSecondLine"
              label="Address Second Line"
              variant="standard"
              value={formik.values.addressSecondLine}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="city"
              name="city"
              label="City"
              variant="standard"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="state"
              name="state"
              label="State"
              variant="standard"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="country"
              name="country"
              label="Country"
              variant="standard"
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="pincode"
              name="pincode"
              label="Pincode"
              variant="standard"
              value={formik.values.pincode}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleAutocompleteField
              name="poc"
              key="poc"
              label="POC"
              variant="standard"
              size={'small'}
              setFieldValue={formik.setFieldValue}
              options={contacts}
              values={formik.values}
              required
              multiple
            />
            {checkAccess(opportunityPaths.CRM_CONTACT, ApiOperations.POST) && (
              <Button
                size="small"
                startIcon={<AddCircle />}
                className={styles.addBtn}
                onClick={() => handAddEntity('contact')}>
                {intl.formatMessage({
                  id: I18nKey.CUSTOMER_RELATION_CONTACT,
                })}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.gridItem}>
            <SimpleAutocompleteField
              name="accountPayablePoc"
              key="accountPayablePoc"
              label="Account Payable POC"
              variant="standard"
              size={'small'}
              setFieldValue={formik.setFieldValue}
              options={contacts}
              values={formik.values}
              required
              multiple
            />
            <Button
              size="small"
              startIcon={<AddCircle />}
              className={styles.addBtn}
              onClick={() => handAddEntity('accountPayablePoc')}>
              {intl.formatMessage({
                id: I18nKey.CUSTOMER_RELATION_CONTACT,
              })}
            </Button>
          </Grid>
        </Grid>
        {(checkAccess(opportunityPaths.CRM_CUSTOMER, ApiOperations.PUT) ||
          checkAccess(opportunityPaths.CRM_CUSTOMER, ApiOperations.POST)) && (
          <Button
            disabled={!formik.dirty || !formik.isValid}
            variant="contained"
            type="submit"
            className="save-btn">
            {intl.formatMessage({
              id: I18nKey.BUTTON_LABEL_SAVE,
            })}
          </Button>
        )}
      </form>
      <Modal open={selectedOption !== null} onClose={() => handleEntiyModalClose(false)}>
        <Box>
          <SidePanel
            header={optionsObj?.[selectedOption!]?.title}
            onClose={() => handleEntiyModalClose(false)}>
            <div className={styles.formContainer}>{optionsObj?.[selectedOption!]?.component}</div>
          </SidePanel>
        </Box>
      </Modal>
    </>
  );
};

AddCustomerForm.defaultProps = {
  customerInfo: {
    name: '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    poc: [],
    accountPayablePoc: [],
  },
  updateFetch: () => {},
  onCreateCustomer: () => {},
};

export default AddCustomerForm;
