import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import { getInvoiceStatusIcons } from './invoiceTableConfig';
import UploadInvoiceForm from './UploadInvoiceForm';

interface ManualInvoiceDetailProps {
  readonly cardInfo: any;
  readonly selectedMonthStart: dayjs.Dayjs;
  readonly selectedMonthEnd: dayjs.Dayjs;
  readonly invoiceDetail: any;
}

const ManualInvoiceDetail: React.FC<ManualInvoiceDetailProps> = ({
  cardInfo,
  selectedMonthStart,
  selectedMonthEnd,
  invoiceDetail,
}) => {
  const styles = useInvoiceDetailStyles();
  const intl = useIntl();

  const invoiceDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_PROJECT,
      }),
      value: cardInfo?.projectName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_INVOICE_NUMBER,
      }),
      value: cardInfo?.invoiceNumber || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_CUSTOMER,
      }),
      value: cardInfo?.customerName || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_START_DATE,
      }),
      value: selectedMonthStart.format('DD MMM YYYY'),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_END_DATE,
      }),
      value: selectedMonthEnd.format('DD MMM YYYY'),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.INVOICE_DETAIL_INVOICE_STATUS,
      }),
      value: getInvoiceStatusIcons(cardInfo?.invoiceStatus) || '-',
    },
  ];

  return (
    <>
      {/* Top card */}
      <Box className={styles.UpperCardWrapper}>
        {invoiceDetails.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box className={styles.UpperCardFields} key={item.label + index}>
            <span className={styles.UpperCardFieldsKey}>{item.label}</span>
            <p className={styles.UpperCardFieldsValue}>{item.value}</p>
          </Box>
        ))}
      </Box>

      {/* Bottom card */}
      <Box className={styles.ManualMiddleCardInfo}>
        <UploadInvoiceForm
          invoiceData={{
            project_uid: invoiceDetail.uid,
            invoiceDate: invoiceDetail.invoiceDate,
            file: null,
            invoiceTotal: invoiceDetail.invoiceTotal,
          }}
          editMode
        />
      </Box>
    </>
  );
};

export default ManualInvoiceDetail;
