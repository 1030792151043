import React from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Box } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import useInterviewDetailsStyles from './InterviewDetailsStyles';

interface AttributeFeedbackProps {
  roundWiseData: any;
}

const AttributeFeedback: React.FC<AttributeFeedbackProps> = ({ roundWiseData }) => {
  const styles = useInterviewDetailsStyles();

  return (
    <Table className={styles.attributeTable}>
      <TableHead>
        <TableRow>
          <TableCell className={styles.tableHead}>Attribute</TableCell>
          <TableCell className={styles.tableHead}>Poor</TableCell>
          <TableCell className={styles.tableHead}>Average</TableCell>
          <TableCell className={styles.tableHead}>Good</TableCell>
          <TableCell className={styles.tableHead}>Excellent</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {roundWiseData?.interviewFeedback?.attributes.map((item: any) => (
          <TableRow>
            <TableCell className={styles.attributeName}>{item.name}</TableCell>
            <TableCell className={styles.icon}>
              {item.rating === 'poor' ? <TaskAltIcon /> : null}
            </TableCell>
            <TableCell className={styles.icon}>
              {item.rating === 'average' ? <TaskAltIcon /> : null}
            </TableCell>
            <TableCell className={styles.icon}>
              {item.rating === 'good' ? <TaskAltIcon /> : null}
            </TableCell>
            <TableCell className={styles.icon}>
              {item.rating === 'excellent' ? <TaskAltIcon /> : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AttributeFeedback;
