import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import I18nKey from '../../translations/I18nKey';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { Status, positions, Priority } from './VacancyTypes';
import useAllVacanciesStyles from './AllVacanciesStyles';

interface AddVacancyFormProps {
  handleSubmit: any;
  projectList: any;
  customersList: any;
  skillsList: any;
  vacancyData: any;
  isEdit: boolean;
}

const AddVacancyForm: React.FC<AddVacancyFormProps> = ({
  handleSubmit,
  isEdit,
  projectList,
  customersList,
  skillsList,
  vacancyData,
}: AddVacancyFormProps) => {
  const intl = useIntl();
  const styles = useAllVacanciesStyles();
  const validationSchema = Yup.object().shape({
    designation: Yup.string().required('Designation is required'),
    jobDescription: Yup.array().required('Job Description is required').min(1),
  });

  return (
    <Box>
      <Formik
        initialValues={vacancyData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { values, isValid, dirty, setFieldValue } = formik;
          return (
            <Form>
              <Grid container rowSpacing={2} spacing={1}>
                <Grid item xs={12}>
                  <SimpleInputField
                    label="Designation"
                    name="designation"
                    type="string"
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="jobDescription"
                    key="jobDescription"
                    placeholder="Select Skills"
                    label="Select Skills"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={skillsList}
                    values={values}
                    multiple
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    label="Job Description"
                    name="jobDescriptionDetail"
                    type="text"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="positions"
                    key="positions"
                    placeholder="No Of Positions"
                    label="No Of Positions"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={positions}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="priority"
                    key="priority"
                    placeholder="Select Priority"
                    label="Select Priority"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={Priority}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="status"
                    key="status"
                    placeholder="Select Status"
                    label="Select Status"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={Status}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    label="Location"
                    name="location"
                    type="string"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    label="Expected Closure Date"
                    name="closureDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="projects"
                    key="projects"
                    placeholder="Select Projects"
                    label="Select Projects"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={projectList}
                    values={values}
                    multiple
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleAutocompleteField
                    name="customers"
                    key="customers"
                    placeholder="Select Customers"
                    label="Select Customers"
                    size="small"
                    setFieldValue={setFieldValue}
                    options={customersList}
                    values={values}
                    multiple
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleInputField
                    label="Remarks"
                    name="remarks"
                    type="text"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} className={styles.submitButton}>
                  <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                    {isEdit
                      ? intl.formatMessage({
                          id: I18nKey.SAVE,
                        })
                      : intl.formatMessage({
                          id: I18nKey.CREATE,
                        })}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddVacancyForm;
