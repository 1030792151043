import React, { useContext, useEffect, useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import IconButton from '@mui/material/IconButton';
import { useIntl } from 'react-intl';
import { Box, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import I18nKey from '../../translations/I18nKey';
import MisTabs from '../MisTabs/MisTabs';
import Datatable from '../Datatable/Datatable';
import { commonColumns, contractUidName, sowColumn, initialSort } from './ContractTableConfig';
import ContractTableClient, {
  PATHS as contractTablePaths,
} from '../../api/ContractTable/contractTableAPIs';
import { ContractDocumentList, GetProjectParams } from '../../api/ContractTable/contractTableTypes';
import ContractTableForm from './ContractTableForm';
import { generateRows } from '../Datatable/datatableUtils';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import { MSA, NDA, SOW } from '../../utils/docTypes';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';
import useContractTableStyles from './ContractTableStyle';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import MasterClient from '../../api/Master/masterAPIs';
import { DocType } from '../../api/Master/masterTypes';
import { AccountType } from '../../api/Project/projectTypes';
import SidePanel from '../SidePanel/SidePanel';

interface ContractTableTabs {
  readonly id: string;
  readonly label: string;
  readonly component: React.ReactNode;
}

interface ContractTableProps {
  project_id?: string;
  isUploadable?: boolean;
  onDataLoad?: Function;
  hideDocCategories?: string[];
  tableHeight?: any;
  currency?: string;
  usedFor?: 'others' | 'listing';
}

const filterByCategory = (contractDocuments: ContractDocumentList[], code: string) => {
  return contractDocuments.filter((c: ContractDocumentList) => c.documentTypeCode === code);
};

const ContractTable: React.FC<ContractTableProps> = ({
  project_id,
  isUploadable,
  onDataLoad,
  tableHeight,
  hideDocCategories = [],
  currency,
  usedFor,
}) => {
  const [contractDocs, setContractDocs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const projectUid = project_id || '';
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [docTypesFiltered, setDocTypesFiltered] = useState<DocType[]>([]);
  const [projectDetail, setProjectDetail] = useState<any>({ poc: [] });
  const [documentTypeCode, setDocumentTypeCode] = useState('');
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [docOptionsForm, setDocOptionsForm] = useState<any>([]);
  const [sowApproverList, setSowApproverList] = useState<any>([]);
  const [contractUid, setContractUid] = useState('');
  const initialDocType = docOptionsForm?.find(
    (item: any) => item.label === docTypesFiltered[0]?.code,
  );
  const [initialFields, setInitialFields] = useState({
    documentType: initialDocType || null,
    documentName: null,
    startDate: null,
    endDate: null,
    purchaseOrder: '',
    sowApprover: '',
    invoiceDueMonthDuration: '1',
    invoiceDueDayDuration: '0',
    accountType: null,
    contractValue: '',
  });

  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess } = useContext(GlobalContext);

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], contractUidName);
    return rows;
  };

  const getProjectDetail = () => {
    const getProjectParams: GetProjectParams = {
      project_id: projectUid,
    };

    if (usedFor === 'others')
      ContractTableClient.getProject(getProjectParams)
        .then((proj) => {
          setProjectDetail(proj);
        })
        .catch((e) => showSnackbar(e, 'error'));
    else if (usedFor === 'listing')
      ContractTableClient.getOpportunityProject(getProjectParams)
        .then((proj) => {
          setProjectDetail(proj);
        })
        .catch((e) => showSnackbar(e, 'error'));
  };

  const getDocumentTypes = () => {
    setLoading(true);
    MasterClient.getDocumentTypes()
      .then((data) => {
        const contractDocTypes = [SOW, MSA, NDA];
        setDocTypes(data.filter((doc: any) => contractDocTypes.includes(doc.code)));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getProjectDetail();
    getDocumentTypes();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setDocTypesFiltered(docTypes.filter((doc: any) => !hideDocCategories.includes(doc.code)));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [docTypes]);

  useEffect(() => {
    setDocTypesFiltered(docTypes.filter(({ code }) => !hideDocCategories.includes(code)));
    const optionsForForm = docTypesFiltered?.map((options: any) => ({
      value: options.uid,
      label: options.code,
    }));
    setDocOptionsForm(optionsForForm);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hideDocCategories]);

  useEffect(() => {
    setInitialFields((prevState) => ({
      ...prevState,
      documentType: initialDocType ? initialDocType.label : null,
    }));
  }, [initialDocType]);

  useEffect(() => {
    const SowApprovers = projectDetail?.poc.map((person: any) => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person.uid,
    }));
    setSowApproverList(SowApprovers);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [projectDetail]);

  useEffect(() => {
    setDocumentTypeCode(docTypesFiltered[0]?.code);
  }, [docTypesFiltered]);

  const extractMonthAndDay = (invoiceDueDateDuration: any) => {
    const monthSubstring = invoiceDueDateDuration.substring(1, invoiceDueDateDuration.indexOf('M'));
    const daySubstring = invoiceDueDateDuration.substring(
      invoiceDueDateDuration.indexOf('M') + 1,
      invoiceDueDateDuration.indexOf('D'),
    );
    return {
      month: monthSubstring,
      day: daySubstring,
    };
  };

  const rowActions = [
    {
      label: 'Edit',
      logo: <EditIcon />,
      callback: (params: any) => {
        setOpen(true);
        setIsEdit(true);
        const result = extractMonthAndDay(params.row.invoiceDueDateDuration);
        setInitialFields({
          documentType: params.row.documentTypeCode,
          documentName: params.row.name,
          startDate: params.row.startDate,
          endDate: params.row.endDate,
          purchaseOrder: params.row.purchaseOrder,
          sowApprover: params.row.sowApproverName,
          invoiceDueMonthDuration: result.month,
          invoiceDueDayDuration: result.day,
          accountType: params.row.accountType,
          contractValue: params.row.contractValue,
        });
        setContractUid(params.row?.contractUid);
      },
      path: contractTablePaths.EDIT_CONTRACT,
      operation: ApiOperations.PUT,
    },
  ];

  const toggle = () => {
    setOpen(!open);
    setIsEdit(false);
    setInitialFields({
      documentType: initialDocType || null,
      documentName: null,
      startDate: null,
      endDate: null,
      purchaseOrder: '',
      sowApprover: '',
      invoiceDueMonthDuration: '1',
      invoiceDueDayDuration: '0',
      accountType: null,
      contractValue: '',
    });
  };

  const getContractDocs = () => {
    setLoading(true);
    const getProjectParams: GetProjectParams = {
      project_id: projectUid,
    };
    ContractTableClient.getContractDocs(getProjectParams)
      .then((data) => {
        if (onDataLoad) onDataLoad(data);
        const rows = getTableRows(data);
        setContractDocs(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getContractDocs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const sowContractRow = filterByCategory(contractDocs, SOW);
  const msaContractRow = filterByCategory(contractDocs, MSA);
  const ndaContractRow = filterByCategory(contractDocs, NDA);

  const contractTableStyles = useContractTableStyles();
  const contractTableTabs: ContractTableTabs[] = [
    {
      id: SOW,
      label: SOW.toUpperCase(),
      component: (
        <Datatable
          columns={sowColumn(currency || '', rowActions)}
          rows={sowContractRow}
          initialSort={initialSort as any}
          tableHeight={tableHeight}
        />
      ),
    },
    {
      id: MSA,
      label: MSA.toUpperCase(),
      component: (
        <Datatable
          columns={commonColumns}
          rows={msaContractRow}
          initialSort={initialSort as any}
          tableHeight={tableHeight}
        />
      ),
    },
    {
      id: MSA,
      label: NDA.toUpperCase(),
      component: (
        <Datatable
          columns={commonColumns}
          rows={ndaContractRow}
          initialSort={initialSort as any}
          tableHeight={tableHeight}
        />
      ),
    },
  ];

  const handleAddContract = (values: any) => {
    const file = values.documentFile;
    const projectContract = {
      projectUid,
      documentTypeUid: values.documentType?.value,
      startDate: values.startDate,
      endDate: values.endDate,
      category: 'professional',
      currency: 'USD',
      ...(values.documentType?.label === SOW && {
        sowApprover: values?.sowApprover?.value,
        invoiceDueDateDuration: `P${values?.invoiceDueMonthDuration}M${values?.invoiceDueDayDuration}D`,
        accountType: values?.accountType?.value,
        contractValue: values.contractValue,
        purchaseOrder: values.purchaseOrder,
      }),
    };
    const formData = new FormData();
    formData.append('project_contract', JSON.stringify(projectContract));
    formData.append('file', file);
    setLoading(true);
    ContractTableClient.addContractDocs(projectUid, formData)
      .then((res) => {
        showSnackbar(res, 'success');
        getContractDocs();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        setLoading(false);
        toggle();
      });
  };

  const handleEditContract = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const project_finance_details = {
      contract_value: values.contractValue,
      purchase_order: values.purchaseOrder,
    };

    const projectFinanceDetails = { project_finance_details };
    setLoading(true);
    ContractTableClient.updateContractDocs(projectUid, contractUid, projectFinanceDetails)
      .then((res: any) => {
        showSnackbar(res, 'success');
        getContractDocs();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        setLoading(false);
        toggle();
      });
  };

  const handleSubmit = (values: any) => {
    if (isEdit) {
      handleEditContract(values);
    } else handleAddContract(values);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={contractTableStyles.contractTablesWrapper}>
        <Box className={`${contractTableStyles.header} contract-table-header`}>
          {intl.formatMessage({
            id: I18nKey.CONTRACT_TABLE_TITLE,
          })}
        </Box>
        <MisTabs tabs={contractTableTabs.filter(({ id }) => !hideDocCategories?.includes(id))} />
      </Box>
      {isUploadable && checkAccess(contractTablePaths.CONTRACT('id'), ApiOperations.POST) && (
        <>
          <div className={contractTableStyles.addButton}>
            <IconButton size="large" onClick={toggle}>
              <AddCircleRoundedIcon fontSize="large" color="primary" />
            </IconButton>
          </div>
          <Modal open={open} onClose={toggle}>
            <Box>
              <SidePanel header={isEdit ? 'Edit document' : 'Add document'} onClose={toggle}>
                <div className={contractTableStyles.formContainer}>
                  <ContractTableForm
                    handleSubmit={handleSubmit}
                    isEdit={isEdit}
                    docOptionsForm={docOptionsForm}
                    sowApproverList={sowApproverList}
                    initialFields={initialFields}
                  />
                  {isLoading && <ProgressSpinner showSpinner={isLoading} />}
                </div>
              </SidePanel>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

ContractTable.defaultProps = {
  isUploadable: false,
  project_id: '',
  onDataLoad: () => {},
  hideDocCategories: [],
  currency: '',
  usedFor: 'listing',
  tableHeight: '335px',
};

export default ContractTable;
