import axiosInstance from '../axiosInstance';
import { AuditLogsParams } from './AuditLogsTypes';

export const PATHS = Object.freeze({
  GET_AUDITLOGS: '/audit/all',
});
const getAuditLogs = async (auditLogsParams: AuditLogsParams): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_AUDITLOGS, { params: auditLogsParams });
  return response;
};
export interface AuditLogAPIClient {
  readonly getAuditLogs: (auditLogsParams: AuditLogsParams) => Promise<any>;
}
const AuditLogClient: AuditLogAPIClient = Object.freeze({
  getAuditLogs,
});

export default AuditLogClient;
