import React, { useState } from 'react';
import { Box } from '@mui/material';
import queryString from 'query-string';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import MisTabs from '../../components/MisTabs/MisTabs';
import ProjectList from './ProjectList';
import useAllProjectsStyles from './AllProjectsStyles';
import FYSelector from '../../components/FYSelector/FYSelector';
import { getFYDateRange } from '../../utils/utils';

interface ProjectTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

const currFYDateRange = getFYDateRange(dayjs());

const AllProjects: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(location?.search);
  const [currentFy, setCurrentFy] = useState(
    queryParams.startDate
      ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
      : currFYDateRange,
  );
  const allProjectsStyles = useAllProjectsStyles();
  const intl = useIntl();

  const projectTabs: ProjectTabs[] = [
    {
      label: 'Active',
      /* eslint-disable react/jsx-boolean-value */
      component: <ProjectList is_active={true} currentFy={currentFy} />,
    },
    {
      label: 'Completed',
      /* eslint-disable react/jsx-boolean-value */
      component: <ProjectList is_active={false} currentFy={currentFy} />,
    },
  ];

  const handleYearChange = (dateRange: any) => {
    setCurrentFy({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    navigate({
      search: createSearchParams({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }).toString(),
    });
  };

  return (
    <Box className={allProjectsStyles.projectWrapper}>
      <Box className={allProjectsStyles.Header}>
        <span className="headerTitle">
          {intl.formatMessage({
            id: I18nKey.PROJECT_DETAIL_TITLE,
          })}
        </span>
        <Box className="headerContent">
          <FYSelector onChange={handleYearChange} defaultValue={currentFy} />
        </Box>
      </Box>
      <div className={allProjectsStyles.allProjectsWrapper}>
        <MisTabs tabs={projectTabs} />
      </div>
    </Box>
  );
};

export default AllProjects;
