import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useRelationStyles = makeStyles((theme: Theme) => {
  return {
    UpperCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    EmptyCard: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      maxHeight: '45vh',
      minheight: '18vh',
    },
    UpperCardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      padding: '0.5rem',
      alignItems: 'center',
      maxHeight: '80%',
      overflowY: 'auto',
    },
    UpperCardHeader: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '110px',
    },
    AddButton: {
      padding: 'initial !important',
      color: `${theme.palette.text.light} !important`,
    },
    UpperCardFieldsKey: { fontSize: '12px' },
    UpperCardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1.5rem',
      position: 'relative',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      paddingBottom: '3.6rem',
    },
    DetailsBox: {
      backgroundColor: '#F9F9F9',
      marginBottom: '10px',
    },
    RelationCard: {
      padding: '9px 0px 0px 10px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    RelationName: {
      color: '#173F74 !important',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      cursor: 'pointer',
    },
    RelationDate: {
      color: 'black',
      padding: '3px 12px 0px 0px',
      fontSize: '14px !important',
      fontWeight: '600 !important',
    },
    RelationRel: {
      color: '#757575',
      padding: '12px 0px 7px 10px',
      fontSize: '12px !important',
      fontWeight: '600 !important',
    },
    RightSection: {
      display: 'flex',
    },
    RelDelete: {
      width: '20px',
      cursor: 'pointer',
    },
    noData: {
      margin: 'auto !important',
      padding: '2rem',
      fontSize: '13px !important',
      textAlign: 'center',
    },
  };
});

export default useRelationStyles;
