/* eslint-disable @typescript-eslint/no-unused-vars */
import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import { AddUpdateSkill, RelationTypes } from './employeeTypes';

export const PATHS = Object.freeze({
  PROFILE: `/profile`,
  ADD_CERTIFICATE: `/profile/document/certificates`,
  ADD_PERSONAL_DOC: `/profile/document/personal`,
  ADD_PROFESSIONAL_DOC: `/profile/document/professional`,
});

const getEmployeeProfile = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROFILE}/`);
  return response;
};

const getEmployeeRelation = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROFILE}/relation`);
  return response;
};

const getEmployeeTeam = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROFILE}/team`);
  return response;
};

const getEmployeeHierarchy = async (params: any): Promise<any> => {
  const queryParams = queryString.stringify(params);

  const response = await axiosInstance.get(`${PATHS.PROFILE}/hierarchy/?${queryParams}`);
  return response;
};

const addEmployeeRelation = async (relationParams: RelationTypes[]): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.PROFILE}/relation`, relationParams);
  return response;
};

const updateEmployeeRelation = async (relationParams: RelationTypes[]): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.PROFILE}/relation`, relationParams);
  return response;
};

const deleteEmployeeRelation = async (relation_id: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.PROFILE}/relation`, {
    data: [relation_id],
  });
  return response;
};

const getEmployeeSkills = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROFILE}/skill`);
  return response;
};

const addEmployeeSkill = async (skillParams: AddUpdateSkill[]): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.PROFILE}/skill`, skillParams);
  return response;
};

const updateEmployeeSkill = async (skillParams: AddUpdateSkill[]): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.PROFILE}/skill`, skillParams);
  return response;
};

// Profile APIs
const getEmployeeTodos = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROFILE}/todo`);
  return response;
};

const addEmployeeTodos = async (todosParams: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.PROFILE}/todo`, todosParams);
  return response;
};

const updateEmployeeTodos = async (todosParams: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.PROFILE}/todo`, todosParams);
  return response;
};

const deleteEmployeeTodos = async (todo_ids: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.PROFILE}/todo`, {
    data: [todo_ids],
  });
  return response;
};

const addEmployeeCertificates = async (employeeDocParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_CERTIFICATE, employeeDocParams);
  return response;
};

const addEmployeePersonalDocs = async (employeeDocParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_PERSONAL_DOC, employeeDocParams);
  return response;
};

const addEmployeeProfessionalDocs = async (employeeDocParams: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.ADD_PROFESSIONAL_DOC, employeeDocParams);
  return response;
};

export interface EmployeeAPIClient {
  readonly getEmployeeRelation: () => Promise<any>;
  readonly addEmployeeRelation: (relationParams: RelationTypes[]) => Promise<any>;
  readonly updateEmployeeRelation: (relationParams: RelationTypes[]) => Promise<any>;
  readonly deleteEmployeeRelation: (relation_id: string) => Promise<any>;
  readonly getEmployeeSkills: () => Promise<any>;
  readonly addEmployeeSkill: (skillParams: AddUpdateSkill[]) => Promise<any>;
  readonly getEmployeeTeam: () => Promise<any>;
  readonly getEmployeeHierarchy: (params: any) => Promise<any>;
  readonly updateEmployeeSkill: (skillParams: AddUpdateSkill[]) => Promise<any>;
  readonly getEmployeeProfile: () => Promise<any>;
  readonly getEmployeeTodos: () => Promise<any>;
  readonly addEmployeeTodos: (todosParams: any[]) => Promise<any>;
  readonly updateEmployeeTodos: (todosParams: any[]) => Promise<any>;
  readonly deleteEmployeeTodos: (todo_ids: string) => Promise<any>;
  readonly addEmployeeCertificates: (employeeDocParams: any) => Promise<any>;
  readonly addEmployeePersonalDocs: (employeeDocParams: any) => Promise<any>;
  readonly addEmployeeProfessionalDocs: (employeeDocParams: any) => Promise<any>;
}

const EmployeeClient: EmployeeAPIClient = Object.freeze({
  getEmployeeRelation,
  addEmployeeRelation,
  updateEmployeeRelation,
  deleteEmployeeRelation,
  getEmployeeTeam,
  getEmployeeHierarchy,
  getEmployeeSkills,
  addEmployeeSkill,
  updateEmployeeSkill,
  getEmployeeProfile,
  getEmployeeTodos,
  addEmployeeTodos,
  updateEmployeeTodos,
  deleteEmployeeTodos,
  addEmployeeCertificates,
  addEmployeeProfessionalDocs,
  addEmployeePersonalDocs,
});

export default EmployeeClient;
