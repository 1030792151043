import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useInterviewLevelTabs = makeStyles((theme: Theme) => {
  return {
    gridWrapper: {
      '& .MuiTypography-root': {
        fontSize: '14px !important',
      },
    },
    contentWrapper: {
      '& a': {
        position: 'absolute',
        bottom: '3px',
        color: theme.palette.text.light,
        textDecoration: 'none',
        fontSize: '14px',
      },
    },
    bottomContainerTitle: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px !important',
      fontSize: '12px !important',
      fontWeight: 'bold !important',
      color: `${theme.palette.status?.midDark} !important`,
    },
    bottomContainerIcons: {
      marginRight: '5px !important',
      borderRadius: '10px !important',
    },
    bold: {
      fontSize: '12px !important',
      fontWeight: 'bold !important',
    },
    title: {
      color: '#BDBDBD',
      fontSize: '14px !important',
      fontWeight: 'bold !important',
    },
    feedback: {
      paddingBottom: '10px',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    tabWrapper: {
      justifyContent: 'space-between !important',
      '& .MuiTabs-flexContainer': { justifyContent: 'space-around !important' },
      '& .MuiTab-root': {
        minWidth: 'auto !important',
        maxWidth: 'auto !important',
        padding: '0 10px',
        margin: 0,
        width: 'auto !important',
      },
    },
    editTitle: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '5px !important',
      fontSize: '12px !important',
      fontWeight: '400px !important',
    },
    editIcons: {
      color: `${theme.palette.status?.midDark} !important`,
      marginRight: '5px !important',
      borderRadius: '10px !important',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
    },
    submitButton: {
      marginTop: '10px !important',
    },
    dateTimeWrapper: {
      '& .MuiOutlinedInput-root': {
        height: '40px !important',
      },
    },
    statusWrapper: {
      display: 'flex',
    },
    comments: {
      wordWrap: 'break-word',
    },
  };
});

export default useInterviewLevelTabs;
