import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import AddCandidateForm from './Forms/AddCandidateForm';
import useAllInterviewStyles from './AllInterviewStyles';
import ApplicationStatusLevelForm from './Forms/ApplicationStatusLevelForm';
import { formatData, getRoundDetails } from './utils';
import InterviewClient from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import AddDocumentForm from './Forms/AddDocumentForm';
import { UserGroupRoles, getProcessFields, hasSuperUserAccess } from '../../utils/utils';
import { InterviewDetailsTypes } from '../../api/Interview/interviewTypes';
import { GlobalContext } from '../../contexts/GlobalContext';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface AddCandidateDetailsProps {
  selectedCandidateId?: any;
  isEditMode?: boolean;
}

const AddCandidateDetails: React.FC<AddCandidateDetailsProps> = ({
  selectedCandidateId,
  isEditMode,
}: any) => {
  const styles = useAllInterviewStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [interviewersData, setInterviewersData] = useState<any>([]);
  const [detailsData, setDetailsData] = useState<InterviewDetailsTypes>();
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const [selectedCandidateUid, setSelectedCandidateUid] = useState<string>(selectedCandidateId);
  const [fetch, setFetch] = useState<number>(0);
  const [phoneScreenRoundData, setphoneScreenRoundData] = useState<any>();
  const { groupNames } = useContext(GlobalContext);
  const [isHRAccess, setIsHRAccess] = useState<boolean>(
    hasSuperUserAccess(groupNames) ||
      groupNames.includes(UserGroupRoles.HR) ||
      groupNames.includes(UserGroupRoles.RECRUITER),
  );
  const [formState, setFormState] = useState({
    0: {
      name: '',
      email: '',
      currentState: null,
      designation: '',
      dateOfJoining: null,
      yearsOfExperience: 0,
      phoneNumber: '',
      currentCtc: '',
      expectedCtc: '',
      offeredCtc: '',
      skills: '',
      relevantExperienceInYrs: 0,
      linkedinUrl: '',
      hasResigned: null,
      lwd: null,
      noticePeriod: null,
      class10thPassingYr: '',
      class10thPassingPercentage: 0,
      class12thPassingYr: '',
      class12thPassingPercentage: 0,
      diplomaPassingYr: '',
      diplomaPassingPercentage: 0,
      graduationPassingYr: '',
      graduationPassingPercentage: 0,
      postGraduationPassingYr: '',
      postGraduationPassingPercentage: 0,
      previousDesignation: '',
      previousEmployer: '',
      addressFirstLine: '',
      referedBy: '',
      remarks: '',
    },
    1: {},
    2: {
      selectInterviewer: null,
      datetime: dayjs(),
      meetingLink: '',
    },
  });
  const generalFormRef = useRef();
  const resumeFormRef = useRef();
  const interviewFormRef = useRef();

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const formRefDict: Record<number, any> = {
    0: generalFormRef,
    1: resumeFormRef,
    2: interviewFormRef,
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const handleTabChange = (event: any, newValue: number) => {
    // Preserving the current state of the form in the global state
    if (formRefDict[tabValue]?.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[tabValue] = formRefDict[tabValue]?.current?.values;
      setFormState(newFormState);
    }

    formRefDict[newValue]?.current?.validateForm();
    // formRefDict[newValue]?.current?.isDirty = true;
    setTabValue(newValue);
  };

  const getInterviewersData = () => {
    setIsDetailsLoading(true);
    InterviewClient.getInterviewers()
      .then((response: any) => {
        setInterviewersData(formatData(response));
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  const getCandidateDetailsById = () => {
    setIsDetailsLoading(true);
    InterviewClient.getAllCandidateDetails(selectedCandidateUid)
      .then((result: InterviewDetailsTypes) => {
        const response: any = result.candidateDetails;
        setDetailsData({
          candidateDetails: result.candidateDetails,
          interviewFeedback: result.interviewFeedback,
        });
        setFormState({
          ...formState,
          0: {
            name: response.candidateName,
            email: response.email,
            currentState: response.currentState,
            dateOfJoining: response.dateOfJoining,
            yearsOfExperience: response.yearsOfExperience || 0,
            currentCtc: response.currentCtc,
            expectedCtc: response.expectedCtc,
            offeredCtc: response.offeredCtc,
            skills: response.skills,
            previousDesignation: response.previousDesignation,
            previousEmployer: response.previousEmployer,
            referedBy: response.referedBy,
            remarks: response.remarks,
            phoneNumber: response.candidatePhoneNumber,
            addressFirstLine: response.addressFirstLine,
            designation: response.candidateDesignation,
            relevantExperienceInYrs: response.relevantExperienceInYrs || 0,
            linkedinUrl: response.linkedinUrl,
            hasResigned: response.hasResigned,
            lwd: response.lwd,
            noticePeriod: response.noticePeriod,
            class10thPassingYr: response.class10thPassingYr,
            class10thPassingPercentage: response.class10thPassingPercentage || 0,
            class12thPassingYr: response.class12thPassingYr,
            class12thPassingPercentage: response.class12thPassingPercentage || 0,
            diplomaPassingYr: response.diplomaPassingYr,
            diplomaPassingPercentage: response.diplomaPassingPercentage || 0,
            graduationPassingYr: response.graduationPassingYr,
            graduationPassingPercentage: response.graduationPassingPercentage || 0,
            postGraduationPassingYr: response.postGraduationPassingYr,
            postGraduationPassingPercentage: response.postGraduationPassingPercentage || 0,
          },
          1: {
            documentName: response.documentName,
          },
        });
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsDetailsLoading(false);
      });
  };

  const handleAddCandidate = (values: any) => {
    setIsDetailsLoading(true);
    const formData = new FormData();
    const valuesData = getProcessFields(values);
    formData.append('candidate', JSON.stringify(valuesData));
    InterviewClient.addCandidate(formData)
      .then((response: any) => {
        // handleModalToggle();
        const result = response.data;
        showSnackbar(response, 'success');
        setSelectedCandidateUid(result.candidateUid);
        setTabValue(1);
        // updateFetchCandidateList();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  const handleUploadDocument = (values: any) => {
    setIsDetailsLoading(true);
    const file = values.documentFile;
    const formData = new FormData();
    formData.append('candidate', '{}');
    formData.append('resume', file);
    // handleModalClose();
    InterviewClient.updateCandidateDetails(selectedCandidateUid, formData)
      .then((res: any) => {
        updateFetch();
        showSnackbar(res, 'success');
        setTabValue(1);
        // updateFetch();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  useEffect(() => {
    getInterviewersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCandidateUid) {
      getCandidateDetailsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCandidateUid, fetch]);

  useEffect(() => {
    const roundOneDetails = detailsData?.interviewFeedback?.find(
      (feedback: any) => feedback?.interviewRoundName === 'PHONESCREEN',
    );
    setphoneScreenRoundData(roundOneDetails);
    const roundOneFormState = getRoundDetails(roundOneDetails, interviewersData);
    setFormState({
      ...formState,
      2: roundOneFormState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData]);

  const normalizeCandidateData = (candidateData: any) => {
    const fieldsToNormalize = [
      'class10thPassingPercentage',
      'class10thPassingYr',
      'yearsOfExperience',
      'relevantExperienceInYrs',
      'class12thPassingYr',
      'class12thPassingPercentage',
      'diplomaPassingYr',
      'diplomaPassingPercentage',
      'graduationPassingYr',
      'graduationPassingPercentage',
      'postGraduationPassingYr',
      'postGraduationPassingPercentage',
    ];

    const normalizedData = { ...candidateData };

    fieldsToNormalize.forEach((field) => {
      if (normalizedData[field] === '') {
        normalizedData[field] = 0;
      }
    });

    return normalizedData;
  };

  const handleEditCandidate = (values: any) => {
    setIsDetailsLoading(true);
    const candidateData = normalizeCandidateData(values);
    const formData = new FormData();
    formData.append('candidate', JSON.stringify(candidateData));
    InterviewClient.updateCandidateDetails(selectedCandidateUid, formData)
      .then((res) => {
        updateFetch();
        showSnackbar(res, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsDetailsLoading(false));
  };

  const handleSubmit = (values: any) => {
    const valuesData = {
      ...values,
      noticePeriod: values.noticePeriod?.value,
      currentState: values.currentState?.label,
      hasResigned: values.hasResigned?.value,
    };
    if (selectedCandidateUid) {
      handleEditCandidate(valuesData);
    } else {
      handleAddCandidate(valuesData);
    }
  };

  return (
    <>
      {isDetailsLoading && <ProgressSpinner showSpinner={isDetailsLoading} />}
      <Tabs className={styles.tabs} value={tabValue} onChange={handleTabChange}>
        <Tab label="General" />
        <Tab label="Documents" disabled={!selectedCandidateUid} />
        {!isEditMode && <Tab label="Phone Screening Schedule" disabled={!selectedCandidateUid} />}
      </Tabs>
      <TabPanel index={0} value={tabValue}>
        <AddCandidateForm
          handleSubmit={handleSubmit}
          formState={formState[0]}
          formRef={generalFormRef}
          isEdit={isEditMode}
        />
      </TabPanel>
      <TabPanel index={1} value={tabValue}>
        <AddDocumentForm
          handleSubmit={handleUploadDocument}
          formRef={resumeFormRef}
          formState={formState[1]}
        />
      </TabPanel>
      <TabPanel index={2} value={tabValue}>
        <ApplicationStatusLevelForm
          interviewRoundName={'PHONESCREEN'}
          candidateId={selectedCandidateUid}
          setIsDetailsLoading={setIsDetailsLoading}
          interviewersData={interviewersData}
          updateFetch={updateFetch}
          formState={formState[2]}
          formRef={interviewFormRef}
          isHRAccess={isHRAccess}
        />
      </TabPanel>
    </>
  );
};

AddCandidateDetails.defaultProps = {
  selectedCandidateId: '',
  isEditMode: false,
};

export default AddCandidateDetails;
