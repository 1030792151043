import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Link as SimpleLink,
  Step,
  StepButton,
  StepIcon,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useInterviewSideCArdStyles from './InterviewsideCardStyles';
import InterviewClient from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getFileInfo, steps } from './utils';

interface InterviewSideCardProps {
  detailsData: any;
  level: any;
  candidateUid: any;
}

const InterviewSideCard: React.FC<InterviewSideCardProps> = ({
  detailsData,
  level,
  candidateUid,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<number | null>();
  const { showSnackbar } = useDisplaySnackbar();
  const navigate = useNavigate();
  const styles = useInterviewSideCArdStyles();
  const [levelsFeedbackPresent, setLevelsFeedbackPresent] = useState<any>([]);

  const handleResumeDownload = (url: string, label: string, documentName: any) => {
    const resumeDownloadParams = {
      preSignedUrl: url,
    };
    InterviewClient.downloadResume(resumeDownloadParams)
      .then((res: any) => {
        const { mimeType, fileName, fileExtension } = getFileInfo(documentName, label);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));

        if (fileExtension === 'pdf') {
          link.target = '_blank';
        } else {
          link.download = fileName;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleCollapse = (index: number) => setCollapsed(index === collapsed ? null : index);

  const handleStepClick = (step: string, index: number) => {
    navigate(`/interview/details/${candidateUid}?selected_tab=${index}`);
  };
  useEffect(() => {
    if (detailsData) {
      const levelFeedbacks = detailsData?.interviewFeedback.map((v: any) => v?.interviewRoundName);
      const normalizedFeedbacks = levelFeedbacks.map((item: any) =>
        item === 'PHONESCREEN' ? 'ps' : item.toLowerCase(),
      );
      setLevelsFeedbackPresent(normalizedFeedbacks);
    }
  }, [detailsData]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}

      <Box className={styles.CardWrapper}>
        <Box className={styles.CardImageWrapper}>
          <Avatar
            alt={detailsData?.candidateDetails?.candidateName.toUpperCase()}
            // src={cardInfo.profileImage}
          />
        </Box>
        <Box className={styles.CardTitleWrapper}>
          <p className={`${styles.TitleText} ${styles.bold}`}>
            {detailsData?.candidateDetails?.candidateName}
          </p>
          <p className={styles.TitleText}>
            {detailsData?.candidateDetails?.candidateDesignation || '-'}
          </p>
          <p className={styles.TitleText}>
            {detailsData?.candidateDetails?.previousEmployer || '-'}
          </p>
          {detailsData?.candidateDetails?.resumePresignedUrl && (
            <SimpleLink
              component="button"
              className={styles.TitleLink}
              onClick={() => {
                handleResumeDownload(
                  detailsData?.candidateDetails?.resumePresignedUrl,
                  detailsData?.candidateDetails?.candidateName,
                  detailsData?.candidateDetails?.documentName,
                );
              }}>
              View resume
            </SimpleLink>
          )}
        </Box>
        <Box>
          <Stepper activeStep={level} alternativeLabel className={styles.stepLevel}>
            {steps.map((step, index) => (
              <Step
                disabled={!levelsFeedbackPresent?.includes(step.toLowerCase())}
                className={
                  levelsFeedbackPresent?.includes(step.toLowerCase()) ? 'Mui-completed' : ''
                }>
                <StepButton
                  className={styles.stepBold}
                  onClick={() => handleStepClick(step, index)}>
                  {step}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Divider variant="middle" light />
        </Box>

        <Box className={styles.CardFooterWrapper}>
          <List>
            <ListItemButton onClick={() => handleCollapse(1)}>
              <img
                className={
                  collapsed === 1 ? `${styles.Card_arrow_inverted}` : `${styles.Card_arrow}`
                }
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
                alt="arrow"
              />
              <ListItemText primary={'Personal Information'} />
            </ListItemButton>
            <Collapse in={collapsed === 1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Box className={styles.innerSectionWrapper}>
                  <Box className={styles.info}>
                    <Box className={`${styles.labelText} ${styles.bold}`}>Phone Number:</Box>
                    <Box className={styles.text}>
                      {detailsData?.candidateDetails?.candidatePhoneNumber}
                    </Box>
                  </Box>
                  <Box className={styles.info}>
                    <Box className={`${styles.labelText} ${styles.bold}`}>Email:</Box>
                    <Box className={styles.text}>{detailsData?.candidateDetails?.email}</Box>
                  </Box>
                  <Box className={styles.info}>
                    <Box className={`${styles.labelText} ${styles.bold}`}>Date Of Joining:</Box>
                    <Box className={styles.text}>
                      {detailsData?.candidateDetails?.dateOfJoining}
                    </Box>
                  </Box>
                </Box>
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default InterviewSideCard;
