import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleCheckboxField from '../../../components/SimpleCheckboxField/SimpleCheckboxField';

const AddSkill: React.FC<any> = ({ newSkill, setNewSkill, skillList }) => {
  const handleChange = (values: any): any => {
    setNewSkill(values);
  };

  return (
    <Formik initialValues={newSkill} onSubmit={() => {}} enableReinitialize>
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleAutocompleteField
                  name="skill"
                  label="Add a Skill"
                  variant="standard"
                  key="skill"
                  setFieldValue={setFieldValue}
                  options={skillList}
                  values={values}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleCheckboxField
                  name="isPrimary"
                  label="Is this your Primary Skill?"
                  checked={values.isPrimary}
                  key="isNominee"
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddSkill;
