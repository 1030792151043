/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Modal, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import VacanciesClient, { PATHS as VacancyPaths } from '../../api/Vacancies/vacanciesAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import {
  contactId,
  dynamicColumns,
  tableRowTotalField,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
  initialSort,
} from './vacanciesTableConfig';
import useAllVacanciesStyles from './AllVacanciesStyles';
import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import AddVacancyForm from './AddVacancyForm';
import SidePanel from '../../components/SidePanel/SidePanel';
import MasterClient from '../../api/Master/masterAPIs';
import { ApiOperations } from '../../utils/utils';
import MisDialog from '../../components/MisDialog/MisDialog';
import {
  addVacancyPayload,
  transformGetVacancyResponse,
  transformVacancyDataFromUpdateOperation,
  updateVacancyPayload,
  updatedRemarks,
} from './utils';

const AllVacancies = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const styles = useAllVacanciesStyles();
  const [vacancyId, setVacancyId] = useState('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [projectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [fetch, setFetch] = useState<number>(0);
  const [vacancyData, setVacancyData] = useState<any>(null);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const rowActions = [
    {
      label: 'Edit',
      logo: (
        <Tooltip title="Edit">
          <EditIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setVacancyId(params.row.uid);
        const vacancyDataPayload = transformVacancyDataFromUpdateOperation(params);
        setVacancyData(vacancyDataPayload);
        setIsEdit(true);
        setModalOpen(true);
      },
      path: VacancyPaths.PUT_VACANCY,
      operation: ApiOperations.PUT,
    },
    {
      label: 'Delete',
      logo: (
        <Tooltip title="Delete">
          <DeleteIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteCandidateId(params.row.id);
      },
      path: VacancyPaths.DELETE_VACANCY,
      operation: ApiOperations.DELETE,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, rowActions),
  );
  const [tableColumns, setTableColumns] = useState<any[]>(getColumns(rowActions));

  const generateDatatableInputs = () => {
    setIsLoading(true);
    VacanciesClient.getVacanciesList()
      .then((response: any) => {
        const transformedData = transformGetVacancyResponse(response.data);
        const rows = generateRows(transformedData, dynamicColumns, tableRowTotalField, contactId);
        setTableRows(rows);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedBulkRemarks = updatedRemarks(updatedRows);
    VacanciesClient.updateBulkRemarks(updatedBulkRemarks)
      .then((result: any) => {
        showSnackbar(result, 'success');
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setVacancyData(null);
    setIsEdit(false);
  };
  const handleSubmit = (input: any) => {
    setIsLoading(true);

    const transformPayload = isEdit
      ? updateVacancyPayload(input, AllSkills, customerList, projectList)
      : addVacancyPayload(input);

    const action = isEdit
      ? VacanciesClient.updateVacanciesDetails(vacancyId, transformPayload)
      : VacanciesClient.addVacancies(transformPayload);

    action
      .then((result: any) => {
        setModalOpen(false);
        showSnackbar(result, 'success');
        updateFetch();
        handleModalClose();
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchProjectsList = () => {
    VacanciesClient.getProjects()
      .then((result: any) => {
        const projects: any = Array.isArray(result.data)
          ? result.data.map((res: { uid: string; projectName: string }) => ({
              value: res.uid,
              label: res.projectName,
            }))
          : [];
        setProjectList(projects);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchCustomersList = () => {
    VacanciesClient.getCustomers()
      .then((result: any) => {
        const customers: any = Array.isArray(result.data)
          ? result.data.map((res: { uid: string; name: string }) => ({
              value: res.uid,
              label: res.name,
            }))
          : [];
        setCustomerList(customers);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const getSkillsList = () => {
    MasterClient.getSkills().then((result: any) => {
      const skills = Array.isArray(result.data)
        ? result.data.map((res: { uid: string; name: string }) => ({
            value: res.uid,
            label: res.name,
          }))
        : [];
      setAllSkills(skills);
    });
  };

  useEffect(() => {
    generateDatatableInputs();
  }, [fetch]);

  useEffect(() => {
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false) updatedTableCols.push(col);
    });

    setShowColumns(updatedTableCols);
  }, [columnVisibilityModel]);

  useEffect(() => {
    fetchProjectsList();
    fetchCustomersList();
    getSkillsList();
  }, []);

  const handleModalOpen = () => {
    setVacancyData({
      designation: vacancyData?.designation || '',
      jobDescription: vacancyData?.jobDescription || [],
      positions: vacancyData?.positions || null,
      priority: vacancyData?.priority || null,
      status: vacancyData?.status || null,
      location: vacancyData?.location || null,
      closureDate: vacancyData?.closureDate || null,
      projects: vacancyData?.projects || [],
      customers: vacancyData?.customers || [],
      remarks: vacancyData?.remarks || null,
      jobDescriptionDetail: vacancyData?.jobDescriptionDetail || null,
    });
    setModalOpen(true);
    setIsEdit(false);
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteCandidateId) {
      setIsLoading(true);
      VacanciesClient.deleteVacancy(deleteCandidateId)
        .then((result: any) => {
          showSnackbar(result, 'success');
          updateFetch();
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteCandidateId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteCandidateId('');
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.allVacanciesWrapper}>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.VACANCIES_TITLE,
            })}
          </span>
        </Box>
        <Box className={styles.ListWrapper}>
          <Datatable
            rows={tableRows}
            columns={tableColumns}
            columnVisibility
            editable
            updateRows={handleRowDataChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            rowCount={tableRows.length || 0}
            showExportButton
            exportedFileName={`Vacancies_${dayjs().format('DD_MMMM')}`}
            initialSort={initialSort as any}
          />
        </Box>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box className={styles.formContainer}>
            <SidePanel
              header={
                isEdit
                  ? intl.formatMessage({
                      id: I18nKey.EDIT_VACANCIES_TITLE,
                    })
                  : intl.formatMessage({
                      id: I18nKey.ADD_VACANCIES_TITLE,
                    })
              }
              onClose={handleModalClose}>
              <AddVacancyForm
                handleSubmit={handleSubmit}
                projectList={projectList}
                customersList={customerList}
                skillsList={AllSkills}
                vacancyData={vacancyData}
                isEdit={isEdit}
              />
            </SidePanel>
          </Box>
        </Modal>
        <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleModalOpen}>
          <AddIcon />
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.VACANCIES_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.DELETE_VACANCIES_TEXT,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

export default AllVacancies;
