import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import useChartStyles from './ChartStyles';
import ChartsClient from '../../../api/Charts/chartsAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { getFYDateRange } from '../../../utils/utils';

const currentFy = getFYDateRange(dayjs().subtract(1, 'year'));

const AppraisalChart: React.FC = () => {
  const styles = useChartStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState<any>({});
  const [pendingData, setPendingData] = useState<any>(null);
  const [closedData, setClosedData] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    const appraisalParams = {
      start_date: currentFy.startDate,
      end_date: currentFy.endDate,
    };
    ChartsClient.getAppraisalList(appraisalParams)
      .then((result) => {
        setList(result);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (list) {
      const pendingDataPercentage = (list.totalAppraisalsPending / list.totalAppraisals) * 100;
      setPendingData(pendingDataPercentage.toFixed(1));
      const closedDataPercentage = (list.totalAppraisalsClosed / list.totalAppraisals) * 100;
      setClosedData(closedDataPercentage.toFixed(1));
    }
  }, [list]);

  return isLoading ? (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Box>
        <Box className={styles.parentWrapper}>
          <Plot
            data={[
              {
                x: [list.totalAppraisalsPending],
                name: 'Pending',
                type: 'bar',
                orientation: 'h',
                marker: {
                  color: '#6CC0FF',
                },
                text: [`${pendingData}%`],
                hovertemplate: '%{text}',
                textposition: 'inside',
              },
              {
                x: [list.totalAppraisalsClosed],
                name: 'Completed',
                type: 'bar',
                orientation: 'h',
                marker: {
                  color: '#3780FF',
                },
                text: [`${closedData}%`],
                hovertemplate: '%{text}',
                textposition: 'inside',
              },
            ]}
            layout={{
              barmode: 'relative',
              autosize: true,
              width: 390,
              height: 223,
              margin: {
                l: 20,
                r: 0,
                b: 0,
                t: 0,
              },
              xaxis: {
                visible: false,
                showticklabels: false,
              },
              yaxis: {
                visible: false,
                showticklabels: false,
              },
              legend: {
                orientation: 'h',
                x: 0.3,
                y: 0,
              },
              showlegend: true,
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
export default AppraisalChart;
