import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useToDoStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '2px',
      marginBottom: '8px',
      marginTop: '-20px',
    },
    CardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      overflowY: 'auto',
      height: 'inherit',
    },
    CardContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      padding: '0.5rem',
      alignItems: 'center',
    },
    noContent: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      alignItems: 'center',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      overflowY: 'hidden',
      height: '100%',
    },
    CardHeader: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    HomePageCardHeader: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    AddButton: {
      padding: 'initial !important',
      color: `${theme.palette.text.light} !important`,
    },
    CardFieldsKey: { fontSize: '12px' },
    CardFieldsValue: { fontWeight: 'bold', fontSize: '16px' },
    LowerCardWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      marginTop: '1.5rem',
      position: 'relative',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      paddingBottom: '3.6rem',
    },
    ListItem: {
      display: 'grid',
      height: 'inherit',
    },
    lineWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
    },
    element: {
      fontSize: '14px',
      marginLeft: '-20px !important',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    deleteButton: {
      padding: 'initial !important',
      height: '20px !important',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noData: {
      textAlign: 'center',
      margin: '3.2rem !important',
      padding: '2.5rem',
      fontSize: '13px !important',
      alignSelf: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
    noDataProfile: {
      textAlign: 'center',
      padding: '2.5rem',
      fontSize: '13px !important',
      alignSelf: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      alignItems: 'center',
    },
  };
});

export default useToDoStyles;
