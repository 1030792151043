import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import MisDialog from '../../../components/MisDialog/MisDialog';
import MasterClient from '../../../api/Master/masterAPIs';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import EmployeeClient from '../../../api/Employee/employeeAPIs';
import I18nKey from '../../../translations/I18nKey';
import AddRelation from './AddRelationComponent';
import ModalPopup from '../../../components/ModalPopup/ModalPopup';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useRelationStyles from './RelationsListStyles';

const RelationsList: React.FC<any> = ({
  relationsList,
  refetchList,
  category,
  isLoading: fetchingData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useRelationStyles();
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { employeeUid } = useContext(GlobalContext);

  const [relationsConstantList, setRelationsConstantList] = useState<any>([]);
  const [deleteRelation, setDeleteRelation] = useState<any>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<any>(false);
  const [relationDetails, setRelationDetails] = useState<any>({});

  const getRelationsConstants = () => {
    setIsLoading(true);
    MasterClient.getAllEnums()
      .then((result: any) => {
        setRelationsConstantList(
          result.data.relation.map((relation: any) => ({ value: relation.code, ...relation })),
        );
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getRelationsConstants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRelation = () => {
    setOpenDialog(true);
  };

  const updateRelation = (rel: any) => {
    const relation = {
      ...rel,
      relation: relationsConstantList.find(
        (el: { value: string; code: string; label: string }) => el?.value === rel.relation,
      ),
    };

    if (relation) setRelationDetails(relation);
    setOpenDialog(true);
  };

  const deleteRelationById = (rel: any) => {
    setOpenDeleteDialog(true);
    setDeleteRelation(rel);
  };

  const handleDeleteRelationClose = () => {
    setOpenDeleteDialog(false);
    setDeleteRelation('');
  };

  const handleDeleteRelation = () => {
    if (employeeUid) {
      setIsLoading(true);
      EmployeeClient.deleteEmployeeRelation(deleteRelation.uid)
        .then((res) => {
          showSnackbar(res, 'success');
          refetchList();
          setOpenDeleteDialog(false);
          setDeleteRelation('');
        })
        .catch((e) => {
          setOpenDeleteDialog(false);
          showSnackbar(e, 'error');
          setDeleteRelation('');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setRelationDetails({});
  };

  return (
    <>
      <Box
        className={
          !fetchingData && relationsList.length === 0 ? classes.EmptyCard : classes.UpperCardWrapper
        }>
        <Box className={classes.UpperCardHeader}>
          <Typography fontWeight={600}>{`${
            category === 'nominee'
              ? intl.formatMessage({
                  id: I18nKey.PROFILE_NOMINEE_DETAILS,
                })
              : intl.formatMessage({
                  id: I18nKey.PROFILE_INSURANCEE_DETAILS,
                })
          }`}</Typography>
          <IconButton onClick={addRelation} className={classes.AddButton}>
            <AddIcon />
          </IconButton>
        </Box>
        {fetchingData || isLoading ? (
          <Box className={classes.spinnerWrapper}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box className={classes.UpperCardContent}>
            {relationsList.length ? (
              relationsList.map((i: any) => {
                return (
                  <Box className={classes.DetailsBox} key={i.uid}>
                    <Box className={classes.RelationCard}>
                      <Typography
                        className={classes.RelationName}
                        onClick={() => updateRelation(i)}>{`${i.firstName} ${
                        i.middleName ? i.middleName : ''
                      } ${i.lastName}`}</Typography>
                      <Box className={classes.RightSection}>
                        <Typography className={classes.RelationDate}>
                          {dayjs(i.dob).format('DD MMM YYYY')}
                        </Typography>
                        <DeleteIcon
                          className={classes.RelDelete}
                          onClick={() => deleteRelationById(i)}
                        />
                      </Box>
                    </Box>
                    <Typography className={classes.RelationRel}>{i.relation}</Typography>
                  </Box>
                );
              })
            ) : (
              <Typography className={classes.noData}>No Relation Added</Typography>
            )}
          </Box>
        )}
      </Box>
      <Modal open={openDialog} onClose={handleClose}>
        <Box>
          <ModalPopup
            header={
              category === 'nominee'
                ? intl.formatMessage({
                    id: I18nKey.PROFILE_NOMINEE_DETAILS,
                  })
                : intl.formatMessage({
                    id: I18nKey.PROFILE_INSURANCEE_DETAILS,
                  })
            }
            onClose={handleClose}>
            <AddRelation
              relationConstants={relationsConstantList}
              handleModalClose={handleClose}
              relationDetails={relationDetails}
              refetchList={refetchList}
              category={category}
            />
          </ModalPopup>
        </Box>
      </Modal>
      <MisDialog
        title={
          category === 'nominee'
            ? intl.formatMessage({
                id: I18nKey.PROFILE_NOMINEE_DETAILS,
              })
            : intl.formatMessage({
                id: I18nKey.PROFILE_INSURANCEE_DETAILS,
              })
        }
        message={intl.formatMessage({
          id: I18nKey.PROFILE_REMOVE_RELATION,
        })}
        isOpen={openDeleteDialog}
        handleSuccess={handleDeleteRelation}
        handleClose={handleDeleteRelationClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_REMOVE,
        })}
      />
    </>
  );
};

export default RelationsList;
