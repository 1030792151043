import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import SimpleAutocompleteField from '../../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { statusOptions } from '../utils';
import useInterviewDetailsStyles from '../InterviewDetailsStyles';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';

interface InterviewStatusFormProps {
  readonly initialField: any;
  readonly setInitialField: any;
  readonly setIsStatusFormValid: any;
  readonly statusFormRef: any;
}
const InterviewStatusForm: React.FC<InterviewStatusFormProps> = ({
  initialField,
  setInitialField,
  setIsStatusFormValid,
  statusFormRef,
}: any) => {
  const styles = useInterviewDetailsStyles();

  const handleChange = (values: any): any => {
    setIsStatusFormValid(!values.status);
  };

  const shareFormValidationSchema = Yup.object().shape({
    status: Yup.object()
      .shape({ label: Yup.string(), code: Yup.string() })
      .typeError('Status is required'),
  });

  return (
    <Formik
      initialValues={initialField}
      onSubmit={() => {}}
      validationSchema={shareFormValidationSchema}
      innerRef={statusFormRef}
      enableReinitialize>
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form className={styles.interviewStatusFormWrapper} onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleAutocompleteField
                  label="Status"
                  name="status"
                  size="small"
                  setFieldValue={setFieldValue}
                  options={statusOptions}
                  values={values}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleInputField
                  name="finalComments"
                  placeholder="Comments"
                  minRows={2}
                  multiline
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InterviewStatusForm;
