export interface GetInvoiceParams {
  project_uid?: string;
  start_date: string;
  end_date: string;
  invoice_no?: string | null;
  invoice_upload_type?: string;
}

export interface GetInvoiceStatusParams {
  readonly date: string;
}

export interface GenerateInvoiceParams {
  readonly startDate: string;
  readonly endDate: string;
}

export interface ShareInvoiceParams {
  readonly invoiceUid: string;
}

export interface InvoiceList {
  readonly id: string;
  readonly projectUid: string;
  readonly projectName: string;
  readonly invoiceNumber: number;
  readonly totalRevenue: number;
  readonly invoiceStatus: string;
}

export interface ProjectInfo {
  readonly projectUid: string;
  readonly projectName: string;
  readonly customerName: string;
  readonly invoiceNumber: number;
  readonly invoiceStatus: string;
  readonly noOfResources: number;
}

export interface ProjectResource {
  readonly employeeUid: string;
  readonly resourceName: string;
  readonly resourceType: string;
  readonly allocation: number;
  readonly billing: number;
  readonly includeInInvoice: boolean;
}

interface Details {
  amount: number;
  comment: string;
}

export interface OtherDetail {
  readonly uid: string;
  readonly projectUid: string;
  readonly heading: string;
  readonly details: Details[];
  readonly billingDate: string;
}

export interface InvoiceDetail {
  readonly id: string;
  readonly projectCustomerInfo: ProjectInfo;
  readonly resourceDetails: Array<ProjectResource>;
  readonly otherDetails: OtherDetail[];
  readonly manualInvoiceDetails: any;
}

export interface FormField {
  heading: string;
  amount: number;
  comment: string;
}

export interface CreateOtherDetailsParams {
  readonly project_uid: string;
  readonly date: string;
}

export interface InvoiceStatus {
  readonly status: string | null;
}

export enum InvoiceUploadType {
  manual = 'MANUAL',
  system = 'SYSTEM',
}
