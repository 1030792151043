import { useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ROUTES } from './RouterConfig';

const AppRouter = () => {
  const [licencedRoutesConfig] = useState<any>([
    {
      path: '/',
      element: <Navigate to="/workspace" replace />,
      module: '',
    },
    ...ROUTES,
  ]);

  return useRoutes(licencedRoutesConfig);
};

export default AppRouter;
