import { Box, Button } from '@mui/material';
import React, { useEffect, useState, useContext, useRef } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import AppraisalClient from '../../api/Appraisal/appraisalAPIs';
import {
  AppraisalDetails,
  AppraisalKRADetails,
  AppraisalStatus,
  AppraisalStatusList,
} from '../../api/Appraisal/appraisalTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../contexts/GlobalContext';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { hasSuperUserAccess } from '../../utils/utils';
import ResourceDetailsTable from './ResourceDetailsTable';
import useResourceDetailStyles from './ResourceDetailStyles';
import ResourceKRAFeedback from './ResourceKRAFeedback';
import useKraTemplateDetailsStyles from './KraTemplateDetailStyles';
import MisDialog from '../../components/MisDialog/MisDialog';

const ResourceDetails: React.FC<any> = ({
  empName,
  empUid,
  managerUid,
  managerName,
  appraisalStatus,
  isFormCompleted,
  isFormDirty,
  appraisalDetails,
  resourceDetailsTableInfo,
  feedbackDetails,
  allCategories,
  isManager,
  isEmployee,
  handleFormChange,
  date,
  updateFetch,
}: any) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [designationName, setDesignationName] = useState('');
  const { employeeUid, groupNames } = useContext(GlobalContext);
  const [isManagerSection, setIsManagerSection] = useState<boolean>(false);
  const [isEmployeeSection, setIsEmployeeSection] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAppraisalDialog, setOpenAppraisalDialog] = useState(false);
  const [oneOnOne, setOneOnOneValue] = useState<boolean>(false);
  const [disableOneonOneSection, setDisableOneonOneSection] = useState<boolean>(true);
  const [formState, setFormState] = useState({
    0: {
      kraDetails: [],
    },
    1: {
      employeeStrength: '',
      managerStrength: '',
      employeeAreaOfImp: '',
      managerAreaOfImp: '',
      employeeOverallFeedback: '',
      managerOverallFeedback: '',
      promotionComments: '',
      promotionRecommended: false,
      oneOnOne: false,
    },
  });
  const [formValidState, setFormValidState] = useState({
    0: { dirty: false, valid: false },
    1: { dirty: false, valid: false },
  });
  const [employeeAvgRating, setEmployeeAvgRating] = useState(0);
  const [managerAvgRating, setManagerAvgRating] = useState(0);

  const styles = useResourceDetailStyles();
  const buttonStyles = useKraTemplateDetailsStyles();
  const intl = useIntl();
  const params = useParams();
  const { showSnackbar } = useDisplaySnackbar();
  const kraFormRef = useRef<any>();
  const feedbackFormRef = useRef<any>();
  const location = useLocation();
  const navigate = useNavigate();

  const appraisalUid = params.id;

  const formRefDict: Record<number, any> = {
    0: kraFormRef,
    1: feedbackFormRef,
  };
  const queryParams = queryString.parse(location?.search);

  const [tabValue, setTabValue] = useState(Number(queryParams?.selected_tab) || 0);

  const checkManagerEmployeeSection = () => {
    if (
      isManager &&
      employeeUid !== empUid &&
      (appraisalStatus === AppraisalStatusList.EMPLOYEE_SUBMITTED ||
        appraisalStatus === AppraisalStatusList.MANAGER_SUBMITTED)
    ) {
      setIsManagerSection(true);
      setIsEmployeeSection(false);
    } else if (isEmployee && appraisalStatus === AppraisalStatusList.GOAL_LOCKED) {
      setIsManagerSection(false);
      setIsEmployeeSection(true);
    } else {
      setIsManagerSection(false);
      setIsEmployeeSection(false);
    }
  };

  const handleTabChange = (newValue: number) => {
    // setTabValues((fetchNum) => fetchNum + 1);
    if (formRefDict[tabValue]?.current?.values) {
      const newFormState: any = { ...formState };
      newFormState[tabValue] = formRefDict[tabValue]?.current?.values;
      setFormState(newFormState);
      const newFormValidState: any = { ...formValidState };
      newFormValidState[tabValue].dirty = formRefDict[tabValue]?.current?.dirty;
      newFormValidState[tabValue].valid = formRefDict[tabValue]?.current?.isValid;
      setFormValidState(newFormValidState);
    }
    formRefDict[newValue]?.current?.validateForm();
    setTabValue(newValue);
    // formRefDict[newValue]?.current?.isDirty = true;
  };

  const getKraDetails = (kra: any) => {
    if (isManagerSection) {
      const kraParams = kra?.map((items: any) => {
        return {
          uid: items.uid,
          rating: items.manager_rating,
          comment: items.manager_comment,
        };
      });
      return kraParams;
    }
    return kra?.map((items: any) => {
      return {
        uid: items.uid,
        rating: items.employee_rating,
        comment: items.employee_comment,
      };
    });
  };
  const getFeedbackDetails = (feedback: any) => {
    const {
      employeeAreaOfImp,
      employeeOverallFeedback,
      employeeStrength,
      managerAreaOfImp,
      managerOverallFeedback,
      managerStrength,
    } = feedback;
    if (isManagerSection) {
      return {
        strength: managerStrength,
        areas_of_improvement: managerAreaOfImp,
        overall_feedback: managerOverallFeedback,
      };
    }
    return {
      strength: employeeStrength,
      areas_of_improvement: employeeAreaOfImp,
      overall_feedback: employeeOverallFeedback,
    };
  };

  const getSaveParamsBySection = (
    kraDetail: any,
    feedBackDetail: any,
    otherfeedBackfields: any,
  ) => {
    if (isManagerSection) {
      return {
        kra: [...kraDetail],
        feedback: feedBackDetail,
        promotion_recommended: otherfeedBackfields.promotionRecommended,
        promotion_comments: otherfeedBackfields.promotionComments,
        one_on_one: otherfeedBackfields.oneOnOne,
      };
    }
    return {
      kra: [...kraDetail],
      feedback: feedBackDetail,
    };
  };

  const getSaveAppraisalParams = () => {
    if (tabValue === 1) {
      const kraDetail = getKraDetails(formState[0].kraDetails);
      const feedBackDetail = getFeedbackDetails(feedbackFormRef.current?.values);
      const saveParamsBySection = getSaveParamsBySection(
        kraDetail,
        feedBackDetail,
        feedbackFormRef.current?.values,
      );
      return saveParamsBySection;
    }
    const kraDetail = getKraDetails(kraFormRef.current?.values.kraDetails);
    const feedBackDetail = getFeedbackDetails(formState[1]);
    const saveParamsBySection = getSaveParamsBySection(kraDetail, feedBackDetail, formState[1]);
    return saveParamsBySection;
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpenAppraisalDialog = () => {
    setOpenAppraisalDialog(true);
  };
  const handleCloseAppraisalDialog = () => {
    setOpenAppraisalDialog(false);
  };

  const submitAppraisal = (saveAppraisalParams: any, isManagerSubmit: boolean) => {
    handleClose();
    setIsLoading(true);
    AppraisalClient.updateAppraisal(saveAppraisalParams, appraisalUid)
      .then((response: any) => {
        showSnackbar(response, 'success');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (isManagerSubmit) {
          setDisableOneonOneSection(false);
          updateFetch();
        } else {
          navigate(`/appraisal?startDate=${date.startDate}&endDate=${date.endDate}`);
        }
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSaveAppraisal = () => {
    const saveAppraisalParams = getSaveAppraisalParams();
    const appraisalParams = {
      ...saveAppraisalParams,
      employeeAverageRating: employeeAvgRating,
      managerAverageRating: managerAvgRating,
    };
    setDisableOneonOneSection(true);
    setIsLoading(true);
    AppraisalClient.updateAppraisal(appraisalParams, appraisalUid)
      .then((response: any) => {
        showSnackbar(response, 'success');
        updateFetch();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmitAppraisal = () => {
    const saveAppraisalParams = getSaveAppraisalParams();
    if (isManagerSection) {
      const submitAppraisalParams = {
        ...saveAppraisalParams,
        status: AppraisalStatus.MANAGER_SUBMITTED,
      };
      submitAppraisal(submitAppraisalParams, isManagerSection);
    } else {
      const submitAppraisalParams = {
        ...saveAppraisalParams,
        status: AppraisalStatus.EMPLOYEE_SUBMITTED,
      };
      submitAppraisal(submitAppraisalParams, isManagerSection);
    }
  };

  const handleCloseAppraisal = () => {
    setIsLoading(true);
    AppraisalClient.updateAppraisalStatus(appraisalUid, AppraisalStatus.COMPLETE)
      .then((result: any) => {
        showSnackbar(result, 'success');
        navigate(`/appraisal?startDate=${date.startDate}&endDate=${date.endDate}`);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (appraisalDetails) {
      setIsLoading(true);
      setStartDate(resourceDetailsTableInfo?.startDate);
      setEndDate(resourceDetailsTableInfo?.endDate);
      setDesignationName(resourceDetailsTableInfo?.designationName);
      setEmployeeAvgRating(resourceDetailsTableInfo?.employeeAvgRating);
      setManagerAvgRating(resourceDetailsTableInfo?.managerAvgRating);
      const newFormState: any = { ...formState };
      newFormState[1] = { ...feedbackDetails };
      newFormState[0] = { kraDetails: appraisalDetails };
      setFormState(newFormState);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalDetails]);

  useEffect(() => {
    checkManagerEmployeeSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalStatus]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.Header}>
        <span className="headerTitle">
          {intl.formatMessage({
            id: I18nKey.APPRAISAL_FYTITLE,
          })}
        </span>
        <Box>
          <Link to={`/appraisal?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}`}>
            {intl.formatMessage({
              id: I18nKey.APPRAISAL_TITLE,
            })}{' '}
          </Link>
          / {`${empName}`}
        </Box>
      </Box>
      <ResourceDetailsTable
        startDate={startDate}
        endDate={endDate}
        managerName={managerName}
        designationName={designationName}
        employeeAvgRating={employeeAvgRating}
        managerAvgRating={managerAvgRating}
      />
      <ResourceKRAFeedback
        isManagerSection={isManagerSection}
        isEmployeeSection={isEmployeeSection}
        appraisalUid={appraisalUid}
        formState={formState}
        feedbackFormRef={feedbackFormRef}
        kraFormRef={kraFormRef}
        updateFetch={updateFetch}
        handleTabChange={handleTabChange}
        appraisalStatus={appraisalStatus}
        setOneOnOneValue={setOneOnOneValue}
        oneOnOne={oneOnOne}
        handleFormChange={handleFormChange}
        isManager={isManager}
        isEmployee={isEmployee}
        isFormDirty={isFormDirty}
        disableOneonOneSection={disableOneonOneSection}
        setEmployeeAvgRating={setEmployeeAvgRating}
        setManagerAvgRating={setManagerAvgRating}
      />
      {appraisalStatus === AppraisalStatusList.MANAGER_SUBMITTED && (
        <Button
          type="submit"
          variant="outlined"
          disabled={!oneOnOne}
          onClick={handleOpenAppraisalDialog}
          className={buttonStyles.saveBtn}>
          Close Appraisal
        </Button>
      )}
      {!oneOnOne && (
        <>
          <Button
            type="submit"
            variant="outlined"
            disabled={
              // eslint-disable-next-line no-nested-ternary
              (isManager
                ? empUid === employeeUid
                  ? !isEmployeeSection
                  : !isManagerSection
                : !isEmployeeSection) ||
              !isFormCompleted ||
              isFormDirty
            }
            onClick={handleOpenDialog}
            className={buttonStyles.saveBtn}>
            Submit Appraisal
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={
              // eslint-disable-next-line no-nested-ternary
              isManager
                ? empUid === employeeUid
                  ? !isEmployeeSection
                  : !isManagerSection
                : !isEmployeeSection
            }
            onClick={handleSaveAppraisal}
            className={buttonStyles.saveBtn}>
            Save
          </Button>
        </>
      )}
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.APPRAISAL_TITLE,
        })}
        message={intl.formatMessage({
          id: isEmployee
            ? I18nKey.APPRAISAL_SUBMIT_EMPLOYEE_CONFIRMATION
            : I18nKey.APPRAISAL_SUBMIT_CONFIRMATION,
        })}
        isOpen={openDialog}
        handleSuccess={handleSubmitAppraisal}
        handleClose={handleClose}
        actionBtnLabel="Submit Appraisal"
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.APPRAISAL_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.APPRAISAL_CLOSE_CONFIRMATION,
        })}
        isOpen={openAppraisalDialog}
        handleSuccess={handleCloseAppraisal}
        handleClose={handleCloseAppraisalDialog}
        actionBtnLabel="Close Appraisal"
      />
    </>
  );
};

export default ResourceDetails;
