import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useEmpDocTableStyles = makeStyles(() => {
  return {
    empDocTablesWrapper: {
      '& .emp-doc': {
        display: 'flex',
        alignSelf: 'center',
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    addButton: {
      position: 'absolute',
      right: 13,
      bottom: 35,
      '& svg': {
        fontSize: '40px',
      },
    },
    categorySelect: {
      paddingBottom: '10px !important',
    },
    gridContainer: { padding: 8 },
    gridItemWrapper: { whiteSpace: 'nowrap', minWidth: 125 },
    gridItem: {
      padding: 12,
      borderRadius: '8px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    uploadDateText: { fontSize: '0.7rem !important', paddingTop: 1 },
    docCount: { fontSize: '0.9rem', paddingTop: 0.6 },
    docName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.8rem !important',
    },
    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(0deg)`,
        transform: `rotate(0deg)`,
        fontSize: '35px',
      },
    },
    noData: {
      margin: 'auto !important',
      padding: '2rem',
      fontSize: '13px !important',
    },
  };
});

export default useEmpDocTableStyles;
