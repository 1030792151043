import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useAllContractsStyles from '../AllContractsStyles';

interface EntityTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

interface TabsComponentProps {
  readonly tabs: EntityTabs[];
  readonly selected?: number | null;
}

const ContractButtons: React.FC<TabsComponentProps> = ({ tabs, selected }) => {
  const [currentTab, setCurrentTab] = useState(selected || 0);
  const styles = useAllContractsStyles();

  useEffect(() => setCurrentTab(selected || 0), [selected]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className={styles.tabs}>
        {tabs.map((tab: any, index: number) => {
          return (
            <Tab
              disabled={!tab.length}
              key={tab.label}
              label={`${tab.label} ${tab.length ? `(${tab.length})` : `(0)`}`}
              {...a11yProps(index)}
              className={`tabBtn`}
              sx={{ borderTop: `2px solid ${tab.color}`, opacity: `${!tab.length ? '0.7' : ''}` }}
            />
          );
        })}
      </Tabs>
      {tabs.map((tab: any, index: number) => {
        return (
          <Box
            key={tab.label}
            role="tabpanel"
            hidden={currentTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}>
            {currentTab === index && <Box>{tab.component}</Box>}
          </Box>
        );
      })}
    </>
  );
};

ContractButtons.defaultProps = {
  selected: 0,
};

export default ContractButtons;
