import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import {
  GetProjectParams,
  ProjectDetail,
  GetProjectResourceParams,
  ProjectResource,
  UpdateProjectResourceParams,
  AccountType,
  DeleteProjectResourceParams,
  DeleteProjectParams,
} from './projectTypes';

export const PATHS = Object.freeze({
  GET_PROJECT: '/project',
  GET_PROJECT_DETAIL: (project_id: string) => `/project/${project_id}`,
  GET_PROJECT_RESOURCES: (project_id: string) => `/project/${project_id}/employee`,
  GET_ACCOUNT_TYPES: '/project/account/types',
  PUT_PROJECT_RESOURCES: (project_id: string) => `/project/${project_id}/employee`,
  DELETE_PROJECT_RESOURCE: (project_id: string, employee_id: string) =>
    `/project/${project_id}/employee/${employee_id}`,
  GET_EMAIL_RECEIPENTS_BY_PROJECTID: (project_id: string) =>
    `/invoice/${project_id}/project/recipients`,
});

const getProjectList = async (getProjectParams: GetProjectParams): Promise<ProjectDetail[]> => {
  const queryParams = queryString.stringify(getProjectParams);

  const response = await axiosInstance.get(`${PATHS.GET_PROJECT}/?${queryParams}`);
  return response.data;
};

const getProjectDetails = async (getProjectParams: GetProjectParams): Promise<ProjectDetail> => {
  const response = await axiosInstance.get(
    `${PATHS.GET_PROJECT_DETAIL(getProjectParams.project_id)}`,
  );
  return response.data[0];
};

const getProjectResources = async (
  getProjectResourceParams: GetProjectResourceParams,
): Promise<ProjectResource[]> => {
  const queryParams = queryString.stringify(getProjectResourceParams);

  const response = await axiosInstance.get(
    `${PATHS.GET_PROJECT_RESOURCES(getProjectResourceParams.project_id)}?${queryParams}`,
  );
  return response.data;
};

const getAccountTypes = async (): Promise<AccountType[]> => {
  const response = await axiosInstance.get(PATHS.GET_ACCOUNT_TYPES);
  return response.data;
};

const updateProjectResources = async (
  project_id: string,
  updateProjectResourceParams: UpdateProjectResourceParams[],
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.PUT_PROJECT_RESOURCES(project_id)}`,
    updateProjectResourceParams,
  );
  return response;
};

const deleteProjectResource = async (
  project_id: string,
  employee_id: string,
  deleteProjectResourceParams: DeleteProjectResourceParams,
): Promise<any> => {
  const response = await axiosInstance.delete(
    `${PATHS.DELETE_PROJECT_RESOURCE(project_id, employee_id)}`,
    { data: deleteProjectResourceParams },
  );
  return response.data;
};

const updateProjectInfo = async (
  project_id: string,
  updateProjectInfoParams: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.GET_PROJECT_DETAIL(project_id)}`,
    updateProjectInfoParams,
  );
  return response;
};

const deleteProjectInfo = async (
  projectId: string,
  deleteOpportunityParams: DeleteProjectParams,
): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.GET_PROJECT_DETAIL(projectId)}`, {
    data: deleteOpportunityParams,
  });
  return response;
};

const getEmailRecipientsByProjectId = async (project_id: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_EMAIL_RECEIPENTS_BY_PROJECTID(project_id));
  return response.data;
};

export interface ProjectAPIClient {
  readonly getProjectList: (getProjectParams: GetProjectParams) => Promise<ProjectDetail[]>;
  readonly getProjectDetails: (getProjectParams: GetProjectParams) => Promise<ProjectDetail>;
  readonly getProjectResources: (
    getProjectResourceParams: GetProjectResourceParams,
  ) => Promise<ProjectResource[]>;
  readonly getAccountTypes: () => Promise<AccountType[]>;
  readonly updateProjectResources: (
    project_id: string,
    updateProjectResourceParams: UpdateProjectResourceParams[],
  ) => Promise<any>;
  readonly deleteProjectResource: (
    project_id: string,
    employee_id: string,
    deleteProjectResourceParams: DeleteProjectResourceParams,
  ) => Promise<any>;
  readonly updateProjectInfo: (project_id: string, updateProjectInfoParams: any) => Promise<any>;
  readonly deleteProjectInfo: (
    projectId: string,
    deleteOpportunityParams: DeleteProjectParams,
  ) => Promise<any>;
  readonly getEmailRecipientsByProjectId: (project_id: string) => Promise<any>;
}

const ProjectClient: ProjectAPIClient = Object.freeze({
  getProjectList,
  getProjectDetails,
  getProjectResources,
  getAccountTypes,
  updateProjectResources,
  deleteProjectResource,
  updateProjectInfo,
  deleteProjectInfo,
  getEmailRecipientsByProjectId,
});

export default ProjectClient;
