/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

import { generateRows } from '../../components/Datatable/datatableUtils';
import InterviewClient, { PATHS as CandidatePaths } from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import NoCandidate from '../../assets/icons/NoCandidate.png';
import useAllInterviewStyles from './AllInterviewStyles';
import MisDialog from '../../components/MisDialog/MisDialog';
import { filterRows } from '../../components/CustomFilter/FilterUtils';
import CustomizeFilter from '../../components/CustomFilter/CustomizeFilter';
import { ApiOperations } from '../../utils/utils';
import {
  candidateUid,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
} from './AllInterviewListTableConfig';

interface AllInterviewListProps {
  readonly selectedList: string;
  readonly isHRAccess: boolean;
  readonly selectedTab: string;
  readonly fetchCandidateList: number;
  readonly updateFetchCandidateList: any;
}

const AllInterviewList: React.FC<AllInterviewListProps> = ({
  selectedList,
  isHRAccess,
  selectedTab,
  fetchCandidateList,
  updateFetchCandidateList,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [allRows, setAllRows] = useState<any[]>([]); // To store the unfiltered data
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [filterBoxVisible, setFilterBoxVisible] = useState(true);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAllInterviewStyles();

  const columnInterviewForFilterList = [
    { value: 'name', type: 'string', label: 'Name' },
    { value: 'currentState', type: 'string', label: 'Current State' },
    { value: 'yearsOfExperience', type: 'number', label: 'Experience' },
    { value: 'skills', type: 'string', label: 'Skill' },
    { value: 'status', type: 'string', label: 'Status' },
    { value: 'noticePeriod', type: 'string', label: 'Category' },
    { value: 'dateOfJoining', type: 'date', label: 'DOJ' },
    { value: 'remarks', type: 'string', label: 'Remarks' },
    { value: 'scheduledDate', type: 'date', label: 'Last Updated on' },
  ];
  const rowActions = [
    {
      label: 'Delete',
      logo: (
        <Tooltip title="Delete">
          <DeleteIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteCandidateId(params.id);
      },
      path: CandidatePaths.ADD_CANDIDATE,
      operation: ApiOperations.DELETE,
    },
    {
      label: 'View Details',
      logo: (
        <Tooltip title="View Details">
          <ArrowOutwardIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        window.open(`/interview/details/${params.id}?selected_tab=0`, '_blank');
      },
    },
  ];

  const filteredRowActions = isHRAccess ? rowActions : [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel(
      { showColumns },
      selectedList,
      selectedTab,
      isHRAccess,
      filteredRowActions,
    ),
  );

  const [tableColumns] = useState<any[]>(
    getColumns(selectedList, selectedTab, isHRAccess, filteredRowActions),
  );

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], candidateUid);
    return rows;
  };

  const [initialValues, setInitialValues] = useState({
    filters: [{ columnName: '', operatorName: '', values: [''] }],
  });
  const [filteredData, setFilteredData] = useState<any>(null);

  const generateDatatableInputs = () => {
    setIsLoading(true);
    InterviewClient.getAllIntervieweesList()
      .then((response: any) => {
        const rows = getTableRows(response);
        setTableRows(rows);
        setAllRows(rows); // Store the unfiltered data
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleClearAllFilter = () => {
    setInitialValues({
      filters: [{ columnName: '', operatorName: '', values: [''] }],
    });
    setFilteredData(null);
    setTableRows(allRows);
    setFilterBoxVisible(true);
  };

  useEffect(() => {
    generateDatatableInputs();
  }, [fetchCandidateList]);

  useEffect(() => {
    if (filteredData !== null) {
      const filteredRows = filterRows(allRows, filteredData, columnInterviewForFilterList); // Use allRows for filtering
      setTableRows(filteredRows);
    }
  }, [filteredData]);

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteCandidateId) {
      setIsLoading(true);
      InterviewClient.deleteCandidate(deleteCandidateId)
        .then((result) => {
          updateFetchCandidateList();
          showSnackbar(result, 'success');
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteCandidateId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteCandidateId('');
  };

  const handleSubmit = (v: any) => {
    setInitialValues(v);
    const transformedData: any = {
      advanced_filters: {},
    };
    v.filters.forEach((filter: any) => {
      const columnName = filter.columnName.value;
      const operations = filter.operatorName.value;
      const { values } = filter;
      if (values.length > 0) {
        if (!transformedData.advanced_filters[columnName]) {
          transformedData.advanced_filters[columnName] = [];
        }
        const existingEntry = transformedData.advanced_filters[columnName]?.find(
          (entry: any) => entry.operation === operations,
        );
        if (existingEntry) {
          existingEntry.data = existingEntry.data.concat(values);
        } else {
          transformedData.advanced_filters[columnName].push({
            data: values,
            operation: operations,
          });
        }
      }
    });
    setFilteredData(transformedData.advanced_filters);
    setFilterBoxVisible(true);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      {tableRows.length === 0 && !filteredData ? (
        <Box>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={NoCandidate}
              alt="No Interviews Found"
              className={styles.image}
            />
          </Box>
          <Box className={styles.messageWrapper}>
            <Box className={styles.message}>No interviews found</Box>
          </Box>
        </Box>
      ) : (
        <>
          {filterBoxVisible && (
            <Box>
              <CustomizeFilter
                initialValues={initialValues}
                columnNameList={columnInterviewForFilterList}
                handleSubmit={handleSubmit}
                style={styles.filterButtonWrapper}
                handleClearAllFilter={handleClearAllFilter}
              />
            </Box>
          )}
          {tableRows.length === 0 && filteredData ? (
            <Box>
              <Box className={styles.imageWrapper}>
                <Box
                  component="img"
                  src={NoCandidate}
                  alt="No Interviews Found"
                  className={styles.image}
                />
              </Box>
              <Box className={styles.messageWrapper}>
                <Box className={styles.message}>No interviews found</Box>
              </Box>
            </Box>
          ) : (
            <Datatable
              rows={tableRows}
              columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
              columnVisibility={true}
              tableHeight="70vh"
              initialSort={initialSort as any}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={onColumnVisibilityModelChange}
              showExportButton
              showFilterButton={false}
              exportedFileName={`Candidate_List_${dayjs().format('DD_MMMM')}`}
            />
          )}
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
            })}
            message="Are you sure you want to delete this Candidate?"
            isOpen={openDialog}
            handleSuccess={handleDelete}
            handleClose={handleClose}
            actionBtnLabel="Delete"
          />
        </>
      )}
    </>
  );
};

export default AllInterviewList;
