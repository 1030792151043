import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllAuditLogsStyles = makeStyles((theme: Theme) => {
  return {
    auditLogsWrapper: {
      padding: '1rem',
    },
    Header: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },

      '& .poc': {
        padding: '2px 8px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',

        '& a': {
          color: 'black',
        },
      },
      '& .poc-cell': {
        flexWrap: 'wrap',
      },
    },
    contentWrapper: {
      display: 'flex',
    },
  };
});

export default useAllAuditLogsStyles;
