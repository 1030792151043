import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useDocumentStyles = makeStyles((theme: Theme) => {
  return {
    generateDocWrapper: {
      padding: '1rem',
    },
    HeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    templateBtn: {
      float: 'right',
      margin: '0px 10px !important',
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    allContentWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      position: 'relative',
      '& .sharedInfoText': {
        position: 'absolute',
        right: '26.7rem',
        top: 15,
        zIndex: 2,
      },
    },
    detailPageWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      marginBottom: '20px',
    },
    Header: {
      fontSize: '24px',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    ContentWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '8px',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    formField: {
      '& input': {
        height: '18px',
      },
    },
    itemWrapper: {
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    routeItem: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.midDark,
      borderRadius: 8,
      border: '1px solid #EBEBEB',
    },
    DetailPageHeader: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    Table: {
      width: '100%',
      border: `1px solid ${theme.palette.tertiary?.main}`,
      marginTop: '2rem',
      marginLeft: '12px',
      borderRadius: '8px',
    },
    Column: {
      borderBottom: '1px solid #dddddd',
      borderRight: '1px solid #f1f1f1',
      textAlign: 'left',
      padding: '7px',
      fontWeight: 'normal',
    },
    TableHeader: {
      borderBottom: '1px solid #dddddd',
      borderRight: '1px solid #f1f1f1',
      textAlign: 'left',
      padding: '10px',
    },
    LastColumn: {
      borderRight: 'none',
    },
    TotalColumn: {
      padding: '7px',
    },
    InfoColumn: {
      width: '40%',
      fontWeight: '500',
      margin: '1rem',
      padding: '7px',
    },
    ValuesColumn: {
      width: '30%',
    },
    SubmitBtn: {
      float: 'right',
      marginTop: '1rem !important',
      marginLeft: '20px !important',
    },
    ShareBtn: {
      float: 'right',
      marginTop: '1rem !important',
    },
    LinkWrapper: {
      fontSize: '14px',
      fontWeight: 'bold',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
  };
});

export default useDocumentStyles;
