import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import useDocumentStyles from './DocumentStyles';
import I18nKey from '../../translations/I18nKey';
import OnBoardingList from '../OnBoardingManagement/OnBoardingList';
import AllEmployeesList from './AllEmployeesList';

const AllEmployees: React.FC<any> = () => {
  const styles = useDocumentStyles();
  const intl = useIntl();

  return (
    <Box className={styles.generateDocWrapper}>
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.EMPLOYEE_TITLE,
          })}
        </span>
        <Box className={styles.LinkWrapper}>
          <Link to="/document">
            {intl.formatMessage({
              id: I18nKey.GENERATE_DOCUMENT_TITLE,
            })}
          </Link>{' '}
          /{' '}
          {intl.formatMessage({
            id: I18nKey.COMPENSATION_LETTER,
          })}
        </Box>
      </Box>
      <Box className={styles.ContentWrapper}>
        <AllEmployeesList />
      </Box>
    </Box>
  );
};

export default AllEmployees;
