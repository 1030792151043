import dayjs from 'dayjs';
import adaptiveWorkCulture from '../../assets/icons/policy/adaptiveWorkCulture.svg';
import codeOfConduct from '../../assets/icons/policy/codeOfConduct.svg';
import employeeProvidentFund from '../../assets/icons/policy/employeeProvidentFund.svg';
import employeeWages from '../../assets/icons/policy/employeeWages.svg';
import employmentManual from '../../assets/icons/policy/employmentManual.svg';
import employmentTermination from '../../assets/icons/policy/employmentTermination.svg';
import harassmentWorkplace from '../../assets/icons/policy/harassmentWorkplace.svg';
import leavePolicy from '../../assets/icons/policy/leavePolicy.svg';
import paternityMaternityLeave from '../../assets/icons/policy/paternityMaternityLeave.svg';

export const Policies: { name: string; icon: any }[] = [
  {
    name: 'CSR Policy (Corporate Social Responsibility Policy)',
    icon: adaptiveWorkCulture,
  },
  {
    name: 'Code of Conduct',
    icon: codeOfConduct,
  },
  {
    name: 'Company Asset Policy (Laptop Policy)',
    icon: employeeProvidentFund,
  },
  {
    name: 'Performance Improvement Plan',
    icon: employeeWages,
  },
  {
    name: 'Performance Appraisal Policy',
    icon: employmentManual,
  },
  {
    name: 'Expense Reimbursement Policy',
    icon: employmentTermination,
  },
  {
    name: 'Travel Policy',
    icon: harassmentWorkplace,
  },
  {
    name: 'Leave Policy',
    icon: leavePolicy,
  },
  {
    name: 'Medi-claim Policy',
    icon: paternityMaternityLeave,
  },
];

export const formatPolicyData = (values: any) => {
  const { name, updatedOn, policyUid, url } = values;
  return {
    label: name,
    updatedOn: dayjs(updatedOn).format('DD/MM/YYYY'),
    policyUid,
    url,
  };
};
