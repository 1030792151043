import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useProfileStyles = makeStyles((theme: Theme) => {
  return {
    PageWrapper: {
      marginTop: '0.5rem',
      padding: '1rem',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    Header: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    MidSectionContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    todoListWrapper: {
      height: '25vh',
    },
    MyTeamWrapper: {
      marginBottom: '0.5rem',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      color: theme.palette.text.midDark,
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      height: '100%',
    },
    tree_root: {
      padding: '0.5rem',
      paddingBottom: '2rem',

      '& .rd3t-link': {
        stroke: '#939393',
      },
      '& .rd3t-node': {
        stroke: 'none',
        strokeWidth: 0,
      },
      '& .rd3t-leaf-node': {
        stroke: 'none',
        strokeWidth: 0,
      },
    },
    AvatarWrapper: {
      position: 'relative',
    },
    TeamHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    TeamInfoWrapper: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      paddingTop: '5px',
      fontSize: 10,
      top: '0px',
      left: '45px',
    },
    boxSpace: {
      paddingTop: '0.5rem',
    },
    EmpHierarchyWrapper: {
      height: 'calc(100vh - 190px)',
      backgroundColor: 'white',
      borderRadius: '8px',
    },
    CurrentUser: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: 'white !important',
    },
    cardWrapper: {
      border: '1px solid rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      backgroundColor: '#fff',
      width: '210px',
      height: '70px',
      color: theme.palette.text.midDark,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      fontSize: '10px',
    },
    upperCardBody: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 0 5px 0',
      width: '100%',
    },
    upperCardBody_nameWrapper: {
      flexDirection: 'column',
    },
    upperCardBody_name: {
      fontSize: '12px',
      fontWeight: 'bold',
      width: '150px',
      marginBottom: '3px',
      marginTop: '10px !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    upperCardBody_desgnation: {
      marginTop: '0px',
      width: '150px',
      fontStyle: 'italic',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cardFloatingBox: {
      backgroundColor: '#DFF4FF',
      height: '24px',
      width: '130px',
      position: 'absolute',
      top: '60px',
      fontSize: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      color: theme.palette.secondary.main,
    },
    smallFloatingBox: {
      width: '100px',
    },
    cardFloatingBox_text: {
      marginRight: '3px',
      fontWeight: 'bold',
      fontSize: '10px !important',
    },
    cardFloatingBox_arrow: {
      marginLeft: '10px',
    },
    cardFloatingBox_arrow_inverted: {
      transform: 'rotate(180deg);',
    },
    upperCardBody_image: {
      height: '45px',
      width: '45px',
      margin: '15px 0',
      borderRadius: '50%',
    },
    upperCardBody_img_placeholder: {
      fontSize: '18px',
      fontWeight: 'bold',
      width: '45px',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.tertiary?.main,
      margin: '5px 5px 0',
      borderRadius: '50%',
    },
    upperCardBody_img_placeholder_current: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
  };
});

export default useProfileStyles;
