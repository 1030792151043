import { Link } from 'react-router-dom';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { dateFromatter } from '../../utils/utils';

const dynamicColumns: any[] = [];
const tableRowTotalField: any = [];
const initialSort = { field: 'appraisalUid', sort: 'desc' };

export enum AppraisalStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export const getAppraisalStatusIcons = (status: string) => {
  switch (status) {
    case AppraisalStatus.PENDING:
      return (
        <Tooltip title="Pending">
          <ErrorOutlineIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    case AppraisalStatus.COMPLETED:
      return (
        <Tooltip title="Completed">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    default:
      return null;
  }
};

const getAppraisalOneOnOneIcons = (status: boolean) => {
  if (status) {
    return (
      <Tooltip title="Completed">
        <TaskAltIcon sx={{ color: 'green' }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Pending">
      <ErrorOutlineIcon sx={{ color: 'orange' }} />
    </Tooltip>
  );
};

const getColumns: any = (startDate: string, endDate: string, rowActions: any) => {
  return [
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Resource',
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={`/appraisal/kraDetails/${params.row.appraisalUid}?startDate=${startDate}&endDate=${endDate}`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    {
      field: 'departmentName',
      type: 'string',
      headerName: 'Department',
      flex: 1,
      sortable: true,
      hide: true,
    },
    {
      field: 'managerName',
      type: 'string',
      headerName: 'Manager',
      flex: 1,
      sortable: true,
    },
    {
      field: 'doj',
      type: 'string',
      valueFormatter: (params: any) => dateFromatter(params.value),
      headerName: 'DOJ',
      flex: 0.5,
      sortable: true,
      editable: false,
    },
    // {
    //   field: 'appraisalDueDate',
    //   type: 'string',
    //   headerName: 'Due Date',
    //   flex: 0.5,
    //   sortable: true,
    //   valueFormatter: (params: any) => dateFromatter(params.value),
    // },
    {
      field: 'appraisalStatus',
      headerName: 'Appraisal Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      hideable: false,
      filterable: false,
    },
    {
      field: 'appraisalOneOnOneDone',
      type: 'string',
      headerName: '1-1 Interaction',
      flex: 0.8,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      hideable: false,
      filterable: false,
      renderCell: ({ value }: GridRenderCellParams) => getAppraisalOneOnOneIcons(value),
    },
    {
      field: 'projects',
      type: 'string',
      headerName: 'Project(s)',
      flex: 1,
      sortable: true,
    },
    {
      field: 'remarks',
      type: 'string',
      headerName: 'Remarks',
      flex: 1,
      editable: true,
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      flex: 0.5,
      width: 10,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action: any) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
};

export const getFirstLastName = (params: any) => {
  const { firstName, lastName } = params;
  return firstName.concat(' ', lastName);
};

const getProcessFields = (allFields: any) => {
  const requiredFields = { ...allFields };
  const processFields = { ...requiredFields };
  Object.keys(requiredFields).forEach((field: any) => {
    if (allFields[field] === '' || allFields[field] === null) delete processFields[field];
  });
  return processFields;
};

const appraisalId = 'appraisalUid';

export {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  getProcessFields,
  appraisalId,
};
