/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import SidePanel from '../../components/SidePanel/SidePanel';
import I18nKey from '../../translations/I18nKey';
import AddCustomerForm from './AddCustomerForm';
import AddContactForm from './AddContactForm';
import AddOpportunityForm from './AddOpportunityForm';
import AddAccountForm from './AddAccountForm';
import { PATHS as customerRelationsPaths } from '../../api/Opportunity/opportunityAPIs';
import { ApiOperations } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAddEntitiesStyles from './AddEntitiesStyles';

interface AddEntitiesProps {
  onCreateOpportunity: Function;
}

const AddEntities: React.FC<AddEntitiesProps> = ({ onCreateOpportunity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const styles = useAddEntitiesStyles();
  const intl = useIntl();
  const { checkAccess } = useContext(GlobalContext);

  const handleModalClose = () => setSelectedOption(null);
  const handleAddClick = () => setIsOpen(!isOpen);
  const handleOptionClick = (option: number) => {
    setIsOpen(false);
    setSelectedOption(option);
  };

  const formProps = {
    handleModalClose,
    onCreateOpportunity,
  };

  const options = [
    {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_OPPORTUNITY,
      }),
      icon: <BusinessCenterOutlinedIcon />,
      component: <AddOpportunityForm {...formProps} />,
      path: customerRelationsPaths.CRM_OPPORTUNITY,
      operation: ApiOperations.POST,
    },
    {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_ACCOUNT,
      }),
      icon: <AccountCircleOutlinedIcon />,
      component: <AddAccountForm {...formProps} />,
      path: customerRelationsPaths.CRM_ACCOUNT,
      operation: ApiOperations.POST,
    },
    {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
      }),
      icon: <PersonAddAltIcon />,
      component: <AddCustomerForm {...formProps} />,
      path: customerRelationsPaths.CRM_CUSTOMER,
      operation: ApiOperations.POST,
    },
    {
      title: intl.formatMessage({
        id: I18nKey.CUSTOMER_RELATION_CONTACT,
      }),
      icon: <ContactsOutlinedIcon />,
      component: <AddContactForm {...formProps} />,
      path: customerRelationsPaths.CRM_CONTACT,
      operation: ApiOperations.POST,
    },
  ];

  return (
    <Box className={styles.fabContainer}>
      <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleAddClick}>
        <Box className={isOpen ? `${styles.inverted}` : ''}>
          <AddIcon />
        </Box>
      </Box>
      {isOpen ? (
        <ul className={styles.options}>
          {options
            .filter(({ path, operation }) => checkAccess(path, operation))
            .map((item, index) => (
              <li key={item.title + item.icon}>
                <span className={styles.btnLabel}>{item.title}</span>
                <div className={styles.iconbutton} onClick={() => handleOptionClick(index)}>
                  {item.icon}
                </div>
              </li>
            ))}
        </ul>
      ) : null}
      <Modal open={selectedOption !== null} onClose={handleModalClose}>
        <Box>
          <SidePanel header={options[Number(selectedOption)].title} onClose={handleModalClose}>
            <div className={styles.formContainer}>{options[Number(selectedOption)].component}</div>
          </SidePanel>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddEntities;
