import axiosInstance from '../axiosInstance';
import { GetEmployeeDetailParams } from './searchTypes';

export const PATHS = Object.freeze({
  EMPLOYEE: `/employee/active/all`,
  PROJECT: `/project/search`,
  EMPLOYEE_DETAIL: `/employee/details`,
});

const getAllEmpList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.EMPLOYEE}`);
  return response;
};

const getAllProjectList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROJECT}`);
  return response;
};

const getEmployeeDetail = async (
  getEmployeeDetailsparams: GetEmployeeDetailParams,
): Promise<any> => {
  const response = await axiosInstance.get(PATHS.EMPLOYEE_DETAIL, {
    params: getEmployeeDetailsparams,
  });
  return response;
};
export interface SearchAPIClient {
  readonly getAllEmpList: () => Promise<any>;
  readonly getEmployeeDetail: (params: GetEmployeeDetailParams) => Promise<any>;
  readonly getAllProjectList: () => Promise<any>;
}
const SearchClient: SearchAPIClient = Object.freeze({
  getAllEmpList,
  getEmployeeDetail,
  getAllProjectList,
});

export default SearchClient;
