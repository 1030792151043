import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useInterviewDetailsStyles = makeStyles((theme: Theme) => {
  return {
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '10px',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    detailWrapper: {
      padding: '1rem',
    },
    detailWrapperBox: {
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      height: '49px',
      marginTop: '-14px',
      background: theme.palette.background.default,
    },
    detailWrapperBoxSecond: {
      borderRadius: '12px',
      boxShadow: `0px 0px 3px ${theme.palette.shadow?.main}`,
      height: '65px',
      background: theme.palette.background.default,
    },
    TitleText: {
      textAlign: 'left',
      paddingTop: '14px',
      paddingLeft: '20px',
      color: theme.palette.secondary.main,
      textTransform: 'capitalize',
    },
    bold: {
      fontSize: '16px !important',
      fontWeight: 'bold !important',
    },
    detailWrapperBoxThird: {
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      minHeight: '425px',
      background: theme.palette.background.default,
    },
    saveButton: {
      marginRight: '10px !important',
      float: 'right',
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginBottom: '20px',
    },
    stepLevel: {
      paddingTop: '10px',
      marginBottom: '5px !important',
      '& .MuiStepIcon-root': {
        fontSize: '18px !important',
      },
      '& .MuiStepIcon-root.Mui-completed': {
        color: `${theme.palette.status?.midDark} !important`,
      },
    },
    stepBold: {
      '& .MuiStepLabel-alternativeLabel': {
        fontSize: '14px !important',
        fontWeight: 'bold !important',
        marginTop: '2px !important',
      },
    },
    tab: {
      '& .MuiTab-root': {
        width: '130px !important',
        textTransform: 'none !important',
      },
    },
    interviewStatusFormWrapper: {
      marginTop: '-30px',
    },
    skillFeedbackWrapperBox: {
      borderRadius: '8px',
      wordWrap: 'break-word',
    },
    title: {
      color: '#BDBDBD',
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      padding: '5px 2px 0px 1px !important',
    },
    skillRating: {
      fontSize: '14px !important',
      color: ' #173F74',
      fontWeight: 'bold !important',
      padding: '2px 0px 0px 1px !important',
    },
    skillComment: {
      fontSize: '14px !important',
      padding: '2px 0px 2px 1px !important',
      whiteSpace: 'pre-wrap',
    },
    FeedbackWrapperBox: {
      borderRadius: '8px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      height: 'auto',
      border: '1px solid #ddd;',
      paddingLeft: '3px',
      paddingRight: '3px',
    },
    detailWrapperDisabled: {
      borderRadius: '12px',
      boxShadow: `0px 0px 3px ${theme.palette.shadow?.main}`,
      height: '75px',
      background: theme.palette.background.default,
    },
    accordion: {
      border: '1px solid #ddd',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      borderRadius: '8px !important',

      '& .MuiAccordionSummary-content': {
        color: `${theme.palette.text.disabled} !important`,
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
        paddingBottom: '4px',
        paddingRight: '10px',
      },
      '& .makeStyles-header-18': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        marginBottom: '-30px',
        marginTop: '-15px',
      },
    },
    FeedbackDetailsWrapper: {
      borderRadius: '12px',
      boxShadow: `0px 0px 3px ${theme.palette.shadow?.main}`,
      background: theme.palette.background.default,
      '& .MuiGrid-root': {
        marginTop: '0px !important',
      },
    },
    boxWrapper: {
      marginRight: '10px',
      marginLeft: '10px',
    },
    attributeTable: {
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      marginBottom: '15px',
      borderRadius: '12px',
      '& .MuiTableCell-root': {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    tableHead: {
      fontSize: '14px !important',
    },
    icon: {
      color: 'green !important',
    },
    attributeName: {
      color: '#173F74 !important',
      fontSize: '14px !important',
      fontWeight: 'bold !important',
    },
    disableDetails: {
      paddingLeft: '10px',
      paddingTop: '5px',
    },
    dateTimeWrapper: {
      '& .MuiOutlinedInput-root': {
        height: '40px !important',
      },
    },
    Rating: {
      fontSize: '14px !important',
      color: ' #173F74',
      fontWeight: 'bold !important',
      padding: '2px 0px 0px 14px !important',
    },
    Comment: {
      fontSize: '14px !important',
      padding: '0px 2px 5px 14px !important',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    feedbackComment: {
      fontSize: '14px !important',
      padding: '5px 2px 5px 14px !important',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    LogicalTitle: {
      color: '#BDBDBD',
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      padding: '5px 2px 0px 14px !important',
    },
  };
});

export default useInterviewDetailsStyles;
