import { Box, Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { SearchOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useAllSearchStyles from './AllSearchStyles';

interface SearchInfoProps {
  readonly initialField: any;
  readonly handleSubmit: any;
  readonly allOptionList: any;
}

const SearchInfo: React.FC<SearchInfoProps> = ({
  initialField,
  handleSubmit,
  allOptionList,
}: any) => {
  const styles = useAllSearchStyles();

  const validationSchema = Yup.object().shape({
    search: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .typeError('Search field is required'),
  });

  return (
    <Box className={styles.searchWrapper}>
      <Formik
        initialValues={initialField}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {(formik) => {
          const { setFieldValue, dirty, isValid, values } = formik;
          return (
            <>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Box className={styles.Searchbox}>
                      <SimpleAutocompleteField
                        name="search"
                        key="search"
                        placeholder="Search"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={allOptionList}
                        values={values}
                        freeSolo
                        required
                        autoFocus
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      className={styles.RoundedBtn}
                      type="submit"
                      variant="contained"
                      disabled={!dirty || !isValid}>
                      <SearchOutlined />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SearchInfo;
