import { AppBar, Avatar, Box, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { googleLogout } from '@react-oauth/google';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo.png';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../contexts/GlobalContext';
import useNewCandidateHeaderStyles from './HeaderStyles';
import { clearNewCandidateAttributes, getNewCandidateAttributes } from '../../utils/auth';

const NewCandidateHeader: React.FC<{ name: string; picture: string }> = ({ name, picture }) => {
  const [isLogoutInProgress, setIsLogoutInProgress] = useState(false);
  const classes = useNewCandidateHeaderStyles();
  const { setIsAuthenticated } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLogoutInProgress(true);
    googleLogout();
    setIsLogoutInProgress(false);
    const token = getNewCandidateAttributes();
    clearNewCandidateAttributes();
    navigate(`/onBoarding/candidate/${token}`);
  };

  return (
    <AppBar className={classes.appBar} component="nav">
      <Toolbar disableGutters>
        <Grid container spacing={4}>
          <Grid item md={6} lg={6} xs={6}>
            <Box className={classes.logoArea}>
              <Typography className={classes.text} fontSize={20}>
                Me@
              </Typography>
              <Box component="img" className={classes.image} src={logo} alt="Tech Prescient" />
            </Box>
          </Grid>
          <Grid display="flex" item md={6} lg={6} xs={6}>
            <Grid container justifyContent="flex-end">
              <Box>
                <Button
                  size="large"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  className={classes.headerBtn}
                  onClick={handleClick}>
                  <Avatar alt={name.toUpperCase()} src={picture} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {isLogoutInProgress && <ProgressSpinner showSpinner={isLogoutInProgress} />}
    </AppBar>
  );
};

export default NewCandidateHeader;
