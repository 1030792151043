import React from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { SnackbarProvider, SnackbarOrigin } from 'notistack';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LanguageContextProvider } from './contexts/LanguageContext';
import './index.css';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const defaultSnackBarOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

root.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000} anchorOrigin={defaultSnackBarOrigin}>
        <App />
      </SnackbarProvider>
    </LanguageContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
