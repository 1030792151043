import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import Datatable from '../../components/Datatable/Datatable';
import MisDialog from '../../components/MisDialog/MisDialog';
import {
  getColumns,
  candidateUid,
  dynamicColumns,
  tableRowTotalField,
  initialSort,
  initialShowCols,
  getInitialVisibilityModel,
} from './CandidateListConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import I18nKey from '../../translations/I18nKey';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { ApiOperations } from '../../utils/utils';
import DocumentClient, { PATHS as CandidatePaths } from '../../api/Document/DocumentAPI';

const CandidateList: React.FC = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);

  const [deletecandidateUid, setDeleteCandidateUid] = useState('');
  const [fetch, setFetch] = useState(0);
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const rowActions = [
    {
      label: 'Download',
      logo: (
        <Tooltip title="Download">
          <DownloadIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        const downloadLetterParams = {
          preSignedUrl: params.row.url,
        };
        DocumentClient.downloadDocument(downloadLetterParams)
          .then((res) => {
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(
              new Blob([res.data], { type: 'application/pdf' }),
            );
            link.download = params.row.name;
            link.click();
          })
          .catch((e) => showSnackbar(e, 'error'));
      },
    },
    {
      label: 'Delete Candidate',
      logo: (
        <Tooltip title="Remove Candidate">
          <DeleteIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setDeleteDialogOpen(true);
        setDeleteCandidateUid(params.id);
      },
      path: CandidatePaths.CANDIDATE_LIST,
      operation: ApiOperations.DELETE,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, rowActions),
  );

  const handleDelete = () => {
    setDeleteDialogOpen(false);
    if (deletecandidateUid) {
      setLoading(true);
      DocumentClient.deleteCandidate(deletecandidateUid)
        .then((result: any) => {
          updateFetch();
          showSnackbar(result, 'success');
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    }
    setDeleteCandidateUid('');
  };
  const handleClose = () => {
    setDeleteDialogOpen(false);
  };
  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, dynamicColumns, tableRowTotalField, candidateUid);
  };
  const handleRowDataChange = (updatedRows: any[]) => {
    setLoading(true);
    const updatedStatus = updatedRows.map((r) => {
      return { uid: r.row.uid, status: r.row.status };
    });
    DocumentClient.updateCandidateStaus(updatedStatus)
      .then((result) => {
        showSnackbar(result, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    DocumentClient.getCandidateList()
      .then((response: any) => {
        const rows = getTableRows(response.data);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Datatable
        columns={getColumns(rowActions)}
        rows={tableRows}
        /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        editable={true}
        updateRows={handleRowDataChange}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.OFFER_LETTER,
        })}
        message={intl.formatMessage({
          id: I18nKey.REMOVE_CANDIDATE,
        })}
        isOpen={deleteDialogOpen}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel={'Delete'}
      />
    </>
  );
};

export default CandidateList;
