import dayjs from 'dayjs';
import * as Yup from 'yup';

const currentDate = dayjs().format('YYYY-MM-DD');

const basicDetailFormValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required').min(2, 'Min 2 Characters expected'),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required('Last Name is required'),
  gender: Yup.object()
    .shape({ label: Yup.string(), code: Yup.string() })
    .typeError('Gender is required'),
  bloodGroup: Yup.object().shape({ label: Yup.string(), code: Yup.string() }).nullable(),
  phone: Yup.string().required('Phone number is required'),
  dob: Yup.string().required('Date of Birth is required'),
  nationality: Yup.string().required('Nationality is required'),
  personalEmail: Yup.string().email('Invalid email').required('Personal Email is required'),
  linkedinUrl: Yup.string().url('Invalid URL').required('LinkedIn URL is required'),
});

const personalDetailFormValidation = Yup.object().shape({
  pan: Yup.string().required('PAN is required').length(10, 'PAN should be 10 Characters'),
  aadhaar: Yup.string()
    .required('Aadhaar no is required')
    .length(12, 'Aadhar should be 12 Characters'),
  uan: Yup.string().nullable(),
  bankIfscCode: Yup.string().nullable().max(11, 'IFSC code should be at most 11 Characters'),
  bankAccountNo: Yup.string().nullable(),
  bankName: Yup.string().nullable(),
  isSameAddress: Yup.boolean(),
  currentAddress: Yup.string()
    .required('Current Address is required')
    .min(5, 'Min 5 Characters expected'),
  currentCity: Yup.string().required('Current City is required'),
  currentPincode: Yup.string().required('Current Pincode is required'),
  currentState: Yup.string().required('Current State is required'),
  currentCountry: Yup.string().required('Current Country is required'),
  permanentAddress: Yup.string()
    .required('Permanent Address is required')
    .min(5, 'Min 5 Characters expected'),
  permanentCity: Yup.string().required('Permanent City is required'),
  permanentState: Yup.string().required('Permanent State is required'),
  permanentPincode: Yup.string().required('Permanent Pincode is required'),
  permanentCountry: Yup.string().required('Permanent Country is required'),
});

const emergencyContactValidationSchema = Yup.object().shape({
  emergencyContacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string().required('Phone number is required'),
      relation: Yup.object()
        .shape({ label: Yup.string(), code: Yup.string() })
        .typeError('Relation is required'),
    }),
  ),
  relationData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      dob: Yup.string().required('Date of Birth is required'),
      isNominee: Yup.boolean().nullable(),
      relation: Yup.object()
        .shape({ label: Yup.string(), code: Yup.string() })
        .typeError('Relation is required'),
      isDependant: Yup.boolean().nullable(),
      nomineePercent: Yup.number().when('isNominee', {
        is: true,
        then: Yup.number().min(1, 'Mininmum 1% is required').typeError('Mininmum 1% is required'),
        otherwise: Yup.number().nullable(),
      }),
    }),
  ),
});

const exitDetailsFormValidationSchema = Yup.object().shape({
  dateOfResignation: Yup.string().nullable(),
  proposedExitDate: Yup.string().nullable(),
  exitReason: Yup.object().shape({
    details: Yup.string().nullable(),
  }),
  exitRemarks: Yup.string().nullable(),
});

const deleteFormValidationSchema = Yup.object().shape({
  dos: Yup.date().required('Date of Separation is required'),
  exitRemarks: Yup.string().nullable(),
});

const removeSpecialChars = (str: string) =>
  str
    .toString()
    .toLowerCase()
    .replace(/[^a-z]/g, '');

export {
  basicDetailFormValidation,
  personalDetailFormValidation,
  emergencyContactValidationSchema,
  exitDetailsFormValidationSchema,
  deleteFormValidationSchema,
  removeSpecialChars,
};
