import { Button, Grid, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useIntl } from 'react-intl';
import DocumentClient from '../../../api/Document/DocumentAPI';
import I18nKey from '../../../translations/I18nKey';
import ProgressSpinner from '../../../components/ProgressSpinner/ProgressSpinner';
import useDisplaySnackbar from '../../../utils/useDisplaySnackbar';
import { Email, capitalizeFirstLetter } from '../../../utils/utils';
import useDocumentStyles from '../DocumentStyles';
import MisDialog from '../../../components/MisDialog/MisDialog';
import { ShareOfferLetterParams } from '../../../api/Document/DocumentTypes';
import {
  formatIntershipLetterData,
  formatOfferLetterData,
  handleCustomInputChange,
  internshipLetterFormValidation,
} from '../utils';
import CandidateShareForm from '../CandidateShareForm';

const InternshipLetterForm: React.FC<any> = ({
  candidateDetails,
  candidateId,
  updateFetch,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [initialField, setInitialField] = useState({
    to: '',
    cc: '',
    subject: '',
    message: '',
  });
  const [isShareFormValid, setIsShareFormValid] = useState(true);
  const [formValues, setFormValues] = useState<any>({});
  const styles = useDocumentStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const navigate = useNavigate();
  const shareFormRef = useRef<any>();
  const formRef = useRef<any>();

  const handleShareClick = (values: any) => {
    setFormValues(values);
    setShareDialogOpen(true);
  };
  const handleShare = () => {
    setIsLoading(true);
    const ccAddress = shareFormRef.current?.values.cc.split(';');
    const modifiedCcAddress = ccAddress.map((address: string, index: number) => {
      if (index === 0) {
        return address; // Keep the first element unchanged
      }
      return address.trim(); // Remove initial space from other elements
    });
    const shareInternshipLetterParams: ShareOfferLetterParams = {
      toAddr: [shareFormRef.current?.values.to],
      ccAddr: modifiedCcAddress,
      emailSubject: shareFormRef.current?.values.subject,
      emailBody: shareFormRef.current?.values.message,
    };
    setShareDialogOpen(false);
    DocumentClient.shareInternshipLetter(candidateId, shareInternshipLetterParams)
      .then((result: any) => {
        updateFetch();
        showSnackbar(result, 'success');
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setShareDialogOpen(false);
    setIsShareFormValid(true);
  };

  const CustomInputField = ({
    name,
    handleChange,
    handleBlur,
    setFieldValue,
    externalHandleChange,
    externalhandleBlur,
    ...formFileds
  }: any) => {
    const fieldProperties: any = {
      name,
      ...formFileds,
    };

    const [field, mata] = useField(name);

    const handleInputChange = (e: any) => {
      handleChange(e);
      externalHandleChange(name, e.target.value, setFieldValue);
    };

    const handleInputBlur = (e: any) => {
      handleBlur(e);
      if (typeof externalhandleBlur === 'function') externalhandleBlur();
    };

    if (mata && mata.touched && mata.error) {
      fieldProperties.error = true;
      fieldProperties.helperText = <ErrorMessage name={name} />;
    }

    return (
      <Field
        as={TextField}
        {...field}
        {...fieldProperties}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    );
  };

  const downloadDocument = (
    GenerateResponse: { data: { fileName: string; filePath: any } },
    downloadInvoiceParams: any,
  ) => {
    return DocumentClient.downloadDocument(downloadInvoiceParams)
      .then((res) => {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        link.download = GenerateResponse.data.fileName;
        link.click();
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const generateInternshipLetterData = {
      ...values,
    };
    const internshipParams = {
      candidate_uid: candidateId,
    };
    if (candidateId) {
      DocumentClient.generateInternshipLetter(internshipParams, generateInternshipLetterData)
        .then(async (GenerateResponse) => {
          const downloadInvoiceParams = {
            preSignedUrl: GenerateResponse.data.url,
          };
          downloadDocument(GenerateResponse, downloadInvoiceParams);
          showSnackbar(GenerateResponse, 'success');
          navigate(`/document/internshipLetter`);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    } else {
      DocumentClient.generateNewInternshipLetter(
        formatIntershipLetterData(generateInternshipLetterData),
      )
        .then(async (GenerateResponse) => {
          const downloadInvoiceParams = {
            preSignedUrl: GenerateResponse.data.url,
          };
          downloadDocument(GenerateResponse, downloadInvoiceParams);
          showSnackbar(GenerateResponse, 'success');
          navigate(`/document/internshipLetter`);
        })
        .catch((e) => {
          showSnackbar(e, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (candidateDetails) {
      const capitalizedCandidateName = capitalizeFirstLetter(candidateDetails.name);
      setInitialField({
        to: candidateDetails.email,
        cc: `${Email.ADMIN};  ${Email.HRDEPARTMENT}`,
        subject: intl.formatMessage({
          id: I18nKey.INTERNSHIP_SUBJECT,
        }),
        message: `Dear ${capitalizedCandidateName},\n\n${intl.formatMessage({
          id: I18nKey.INTERNSHIP_MESSAGE_BODY,
        })}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Formik
        initialValues={candidateDetails}
        onSubmit={handleSubmit}
        validationSchema={internshipLetterFormValidation}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const {
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
            errors,
            dirty,
            isValid,
          } = formik;
          return (
            <Form>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="name"
                    type="string"
                    label="Name"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiTelInput
                    fullWidth
                    defaultCountry="IN"
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                    inputProps={{ style: { fontSize: 15 } }}
                    size="small"
                    value={values.phoneNumber}
                    onChange={(newValue, info) => {
                      setFieldValue('phoneNumber', info.numberValue);
                    }}
                    forceCallingCode
                    splitCallingCode
                    focusOnSelectCountry
                    required
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={
                      touched.phoneNumber && errors.phoneNumber ? (errors.phoneNumber as any) : null
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="addressFirstLine"
                    type="string"
                    label="Address Line 1"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="addressSecondLine"
                    type="string"
                    label="Address Line 2"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="dateOfJoining"
                    type="date"
                    label="Date Of Joining"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="endDate"
                    type="date"
                    label="End Date"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="designation"
                    type="text"
                    label="Designation"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomInputField
                    className={styles.formField}
                    inputProps={{ style: { fontSize: 15 } }}
                    name="stipend"
                    type="number"
                    label="Stipend(in INR)"
                    size="small"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    externalHandleChange={handleCustomInputChange}
                    required
                  />
                </Grid>
              </Grid>
              <Button
                className={styles.SubmitBtn}
                disabled={!dirty || !isValid}
                variant="contained"
                type="submit">
                Generate Document
              </Button>
              {candidateId && (
                <Button
                  className={styles.ShareBtn}
                  variant="contained"
                  disabled={!isValid}
                  onClick={() => handleShareClick(values)}
                  endIcon={<ArrowOutwardIcon />}>
                  <Typography variant="button" component="span">
                    Share
                  </Typography>
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.OFFER_LETTER,
        })}
        message={intl.formatMessage({
          id: I18nKey.SHARE_OFFERLETTER,
        })}
        width="lg"
        isOpen={shareDialogOpen}
        handleSuccess={handleShare}
        handleClose={handleClose}
        actionBtnLabel={'Share'}
        disableSubmitBtn={isShareFormValid}
        additionalInfoSection={
          <CandidateShareForm
            initialField={initialField}
            setInitialField={setInitialField}
            setIsShareFormValid={setIsShareFormValid}
            shareFormRef={shareFormRef}
          />
        }
      />
    </>
  );
};

export default InternshipLetterForm;
