import React from 'react';
import { Grid, Box, Typography, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import documentLogo from '../../assets/icons/document.png';
import useEmpDocTableStyles from './EmpDocTableStyle';

interface EmpDocContentProps {
  rows: any;
}

const EmpDocContent: React.FC<EmpDocContentProps> = ({ rows }) => {
  const styles = useEmpDocTableStyles();

  return (
    <Box className={styles.gridContainer}>
      <Grid container spacing={1}>
        {rows.length ? (
          rows.map((row: any, i: number) => (
            <Grid item xs={4} sm={3} md={2} className={styles.gridItemWrapper}>
              <Box className={styles.gridItem}>
                <Box component="img" src={documentLogo} alt="document" />
                <Typography className={styles.docCount}>{`Document ${i + 1}`}</Typography>
                <Tooltip title={row.documentName}>
                  <Typography className={styles.docName}>{row.documentName}</Typography>
                </Tooltip>
                <Typography className={styles.uploadDateText}>
                  {dayjs(row.uploadedOn).format('MMM DD')}
                </Typography>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography className={styles.noData}>No Document Added</Typography>
        )}
      </Grid>
    </Box>
  );
};

EmpDocContent.defaultProps = {};

export default EmpDocContent;
