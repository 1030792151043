import { makeStyles } from '@mui/styles';
import theme from '../../theme';

export default makeStyles(() => {
  return {
    tabs: {
      '& .MuiTab-root': {
        width: '200px',
        textTransform: 'capitalize',
      },
      '& .MuiTab-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.lighter,
      },
    },
  };
});
