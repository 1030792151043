import { getFullName } from '../../utils/utils';

export interface TicketDetails {
  assigned_to: { id: number; name: string };
  attachments: [];
  category: { name: string; description: string; id: number };
  completed_at: string;
  created_at: string;
  description: string;
  id: number;
  priority: string;
  status: string;
  time_taken: number;
  title: string;
  user: { id: number; name: string };
  uid: string;
}

export enum TicketStatus {
  OPEN = 'open',
  INPROGRESS = 'in_progress',
  RESOLVED = 'resolved',
  BLOCKED = 'blocked',
}

export enum TicketLength {
  OPEN = 'open',
  INPROGRESS = 'in_progress',
  RESOLVED = 'resolved',
  BLOCKED = 'blocked',
}

export const categoryFormatData = (values: any) =>
  values.map((el: any) => ({
    label: el.name,
    value: el.uid,
  }));

export const formatEmployeeList = (values: any) =>
  values.map((el: any) => ({
    label: getFullName(el),
    value: el.uid,
  }));

export const priority = [
  { value: 'low', label: 'Low' },
  { value: 'normal', label: 'Normal' },
  { value: 'high', label: 'High' },
  { value: 'urgent', label: 'Urgent' },
];

export interface Ticket {
  title: string;
  description: string;
  category: any;
  priority: any;
  assignee: any;
  dueAt: any;
  files: any;
  uid?: any;
  comments?: any;
}

export enum PriorityColor {
  HIGH = 'high',
  NORMAL = 'normal',
  LOW = 'low',
  URGENT = 'urgent',
}

export type TicketList = {
  id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  raisedBy: string;
  createdAt: string;
  completedAt: string;
  file: [string];
  category: string;
  assignee: string;
  updated_at: string;
};

export interface Props {
  setLocalTickets: any;
  categoryData: any;
  assigneeData: any;
}
export interface Category {
  uid: string | number | readonly string[] | undefined;
  description: string;
  name: string;
  id: number;
  label: string;
  value: string | number | readonly string[] | undefined;
}
export interface User {
  uid: string | number | readonly string[] | undefined;
  firstName: any;
  id: number;
  name: string;
  label: string;
  value: string | number | readonly string[] | undefined;
}

export interface Params {
  priority: string;
  category_uid: string;
  start_date: string;
  end_date: string;
  query: string;
  assignee_uid: string;
  reporter_uid: string;
}

export enum Assignee {
  HELPDESK = 'Naresh Khaladkar',
  HR = 'Ishika Agrawal',
  Admin = 'Ashwini Damle',
}

export enum CategoryTypes {
  HARDWARE = 'Hardware',
  PAYROLL = 'Payroll',
  HR = 'HR',
  MEDICAL = 'Medical Insurance',
  WELCOMEKIT = 'Welcome Kit',
  LAPTOP = 'Laptop Allocation/Deallocation',
  IDCARD = 'ID Card Printing',
  ASSET = 'Asset collection',
  OATSTAX = 'OATS - Tax Queries',
  OATSPASSWORD = 'OATS - Password Reset',
  OATSMANAGER = 'OATS - Manager Change',
  GOOGLEPASSWORD = 'Google - Password Reset',
  TRAVEL = 'Travel',
  ACCOMMODATION = 'Accommodation',
}
