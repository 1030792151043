/* eslint-disable prefer-template */
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

export default makeStyles(() => {
  return {
    CardWrapper: {
      position: 'relative',
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: ' 0px 0px 4px ' + theme.palette.shadow?.main,
      borderRadius: '12px',
      color: theme.palette.secondary.main,
      background: theme.palette.background.default,

      '& .editBtn': {
        position: 'absolute',
        right: '0',
      },
    },
    CardImageWrapper: {
      backgroundColor: theme.palette.popUpBackground?.light,
      borderRadius: '12px 12px 0px 0px',
      minHeight: '137px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '80px',

      '& .MuiAvatar-root': {
        width: '120px',
        height: '120px',
        marginBottom: '-130px',
      },
    },
    CardTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
    },
    TitleText: {
      marginBottom: '0',
      marginTop: '8px',
      textTransform: 'capitalize',
      textAlign: 'center',
      padding: '0 3px 0px 3px',
    },
    bold: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    CardFooterWrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'flex-end',
      overflow: 'auto',

      '& ul': {
        width: '100%',
      },
    },
    Card_arrow: {
      marginRight: '10px',
      transform: 'rotate(90deg)',
    },
    Card_arrow_inverted: {
      marginRight: '10px',
      transform: 'rotate(180deg)',
    },
    text: {
      color: theme.palette.text.midDark,
      fontSize: '12px',
      marginLeft: '3px',
    },
    labelText: {
      color: theme.palette.text.midDark,
      fontSize: '12px',
      marginLeft: '3px',
      whiteSpace: 'nowrap',
    },
    email: {
      color: theme.palette.text.light,
      fontSize: '12px',
      marginLeft: '3px',
    },
    pocInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '18px',
    },
    pocInfo: {
      display: 'flex',
    },
  };
});
