/* eslint-disable react-hooks/rules-of-hooks */
import { GridCellParams, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const dynamicColumns: any[] = [];
const tableRowTotalField: any = [];
const initialSort = { field: 'employeeId', sort: 'desc' };

const getOfferLetterStatusIcons = (status: boolean) => {
  if (status) {
    return (
      <Tooltip title="Shared">
        <TaskAltIcon sx={{ color: 'green' }} style={{ marginLeft: '28%' }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Not Shared">
      <ErrorOutlineIcon sx={{ color: 'orange' }} style={{ marginLeft: '28%' }} />
    </Tooltip>
  );
};

const getColumns = (rowActions: any[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setExpanded] = useState(true);
  const handleExpanded = (rowId: any) => {
    setExpanded(!isExpanded);
  };
  return [
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/document/offerLetter/${params.row.uid}?letter=offerLetter`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    {
      field: 'email',
      type: 'string',
      headerName: 'Email',
      flex: 1.5,
      sortable: true,
    },
    {
      field: 'designation',
      type: 'string',
      headerName: 'Designation',
      flex: 1.5,
      sortable: true,
    },
    {
      field: 'phoneNumber',
      type: 'string',
      headerName: 'Phone Number',
      flex: 1,
      sortable: true,
    },
    {
      field: 'status',
      type: 'string',
      headerName: 'Status',
      flex: 1,
      sortable: true,
      hideable: false,
      editable: true,
    },
    {
      field: 'emailShared',
      type: 'string',
      headerName: 'OfferLetter Status',
      flex: 1,
      sortable: false,
      hideable: false,
      renderCell: ({ value }: GridRenderCellParams) => getOfferLetterStatusIcons(value),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      hideable: false,
      getActions: (params: any) => {
        let isDisabled = true;
        if (params.row.url) isDisabled = false;
        return rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              disabled={isDisabled}
              onClick={() => action.callback(params)}
            />
          );
        });
      },
    },
  ];
};

const getInitialVisibilityModel = ({ showColumns }: any, rowActions: any[]) => {
  const colRange = getColumns(rowActions);
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const candidateUid = 'uid';

const initialShowCols = [
  'name',
  'email',
  'designation',
  'phoneNumber',
  'status',
  'emailShared',
  'actions',
];

export {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  candidateUid,
  getInitialVisibilityModel,
  initialShowCols,
};
