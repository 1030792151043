import React from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { FieldArray } from 'formik';
import { AddCircleOutlined, CloseOutlined } from '@mui/icons-material';
import { MuiTelInput } from 'mui-tel-input';
import useAddEmployeeStyles from './AddEmployeeStyles';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';

interface EmergencyContactProps {
  relations: any;
  formik: any;
}

const EmergencyContact: React.FC<EmergencyContactProps> = ({ relations, formik }) => {
  const { values, setFieldValue } = formik;

  const styles = useAddEmployeeStyles();

  return (
    <FieldArray
      name="emergencyContacts"
      render={(arrayHelpers) => {
        return (
          <>
            {values.emergencyContacts.map((item: any, index: number) => (
              <>
                <Box className={styles.dataBox}>
                  <Grid container rowSpacing={2} spacing={1}>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`emergencyContacts[${index}].firstName`}
                        type="string"
                        label="First Name"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`emergencyContacts[${index}].middleName`}
                        type="string"
                        label="Middle Name"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SimpleInputField
                        name={`emergencyContacts[${index}].lastName`}
                        type="string"
                        label="Last Name"
                        size="small"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        fullWidth
                        id="relation"
                        size="small"
                        disableClearable
                        options={relations}
                        value={values.emergencyContacts[index].relation}
                        onChange={(event, value) => {
                          setFieldValue(`emergencyContacts[${index}].relation`, value);
                        }}
                        getOptionLabel={(option) => option.code}
                        renderInput={(params) => (
                          <TextField {...params} label="Relation" required />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MuiTelInput
                        fullWidth
                        defaultCountry="IN"
                        key="phone"
                        name="phone"
                        label="Phone number"
                        size="small"
                        value={values.emergencyContacts[index].phone}
                        onChange={(newValue, info) => {
                          setFieldValue(`emergencyContacts[${index}].phone`, info.numberValue);
                        }}
                        forceCallingCode
                        splitCallingCode
                        focusOnSelectCountry
                        required
                      />
                    </Grid>
                  </Grid>
                  <Button
                    size="small"
                    className={styles.dataRemoveBtn}
                    sx={{ position: 'absolute' }}
                    onClick={() => arrayHelpers.remove(index)}>
                    <CloseOutlined />
                  </Button>
                </Box>
              </>
            ))}
            <Button
              className={styles.addBtn}
              onClick={() =>
                arrayHelpers.push({
                  firstName: '',
                  lastName: '',
                  middleName: '',
                  phone: '',
                  relation: null,
                })
              }>
              <AddCircleOutlined />
            </Button>
          </>
        );
      }}
    />
  );
};

export default EmergencyContact;
