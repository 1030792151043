import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCategoryStyles = makeStyles((theme: Theme) => {
  return {
    categoryWrapper: {
      padding: '1rem',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    linkWrapper: {
      fontSize: '14px',
      fontWeight: 'bold',
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    allHeader: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    fabContainer: {
      position: 'fixed',
      bottom: '60px',
      right: '25px',
    },
    iconButton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        fontSize: '35px',
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
  };
});

export default useCategoryStyles;
