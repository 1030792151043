/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, TextField, Button, Tooltip } from '@mui/material';
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import I18nKey from '../../translations/I18nKey';
import { getCurrencySymbol } from '../../utils/currency';
import useInvoiceDetailStyles from './InvoiceDetailStyles';
import { InvoiceStatus } from './invoiceTableConfig';

const OtherDetailsFormItem = ({ element, name, currency, invoiceStatus }: any) => {
  const styles = useInvoiceDetailStyles();
  const intl = useIntl();

  const handleMove = (
    arrayHelpers: FieldArrayRenderProps,
    index: number,
    direction: 'up' | 'down',
  ) => {
    if (direction === 'up') arrayHelpers.move(index, index - 1);
    else arrayHelpers.move(index, index + 1);
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {element.items.length
            ? element.items.map((el: any, index: number) => (
                <Box className={styles.FieldWrapper}>
                  <label className={styles.formLabel}>
                    {intl.formatMessage({
                      id: I18nKey.OTHER_DETAILS_DETAIL,
                    })}{' '}
                    :
                  </label>
                  <Box className={styles.Field}>
                    <Field
                      as={TextField}
                      type="text"
                      name={`${name}[${index}].comment`}
                      placeholder="Detail"
                    />
                    <Box className={styles.ErrorMsg}>
                      <ErrorMessage name={`${name}[${index}].comment`} />
                    </Box>
                  </Box>
                  <label className={styles.formLabel}>
                    {intl.formatMessage(
                      {
                        id: I18nKey.OTHER_DETAILS_AMOUNT,
                      },
                      { currency: getCurrencySymbol(currency) },
                    )}{' '}
                    :
                  </label>
                  <Box className={styles.Field}>
                    <Field
                      as={TextField}
                      type="number"
                      name={`${name}[${index}].amount`}
                      placeholder="Amount"
                    />
                    <Box className={styles.ErrorMsg}>
                      <ErrorMessage name={`${name}[${index}].amount`} />
                    </Box>
                  </Box>
                  {element.items.length > 1 && (
                    <>
                      <Button
                        className={styles.innerRemoveBtn}
                        onClick={() => arrayHelpers.remove(index)}>
                        <DeleteIcon />
                      </Button>
                      <Box className={styles.arrowBtnWrapper}>
                        {index > 0 && (
                          <Tooltip title="Move Up">
                            <ArrowCircleUp onClick={() => handleMove(arrayHelpers, index, 'up')} />
                          </Tooltip>
                        )}
                        {index < element.items.length - 1 && (
                          <Tooltip title="Move Down">
                            <ArrowCircleDown
                              onClick={() => handleMove(arrayHelpers, index, 'down')}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              ))
            : null}
          {invoiceStatus !== InvoiceStatus.SHARED && (
            <Button
              sx={{
                textTransform: 'none',
              }}
              className={styles.innerAddBtn}
              variant="text"
              onClick={() => arrayHelpers.push({ amount: 0, comment: '' })}>
              Add Another
            </Button>
          )}
        </>
      )}
    />
  );
};

export default OtherDetailsFormItem;
