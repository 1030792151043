import axiosInstance from '../axiosInstance';
import { AppraisalParams, ResourceParams } from './chartsTypes';

export const PATHS = Object.freeze({
  CONTRACT: `/project/contract/overview`,
  PROJECT: `/project/overview`,
  REVENUE: `/revenue/overview`,
  APPRAISAL: '/appraisal/overview',
  RESOURCE: '/employee/resources/overview',
});

const getContractList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.CONTRACT}`);
  return response.data;
};

const getProjectList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PROJECT}`);
  return response.data;
};

const getAppraisalList = async (appraisalParams: AppraisalParams): Promise<any> => {
  const response = await axiosInstance.get(PATHS.APPRAISAL, { params: appraisalParams });
  return response.data;
};

const getResourceList = async (resourceParams: ResourceParams): Promise<any> => {
  const response = await axiosInstance.get(PATHS.RESOURCE, { params: resourceParams });
  return response.data;
};

const getRevenueList = async (getRevenueParams: any): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.REVENUE}`, { params: getRevenueParams });
  return response.data;
};

export interface ChartAPIClient {
  readonly getContractList: () => Promise<any>;
  readonly getProjectList: () => Promise<any>;
  readonly getRevenueList: (getRevenueParams: any) => Promise<any>;
  readonly getAppraisalList: (appraisalParams: AppraisalParams) => Promise<any>;
  readonly getResourceList: (resourceParams: ResourceParams) => Promise<any>;
}

const ChartsClient: ChartAPIClient = Object.freeze({
  getContractList,
  getProjectList,
  getRevenueList,
  getAppraisalList,
  getResourceList,
});

export default ChartsClient;
