/* eslint-disable react-hooks/rules-of-hooks */
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { replaceEmpty, sortAlphaNumeric } from '../../utils/utils';

const tableRowTotalField: any = [];
const initialSort = { field: 'startDate', sort: 'desc' };

export const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={value}>
      <div className="project-name">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="project-name">{newVal}</div>
  );
};

const getColumns = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return [
    {
      field: 'project_name',
      type: 'string',
      headerName: 'Project',
      flex: 1.75,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.project_name),
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'allocation_percentage',
      type: 'string',
      headerName: 'Allocation Percentage',
      flex: 1.25,
      sortable: true,
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'start_date',
      type: 'string',
      headerName: 'Start Date',
      flex: 1.5,
      sortable: true,
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'end_date',
      type: 'string',
      headerName: 'End Date',
      flex: 1.5,
      sortable: true,
      sortComparator: sortAlphaNumeric,
    },
  ];
};

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const colRange = getColumns();
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const resourceIdName = 'uid';
const initialShowCols = ['projectName', 'allocationPercentage', 'startDate', 'endDate'];

export {
  initialSort,
  getColumns,
  tableRowTotalField,
  getInitialVisibilityModel,
  initialShowCols,
  resourceIdName,
};
