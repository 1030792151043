import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import useModalPopupStyles from './ModalPopupStyle';

interface ModalPopupProps {
  header: string;
  onClose: Function;
  children: JSX.Element;
}

const ModalPopup: React.FC<ModalPopupProps> = ({ header, children, onClose }) => {
  const ModalPopupStyle = useModalPopupStyles();
  return (
    <Box className={ModalPopupStyle.modalWrapper}>
      <div className={ModalPopupStyle.popupWrapper}>
        <div className={ModalPopupStyle.header}>
          <span>{header}</span>
        </div>
        <div className={ModalPopupStyle.content}>{children}</div>
        <Divider />
        <Box className={ModalPopupStyle.btnWrapper}>
          <Button variant="text" className={ModalPopupStyle.closeBtn} onClick={() => onClose()}>
            <span>Close</span>
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default ModalPopup;
