import { Box, Typography } from '@mui/material';
import useInterviewDetailsStyles from './InterviewDetailsStyles';

interface ProjectFeedbackprops {
  roundWiseData: any;
}

const ProjectFeedback: React.FC<ProjectFeedbackprops> = ({ roundWiseData }: any) => {
  const styles = useInterviewDetailsStyles();

  return (
    <>
      <Box className={styles.FeedbackWrapperBox}>
        <Typography className={styles.Rating}>
          {roundWiseData?.interviewFeedback?.project}
        </Typography>
        <Typography className={styles.feedbackComment}>
          {roundWiseData?.interviewFeedback?.projectComment}
        </Typography>
      </Box>
    </>
  );
};

export default ProjectFeedback;
