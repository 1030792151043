import { Checkbox, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { replaceEmpty } from '../../utils/utils';

const dynamicColumns: any = [];

const tableRowTotalField: any = [];

const contactId = 'uid';

const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={value}>
      <div className="textWrapper">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="textWrapper">{newVal}</div>
  );
};

const columns = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Name',
    flex: 1.7,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className="textWrapper">
          <Link to={`${params.row.profile_url}`} key={params.row.id} target="_blank">
            {renderCellTooltip(params.row.name, 22)}
          </Link>
        </div>
      );
    },
  },
  {
    field: 'connected_on',
    type: 'string',
    headerName: 'Connected On',
    flex: 1,
    sortable: true,
    valueFormatter: (params: any) => replaceEmpty(params.value),
  },
  {
    field: 'designation',
    type: 'string',
    headerName: 'Designation',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.designation, 22),
  },
  {
    field: 'location',
    type: 'string',
    headerName: 'Location',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.location),
  },
  {
    field: 'city',
    type: 'string',
    headerName: 'City',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.city),
  },
  {
    field: 'state',
    type: 'string',
    headerName: 'State',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.state),
  },
  {
    field: 'country',
    type: 'string',
    headerName: 'Country',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.country),
  },
  {
    field: 'company_shortened_url',
    type: 'string',
    headerName: 'Company',
    flex: 1.3,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params.row.company_url ? (
        <div className="textWrapper">
          <Link to={`${params.row.company_url}`} key={params.row.id} target="_blank">
            {renderCellTooltip(`${params.row.company_shortened_url}`, 20)}
          </Link>
        </div>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'company_size',
    type: 'string',
    headerName: 'Size',
    flex: 0.8,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.company_size),
  },
  {
    field: 'company_founded',
    type: 'string',
    headerName: 'Founded',
    flex: 0.6,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.company_founded),
  },
  {
    field: 'company_specialities',
    type: 'string',
    headerName: 'Specialities',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      renderCellTooltip(params.row.company_specialities, 52),
  },
  {
    field: 'company_head_quarters',
    type: 'string',
    headerName: 'Headquarters',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      renderCellTooltip(params.row.company_head_quarters),
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 1.5,
    hideable: true,
    filterable: false,
    editable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.remarks, 32),
  },
  {
    field: 'is_relevant',
    headerName: 'Relevant',
    type: 'boolean',
    flex: 1,
    editable: true,
    renderCell: (params: any) => {
      return <Checkbox checked={params.value} disabled />;
    },
  },
];

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const initialVisibilityModelObj: any = {};
  columns.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const initialShowCols = [
  'name',
  'designation',
  'city',
  'state',
  'company_shortened_url',
  'remarks',
  'company_size',
  'company_founded',
  'company_head_quarters',
  'is_relevant',
];

export {
  contactId,
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
};
