import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import { GetAppraisalDetailsParams, GetAppraisalParams } from './appraisalTypes';

export const PATHS = Object.freeze({
  GET_APPRAISAL: '/appraisal/',
  GET_REPORTEES: (manager_uid: string) => `/employee/${manager_uid}/reportees`,
  POST_KRA: (appraisal_uid: string) => `/appraisal/${appraisal_uid}/kra`,
  PUT_APPRAISAL: (appraisal_uid: string) => `/appraisal/${appraisal_uid}`,
  PUT_APPRAISAL_REMARKS: `/appraisal/remarks`,
  APPRAISAL_KRA: (appraisal_uid: string) => `/appraisal/${appraisal_uid}/kra`,
  APPRAISAL_KRA_STATUS: (appraisal_uid: string) => `/appraisal/${appraisal_uid}/status`,
});

const getAllAppraisals = async (getAppraisalParams: GetAppraisalParams): Promise<any> => {
  const queryParams = queryString.stringify(getAppraisalParams);

  const response = await axiosInstance.get(`${PATHS.GET_APPRAISAL}?${queryParams}`);
  return response.data;
};

const postAppraisal = async (postAppraisalParams: any, appraisalUid: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.POST_KRA(appraisalUid), postAppraisalParams);
  return response.data;
};

const postStartAppraisal = async (postAppraisalParams: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.GET_APPRAISAL}`, postAppraisalParams);
  return response.data;
};

const getAppraisalDetails = async (
  getAppraisalDetailsParams: GetAppraisalDetailsParams,
): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_APPRAISAL}${getAppraisalDetailsParams}`);
  return response.data;
};

const getAllReportees = async (managerUid: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_REPORTEES(managerUid));
  return response.data;
};

const getallCategories = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_APPRAISAL}category`);
  return response.data;
};

const getallSubCategories = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_APPRAISAL}subcategory`);
  return response;
};

const updateAppraisal = async (updateAppraisalParams: any, appraisalUid: any): Promise<any> => {
  const response = await axiosInstance.patch(
    PATHS.PUT_APPRAISAL(appraisalUid),
    updateAppraisalParams,
  );
  return response;
};

const updateAppraisalRemarks = async (updateRemarksParams: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_APPRAISAL_REMARKS, updateRemarksParams);
  return response;
};

const updateAppraisalKra = async (params: any, appraisalUid: any): Promise<any> => {
  const response = await axiosInstance.patch(PATHS.APPRAISAL_KRA(appraisalUid), params);
  return response;
};

const deleteAppraisalKra = async (params: any, appraisalUid: any): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.APPRAISAL_KRA(appraisalUid), {
    data: {
      appraisalDetailUids: params,
    },
  });
  return response;
};

const updateAppraisalStatus = async (appraisalUid: any, appraisalStatus: string): Promise<any> => {
  const response = await axiosInstance.put(PATHS.APPRAISAL_KRA_STATUS(appraisalUid), {
    status: appraisalStatus,
  });
  return response;
};

const deleteAppraisal = async (appraisal_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.GET_APPRAISAL}${appraisal_uid}`);
  return response;
};

const AppraisalClient: any = Object.freeze({
  getAllAppraisals,
  getAppraisalDetails,
  getAllReportees,
  postAppraisal,
  postStartAppraisal,
  updateAppraisal,
  getallCategories,
  getallSubCategories,
  updateAppraisalKra,
  deleteAppraisalKra,
  updateAppraisalStatus,
  updateAppraisalRemarks,
  deleteAppraisal,
});

export default AppraisalClient;
