import { Box, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Link, createSearchParams, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useAllSearchStyles from './AllSearchStyles';
import SearchClient from '../../api/Search/searchAPIs';
import { getFullName } from '../../utils/utils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import { GetEmployeeDetailParams } from '../../api/Search/searchTypes';
import SearchInfo from './SearchInfo';
import SearchDetailsCard from './SearchDetailsCard';
import SearchProjectCard from './SearchProjectCard';
import { formatEmployeeList, formatProjectList } from './utils';
import ProjectList from '../Project/ProjectList';

const AllSearch = () => {
  const { id } = useParams();
  const [allEmployeeList, setAllEmployeeList] = useState<any>([]);
  const [allProjectList, setAllProjectList] = useState<any>([]);
  const [allOptionList, setAllOptionList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [candidateDetails, setCandidateDetails] = useState<any>({});
  const [empInProject, setEmpInProject] = useState<any>([]);
  const [allCandidateProjects, setAllCandidateProjects] = useState('-');
  const [allPrimarySkills, setAllPrimarySkills] = useState<any>([]);
  const [candidateId, setCandidateId] = useState(id);
  const [projectId, setProjectId] = useState(id);
  const [initialField, setInitialField] = useState<any>({
    search: null,
  });

  const styles = useAllSearchStyles();
  const navigate = useNavigate();
  const intl = useIntl();

  const { showSnackbar } = useDisplaySnackbar();
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);
  const selectedOption = queryParams.searchBy as string;

  const handleOrganizationClick = () => {
    navigate(`/search/${candidateId}/org-tree?searchBy=${queryParams.searchBy}`);
  };
  const handleOrganizationClickInProject = () => {
    navigate(
      `/search/${queryParams.selectedEmployee}/org-tree?searchBy=${queryParams.searchBy}&selectedProject=${id}`,
    );
  };

  const handleProfileClick = () => {
    // navigate('/profile');
  };

  const getEmployeeDetails = (params: GetEmployeeDetailParams) => {
    setIsLoading(true);
    SearchClient.getEmployeeDetail(params)
      .then((response) => {
        const { data } = response;
        if (selectedOption === 'name') {
          setCandidateDetails(data);
          const initialName = allEmployeeList.find((i: any) => i.value === data.uid);
          setInitialField({ search: initialName });
          setAllCandidateProjects(data.projectNames);
          if (data.primarySkills) setAllPrimarySkills(data.primarySkills.join(', '));
        } else {
          setEmpInProject(data);
          const initialName = allProjectList.find((i: any) => i.value === id);
          setInitialField({ search: initialName });
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  const getEmployeeDetailsOfProject = (params: GetEmployeeDetailParams) => {
    setIsLoading(true);
    SearchClient.getEmployeeDetail(params)
      .then((response) => {
        const { data } = response;
        setCandidateDetails(data);
        setAllCandidateProjects(data.projectNames);
        if (data.primarySkills) setAllPrimarySkills(data.primarySkills.join(', '));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getAllEmployeeList = () => {
    setIsLoading(true);
    SearchClient.getAllEmpList()
      .then((response) => {
        setAllEmployeeList(formatEmployeeList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getAllProjList = () => {
    setIsLoading(true);
    SearchClient.getAllProjectList()
      .then((response) => {
        setAllProjectList(formatProjectList(response?.data));
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    navigate(`/search/${values.search.value}?searchBy=${selectedOption}`);
    if (selectedOption === 'name') {
      setCandidateId(values.search.value);
      const detailParams = {
        employee_uid: values.search.value,
      };
      getEmployeeDetails(detailParams);
    } else {
      setProjectId(values.search.value);
      const detailParams = {
        project_uid: values.search.value,
      };
      getEmployeeDetails(detailParams);
    }
  };

  useEffect(() => {
    if (selectedOption === 'name') {
      getAllEmployeeList();
    } else {
      getAllProjList();
      const detailParams = {
        project_uid: id,
      };
      getEmployeeDetails(detailParams);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (queryParams.selectedEmployee) {
      const detailParams = {
        employee_uid: queryParams.selectedEmployee,
      };
      getEmployeeDetailsOfProject(detailParams);
    }
  }, [queryParams.selectedEmployee]);
  useEffect(() => {
    if (selectedOption === 'name') {
      if (id && allEmployeeList.length) {
        setAllCandidateProjects('-');
        setAllOptionList(allEmployeeList);
        setAllPrimarySkills([]);
        const detailParams = {
          employee_uid: id,
        };
        getEmployeeDetails(detailParams);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (id && allProjectList.length) {
        setAllOptionList(allProjectList);
        const detailParams = {
          project_uid: id,
        };
        getEmployeeDetails(detailParams);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [allEmployeeList, allProjectList]);

  return (
    <>
      {candidateDetails && isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.AllsearchWrapper}>
        <Box className={styles.searchOptions}>
          <Box className={styles.header}>
            <span>
              {intl.formatMessage({
                id: I18nKey.SEARCH_TITLE,
              })}
            </span>
            {id ? (
              <Box>
                <Link to="/home">
                  {intl.formatMessage({
                    id: I18nKey.HOME,
                  })}
                </Link>{' '}
                / {selectedOption === 'name' ? 'Search Employee' : 'Search Project'}
              </Box>
            ) : (
              <Box>
                <Link to="/home">
                  {intl.formatMessage({
                    id: I18nKey.HOME,
                  })}
                </Link>{' '}
                /
                {intl.formatMessage({
                  id: I18nKey.SEARCH_TITLE,
                })}{' '}
              </Box>
            )}
          </Box>
          <SearchInfo
            allOptionList={allOptionList}
            handleSubmit={handleSubmit}
            initialField={initialField}
          />
        </Box>
        <Divider className={styles.DividerLine} />
        {selectedOption === 'name' ? (
          <Grid container spacing={1}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <SearchDetailsCard
                candidateDetails={candidateDetails}
                handleOrganizationClick={handleOrganizationClick}
                allCandidateProjects={allCandidateProjects}
                allPrimarySkills={allPrimarySkills}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <SearchProjectCard
                searchBy={selectedOption}
                empInProject={empInProject}
                selectedEmpUid={queryParams?.selectedEmployee || ''}
              />
            </Grid>
            {queryParams.selectedEmployee && (
              <>
                <Grid xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={4}>
                  <SearchDetailsCard
                    candidateDetails={candidateDetails}
                    handleOrganizationClick={handleOrganizationClickInProject}
                    allCandidateProjects={allCandidateProjects}
                    allPrimarySkills={allPrimarySkills}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default AllSearch;
