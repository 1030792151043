import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import documentLogo from '../../assets/icons/document.png';
import { currencyValueFormatter, dateFromatter } from '../../utils/utils';
import { getCurrencySymbol } from '../../utils/currency';

const initialSort = { field: 'startDate', sort: 'desc' };

const validateDate = (params: any) =>
  params?.value && dayjs(params.value).isValid() ? dateFromatter(params.value) : '-';

const commonColumns = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Document Name',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <a href={params.row.documentPath} download>
          <Box className="contract-doc">
            <Box component="img" src={documentLogo} alt="document" />
            <Typography>{params.value}</Typography>
          </Box>
        </a>
      );
    },
  },
  {
    field: 'startDate',
    type: 'date',
    valueFormatter: validateDate,
    headerName: 'Start Date',
    flex: 1,
  },
  {
    field: 'endDate',
    type: 'date',
    valueFormatter: validateDate,
    headerName: 'End Date',
    flex: 1,
  },
];

const sowColumn = (currency: string, rowActions: any[]) => {
  return [
    ...commonColumns,
    {
      field: 'contractValue',
      type: 'number',
      headerName: `Contract Value (${getCurrencySymbol(currency)})`,
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => currencyValueFormatter(params.value),
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'delete-action',
      flex: 1,
      hideable: false,
      getActions: (params: any) =>
        rowActions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.label}
              icon={action.logo}
              label={action.label}
              onClick={() => action.callback(params)}
            />
          );
        }),
    },
  ];
};

const contractUidName = 'contractUid';

export { commonColumns, sowColumn, contractUidName, initialSort };
