import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import { currencyFormatter, dateFromatter } from '../../utils/utils';
import useContractDetailStyles from './ContractDetailStyles';
import ContractTable from '../../components/ContractTable/ContractTable';
import OrganizationSideCard from '../../components/OrganizationSideCard/OrganizationSideCard';
import { MSA, SOW } from '../../utils/docTypes';
import { F0 } from '../../utils/oppStages';

const ContractDetail = () => {
  const [projectDetail, setProjectDetail] = useState<any>({});
  const [contractSummary, setContractSummary] = useState<any>({ latestMSA: null, latestSOW: null });
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const classes = useContractDetailStyles();
  const intl = useIntl();
  const projectUid = params.id || '';
  const { latestMSA, latestSOW } = contractSummary;

  const projectDetails: { label: string; value: string }[] = [
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_DETAIL_MSA_END_DATE,
      }),
      value: latestMSA ? dateFromatter(latestMSA.endDate) : '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_DETAIL_SOW_END_DATE,
      }),
      value: latestSOW ? dateFromatter(latestSOW.endDate) : '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_DETAIL_PO,
      }),
      value: latestSOW?.purchaseOrder || '-',
    },
    {
      label: intl.formatMessage({
        id: I18nKey.CONTRACT_DETAIL_SOW_VALUE,
      }),
      value: latestSOW ? currencyFormatter(latestSOW.contractValue, projectDetail?.currency) : '-',
    },
  ];

  const onProjectDataLoad = (project: any) => setProjectDetail(project);

  const onContractDataLoad = (docs: any) => {
    let ltstMSA = docs.filter((doc: any) => doc.documentTypeCode === MSA);
    let ltstSOW = docs.filter((doc: any) => doc.documentTypeCode === SOW);
    ltstMSA = ltstMSA.length
      ? ltstMSA.reduce((prevDoc: any, currDoc: any) => {
          return dayjs(prevDoc.endDate).isAfter(currDoc.endDate) ? prevDoc : currDoc;
        })
      : null;
    ltstSOW = ltstSOW.length
      ? ltstSOW.reduce((prevDoc: any, currDoc: any) => {
          if (dayjs(prevDoc.startDate).isSame(currDoc.startDate)) {
            return dayjs(prevDoc.createdOn).isAfter(currDoc.createdOn) ? prevDoc : currDoc;
          }
          return dayjs(prevDoc.startDate).isAfter(currDoc.startDate) ? prevDoc : currDoc;
        })
      : null;
    setContractSummary({ latestMSA: ltstMSA, latestSOW: ltstSOW });
  };

  const hideDocCategories = projectDetail?.stage !== F0 ? [SOW] : [];

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={classes.ContractDetailsWrapper}>
        {/* Header */}
        <Box className={classes.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.CONTRACT_DETAIL_TITLE,
            })}
          </span>
          <Box>
            <Link to="/contract">
              {intl.formatMessage({
                id: I18nKey.CONTRACT_DETAIL_SUBTITLE,
              })}
            </Link>{' '}
            / {projectDetail?.name}
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item md={3} sm={12} xs={12}>
            <OrganizationSideCard
              projectId={String(params.id)}
              onDataLoad={onProjectDataLoad}
              usedBy="others"
            />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <Box className={classes.UpperCardWrapper}>
              <Typography className={classes.UpperCardHeader}>
                {intl.formatMessage({
                  id: I18nKey.CONTRACT_DETAIL_INFO,
                })}
              </Typography>
              <Divider />
              <Box className={classes.UpperCardContent}>
                {projectDetails?.map((item) => (
                  <Box key={item.label}>
                    <span className={classes.UpperCardFieldsKey}>{item.label}</span>
                    <p className={classes.UpperCardFieldsValue}>{item.value}</p>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={classes.LowerCardWrapper}>
              <ContractTable
                isUploadable
                project_id={projectUid}
                onDataLoad={onContractDataLoad}
                hideDocCategories={hideDocCategories}
                currency={projectDetail?.currency}
                usedFor="listing"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContractDetail;
