import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@mui/material';
import { dateFromatter } from '../../utils/utils';

const dynamicColumns: any[] = [];
const tableRowTotalField: any = [];
const initialSort = { field: 'employeeId', sort: 'desc' };

const getColumns = (currentFy?: { startDate: string; endDate: string }) => {
  const getCheckIcons = (status: string) => {
    if (status === 'Yes') {
      return (
        <Tooltip title="Completed">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Pending">
        <ErrorOutlineIcon sx={{ color: 'orange' }} />
      </Tooltip>
    );
  };
  const salaryRevisionYear = currentFy?.endDate.substring(0, 4);
  return [
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Name',
      flex: 1.25,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={`/document/compensationLetter/${params.row.employeeUid}?letter=compensationLetter&startDate=${currentFy?.startDate}&endDate=${currentFy?.endDate}&salary_revision_year=${salaryRevisionYear}`}>
            {params.formattedValue}
          </Link>
        );
      },
    },
    {
      field: 'managerName',
      type: 'string',
      headerName: 'Manager',
      flex: 1.5,
      sortable: true,
    },
    {
      field: 'departmentName',
      type: 'string',
      headerName: 'Department Name',
      flex: 1.5,
      sortable: true,
    },
    {
      field: 'appraisalStatus',
      type: 'string',
      headerName: 'Appraisal Status',
      flex: 1.5,
      sortable: true,
    },
    {
      field: 'doj',
      type: 'string',
      headerName: 'DOJ',
      flex: 1,
      filterable: false,
      valueFormatter: (params: any) => dateFromatter(params.value),
    },
    {
      field: 'isLetterCreated',
      type: 'string',
      headerName: 'Letter Created',
      flex: 1,
      sortable: true,
      renderCell: ({ value }: GridRenderCellParams) => getCheckIcons(value),
    },
    {
      field: 'isBonusGiven',
      type: 'string',
      headerName: 'Bonus',
      flex: 1,
      sortable: true,
      hide: true,
      renderCell: ({ value }: GridRenderCellParams) => getCheckIcons(value),
    },
    {
      field: 'isShared',
      type: 'string',
      headerName: 'Share',
      flex: 0.5,
      sortable: true,
      renderCell: ({ value }: GridRenderCellParams) => getCheckIcons(value),
    },
    // Additional columns can be defined here
  ];
};

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const colRange = getColumns();
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });
  return initialVisibilityModelObj;
};

const employeeUid = 'employeeUid';

const initialShowCols = [
  'employeeName',
  'managerName',
  'appraisalStatus',
  'doj',
  'isLetterCreated',
  'isShared',
];

export {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  employeeUid,
  getInitialVisibilityModel,
  initialShowCols,
};
