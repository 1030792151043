import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import useResourceDetailStyles from '../ResourceDetailStyles';

export const getLastDateOfCurrentMonth = () => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return lastDayOfMonth.toISOString().split('T')[0];
};

export const getFirstDateOfSixMonthsBack = () => {
  const currentDate = new Date();
  const sixMonthsBack = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
  return sixMonthsBack.toISOString().split('T')[0];
};

export const SearchProjectHistoryForm = ({ handleSubmit }: any) => {
  const styles = useResourceDetailStyles();

  return (
    <>
      <Formik
        initialValues={{
          startDate: getFirstDateOfSixMonthsBack(),
          endDate: getLastDateOfCurrentMonth(),
        }}
        onSubmit={handleSubmit}
        enableReinitialize>
        {(formik) => {
          const { isValid, dirty } = formik;
          return (
            <Form>
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: '10px', paddingRight: '3px', marginTop: '2px' }}>
                <Grid item xs={12} sm={4}>
                  <SimpleInputField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 13 } }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SimpleInputField
                    label="End Date"
                    name="endDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 13 } }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    disabled={!dirty || !isValid}
                    variant="contained"
                    className={styles.fetchResultsButton}
                    type="submit">
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SearchProjectHistoryForm;
